import React from 'react';
import { useParams } from 'react-router-dom';
import { LTIProviders } from './LTI.types';
import { LTICourse } from './lti-course';

export const LTI: React.FC = () => {
  const { provider } = useParams<{ provider?: string }>();

  switch (provider) {
    case LTIProviders.GOODHABITZ:
      return <LTICourse provider={LTIProviders.GOODHABITZ} />;
    default:
      return <p>Invalid provider: {provider}</p>;
  }
};
