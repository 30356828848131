import classnames from 'classnames';
import { Typography } from 'components/typography';
import React from 'react';
import theme from 'theme';
import { isHTML } from 'util/isHTML';
import { QuestionProps, QuestionTextProps } from './types';

const Question: React.FC<QuestionProps> = ({
  variant = '',
  text,
  extended = false,
  noStyles = false,
  rich = isHTML(text),
  scaleWithContent,
  color,
  ...rest
}) => (
  <div
    className={classnames(
      'relative',
      {
        'my-5 mx-4 rounded-lg border-2 border-solid border-light': !variant,
        'py-5 px-2.5': !variant && !extended,
        'my-5 mx-0 rounded-3xl bg-ocean flex items-center justify-center': variant === 'bold',
        'min-h-[2.5rem]': variant === 'bold' && !scaleWithContent,
        'p-3.5 text-greyishBrown leading-5': variant === 'borderless',
      },
      {
        'min-h-full': scaleWithContent,
        'p-0': extended,
      },
    )}
    style={{
      backgroundColor: variant === 'bold' ? color || theme.colors.ocean : '',
    }}
    {...rest}
  >
    {rich ? (
      <Typography
        rich
        className={classnames('text-greyishBrown font-normal leading-5 break-words', {
          'p-2': !extended,
          'py-12 px-8': extended,
        })}
        data-testid='question-rich-text'
      >
        {text}
      </Typography>
    ) : (
      <QuestionText
        extended={extended}
        scaleWithContent={scaleWithContent}
        text={text}
        noStyles={noStyles}
        variant={variant}
      />
    )}
  </div>
);

const QuestionText: React.FC<QuestionTextProps> = ({ extended, scaleWithContent, text, noStyles, variant }) => (
  <div
    className={classnames('leading-5 break-words', {
      'text-greyishBrown': (!noStyles && !variant) || scaleWithContent,
      'p-2': (noStyles || variant) && !extended,
      'font-normal': noStyles || variant !== 'bold',
      'text-lg text-center text-greyishBrown flex items-center justify-center whitespace-pre-wrap py-11':
        !noStyles && !variant,
      'w-full text-base font-bold text-center text-white whitespace-pre-wrap uppercase p-0':
        !noStyles && variant === 'bold',
      'relative': !noStyles && variant === 'borderless',
      'py-12 px-8': extended,
    })}
  >
    <p className='m-0'>{text}</p>
  </div>
);

export default Question;
