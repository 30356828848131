import { PREVIOUS_LOCATION_COOKIE } from 'constants/global';
import { XT_PROV_URL } from 'containers/authentication';
import { unallowedPreviousLocations } from 'containers/authentication/withAuthentication';
import { GraphQLError } from 'graphql';
import cookie from 'js-cookie';
import { preparePreviousLocation } from './preparePreviousLocation';

export enum ExpectedAcceptHeaders {
  JSON = 'application/json',
}

const parseResponse = (response: Response) => {
  const contentType = response.headers.get('Content-Type');

  if (contentType?.includes(ExpectedAcceptHeaders.JSON)) {
    return Promise.resolve(response.json());
  }

  return Promise.resolve(response.text());
};

export interface RequestError extends Pick<Response, 'status' | 'statusText'> {
  message: string;
}

function checkStatus(response: Response) {
  if (response.ok) {
    return parseResponse(response);
  }

  return response.json().then((json) => {
    /**
     * Parsed error from server response
     * @typedef {Object} ServerError
     * @prop {number} status - response status
     * @prop {string} statusText - response status text
     * @prop {string} message - response message
     */
    const reason: RequestError = {
      status: response.status,
      statusText: response.statusText,
      message: json.message || response.statusText,
    };

    return Promise.reject(reason);
  });
}

const isGraphqlError = (error: unknown): error is GraphQLError => {
  if (!error) {
    return false;
  }
  const asGraphqlError = error as GraphQLError;
  return 'message' in asGraphqlError && 'locations' in asGraphqlError && 'path' in asGraphqlError;
};

const isGraphqlUnauthorized = (currentError: unknown) => {
  if (!isGraphqlError(currentError)) {
    return false;
  }
  return currentError.message.toLocaleLowerCase() === 'unauthorized';
};

export const handleError = (error: unknown) => {
  const errors = Array.isArray(error) ? error : [error];

  const haveUnauthorized = errors.some(isGraphqlUnauthorized);
  const ssoRedirectUri = cookie.get(XT_PROV_URL);

  if (haveUnauthorized && window.location.pathname !== '/login') {
    const currentURL = preparePreviousLocation(window.location);
    const isAllowedPreviousLocation = !unallowedPreviousLocations.includes(currentURL);

    if (isAllowedPreviousLocation) {
      cookie.set(PREVIOUS_LOCATION_COOKIE, currentURL);
    }

    if (ssoRedirectUri) {
      cookie.remove(XT_PROV_URL);
      window.location.href = ssoRedirectUri;
    } else {
      window.location.replace('/login');
    }
  }

  return { data: null, errors: error, err: error };
};

export interface RequestResult<Data = any> {
  data: Data;
  errors?: unknown;
  err?: unknown;
}

export default function request(url: string, options?: RequestInit): Promise<RequestResult> {
  return fetch(url, options)
    .then(checkStatus)
    .then((data) => ({ data }))
    .catch(handleError);
}
