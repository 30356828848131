import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { testsApi } from 'pages/tests/Tests.api';
import {
  CheckpointPayload,
  Domains,
  GetFeaturedPathSuccessPayload,
  GetUserPathPayload,
  GetUserPathsSuccessPayload,
  HomeStore,
  Leco,
  SetShowInstallInstructionReminderPayload,
} from './Home.types';

export const initialState: HomeStore = {
  paths: [],
  featuredPath: null,
  categories: [],
  pathSet: false,
  pathID: '',
  error: null,
  unlockedPaths: [],
  unlockedPathsToAnimate: [],
  shouldAnimateUnlocks: false,
  showInstallInstructionReminder: false,
};

const slice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setSplashScreen: (state, _action: PayloadAction<Leco>) => state,
    getUserLecos: (state, _action: PayloadAction<GetUserPathPayload>) => ({
      ...state,
      shouldAnimateUnlocks: false,
    }),
    getUserLecosSuccess: (state, action: PayloadAction<GetUserPathsSuccessPayload>) => {
      const { paths } = action.payload;
      return {
        ...state,
        paths: paths.map((path) => ({
          ...path,
          /**
           * Id is converted to a string until all resolvers/endpoints return
           * the path id as a `number` to avoid inconsistencies at runtime
           */
          id: String(path.content.lecoId || path.content.id),
        })),
      };
    },
    getUserLecosError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      paths: [],
      error: payload,
    }),
    getCategories: (state) => state,
    getCategoriesSuccess: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      categories: payload,
    }),
    getCategoriesError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      error: payload,
    }),
    getFeaturedPath: (state) => state,
    getFeaturedPathSuccess: (state, { payload }: PayloadAction<GetFeaturedPathSuccessPayload>) => ({
      ...state,
      featuredPath: payload.featuredPath,
    }),
    getFeaturedPathError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      error: payload,
    }),
    createCheckpoint: (state, _action: PayloadAction<CheckpointPayload>) => state,
    createCheckpointSuccess: (state, action: PayloadAction<CheckpointPayload>) => {
      const { payload: checkpoint } = action;

      const nextLecos = state.paths.map((leco) => {
        if (Number(leco.id) !== checkpoint.learningContentId) {
          return leco;
        }
        if (checkpoint.domain !== Domains.LINK) {
          return leco;
        }

        // ! Currently the links open in a new tab and therefor we'll need to
        // ! update its progress as soon as we create the checkpoint.
        // ! This should be changed as soon as the backend responds with the progress.

        return {
          ...leco,
          progress: 100,
        };
      });

      return {
        ...state,
        paths: nextLecos,
      };
    },
    createCheckpointError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      error: payload,
    }),
    toggleAnimateLocks: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      shouldAnimateUnlocks: payload,
    }),
    setUnlockedPathsToAnimate: (state, { payload }: PayloadAction<{ id: number }[]>) => ({
      ...state,
      unlockedPathsToAnimate: payload,
    }),
    cleanUnlockedPathsToAnimate: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      unlockedPathsToAnimate: state.unlockedPathsToAnimate.filter((leco) => String(leco.id) !== String(payload)),
    }),
    cleanUnlockedPaths: (state) => ({ ...state, unlockedPaths: [] }),
    setShowInstallInstructionReminder: (state, action: PayloadAction<SetShowInstallInstructionReminderPayload>) => ({
      ...state,
      showInstallInstructionReminder: action.payload.showInstallInstructionReminder,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(testsApi.endpoints.submitLearningTestAnswer.matchFulfilled, (state, action) => {
      const { testCompletion } = action.payload;

      const unlockedPaths = testCompletion?.unlockedPaths ?? [];
      const unlockedPathsIds = unlockedPaths.map((unlockPath) => ({ id: unlockPath }));
      state.unlockedPaths.push(...unlockedPathsIds);
    });
  },
});

export const { actions } = slice;
export default slice.reducer;
