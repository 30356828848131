import { ConfirmationDrawer } from 'components/drawer';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMessageContext } from 'stream-chat-react';
import { ActionsProps } from './Message.types';

export const MessageDeleteAction: FC<ActionsProps> = ({ isOpen, onClose, renderHighlightedMessage }) => {
  const { handleDelete } = useMessageContext();

  return (
    <ConfirmationDrawer
      isOpen={isOpen}
      title={
        <FormattedMessage
          id='community.chat.delete-message.question'
          defaultMessage='Delete message?'
        />
      }
      confirmationText={
        <FormattedMessage
          id='community.chat.delete-message.confirmation'
          defaultMessage='Yes, delete message'
        />
      }
      cancellationText={
        <FormattedMessage
          id='community.chat.delete-message.cancellation'
          defaultMessage='Do not delete'
        />
      }
      onConfirm={handleDelete}
      onClose={onClose}
      renderElement={renderHighlightedMessage}
    />
  );
};
