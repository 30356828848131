import { createSelector } from 'reselect';
import { Store } from 'store/types';

export const stateHome = ({ home }: Store) => home;
export const stateUnreadPosts = ({ newsfeed }: Store) => newsfeed.unreadPosts;

export const navSelector = createSelector(stateHome, stateUnreadPosts, (home, unreadPosts) => ({
  paths: home.paths,
  pathSet: home.pathSet,
  unreadPosts,
}));
