import { SharedResultsProps as Props } from 'pages/community/newsfeed/Newsfeed.types';
import React from 'react';

export const SharedResults: React.FC<Props> = ({ post }) => {
  if (!post.answers && !Array.isArray(post.answers)) {
    return null;
  }

  return (
    <div className='flex flex-row justify-between w-full mb-3.5'>
      <ul className='w-full p-0 m-0 list-none'>
        {post.answers.map((answer) => (
          <li
            key={`${answer.id}answerResult`}
            className='flex justify-between items-center list-none text-lightGrey font-medium text-sm my-2.5 mx-0 select-none bg-gray-100 p-3.5 rounded-lg break-words'
          >
            <span style={{ width: '75%' }}>{answer.text}</span>
            <span>{answer.answersCount} votes</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
