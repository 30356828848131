import addAvatarIcon from 'assets/images/no-avatar-user.svg';
import classnames from 'classnames';
import { Media } from 'components/media';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import theme from 'theme';
import { AvatarProps as Props } from './types';

export const Avatar: React.FC<Props> = ({
  borderColor,
  borderWidth = 5,
  className,
  src,
  squared: isSquared = false,
  disableBorder = false,
  editable = false,
  cameraIcon = false,
  presence,
  notifications = 0,
  title = '',
  primaryColor = '',
  onClick,
  onClose,
  ...rest
}) => (
  <div
    className='relative flex justify-center h-full'
    data-testid='e2e-avatar'
    onClick={onClick}
  >
    {presence === 'online' && (
      <span
        className={classnames('absolute left-0 top-0 z-10 w-4 h-4 rounded-full border-2 border-white', {
          'bg-green-500': presence === 'online',
        })}
      />
    )}

    {notifications > 0 && (
      <span className='absolute right-0 bottom-0 z-10 w-6 h-6 rounded-full border-2 border-white bg-red-500 text-white text-xs text-center p-0.5'>
        {notifications}
      </span>
    )}

    {onClose && (
      <button
        className='absolute z-10 w-5 h-5 bg-gray-500 rounded-full -bottom-1/20 -right-1/20'
        onClick={onClose}
      >
        <SvgIcon
          name='close'
          color={theme.colors.white}
          size={16}
        />
      </button>
    )}

    <div
      className={classnames(className, 'relative w-16 h-16 overflow-hidden', {
        'cursor-pointer': editable,
        'border-2 border-gray-600': !disableBorder,
        'rounded-full': !isSquared,
      })}
      style={{
        border: borderColor && !disableBorder ? `${borderWidth}px solid ${borderColor}` : '',
        background: !src && title ? '#D9D9D9' : '',
      }}
    >
      <Media
        src={src || ''}
        alt='avatar'
        disableCover
        {...rest}
      />
      {!src && editable && (
        <img
          src={addAvatarIcon}
          className='w-full h-full'
          alt=''
        />
      )}

      {title && !src && (
        <div
          className='flex absolute top-1/2 left-1/2 translate-3d-[-50%,-50%,0] font-medium'
          style={{
            color: primaryColor,
          }}
        >
          {title
            .split(' ')
            .map((word) => word[0])
            .join('')
            .toUpperCase()}
        </div>
      )}
    </div>
    {cameraIcon && editable && (
      <span
        className='absolute flex items-center justify-center w-8 h-8 rounded-full pointer-events-none -bottom-1/20 -right-1/20'
        style={{ backgroundColor: borderColor || '#00bbd5' }}
        data-testid='avatar-camera'
      >
        <SvgIcon
          name='camera'
          color='#FFFFFF'
          size={18}
        />
      </span>
    )}
  </div>
);
