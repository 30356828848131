import { Button } from 'components/button';
import { Popup } from 'components/popup';
import { User } from 'containers/authentication';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { animated, interpolate, useSpring } from 'react-spring';
import { Store } from 'store/types';
import { SpringStyles } from 'util/types';
import { FeedbackPopupProps } from '../PersonalityTest.types';
import { Chest } from './Chest';

const ANIMATION_DURATION_MS = 2000;

export const PersonalityTestFeedbackPopup: React.FC<FeedbackPopupProps> = ({ onModalClose, open, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChest = () => {
    setIsOpen(true);
  };

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: isOpen ? 1 : 0 },
    delay: ANIMATION_DURATION_MS,
  });

  const fadeOut = useSpring({
    from: { opacity: 1 },
    to: { opacity: isOpen ? 0 : 1 },
  });

  const { start } = useSpring({
    from: { start: 0 },
    start: isOpen ? 1 : 0,
    config: {
      duration: ANIMATION_DURATION_MS,
    },
  });

  const translate = start.interpolate({
    range: [0, 0.1, 0.2, 0.25, 0.3, 0.35, 0.5, 0.58, 0.66, 0.8, 0.9, 1],
    output: [0, -25, -25, -30, -25, -25, -25, -40, -25, -25, -25, -210],
  });
  const scale = start.interpolate({
    range: [0, 0.1, 0.2, 0.25, 0.3, 0.35, 0.5, 0.58, 0.66, 0.8, 0.9, 1],
    output: [1, 2, 2, 2.2, 2, 2, 2, 2.5, 2, 2, 2, 1],
  });
  const rotate = start.interpolate({
    range: [0, 0.1, 0.2, 0.25, 0.3, 0.35, 0.5, 0.58, 0.66, 0.8, 0.9, 1],
    output: [0, 0, 0, -5, 2, 0, 0, 10, -4, 0, 0, 0],
  });
  const transform = interpolate(
    [translate, scale, rotate],
    (y, s, r) => `translateY(${y}px) scale(${s}) rotate(${r}deg)`,
  );

  const persona = useSelector<Store, User['persona']>(({ profile }) => profile.user.persona);

  if (!persona) {
    throw new Error('`persona` not defined');
  }

  const handleStartLearning = () => {
    onModalClose();
  };

  const handleClickModal = () => {
    isOpen ? handleStartLearning() : handleOpenChest();
  };

  const ClosedContent: React.FC<{ style?: React.CSSProperties | SpringStyles }> = ({ style }) => (
    <div className='absolute flex flex-col items-center w-64'>
      <animated.div style={style}>
        <h1 className='text-lg text-turquoiseBlue'>
          <FormattedMessage id='personality.feedback-popup.closed-chest.title' />
        </h1>
      </animated.div>
      <animated.div style={style}>
        <p className='font-normal whitespace-pre-line'>
          <FormattedMessage id='personality.feedback-popup.closed-chest.content' />
        </p>
      </animated.div>
      <animated.div
        className='mt-32'
        style={style}
      >
        <p className='whitespace-pre-line'>
          <FormattedMessage id='personality.feedback-popup.closed-chest.hint' />
        </p>
      </animated.div>
    </div>
  );

  const OpenContent: React.FC<{ style?: React.CSSProperties | SpringStyles }> = ({ style }) => (
    <div className='flex flex-col items-center w-64 h-full justify-evenly'>
      <animated.div style={style}>
        <h1 className='text-lg text-turquoiseBlue'>
          <FormattedMessage id={`personality.feedback-popup.opened-chest.types.${persona}.title`} />
        </h1>
      </animated.div>
      <animated.div style={style}>
        <p className='font-normal whitespace-pre-line'>
          <FormattedMessage id={`personality.feedback-popup.opened-chest.types.${persona}.description`} />{' '}
          <FormattedMessage id='personality.feedback-popup.opened-chest.content' />
        </p>
      </animated.div>
      <animated.div style={style}>
        <Button
          variant='outlined'
          className='px-6 py-4 mt-4 text-greyish'
        >
          <FormattedMessage id='personality.feedback-popup.opened-chest.confirmButton' />
        </Button>
      </animated.div>
    </div>
  );

  return (
    <Popup
      open={open}
      variant='personalityTest'
      onClick={handleClickModal}
      {...props}
    >
      <div className='relative flex flex-col items-center justify-center w-full text-sm text-center h-80 text-warmGrey'>
        <animated.div
          className='absolute z-10 cursor-pointer top-32'
          style={{ transform }}
        >
          <Chest
            open={isOpen}
            delay={ANIMATION_DURATION_MS * 0.6}
          />
        </animated.div>
        {isOpen && <OpenContent style={fadeIn} />}
        {!isOpen && <ClosedContent style={fadeOut} />}
      </div>
    </Popup>
  );
};
