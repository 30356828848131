import classnames from 'classnames';
import { PollAnswerOptionsProps, PostAnswer } from 'pages/community/newsfeed/Newsfeed.types';
import React from 'react';
import theme from 'theme';

export const PollAnswerOptions: React.FC<PollAnswerOptionsProps> = ({
  post,
  color,
  onAnswerClick,
  selectedAnswerID,
}) => {
  const isAnswered = (ans: PostAnswer) => ans.answered;
  const isSelected = (ans: PostAnswer) => ans.id === selectedAnswerID && !isAnswered(ans);

  const getBackgroundColor = (ans: PostAnswer) => {
    if (isAnswered(ans)) {
      return theme.colors.green;
    }
    if (isSelected(ans)) {
      return color;
    }
    return theme.colors.neutral100;
  };

  if (!Array.isArray(post.answers)) {
    return null;
  }

  return (
    <ul className='pl-0 ml-0 break-words list-none select-none'>
      {post.answers.map((ans) => (
        <li
          key={`${ans.id}-pollAnswer`}
          onClick={onAnswerClick ? onAnswerClick(ans.id) : undefined}
          className={classnames(
            'list-none flex items-center text-neutral-600 font-medium text-sm my-3.5 cursor-pointer p-3.5 rounded-lg',
          )}
          style={{ backgroundColor: getBackgroundColor(ans) }}
        >
          <i className='block w-5 h-5 mr-5 bg-white border-2 border-solid rounded-full border-neutral-500' />
          <span
            className={classnames('break-words w-9/10 transition-color duration-200', {
              'text-white': ans.id === selectedAnswerID || ans.answered,
            })}
          >
            {ans.text}
          </span>
        </li>
      ))}
    </ul>
  );
};
