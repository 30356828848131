import classNames from 'classnames';
import { PageContainer } from 'components/page-container';
import React from 'react';
import { FireworksPath } from '../FireworksPath';
import { PopupVariantProps } from '../Popup.types';
import { XPoval } from '../XPoval';

export const ExperiencePointsPopup: React.FC<PopupVariantProps> = ({
  showXP,
  xpText,
  xpFill = '#FFB500',
  xpColor,
  xpTextColor,
  xp,
}) => (
  <PageContainer center>
    <svg
      style={{ maxWidth: '15.63rem' }}
      width='100%'
      viewBox='0 0 250 251'
      data-testid='e2e-popup'
    >
      <g fillRule='evenodd'>
        <g
          className={classNames('transform transition-transform ease-in-out', {
            'translate-x-1/2 translate-y-1/2 scale-0 delay-200': !showXP,
            'translate-x-0 translate-y-0 scale-1 delay-300': showXP,
          })}
        >
          <FireworksPath fill={xpFill || ''} />
        </g>

        <g
          className={classNames('transform transition-transform delay-200 ease-in-out', {
            'translate-x-1/2 translate-y-1/2 scale-0': !showXP,
            'translate-x-0 translate-y-0 scale-1': showXP,
          })}
        >
          <XPoval
            text={xpText || undefined}
            textColor={xpTextColor}
            fill={xpFill || ''}
            xp={xp || ''}
            xpColor={xpColor || ''}
          />
        </g>
      </g>
    </svg>
  </PageContainer>
);
