import { skipToken } from '@reduxjs/toolkit/dist/query';
import classnames from 'classnames';
import { InstallInstructionReminderPopup } from 'components/install-instruction-reminder-popup';
import { Logo } from 'components/logo';
import { NotificationsBadge, SvgIcon } from 'components/svg-icon';
import { API_URL } from 'constants/endpoints';
import { hasRequiredFeatures } from 'containers/authentication';
import { languageTextDirections } from 'containers/language-provider';
import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { actions } from 'pages/community/newsfeed/Newsfeed.ducks';
import { GROWTH_PLAN_PATH } from 'pages/growth-plan/GrowthPlan';
import { actions as homeActions } from 'pages/home';
import { getIdLaunchUrl } from 'pages/login/useIdPortal';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { useFiltersQueryParams } from 'util/useFiltersQueryParams';
import { useChatUnreadCount } from '../../pages/community/chat/ChatContext';
import { NavigationIcons, NavigationType, BottomNavigationProps as Props, Themes } from './types';

const iconSize = 23;

const BottomNavigation: React.FC<Props> = ({
  features,
  history,
  location,
  pathSet,
  paths,
  unreadPosts,
  className,
  locale,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { enqueueModal } = useModalQueue();
  const { count: amountOfAppliedFilters } = useFiltersQueryParams();

  const { logoDarkUrl, logoUrl, id: accountId } = useSelector(({ profile }: RootState) => profile.account);
  const { id: userId } = useSelector(({ profile }: RootState) => profile.user);
  const showInstallInstructionReminder = useSelector(({ home }: RootState) => home.showInstallInstructionReminder);
  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);
  const cmsAppSwitchingConfig = dynamicConfiguration?.appSwitchingConfig.cms;
  const coachingAppSwitchingConfig = dynamicConfiguration?.appSwitchingConfig.coachingAppFrontend;
  const hideLearningPathIcon = useFeatureFlag(FlagKeys.LEARNING_PATH_NAVBAR_ICON);
  const isGrowthPlanEnable = useFeatureFlag(FlagKeys.GROWTH_PLAN) && dynamicConfiguration?.menu.coachingTool.show;
  const isCategoriesTypesFiltersEnabled = useFeatureFlag(FlagKeys.SKILLFLIX_FILTERS_CATEGORIES_TYPES);
  const isCommunityEnabled = useFeatureFlag(FlagKeys.COMMUNITY_CHAT) && dynamicConfiguration?.menu.community.show;
  const isNewsfeedEnabled = Boolean(dynamicConfiguration?.menu.newsfeed.show);

  const [isCollapsed, setCollapsed] = React.useState(true);

  const homeTheme = Themes.DARK;
  const currentPath = location.pathname;

  const { unreadCount } = useChatUnreadCount();

  const hasCommunityNotification = unreadPosts > 0 || unreadCount > 0;

  const helpIconRef = useRef<HTMLLIElement | null>(null);

  React.useEffect(() => {
    const hasChangedRoute = Boolean(currentPath);
    const shouldRequestNewsfeed = features.newsfeed && !unreadPosts && hasChangedRoute;
    if (!shouldRequestNewsfeed) {
      return;
    }

    dispatch(actions.getUnreadPosts({}));
  }, [dispatch, features.newsfeed, currentPath, unreadPosts]);

  const handleAppSwitching = (app?: string) => () => {
    if (!app) {
      return;
    }
    const idLaunchUrl = getIdLaunchUrl(app, process.env.REACT_APP_CLIENT_ID, userId);
    const { href } = idLaunchUrl;
    window.location.replace(href);
  };

  const handleFilterCategory = () => {
    enqueueModal({ type: ModalTypes.CATEGORY_FILTER });
  };

  const communityNewsfeed: NavigationType = {
    icon: 'mailOutline',
    path: isNewsfeedEnabled ? ['/community/newsfeed'] : ['/community/chat'],
    notificationsBadge: <NotificationsBadge open={hasCommunityNotification} />,
    theme: Themes.DARK,
    title: (
      <FormattedMessage
        id='navbar.community'
        defaultMessage='Community'
      />
    ),
    screenReaderText: (
      <>
        <FormattedMessage id='navbar.current-item' />:<FormattedMessage id='navbar.community' />
      </>
    ),
  };

  const defaultNewsfeed: NavigationType = {
    icon: 'mailOutline',
    path: ['/newsfeed'],
    required: ['newsfeed'],
    notificationsBadge: <NotificationsBadge open={unreadPosts > 0} />,
    theme: Themes.LIGHT,
    title: <FormattedMessage id='navbar.newsfeed' />,
    screenReaderText: (
      <>
        <FormattedMessage id='navbar.current-item' />:<FormattedMessage id='navbar.newsfeed' />
      </>
    ),
  };

  const NavigationItems: NavigationIcons = {
    home: {
      icon: 'home',
      path: ['/'],
      theme: homeTheme,
      title: <FormattedMessage id='navbar.home' />,
      viewBox: '-3 0 25 25',
      screenReaderText: (
        <>
          <FormattedMessage id='navbar.current-item' />:<FormattedMessage id='navbar.home' />
        </>
      ),
    },
    ...(!hideLearningPathIcon && {
      path: {
        icon: 'path',
        path: ['/path', '/skill', 'no-path'],
        theme: Themes.DARK,
        title: <FormattedMessage id='navbar.path' />,
        viewBox: '-1 0 25 28',
        screenReaderText: (
          <>
            <FormattedMessage id='navbar.current-item' />:<FormattedMessage id='navbar.path' />
          </>
        ),
      },
    }),
    growthPlan: {
      icon: 'growth-plan',
      path: [GROWTH_PLAN_PATH],
      theme: Themes.LIGHT,
      title: <FormattedMessage id='navbar.growth-plan' />,
      className: classnames({ hidden: !isGrowthPlanEnable }),
      screenReaderText: (
        <>
          <FormattedMessage id='navbar.current-item' />:<FormattedMessage id='navbar.growth-plan' />
        </>
      ),
    },
    newsfeed: isCommunityEnabled ? communityNewsfeed : defaultNewsfeed,
    search: {
      icon: 'search',
      path: ['/search'],
      theme: Themes.LIGHT,
      title: <FormattedMessage id='navbar.search' />,
      viewBox: '1 0 21 21',
      screenReaderText: (
        <>
          <FormattedMessage id='navbar.current-item' />:<FormattedMessage id='navbar.search' />
        </>
      ),
    },
    profile: {
      icon: 'profile',
      path: ['/profile'],
      theme: Themes.LIGHT,
      title: <FormattedMessage id='navbar.profile' />,
      viewBox: '2 0 19 20',
      screenReaderText: (
        <>
          <FormattedMessage id='navbar.current-item' />:<FormattedMessage id='navbar.profile' />
        </>
      ),
    },
    goToCoachingTool: {
      icon: 'coaching-tool',
      onClick: handleAppSwitching(coachingAppSwitchingConfig),
      path: [],
      theme: Themes.LIGHT,
      title: <FormattedMessage id='navbar.go-to-coaching-tool' />,
      className: classnames('hidden', { 'lg:flex': coachingAppSwitchingConfig }),
      screenReaderText: (
        <>
          <FormattedMessage id='navbar.current-item' />:<FormattedMessage id='navbar.go-to-coaching-tool' />
        </>
      ),
    },
    goToCms: {
      icon: 'go-to-cms',
      onClick: handleAppSwitching(cmsAppSwitchingConfig),
      path: [],
      theme: Themes.LIGHT,
      title: <FormattedMessage id='navbar.go-to-cms' />,
      className: classnames('hidden', { 'lg:flex': cmsAppSwitchingConfig }),
      screenReaderText: (
        <>
          <FormattedMessage id='navbar.current-item' />:<FormattedMessage id='navbar.go-to-cms' />
        </>
      ),
    },
    filters: {
      icon: 'filter',
      path: [],
      onClick: handleFilterCategory,
      theme: Themes.LIGHT,
      className: classnames('hidden', { 'lg:flex mt-auto': currentPath === '/' && isCategoriesTypesFiltersEnabled }),
      notificationsBadge: (
        <NotificationsBadge
          open
          amount={amountOfAppliedFilters}
          cy='3'
        />
      ),
      screenReaderText: <FormattedMessage id='comp.help-filters' />,
    },
    info: {
      icon: 'help-circle',
      path: ['/help'],
      theme: Themes.LIGHT,
      className: classnames('hidden lg:flex mb-4', {
        'z-50': showInstallInstructionReminder,
        'mt-auto': currentPath !== '/' || !isCategoriesTypesFiltersEnabled,
      }),
      screenReaderText: <FormattedMessage id='comp.help-icon' />,
      ref: helpIconRef,
    },
  };

  const navigationItems = Object.values(NavigationItems);

  const getActiveIcon = (path: string) => {
    const [, rootPath] = path.split('/');

    const hasPath = (iconPath: string) => (rootPath ? iconPath.includes(rootPath) : iconPath === '/');
    const isActive = (icon: NavigationType) => icon.path.some(hasPath);
    const activeIcon = navigationItems.find(isActive);

    return activeIcon;
  };

  const goTo = (path: string) => () => {
    if ((paths.length === 0 && path === '/path') || (path === '/path' && !pathSet)) {
      history.push('/no-path');
      return;
    }
    history.push(path);
  };

  const handleCollpaseToggle = () => setCollapsed(!isCollapsed);
  const activeIcon = getActiveIcon(currentPath);
  const theme = activeIcon?.theme;
  const isDarkThemeActive = theme === Themes.DARK;
  const accountLogo = isDarkThemeActive ? logoDarkUrl : logoUrl;

  const direction = languageTextDirections[locale];
  const isRTL = direction === 'rtl';
  const shouldRotateCollapseIcon = (!isRTL && !isCollapsed) || (isRTL && isCollapsed);
  const verticalBorderWidth = isRTL ? 'lg:border-l-2' : 'lg:border-r-2';
  const clickableIconClassnames = 'transform opacity-100 transition-all duration-200 ease-in-out cursor-pointer';

  const handleInstallInstructionReminderPopupOnClose = () =>
    dispatch(homeActions.setShowInstallInstructionReminder({ showInstallInstructionReminder: false }));

  return (
    <>
      <div
        className={classnames(
          'flex justify-around items-center border-solid border-t-2 lg:border-t-0 order-2 px-1 py-3.5 lg:py-3',
          { 'z-10': !showInstallInstructionReminder },
          'lg:max-w-xs',
          isDarkThemeActive
            ? 'bg-black text-white text-opacity-75 border-white lg:bg-dark lg:border-t-0'
            : `bg-white text-warmGrey border-whiteFour lg:border-r-2 ${verticalBorderWidth}`,
          className,
          { 'lg:px-6': !isCollapsed },
        )}
        data-testid='BottomNavigation'
        {...rest}
      >
        <button
          className={classnames(
            clickableIconClassnames,
            'my-12 hidden self-start w-full h-12 justify-start items-center lg:flex',
          )}
          onClick={handleCollpaseToggle}
          aria-expanded={!isCollapsed}
        >
          <SvgIcon
            name='double-arrows'
            size={20}
            viewBox='-1 0 20 20'
            className={classnames('w-full lg:w-6', {
              'transform -rotate-180': shouldRotateCollapseIcon,
            })}
          />
          {accountLogo && !isCollapsed && (
            <div className='w-32 h-full mx-6'>
              <Logo
                alt='logo'
                className='w-auto '
                src={`${API_URL}/${accountLogo}`}
              />
            </div>
          )}
        </button>
        <nav className='w-full lg:h-full'>
          <ul className={classnames('flex justify-around lg:h-full lg:flex-col')}>
            {navigationItems.map((item) => {
              if (!hasRequiredFeatures(item.required, features)) {
                return null;
              }

              const isActive = item === activeIcon;
              const viewBox = item.viewBox ?? ` 0 0 ${iconSize} ${iconSize}`;
              const onClick = item.onClick ?? goTo(item.path[0]);

              return (
                <li
                  className={classnames(
                    clickableIconClassnames,
                    'lg:mb-5 flex items-center justify-start select-none lg:w-full',
                    {
                      'text-turquoiseBlue': isActive,
                    },
                    item.className,
                  )}
                  key={`icon-${item.icon}`}
                  onClick={onClick}
                  ref={item.ref}
                >
                  <SvgIcon
                    id={`${item.icon}-bottomBarIcon`}
                    color='currentColor'
                    name={item.icon}
                    size={iconSize}
                    badge={item.notificationsBadge}
                    viewBox={viewBox}
                  />
                  <span className='sr-only'>{item.screenReaderText}</span>
                  {!isCollapsed && (
                    <span className='hidden mx-6 text-sm whitespace-nowrap lg:inline'>{item.title}</span>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      <InstallInstructionReminderPopup
        open={showInstallInstructionReminder}
        onModalClose={handleInstallInstructionReminderPopupOnClose}
        target={helpIconRef}
        placement='top-right'
        className='hidden lg:flex'
      />
    </>
  );
};

export default BottomNavigation;
