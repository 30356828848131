/*
 * Default headers for API calls
 */

import { OAuth2Credentials } from 'constants/global'

function getDefaultHeaders({ type } = { type: '' }) {
  const headers = { 'Content-Type': 'application/json' }

  if (type == null) return headers

  switch (type) {

    case 'basic':
      const b64BasicAuthCredentials = btoa(`${OAuth2Credentials.id}:${OAuth2Credentials.secret}`)

      headers['Content-Type'] = 'application/x-www-form-urlencoded'
      headers['authorization'] = `Basic ${b64BasicAuthCredentials}`
      break
    default:
      break
  }

  return headers
}

export default getDefaultHeaders
