import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { TipsTricks } from 'containers/FormatScreen';
import { LepaTypes } from 'pages/home/Home.types';
import theme from 'theme';
import { ActivePathLepaProps, LegacyLepa, Lepa } from './Lepa.types';

export const isLegacyLearningPath = (lepa: Lepa): lepa is LegacyLepa => lepa.type === LepaTypes.LEGACY;

function isMediaType(value: string | null): value is TipsTricks['mediaData']['mediaType'] {
  return value === 'video' || value === 'media';
}

export const mapLepaToActivePathStructure = (
  lepa: LegacyLepa,
  lepaError: FetchBaseQueryError | SerializedError | undefined,
  openedTipsTricks: number[],
): ActivePathLepaProps => {
  // many information is not available through the endpoint, so we need to fake it or use stuff like empty strings
  const { type, color: lepaColor } = lepa;
  const color = lepaColor ?? theme.colors.turquoiseBlue;
  const { locale } = lepa.content.levels[0];
  const locales = [{ slug: locale, enabled: true, default: true }];
  const error = lepaError ? 'error' : null;
  const lastLevelUnlocked = lepa.content.levels
    .slice()
    .reverse()
    .find((level) => level.progress.unlocked);
  const levels = lepa.content.levels.map((level) => {
    const skills = level.skills.map((skill) => {
      const modules = skill.modules.map((module) => ({
        id: String(module.id),
        title: module.title,
        summary: '',
        unlocked: module.progress.unlocked,
        done: module.progress.done,
        passed: module.progress.passed,
        tags: [],
        percentage: Number.parseFloat(module.progress.percentage),
        formats: [],
      }));

      return {
        color,
        id: String(skill.id),
        title: skill.title,
        summary: skill.summary,
        tags: skill.tags,
        unlocked: skill.progress.unlocked,
        done: skill.progress.done,
        passed: skill.progress.passed,
        testScore: skill.progress.testScore,
        modules,
      };
    });

    const tipsTricks = [...level.tipsTricks]
      .sort((a, b) => a.order - b.order)
      .map((tipTrick) => ({
        id: String(tipTrick.id),
        title: tipTrick.title,
        summary: tipTrick.summary,
        tags: tipTrick.tags ?? [],
        category: '',
        opened: openedTipsTricks.includes(tipTrick.id),
        mediaData: {
          media: tipTrick.media ?? '',
          mediaType: isMediaType(tipTrick.mediaType) ? tipTrick.mediaType : 'media',
        },
        created: '',
        updated: '',
      }));

    return {
      id: String(level.id),
      title: level.title,
      summary: '',
      rank: '',
      color: level.color,
      unlocked: level.progress.unlocked,
      unlockedAt: level.progress.unlockedAt,
      done: level.progress.done,
      passed: level.progress.passed,
      testScore: level.progress.testScore,
      progress: 100,
      popups: [],
      tipsTricks,
      retentionTest: [],
      skills,
    };
  });

  return {
    id: String(lepa.id),
    slug: lepa.title,
    type,
    color,
    levels,
    locale,
    locales,
    error,
    currentLevelIndex: lastLevelUnlocked ? lepa.content.levels.indexOf(lastLevelUnlocked) : 0,
  };
};
