import { MessageDialog } from 'components/message-dialog';
import React from 'react';
import { ChatRoleTypes } from '../../MobieChatPopup.types';
import { Tags } from '../tags/Tags';
import { ChatMessageProps as Props } from './ChatMessage.types';

export const ChatMessage: React.FC<Props> = ({ role, message, tags }) => {
  const isAssistant = role === ChatRoleTypes.ASSISTANT;
  const hasTags = tags && tags.length > 0;
  const shouldShowTags = isAssistant && hasTags;

  return (
    <MessageDialog
      isMyMessage={!isAssistant}
      isLastMessageInGroup={true}
    >
      <>
        {message}
        {shouldShowTags && <Tags tags={tags} />}
      </>
    </MessageDialog>
  );
};
