import React from 'react';
import { BadgeBannerProps as Props } from './types';

const BadgeBanner: React.FC<Props> = ({ text, className }) => (
  <svg
    width={72}
    height={20}
    viewBox='0 0 72 20'
    className={className || ''}
  >
    <path
      fill='#23CAE2'
      d='M68.594,19.248l-1.738-5.126c-0.092-0.269-0.013-0.564,0.201-0.755l4.579-4.162 c0.204-0.186,0.22-0.502,0.034-0.706c-0.072-0.079-0.168-0.133-0.271-0.154C53.195,4.687,41.412,2.857,36.047,2.857 c-5.386,0-17.216,1.833-35.488,5.5l0,0C0.288,8.412,0.113,8.675,0.167,8.946c0.02,0.101,0.071,0.193,0.146,0.264l4.615,4.394 c0.199,0.189,0.271,0.475,0.186,0.733l-1.559,4.856c-0.084,0.264,0.06,0.545,0.323,0.629c0.076,0.025,0.156,0.031,0.235,0.018 l6.735-1.12c0.482-0.08,0.836-0.497,0.836-0.986v-6.305h47.837v6.14c0,0.484,0.349,0.9,0.827,0.984l7.686,1.349 c0.272,0.048,0.531-0.134,0.58-0.405C68.627,19.413,68.621,19.328,68.594,19.248z'
    />
    <path
      fill='#00BBD5'
      d='M60.88,17.57c-10.015-3.025-18.348-4.539-24.998-4.539s-14.983,1.514-24.998,4.539l0,0 c-0.859,0.259-1.785-0.086-2.267-0.843l-3.396-5.344L3.021,7.92C2.429,6.988,2.705,5.752,3.637,5.159 C3.825,5.04,4.031,4.953,4.248,4.901C18.04,1.634,28.585,0,35.882,0s17.842,1.634,31.633,4.901l0,0 c1.075,0.255,1.74,1.333,1.485,2.407c-0.052,0.217-0.138,0.423-0.258,0.611l-5.597,8.808C62.666,17.484,61.738,17.829,60.88,17.57z'
    />
    <path
      id='curve'
      fill='none'
      d='M6.331,12.291c12.902-3.056,22.767-4.584,29.593-4.584c6.827,0,16.691,1.529,29.592,4.584'
    />
    <text
      textAnchor='middle'
      style={{ fontSize: '5px', fill: '#fff' }}
    >
      <textPath
        xlinkHref='#curve'
        startOffset='50%'
      >
        <tspan
          x='0'
          y='0'
        >
          {text}
        </tspan>
      </textPath>
    </text>
  </svg>
);

export default BadgeBanner;
