import { SvgIconProps } from 'components/svg-icon';
import { Locales } from 'constants/global';
import { FormatContentGroup, FormatContentOption } from 'containers/FormatScreen';
import { Locale } from 'containers/language-provider';
import { FullProps } from 'containers/leco-overview';
import { DefaultModalProps, ModalTypes } from 'containers/modal-controller/ModalController.types';
import { Event } from 'pages/event/Event.types';
import React from 'react';

export interface HomeStore {
  paths: Leco[];
  featuredPath: Leco | null;
  categories: string[];
  pathSet: boolean;
  pathID: string;
  error?: Error | null;
  unlockedPaths: { id: number }[];
  unlockedPathsToAnimate: { id: number }[];
  shouldAnimateUnlocks?: boolean;
  showInstallInstructionReminder: boolean;
}

export enum BuiltInPathCategories {
  CONTINUE = 'doing',
  PLAY_AGAIN = 'done',
  NEW = 'new',
  ALSO_INTERESTING = 'also-interesting',
  LINK = 'link',
  COACHING = 'recommended-from-coaching',
}

export enum ContentOrigin {
  LEARNING_PATH = 'learning-path',
  LTI = 'lti',
}

export enum LepaTypes {
  LEGACY = 'legacy',
  CRASH_COURSE = 'crash_course',
  TEXT = 'text',
  VIDEO = 'video',
  QUESTIONS_ONLY = 'questions_only',
  SINGLE_LEARNING = 'single_learning',
}

export enum LecoTypes {
  LEGACY = 'legacy',
  CRASH_COURSE = 'crash_course',
  LINK = 'link',
  LTI = 'lti',
  EVENT = 'event',
  SCORM = 'scorm',
  TEXT = 'text',
  VIDEO = 'video',
  QUESTIONS_ONLY = 'questions_only',
  SINGLE_LEARNING = 'single_learning',
}

export interface Category {
  title: string;
}

export type FormatType =
  | 'text'
  | 'video'
  | 'drag_drop'
  | 'multiple_choice'
  | 'multiple_choice_drag_drop'
  | 'multiple_answer'
  | 'multiple_answer_swipe'
  | 'yes_no'
  | 'ranking_quiz'
  | 'multiple_image_choice'
  | 'multiple_image_answer'
  | 'true_false'
  | 'hotspot'
  | null;

export type Leco =
  | LegacyContentListItem
  | CrashCourseContentListItem
  | EventContentListItem
  | ExternalLecoListItem
  | ScormContentListItem
  | TextContentListItem
  | VideoContentListItem
  | QuestionsOnlyContentListItem
  | SingleLearningContentListItem;

export interface GetUserPathPayload {
  userId: string;
  queryParams: string;
}

export interface GetUserPathsSuccessPayload {
  paths: Leco[];
}

export interface GetFeaturedPathSuccessPayload {
  featuredPath: Leco;
}

export interface Lepa {
  id: string;
  lecoId: number;
  type: LepaTypes;
  levels: LevelData[];
  pathBackground: string;
  retentionEnabled: boolean;
  splashScreen?: string;
  modulesLimit: number;
  weekModulesLimit: number;
  parentID: string | null;
  unlockModules: boolean;
  isCertification: boolean;
  progress: number;
}

export interface GroupsExpression {
  x?: Partial<GroupsExpression> | number;
  y?: Partial<GroupsExpression> | number;
  op: 'gid' | 'or' | 'and' | 'not';
}

export interface BaseLeco {
  id: string;
  title: string;
  estimatedTimeToComplete: number;
  description: string;
  objectives: string;
  publishTime?: string;
  published: boolean;
  image: string;
  targetedUsersCount?: number;
  primaryColor: string;
  playButtonColor: string;
  categories: Category[];
  unlocked: boolean;
  progress: number;
}

interface LockPath {
  id: number;
  title: string;
  type: LepaTypes;
}

interface LepaListItem extends Pick<Lepa, 'id' | 'lecoId' | 'progress' | 'splashScreen' | 'type'> {
  isCertification: boolean;
  lockedBy: LockPath[];
}

export interface LegacyContentListItem extends BaseLeco {
  type: LecoTypes.LEGACY;
  content: LepaListItem;
}
export interface CrashCourseContentListItem extends BaseLeco {
  type: LecoTypes.CRASH_COURSE;
  content: LepaListItem;
}

interface LinkListItem {
  id: string;
  lecoId: number;
  url: string;
  progress: number;
}

type EventListItem = Pick<Event, 'id' | 'lecoId' | 'progress'>;

export interface LinkContentListItem extends BaseLeco {
  type: LecoTypes.LINK;
  content: LinkListItem;
}

export interface LTIContentListItem extends BaseLeco {
  type: LecoTypes.LTI;
  content: LinkListItem;
}

export interface EventContentListItem extends BaseLeco {
  type: LecoTypes.EVENT;
  content: EventListItem;
}

export interface ExternalLecoListItem extends BaseLeco {
  type: LecoTypes.LTI | LecoTypes.LINK;
  content: LinkListItem;
}

export interface ScormContentListItem extends BaseLeco {
  content: ScormListItem;
  type: LecoTypes.SCORM;
}

export interface TextContentListItem extends BaseLeco {
  type: LecoTypes.TEXT;
  content: LepaListItem;
}

export interface VideoContentListItem extends BaseLeco {
  type: LecoTypes.VIDEO;
  content: LepaListItem;
}

export interface QuestionsOnlyContentListItem extends BaseLeco {
  type: LecoTypes.QUESTIONS_ONLY;
  content: LepaListItem;
}

export interface SingleLearningContentListItem extends BaseLeco {
  type: LecoTypes.SINGLE_LEARNING;
  content: LepaListItem;
}

interface ScormListItem {
  id: string;
  lecoId: number;
  progress: number;
  filename: string;
}

export interface PathLocale {
  slug: Locales;
  default: boolean;
}

export interface FormatContent {
  question?: string;
  url?: string;
  imageUrl?: string;
  buttonText?: string;
  rightButtonText?: string;
  wrongButtonText?: string;
  groups: FormatContentGroup[];
  options: FormatContentOption[];
}

export interface FormatData {
  id: string;
  order: number;
  formatType: FormatType;
  title: string | '';
  summary?: string;
  priority: boolean;
  tags: string[];
  positiveTitle?: string;
  positiveText?: string;
  negativeTitle?: string;
  negativeText?: string;
  partialTitle?: string;
  partialText?: string;
  generalFeedbackText?: string;
  content: FormatContent;
  right?: boolean;
}

export interface ModuleData {
  id: string;
  title: string | '';
  order: number;
  tags: string[];
  summary?: string;
  formats: FormatData[];
}

export interface SkillData {
  id: string;
  title: string | '';
  order: number;
  summary?: string;
  tags: string[];
  threshold: number;
  testSize: number;
  modules: ModuleData[];
}

export interface TipData {
  id: string;
  title: string | '';
  summary?: string;
  tags: string[];
  order: number;
  mediaData: {
    mediaType: string | '';
    media: string | null;
  };
}

export interface LevelData {
  id: string;
  title: string | '';
  order: number;
  summary?: string;
  threshold: number;
  testSize: number;
  skills: SkillData[];
  tipsTricks: TipData[];
}

export enum Domains {
  LINK = 'link',
}

export interface Checkpoint {
  id: number;
  learningContentId: number;
  domain: Domains;
  domainId: number;
  createdAt: Date;
  updatedAt: Date;
}

export type PathUnlockedPopupComponentProps = DefaultModalProps;
export interface PathUnlockedModalData {
  type: ModalTypes.PATH_UNLOCKED;
}

export interface PathLockedPopupComponentProps extends DefaultModalProps {
  paths: LockPath[];
}

export interface PathLockedModalData {
  type: ModalTypes.PATH_LOCKED;
  paths: LockPath[];
}

export interface LolecoProps {
  categories: PathCategories;
  onLecoPlay: (leco: Leco) => void;
  onLecoInfoOpen: (leco: Leco, e: React.MouseEvent) => void;
  locale: Locale;
}

export type CheckpointPayload = Pick<Checkpoint, 'learningContentId' | 'domain' | 'domainId'>;

export type PathCategories = { [categories in BuiltInPathCategories]: Leco[] } & { [customCategory: string]: Leco[] };
export interface CardConfiguration {
  Component: React.ComponentType<FullProps>;
  getIcon: (leco: Leco, svgIconProps?: Partial<SvgIconProps>) => React.ReactNode;
  play: (leco: Leco) => void;
  openInfo: (lecoId: Leco) => void;
  canOpen: (leco: Leco) => boolean;
}

export interface GetCategoriesPayload {
  userId: number | string;
  accountId: number | string;
  locale?: Locale;
}

interface CategoryItem {
  id: number;
  title: string;
}

export type GetCategoriesResponse = CategoryItem[];

export interface SetShowInstallInstructionReminderPayload {
  showInstallInstructionReminder: boolean;
}
