import classnames from 'classnames';
import React from 'react';
import { SwipeArrowTypes as Props } from './SwipeArrow.types';

export const SwipeArrow: React.FC<Props> = ({ direction, color, withBorder = false, onClick, className = '' }) => (
  <div
    className={classnames(
      'absolute w-0 h-0 border-t-20 border-r-20 border-b-20 border-transparent top-1/2',
      {
        '-rotate-90': direction === 'up',
        'rotate-180': direction === 'right',
        'rotate-270': direction === 'down',
      },
      className,
    )}
    style={{
      borderRightColor: color,
      transformStyle: 'preserve-3d',
    }}
    {...(onClick && { onClick })}
  >
    {withBorder && (
      <div className='absolute border-t-24 border-r-24 border-b-24 border-transparent border-r-color-[white] translate-3d-[-9.6%,-50%,-1px]' />
    )}
  </div>
);
