import classnames from 'classnames';
import Question from 'components/Question';
import { Button } from 'components/button';
import { ImageFormat, VideoFormat } from 'containers/FormatScreen/Formats';
import { FormatContentOption } from 'containers/FormatScreen/types';
import React from 'react';
import theme from 'theme';
import { isHTML } from 'util/isHTML';
import { AnswerVariant, YesOrNoProps } from './types';

type Callback = (selectedAnswer: string, event: React.MouseEvent<HTMLElement>) => void;
const createOnClick = (fn: Callback, value: string) => (event: React.MouseEvent<HTMLElement>) => fn(value, event);

function isPredefinedValue(value: string): value is AnswerVariant {
  return ['yes', 'no', 'true', 'false'].includes(value);
}

/** Returns a translated value if the value is empty according to the format's old false and right position */
// eslint-disable-next-line @typescript-eslint/ban-types
export const internationalizeValue = (value: string, IntlFormat: Function, fallback: AnswerVariant) => {
  const _value = value.toLocaleLowerCase();

  if (isPredefinedValue(_value)) {
    return IntlFormat({ id: `formats.rightwrong.${_value}` });
  }
  // Support old empty labels for yes/no
  if (fallback === 'yes' && !_value) {
    return IntlFormat({ id: 'formats.rightwrong.yes' });
  }
  if (fallback === 'no' && !_value) {
    return IntlFormat({ id: 'formats.rightwrong.no' });
  }

  return value;
};

export const YesOrNo: React.FC<YesOrNoProps> = ({
  selected,
  showAnswers,
  correct,
  question,
  answers,
  imgSrc,
  video,
  onChange,
  focusColor: propsFocusColor,
  IntlFormatFn,
  ...rest
}) => {
  const [firstAnswer, secondAnswer] = answers;
  const correctAnswer = correct && correct[0] === String(selected);

  const renderAnswer = (answer: FormatContentOption, fallbackValue: AnswerVariant) => {
    const isFocused = String(selected) === answer.id;
    const hasFeedback = showAnswers && isFocused;
    const feedbackColor = correctAnswer ? 'bg-yellowGreen text-white' : 'bg-fullRed text-white';
    const feedbackClassName = hasFeedback ? feedbackColor : '';
    const focusColor = hasFeedback ? theme.colors.white : propsFocusColor;
    return (
      <Button
        variant={showAnswers ? 'contained' : 'outlined'}
        focus={isFocused}
        onClick={createOnClick(onChange, answer.id)}
        focusColor={focusColor}
        className={classnames('w-14 h-14 text-black', {
          'border-light': !showAnswers,
          'bg-light': showAnswers && !isFocused,
          [feedbackClassName]: Boolean(feedbackClassName),
        })}
        round
        shrinkToFit
      >
        {internationalizeValue(answer.label, IntlFormatFn, fallbackValue)}
      </Button>
    );
  };

  return (
    <div
      className='w-full'
      {...rest}
    >
      {video && (
        <div className='my-4'>
          <VideoFormat url={video} />
        </div>
      )}
      {imgSrc && !video && (
        <div className='my-4'>
          <ImageFormat imgSrc={imgSrc} />
        </div>
      )}

      <Question
        text={question}
        rich={isHTML(question)}
        extended
      />

      <div className='relative flex justify-center bottom-12'>
        {renderAnswer(firstAnswer, 'yes')}
        <div className='mr-7'></div>
        {renderAnswer(secondAnswer, 'no')}
      </div>
    </div>
  );
};
