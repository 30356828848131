import { USER_ROLES } from 'constants/global';
import { getRoleAccessLevel } from 'containers/authentication';
import { GetWalkthroughsResponse, WalkthroughVariantTypes, walkthroughVariants } from './Walkthrough.types';

export const isUserWithWalkthroughAccess = (role: string) => getRoleAccessLevel(role) > USER_ROLES.PREVIEW;

export const shouldShowWalkthrough = (
  completedWalkthroughs: GetWalkthroughsResponse,
  variant: WalkthroughVariantTypes,
) => {
  if (!completedWalkthroughs[variant]) {
    return true;
  }
  const { showAfter } = completedWalkthroughs[variant];
  if (!showAfter) {
    return false;
  }
  return new Date(showAfter) < new Date();
};

export const completedWalkthroughsFallback = walkthroughVariants.reduce((acc, curr) => {
  acc[curr] = {
    showAfter: null,
    xpEarned: 0,
  };
  return acc;
}, {} as GetWalkthroughsResponse);
