import { animated, config, useSpring } from 'react-spring';

export const StarStruck = () => {
  const styles = useSpring({
    loop: true,
    from: { transform: 'translateY(-8px) scale(0.5)' },
    to: { transform: 'translateY(0) scale(1)' },
    config: config.molasses,
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={92}
      height={92}
      fill='none'
      viewBox='0 0 92 92'
    >
      <circle
        cx={45.748}
        cy={45.748}
        r={45.748}
        fill='#FFD23B'
      />
      <path
        d='M45.748 91.497c25.267 0 45.749-20.482 45.749-45.748C91.497 20.482 71.014 0 45.748 0v91.497z'
        fill='#FFB24A'
      />
      <path
        d='M18.763 45.748c0 14.805 12.042 26.806 26.896 26.806 14.853 0 26.895-12.002 26.895-26.806h-53.79z'
        fill='#962B7C'
      />
      <path
        d='M18.764 45.748c0 14.805 12.041 26.806 26.895 26.806V45.748H18.764z'
        fill='#C22C7B'
      />
      <path
        d='M18.763 45.748h53.79c0 2.476-.336 4.873-.966 7.148H19.73a26.749 26.749 0 01-.967-7.148z'
        fill='#fff'
      />
      <path
        d='M45.748 45.748h26.806c0 2.476-.337 4.873-.967 7.148h-25.84v-7.148z'
        fill='#EEE'
      />
      <animated.g style={{ ...styles, transformOrigin: '50% ' }}>
        <path
          fill='#FF961E'
          d='M18.203 7.412l9.5 7.258 11.947-.37-3.966 11.277 4.043 11.25-11.95-.289-9.45 7.322-3.419-11.455-9.883-6.724 9.838-6.791 3.34-11.478z'
        ></path>
        <path
          fill='#F28E1C'
          d='M73.06 7.412l-9.5 7.258-11.947-.37 3.966 11.277-4.043 11.25 11.95-.289 9.45 7.322 3.418-11.455 9.884-6.724L76.4 18.89 73.06 7.412z'
        ></path>
      </animated.g>
    </svg>
  );
};
