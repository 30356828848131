import classnames from 'classnames';
import { Typography } from 'components/typography';
import React, { useCallback, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { ReplayFrameContent } from './ReplayFrameContent';
import VideoControls from './VideoControls';
import './VideoFormat.css';
import { VideoFormatProps, VideoStatus } from './types';

export const VideoFormat: React.FC<VideoFormatProps> = ({
  vars = {
    autoplay: 0,
    modestbranding: 1,
    rel: 0,
    playsinline: 0, // important property for iOS to play fullscreen correctly
  },
  interactive = false,
  url,
  summary,
  isFullscreen = false,
  onNavigateAway,
}) => {
  const [playerStatus, setPlayerStatus] = useState<VideoStatus>(vars.autoplay ? 'play' : 'pause');
  const [showReplayScreen, setShowReplayScreen] = useState(false);
  const player = useRef<ReactPlayer>(null);

  const resetPlayer = useCallback(() => {
    setPlayerStatus(vars.autoplay ? 'play' : 'pause');
    setShowReplayScreen(false);
  }, [vars.autoplay]);

  React.useEffect(() => {
    if (!url) {
      return;
    }
    resetPlayer();
  }, [resetPlayer, url]);

  const handlePause = () => {
    setPlayerStatus('pause');
    setShowReplayScreen(false);
  };

  const handlePlay = () => {
    setPlayerStatus('play');
    setShowReplayScreen(false);
  };

  /** replays the video from the start */
  const handleReplay = () => {
    setShowReplayScreen(false);
    handlePlay();
    player.current?.seekTo(0);
  };

  const handleEnd = () => {
    setPlayerStatus('pause');
    setShowReplayScreen(true);
  };

  if (!url) {
    return null;
  }

  const showReplayFrame = showReplayScreen && playerStatus === 'pause';

  return (
    <div
      className='flex flex-col w-full'
      data-testid='video-format'
    >
      <div
        className={classnames('relative overflow-hidden pt-0', {
          'w-screen lg:w-[calc(100vw-54px)] h-[calc(100vh-54px)] lg:h-screen': isFullscreen,
          'hidden': showReplayScreen,
        })}
      >
        {!showReplayFrame && (
          <ReactPlayer
            id='video-format-player'
            ref={player}
            className={classnames({
              'relative pt-0 overflow-hidden pb-[56.25%]': !isFullscreen,
              'object-contain': isFullscreen,
            })}
            url={url}
            width='100%'
            height='100%'
            playing={playerStatus === 'play'}
            config={{
              youtube: { playerVars: { ...vars } },
            }}
            controls
            onPlay={handlePlay}
            onEnded={handleEnd}
          />
        )}
      </div>

      {showReplayFrame && (
        <>
          {isFullscreen ? (
            <div className='w-full lg:w-[calc(100vw-54px)] h-[calc(100vh-54px)] lg:h-screen flex items-center justify-center'>
              <ReplayFrameContent
                onReplay={handleReplay}
                onClose={onNavigateAway}
              />
            </div>
          ) : (
            <ReplayFrameContent onReplay={handleReplay} />
          )}
        </>
      )}

      {interactive && (
        <VideoControls
          status={playerStatus}
          onPause={handlePause}
          onPlay={handlePlay}
        />
      )}

      {summary && (
        <div className='h-2/10 flex-grow text-base leading-4 mb-6 text-greyishBrown break-words p-2.5 pb-0'>
          <Typography rich>{summary}</Typography>
        </div>
      )}
    </div>
  );
};
