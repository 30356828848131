import React from 'react';
import { animated, useSpring } from 'react-spring';

interface ChestProps {
  open?: boolean;
  delay: number;
}

export const Chest: React.FC<ChestProps> = ({ open = false, delay }) => {
  const openLidStyles = useSpring({
    from: { transform: 'translateY(20px) scaleY(1.5)' },
    to: { transform: open ? 'translateY(0px) scaleY(1)' : 'translateY(20px) scaleY(1.5)' },
    delay,
  });
  const { fill: fillLeft } = useSpring({
    from: { fill: '#E3921B' },
    fill: open ? '#D2AB14' : '#E3921B',
    delay,
  });
  const { fill: fillRight } = useSpring({
    from: { fill: '#F29C1F' },
    fill: open ? '#F0C419' : '#F29C1F',
    delay,
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='108'
      height='108'
      fill='none'
      viewBox='0 0 108 108'
    >
      <animated.g
        id='lid'
        style={openLidStyles}
      >
        <path
          fill='#805333'
          d='M108 20.482v1.863H0v-1.863A20.47 20.47 0 0120.482 0h67.036A20.469 20.469 0 01108 20.482z'
        ></path>
        <path
          fill='#49301E'
          d='M13.034 52.138L0 22.345l53.937-2.793L108 22.345 94.966 52.138H13.035z'
        ></path>
        <animated.path
          fill={fillRight}
          d='M31.655 0c-7.802 0-14.654 2.495-18.64 7.448a16.117 16.117 0 00-3.37 7.449 19.95 19.95 0 00-.335 3.724v3.724H0v-1.863A20.47 20.47 0 0120.482 0h11.174zM108 20.482v1.863h-9.31V18.62a19.95 19.95 0 00-.335-3.724 16.117 16.117 0 00-3.37-7.45C90.999 2.496 84.147 0 76.345 0h11.173A20.468 20.468 0 01108 20.482z'
        ></animated.path>
        <path
          fill='#805333'
          d='M98.69 18.621v3.724H9.31V18.62c-.005-1.25.107-2.497.336-3.724h88.709c.228 1.227.34 2.475.335 3.724z'
        ></path>
        <path
          fill='#2E1E13'
          d='M.21 22.345l13.035 29.793h40.903V19.552L.21 22.345z'
        ></path>
        <path
          fill='#805333'
          d='M98.355 14.897H9.645a16.116 16.116 0 013.37-7.45h81.97a16.117 16.117 0 013.37 7.45z'
        ></path>
        <path
          fill='#6A452A'
          d='M.21 22.345h54V0H20.694A20.47 20.47 0 00.211 20.482v1.863z'
        ></path>
        <path
          fill='#6A452A'
          d='M.21 22.345h54V0H20.694A20.47 20.47 0 00.211 20.482v1.863z'
        ></path>
        <path
          fill='#804537'
          d='M67.71 37.353l.02-.075.204.131-.223-.056z'
        ></path>
        <animated.path
          fill={fillLeft}
          d='M31.866 0c-7.802 0-14.654 2.495-18.64 7.448a16.118 16.118 0 00-3.37 7.449 19.95 19.95 0 00-.335 3.724v3.724H.211v-1.863A20.47 20.47 0 0120.693 0h11.173z'
        ></animated.path>
        <path
          fill='#6A452A'
          d='M9.521 22.345h44.585v-7.448H9.856a19.95 19.95 0 00-.335 3.724v3.724z'
        ></path>
        <path
          fill='#6A452A'
          d='M54.105 14.897H9.856a16.117 16.117 0 013.37-7.45h40.88v7.45z'
        ></path>
      </animated.g>
      <g id='container'>
        <path
          fill='#F29C1F'
          d='M65.172 52.138h38.843a3.735 3.735 0 013.725 3.966l-3.241 48.414a3.725 3.725 0 01-3.705 3.482H7.207a3.724 3.724 0 01-3.706-3.482L.261 56.104a3.736 3.736 0 013.724-3.967h61.187z'
        ></path>
        <path
          fill='#804537'
          d='M67.5 37.353l.019-.075.204.131-.223-.056z'
        ></path>
        <path
          fill='#2C2F38'
          d='M57.724 63.31a3.725 3.725 0 11-7.45 0 3.725 3.725 0 017.45 0z'
        ></path>
        <path
          fill='#F0C419'
          d='M42.828 52.138v14.896c0 6.171 5.001 11.173 11.172 11.173 6.17 0 11.172-5.002 11.172-11.172V52.137H42.828zM54 67.034a3.725 3.725 0 113.724-3.724A3.736 3.736 0 0154 67.034z'
        ></path>
        <path
          fill='#805333'
          d='M45.695 74.482H12.681l-.615-9.18a1.864 1.864 0 011.862-1.992h28.9v3.724a11.04 11.04 0 002.867 7.448zM95.32 74.482l-.746 11.174H13.426l-.745-11.174h33.014a11.12 11.12 0 0016.61 0h33.014zM94.574 85.656l-.633 9.44a1.862 1.862 0 01-1.862 1.732H15.921a1.862 1.862 0 01-1.862-1.732l-.633-9.44h81.148zM95.934 65.302l-.615 9.18H62.305a11.04 11.04 0 002.867-7.448V63.31h28.9a1.864 1.864 0 011.862 1.992z'
        ></path>
        <path
          fill='#2C2F38'
          d='M54 72.621a1.862 1.862 0 01-1.862-1.862v-3.725a1.862 1.862 0 013.724 0v3.725A1.862 1.862 0 0154 72.62z'
        ></path>
        <path
          fill='#E3921B'
          d='M7.418 108H54.21V52.138H4.196A3.736 3.736 0 00.47 56.104l3.241 48.414A3.724 3.724 0 007.418 108z'
        ></path>
        <path
          fill='#D2AB14'
          d='M43.039 67.034V52.138H54.21V78.207c-6.17 0-11.172-5.002-11.172-11.172z'
        ></path>
        <path
          fill='#6A452A'
          fillRule='evenodd'
          d='M12.892 74.482l-.615-9.18a1.864 1.864 0 011.862-1.992h28.9v3.724a11.04 11.04 0 002.867 7.448 11.119 11.119 0 008.305 3.725V96.828H16.13a1.862 1.862 0 01-1.862-1.732l-.632-9.44-.745-11.174z'
          clipRule='evenodd'
        ></path>
        <path
          fill='#2C2F38'
          d='M52.349 70.759c0 1.028.834 1.862 1.862 1.862v-7.449a1.862 1.862 0 00-1.862 1.862v3.725z'
        ></path>
        <path
          fill='#2C2F38'
          d='M50.487 63.31a3.725 3.725 0 003.724 3.724v-7.448a3.724 3.724 0 00-3.724 3.724z'
        ></path>
      </g>
    </svg>
  );
};
