import { FormatScreenStore } from 'containers/FormatScreen/types';
import { ProfileStore } from 'containers/Profile/types';
import { AppStore } from 'containers/app/App.types';
import { AuthHistoryStateParams, AuthStore } from 'containers/authentication';
import { ModalsStore } from 'containers/modal-controller/ModalController.types';
import { SettingsStore } from 'containers/settings/Settings.types';
import { NewsfeedStore } from 'pages/community/newsfeed/Newsfeed.types';
import { CrashCourseStore } from 'pages/crash-course/CrashCourse.types';
import { EventStore } from 'pages/event/Event.types';
import { HelpStore } from 'pages/help';
import { HomeStore } from 'pages/home/Home.types';
import { ActiveLearningPathStore } from 'pages/lepa';
import { LTIState } from 'pages/lti/LTI.types';
import { PersonalityTestState } from 'pages/personality-test/PersonalityTest.types';
import { RegisterStore } from 'pages/register/Register.types';
import { SearchStore } from 'pages/search/Search.types';
import { SkillStore } from 'pages/skill/Skill.types';
import { Action as ReduxAction } from 'redux';
import { FeatureFlagsStore } from './feature-flags';
import { PendingRequestsStore } from './pending-requests';

export type HistoryStateParams = AuthHistoryStateParams;

export interface Store {
  auth: AuthStore;
  profile: ProfileStore;
  home: HomeStore;
  activePath: ActiveLearningPathStore;
  crashCourse: CrashCourseStore;
  skill: SkillStore;
  register: RegisterStore;
  settings: SettingsStore;
  formats: FormatScreenStore;
  help: HelpStore;
  app: AppStore;
  newsfeed: NewsfeedStore;
  modals: ModalsStore;
  personalityTest: PersonalityTestState;
  lti: LTIState;
  search: SearchStore;
  featureFlags: FeatureFlagsStore;
  pendingRequests: PendingRequestsStore;
  activeEvent: EventStore;
}

export type Action<PayloadType> = {
  type: ReduxAction;
  payload: PayloadType;
};

export enum RequestStates {
  IDLE = 'idle',
  LOADING = 'loading',
  FAILED = 'failed',
  SUCCESS = 'success',
}
