import React from 'react';
import { LearningPathVariantProps } from '../types';
import Module from './Module';

const createOnClick =
  (fn: (value: any, event: React.MouseEvent<HTMLElement>) => void, value: any) =>
  (event: React.MouseEvent<HTMLElement>) =>
    fn(value, event);

export const StraightPath: React.FC<LearningPathVariantProps> = ({
  reverse,
  width = 250,
  primaryColor,
  secondaryColor,
  lockedColor,
  pathThickness,
  unlocked,
  percentage,
  skill,
  bounce,
  initial,
  nodeSize = 40,
  onNodeClick,
  ...props
}) => {
  const viewbox = {
    width: 250,
    height: skill ? 300 : 100,
  };

  const height = width * (viewbox.height / viewbox.width);

  // Move To (x, y) = (width / 2, 0) and Line to (x, y) = (width / 2, height)
  // this creates a vertical line horizontal centered within the svg of size width * height
  const path = `M${width / 2} 0 L${width / 2} ${height} Z`;
  return (
    <>
      <svg
        x='0px'
        y='0px'
        width={width}
        height={height}
        viewBox={`0 0 ${viewbox.width} ${viewbox.height}`}
        {...props}
        style={{ display: 'block' }}
        opacity={unlocked ? '1' : '0.3'}
      >
        <path
          fill='none'
          stroke={primaryColor}
          strokeWidth={pathThickness}
          d={path}
        />
      </svg>

      {!!skill && (
        <div className='absolute w-full h-full -top-5'>
          {/* by using createOnClick we prevent adding unnecessary event listeners */}
          <div
            data-testid='skill-container'
            className='flex flex-col items-center justify-center w-full h-full cursor-pointer flex-nowrap'
            onClick={createOnClick(onNodeClick, skill.id)}
          >
            {skill.modules
              .map((module, index) => (
                <Module
                  variant='straight'
                  key={module.id}
                  primaryColor={primaryColor}
                  lockedColor={lockedColor}
                  nodeSize={nodeSize}
                  module={module}
                  pathThickness={pathThickness}
                  isOdd={index % 2 === 1}
                />
              ))
              .reverse()}
          </div>
        </div>
      )}
    </>
  );
};

export default StraightPath;
