import { Button } from '@mobietrain/ui';
import classnames from 'classnames';
import { LinearProgressionBar } from 'components/ProgressionBar';
import { Logo } from 'components/logo';
import { Media } from 'components/media';
import { SvgIcon } from 'components/svg-icon';
import { API_URL } from 'constants/endpoints';
import { languageTextDirections } from 'containers/language-provider';
import { LecoTypes } from 'pages/home/Home.types';
import { getLecoAvatar } from 'pages/home/helpers';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import theme from 'theme';
import tinycolor from 'tinycolor2';
import { getSrcset } from 'util/image';
import { FullProps as Props } from './LecoOverview.types';

export const LecoOverview: React.FC<Props> = ({
  leco,
  accountLogo,
  playButtonColor = theme.colors.orangeRed,
  contentClassName,
  onClick,
  onLecoInfoOpen,
  locale,
}) => {
  const { avatarUrl, external } = getLecoAvatar(leco);

  const lecosWithProgress = [LecoTypes.EVENT, LecoTypes.LEGACY, LecoTypes.CRASH_COURSE, LecoTypes.SCORM];
  const progress = lecosWithProgress.includes(leco.type) ? leco.progress : 0;

  const svgIconColor = tinycolor
    .mostReadable(playButtonColor, ['#FFFFFF', '#222222'], { includeFallbackColors: true })
    .toString();

  const direction = languageTextDirections[locale];
  const isLTR = direction === 'ltr';

  const handleLecoInfoOpen = (event: React.MouseEvent) => {
    if (!onLecoInfoOpen) {
      return;
    }
    onLecoInfoOpen(leco, event);
  };

  return (
    <>
      <div
        className='absolute z-0 w-full h-140 lg:min-h-4/10'
        data-testid='featured-path'
      >
        <div className='relative block w-full h-full m-0'>
          <Media
            className='w-full'
            alt=''
            data-testid='full-path-avatar-logo'
            src={avatarUrl}
            external={external}
            sizes='100vw'
            srcSet={getSrcset(avatarUrl ?? '', { sizes: 'fullscreen' })}
          />
          <div
            className='absolute bottom-0 z-30 block w-full h-full'
            // tailwind does not allow opacity in linear-gradient
            style={{
              backgroundImage:
                'linear-gradient(to top right, black 1%, rgba(0, 0, 0, 0) 60%), linear-gradient(to top, black, rgba(0, 0, 0, 0) 60%)',
            }}
          />
        </div>
      </div>
      <div
        data-testid='learning-path'
        className={classnames('lg:h-140 lg:mt-14', {
          'lg:pl-16': isLTR,
          'lg:pr-16': !isLTR,
          'opacity-100': leco.unlocked,
          'opacity-50': !leco.unlocked,
        })}
      >
        <div
          className={classnames(
            contentClassName,
            'relative flex flex-col items-center lg:justify-center lg:items-start h-full w-full z-0',
            {
              'h-full': !avatarUrl,
            },
          )}
        >
          <div className='flex items-center justify-center w-full h-full mb-24 mt-14 lg:hidden'>
            {accountLogo && (
              <div className='flex items-center justify-center object-cover w-32 h-14'>
                <Logo
                  alt='account-logo'
                  component='img'
                  className='max-w-full max-h-full'
                  data-testid='full-path-client-logo'
                  src={`${API_URL}/${String(accountLogo)}`}
                />
              </div>
            )}
          </div>
          <div id='featured-path'>
            <div className='mb-10 text-2xl font-bold text-center text-white lg:text-4xl lg:text-left w-60 lg:w-80'>
              <h1 className='w-full filter drop-shadow-2xl'>{leco.title}</h1>
            </div>
            <div className='flex justify-around gap-4 mb-2 w-60 lg:w-96'>
              <Button
                variant='contained'
                className='py-1 w-28 lg:w-48'
                color={playButtonColor}
                onClick={onClick}
                fullWidth
                centered
              >
                <SvgIcon
                  data-testid='play-button'
                  className='relative top-1 mb-0.5'
                  name='play-outlined'
                  color={svgIconColor}
                  size={15}
                  viewBox='0 0 140 140'
                />
                <span className='sr-only'>
                  <FormattedMessage id='comp.play-course' />
                </span>
              </Button>

              <Button
                variant='contained'
                className='py-1 w-28 lg:w-48'
                textColor='black'
                color='white'
                onClick={handleLecoInfoOpen}
                fullWidth
                centered
              >
                <SvgIcon
                  className='relative top-1'
                  name='information'
                  color='black'
                  size={18}
                >
                  <span className='sr-only'>
                    <FormattedMessage id='comp.course-information' />
                  </span>
                </SvgIcon>
              </Button>
            </div>
          </div>
          <div className='flex flex-col items-center w-60 lg:w-96'>
            <div className='w-full mt-3 mb-2'>
              <LinearProgressionBar
                percentage={progress}
                className='h-0.5'
                color={playButtonColor}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
