import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { LepaTypes } from 'pages/home/Home.types';
import { Lepa } from 'pages/lepa';
import { IntlShape } from 'react-intl';

const unlockedFinalTestTipData = (levelId: number) => ({
  modalType: ModalTypes.UNLOCKED_FINAL_TEST,
  variant: 'unlockedFinalTest',
  contentType: 'unlockedFinalTest',
  summary: '',
  levelID: String(levelId),
});

const skillTestTipData = ({
  intl,
  passed,
  skillId,
  levelId,
  threshold,
}: {
  intl: IntlShape;
  passed: boolean;
  skillId: number;
  levelId: number;
  threshold: number;
}) => {
  if (!passed) {
    return {
      modalType: ModalTypes.SKILL_FAILED,
      variant: 'skillFail',
      buttonText: intl.formatMessage({ id: 'comp.popup.button.tryAgain' }),
      summary: intl.formatMessage({ id: 'comp.popup.skillFail' }, { threshold }),
      title: intl.formatMessage({ id: 'comp.popup.title.almost' }),
      skillID: String(skillId),
      levelID: String(levelId),
    };
  }
  return {
    modalType: ModalTypes.SKILL_PASSED,
    variant: 'skillPassed',
    buttonText: intl.formatMessage({ id: 'formats.button.thanks' }),
    summary: intl.formatMessage({ id: 'comp.popup.skillPassed' }),
    title: intl.formatMessage({ id: 'comp.popup.title.awesome' }),
    skillID: String(skillId),
    levelID: String(levelId),
  };
};

export const getSkillTestTipData = ({
  intl,
  lepa,
  levelId,
  skillId,
  passed,
  threshold,
}: {
  intl: IntlShape;
  lepa: Lepa;
  levelId: number;
  skillId: number;
  passed: boolean;
  threshold: number;
}) => {
  const isLegacy = lepa.type === LepaTypes.LEGACY;
  const isCrashCourse = lepa.type === LepaTypes.CRASH_COURSE;

  if (!isLegacy && !isCrashCourse) {
    throw new Error('Unable to evaluate skill test tip data');
  }

  const isLastSkillInLastLevelOfLegacyPath =
    isLegacy && lepa.content.levels.slice(-1)[0]?.skills.slice(-1)[0]?.id === skillId;

  if ((isCrashCourse || isLastSkillInLastLevelOfLegacyPath) && passed) {
    return unlockedFinalTestTipData(levelId);
  }

  return skillTestTipData({
    intl,
    levelId,
    skillId,
    passed,
    threshold,
  });
};

const levelTestTipData = ({
  intl,
  passed,
  levelId,
  threshold,
  nextLevelTitle,
}: {
  intl: IntlShape;
  passed: boolean;
  levelId: number;
  threshold: number;
  nextLevelTitle: string;
}) => {
  if (!passed) {
    return {
      modalType: ModalTypes.LEVEL_FAILED,
      variant: 'levelFail',
      contentType: 'levelFailed',
      buttonText: intl.formatMessage({ id: 'comp.popup.button.tryAgain' }),
      summary: intl.formatMessage({ id: 'comp.popup.levelFail' }, { threshold }),
      title: intl.formatMessage({ id: 'comp.popup.title.almost' }),
      levelTestTitle: nextLevelTitle,
      levelID: String(levelId),
    };
  }
  return {
    modalType: ModalTypes.LEVEL_PASSED,
    variant: 'gradientFullscreen',
    contentType: 'levelPassed',
    buttonText: '',
    summary: '',
    title: '',
    levelTestTitle: nextLevelTitle,
    levelID: levelId,
  };
};

export const getLevelTestTipData = ({
  intl,
  lepa,
  levelId,
  passed,
  threshold,
}: {
  intl: IntlShape;
  lepa: Lepa;
  levelId: number;
  passed: boolean;
  threshold: number;
}) => {
  if (lepa.type !== LepaTypes.LEGACY) {
    throw new Error('Unable to evaluate level test tip data');
  }
  const lastLevel = lepa.content.levels[lepa.content.levels.length - 1];
  const isLastLevel = lastLevel.id === levelId;
  if ((!isLastLevel && passed) || !passed) {
    const currentLevelIndex = lepa.content.levels.findIndex((level) => level.id === levelId);
    const nextLevel = lepa.content.levels[currentLevelIndex + 1];
    const nextLevelTitle = nextLevel?.title ?? '';
    return levelTestTipData({
      intl,
      passed,
      levelId,
      threshold,
      nextLevelTitle,
    });
  }
  return null;
};
