import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import { MobieTrainUI } from '@mobietrain/ui';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ErrorMonitor from 'components/ErrorMonitor';
import ScreenLock from 'components/ScreenLock';
import { ConnectedRouter } from 'connected-react-router';
import { MatomoRoutingAnalyticsTracker, matomoInstance } from 'containers/Analytics/matomo';
import { App } from 'containers/app';
import { LanguageProvider } from 'containers/language-provider';
import { worker } from 'mocks/setup-worker';
import ReactDOM from 'react-dom';
import 'react-image-crop/src/ReactCrop.scss';
import { Provider } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import store, { history } from 'store';
import 'styles/app.scss';
import browserDetect from 'util/browserDetect/browserDetect';
import { isFirefoxBrowser, isSafariBrowser } from 'util/platforms';

import 'stream-chat-react/dist/css/v2/index.css';
import { UserNavigationTracker } from './containers/Analytics/internal';
import { ChatProvider } from './pages/community/chat/ChatContext';
import * as serviceWorker from './serviceWorker';

browserDetect();

const ENV = process.env.REACT_APP_NODE_ENV;
// eslint-disable-next-line no-underscore-dangle
const [__PROD__, __DEV__] = [ENV === 'production', ENV === 'development'];

const API_URL = process.env.REACT_APP_API_URL;

if (__PROD__ && API_URL) {
  Sentry.init({
    dsn: 'https://7aff81f777954654890b7518036b0ffa@o646067.ingest.sentry.io/5762897',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [new URL(API_URL).hostname],
      }),
    ],
    tracesSampleRate: 0.07,
    environment: process.env.REACT_APP_NODE_ENV,
  });
}

if (__DEV__) {
  void worker.start({
    onUnhandledRequest: 'bypass',
    serviceWorker: {
      url: '/mockServiceWorkerBypass.js',
    },
  });
}

function render(Component: RouteProps['component']) {
  ReactDOM.render(
    <ErrorMonitor>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <LanguageProvider>
            <ScreenLock>
              <MatomoProvider value={matomoInstance}>
                <MatomoRoutingAnalyticsTracker>
                  <UserNavigationTracker>
                    <MobieTrainUI layout='app'>
                      <ChatProvider>
                        <Route
                          path='/'
                          component={Component}
                        />
                      </ChatProvider>
                    </MobieTrainUI>
                  </UserNavigationTracker>
                </MatomoRoutingAnalyticsTracker>
              </MatomoProvider>
            </ScreenLock>
          </LanguageProvider>
        </ConnectedRouter>
      </Provider>
    </ErrorMonitor>,
    document.getElementById('root'),
  );
}

render(App);

const isUsingSafariBrowser = isSafariBrowser();
const isUsingFirefoxBrowser = isFirefoxBrowser();
const shouldUnregisterServiceWorker = !__DEV__ || (!isUsingSafariBrowser && !isUsingFirefoxBrowser);
if (shouldUnregisterServiceWorker) {
  serviceWorker.unregister();
}
