import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConnectionOpen, StreamChat } from 'stream-chat';
import { RootState } from '../../../../store';
import { doNothing } from '../../../../util/doNothing';
import { useGetChatTokenMutation } from '../Chat.api';
import { UseClientOptions } from '../Chat.types';

export const useStreamClient = ({ apiKey, isChatPluginEnabled }: UseClientOptions) => {
  const { user, account } = useSelector((state: RootState) => state.profile);
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const [unreadCount, setUnreadCount] = useState(0);

  const { id } = user;
  const name = `${user.firstName} ${user.lastName}`;
  const image = user.avatar;

  const [getChatToken] = useGetChatTokenMutation();

  useEffect(() => {
    if (!chatClient) {
      return;
    }

    chatClient.on((event) => {
      const { total_unread_count: totalUnreadCount = 0, type } = event;

      const isOwnMessageReadEvent = event?.user?.id === id;
      const hasReadEvent = type === 'notification.mark_read' && isOwnMessageReadEvent;
      if (hasReadEvent) {
        setUnreadCount(totalUnreadCount);
        return;
      }

      if (totalUnreadCount !== null && totalUnreadCount > 0) {
        setUnreadCount(totalUnreadCount);
      }
    });
  }, [chatClient, id]);

  useEffect(() => {
    if (!apiKey || !id || !isChatPluginEnabled || !chatClient) {
      return doNothing();
    }

    const updateUser = async () => {
      await chatClient.partialUpdateUser({
        id,
        set: {
          name,
          image,
        },
      });
    };

    void (async () => {
      await updateUser();
    })();

    return doNothing();
  }, [apiKey, chatClient, id, image, isChatPluginEnabled, name]);

  const tokenOrProvider = useCallback(async () => {
    if (!account.id || !user.id || !isChatPluginEnabled) {
      return '';
    }

    try {
      const { token } = await getChatToken({
        accountId: account.id,
        userId: parseInt(user.id, 10),
      }).unwrap();

      return token;
    } catch (error: unknown) {
      console.error('Error fetching chat token', error);
      return '';
    }
  }, [account.id, getChatToken, user.id, isChatPluginEnabled]);

  useEffect(() => {
    if (!apiKey || !id || !tokenOrProvider || !isChatPluginEnabled) {
      return doNothing();
    }

    const client = StreamChat.getInstance(apiKey);

    // prevents application from setting stale client (user changed, for example)
    let didUserConnectInterrupt = false;

    const connectionPromise = client
      .connectUser(
        {
          id,
          name,
          image,
        },
        tokenOrProvider,
      )
      .then((data) => {
        if (!didUserConnectInterrupt) {
          const connectionData = data as ConnectionOpen;
          const initialUnreadCount = connectionData.me?.total_unread_count ?? 0;
          setUnreadCount(initialUnreadCount);

          setChatClient(client);
        }
      });

    return () => {
      didUserConnectInterrupt = true;
      setChatClient(null);

      // wait for connection to finish before initiating closing sequence
      void connectionPromise
        .then(() => client.disconnectUser())
        .then(() => {
          console.log('connection closed');
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Should only run when ID changes!
  }, [apiKey, id, isChatPluginEnabled, tokenOrProvider]);

  return { chatClient, unreadCount };
};
