import React from 'react';
import { animated, useSpring } from 'react-spring';
import theme from 'theme';
import { easeOutCirc } from 'util/easings';

export type Positions = 'top' | 'bottom' | 'left' | 'right';

interface FabProps {
  backgroundColor?: string;
  position: { x: Positions; y: Positions };

  onClick: () => void;
}

/**
 * Floating button used for main actions
 */
export const Fab: React.FC<FabProps> = ({
  children,
  backgroundColor = theme.colors.turquoiseBlue,
  onClick,
  ...rest
}) => {
  // TODO: Add logic for position...
  const positionStyles = {
    bottom: '12%',
    right: '5%',
  };

  const moveInBottomStyles = useSpring({
    loop: false,
    from: {
      opacity: 0,
      transform: 'translateY(30px)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0px)',
    },
    config: {
      duration: 300,
      easing: easeOutCirc,
    },
  });

  return (
    <animated.span
      data-testid='e2e-button'
      className='rounded-[50%] w-12 h-12 bg-white text-center absolute cursor-pointer select-none outline-0'
      style={{ ...moveInBottomStyles, ...positionStyles, backgroundColor }}
      onClick={onClick}
      {...rest}
    >
      {children}
    </animated.span>
  );
};
