import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import { actions } from '../../Home.ducks';

interface UnlockPadlockLecoProps {
  open?: boolean;
  lecoId: string;
}

export const UnlockPadlockLeco: React.FC<UnlockPadlockLecoProps> = ({ open = false, lecoId }) => {
  const dispatch = useDispatch();

  const openLock = useSpring({
    from: { transform: 'scale(1)  rotateY(0deg)' },
    to: useCallback(
      async (next) => {
        if (!open) {
          return;
        }

        await next({ transform: 'scale(1) rotateY(180deg)' });

        dispatch(actions.cleanUnlockedPathsToAnimate(Number(lecoId)));
      },
      [dispatch, lecoId, open],
    ),
    config: { duration: 1200 },
  });

  return (
    <div className='absolute z-40 transform left-5/10 top-5/10 translate -translate-x-5/10 -translate-y-5/10'>
      <svg
        width='40'
        height='17'
        viewBox='-8 0 42 17'
        fill='white'
        xmlns='http://www.w3.org/2000/svg'
      >
        <animated.path
          style={{ ...openLock, transformOrigin: '17px' }}
          d='M19.3792 9.72106C19.3792 4.35311 15.02 -0.0129905 9.66684 2.90423e-05C4.30723 0.0130486 -4.95911e-05 4.44359 -4.95911e-05 9.8187V14.9239C-4.95911e-05 16.0705 0.929457 17 2.07606 17C3.22267 17 4.15217 16.0705 4.15217 14.9239V9.72106C4.15378 8.26555 4.72401 6.86868 5.74048 5.83028C6.75696 4.79187 8.13865 4.1947 9.58899 4.16693C12.6947 4.11225 15.225 6.67839 15.225 9.79787V17H19.3792V9.72106Z'
        />
      </svg>
      <svg
        width='30'
        height='21'
        viewBox='0 0 30 21'
        fill='white'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M7.78,0h-2.08c-.38,0-.69,.31-.69,.7V18.2c0,1.54,1.25,2.8,2.78,2.8H27.22c1.53,0,2.78-1.26,2.78-2.8V.7c0-.39-.31-.7-.69-.7H7.78Zm11.8,14.62c.02,.2-.04,.4-.17,.54-.13,.15-.32,.23-.52,.23h-2.78c-.2,0-.39-.08-.52-.23-.13-.15-.19-.35-.17-.54l.44-3.97c-.71-.52-1.14-1.35-1.14-2.25,0-1.54,1.25-2.8,2.78-2.8s2.78,1.26,2.78,2.8c0,.9-.43,1.73-1.14,2.25l.44,3.97h0Z' />
      </svg>
    </div>
  );
};
