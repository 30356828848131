import classnames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import { animated, useTransition } from 'react-spring';
import { TooltipProps as Props } from './Tooltip.types';

export const Tooltip: React.FC<Props> = ({
  open = false,
  content,
  placement = 'auto',
  closeOnOutsideClick = true,
  trigger = 'click',
  children,
  className = 'z-20 p-0 border-r rounded border-darkGrey',
  arrowClassName,
  ...rest
}) => {
  const [isVisible, setVisible] = React.useState<boolean>(open);

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    trigger,
    placement,
    visible: isVisible,
    onVisibleChange: setVisible,
    ...rest,
  });

  const fadeIn = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 100 },
  });

  const { style: tooltipStyles, ...tooltipProps } = getTooltipProps({
    className: classnames('tooltip-container', className),
  });

  return (
    <>
      <div ref={setTriggerRef}>{children}</div>

      {ReactDOM.createPortal(
        fadeIn(
          (styles, item) =>
            item && (
              <animated.div
                ref={setTooltipRef}
                {...rest}
                {...tooltipProps}
                style={{ ...styles, ...tooltipStyles }}
              >
                {content}
                <div
                  {...getArrowProps({
                    className: classnames('tooltip-arrow', arrowClassName),
                  })}
                />
              </animated.div>
            ),
        ),
        document.body,
      )}
    </>
  );
};
