import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { defaultLocale } from 'containers/language-provider';
import { actions as modalAction } from 'containers/modal-controller/ModalController.ducks';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { History } from 'history';
import { EventContentListItem } from 'pages/home/Home.types';
import { Dispatch, MiddlewareAPI } from 'redux';
import { Store as ReduxStore } from 'store/types';
import { CreateRegistrationPayload, EventStore, GetEventPayload, GetEventSuccessPayload } from './Event.types';

const initialState: EventStore = {
  event: null,
  error: null,
};

const slice = createSlice({
  name: 'activeEvent',
  initialState,
  reducers: {
    getEvent: (state, _action: PayloadAction<GetEventPayload>) => state,
    getEventSuccess: (state, action: PayloadAction<GetEventSuccessPayload>) => {
      const { event } = action.payload;
      return {
        ...state,
        event,
      };
    },
    getEventError: (state, { payload }: PayloadAction<Error>) => ({ ...state, error: payload }),
    createRegistration: (state, _action: PayloadAction<number | null>) => state,
    createRegistrationSuccess: (state, _action: PayloadAction<CreateRegistrationPayload>) => state,
    createRegistrationNoSpotsAvailable: (state, action: PayloadAction<CreateRegistrationPayload>) => {
      const { payload } = action;
      const { event } = current(state);

      if (!event) {
        return state;
      }

      const { id } = payload;
      const sessions = event.content.sessions.map((session) => {
        if (session.id !== id) {
          return session;
        }

        return {
          ...session,
          userRegistration: {},
          registrationCount: session.spots,
        };
      });

      return {
        ...state,
        event: {
          ...event,
          sessions,
        },
      };
    },
    createRegistrationError: (_state, _action: PayloadAction<Error>) => {
      // do nothing
    },
    cancelRegistration: (state, _action: PayloadAction<number | null>) => state,
    cancelRegistrationSuccess: (state, _action) => state,
    cancelRegistrationError: (_state, _action: PayloadAction<Error>) => {
      // do nothing
    },
    cancelRegistrationUserIsAlreadyConfirmed: (state, _action) => state,
  },
});

export const createActiveEventMiddleware =
  (history: History) =>
  (store: MiddlewareAPI<Dispatch, ReduxStore>) =>
  (next: Dispatch) =>
  (action: PayloadAction<EventContentListItem>): void => {
    next(action);

    switch (action.type) {
      case slice.actions.createRegistrationSuccess.type:
        history.replace('/');
        store.dispatch(
          slice.actions.getEvent({
            lecoId: String(store.getState().activeEvent.event?.content.lecoId),
            locale: store.getState().profile.user.locale || defaultLocale,
          }),
        );
        store.dispatch(modalAction.enqueueModal({ type: ModalTypes.EVENT_SUBMIT_CONFIRMED_REGISTRATION }));
        break;
      case slice.actions.createRegistrationNoSpotsAvailable.type:
        store.dispatch(
          slice.actions.getEvent({
            lecoId: String(store.getState().activeEvent.event?.content.lecoId),
            locale: store.getState().profile.user.locale || defaultLocale,
          }),
        );
        store.dispatch(modalAction.enqueueModal({ type: ModalTypes.EVENT_SUBMIT_NO_SPOTS_AVAILABLE }));
        break;
      case slice.actions.cancelRegistrationUserIsAlreadyConfirmed.type:
        store.dispatch(modalAction.enqueueModal({ type: ModalTypes.EVENT_SUBMIT_USER_IS_ALREADY_CONFIRMED }));
        break;
      case slice.actions.cancelRegistrationSuccess.type:
        history.replace('/');
        break;
      default:
        break;
    }
  };

export const { actions } = slice;
export default slice.reducer;
