import { Media } from 'components/media';
import * as React from 'react';
import { getSrcset } from 'util/image';
import { SpinnerBalls } from './SpinnerBalls';
import { LoadingProps } from './types';

export const Loading: React.FC<LoadingProps> = ({ splashSrc, ...rest }) => (
  <div
    data-testid='e2e-loading'
    className='absolute top-0 flex items-center justify-center w-full h-full -translate-x-1/2 bg-white z-150 left-1/2'
    aria-label='loading'
    {...rest}
  >
    {!splashSrc && <SpinnerBalls />}
    {splashSrc && (
      <div className='relative w-full h-full'>
        <div className='absolute z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
          <SpinnerBalls backgroundColor='bg-white' />
        </div>
        <Media
          alt='splashscreen'
          className='relative inset-0 w-full h-full'
          sizes='100vw'
          srcSet={getSrcset(splashSrc, {
            sizes: 'fullscreen',
          })}
          src={splashSrc}
        />
      </div>
    )}
  </div>
);
