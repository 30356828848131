import React from 'react';

import { Button } from 'components/button';
import { Popup } from 'components/popup';
import theme from 'theme';
import { locales } from './LanguageProvider.helpers';
import { Locale, LocaleSelectPopupProps } from './LanguageProvider.types';

const LocaleSelectPopup: React.FC<LocaleSelectPopupProps> = ({ isOpen, selectedLocale, onChange, onClose }) => {
  const localeCodes = Object.keys(locales);

  const onLocaleChange = (locale: Locale) => () => {
    onChange(locale);
  };

  return (
    <Popup
      open={isOpen}
      variant='clean'
      closeIcon
      onRequestClose={onClose}
    >
      <div className='overflow-visible h-140'>
        <h3 className='mb-4'>Select a language</h3>
        {localeCodes.map((locale) => (
          <Button
            className='py-1 my-1'
            variant='outlined'
            key={locale}
            focusColor={theme.colors.turquoiseBlue}
            text={locales[locale as Locale]}
            focus={selectedLocale === locale}
            onClick={onLocaleChange(locale as Locale)}
          />
        ))}
      </div>
    </Popup>
  );
};

export default LocaleSelectPopup;
