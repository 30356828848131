import dompurify from 'dompurify';

const config: DOMPurify.Config = {
  ALLOWED_ATTR: ['style', 'href', 'target', 'rel'],
};

export function filterXSS(string: string): string {
  const html = dompurify.sanitize(string, config);
  if (typeof html !== 'string') {
    throw new Error('HTML generated is not of type string');
  }
  return html;
}
