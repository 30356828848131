import { IconButton } from 'components/button';
import { Popup } from 'components/popup';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ExternalContentDisclaimerProps as Props } from './ExternalContentDisclaimer.types';

export const ExternalContentDisclaimer: React.FC<Props> = ({ modal, open, onModalClose }) => {
  const { onContinueClick } = modal;

  const handleContinueClick = () => {
    onContinueClick();
    return onModalClose();
  };

  return (
    <Popup
      variant='clean'
      open={open}
      onRequestClose={onModalClose}
      closeIcon
    >
      <div className='px-12 py-0 overflow-y-hidden'>
        <h1 className='text-xl text-turquoiseBlue'>
          <FormattedMessage id='external-content-disclaimer-popup.title' />
        </h1>

        <div className='flex content-center justify-center mx-0 my-8'>
          <IconButton
            variant='outlined'
            round
            icon='arrowForward'
            onClick={handleContinueClick}
            size={30}
          />
        </div>
      </div>
    </Popup>
  );
};
