import { Format, FormatType } from 'containers/FormatScreen';
import { ModalData, ModalTypes } from 'containers/modal-controller/ModalController.types';
import cookie from 'js-cookie';
import _shuffle from 'lodash/shuffle';
import moment from 'moment';
import { LepaTypes } from 'pages/home/Home.types';
import { CrashCourseLepa, LegacyLepa, Lepa } from 'pages/lepa';
import { doNothing } from 'util/doNothing';
import { LearningFormat, LearningFormats, LearningTestFormats } from './Tests.types';

export const isLegacy = (lepa: Lepa): lepa is LegacyLepa => lepa.type === LepaTypes.LEGACY;

export const isCrashCourse = (lepa: Lepa): lepa is CrashCourseLepa => lepa.type === LepaTypes.CRASH_COURSE;

const sortByOrder = <T extends { order: number }>(list: T[]): T[] => [...list].sort((a, b) => a.order - b.order);

export const mapLearningFormatToOldStructure = ({
  id,
  title,
  formatType,
  summary,
  positiveTitle,
  positiveText,
  negativeTitle,
  negativeText,
  generalFeedbackText,
  partialText,
  partialTitle,
  batch,
  question,
  url,
  buttonText,
  imageUrl,
  rightButtonText,
  wrongButtonText,
  questionGroups,
  formatResponses,
}: LearningFormat): Format => ({
  id: String(id),
  title,
  formatType: formatType.title,
  summary: summary ?? '',
  positiveTitle: positiveTitle ?? '',
  positiveText: positiveText ?? '',
  negativeTitle: negativeTitle ?? '',
  negativeText: negativeText ?? '',
  generalFeedbackText: generalFeedbackText ?? '',
  partialText: partialText ?? '',
  partialTitle: partialTitle ?? '',
  batch,
  content: {
    question,
    url: url ?? '',
    buttonText: buttonText ?? '',
    imageUrl: imageUrl ?? '',
    rightButtonText: rightButtonText ?? '',
    wrongButtonText: wrongButtonText ?? '',
    groups: questionGroups.map((questionGroup) => ({
      id: String(questionGroup.id),
      name: questionGroup.title,
      imgSrc: questionGroup.backgroundImage ?? '',
    })),
    options: formatResponses.map((formatResponse) => ({
      id: String(formatResponse.id),
      label: formatResponse.title,
      imageUrl: formatResponse.imageUrl ?? '',
      metadata: formatResponse.metadata,
    })),
  },
});

/* eslint-disable camelcase */
const formatTypeResponsesShouldBeShuffled: { [key in FormatType]: boolean } = {
  text: false,
  video: false,
  drag_drop: true,
  yes_no: false,
  ranking_quiz: false,
  multiple_choice: true,
  multiple_choice_drag_drop: true,
  multiple_answer: true,
  multiple_answer_swipe: true,
  multiple_image_choice: false,
  multiple_image_answer: false,
  true_false: true,
  hotspot: false,
};
/* eslint-enable camelcase */

const prepareFormatResponses = (formatResponses: LearningFormat['formatResponses'], formatType: FormatType) =>
  formatTypeResponsesShouldBeShuffled[formatType] ? _shuffle(formatResponses) : sortByOrder([...formatResponses]);

const prepareQuestionGroups = (questionGroups: LearningFormat['questionGroups']) => sortByOrder([...questionGroups]);

const prepareQuestionGroupsAndFormatResponses = (learningFormats: LearningFormats): LearningFormats =>
  learningFormats.map((learningFormat) => {
    const {
      questionGroups,
      formatResponses,
      formatType: { title: learningFormatType },
    } = learningFormat;
    const preparedQuestionGroups = prepareQuestionGroups(questionGroups);
    const preparedFormatResponses = prepareFormatResponses(formatResponses, learningFormatType);

    return {
      ...learningFormat,
      questionGroups: preparedQuestionGroups,
      formatResponses: preparedFormatResponses,
    };
  });

export const prepareFormats = (learningFormats: LearningFormats): LearningFormats => {
  const orderedLearningFormats = sortByOrder([...learningFormats]);
  return prepareQuestionGroupsAndFormatResponses(orderedLearningFormats);
};

export const prepareTestFormats = (learningTestFormats: LearningTestFormats): LearningFormats => {
  const orderedLearningFormats = sortByOrder([...learningTestFormats]).map(({ format }) => format);
  return prepareQuestionGroupsAndFormatResponses(orderedLearningFormats);
};

const FIRST_MODULE_DONE_KEY = '_fd';

export const isFirstModuleDone = () => Boolean(cookie.get(FIRST_MODULE_DONE_KEY));

export const saveFirstModuleDone = () => cookie.set(FIRST_MODULE_DONE_KEY, 'true');

export const enqueueLearningPathCompletionModals = ({
  isEnabledLearningPathRating,
  enqueueModal,
  lepaId,
  isCertification,
  title,
  color,
  isLearningMoment = false,
}: {
  isEnabledLearningPathRating: boolean;
  enqueueModal: (modal: ModalData) => void;
  lepaId: string;
  isCertification: boolean;
  title: string;
  color: string;
  isLearningMoment?: boolean;
}) => {
  if (isEnabledLearningPathRating) {
    enqueueModal({
      type: ModalTypes.LEPA_RATING,
      modal: {
        pathId: lepaId,
        pathName: title,
        onClose: doNothing,
        onSubmitCallback: doNothing,
      },
    });
  }
  if (!isEnabledLearningPathRating) {
    enqueueModal({
      type: ModalTypes.PATH_END,
      modal: {
        brandColor: color,
        hidePlayMoreInfo: isLearningMoment,
        onClose: doNothing,
      },
    });
  }
  if (isCertification) {
    enqueueModal({
      type: ModalTypes.CERTIFICATION_PASSED,
      modal: {
        pathName: title,
        pathId: lepaId,
      },
    });
    enqueueModal({
      type: ModalTypes.CERTIFICATION_SHARE,
      modal: {
        date: moment().format('DD-MM-YYYY'),
        pathName: title,
        color,
      },
    });
  }
};
