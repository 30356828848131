import { START_LEPA_TAG } from 'pages/lepa/Lepa.api';
import { rootRestApi } from 'store/root.api';
import {
  GetLearningTestFormatsPayload,
  GetModuleFormatsPayload,
  GetRetentionTestPayload,
  LearningFormats,
  LearningTestFormatsResponse,
  SubmitLearningFormatAnswerPayload,
  SubmitLearningFormatAnswerResponse,
  SubmitLearningTestAnswerPayload,
  SubmitLearningTestAnswerResponse,
} from './Tests.types';

export const GET_RETENTION_TEST_TAG = 'getRetentionTest';

export const testsApi = rootRestApi
  .enhanceEndpoints({
    addTagTypes: [GET_RETENTION_TEST_TAG, START_LEPA_TAG],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getModuleFormats: builder.query<LearningFormats, GetModuleFormatsPayload>({
        query: ({ userId, moduleId }) => ({
          url: `users/${String(userId)}/modules/${String(moduleId)}/learning-formats`,
          method: 'GET',
        }),
      }),
      getRetentionTest: builder.query<LearningTestFormatsResponse | null, GetRetentionTestPayload>({
        query: ({ userId, lepaId }) => ({
          url: `users/${userId}/learning-paths/${lepaId}/assess-retention-test`,
          method: 'POST',
        }),
        providesTags: (_result, _error, { lepaId }) => [{ type: GET_RETENTION_TEST_TAG, id: lepaId }],
      }),
      getLearningTestFormats: builder.query<LearningTestFormatsResponse, GetLearningTestFormatsPayload>({
        query: ({ learningTestId }) => ({
          url: `learning-tests/${learningTestId}`,
          method: 'GET',
        }),
      }),
      submitLearningFormatAnswer: builder.mutation<
        SubmitLearningFormatAnswerResponse,
        SubmitLearningFormatAnswerPayload
      >({
        query: ({ learningFormatId, body }) => ({
          url: `learning-formats/${learningFormatId}/answer`,
          method: 'POST',
          body,
        }),
        invalidatesTags: (result, _error, { learningPathId }) => {
          if (result && result.momentCompletion) {
            return [{ type: START_LEPA_TAG, id: learningPathId }];
          }
          return [];
        },
      }),
      submitLearningTestAnswer: builder.mutation<SubmitLearningTestAnswerResponse, SubmitLearningTestAnswerPayload>({
        query: ({ learningTestId, learningFormatId, body }) => ({
          url: `learning-tests/${learningTestId}/learning-formats/${String(learningFormatId)}/answer`,
          method: 'POST',
          body,
        }),
        invalidatesTags: (result, _error, { learningPathId, isLastFormat = false }) => {
          if (result && (result.testCompletion || isLastFormat)) {
            return [
              { type: START_LEPA_TAG, id: learningPathId },
              { type: GET_RETENTION_TEST_TAG, id: learningPathId },
            ];
          }
          return [];
        },
      }),
    }),
    overrideExisting: false,
  });

export const {
  useGetModuleFormatsQuery,
  useGetRetentionTestQuery,
  useGetLearningTestFormatsQuery,
  useSubmitLearningFormatAnswerMutation,
  useSubmitLearningTestAnswerMutation,
} = testsApi;
