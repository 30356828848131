import Textfield from 'components/Textfield';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { SearchInputProps } from './SearchInput.types';

export const SearchInput: FC<SearchInputProps> = ({ value, onChange }) => {
  const intl = useIntl();

  return (
    <Textfield
      className='mx-4 my-6 bg-zinc-100'
      inputClassName='font-light border bg-zinc-100'
      name='name'
      placeholder={intl.formatMessage({
        id: 'community.chat.name-search',
        defaultMessage: 'Name search',
      })}
      value={value}
      withBorder
      required
      autoComplete='off'
      onChange={onChange}
    />
  );
};
