import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import theme from 'theme';
import classes from './TextInputWithFeedback.module.scss';
import { TextInputWithFeedback as Props } from './types';

const TextInputWithFeedback: React.FC<Props> = ({
  value,
  placeholder,
  buttonIcon,
  color,
  feedback,
  onChange,
  onButtonPress,
  onDismissFeedback,
}) => {
  const createOnChange =
    (fn: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) =>
      fn(event.target.value, event);

  const hasFeedback = feedback !== '';

  const handleButtonPress = () => (hasFeedback ? onDismissFeedback() : onButtonPress());

  const renderContent = () => {
    if (hasFeedback) {
      return (
        <div
          style={{ color }}
          onClick={handleButtonPress}
        >
          {feedback}
        </div>
      );
    }
    return (
      <input
        onChange={createOnChange(onChange)}
        className={classes.input}
        value={value}
        placeholder={placeholder}
      />
    );
  };

  return (
    <div
      className={classes.container}
      style={{ borderColor: color }}
      onClick={() => null}
      data-testid='e2e-input'
    >
      <div className={classes.inputWrapper}>{renderContent()}</div>
      <div
        className={classes.button}
        style={{ backgroundColor: color }}
        onClick={handleButtonPress}
      >
        <SvgIcon
          name={buttonIcon}
          color={theme.colors.white}
          fill={theme.colors.white}
          className={classes.icon}
          data-testid='test-button-svg'
        />
      </div>
    </div>
  );
};

export default TextInputWithFeedback;
