import { skipToken } from '@reduxjs/toolkit/dist/query';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ProfileStore } from '../../../../../containers/Profile';
import { useGetDynamicConfigurationQuery } from '../../../../../store/dynamic-configuration';
import { Store } from '../../../../../store/types';
import { ListItemPreview } from '../../ListItemPreview';
import { NewChatViews } from '../NewChat.types';
import { NewGroupPreviewProps } from './NewGroup.types';

export const NewGroupPreview: FC<NewGroupPreviewProps> = ({ onChangeView }) => {
  const intl = useIntl();

  const { id: accountId } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);

  const hasChatAdminPermissions = Boolean(dynamicConfiguration?.communityChat?.hasChatAdminPermissions);
  const haveUsersFullGroupPermissions = Boolean(
    dynamicConfiguration?.communityChat?.grantUsersFullGroupChatPermissions,
  );

  const isNewGroupChatButtonDisplayed = haveUsersFullGroupPermissions || hasChatAdminPermissions;

  const handleClickNewGroupChat = () => {
    onChangeView(NewChatViews.NEW_GROUP);
  };

  return (
    <>
      {isNewGroupChatButtonDisplayed && (
        <ListItemPreview
          title={intl.formatMessage({ id: 'community.chat.new.group-chat', defaultMessage: 'Group chat' })}
          message={intl.formatMessage({
            id: 'community.chat.new.group-chat-description',
            defaultMessage: 'Start a new group chat',
          })}
          icon='account-group-outline'
          onClick={handleClickNewGroupChat}
        />
      )}
    </>
  );
};
