import { v4 as uuid } from 'uuid';
import { rootApi } from '../../store/root.api';
import { TrackEventPayload } from './internal/InternalAnalytics.types';

const APP_VERSION = process.env.REACT_APP_VERSION;

export const analyticsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    trackEvent: builder.mutation<void, TrackEventPayload>({
      query: (payload) => ({
        url: '/analytics',
        method: 'POST',
        body: {
          ...payload,
          id: uuid(),
          platformName: 'app',
          platformVersion: APP_VERSION,
          accountId: parseInt(payload.accountId, 10),
        },
      }),
    }),
  }),
});

export const { useTrackEventMutation } = analyticsApi;
