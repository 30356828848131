import { Popup } from 'components/popup';
import { ExperiencePointsPopupProps as Props } from './ExperiencePointsPopup.types';

export const ExperiencePointsPopup: React.FC<Props> = ({
  showExperiencePoints,
  experiencePoints,
  text,
  textColor,
  fillColor,
}) => {
  const textSplitted = text.split('\n');
  const xpPointsText = `+ ${String(experiencePoints)} XP!`;

  return (
    <Popup
      open
      variant='xp'
      showXP={showExperiencePoints}
      xpFill={fillColor}
      xpText={textSplitted}
      xp={xpPointsText}
      xpColor={textColor}
    />
  );
};
