import { Format, FormatType } from 'containers/FormatScreen';
import { AnswerPayload, NextTest } from 'pages/tests/Tests.types';
import { AnswerResponse } from './hooks/use-learning-format/types';

export interface TestContext {
  levelId: number;
  skillId: number;
  moduleId: number;
}

export interface LoformatsProps {
  learningFormats: Format[];
  title: string;
  id: string;
  color?: string;
  navigateBackRoute: string;
  testContext?: TestContext;
  withBackNavigation?: boolean;
  isSubmittingAnswer?: boolean;
  isCorrect?: boolean;
  correctOptions?: number[];
  percentage?: number;
  xp?: number;
  passed?: boolean;
  previousSubmittedFormatId?: number;
  next?: NextTest | null;
  learningPathDone?: boolean;
  onAnswer?: (answer: AnswerPayload) => Promise<AnswerResponse | null>;
  onNavigateAway: () => void;
}

export interface AwardInfo {
  points: number;
  fill: string;
  xpColor: string;
  translationId: string;
}

export enum ExperiencePointsType {
  STREAK = 'streak',
  NICE_COMEBACK = 'niceComeback',
  QUICK_ANSWER = 'quickAnswer',
  BACK_ON_TRACK = 'backOnTrack',
}

export type Awards = {
  [k in ExperiencePointsType]: number;
};

export enum AnswerTypes {
  NO_ANSWER = 'no-answer',
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  GROUPS = 'groups',
}

interface FormatTypeInfo {
  answerType: AnswerTypes;
  answerPropertyName: string;
  qaTimer: number;
}

export type FormatTypeInformationMap = {
  [key in FormatType]: FormatTypeInfo;
};
