import { TopBar } from 'components/top-bar';
import { ProfileStore } from 'containers/Profile';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { UserResponse } from 'stream-chat';
import { LoadingIndicator, useChatContext } from 'stream-chat-react';
import { PageContainer } from '../../../../components/page-container';
import { useAddMembersMutation } from '../Chat.api';
import { AppChannelUser } from '../Chat.types';
import { AlphabeticUserList } from '../components/alphabetic-user-list';
import { SearchInput } from '../components/search-input';
import { useSearchUsers } from '../hooks/useSearchUsers';
import { SelectedUsers } from '../new/group';
import { BaseGroupInfoProps } from './Conversation.types';

export const GroupAddMembers: React.FC<BaseGroupInfoProps> = ({ refreshMembers, onClose }) => {
  const { channel } = useChatContext();

  const { id: accountId, primaryColor } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const [selectedUsers, setSelectedUsers] = useState<UserResponse[]>([]);

  const filters = useMemo(() => {
    const currentMembers = channel?.state?.members ?? [];
    const channelMembersIds = Object.keys(currentMembers);
    return { id: { $nin: channelMembersIds } };
  }, [channel?.state?.members]);
  const { searchUsers, users, isLoading, searchText } = useSearchUsers(filters);

  const [addMembers] = useAddMembersMutation();

  const hasSelectedUsers = selectedUsers.length > 0;

  const isUserSelected = (user: UserResponse) => selectedUsers.some((currentUser) => currentUser.id === user.id);

  const handleDeselectUser = (user: UserResponse) => {
    const nextSelectedUsers = selectedUsers.filter((currentUser) => currentUser.id !== user.id);
    setSelectedUsers(nextSelectedUsers);
  };

  const handleToggleUser = (user: UserResponse) => {
    if (isUserSelected(user)) {
      handleDeselectUser(user);
      return;
    }

    setSelectedUsers([...selectedUsers, user]);
  };

  const availableUsers: AppChannelUser[] = users.map((user) => ({
    ...user,
    isSelected: isUserSelected(user),
  }));

  const handleAddMembers = async () => {
    if (!channel?.id) {
      return;
    }

    await addMembers({
      accountId,
      channelId: channel.id,
      members: selectedUsers.map((user) => parseInt(user.id, 10)),
    });
    await refreshMembers();

    setSelectedUsers([]);
    onClose();
  };

  return (
    <div className='absolute top-0 z-10 flex flex-col w-full h-full bg-zinc-100'>
      <TopBar
        backButton={<TopBar.IconButton onClick={onClose} />}
        nextButton={
          <TopBar.Button
            onClick={handleAddMembers}
            disabled={selectedUsers.length === 0}
          >
            <FormattedMessage
              id='community.chat.group-info.save'
              defaultMessage='Save'
            />
          </TopBar.Button>
        }
      >
        <FormattedMessage
          id='community.chat.group-info.add-members'
          defaultMessage='Add Members'
        />
      </TopBar>

      <PageContainer className='h-full pb-3 overflow-y-hidden'>
        <SearchInput
          value={searchText}
          onChange={searchUsers}
        />

        {hasSelectedUsers && (
          <SelectedUsers
            users={selectedUsers}
            onClose={handleDeselectUser}
          />
        )}

        {isLoading ? (
          <div className='flex justify-center w-full p-5'>
            <LoadingIndicator color={primaryColor} />
          </div>
        ) : (
          <AlphabeticUserList
            users={availableUsers}
            hasSelectIndicator
            selectedUsers={selectedUsers}
            sectionClassName='px-4'
            listClassName='overflow-y-auto h-full'
            onClick={handleToggleUser}
          />
        )}
      </PageContainer>
    </div>
  );
};
