import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { FormattedDate } from 'react-intl';
import { SessionProps as Props } from '../Event.types';

export const Session: React.FC<Props> = ({
  id,
  title,
  startAt,
  endAt,
  spots,
  registrationCount,
  userRegistration,
  borderColor,
  isSelected,
  isSelectable,
  className = '',
  onclick,
}) => {
  const areAllSpotsTaken = registrationCount >= spots;
  const shouldBlockSelection = areAllSpotsTaken || !isSelectable;
  const isUserRegistered = Boolean(userRegistration?.id);
  const isRegistrationUnavailable = areAllSpotsTaken && !isUserRegistered;

  const hasEqualStartAndEndDay = moment(startAt).isSame(endAt, 'day');

  const handleClick = (sessionId: number) => () => {
    if (shouldBlockSelection) {
      return null;
    }

    return onclick(sessionId);
  };

  return (
    <div
      data-testid='e2e-session'
      className={classNames(
        'relative h-auto mb-3 rounded-lg cursor-pointer border-transparent border-4',
        {
          'cursor-not-allowed': shouldBlockSelection,
          'border-turquoiseBlue': isSelected,
          'bg-yellowGreen text-white': isUserRegistered,
          'bg-neutral-200 text-lightGrey': !isUserRegistered,
        },
        className,
      )}
      style={{ borderColor: isSelected ? borderColor : 'transparent' }}
      onClick={handleClick(id)}
    >
      <div className='flex items-center justify-between mx-3 my-2'>
        <div className='break-words w-9/10'>
          <h2 className={classNames('font-semibold', { 'opacity-30': isRegistrationUnavailable })}>{title}</h2>
          <p className={classNames('text-xs font-normal', { 'opacity-30': isRegistrationUnavailable })}>
            <FormattedDate
              value={startAt}
              month='long'
              day='numeric'
              hour='numeric'
              minute='numeric'
              weekday='long'
              hour12={true}
            />
            {' - '}
            {hasEqualStartAndEndDay ? (
              <FormattedDate
                value={endAt}
                hour='numeric'
                minute='numeric'
                hour12={true}
              />
            ) : (
              <FormattedDate
                value={endAt}
                month='long'
                day='numeric'
                hour='numeric'
                minute='numeric'
                weekday='long'
                hour12={true}
              />
            )}
          </p>
        </div>
        <div>
          <span className={classNames('text-xs font-semibold opacity-50', { 'opacity-30': areAllSpotsTaken })}>
            {`${registrationCount}/${spots}`}
          </span>
        </div>
      </div>
    </div>
  );
};
