import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnqueueModalPayload, ModalData, ModalsStore, ModalTypes } from './ModalController.types';

export const initialState: ModalsStore = {
  previousModal: { type: ModalTypes.EMPTY },
  queue: [],
  currentModal: null,
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    enqueueModal: (state, action: PayloadAction<EnqueueModalPayload>) => {
      // Casting needed because ModalData type cannot be converted to WritableDraft by RTK
      (state.queue as ModalData[]).push(action.payload);
    },
    showNextModal: (state) => {
      const [currentModal, ...nextQueue] = state.queue;
      state.currentModal = currentModal ?? null;
      state.queue = nextQueue;
    },
    resetQueue: () => initialState,
    setPreviousModal: (state, action: PayloadAction<ModalData>) => {
      // Casting needed because ModalData type cannot be converted to WritableDraft by RTK
      (state.previousModal as ModalData) = action.payload;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;
