import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import theme from 'theme';
import { StepIndicatorProps as Props } from './InstallInstructionStepsPopup.types';

export const StepIndicator: React.FC<Props> = ({ stepNumber, currentStep }) => {
  const accountPrimaryColor = useSelector(({ profile }: Store) => profile.account.primaryColor);
  const customizableAccountColor = accountPrimaryColor || theme.colors.orangeRedTwo;

  return (
    <div
      className='w-1/3 h-1'
      style={{
        backgroundColor: currentStep >= stepNumber ? customizableAccountColor : theme.colors.zinc300,
      }}
    />
  );
};
