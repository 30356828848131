import { PayloadAction } from '@reduxjs/toolkit';
import { REST_API_URL } from 'constants/endpoints';
import { loader } from 'graphql.macro';
import { call, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';
import { Store } from 'store/types';
import getDefaultHeaders from 'util/getDefaultHeaders';
import request from 'util/request';
import requestGraphql from 'util/requestGraphql';
import { actions } from './Newsfeed.ducks';
import {
  AnswerPollPayload,
  GetPostsPayload,
  GetUnreadPostsPayload,
  LikePostPayload,
  Post,
  PostEngagement,
  PostEngagementPayload,
  PostPayload,
} from './Newsfeed.types';

const getPostsQuery = loader('./graphql/queries/get-feed.graphql');
const getUnreadPostsQuery = loader('./graphql/queries/get-unread-feed.graphql');
const answerPollMutation = loader('./graphql/mutation/submit-poll-answer.graphql');

function* getPostsWorker({
  payload: { dir, limit, offset, orderBy, refresh },
}: PayloadAction<GetPostsPayload>): Generator<StrictEffect, void> {
  const state = (yield select()) as Store;
  const fetchLimit = limit || state.newsfeed.currentPostLimit;

  const body = {
    query: getPostsQuery,
    variables: {
      dir,
      limit: fetchLimit,
      offset,
      // eslint-disable-next-line camelcase
      order_by: orderBy,
    },
  };

  interface ResponsePayload {
    newsfeed: {
      getFeed: {
        data: Post[];
      };
    };
  }

  const { data, errors } = (yield call(requestGraphql, body)) as { data: ResponsePayload; errors: Error };

  if (errors) {
    yield put(actions.getPostsError(errors));
  } else {
    yield put(actions.getPostsSuccess({ data: data.newsfeed.getFeed.data, refresh: !!refresh, offset: !!offset }));
  }
}

/** Fetch the feed posts with pagination */
function* getUnreadPostsWorker({
  payload: { dir, limit, offset, orderBy },
}: PayloadAction<GetUnreadPostsPayload>): Generator<StrictEffect, void> {
  const state = (yield select()) as Store;
  const fetchLimit = limit || state.newsfeed.currentPostLimit;

  const body = {
    query: getUnreadPostsQuery,
    variables: {
      dir,
      limit: fetchLimit,
      offset,
      // eslint-disable-next-line camelcase
      order_by: orderBy,
    },
  };

  interface ResponsePayload {
    newsfeed: {
      getUnreadFeed: {
        data: Post[];
        pagination: {
          rowCount: number;
        };
      };
    };
  }

  const { data, errors } = (yield call(requestGraphql, body)) as { data: ResponsePayload; errors: Error };

  if (errors) {
    yield put(actions.getUnreadPostsError(errors));
  } else {
    yield put(
      actions.getUnreadPostsSuccess({
        data: data.newsfeed.getUnreadFeed.data || [],
        count: data.newsfeed.getUnreadFeed.pagination.rowCount,
      }),
    );
  }
}

/** Like post worker */
function* likePostWorker({ payload: { post, like } }: PayloadAction<LikePostPayload>): Generator<StrictEffect, void> {
  const requestUrl = `${REST_API_URL}/post-source/${post.source}/engagements`;
  const headers = getDefaultHeaders();

  const state = (yield select()) as Store;

  interface Body {
    post: PostPayload;
    engagement: PostEngagementPayload;
  }

  const body: Body = {
    post: {
      accountId: parseInt(state.profile.account.id, 10),
      source: post.source,
      sourceId: post.sourceId,
      type: post.type,
    },
    engagement: {
      like,
      postId: parseInt(post.id, 10),
    },
  };

  const { data: engagement, errors } = (yield call(request, requestUrl, {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  })) as { data: PostEngagement; errors: Error };

  if (errors) {
    yield put(actions.likePostError(errors));
  } else {
    yield put(actions.likePostSuccess({ postId: String(engagement.postId), liked: engagement.like }));
  }
}

/** Answer post worker */
function* answerPollWorker({
  payload: { answerID, postID },
}: PayloadAction<AnswerPollPayload>): Generator<StrictEffect, void> {
  const body = {
    query: answerPollMutation,
    variables: { answerID },
  };

  const { errors } = (yield call(requestGraphql, body)) as { data: void; errors: Error };

  if (errors) {
    yield put(actions.answerPollError(errors));
  } else {
    yield put(actions.answerPollSuccess({ answerID, postID }));
  }
}

/** root saga */
export function* rootSaga() {
  yield takeLatest(actions.getPosts.type, getPostsWorker);
  yield takeLatest(actions.getUnreadPosts.type, getUnreadPostsWorker);
  yield takeLatest(actions.likePost.type, likePostWorker);
  yield takeLatest(actions.answerPoll.type, answerPollWorker);
}

export default rootSaga;
