import classNames from 'classnames';
import React from 'react';
import { SvgIconProps } from './SvgIcon.types';
import { iconPaths } from './iconPaths';

export const SvgIcon: React.FC<SvgIconProps> = ({
  size = 16,
  color = 'currentColor',
  name,
  badge,
  viewBox = '-.5 -.5 25 25',
  className,
  ...rest
}) => (
  <svg
    width={size}
    height={size}
    viewBox={viewBox}
    fill={color}
    data-testid={`svgicon-${name}`}
    className={classNames('inline align-baseline overflow-visible', className)}
    {...rest}
  >
    <path d={iconPaths[name]} />
    {badge}
  </svg>
);
