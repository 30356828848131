import classnames from 'classnames';
import { Media } from 'components/media';
import Question from 'components/Question';
import { SvgIcon } from 'components/svg-icon';
import { API_URL } from 'constants/endpoints';
import { ImageFormat, VideoFormat } from 'containers/FormatScreen/Formats';
import { MultipleChoiceProps as Props } from 'containers/FormatScreen/Formats/MultipleChoice/types';
import React from 'react';
import theme from 'theme';
import { getSrcset } from 'util/image';

const createOnClick = (fn: any, value: any) => (event: any) => fn(value, event);

export const MultipleImageChoice: React.FC<Props> = ({
  focusColor,
  selected = '',
  showAnswers,
  correct,
  question,
  answers,
  imgSrc,
  video,
  onChange,
}) => (
  <div className='flex flex-col w-full'>
    {video && (
      <div className='my-4'>
        <VideoFormat url={video} />
      </div>
    )}
    {imgSrc && !video && (
      <div className='my-4'>
        <ImageFormat
          imgSrc={imgSrc}
          alt={question}
        />
      </div>
    )}

    {(!video || !imgSrc) && (
      <Question
        text={question}
        scaleWithContent
      />
    )}

    <div className='flex flex-wrap justify-between w-full px-2 py-5'>
      {answers.map((answer) => {
        const isSelected = selected === answer.id;
        const showAnswerFeedback = isSelected && showAnswers;
        const isCorrect = correct.includes(answer.id);
        const isFocused = !showAnswers && isSelected;

        const isCorrectAndSelected = showAnswers && isSelected && isCorrect;
        const isCorrectAndUnselected = showAnswers && !isSelected && isCorrect;

        const isWrongAndSelected = showAnswers && isSelected && !isCorrect;
        const isWrongAndUnselected = !isSelected && !isCorrect;

        const answerStyles: React.CSSProperties = {
          borderColor: isFocused ? focusColor || theme.colors.turquoiseBlue : '',
          width: 'calc(50% - 16px)',
        };

        return (
          <div
            key={`multi-img-answer-${answer.id}`}
            className={classnames(
              'relative flex rounded-lg cursor-pointer border-4 m-2',
              { 'border-transparent': !showAnswers || isWrongAndUnselected },
              { 'border-fullRed': isWrongAndSelected },
              { 'border-yellowGreen': isCorrectAndSelected },
              { 'border-dashed border-yellowGreen': isCorrectAndUnselected },
            )}
            style={answerStyles}
            onClick={createOnClick(onChange, answer.id)}
          >
            <div className='relative w-full pb-9/16'>
              {answer.imageUrl && (
                <Media
                  alt='answer'
                  className='absolute rounded-lg'
                  src={`${API_URL}/${answer.imageUrl}`}
                  sizes='(max-width: 768px) 45vw, 20vw'
                  srcSet={getSrcset(`${API_URL}/${answer.imageUrl}`, {
                    sizes: [
                      [1520, '1120w'],
                      [1080, '720w'],
                      [720, '420w'],
                      [420, '320w'],
                    ],
                  })}
                />
              )}

              {showAnswerFeedback && (
                <div className='absolute transform top-5/10 left-5/10 -translate-y-5/10 -translate-x-5/10 '>
                  <SvgIcon
                    className='transform scale-150 rounded-full'
                    name={isCorrect ? 'check' : 'close'}
                    size={24}
                    color={theme.colors.white}
                    style={{
                      backgroundColor: isCorrect ? theme.colors.green : theme.colors.red,
                    }}
                    data-testid={isCorrect ? 'answer-correct' : 'answer-incorrect'}
                  />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  </div>
);
