import { Media } from 'components/media';
import { PageContainer } from 'components/page-container';
import { PlayButton } from 'components/play-button';
import { SvgIcon } from 'components/svg-icon';
import { API_URL } from 'constants/endpoints';
import { ProfileStore } from 'containers/Profile/types';
import { HomeStore } from 'pages/home/Home.types';
import { isLepa, useLecoCardConfiguration } from 'pages/home/useLecoCardConfiguration';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Store } from 'store/types';
import theme from 'theme';
import { LepaInfoProps as Props } from './LepaInfo.types';
import { Info } from './info';

export const LepaInfo: React.FC<Props> = ({ match }) => {
  const { lepaId } = match.params;

  const { paths } = useSelector<Store, HomeStore>(({ home }) => home);
  const path = paths.find((currentPath) => currentPath.id === lepaId || String(currentPath.content.lecoId) === lepaId);
  const account = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const lecoCardConfiguration = useLecoCardConfiguration();

  if (!path) {
    return <Redirect to='/' />;
  }

  const { objectives, description, estimatedTimeToComplete, image, title, primaryColor, playButtonColor, type } = path;
  const iconColor = primaryColor || playButtonColor || account.pathColor || theme.colors.orange;
  const showEstimatedTimeToComplete = estimatedTimeToComplete > 0;
  const isCertification = isLepa(path) && Boolean(path.content.isCertification);

  const openPath = () => {
    lecoCardConfiguration[type].play(path);
  };

  return (
    <div
      className='relative w-full h-full overflow-y-auto'
      data-testid='path-info-screen'
    >
      <div className='relative w-full mb-16 h-1/2 max-h-52'>
        <div className='relative h-full'>
          <Media
            alt='learning-path'
            src={image ? `${API_URL}/${image}` : ''}
            className='w-full h-full m-0 bg-black'
          />

          <div className='absolute left-0 right-0 z-40 bottom-4/10'>
            <h2 className='pl-5 pr-5 text-lg text-center text-white top-1/10'>{title}</h2>
          </div>

          <PlayButton
            bgColor={iconColor}
            className='absolute left-0 right-0 z-10 w-20 h-20 bottom-10'
            onClick={openPath}
          />
          <div className='absolute bottom-0 right-0 w-full h-6/10 bg-gradient-to-t from-black to-transparent' />
        </div>
      </div>

      <PageContainer className='flex flex-col items-center justify-center text-base text-greyishBrown'>
        {showEstimatedTimeToComplete && (
          <Info>
            <SvgIcon
              name='timer'
              viewBox='0 0 165 210'
              color={iconColor}
              size={45}
              className='mb-2'
            />
            <p>{estimatedTimeToComplete} min</p>
          </Info>
        )}

        {description && (
          <Info>
            <SvgIcon
              name='information'
              color={iconColor}
              size={45}
              className='mb-2'
            />
            <p>{description}</p>
          </Info>
        )}

        {objectives && (
          <Info>
            <SvgIcon
              name='objectives'
              viewBox='0 0 200 220'
              color={iconColor}
              size={45}
              className='mb-2'
            />
            <div>
              {objectives.split('\n').map((objective, i) => (
                <p key={i}>{objective}</p>
              ))}
            </div>
          </Info>
        )}

        {isCertification && (
          <Info>
            <SvgIcon
              name='certification'
              color={iconColor}
              width='28'
              height='42'
              viewBox='0 0 28 42'
              className='mb-2'
            />
            <FormattedMessage id='path-info.certified' />
          </Info>
        )}
      </PageContainer>
    </div>
  );
};
