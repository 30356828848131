import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import { useTextDirection } from 'hooks/useTextDirection';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { BackButtonProps as Props } from './BackButton.types';

export const BackButton: React.FC<Props> = ({ to = '/' }) => {
  const { isLTR } = useTextDirection();

  return (
    <div
      className='w-full p-3'
      data-testid='e2e-button'
    >
      <Link
        to={to}
        className='text-xs font-medium leading-normal text-greyish'
      >
        <SvgIcon
          name={isLTR ? 'arrowBack' : 'arrowForward'}
          className={classnames('relative top-1', {
            'mr-1': isLTR,
            'ml-1': !isLTR,
          })}
        />
        <FormattedMessage id='comp.breadcrumbs' />
      </Link>
    </div>
  );
};
