import { skipToken } from '@reduxjs/toolkit/dist/query';
import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { StreamChat } from 'stream-chat';
import { STREAM_API_KEY } from '../../../constants/global';
import { ProfileStore } from '../../../containers/Profile';
import { useGetDynamicConfigurationQuery } from '../../../store/dynamic-configuration';
import { Store } from '../../../store/types';
import { useStreamClient } from './hooks/useStreamClient';

interface UseChat {
  chatClient: StreamChat | null;
  unreadCount: number;
}

const ChatContext = createContext<UseChat>({
  chatClient: null,
  unreadCount: 0,
});

export const useChat = (): UseChat => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatContext');
  }
  return context;
};

export const useChatUnreadCount = () => {
  const { unreadCount } = useChat();
  return { unreadCount };
};

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { id: accountId } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);
  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);

  const isChatPluginEnabled = dynamicConfiguration?.plugins?.chat;

  const { chatClient, unreadCount } = useStreamClient({
    apiKey: STREAM_API_KEY,
    isChatPluginEnabled,
  });

  return <ChatContext.Provider value={{ chatClient, unreadCount }}>{children}</ChatContext.Provider>;
};
