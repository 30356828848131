import { SearchResult } from 'pages/search/search-result';
import React from 'react';
import { SearchResultsProps as Props } from '../SearchResult.types';

export const LevelResult: React.FC<Props> = ({ result, context, ...rest }) => (
  <>
    {result.children.map((topic) => (
      <SearchResult
        key={`topic-${topic.id}`}
        result={topic}
        pathContext={context}
        context={result}
        {...rest}
      />
    ))}
  </>
);
