import { REST_API_URL } from 'constants/endpoints';
import { call, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';
import { Action, Store } from 'store/types';
import getDefaultHeaders from 'util/getDefaultHeaders';
import request, { RequestResult } from 'util/request';
import { actions } from './Search.ducks';
import { AppSearchResult } from './Search.types';

function* getSearchResults({ payload }: Action<string>): Generator<StrictEffect, void> {
  const state = (yield select()) as Store;
  const { id } = state.profile.account;

  const searchResult = encodeURIComponent(payload);
  const requestUrl = `${REST_API_URL}/accounts/${id}/search/app?query=${searchResult}`;
  const headers = (yield call(getDefaultHeaders)) as HeadersInit;
  const response = (yield call(request, requestUrl, { method: 'GET', headers })) as RequestResult<AppSearchResult[]>;

  if (!response.err) {
    yield put(actions.getSearchResultsSuccess(response.data));
  } else {
    const { err } = response;
    const error = err instanceof Error ? err : new Error(String(err));
    yield put(actions.getSearchResultsError(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.getSearchResults.type, getSearchResults);
}
