import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Props as JoyrideProps } from 'react-joyride';

import { JoyrideWrapper } from '../Walkthrough';
import { WalkthroughVariantProps as Props } from '../Walkthrough.types';

const getSteps = (target: Props['targets']): JoyrideProps['steps'] => [
  {
    content: <FormattedMessage id='walkthrough.crash-course-test.step-1.summary' />,
    title: <FormattedMessage id='walkthrough.crash-course-test.step-1.title' />,
    target: target[0],
    disableBeacon: true,
    placement: 'auto',
  },
];

/**
 * Crash course path test Walktrough component
 */
export const CrashCourseTestWalkthrough: React.FC<Props> = ({ targets, ...props }) => {
  const steps = getSteps(targets);

  return (
    <JoyrideWrapper
      steps={steps}
      continuous
      {...props}
    />
  );
};
