import qs from 'qs';
import React from 'react';
import { useHistory } from 'react-router-dom';

type ObjectKey = string | number | symbol;
type ObjectValue = string | number | boolean;

export type URLSearchParams<K extends ObjectKey = string, V extends ObjectValue = string> = { [key in K]: V };

export function useURLSearchParams<K extends ObjectKey = string, V extends ObjectValue = string>() {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const params: URLSearchParams<K, V> = React.useMemo(
    () => qs.parse(search, { ignoreQueryPrefix: true, parameterLimit: 5 }) as URLSearchParams<K, V>,
    [search],
  );
  return params;
}
