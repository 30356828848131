export enum EventTypes {
  NAVIGATE = 'navigate',
  NAVIGATE_EXTERNAL = 'navigate-external',
  OPEN_CHATBOT = 'open-chatbot',
  OPEN_NEWSFEED_POST = 'open-newsfeed-post',
  SET_LECO_FILTERS = 'set-leco-filters',
  CLEAR_LECO_FILTERS = 'clear-leco-filters',
}

export interface TrackEventPayload {
  eventType: EventTypes;
  accountId: string;
  properties: Record<string, unknown>;
}
