import classnames from 'classnames';
import { ProfileStore } from 'containers/Profile';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { useChatContext } from 'stream-chat-react';
import tinycolor from 'tinycolor2';
import { Drawer } from '../../../../components/drawer';
import { CloseIcon } from '../../../../components/popup/close-icon';
import { SvgIcon } from '../../../../components/svg-icon';
import { API_URL } from '../../../../constants/endpoints';
import { useAddModeratorMutation } from '../Chat.api';
import { SeparatorLine } from '../components/separator-line';
import { ListItemPreview } from '../ListItemPreview';
import { UserChatDrawerItemProps, UserChatDrawerProps } from './Conversation.types';

export const UserChatDrawer: React.FC<UserChatDrawerProps> = ({
  user,
  isOpen,
  onClose,
  onRemoveUser,
  refreshMembers,
}) => {
  const { channel } = useChatContext();
  const { id: accountId, primaryColor } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const isPrimaryColorDark = tinycolor(primaryColor).isDark();
  const defaultIconColor = isPrimaryColorDark ? tinycolor(primaryColor).brighten(40).toString() : primaryColor;
  const userAvatar = user?.image ? `${API_URL}/${user?.image}` : '';

  const [addModerator] = useAddModeratorMutation();

  const handleAddModerator = async () => {
    if (!user || !channel?.id) {
      return;
    }

    await addModerator({
      accountId,
      channelId: channel.id,
      userIds: [parseInt(user.id, 10)],
    });

    await refreshMembers();
    onClose();
  };

  const menuItems: UserChatDrawerItemProps[] = [
    {
      icon: 'shield-account-outline',
      text: (
        <FormattedMessage
          id='community.chat.group-info.make-group-admin'
          defaultMessage='Make group admin'
        />
      ),
      onClick: handleAddModerator,
    },
    {
      icon: 'do-not-disturb',
      text: (
        <FormattedMessage
          id='community.chat.group-info.remove'
          defaultMessage='Remove from group'
        />
      ),
      classNames: 'text-red-500',
      iconColor: '#ef4444',
      onClick: onRemoveUser,
    },
  ];

  return (
    <Drawer
      className='text-white text-opacity-75 bg-neutral-800'
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className='flex w-full text-white'>
        <ListItemPreview
          title={user?.name ?? ''}
          image={userAvatar}
        />
        <CloseIcon
          size={22}
          onClose={onClose}
        />
      </div>
      <SeparatorLine />

      {menuItems.map((item) => (
        <>
          <button
            key={item.icon}
            className={classnames('flex w-full p-4 pl-0 text-lg text-white font-light', item.classNames)}
            onClick={item.onClick}
          >
            <SvgIcon
              viewBox='-.5 -3.5 25 25'
              name={item.icon}
              size={24}
              color={item.iconColor ?? defaultIconColor}
            />
            <p className='pl-4'>{item.text}</p>
          </button>
          <SeparatorLine />
        </>
      ))}
    </Drawer>
  );
};
