import { Skill, TipsTricks } from 'containers/FormatScreen/types';
import { Locale } from 'containers/language-provider';
import { CompleteWalkthroughRequestPayload } from 'containers/walkthrough';
import { History } from 'history';
import { WrappedComponentProps } from 'react-intl';
import { Store } from 'store/types';
import { WithCompletedWalkthroughs } from 'util/withCompletedWalkthroughs';

export interface SearchProps extends WrappedComponentProps, WithCompletedWalkthroughs {
  history: History;
  activePath: Store['activePath'];
  user: Store['profile']['user'];
  locale: Locale;

  completeWalkthrough: (payload: CompleteWalkthroughRequestPayload) => Promise<void>;
}

export interface SearchState {
  searchText: string;
  filteredResults: {
    skills?: Skill[];
    tipsTricks?: TipsTricks[];
  };
  searchResults: {
    skills?: Skill[];
    tipsTricks?: TipsTricks[];
  };
  popupOpen: boolean;
  popupMedia: string | null;
  popupTitle: string | null;
}

export interface NewResults {
  [group: string]: any;
  skills?: Skill[];
  tipsTricks?: TipsTricks[];
}

/** filtered skills */
export interface Result extends Skill, TipsTricks {
  id: string;
  skillID: string;
  levelID: string;
  levelName: string;
  rank?: Rank;
  title: string;
  summary: string;
  tags: [];
  locked: boolean;
  videos: any[];
  type: 'module' | 'skill' | 'tip';
}

export enum Rank {
  PLAYER = 'Player',
  EXPLORER = 'Explorer',
  GURU = 'Guru',
  SPECIALIST = 'Specialist',
  NAVIGATOR = 'Navigator',
}
