import { animated, config, useSpring } from 'react-spring';

export const Grimacing = () => {
  const styles = useSpring({
    loop: true,
    from: { transform: 'scalex(0)' },
    to: { transform: 'scalex(1)' },
    config: config.molasses,
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='92'
      height='92'
      fill='none'
      viewBox='0 0 92 92'
    >
      <circle
        cx='45.748'
        cy='45.748'
        r='45.748'
        fill='#FFD23B'
      ></circle>
      <path
        fill='#FFB24A'
        d='M45.748 91.497c25.266 0 45.749-20.482 45.749-45.748C91.497 20.482 71.014 0 45.748 0v91.497z'
      ></path>
      <circle
        cx='45.033'
        cy='60.759'
        r='13.582'
        fill='#FF3187'
      ></circle>
      <path
        fill='#FF5178'
        d='M45.748 74.34c7.501 0 13.582-6.08 13.582-13.58 0-7.502-6.081-13.582-13.582-13.582V74.34z'
      ></path>
      <circle
        cx='45.748'
        cy='45.748'
        r='45.748'
        fill='#FFD23B'
      ></circle>
      <path
        fill='#FFB24A'
        d='M45.748 91.497c25.266 0 45.749-20.482 45.749-45.748C91.497 20.482 71.014 0 45.748 0v91.497z'
      ></path>
      <ellipse
        cx='26.448'
        cy='34.312'
        fill='#2D3E53'
        rx='5.004'
        ry='5.719'
      ></ellipse>
      <ellipse
        cx='65.049'
        cy='34.312'
        fill='#000'
        rx='5.004'
        ry='5.719'
      ></ellipse>
      <animated.g style={{ ...styles, transformOrigin: '50%' }}>
        <rect
          width='57.186'
          height='20.015'
          x='17.156'
          y='51.467'
          fill='#962B7C'
          rx='10.008'
        ></rect>
        <path
          fill='#C22C7B'
          d='M45.749 51.467h18.585c5.527 0 10.008 4.48 10.008 10.007 0 5.527-4.48 10.008-10.008 10.008H45.75V51.467z'
        ></path>
        <path
          fill='#fff'
          d='M25.734 51.467H65.76400000000001V60.045H25.734z'
        ></path>
        <path
          fill='#fff'
          d='M25.734 62.904H65.76400000000001V71.482H25.734z'
        ></path>
        <path
          fill='#EEE'
          d='M45.749 51.467H65.76400000000001V60.045H45.749z'
        ></path>
        <path
          fill='#EEE'
          d='M45.749 62.904H65.76400000000001V71.482H45.749z'
        ></path>
      </animated.g>
    </svg>
  );
};
