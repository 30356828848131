import React, { createContext, useContext } from 'react';

type ScrollRef = React.MutableRefObject<HTMLDivElement | null>;

interface MainScrollProps {
  scrollRef: ScrollRef;
  children: React.ReactNode;
}

const MainScrollContext = createContext<ScrollRef | null>(null);

export function useMainScroll() {
  const mainScrollRef = useContext(MainScrollContext);
  if (!mainScrollRef) {
    throw new Error('The main scroll ref is not defined');
  }
  return mainScrollRef;
}

export const MainScroll: React.FC<MainScrollProps> = ({ scrollRef, children }) => (
  <MainScrollContext.Provider value={scrollRef}>{children}</MainScrollContext.Provider>
);
