import partialImg from 'assets/images/feedback_partial.svg';
import classnames from 'classnames';
import { Typography } from 'components/typography';
import { useMainScroll } from 'containers/app/root/MainScroll';
import React, { useEffect } from 'react';
import { isHTML } from 'util/isHTML';
import { negativeEmojis, positiveEmojis } from './emojis/index';
import { FeedbackImageProps, FeedbackProps, FeedbackTextProps } from './types';

/**
 * Feedback component
 *
 * Rendered after all formats that can be wrong or right (all expect text and video)
 */
const Feedback: React.FC<FeedbackProps> = ({ type, options, titleColor, rich = false }) => {
  const negativeTitle = type === 'negative' ? options.negativeTitle : options.partialTitle;
  const title = type === 'positive' ? options.positiveTitle : negativeTitle;
  const negativeText = type === 'negative' ? options.negativeText : options.partialText;
  const text = type === 'positive' ? options.positiveText : negativeText;

  const isRich = rich || isHTML(title) || isHTML(text);
  const textIsNotRichNeitherHTML = !rich && !isHTML(text);

  const mainScrollRef = useMainScroll();
  useEffect(() => {
    mainScrollRef?.current?.scrollTo(0, 0);
  }, [mainScrollRef]);

  return (
    <div
      className={classnames('flex w-full p-3.5 justify-center items-center flex-col text-sm overflow-x-hidden', {
        'h-full': !isRich,
      })}
    >
      {/* Feedback Image */}
      <div className='relative flex w-full min-h-[9rem] pt-1/20 justify-center'>
        <FeedbackImage type={type} />
      </div>

      {/* Feedback title */}
      <FeedbackText
        textClassName='text-center text-2xl text-turquoiseBlue uppercase mt-1 p-1 mb-6 w-full animate-punchOut'
        styles={{ animationFillMode: 'forwards', animationDelay: '0.1s' }}
        rich={rich}
        text={title}
        color={titleColor}
      />

      {/* Feedback content */}
      <div
        className='max-w-full p-1 overflow-y-auto opacity-0 animate-fadeIn'
        style={{ animationFillMode: 'forwards', animationDelay: '0.3s' }}
      >
        {!!text && (
          <FeedbackText
            textClassName={classnames('pb-3.5 whitespace-pre-wrap', {
              'text-center': textIsNotRichNeitherHTML,
            })}
            rich={rich}
            text={text}
            noStyles
          />
        )}

        <FeedbackText
          textClassName={classnames('mt-5', {
            'text-center': textIsNotRichNeitherHTML,
          })}
          rich={rich}
          text={options.generalFeedbackText}
        />
      </div>
    </div>
  );
};

/** General feedback image for the Feedback */
const FeedbackImage: React.FC<FeedbackImageProps> = ({ type }) => {
  const emojisLength = type === 'positive' ? positiveEmojis.length : negativeEmojis.length;
  const randomEmoji = React.useMemo(() => Math.floor(Math.random() * emojisLength), [emojisLength]);

  switch (type) {
    case 'positive': {
      return <div data-testid='positive-feedback'>{positiveEmojis[randomEmoji]}</div>;
    }
    case 'negative': {
      return <div data-testid='negative-feedback'>{negativeEmojis[randomEmoji]}</div>;
    }
    case 'partial': {
      return (
        <img
          src={partialImg}
          alt='Partial Feedback'
        />
      );
    }
    default:
      return <></>;
  }
};

/** General feedback text field for the Feedback */
const FeedbackText: React.FC<FeedbackTextProps> = ({
  textClassName,
  rich,
  text,
  color,
  styles = {},
  noStyles = false,
}) => {
  const hasRichText = rich || isHTML(text);

  if (hasRichText) {
    return (
      <Typography
        className={classnames('whitespace-normal', {
          [textClassName]: !noStyles,
        })}
        style={{ ...styles, color }}
        rich
      >
        {text}
      </Typography>
    );
  }

  return (
    <div
      className={textClassName}
      style={{ ...styles, color }}
    >
      {text}
    </div>
  );
};

export default Feedback;
