export enum LTIProviders {
  GOODHABITZ = 'goodhabitz',
}

export interface LTIProvider {
  getSignedRequestUrl?: (url: string) => void;
}

/* eslint-disable camelcase */
export interface SignedGoodHabitzCourse {
  oauth_consumer_key: string;
  oauth_nonce: string;
  oauth_signature_method: string;
  oauth_timestamp: number;
  oauth_version: string;
  lti_message_type: string;
  lti_version: string;
  resource_link_id: string;
  launch_presentation_locale: string;
  user_id: string;
  oauth_signature: string;
}
/* eslint-enable camelcase */

export interface GetLTICourseSettingsSuccessPayload extends SignedGoodHabitzCourse {
  provider: LTIProviders;
}

export interface LTIState {
  goodhabitz: SignedGoodHabitzCourse | null;
  error: string | null;
}

export interface UseLTIProps {
  provider: LTIProviders;
}
