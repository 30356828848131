import classNames from 'classnames';
import React from 'react';
import { animated, config, useSpring } from 'react-spring';
import { SliderProps } from './Slider.types';

export const Slider: React.FC<SliderProps> = ({ className, fade = false, ...rest }) => {
  const [canScroll, setCanScroll] = React.useState(true);
  const showFade = fade && canScroll;
  const props = useSpring({ opacity: showFade ? 1 : 0, config: config.slow });

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const nextCanScroll = target.scrollWidth - target.scrollLeft > target.clientWidth;
    setCanScroll(nextCanScroll);
  };

  return (
    <div
      className='relative'
      data-testid='e2e-slider'
    >
      <div
        className={classNames('flex flex-nowrap overflow-x-auto', className)}
        {...rest}
        onScroll={handleScroll}
      />
      <animated.div style={props} />
    </div>
  );
};
