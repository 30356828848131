import { actions, LoginCredentials } from 'containers/authentication';
import { defaultLocale } from 'containers/language-provider';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { Store } from 'store/types';

import { Login } from './Login';

const authSelector = ({ auth }: Store) => auth;
const profileSelector = ({ profile }: Store) => profile;

const loginSelector = createSelector(authSelector, profileSelector, (auth, profile) => ({
  error: auth.error,
  locale: profile.user.locale || defaultLocale,
  accountLogo: profile.account.logoUrl,
  customLogin: auth.customLogin,
  isAuthorizing: auth.isAuthorizing,
}));

const loginMapDispatchToProps = (dispatch: Dispatch) => ({
  logUserIn: (credentials: LoginCredentials) => actions.login({ credentials }),
  validateCustomLogin: (loginSlug: string) => dispatch(actions.validateCustomLogin(loginSlug)),
});

const ConnectedLogin = connect(loginSelector, loginMapDispatchToProps)(Login);
export const DeprecatedLogin = injectIntl(ConnectedLogin);
