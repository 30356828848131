import { animated, config, useSpring } from 'react-spring';

export const UnlockPadlock = () => {
  const shackleStyles = useSpring({
    loop: { reverse: true, delay: 1000 },
    from: { transform: 'scale(1) translateY(8px) rotate(0deg)' },
    to: { transform: 'scale(1) translateY(0px) rotate(5deg)' },
    config: config.stiff,
  });

  const bodyStyles = useSpring({
    loop: { reverse: true, delay: 1000 },
    from: { transform: 'scale(1) translateY(-10px) rotate(0deg)' },
    to: { transform: 'scale(1) translateY(-5px) rotate(-5deg)' },
    config: config.stiff,
  });

  return (
    <svg
      width='85'
      height='130'
      viewBox='-5 0 95 130'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <animated.g style={{ ...shackleStyles, transformOrigin: '50%' }}>
        <path
          d='M56.5601 44.28V25.029C56.5601 21.2788 55.0704 17.6823 52.4186 15.0305C49.7668 12.3787 46.1703 10.889 42.4201 10.889C38.67 10.889 35.0734 12.3787 32.4216 15.0305C29.7699 17.6823 28.2801 21.2788 28.2801 25.029V38.8355C28.2801 41.8424 25.8425 44.28 22.8356 44.28C19.8287 44.28 17.3911 41.8424 17.3911 38.8355V25.029C17.3911 18.3909 20.0281 12.0247 24.7219 7.33082C29.4158 2.63698 35.782 0 42.4201 0C49.0582 0 55.4244 2.63698 60.1183 7.33082C64.8121 12.0247 67.4491 18.3909 67.4491 25.029V44.28H56.5601Z'
          fill='white'
        />
        <path
          d='M56.5609 25.029V58.5H67.4499V25.029C67.4422 18.3933 64.8028 12.0315 60.1106 7.3393C55.4184 2.64712 49.0566 0.00767522 42.4209 0V10.889C46.1698 10.8932 49.7639 12.3843 52.4147 15.0352C55.0655 17.686 56.5567 21.2801 56.5609 25.029Z'
          fill='#E8EDF6'
        />
      </animated.g>
      <animated.path
        style={{ ...bodyStyles, transformOrigin: '50%' }}
        d='M81.59 129.952H3.251C2.38878 129.952 1.56187 129.609 0.952194 129C0.342513 128.39 0 127.563 0 126.701V61.4189C0.000265163 60.5569 0.342897 59.7303 0.952549 59.1208C1.5622 58.5113 2.38895 58.1689 3.251 58.1689H81.589C82.451 58.1689 83.2778 58.5113 83.8874 59.1208C84.4971 59.7303 84.8397 60.5569 84.84 61.4189V126.701C84.84 127.563 84.4976 128.39 83.8882 128.999C83.2787 129.609 82.452 129.952 81.59 129.952Z'
        fill='#FFA700'
      />
      <animated.path
        style={{ ...bodyStyles, transformOrigin: '50%' }}
        d='M81.5899 58.1689H42.4209V129.952H81.5899C82.4521 129.952 83.279 129.609 83.8887 129C84.4984 128.39 84.8409 127.563 84.8409 126.701V61.4189C84.8406 60.5569 84.498 59.7303 83.8883 59.1208C83.2787 58.5113 82.452 58.1689 81.5899 58.1689Z'
        fill='#FF8300'
      />
      <animated.path
        style={{ ...bodyStyles, transformOrigin: '50%' }}
        d='M52.1991 87.953C52.1995 86.1597 51.7068 84.4008 50.7747 82.8687C49.8427 81.3365 48.5074 80.0902 46.9147 79.266C45.322 78.4417 43.5333 78.0713 41.7443 78.1953C39.9552 78.3192 38.2347 78.9327 36.7709 79.9687C35.3071 81.0047 34.1563 82.4233 33.4445 84.0693C32.7327 85.7153 32.4872 87.5253 32.7348 89.3015C32.9825 91.0776 33.7138 92.7515 34.8488 94.1399C35.9838 95.5284 37.4787 96.578 39.1701 97.174V106.698C39.1912 107.546 39.5428 108.352 40.15 108.945C40.7572 109.537 41.5719 109.868 42.4201 109.868C43.2684 109.868 44.083 109.537 44.6902 108.945C45.2974 108.352 45.6491 107.546 45.6701 106.698V97.174C47.5775 96.4996 49.2292 95.251 50.3983 93.5999C51.5674 91.9488 52.1965 89.9762 52.1991 87.953Z'
        fill='#A76335'
      />
      <animated.path
        style={{ ...bodyStyles, transformOrigin: '50%' }}
        d='M45.6719 106.698V97.1738C47.8482 96.4068 49.6831 94.8949 50.8521 92.9054C52.021 90.916 52.4488 88.5772 52.0596 86.3027C51.6704 84.0283 50.4895 81.9648 48.7255 80.4772C46.9616 78.9895 44.7284 78.1736 42.4209 78.1738V109.948C43.2829 109.948 44.1097 109.605 44.7194 108.996C45.329 108.387 45.6716 107.56 45.6719 106.698Z'
        fill='#8A5430'
      />
    </svg>
  );
};
