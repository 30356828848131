import { Avatar } from 'components/avatar';
import { API_URL } from 'constants/endpoints';
import { FC, MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../store/types';
import { SelectedUserProps } from './NewGroup.types';

export const SelectedUser: FC<SelectedUserProps> = ({ user, onClose }) => {
  const userAvatar = user.image ? `${API_URL}/${user.image}` : '';
  const { account } = useSelector(({ profile }: Store) => profile);

  const handleClose: MouseEventHandler<HTMLButtonElement> = (event) => {
    onClose?.(user, event);
  };

  return (
    <div className='cursor-pointer'>
      <div className='flex flex-col w-12 mr-4 text-center'>
        <Avatar
          src={userAvatar}
          className='w-12 h-12'
          title={user.name}
          disableBorder
          primaryColor={account.primaryColor}
          onClose={handleClose}
        />
        <p className='mt-1 text-xs font-light text-zinc-900'>{user.name}</p>
      </div>
    </div>
  );
};
