import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { History } from 'history';
import cookie from 'js-cookie';
import { actions as homeActions } from 'pages/home';
import { actions as registerActions } from 'pages/register';
import qs from 'qs';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { Store as ReduxStore } from 'store/types';
import { clearLegacyAccessTokenCookie, clearSSOCookies } from 'util/cookieHelper';
import enhancedMatchPath from 'util/enhancedMatchPath';
import { getStorage } from 'util/storage';
import {
  AuthRedirectMessageTypes,
  AuthStore,
  ExchangeCodeForAuthTokenPayload,
  ExchangeCodeForAuthTokenSuccessPayload,
  GetUserInfoSuccessPayload,
  GetUserPayload,
  LoginPayload,
  LoginUserSuccessPayload,
  LogoutSuccessPayload,
  ResetPasswordPayload,
  SetActiveAccountPayload,
  TokenLoginPayload,
  User,
  ValidateCustomLoginSuccess,
  ValidatePreviewTokenPayload,
  ValidatePreviewTokenSuccessPayload,
} from './Authentication.types';

export const SSO_KEY = 'sso';
export const XT_PROV_URL = 'mt-xt-prov-url';
export const TOKEN_MAX_AGE = 90; // <-- 3 months

// Initial State
const initialState: AuthStore = {
  sso: cookie.get(SSO_KEY) === 'true',
  // ! This can only be set to false when we migrate to a better pending state flow
  // ! The problem is that after exchanging the code, the user is not fully authorized yet
  // ! because we need its roles from the get_user_info request.
  isAuthorizing: true,
  error: '',
  forgot: {
    sent: false,
  },
  reset: {
    success: false,
    error: false,
    message: '',
  },
  preview: {
    isValidating: false,
    isValidToken: false,
  },
  customLogin: {
    accountID: '',
    accountLogo: '',
    primaryColor: '',
    sidebarColor: '',
    splashScreen: '',
    hasExternalIdProvider: false,
  },
  features: {
    newsfeed: false,
  },
};

const slice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    login: (state, _action: PayloadAction<LoginPayload>) => ({
      ...state,
      isAuthorizing: true,
      error: '',
    }),
    loginSuccess: (state, _action: PayloadAction<LoginUserSuccessPayload>) => ({
      ...state,
      isAuthorizing: false,
      error: '',
    }),
    loginError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      isAuthorizing: false,
      error: payload.message,
    }),
    exchangeCodeForAuthToken: (state, _action: PayloadAction<ExchangeCodeForAuthTokenPayload>) => ({
      ...state,
      isAuthorizing: true,
      error: '',
    }),
    exchangeCodeForAuthTokenSuccess: (state, _action: PayloadAction<ExchangeCodeForAuthTokenSuccessPayload>) => ({
      ...state,
      error: '',
    }),
    exchangeCodeForAuthTokenError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      error: payload.message,
    }),
    tokenLogin: (state, _action: PayloadAction<TokenLoginPayload>) => ({
      ...state,
      preview: {
        ...state.preview,
        isValidating: true,
      },
      isAuthorizing: true,
    }),
    tokenLoginSuccess: (state, _action: PayloadAction<ValidatePreviewTokenSuccessPayload>) => ({
      ...state,
      preview: {
        ...state.preview,
        isValidating: false,
        isValidToken: true,
      },
      isAuthorizing: false,
      error: '',
    }),
    tokenLoginError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      preview: {
        ...state.preview,
        isValidating: false,
        isValidToken: false,
      },
      error: payload,
      isAuthorizing: false,
    }),
    logout: (state) => state,
    logoutSuccess: (state, _action: PayloadAction<LogoutSuccessPayload>) => ({
      ...state,
      isAuthorizing: false,
      error: '',
    }),
    logoutError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      error: payload,
    }),
    resetForgotPassword: (state) => ({
      ...state,
      forgot: {
        ...state.forgot,
        sent: false,
      },
    }),
    validatePreviewToken: (state, _action: PayloadAction<ValidatePreviewTokenPayload>) => ({
      ...state,
      preview: {
        ...state.preview,
        isValidating: true,
      },
      isAuthorizing: true,
    }),
    validatePreviewTokenSuccess: (state, { payload }: PayloadAction<ValidatePreviewTokenSuccessPayload>) => ({
      ...state,
      preview: {
        ...state.preview,
        isValidating: false,
        isValidToken: true,
        activePathId: payload.activePathId,
        activePathType: payload.activePathType,
      },
      error: '',
      isAuthorizing: false,
    }),
    validatePreviewTokenError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      preview: {
        ...state.preview,
        isValidating: false,
        isValidToken: false,
      },
      error: payload,
      isAuthorizing: false,
    }),
    resetPassword: (state, _action: PayloadAction<ResetPasswordPayload>) => state,
    resetPasswordSuccess: (state, _action: PayloadAction<AnyAction>) => ({
      ...state,
      reset: {
        ...state.reset,
        success: true,
        error: false,
        message: '',
      },
    }),
    resetPasswordError: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      reset: {
        ...state.reset,
        success: false,
        error: true,
        message: payload,
      },
    }),
    validateCustomLogin: (state, _action: PayloadAction<string>) => ({
      ...state,
      isAuthorizing: false,
      customLogin: {
        ...state.customLogin,
        accountID: undefined,
      },
    }),
    validateCustomLoginSuccess: (state, { payload }: PayloadAction<ValidateCustomLoginSuccess>) => ({
      ...state,
      isAuthorizing: false,
      customLogin: {
        ...state.customLogin,
        accountID: payload.accountID,
        accountLogo: payload.accountLogo,
        primaryColor: payload.primaryColor,
        sidebarColor: payload.sidebarColor,
        splashScreen: payload.splashScreen,
        hasExternalIdProvider: payload.hasExternalIdProvider,
      },
    }),
    validateCustomLoginError: (state, _action: PayloadAction<Error>) => ({
      ...state,
      isAuthorizing: false,
      customLogin: {
        ...state.customLogin,
        accountID: undefined,
      },
    }),
    getUser: (state, _action: PayloadAction<GetUserPayload>) => ({
      ...state,
      isAuthorizing: true,
      error: '',
    }),
    getUserSuccess: (state, _action: PayloadAction<User>) => ({
      ...state,
      isAuthorizing: false,
      error: '',
    }),
    getUserError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      isAuthorizing: false,
      error: payload.message,
    }),
    getUserInfo: (state) => ({
      ...state,
      isAuthorizing: true,
      error: '',
    }),
    getUserInfoSuccess: (state, { payload }: PayloadAction<GetUserInfoSuccessPayload>) => ({
      ...state,
      features: {
        ...state.features,
        newsfeed: payload.users.me.lastAccount.newsfeedEnabled,
      },
      sso: Boolean(payload.users.me.sso),
      isAuthorizing: false,
      error: '',
    }),
    getUserInfoError: (state, { payload }: PayloadAction<Error>) => ({
      ...state,
      isAuthorizing: false,
      error: payload.message,
    }),
    setActiveAccount: (state, _action: PayloadAction<SetActiveAccountPayload>) => state,
    setActiveAccountSuccess: (state) => state,
    setActiveAccountError: (state, _action: PayloadAction<Error>) => state,
  },
  extraReducers: (builder) => {
    builder
      .addCase(homeActions.getUserLecos, (state) => {
        state.isAuthorizing = true;
        state.error = '';
      })
      .addCase(homeActions.getFeaturedPath, (state) => {
        state.isAuthorizing = true;
        state.error = '';
      })
      .addCase(homeActions.getUserLecosSuccess, (state) => {
        state.isAuthorizing = false;
      })
      .addCase(homeActions.getUserLecosError, (state) => {
        state.isAuthorizing = false;
      })
      .addCase(homeActions.getFeaturedPathSuccess, (state) => {
        state.isAuthorizing = false;
      })
      .addCase(homeActions.getFeaturedPathError, (state) => {
        state.isAuthorizing = false;
      })
      .addCase(registerActions.validateInviteSuccess, (state) => {
        state.isAuthorizing = false;
        state.error = '';
      });
  },
});

/**
 * @deprecated READ DESCRIPTION
 *
 * This function forces an authenticated request to the /manifest API endpoint.
 * This fixes issues caused by the new Web Manifest flow on iOS > 15.4.
 *
 * "Web App Manifest improvements include ensuring the browser always fetches the manifest file during page load
 * instead of when the user chooses to "Add to Home Screen" from the Share menu. This approach improves reliability,
 * and also allows a manifest file to define the characteristics of a webpage in Safari."
 *
 * Read more at: https://webkit.org/blog/12445/new-webkit-features-in-safari-15-4/
 */
function refreshIfIOS() {
  const ua = window.navigator.userAgent;
  const iOS = Boolean(/iPad/i.exec(ua)) || Boolean(/iPhone/i.exec(ua));
  if (iOS) {
    setTimeout(() => window.location.reload());
  }
}

/**
 * Auth/Login store middleware
 *
 * Intercepts login and auth success and errors and sets or
 * removes a cookie accordingly. This way we can keep users
 * logged in if the user resets the application.
 * i.e. when the page is reloaded.
 */
export const createAuthMiddleware = (history: History) => () => (next: Dispatch) => (action: AnyAction) => {
  next(action);

  switch (action.type) {
    case actions.exchangeCodeForAuthTokenSuccess.type: {
      clearLegacyAccessTokenCookie();

      refreshIfIOS();
      history.replace('/');
      break;
    }
    case actions.exchangeCodeForAuthTokenError.type: {
      history.replace(`/login?error=${actions.exchangeCodeForAuthTokenError.type}`);
      break;
    }
    case actions.logoutSuccess.type: {
      const typedAction = action as PayloadAction<LogoutSuccessPayload>;
      getStorage('local').clear();
      const { redirectUri, sso, ssoRedirectUri } = typedAction.payload;

      const redirectUriQueryString = qs.stringify({
        sourceClientId: process.env.REACT_APP_CLIENT_ID,
        ...(sso && {
          ssoLogoutRedirectUri:
            ssoRedirectUri ??
            `${String(process.env.REACT_APP_APP_URL)}/ssomessage?messageType=${AuthRedirectMessageTypes.LOGOUT}`,
        }),
      });
      const redirectUrl = new URL(`${redirectUri}?${redirectUriQueryString}`);

      if (sso && ssoRedirectUri) {
        cookie.remove(XT_PROV_URL);
      }

      window.location.href = redirectUrl.href;
      break;
    }

    case actions.validateCustomLoginSuccess.type: {
      const typedAction = action as PayloadAction<ValidateCustomLoginSuccess>;
      if (typedAction.payload.hasExternalIdProvider) {
        // Same for SSO cookies
        clearSSOCookies();
      }

      break;
    }

    case actions.validateCustomLoginError.type: {
      history.replace('/login');
      break;
    }

    case actions.getUserInfoSuccess.type: {
      const typedAction = action as PayloadAction<GetUserInfoSuccessPayload>;

      const { me } = typedAction.payload.users;
      const { sso } = me;

      cookie.set(SSO_KEY, String(!!sso), {
        path: '/',
        expires: TOKEN_MAX_AGE * 2,
      });

      // Set user context in raven
      Sentry.setUser({ id: me.id, email: me.email });

      break;
    }

    default:
  }
};

const ALLOWED_PREVIEW_ROUTES = [
  { path: '/preview/:token' },
  { path: '/path' },
  { path: '/login' },
  { path: '/', exact: true },
  { path: '/path/:pathID/:testType/:levelID/:skillID?/:moduleID?' },
  { path: '/path/:pathID/level/:levelID/skill/:skillID' },
  { path: '/learning-path/:pathID/level/:levelID/skill/:skillID' },
  { path: '/learning-path/:learningPathId/level/:levelId/skill/:skillId/module/:moduleId' },
  { path: '/crash-course/:crashCourseId/module/:moduleId' },
  { path: '/learning-path/:learningPathId/level/:levelId/skill/:skillId/test/:learningTestId' },
  { path: '/crash-course/:crashCourseId/test/:learningTestId' },
  { path: '/learning-path/:learningPathId/level/:levelId/test/:learningTestId' },
  { path: '/learning-path/:learningPathId/retention-test/:learningTestId' },
  { path: '/crash-course/:crashCourseId/retention-test/:learningTestId' },
  { path: '/video/:videoId' },
];

/**
 * Handles when a preview user is detected
 */
export const cretePreviewMiddleware =
  (history: History) => (store: MiddlewareAPI<Dispatch, ReduxStore>) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    const isAllowedRoute = enhancedMatchPath(history.location.pathname, ALLOWED_PREVIEW_ROUTES);

    // Only run the middleware code  inside the allowed routes
    if (!isAllowedRoute) {
      const state: ReduxStore = store.getState();
      const isPreviewUser = state.profile.user.role === 'preview';

      // Redirects the `preview` user if he explicitly goes to a forbiden route
      if (isPreviewUser) {
        history.push('/path');
      }
    }
  };

export const { actions } = slice;
export default slice.reducer;
