import { FormatType } from 'containers/FormatScreen';
import { Locale } from 'containers/language-provider';

export interface GetLearningTestFormatsPayload {
  learningTestId: string;
}

export interface GetModuleFormatsPayload {
  userId: number;
  moduleId: number;
  locale: Locale;
}

interface LearningFormatType {
  id: number;
  title: FormatType;
}

interface LearningFormatResponsesMetadata {
  x: string;
  y: string;
}

interface LearningFormatResponse {
  id: number;
  order: number;
  imageUrl: string | null;
  metadata?: LearningFormatResponsesMetadata;
  title: string;
}

interface LearningFormatQuestionGroup {
  id: number;
  order: number;
  backgroundImage: string | null;
  title: string;
}

export interface LearningFormat {
  id: number;
  tags: string[];
  order: number;
  priority: boolean;
  batch: number;
  title: string;
  summary: string | null;
  question: string;
  buttonText: string | null;
  positiveTitle: string | null;
  positiveText: string | null;
  negativeTitle: string | null;
  negativeText: string | null;
  partialTitle: string | null;
  partialText: string | null;
  generalFeedbackText: string | null;
  link: string | null;
  url: string | null;
  imageUrl: string | null;
  rightButtonText: string | null;
  wrongButtonText: string | null;
  formatType: LearningFormatType;
  formatResponses: LearningFormatResponse[];
  questionGroups: LearningFormatQuestionGroup[];
}

export type LearningFormats = LearningFormat[];

export enum LearningTestTypes {
  SKILL = 'skill',
  LEVEL = 'level',
  RETENTION = 'retention',
  MODULE = 'module',
  LEARNING_MOMENT = 'learning-moment',
}

export enum LearningFormatTestTypes {
  MOMENT = 'moment',
  TEST = 'test',
}

interface LearningTestFormat {
  formatId: number;
  order: number;
  done: boolean;
  passed: boolean;
  format: LearningFormat;
}

export type LearningTestFormats = LearningTestFormat[];

export interface LearningTestFormatsResponse {
  id: string;
  type: LearningTestTypes;
  learningTestFormats: LearningTestFormats;
}

export interface GetRetentionTestPayload {
  userId: number;
  lepaId: number;
}

export interface ModuleTestUrlParams {
  moduleId: string;
  skillId: string;
  levelId: string;
  learningPathId?: string;
  crashCourseId?: string;
}

export interface SkillTestUrlParams {
  learningTestId: string;
  skillId: string;
  levelId: string;
  learningPathId?: string;
  crashCourseId?: string;
}

export interface LevelTestUrlParams {
  learningTestId: string;
  levelId: string;
  learningPathId: string;
}

export interface RetentionTestUrlParams {
  learningTestId: string;
  learningPathId?: string;
  crashCourseId?: string;
}

export interface LearningMomentUrlParams {
  learningMomentId: string;
}

interface BaseLearningAnswer {
  startTime: number;
  endTime: number;
  answer: number | number[] | Answer[];
}

export interface AnswerPayload extends BaseLearningAnswer {
  learningFormatId: number;
  formatType: FormatType;
}

export interface Answer {
  id: number;
  options: number[];
}

export interface SubmitLearningFormatAnswerBody extends BaseLearningAnswer {
  formatType: FormatType;
}

export interface SubmitLearningFormatAnswerPayload {
  learningPathId: number;
  learningFormatId: number;
  body: SubmitLearningFormatAnswerBody;
}

interface CompletionUnlockedBadge {
  id: number;
  tier: number;
  required: number;
  typeId: number;
}

export interface SubmitLearningFormatAnswerResponse {
  isCorrect: boolean;
  correctOptions: number[];
  momentCompletion?: Completion;
}

export interface SubmitLearningTestAnswerPayload {
  learningPathId: number;
  learningTestId: string;
  learningFormatId: number;
  body: BaseLearningAnswer;
  isLastFormat?: boolean;
}

export interface NextTest {
  type: LearningTestTypes | LearningFormatTestTypes;
  id: number | string;
}

export interface Completion {
  testType?: LearningTestTypes;
  moduleCreditReached?: boolean;
  threshold?: number;
  passed: boolean;
  xp: number;
  percentage: number;
  learningPathDone: boolean;
  unlockedPaths: number[];
  unlockedBadges: CompletionUnlockedBadge[];
  next: NextTest | null;
}

export interface SubmitLearningTestAnswerResponse {
  isCorrect: boolean;
  correctOptions: number[];
  testCompletion: Completion | null;
}
