import capSrc from 'assets/images/test_ready_cap.svg';
import classnames from 'classnames';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { LepaTypes } from 'pages/home/Home.types';
import React from 'react';
import { doNothing } from 'util/doNothing';
import TestBanner from './TestBanner';
import { BaseProps, BaseState, ModuleShapesModals } from './types';

/**
 * This is the base to the shape animations
 * It will draw lines on the canvas and animate
 * them from one point to another if the case.
 *
 * @module ModuleShape/Base
 */
class Base<TProps> extends React.Component<TProps & BaseProps, BaseState> {
  public static defaultProps = {
    onNodeClick: doNothing,
  };

  public state: BaseState = {
    width: 0,
    height: 0,
    animate: false,
    showLocks: [],
  };

  /** Canvas 2D rendering context */
  public ctx: CanvasRenderingContext2D | null = null;

  public timer?: number | NodeJS.Timeout;

  public canvasRef: React.RefObject<HTMLCanvasElement> | null = React.createRef();

  /**
   * We store the context reference here and set the canvas
   * width and height to fit it's parent. Then we call `drawShape`
   * method which have to be implemented by it's children class.
   *
   * Note: children can not have `state` or implement `componentDidMount`
   * and `componentWillUnmount` methods as it will override the Base class ones.
   */
  public componentDidMount() {
    const canvas = this.canvasRef;

    if (canvas && canvas.current) {
      const parent = canvas.current.parentElement;

      if (parent) {
        const parentPos = parent.getBoundingClientRect();
        this.ctx = canvas.current.getContext('2d');

        this.setState({ width: parentPos.width, height: parentPos.height }, () => this.drawShape());
      }
    }
  }

  /**
   * We need to clear the interval when the component unmounts as we will not
   * need it if the component is not mounted.
   */
  public componentWillUnmount() {
    this.timer && clearInterval(this.timer as number);
  }

  /**
   * Point
   * @typedef {object} point
   * @prop {number} x - horizontal Axix of the canvas
   * @prop {number} y - vertical Axix of the canvas
   */

  /**
   * Line
   * @typedef {object} line
   * @prop {point} from - The start point of the line
   * @prop {point} to - The end point of the line
   * @prop {object} props - Context properties to be applied before drawing the line
   */

  /**
   * Animates the shape lines from one point to another
   * @param {line[]} lines - The lines array to be drawn, you should pass animate true if animated
   * @param {number} duration - The duration in milliseconds of the lines animation
   * @param {function} callback - Function to be called when the animation is done
   */
  public animateLines(lines: any[], duration: number, callback: (...args: any) => void) {
    const steps = 100;
    const animated: any[] = [];
    const notAnimated: any[] = [];

    for (let i = lines.length - 1; i >= 0; i -= 1) {
      const line = { ...lines[i] };

      if (!line.animated) {
        notAnimated.push(line);
        continue;
      }

      line.incX = (line.to.x - line.from.x) / steps;
      line.incY = (line.to.y - line.from.y) / steps;
      line.currentX = line.from.x;
      line.currentY = line.from.y;

      animated.push(line);
    }

    const { width, height } = this.state;
    let step = 0;

    this.timer = window.setInterval(() => {
      step += 1;

      this.ctx && this.ctx.clearRect(0, 0, width, height);

      this.drawLines(notAnimated);

      animated.forEach((animatedItem) => {
        const haveProps = typeof animatedItem.props === 'object';

        if (haveProps && this.ctx) {
          this.ctx && this.ctx.save();

          const { props } = animatedItem;
          for (const prop of Object.keys(props)) {
            // @ts-ignore: Canvas props
            this.ctx[prop] = props[prop];
          }
        }

        animatedItem.currentX += animatedItem.incX;
        animatedItem.currentY += animatedItem.incY;
        const line = animatedItem;
        this.drawLine(line.from.x, line.from.y, line.currentX, line.currentY);

        if (haveProps && this.ctx) {
          this.ctx.restore();
        }
      });

      if (step === steps) {
        clearInterval(this.timer as number);
        if (typeof callback === 'function') {
          return callback();
        }
      }
      return true;
    }, duration / steps);
  }

  /**
   * It draws an array of lines in the canvas
   * @param {line[]} lines - The lines to be drawn
   */
  public drawLines(lines: any) {
    const i = lines.length;
    if (i > 0) {
      lines.forEach((line: any) => {
        const haveProps = typeof line.props === 'object';

        if (haveProps && this.ctx) {
          this.ctx.save();

          const { props } = line;
          for (const prop of Object.keys(props)) {
            // @ts-ignore: Canvas props
            this.ctx[prop] = props[prop];
          }
        }

        this.drawLine(line.from.x, line.from.y, line.to.x, line.to.y);

        if (haveProps && this.ctx) {
          this.ctx.restore();
        }
      });
    }
  }

  /**
   * It draws a single line in the canvas
   * @param {number} x1 - Where to start in the horizontal Axix
   * @param {number} y1 - Where to end in the horizontal Axix
   * @param {number} x2 - Where to start in the vertical Axix
   * @param {number} y2  - Where to end in the vertical Axix
   */
  public drawLine(x1: number, y1: number, x2: number, y2: number) {
    if (this.ctx) {
      this.ctx.beginPath();
      this.ctx.moveTo(x1, y1);
      this.ctx.lineTo(x2, y2);
      this.ctx.stroke();
    }
  }

  public drawArch({ reverse = false, startAngle = 0.5, stopAngle = 1.5 } = {}) {
    if (this.ctx) {
      this.ctx.beginPath();
      const { width, height } = this.ctx.canvas;
      this.ctx.arc(width / 2, height / 2, width / 2.3, Math.PI * startAngle, Math.PI * stopAngle, reverse);
      this.ctx.stroke();
    }
  }

  /**
   * Calls the passed prop onNodeClick when node is clicked
   */
  public nodeClicked = (id: number | string) => () => {
    this.props.onNodeClick('moduleTest', id);
  };

  /**
   * Calls the passed prop onNodeClick when Skill test icon is clicked
   */
  public hatClicked = () => {
    if (this.props.skillTest) {
      this.props.onNodeClick('skillTest');
    }
  };

  public openPopup = (type: ModuleShapesModals) => () => {
    this.renderPopup(type);
  };

  /** It renders the final test cap if all modules are done */
  public renderCap(classCap: string) {
    const { modules, intl, skillTest, color, pathType } = this.props;

    const isCrashCourse = pathType === LepaTypes.CRASH_COURSE;

    let showCap = !isCrashCourse && skillTest;

    modules.forEach((module) => {
      if (!module.passed) {
        showCap = false;
      }
    });

    const capStyle = !skillTest
      ? { opacity: 0.2, cursor: 'not-allowed', backgroundImage: `url(${capSrc})` }
      : { opacity: 1, backgroundImage: `url(${capSrc})` };

    return showCap ? (
      <div
        className={classnames(classCap, 'wt-skill-test')}
        style={capStyle}
        onClick={this.hatClicked}
      >
        <TestBanner
          className='absolute -bottom-3.5 -left-8'
          text={intl.formatMessage({ id: 'comp.testBanner.text' })}
          color={color}
        />
      </div>
    ) : null;
  }

  public renderPopup(popupType: ModuleShapesModals) {
    const { enqueueModal } = this.props;

    switch (popupType) {
      case ModalTypes.LEMO_LOCKED: {
        return enqueueModal({ type: ModalTypes.LEMO_LOCKED });
      }
      case ModalTypes.LEMO_LIMIT_REACHED: {
        return enqueueModal({ type: ModalTypes.LEMO_LIMIT_REACHED });
      }
      default:
        return null;
    }
  }

  /** Draws a specific shape - will be override by childs */
  public drawShape = (): void => {
    throw new Error('Method not implemented.');
  };

  /** Returns the ref with the biggest height */
  public findBiggestRef = (refs: React.RefObject<any>[], length: number) => {
    let biggestRef = 0;

    let i = 0;
    for (i; i < length; i += 1) {
      if (refs[i].current.clientHeight > biggestRef) {
        biggestRef = refs[i].current.clientHeight;
      }
    }

    return biggestRef;
  };
}

export default Base;
