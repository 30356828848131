import classnames from 'classnames';
import ModuleStar from 'components/ModuleStar';
import PopupStar from 'components/PopupStar';
import { Avatar } from 'components/avatar';
import { SvgIcon } from 'components/svg-icon';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { getColorFromScore } from 'pages/skill';
import { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';
import tinyColor from 'tinycolor2';
import { isOdd } from 'util/isOdd';
import Base from '../Base';
import { PolygonType } from '../types';
import { LineProps } from './types';

class Line extends Base<LineProps> {
  public render() {
    const { modulesAmount, color, avatar } = this.props;
    const { width, height } = this.state;
    return (
      <div
        className='relative w-64 text-greyish'
        style={{
          /** height will scale based on the amount of modules available */
          height: 90 * modulesAmount,
        }}
      >
        <canvas
          ref={this.canvasRef}
          width={width}
          height={height}
        />

        {this.renderNodes()}

        <div className='mb-5 -translate-y-1/20'>
          <Avatar
            className='mb-5 -translate-y-1/20'
            borderColor={color}
            src={avatar}
            onClick={this.handleAvatarClick}
            editable
          />
        </div>
        {this.renderCap('')}
      </div>
    );
  }

  /**
   * This method needs to be implemented so the Base parent
   * can call it on `componentDidMount`
   */
  public drawShape = () => {
    const { animationIndex, animateUnlocks, modules } = this.props;
    if (animationIndex >= 0 && animationIndex <= modules.length - 1 && animateUnlocks) {
      this.setState({ animate: true }, () =>
        this.animateLines(this.getLine(), 800, () => this.setState({ animate: false, showLocks: [] })),
      );
    } else {
      this.drawLines(this.getLine());
    }
  };

  /**
   * It returns a line shape with no animations
   */
  public getLine() {
    const { width, height } = this.state;
    const { color, modules } = this.props;
    const lineWidth = 5;

    // Stroked line
    if (this.ctx && color) {
      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = lineWidth;
    }

    const moduleHeight = (height - lineWidth) / modules.length;

    const reversedModules = [...modules].reverse();

    return reversedModules.map((module, index) => ({
      from: { x: width / 2, y: moduleHeight * index },
      to: { x: width / 2, y: moduleHeight * (index + 1) },
      props: { globalAlpha: !module.unlocked ? 0.3 : 1 },
    }));
  }

  /**
   * It will animate the unlocking of the 2nd and 3rd module
   */
  public unlockFirstLevel = () => {
    /**
     * We set state to change the look of the locks as the lines are
     * being animated. And animate to true to show the stars.
     */
    this.setState({ animate: true, showLocks: [1, 2] }, () => {
      const { width, height } = this.state;
      const { color } = this.props;
      const lineWidth = 5;

      // Stroked triangle
      if (this.ctx && color) {
        this.ctx.strokeStyle = color;
        this.ctx.lineWidth = lineWidth;
      }

      const props = { globalAlpha: 0.3 };

      /**
       * The line sides to be drawn
       */
      const square: PolygonType[] = [
        { from: { x: lineWidth, y: lineWidth }, to: { x: width - lineWidth, y: lineWidth }, props },
        { from: { x: lineWidth, y: lineWidth }, to: { x: lineWidth, y: height - lineWidth }, props },
        { from: { x: width - lineWidth, y: lineWidth }, to: { x: width - lineWidth, y: height - lineWidth }, props },
        { from: { x: width - lineWidth, y: height - lineWidth }, to: { x: lineWidth, y: height - lineWidth }, props },
      ];

      /**
       * We loop the sides and add props that will show better looking
       * animation.
       */
      const animatedOptions = { animated: true, props: { globalAlpha: 1 } };
      const animatedSquare = square.reduce((acc: PolygonType[], line, indx) => {
        if (indx < 2) {
          return [...acc, line, { ...line, ...animatedOptions }];
        }
        return [...acc, line];
      }, []);

      /**
       * Call `animateLines` that is made available by the Base parent class
       * and use the callback to set state at the end.
       */
      this.animateLines(animatedSquare, 800, () => this.setState({ animate: false, showLocks: [] }));
    });
  };

  /**
   * It will animate the unlocking of the last module,
   * same as first level but with different lines and nodes animated.
   */
  public unlockSecondLevel = () => {
    this.setState({ animate: true, showLocks: [3] }, () => {
      const { width, height } = this.state;
      const { color } = this.props;
      const lineWidth = 5;

      // Stroked triangle
      if (this.ctx && color) {
        this.ctx.strokeStyle = color;
        this.ctx.lineWidth = lineWidth;
      }
      const props = { globalAlpha: 0.3 };

      const square: PolygonType[] = [
        {
          from: { x: lineWidth, y: lineWidth },
          to: { x: width - lineWidth, y: lineWidth },
          props: { globalAlpha: 1 },
        },
        {
          from: { x: lineWidth, y: lineWidth },
          to: { x: lineWidth, y: height - lineWidth },
          props: { globalAlpha: 1 },
        },
        {
          from: { x: width - lineWidth, y: lineWidth },
          to: { x: width - lineWidth, y: height - lineWidth },
          props,
        },
        {
          from: { x: lineWidth, y: height - lineWidth },
          to: { x: width - lineWidth, y: height - lineWidth },
          props,
        },
      ];

      const animatedOptions = { animated: true, props: { globalAlpha: 1 } };
      const animatedSquare = square.reduce((acc: PolygonType[], line, indx) => {
        if (indx >= 2) {
          return [...acc, line, { ...line, ...animatedOptions }];
        }
        return [...acc, line];
      }, []);

      this.animateLines(animatedSquare, 800, () => this.setState({ animate: false, showLocks: [] }));
    });
  };

  /**
   * It renders the nodes that can be clicked and also will animate
   */
  renderNodes() {
    const { modules, animationIndex, moduleCreditReached, animationColor, color, hasUnlockAnimation, bounce } =
      this.props;
    const { animate } = this.state;
    const { showLocks } = this.state;

    const labelStyles = {
      left: {
        'top': '50%',
        'transform': 'translateY(-50%)',
        'left': 50,
        color,
        'text-align': 'left',
      },
      right: {
        'top': '50%',
        'transform': 'translateY(-50%)',
        'right': 50,
        color,
        'text-align': 'right',
      },
    };

    // Because modules array gets reversed next, we need to match animated module by id instead of index.
    const animatedModuleId = animationIndex > 0 && animationIndex < modules.length ? modules[animationIndex].id : '-1';

    const reversedModules = [...modules].reverse();

    return reversedModules.map((module, index) => {
      const { id, title, done, passed, unlocked, percentage: score } = module;
      const unlocking = showLocks.includes(index);
      const limited = moduleCreditReached && !done;
      const popupType = !unlocked ? ModalTypes.LEMO_LOCKED : ModalTypes.LEMO_LIMIT_REACHED;
      const moduleStarColor = getColorFromScore(score);
      const brightColor = tinyColor(color).brighten();
      const darkColor = tinyColor(color).darken();

      const shouldStartUnlockAnimate = hasUnlockAnimation && passed;

      const style: CSSProperties = {
        position: 'absolute',
        top: `${(100 * index) / modules.length}%`,
        left: '50%',
        transform: 'translate(-50%, -50%)',
      };

      const baseColor = !unlocked ? '#c3c3c3' : color;
      const brightBaseColor = unlocking ? brightColor : baseColor;
      const finalColor = passed ? darkColor : brightBaseColor;

      return (
        <div
          key={`line-module-${String(module.id)}`}
          style={style}
          className={!unlocked ? 'locked-node' : 'unlocked-node'}
        >
          <SvgIcon
            color={String(finalColor)}
            size={50}
            name='circle'
            className={classnames({
              'animate-bounce': bounce && unlocked,
            })}
            style={{ pointerEvents: 'none' }}
          />

          {unlocking || limited || !unlocked ? (
            <SvgIcon
              className='absolute top-0 left-0 translate-3d-[50%,50%,0]'
              color='#fff'
              size={25}
              name={unlocking ? 'lock-open' : 'lock'}
              onClick={this.openPopup(popupType)}
            />
          ) : (
            <SvgIcon
              className='absolute top-0 left-0 text-aquaMarine fill-current opacity-0 transition-opacity duration-200 ease-in-out translate-3d-[-10%,-10%,0] cursor-pointer'
              size={50}
              name='circle'
              onClick={this.nodeClicked(id)}
            />
          )}

          {shouldStartUnlockAnimate && (
            <ModuleStar
              className='absolute top-0 left-0 translate-3d-[75%,75%,0] pointer-events-none'
              color={moduleStarColor}
            />
          )}
          <div
            className={classnames('absolute w-40 text-xs', {
              'text-turquoiseBlue': unlocked && !passed,
              'text-ocean': passed,
            })}
            style={labelStyles[isOdd(index) ? 'left' : 'right']}
          >
            {title}
          </div>

          {shouldStartUnlockAnimate && animationColor !== 'ruby' && (
            <div
              className={classnames(
                'absolute w-40 text-xs',
                'absolute bg-white text-turquoiseBlue text-base pointer-events-none',
                {
                  'pb-3 translate-3d-[-25%,-200%,0]': index <= 1,
                  'pb-2.5 -translate-x-1/4': index > 1,
                  'opacity-1 transition-opacity duration-200 ease-in-out': animate && animationIndex === index,
                  'opacity-0 transition-opacity duration-700 ease-in-out delay-200':
                    !animate || animatedModuleId !== id,
                },
              )}
            >
              {animationColor === 'diamond' && <FormattedMessage id='comp.moduleShape.finishedDiamond' />}
              {animationColor === 'gold' && <FormattedMessage id='comp.moduleShape.finishedGold' />}
              {animationColor === 'silver' && <FormattedMessage id='comp.moduleShape.finishedSilver' />}
            </div>
          )}

          {shouldStartUnlockAnimate && (
            <PopupStar
              color={animationColor}
              className={classnames('absolute top-4/10 left-1/2 w-20 h-16 pointer-events-none', {
                'opacity-1 translate-3d-scale-[-50%,-50%,0,1] transition-all duration-200 ease-in-out':
                  animate && animatedModuleId === id,
                'opacity-0 translate-3d-scale-[-50%,-50%,0,0] transition-all duration-700 ease-in-out':
                  !animate || animatedModuleId !== id,
              })}
            />
          )}
        </div>
      );
    });
  }

  private handleAvatarClick = () => {
    this.props.history.push('/profile');
  };
}

export default Line;
