import { defaultLocale, Locale } from 'containers/language-provider';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { getRelativeDate } from 'util/dateFormater';
import { RelativeDateProps as Props } from './RelativeDate.types';

export const RelativeDate: React.FC<Props> = ({ date }) => {
  const locale = useSelector<Store, Locale>(({ profile }) => profile.user.locale || defaultLocale);
  const relativeDate = getRelativeDate(date, locale);

  return <span className='text-sm text-greyish select-none mb-0.5'>{relativeDate}</span>;
};
