import classnames from 'classnames';
import * as React from 'react';
import classes from '../ProgressionBar.module.scss';
import { LinearProgressionBarProps as Props } from '../types';

const LinearProgressionBar: React.FC<Props> = ({ className, percentage, progressStyle, color, ...rest }) => (
  <div
    className={classnames(classes.linearProgress, className)}
    data-testid='e2e-linear-progressbar'
    {...rest}
  >
    <div
      data-testid='progress-percentage'
      className={classes.linearProgressBar}
      style={{
        width: `${percentage}%`,
        backgroundColor: color,
        ...progressStyle,
      }}
    />
  </div>
);

export default LinearProgressionBar;
