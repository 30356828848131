import classnames from 'classnames';
import * as React from 'react';
import theme from 'theme';
import classes from '../ProgressionBar.module.scss';
import { StepsProgressionBarProps as Props } from '../types';

const StepProgressionBar: React.FC<Props> = ({ className, steps, current, variant = 'default', color }) => {
  const stepEls = [];

  for (let i = steps - 1; i >= 0; i -= 1) {
    const isCurrent = i <= current - 1;

    stepEls.push(
      <div
        key={`step-${i}`}
        className={classnames(classes.step, {
          [classes.current]: isCurrent,
          [classes.circularStep]: variant === 'circular',
        })}
        style={{
          backgroundColor: isCurrent ? color || theme.colors.turquoiseBlue : '',
        }}
      />,
    );
  }

  stepEls.reverse();

  return (
    <div
      className={classnames(classes.root, className, 'select-none px-4 text-center')}
      data-testid='e2e-step-progressbar'
    >
      {stepEls}
    </div>
  );
};

export default StepProgressionBar;
