import { rootRestApi } from 'store/root.api';
import {
  CompleteWalkthroughRequestPayload,
  GetWalkthroughsRequestPayload,
  GetWalkthroughsResponse,
} from './Walkthrough.types';

const WALKTHROUGHS_TAG = 'walkthroughs-tag';

export const walkthroughApi = rootRestApi
  .enhanceEndpoints({
    addTagTypes: [WALKTHROUGHS_TAG],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getWalkthroughs: builder.query<GetWalkthroughsResponse, GetWalkthroughsRequestPayload>({
        query: ({ userId }) => `/users/${userId}/walkthroughs`,
        providesTags: [WALKTHROUGHS_TAG],
      }),
      completeWalkthrough: builder.mutation<void, CompleteWalkthroughRequestPayload>({
        query: ({ userId, body }) => ({
          url: `/users/${userId}/walkthroughs`,
          method: 'PUT',
          body,
        }),
        invalidatesTags: [WALKTHROUGHS_TAG],
      }),
    }),
  });

export const { useGetWalkthroughsQuery, useCompleteWalkthroughMutation } = walkthroughApi;
