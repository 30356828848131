import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import theme from 'theme';
import { AnswerProps as Props } from './types';

const createOnClick =
  (fn: (value: string, event: React.MouseEvent) => void, value: string) => (event: React.MouseEvent) =>
    fn(value, event);

const Answer: React.FC<Props> = ({
  answer,
  correct,
  selected,
  showFeedback,
  focusColor = theme.colors.turquoiseBlue,
  onClick,
  ...rest
}) => {
  const showAnswerFeedback = showFeedback && selected;

  const isFocused = !showFeedback && selected;
  const isCorrect = showFeedback && selected && correct;
  const isIncorrert = showFeedback && selected && !correct;
  const isMissingAnswer = showFeedback && !selected && correct;

  const iconName = correct ? 'check' : 'close';
  const answerStyles: React.CSSProperties = {
    borderColor: isFocused ? focusColor : '',
  };

  return (
    <div
      className={classnames('relative flex rounded-lg my-3.5 mx-0 cursor-pointer border-4 py-0.5 px-2', {
        'bg-whiteTwo text-greyishBrown border-solid border-transparent':
          !isCorrect && !isIncorrert && !isMissingAnswer && !isFocused,
        'bg-yellowGreen text-white border-solid border-transparent': isCorrect,
        'bg-fullRed text-white border-solid border-transparent': isIncorrert,
        'bg-whiteTwo text-greyishBrown border-dashed border-yellowGreen': isMissingAnswer,
        'bg-whiteTwo text-greyishBrown border-solid border-turquoiseBlue': isFocused,
      })}
      onClick={createOnClick(onClick, answer.id)}
      style={answerStyles}
      data-testid='answer'
      {...rest}
    >
      <div className='leading-tight min-h-[48px] flex items-center justify-center'>{answer.label}</div>
      {showAnswerFeedback && (
        <div
          className='absolute top-11/20 -translate-y-1/2 right-3.5'
          data-testid={correct ? 'answer-correct' : 'answer-incorrect'}
        >
          <SvgIcon
            name={iconName}
            title={`icon-${iconName}`}
            size={24}
            color={theme.colors.white}
          />
        </div>
      )}
    </div>
  );
};

export default Answer;
