/**
 * Animated Path it draws a slice of a path that can be glued
 * when multiple of these are in the DOM and simulates a full
 * path, they can also be animated by passing a value to
 * percentage of how much of the path shoud be of a different color.
 *
 * @module AnimatedPath
 */
import classnames from 'classnames';
import React from 'react';

import tipLightSrc from 'assets/images/path_tip_light_v2.png';
import tipSrc from 'assets/images/path_tip_v2.png';
import pencilSrc from 'assets/images/popup_pencil.png';
import { PopupType } from 'components/popup';
import { animated, useSpring } from 'react-spring';
import theme from 'theme';
import { AnimatedPathProps as Props } from './types';
import { DefaultPath, StraightPath } from './variants';

// Popups image srcs
const srcs: { [index: string]: string } = {
  tip: tipSrc,
  light: tipLightSrc,
  retention: pencilSrc,
};

const createOnClick =
  <T extends unknown>(fn: (value: T, event: React.MouseEvent<HTMLElement>) => void, value: T) =>
  (event: React.MouseEvent<HTMLElement>) =>
    fn(value, event);

const AnimatedPath: React.FC<Props> = ({
  variant = 'default',
  initial,
  width = 250,
  pathThickness = 6,
  primaryColor: propPrimaryColor,
  secondaryColor: propSecondaryColor,
  percentage = 0,
  reverse,
  skill,
  tips,
  isNext,
  unlocked = true,
  className,
  onSkillNodeClick,
  onTipClick,
  isPreviewUserRole,
  ...props
}) => {
  // If no color passed trough props use palette one
  const primaryColor = propPrimaryColor || theme.colors.ocean;
  const secondaryColor = propSecondaryColor || theme.colors.turquoiseBlue;

  // State colors
  const lockedColor = theme.colors.pinkishGrey;

  // we need to reverse the tips if
  // initial or reverse flags are present
  const pops = initial || reverse ? [...tips].reverse() : tips;

  const pulseStyles = useSpring({
    loop: true,
    from: {
      opacity: 0.6,
      transform: 'scale(0.1)',
    },
    to: {
      opacity: 0,
      transform: 'scale(1.5)',
    },
    config: {
      duration: 2000,
    },
  });

  const isStarting = skill && skill.unlocked && skill.modules.every((module) => !module.done && !module.percentage);
  const hasBounce = isNext && isStarting && !isPreviewUserRole;

  return (
    <div
      {...props}
      className={classnames('relative', className)}
    >
      {!!pops.length && (
        <div
          className={classnames('absolute top-6 w-48 text-right z-10', {
            'w-52': reverse || initial,
          })}
        >
          {pops.map((popup: PopupType, indx: number) => {
            const extraStyles = indx === 0 ? { marginRight: 70 } : {};
            return (
              <div
                key={`popup-${String(popup.id)}`}
                className='inline-block w-6 h-6 bg-center bg-no-repeat bg-contain cursor-pointer'
                style={{ backgroundImage: `url(${srcs[popup.iconType || '']})`, ...extraStyles }}
                data-testid={`path-tip-icon-${String(popup.iconType)}`}
                // this way we prevent adding unnecessary event listeners
                onClick={createOnClick(onTipClick, popup)}
              >
                {popup.pulse && (
                  <animated.span
                    style={pulseStyles}
                    className='block w-full h-full rounded-[50%] bg-yellow-500 hover:bg-transparent'
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
      {
        {
          default: (
            <DefaultPath
              width={width}
              initial={initial}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              pathThickness={pathThickness}
              reverse={reverse}
              unlocked={unlocked}
              percentage={percentage}
              skill={skill}
              lockedColor={lockedColor}
              bounce={hasBounce}
              onNodeClick={onSkillNodeClick}
            />
          ),
          straight: (
            <StraightPath
              width={width}
              initial={initial}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              pathThickness={pathThickness}
              reverse={reverse}
              unlocked={unlocked}
              percentage={percentage}
              skill={skill}
              lockedColor={lockedColor}
              onNodeClick={onSkillNodeClick}
            />
          ),
        }[variant]
      }
    </div>
  );
};

export default AnimatedPath;
