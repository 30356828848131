import { createSelector } from 'reselect';
import { Store } from 'store/types';

const stateProfile = ({ profile }: Store) => profile;
const stateActivePath = ({ activePath }: Store) => activePath;

export const progressionSelector = createSelector(stateProfile, stateActivePath, (profile, activePath) => ({
  profile,
  levelInfo: {
    currentLevelName: activePath.currentLevelName,
    currentLevelIndex: activePath.currentLevelIndex,
  },
}));

export default progressionSelector;
