import { connectRouter } from 'connected-react-router';
import formats from 'containers/FormatScreen/ducks';
import profile from 'containers/Profile/Profile.ducks';
import app from 'containers/app/App.ducks';
import auth from 'containers/authentication/Authentication.ducks';
import modals from 'containers/modal-controller/ModalController.ducks';
import settings from 'containers/settings/Settings.ducks';
import { walkthroughApi } from 'containers/walkthrough';
import { History } from 'history';
import newsfeed from 'pages/community/newsfeed/Newsfeed.ducks';
import { crashCourseReducer } from 'pages/crash-course';
import activeEvent from 'pages/event/Event.ducks';
import { helpPageReducer } from 'pages/help';
import home from 'pages/home/Home.ducks';
import { mobieChatApi } from 'pages/home/popups/mobie-chat';
import { lepaPageReducer } from 'pages/lepa';
import { lepaApi } from 'pages/lepa/Lepa.api';
import lti from 'pages/lti/LTI.ducks';
import personalityTest from 'pages/personality-test/PersonalityTest.ducks';
import register from 'pages/register/Register.ducks';
import { scormApi } from 'pages/scorm/Scorm.api';
import search from 'pages/search/Search.ducks';
import { skillReducer } from 'pages/skill';
import { testsApi } from 'pages/tests/Tests.api';
import { combineReducers } from 'redux';
import { analyticsApi } from '../containers/Analytics/Analytics.api';
import { dynamicConfigurationApi } from './dynamic-configuration';
import featureFlags from './feature-flags';
import pendingRequests from './pending-requests/pending-requests.ducks';
import { rootApi, rootRestApi } from './root.api';

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    profile,
    activePath: lepaPageReducer,
    crashCourse: crashCourseReducer,
    skill: skillReducer,
    register,
    settings,
    formats,
    help: helpPageReducer,
    home,
    app,
    newsfeed,
    modals,
    personalityTest,
    lti,
    search,
    featureFlags,
    pendingRequests,
    activeEvent,
    [rootRestApi.reducerPath]: rootRestApi.reducer,
    [rootApi.reducerPath]: rootApi.reducer,
    [dynamicConfigurationApi.reducerPath]: dynamicConfigurationApi.reducer,
    [analyticsApi.reducerPath]: analyticsApi.reducer,
    [scormApi.reducerPath]: scormApi.reducer,
    [mobieChatApi.reducerPath]: mobieChatApi.reducer,
    [lepaApi.reducerPath]: lepaApi.reducer,
    [walkthroughApi.reducerPath]: walkthroughApi.reducer,
    [testsApi.reducerPath]: testsApi.reducer,
  });

export default rootReducer;
