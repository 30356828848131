import { Media } from 'components/media';
import { Spot } from 'components/spot';
import { API_URL } from 'constants/endpoints';
import { getSrcset } from 'util/image';
import { ImageMapProps as Props } from './ImageMap.types';

export const ImageMap: React.FC<Props> = ({
  imgSrc,
  spots,
  selected,
  showAnswers,
  correct,
  focusColor,
  onSpotChange,
  ...rest
}) => {
  const renderSpots = spots.map(({ id, metadata }, index) => {
    const x = metadata?.x ?? '0';
    const y = metadata?.y ?? '0';
    const currentIndex = String(index + 1);
    const isSelected = selected.includes(id);
    const isCorrect = correct.includes(id);

    return (
      <Spot
        key={id}
        id={id}
        index={currentIndex}
        x={x}
        y={y}
        isSelected={isSelected}
        onChange={onSpotChange}
        isCorrect={isCorrect}
        showAnswers={showAnswers}
        focusColor={focusColor}
      />
    );
  });

  return (
    <div
      className='relative w-full h-full'
      {...rest}
    >
      <Media
        alt='image-format'
        component='img'
        className='z-10'
        src={`${API_URL}/${imgSrc}`}
        sizes='100vw'
        srcSet={getSrcset(`${API_URL}/${imgSrc}`, {
          sizes: [
            [1520, '1520w'],
            [1120, '1120w'],
            [720, '720w'],
            [420, '420w'],
            [320, '320w'],
          ],
        })}
      />
      <div className='absolute top-0 left-0 w-full h-full'>
        <div className='relative w-full h-full'>{renderSpots}</div>
      </div>
    </div>
  );
};
