import classnames from 'classnames';
import { StepProgressionBar } from 'components/ProgressionBar';
import { StepsProgressionBarProps } from 'components/ProgressionBar/types';
import { IconButton, IconButtonProps } from 'components/button';
import React from 'react';
import { PlaygroundActionsProps } from './Playground.types';

function showProgress(progress: PlaygroundActionsProps['progress']): progress is StepsProgressionBarProps {
  return typeof progress !== 'boolean' || progress;
}

function showNavigationButton(onNavigation: PlaygroundActionsProps['previous']): onNavigation is IconButtonProps {
  return typeof onNavigation !== 'boolean' || onNavigation;
}

const NextIconButton: React.FC<Partial<IconButtonProps>> = ({ icon = 'arrowForward', ...rest }) => (
  <IconButton
    variant='outlined'
    round
    icon={icon}
    size={30}
    {...rest}
  />
);

export const PlaygroundActions: React.FC<PlaygroundActionsProps> = ({
  className = '',
  progress = false,
  previous = false,
  next = false,
  children,
  ...rest
}) => (
  <div
    className={classnames('flex flex-col justify-end items-center py-7 px-0 flex-shrink-0 flex-grow', className)}
    {...rest}
  >
    {children}
    <div className='flex mb-5 space-x-5'>
      {showNavigationButton(previous) && (
        <NextIconButton
          className='rotate-180'
          {...previous}
        />
      )}
      {showNavigationButton(next) && <NextIconButton {...next} />}
    </div>
    {showProgress(progress) && <StepProgressionBar {...progress} />}
  </div>
);
