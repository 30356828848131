import { animated, config, useSpring } from 'react-spring';

export const Triumph = () => {
  const styles = useSpring({
    loop: true,
    from: { transform: 'translateY(45px) scaleY(0)' },
    to: { transform: 'translateY(0) scaleY(1)' },
    config: config.molasses,
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={91}
      height={94}
      fill='none'
      viewBox='0 0 91 94'
    >
      <circle
        cx={45.328}
        cy={45.328}
        r={45.328}
        fill='#FFD23B'
      />
      <path
        d='M45.328 90.656c25.034 0 45.328-20.294 45.328-45.328C90.656 20.294 70.362 0 45.328 0v90.656z'
        fill='#FFB24A'
      />
      <path
        d='M72.492 59.204c-2.86-7.863-12.162-14.296-27.163-14.296-15.002 0-24.304 6.433-27.163 14.296 13.581-11.145 41.46-10.943 54.326 0z'
        fill='#962B7C'
      />
      <path
        d='M26.648 37.536a8.056 8.056 0 008.057-8.056c-6.19 3.61-9.756 3.53-16.113 0a8.056 8.056 0 008.056 8.056z'
        fill='#2D3E53'
      />
      <path
        d='M63.393 37.238a8.056 8.056 0 008.056-8.056c-6.19 3.61-9.755 3.53-16.112 0a8.056 8.056 0 008.056 8.056z'
        fill='#000'
      />
      <animated.g style={styles}>
        <path
          d='M33.625 67.885l5.657-24.916c.165-.726-.79-1.142-1.184-.515l-13.55 21.53c-.791 1.159-2.292 1.677-3.557 1.082-2.164-1.016-4.81-.93-7.05.665-1.98 1.409-3.08 3.875-2.904 6.34.104 1.457.586 2.688 1.301 3.692.666.932.86 2.657.554 3.762-.704 2.542-.44 5.391 1.05 7.95 2.196 3.773 6.708 5.708 10.865 4.66a9.81 9.81 0 006.247-4.823c.55-1 1.881-2.07 2.995-2.24 1.199-.183 2.39-.697 3.473-1.646 1.831-1.604 2.766-4.14 2.35-6.576-.47-2.757-2.27-4.75-4.495-5.615-1.3-.506-1.998-1.957-1.752-3.35z'
          fill='#EEE'
        />
        <path
          d='M57 67.885l-5.656-24.916c-.165-.726.79-1.142 1.184-.515l13.549 21.53c.792 1.159 2.293 1.677 3.557 1.082 2.165-1.016 4.81-.93 7.05.665 1.98 1.409 3.08 3.875 2.904 6.34-.104 1.457-.585 2.688-1.3 3.692-.666.932-.86 2.657-.555 3.762.704 2.542.44 5.391-1.05 7.95-2.196 3.773-6.707 5.708-10.865 4.66a9.81 9.81 0 01-6.246-4.823c-.55-1-1.882-2.07-2.996-2.24-1.198-.183-2.389-.697-3.472-1.646-1.831-1.604-2.767-4.14-2.351-6.576.47-2.757 2.27-4.75 4.496-5.615 1.3-.506 1.998-1.957 1.752-3.35z'
          fill='#D3D3D3'
        />
      </animated.g>
    </svg>
  );
};
