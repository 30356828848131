import { StepProgressionBar } from 'components/ProgressionBar';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import theme from 'theme';
import { TooltipProps as Props } from '../Walkthrough.types';

export const WalkthroughTooltip: React.FC<Props> = ({
  index,
  isLastStep,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
  size,
  withSkip = true,
  onSkip,
  onEnd,
}) => {
  const renderSkipButton = withSkip && !isLastStep;
  const renderNextButton = !isLastStep;

  const handleSkipClick = async () => {
    if (!onSkip) {
      return;
    }

    await onSkip();
  };

  const handleCloseClick = async () => {
    if (!onEnd) {
      return;
    }

    await onEnd();
  };

  return (
    <div
      className='mb-5 mx-auto text-white w-9/10 min-w-[20rem]'
      {...tooltipProps}
    >
      {step.title && <h2 className='text-xl'>{step.title}</h2>}
      {step.content && <div className='text-sm font-light text-white whitespace-pre-line'>{step.content}</div>}

      <div className='flex items-center justify-between w-full mt-5'>
        {renderSkipButton && (
          <button
            className='text-xs underline border-none cursor-pointer text-stone-300 bg-none'
            {...skipProps}
            onClick={handleSkipClick}
          >
            <FormattedMessage id='walkthrough.options.skip' />
          </button>
        )}

        <div className='flex items-center justify-around w-full h-8'>
          {index > 0 && (
            <button
              className='text-white bg-orangeRed rounded-md py-1.5 px-4 border-0 text-sm cursor-pointer'
              {...backProps}
            >
              <FormattedMessage id='walkthrough.options.back' />
            </button>
          )}

          {size > 1 && (
            <StepProgressionBar
              steps={size}
              current={index + 1}
              color={theme.colors.orangeRed}
              variant='circular'
              className='flex flex-nowrap'
            />
          )}

          {renderNextButton ? (
            <button
              className='text-white bg-orangeRed rounded-md py-1.5 px-4 border-0 text-sm cursor-pointer'
              {...primaryProps}
            >
              <FormattedMessage id='walkthrough.options.next' />
            </button>
          ) : (
            <button
              className='text-white bg-orangeRed rounded-md py-1.5 px-4 border-0 text-sm cursor-pointer'
              {...primaryProps}
              onClick={handleCloseClick}
            >
              <FormattedMessage id='walkthrough.options.close' />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
