import classNames from 'classnames';
import React from 'react';
import { RowProps } from './Row.types';

export const Row: React.FC<RowProps> = ({ className, ...rest }) => (
  <div
    className={classNames('my-[3vw] mx-0', className)}
    data-testid='e2e-row'
    {...rest}
  />
);
