import { Popup } from 'components/popup';
import React from 'react';
import { useIntl } from 'react-intl';
import { UnlockedFinalTestModalProps as Props } from '../Lepa.types';

export const UnlockedFinalTestModal: React.FC<Props> = ({ modal, onModalClose, open }) => {
  const intl = useIntl();
  const { onPrimaryClick } = modal;

  const handlePrimaryButtonClick = (event: React.SyntheticEvent) => {
    onModalClose();

    if (onPrimaryClick) {
      onPrimaryClick(event);
    }
  };

  return (
    <Popup
      variant='unlockedFinalTest'
      open={open}
      title={intl.formatMessage({ id: 'modal.unlockedFinalTestModal.title' })}
      buttonText={intl.formatMessage({ id: 'modal.unlockedFinalTestModal.primaryButtonText' })}
      onRequestClose={onModalClose}
      onPrimaryClick={handlePrimaryButtonClick}
      closeIcon
    >
      <div className='relative flex flex-col content-center text-center'>
        <div
          key='text-container'
          className='pt-2 text-sm font-light text-center whitespace-pre-wrap'
        >
          <p>{intl.formatMessage({ id: 'modal.unlockedFinalTestModal.text' })}</p>
        </div>
      </div>
    </Popup>
  );
};
