import { PayloadAction } from '@reduxjs/toolkit';
import { REST_API_URL } from 'constants/endpoints';
import { actions as authActions } from 'containers/authentication';
import { defaultLocale } from 'containers/language-provider';
import { loader } from 'graphql.macro';
import { StrictEffect, call, put, select, takeLatest } from 'redux-saga/effects';
import { Store } from 'store/types';
import getDefaultHeaders from 'util/getDefaultHeaders';
import request from 'util/request';
import requestGraphql from 'util/requestGraphql';
import { actions } from './Home.ducks';
import { Category, CheckpointPayload, GetUserPathPayload, Leco } from './Home.types';
import { OldLeco, lecoAdapter } from './helpers';

const getFeaturedPath = loader('./graphQL/query/get-featured-path.graphql');
const getCategories = loader('./graphQL/query/get-categories.graphql');

function* getUserPathsWorker({ payload }: PayloadAction<GetUserPathPayload>): Generator<StrictEffect, void> {
  const { userId, queryParams } = payload;

  try {
    const requestUrl = `${REST_API_URL}/users/${userId}/learning-contents${queryParams}`;
    const headers = (yield call(getDefaultHeaders)) as HeadersInit;

    const { data, errors } = (yield call(request, requestUrl, {
      method: 'GET',
      headers,
    })) as { data: Leco[]; errors: Error };

    if (errors) {
      yield put(actions.getUserLecosError(errors));
      return;
    }

    yield put(
      actions.getUserLecosSuccess({
        paths: data,
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(actions.getUserLecosError(error));
    } else {
      throw new Error('unknown error`');
    }
  }
}

function* getCategoriesWorker(): Generator<StrictEffect, void> {
  const { profile } = (yield select()) as Store;
  const accountID = profile.account.id;
  const locale = profile.user.locale || defaultLocale;
  const body = {
    query: getCategories,
    variables: {
      accountID,
      locale,
    },
  };

  const { data, errors } = (yield call(requestGraphql, body)) as {
    data: { categories: { getByAccount: Category[] } };
    errors: Error;
  };

  if (errors) {
    yield put(actions.getCategoriesError(errors));
    return;
  }

  const categories = data.categories.getByAccount.map(({ title }: { title: string }) => title);

  yield put(actions.getCategoriesSuccess(categories));
}

function* getFeaturedPathWorker() {
  const body = {
    query: getFeaturedPath,
  };

  const { data, errors } = (yield call(requestGraphql, body)) as {
    data: { users: { getFeaturedPath: OldLeco } };
    errors: Error;
  };

  if (errors) {
    yield put(actions.getFeaturedPathError(errors));
    return;
  }

  const featuredPath = lecoAdapter(data.users.getFeaturedPath);
  yield put(actions.getFeaturedPathSuccess({ featuredPath }));
}

function* createCheckpoint({ payload: checkpoint }: PayloadAction<CheckpointPayload>): Generator<StrictEffect, void> {
  try {
    const requestUrl = `${REST_API_URL}/learning-contents/${checkpoint.learningContentId}/checkpoint`;
    const headers = (yield call(getDefaultHeaders)) as HeadersInit;
    const body = JSON.stringify({
      domain: checkpoint.domain,
      domainId: checkpoint.domainId,
    });

    yield call(request, requestUrl, {
      method: 'POST',
      headers,
      body,
    });

    yield put(actions.createCheckpointSuccess(checkpoint));
  } catch (error) {
    if (error instanceof Error) {
      yield put(actions.createCheckpointError(error));
    } else {
      throw new Error('unknown error');
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.getUserLecos.type, getUserPathsWorker);
  yield takeLatest(authActions.getUserInfoSuccess, getCategoriesWorker);
  yield takeLatest(actions.getCategories.type, getCategoriesWorker);
  yield takeLatest(actions.getFeaturedPath.type, getFeaturedPathWorker);
  yield takeLatest(actions.createCheckpoint.type, createCheckpoint);
}
