import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HeaderFeedbackMessageProps as Props } from '../types';

export const HeaderFeedbackMessage: React.FC<Props> = ({ showAnswers, message }) => {
  if (showAnswers) {
    return (
      <div className='text-center mb-2.5 break-words text-greyishBrown'>
        <p className='text-sm font-light'>
          <FormattedMessage
            id='comp.SwipeFormat.done'
            tagName='b'
          />
        </p>
      </div>
    );
  }

  return (
    <div className='text-center mb-8 mt-4 break-words text-greyishBrown'>
      <p className='text-sm font-bold mb-3.5'>
        <FormattedMessage
          id='comp.SwipeFormat.finalCheck.title'
          tagName='b'
        />
      </p>
      <p className='text-sm font-normal'>{message}</p>
    </div>
  );
};
