import React from 'react';
import { TagsProps as Props } from './Tags.types';

export const Tags: React.FC<Props> = ({ tags }) => {
  if (!tags.length) {
    return <></>;
  }

  return (
    <ul className='w-full mt-6'>
      {tags.map((tag, index) => (
        <li
          key={index}
          className='py-1.5 px-2.5 text-xs bg-white text-gray-600 rounded-xl mr-1 my-1 inline-block'
        >
          {tag}
        </li>
      ))}
    </ul>
  );
};
