import classnames from 'classnames';
import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import React from 'react';
import { PopupVariantProps } from '../Popup.types';
import { CloseIcon } from '../close-icon';
import { PopupTitle } from '../popup-title';

export const FadedPopup: React.FC<PopupVariantProps> = ({
  variant,
  icon,
  iconClassnames = '',
  title,
  buttonText,
  secondaryButtonText,
  closeIcon,
  children,
  onPrimaryClick,
  onSecondaryClick,
  onClose,
}) => {
  const renderIcon =
    typeof icon === 'string' ? (
      <img
        src={icon}
        alt={title}
      />
    ) : (
      icon
    );

  return (
    <PageContainer
      center
      onClick={onClose('modal')}
    >
      <div
        className='relative flex flex-col p-4 bg-white rounded-lg w-9/10 text-greyishBrown'
        data-testid='e2e-popup'
      >
        {icon && (
          <div
            className={classnames(
              'absolute block transform -translate-x-1/2 pointer-events-none left-1/2 -translate-y-3/4',
              iconClassnames,
            )}
          >
            {renderIcon}
          </div>
        )}

        {closeIcon && <CloseIcon onClose={onClose('close')} />}

        {title && <PopupTitle variant={variant}>{title}</PopupTitle>}

        <div className='flex flex-col items-center justify-center overflow-x-hidden overflow-y-auto h-9/10'>
          {children}
        </div>

        {buttonText && (
          <div className='flex justify-center pt-4'>
            <Button
              variant='outlined'
              text={buttonText}
              onClick={onPrimaryClick}
            />
            {secondaryButtonText && (
              <Button
                variant='outlined'
                text={secondaryButtonText}
                onClick={onSecondaryClick}
              />
            )}
          </div>
        )}
      </div>
    </PageContainer>
  );
};
