import _debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import tinyColor from 'tinycolor2';

import TextInputWithFeedback from 'components/TextInputWithFeedback';
import { Avatar } from 'components/avatar';
import { Popup } from 'components/popup';
import { SvgIconProps } from 'components/svg-icon';
import {
  CertificationStates,
  CertificationShareModalProps as Props,
  SocialNetworkShareProps,
  actions,
} from 'pages/lepa';

import star from 'assets/images/single_star.svg';
import { API_URL } from 'constants/endpoints';
import { Store } from 'store/types';
import theme from 'theme';

const SocialNetworkShare: React.FC<SocialNetworkShareProps> = ({
  shareDetails: { url, title, hashtags, source, relations },
  iconColor,
  iconSize,
}) => (
  <div className='flex mt-6 w-52 justify-evenly'>
    <FacebookShareButton
      translate='yes'
      url={url}
      quote={title}
      hashtag={hashtags[0]}
    >
      <FacebookIcon
        crossOrigin='use-credentials'
        round
        size={iconSize}
        bgStyle={{ fill: iconColor }}
      />
    </FacebookShareButton>
    <TwitterShareButton
      translate='yes'
      url={url}
      title={title}
      via={source}
      hashtags={hashtags}
      related={relations}
    >
      <TwitterIcon
        crossOrigin='use-credentials'
        round
        size={iconSize}
        bgStyle={{ fill: iconColor }}
      />
    </TwitterShareButton>
    <LinkedinShareButton
      translate='yes'
      url={url}
      title={title}
      source={source}
    >
      <LinkedinIcon
        crossOrigin='use-credentials'
        round
        size={36}
        bgStyle={{ fill: iconColor }}
      />
    </LinkedinShareButton>
  </div>
);

const CertificationShareModalComponent: React.FC<Props> = ({ intl, modal, open, onModalClose }) => {
  const { user, account } = useSelector(({ profile }: Store) => profile);
  const { certificationState, certificateId } = useSelector(({ activePath }: Store) => activePath);

  const dispatch = useDispatch();

  const {
    colors: { red, turquoiseBlue },
  } = theme;
  const { color } = modal;

  const accentColor = {
    light: color || turquoiseBlue,
    dark: color || tinyColor(theme.colors.turquoiseBlue).darken().toHex(),
  };

  const { pathName, closeIcon, date } = modal;
  const { name, jobPosition, email } = user;
  const accountLogo = account.logoUrl ?? '';

  const socialNetworkProps = {
    url: 'https://www.mobietrain.com',
    title: `${intl.formatMessage({ id: 'certification.sharePopup.socialShareButtons.title-1' })} ${String(
      pathName,
    )} ${intl.formatMessage({ id: 'certification.sharePopup.socialShareButtons.title-2' })}`,
    hashtags: ['microlearning'],
    source: 'Mobietrain',
    relations: ['mobietrain'],
  };

  const [emailValue, setEmail] = useState(email);
  const [inputFeedback, setInputFeedback] = useState('');
  const [inputColor, setInputColor] = useState(accentColor.light);
  const [inputButtonIcon, setInputButtonIcon] = useState<SvgIconProps['name']>('send');

  useEffect(() => {
    switch (certificationState) {
      case CertificationStates.sent: {
        setInputFeedback(intl.formatMessage({ id: 'certification.sharePopup.feedback-success' }));
        break;
      }
      case CertificationStates.sendFailed: {
        setInputFeedback(intl.formatMessage({ id: 'certification.sharePopup.feedback-error' }));
        setInputColor(red);
        setInputButtonIcon('exclamation-thick');
        break;
      }
      default:
    }
  }, [red, certificationState, intl]);

  const handleInputChange = (value: string) => {
    setEmail(value);
  };

  const handleEmailSend = () => {
    if (certificationState === CertificationStates.sending) {
      return;
    }
    dispatch(actions.sendCertificate({ id: certificateId, email: emailValue }));
  };

  const handleDismissFeedback = () => {
    setInputFeedback('');
    setInputColor(accentColor.light);
    setInputButtonIcon('send');
  };

  return (
    <Popup
      variant='gradientFullscreen'
      open={open}
      className='flex items-center justify-center'
      onRequestClose={onModalClose}
      closeIcon={closeIcon}
      accentColor={accentColor.light}
    >
      <div className='flex flex-col h-full w-9/10 justify-evenly'>
        <div className='flex flex-col items-center'>
          <Avatar
            className='mt-1 w-36 h-36'
            style={{ borderColor: accentColor.light }}
            src={`${API_URL}/${accountLogo}`}
            disableBorder
            squared
          />

          <div
            className='flex flex-col text-center'
            style={{ color: accentColor.dark }}
          >
            <div className='mt-2 text-sm'>
              {intl.formatMessage({ id: 'certification.sharePopup.at-date' })} {date}
            </div>
            <div className='mt-4'>{name}</div>
            <div>{jobPosition}</div>
          </div>
        </div>

        {/* HORIZONTAL STAR SEPARATOR */}
        <div className='flex items-center justify-around my-5'>
          <hr className='bg-yellow-400 h-0.5 w-full border-0 rounded-sm' />
          <div className='mx-2.5'>
            <img
              src={star}
              alt=''
            />
          </div>
          <hr className='bg-yellow-400 h-0.5 w-full border-0 rounded-sm' />
        </div>

        {/* LEARNING PATH DETAILS */}
        <div className='text-center'>
          <div
            className='text-sm'
            style={{ color: accentColor.light }}
          >
            {intl.formatMessage({ id: 'certification.sharePopup.title' })}
          </div>
          <div
            className='mt-2 text-xl'
            style={{ color: accentColor.light }}
          >
            {pathName}
          </div>
          <div
            className='mt-3'
            style={{ color: accentColor.light }}
          >
            {intl.formatMessage({ id: 'certification.sharePopup.subtitle' })}
          </div>
        </div>

        {/* CERTIFICATE SHARE ROW */}
        <div className='flex flex-col items-center mb-5'>
          <div>
            <div className='text-warmGrey mb-1 ml-2.5 text-center'>
              {intl.formatMessage({ id: 'certification.sharePopup.label' })}
            </div>
            <TextInputWithFeedback
              onChange={handleInputChange}
              value={emailValue}
              onButtonPress={_debounce(handleEmailSend, 400)}
              onDismissFeedback={handleDismissFeedback}
              placeholder={intl.formatMessage({ id: 'certification.sharePopup.email-placeholder' })}
              buttonIcon={inputButtonIcon}
              color={inputColor}
              feedback={inputFeedback}
            />
          </div>
          <SocialNetworkShare
            shareDetails={socialNetworkProps}
            iconSize={36}
            iconColor={accentColor.light}
          />
        </div>
      </div>
    </Popup>
  );
};

export const CertificationShareModal = injectIntl(CertificationShareModalComponent);
