import { API_URL } from 'constants/endpoints';
import { LTIProviders } from 'pages/lti/LTI.types';
import { BuiltInPathCategories, ContentOrigin, Leco, LecoTypes, LepaTypes, PathCategories } from './Home.types';

const getSinglePathCategories = (path: Leco, initialCategories: PathCategories) => {
  const { categories } = path;

  if (!categories) {
    return initialCategories;
  }

  return categories.reduce((accPathCategories, category) => {
    const currentPaths = accPathCategories[category.title] ? [...accPathCategories[category.title]] : [];

    return {
      ...accPathCategories,
      [category.title]: [...currentPaths, path],
    };
  }, initialCategories);
};

export const getAllPathCategories = (paths: Leco[], initialCategories: PathCategories) =>
  paths.reduce((accPathsCategories, path) => {
    const pathCategories = getSinglePathCategories(path, accPathsCategories);
    return pathCategories;
  }, initialCategories);

export const getBuiltInCategories = () => {
  const initialCategoriesList = Object.values(BuiltInPathCategories);
  return initialCategoriesList.reduce((acc, category) => ({ ...acc, [category]: [] }), {}) as PathCategories;
};

export const getLecoAvatar = (leco: Leco) => {
  if (!leco.image) {
    return {};
  }

  const isExternallyProvided = leco.image?.startsWith('https');
  const avatarUrl = isExternallyProvided ? leco.image : `${API_URL}/${leco.image}`;

  return { avatarUrl, external: isExternallyProvided };
};

export interface OldLeco {
  id: string;
  slug: string;
  avatar: string;
  progress: number;
  unlockedModules: number;
  imperfectModules: number;
  completed: boolean;
  currentLevel: number;
  unlocked: boolean;
  type: LepaTypes;
  splashScreen: string;
  background: string;
  categories: string[];
  darkColor?: string;
  color?: string;
  objectives: string;
  description: string;
  estimatedTimeToComplete: number;
  origin: ContentOrigin;
  provider?: LTIProviders;
  url?: string;
}

const lepaTypeToLecoType = (type: LepaTypes): LecoTypes =>
  ({
    [LepaTypes.LEGACY]: LecoTypes.LEGACY,
    [LepaTypes.CRASH_COURSE]: LecoTypes.CRASH_COURSE,
    [LepaTypes.QUESTIONS_ONLY]: LecoTypes.QUESTIONS_ONLY,
    [LepaTypes.SINGLE_LEARNING]: LecoTypes.SINGLE_LEARNING,
    [LepaTypes.TEXT]: LecoTypes.TEXT,
    [LepaTypes.VIDEO]: LecoTypes.VIDEO,
  }[type]);

export const lecoAdapter = (oldLeco: OldLeco): Leco => {
  const {
    id,
    slug,
    avatar,
    origin,
    progress,
    type,
    darkColor = '',
    color = '',
    splashScreen,
    url,
    categories,
    ...props
  } = oldLeco;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Typescript is acting up infering LecoTypes here
  return {
    ...props,
    type: lepaTypeToLecoType(type),
    id,
    title: slug,
    image: avatar,
    playButtonColor: darkColor,
    published: true,
    primaryColor: color,
    categories: categories.map((category) => ({ title: category })),
    progress,
    content: {
      id,
      lecoId: parseInt(id, 10),
      progress,
      type,
      splashScreen,
      isCertification: false,
      lockedBy: [],
    },
  };
};
