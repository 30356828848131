import classnames from 'classnames';
import Question from 'components/Question';
import { SvgIcon } from 'components/svg-icon';
import { ImageFormat, VideoFormat } from 'containers/FormatScreen/Formats';
import { FormatContentOption } from 'containers/FormatScreen/types';
import React from 'react';
import theme from 'theme';
import { TrueOrFalseProps } from './types';

type Callback = (selectedAnswer: string, event: React.MouseEvent<HTMLDivElement>) => void;
const createOnClick = (fn: Callback, value: string) => (event: React.MouseEvent<HTMLDivElement>) => fn(value, event);

export const TrueOrFalse: React.FC<TrueOrFalseProps> = (props) => {
  const { selected, showAnswers, correct, question, answers, imgSrc, video, onChange, focusColor } = props;
  const [firstAnswer, secondAnswer] = answers;

  const renderAnswer = (answer: FormatContentOption) => {
    const isFocused = selected === answer.id;

    const answerStyles: React.CSSProperties = {
      boxShadow: isFocused && !showAnswers ? `inset 0px 0px 0px 3px ${focusColor || theme.colors.turquoiseBlue}` : '',
      borderColor: isFocused && !showAnswers ? focusColor || theme.colors.turquoiseBlue : '',
    };

    const wrongAnswer =
      isFocused && selected && selected.includes(answer.id) && showAnswers && !correct.includes(answer.id);

    return (
      <div
        className={classnames(
          'relative flex rounded my-3 bg-white text-greyishBrown border border-greyish cursor-pointer box-border p-2',
          { 'border-none bg-gray-100 bg-opacity-50 text-gray-600 text-opacity-50': isFocused && showAnswers },
          { 'border-turquoiseBlue': isFocused && !showAnswers },
        )}
        style={answerStyles}
        onClick={createOnClick(onChange, answer.id)}
      >
        <div className='min-h-[48px] flex items-center justify-center'>{answer.label}</div>

        <div
          className={classnames(
            'absolute flex w-14 h-14 rounded-full opacity-100 items-center justify-center',
            'transition-transform delay-200 ease-in-out transform scale-0 left-5/10 -ml-7 top-5/10 -mt-7',
            {
              'transform scale-100 bg-yellowGreen': isFocused && showAnswers && correct.includes(answer.id),
              'transform scale-100 bg-fullRed animate-shake': wrongAnswer,
            },
          )}
        >
          <SvgIcon
            name={correct.includes(answer.id) ? 'check' : 'close'}
            size={32}
            color={theme.colors.white}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='w-full px-5 py-0'>
      {video && (
        <div className='my-4'>
          <VideoFormat url={video} />
        </div>
      )}
      {imgSrc && !video && (
        <div className='my-4'>
          <ImageFormat imgSrc={imgSrc} />
        </div>
      )}

      {renderAnswer(firstAnswer)}

      <Question
        variant='bold'
        text={question}
        color={focusColor}
      />

      {renderAnswer(secondAnswer)}
    </div>
  );
};
