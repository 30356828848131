import { Popup } from 'components/popup';
import { StarTail } from 'components/star-tail';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { RatingFeedbackModalProps as Props } from '../Lepa.types';

export const RatingFeedbackModal: React.FC<Props> = ({ open, onModalClose }) => {
  const history = useHistory();

  const handleClose = () => {
    onModalClose();
    history.push('/');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (open) {
        handleClose();
      }
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Popup
      variant='ratingFeedback'
      open={open}
      onRequestClose={handleClose}
    >
      <div className='relative flex flex-col content-center text-center'>
        <h1 className='my-8 font-extrabold text-2xl'>
          <FormattedMessage id={'comp.rating-feedback.feedback'} />
        </h1>
        <div className='self-center'>
          <StarTail />
        </div>
        <p className='mt-8 self-center w-64 font-normal mb-7'>
          <FormattedMessage id={'comp.rating-feedback.improvement'} />
        </p>
      </div>
    </Popup>
  );
};
