import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { TranslationLanguages } from 'stream-chat';
import { Chat, Streami18n } from 'stream-chat-react';
import { ProfileStore } from '../../../../containers/Profile';
import { useGetDynamicConfigurationQuery } from '../../../../store/dynamic-configuration';
import { Store } from '../../../../store/types';
import { useChat } from '../ChatContext';

const ChatProvider: React.FC = ({ children }) => {
  const { id: accountId } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);
  const { locale = 'en-GB' } = useSelector<Store, ProfileStore['user']>(({ profile }) => profile.user);
  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);

  const { chatClient } = useChat();

  const isChatPluginEnabled = dynamicConfiguration?.plugins?.chat;
  if (!isChatPluginEnabled) {
    return null;
  }

  if (!chatClient) {
    return null;
  }

  const language = locale as TranslationLanguages;
  const i18n = new Streami18n({ DateTimeParser: moment, language });

  return (
    <Chat
      customClasses={{
        channelList: 'bg-zinc-100 h-full',
      }}
      client={chatClient}
      i18nInstance={i18n}
    >
      {children}
    </Chat>
  );
};

/**
 * Wrap all pages that consume Stream API with this HOC.
 * Because the community chat is an optional plugin for the accounts and if the plugin is disabled
 * the application should continue to work without it.
 */
export function withChatProvider<T>(Component: React.ComponentType<T>) {
  return (props: T) => (
    <ChatProvider>
      <Component {...props} />
    </ChatProvider>
  );
}
