import { FullGestureState, useGesture } from '@use-gesture/react';
import { SvgIcon } from 'components/svg-icon';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { animated, useSpring } from 'react-spring';
import { FullScreenContainerProps } from './Media.types';

interface OnPinchParameters extends FullGestureState<'pinch'> {
  memo?: {
    x: number;
    y: number;
    translatedX: number;
    translatedY: number;
  };
}

const DELTA_MANUAL_SCALE = 0.5;
const MAX_ZOOM = 2;
const MIN_ZOOM = 0.5;

export const FullScreenContainer: React.FC<FullScreenContainerProps> = ({ children, onClose }) => {
  const imageRef = useRef<HTMLDivElement | null>(null);
  const imageContainerRef = useRef<HTMLDivElement | null>(null);

  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
  }));

  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y] }) => {
        if (pinching) {
          return cancel();
        }
        return api.start({ x, y });
      },
      onPinch: ({
        origin: [originX, originY],
        first: isFirst,
        movement: [scaleMovement],
        offset: [scaleOffset],
        memo = { x: 0, y: 0, translatedX: 0, translatedY: 0 },
      }: OnPinchParameters) => {
        if (isFirst && imageRef.current) {
          const { width, height, x, y } = imageRef.current.getBoundingClientRect();
          const translatedX = originX - (x + width / 2);
          const translatedY = originY - (y + height / 2);
          // memo is designed to be mutable
          // eslint-disable-next-line no-param-reassign
          memo = {
            x: style.x.get(),
            y: style.y.get(),
            translatedX,
            translatedY,
          };
        }

        const x = memo.x - (scaleMovement - 1) * memo.translatedX;
        const y = memo.y - (scaleMovement - 1) * memo.translatedY;
        api.start({ scale: scaleOffset, x, y });
        return memo;
      },
    },
    {
      target: imageRef,
      drag: {
        from: () => [style.x.get(), style.y.get()],
      },
      pinch: { scaleBounds: { min: MIN_ZOOM, max: MAX_ZOOM }, rubberband: true },
    },
  );

  const zoomIn = () => {
    const currentScale = style.scale.get();
    if (currentScale < MAX_ZOOM) {
      const nextScale = currentScale + DELTA_MANUAL_SCALE;
      const scale = nextScale > MAX_ZOOM ? MAX_ZOOM : nextScale;
      api.start({ scale });
    }
  };

  const zoomOut = () => {
    const currentScale = style.scale.get();
    if (currentScale > MIN_ZOOM) {
      const nextScale = currentScale - DELTA_MANUAL_SCALE;
      const scale = nextScale < MIN_ZOOM ? MIN_ZOOM : nextScale;
      api.start({ scale });
    }
  };

  const container = (
    <animated.div
      data-testid='e2e-fullscreen-container'
      className='fixed top-0 left-0 z-50 flex items-center justify-center w-screen overflow-hidden bg-black h-screens'
    >
      <div ref={imageContainerRef}>
        <animated.div
          ref={imageRef}
          style={style}
        >
          {children}
        </animated.div>
      </div>
      <div className='absolute space-x-5 text-white bottom-5 left-50'>
        <button onClick={zoomOut}>
          <SvgIcon
            name='zoom-out'
            color='white'
            size={26}
            viewBox='-3 -3 25 25'
            className='text-white bg-black bg-opacity-50 rounded-sm'
          />
        </button>
        <button onClick={zoomIn}>
          <SvgIcon
            name='zoom-in'
            color='white'
            size={26}
            viewBox='-3 -3 25 25'
            className='text-white bg-black bg-opacity-50 rounded-sm'
          />
        </button>
      </div>
      <button
        className='absolute top-5 right-5'
        onClick={onClose}
      >
        <SvgIcon
          name='close'
          color='white'
          size={26}
          viewBox='1 1 22 22'
          className='text-white bg-black bg-opacity-50 rounded-sm'
        />
      </button>
    </animated.div>
  );

  return ReactDOM.createPortal(container, document.getElementById('root') as HTMLElement);
};
