import classnames from 'classnames';
import React from 'react';
import { PageContainerProps } from './PageContainer.types';

export const PageContainer: React.FC<PageContainerProps> = ({
  center = false,
  maxWidth = true,
  className = '',
  ...rest
}) => (
  <div
    className={classnames(className, 'mx-auto w-full flex flex-col', {
      'items-center justify-center': center,
      'max-w-3xl': maxWidth,
    })}
    {...rest}
  />
);
