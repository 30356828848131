import { skipToken } from '@reduxjs/toolkit/dist/query';
import ProfileHeader from 'components/ProfileHeader';
import Stats from 'components/Stats';
import { actions as profileActions } from 'containers/Profile';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import theme from 'theme';
import { ProgressionProps as Props } from './types';

export const Progression: React.FC<Props> = ({ profile, levelInfo }) => {
  const dispatch = useDispatch();

  const { id: accountId } = profile.account;
  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);
  const showMyStore = dynamicConfiguration?.profile.showMyLocation ?? true;

  React.useEffect(() => {
    dispatch(profileActions.getUserProgress());
  }, [dispatch]);

  const { user } = profile;
  const { progress } = user;

  const redColors = [theme.gradients.red, '#f34280'];
  const silverColors = [theme.gradients.grey, 'linear-gradient(to bottom, #adadad 0%, #ffffff 100%)'];
  const goldColors = [theme.gradients.orange, 'linear-gradient(to bottom, #f76b1c 0%, #fad961 100%)'];
  const diamondColors = [theme.gradients.diamond, 'linear-gradient(to bottom, #24fe41 0%, #fdfc47 100%)'];

  const progMeLastMonth = progress.meLastMonth;
  const progMeBrightColors = progMeLastMonth >= 90 && progMeLastMonth < 100 ? goldColors : diamondColors;
  const progMeNeutralColors = progMeLastMonth >= 80 && progMeLastMonth < 90 ? silverColors : progMeBrightColors;
  const meLastMonthColors = progMeLastMonth < 80 ? redColors : progMeNeutralColors;

  const { meOverall } = progress;
  const meOverallBrightColors = meOverall >= 90 && meOverall < 100 ? goldColors : diamondColors;
  const meOverallNeutralColors = meOverall >= 80 && meOverall < 90 ? silverColors : meOverallBrightColors;
  const meOverallColors = meOverall < 80 ? redColors : meOverallNeutralColors;

  const colors = [
    theme.colors.turquoiseBlue,
    theme.colors.redSecondary,
    theme.colors.blueSecondary,
    theme.colors.purpleSecondary,
    theme.colors.marigoldSecondary,
  ];
  const primaryColor = colors[levelInfo.currentLevelIndex]
    ? colors[levelInfo.currentLevelIndex]
    : colors[colors.length - 1];

  const statsData = [
    {
      name: 'lastMonth',
      me: progMeLastMonth,
      myStore: progress.myStoreLastMonth,
      total: progress.totalLastMonth,
      colors: meLastMonthColors,
      defaultColor: theme.colors.purple,
    },
    {
      name: 'overall',
      me: meOverall,
      myStore: progress.myStoreOverall,
      total: progress.totalOverall,
      colors: meOverallColors,
      defaultColor: theme.colors.aquaMarine,
    },
  ];

  return (
    <div className='h-full p-3'>
      <ProfileHeader
        primaryColor={primaryColor}
        user={profile.user}
        src={user.avatar}
      />

      <div className='flex justify-between my-6 text-xs'>
        <div className='flex-1 px-4 pt-0 pb-1'>
          <div className='leading-loose text-gray-400'>
            <FormattedMessage id='profile.completedLabel' />
          </div>
          <div
            className='text-lg font-bold'
            style={{ color: primaryColor }}
          >
            <FormattedMessage
              id='profile.completedValue'
              values={{ value: progress.completedModules }}
            />
          </div>
        </div>
        <div className='flex-1 px-4 pt-0 pb-1'>
          <div className='leading-loose text-gray-400'>
            <FormattedMessage id='profile.nextLevelLabel' />
          </div>
          <div
            className='text-lg font-bold'
            style={{ color: primaryColor }}
          >
            <FormattedMessage
              id='profile.nextLevelValue'
              values={{ value: progress.modulesToNextLevel }}
            />
          </div>
        </div>
      </div>
      <div
        id='profile-stats'
        className='flex justify-between my-6 text-xs h-72'
      >
        {statsData.map((stats, idx) => (
          <Stats
            key={`stats-${idx}`}
            data={stats}
            showMyStore={showMyStore}
          />
        ))}
      </div>
    </div>
  );
};
