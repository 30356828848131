/*
 * fetch wrapper with grapphql in mind
 */
import { API_URL } from 'constants/endpoints';
import { DocumentNode } from 'graphql';
import getDefaultHeaders from './getDefaultHeaders';
import { handleError } from './request';

const url = `${API_URL}/graphql`;

export interface GraphQLRequestResult<Data = any> {
  data: Data;
  errors: Error | { errors: Error[] };
}

function checkStatus(response: Response) {
  if (response.ok) {
    return response.json().then((json: Record<string, unknown>) => {
      if (json.errors) {
        const reason = { errors: json.errors, statusCode: response.status };
        return Promise.reject(reason);
      }

      return Promise.resolve(json.data);
    });
  }

  return response.json().then((json: any) => {
    const errors = json.errors || [{ message: json.message }];
    const reason = { errors, statusCode: response.status };

    return Promise.reject(reason);
  });
}

const request = (
  { query, variables }: { query: DocumentNode; variables?: Record<string, unknown> },
  extraHeaders: HeadersInit = {},
): Promise<unknown> => {
  const options = {
    headers: { ...getDefaultHeaders(), ...extraHeaders },
    method: 'POST',
    body: JSON.stringify({ query, variables }),
  };

  return fetch(url, options)
    .then(checkStatus)
    .then((data: unknown) => ({ data }))
    .catch((data: { errors: unknown }) => handleError(data.errors));
};

export default request;
