import classnames from 'classnames';
import ModuleStar from 'components/ModuleStar';
import PopupStar from 'components/PopupStar';
import { SvgIcon } from 'components/svg-icon';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { getColorFromScore } from 'pages/skill';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import tinyColor from 'tinycolor2';
import Base from '../Base';
import { ModuleShapeProps, PolygonType } from '../types';

class Triangle extends Base<ModuleShapeProps> {
  /** array of all module label refs */
  private labelRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {};

  public render() {
    const { modules } = this.props;
    const { width, height } = this.state;

    // Offset to make sure the container has enought space
    // this because the labels are set absolute outside of the canvas
    let heightestRef = 15;
    if (this.labelRefs[0] && this.labelRefs[0].current) {
      const numOfModules = modules.length;
      heightestRef = this.findBiggestRef(this.labelRefs as any, numOfModules);
    }

    return (
      <div
        className='relative h-56 w-72 text-greyish'
        style={{ marginTop: heightestRef }}
      >
        <canvas
          ref={this.canvasRef}
          width={width}
          height={height}
        />
        {this.renderNodes()}
        {this.renderCap('absolute top-20 left-24 w-24 h-20 bg-no-repeat bg-center bg-100/100')}
      </div>
    );
  }

  /**
   * This method needs to be implemented so the Base parent
   * can call it on `componentDidMount`
   */
  public drawShape = () => {
    const { animationIndex, animateUnlocks } = this.props;
    if (animationIndex >= 0 && animationIndex <= 2) {
      if (animateUnlocks) {
        this.unlockNextModule(animationIndex);
      } else {
        this.setState({ animate: true }, () =>
          this.animateLines(this.getTriangle(), 800, () => this.setState({ animate: false })),
        );
      }
    } else {
      this.drawLines(this.getTriangle());
    }
  };

  /**
   * It returns a triangle shape with no animations
   */
  public getTriangle() {
    const { width, height } = this.state;
    const { modules, color } = this.props;
    const lineWidth = 5;
    const halfW = width / 2;

    // Stroked triangle
    if (this.ctx && color) {
      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = lineWidth;
    }

    return [
      {
        from: { x: halfW, y: 0 },
        to: { x: width - lineWidth, y: height - lineWidth },
        props: { globalAlpha: modules[1].unlocked ? 1 : 0.3 },
      },
      {
        from: { x: 0, y: height - lineWidth },
        to: { x: width - lineWidth, y: height - lineWidth },
        props: { globalAlpha: modules[2].unlocked ? 1 : 0.3 },
      },
      {
        from: { x: halfW, y: 0 },
        to: { x: 0, y: height - lineWidth },
        props: { globalAlpha: modules[2].done ? 1 : 0.3 },
      },
    ];
  }

  /**
   * It will animate the next unlocked module
   */
  unlockNextModule = (moduleIndex: number) => {
    const lock = moduleIndex + 1;
    this.setState({ animate: true, showLocks: [lock] }, () => {
      const { width, height } = this.state;
      const { color } = this.props;
      const lineWidth = 5;
      const halfW = width / 2;

      // Stroked triangle
      if (this.ctx && color) {
        this.ctx.strokeStyle = color;
        this.ctx.lineWidth = lineWidth;
      }
      const props = { globalAlpha: 0.3 };

      /**
       * The triangle sides to be drawn
       */
      const triangleVertices: PolygonType[] = [
        { from: { x: halfW, y: 0 }, to: { x: width - lineWidth, y: height - lineWidth }, props },
        { from: { x: width - lineWidth, y: height - lineWidth }, to: { x: 0, y: height - lineWidth }, props },
        { from: { x: halfW, y: 0 }, to: { x: 0, y: height - lineWidth }, props },
      ];
      /**
       * We loop the sides and add props that will show better looking
       * animation.
       */
      const animatedOptions = { props: { globalAlpha: 1 } };
      const animatedTriangle = triangleVertices.reduce((acc: PolygonType[], line, indx) => {
        if (indx < moduleIndex) {
          return [...acc, { ...line, ...animatedOptions }];
        }
        if (indx === moduleIndex) {
          return [...acc, line, { ...line, ...animatedOptions, animated: true }];
        }
        return [...acc, line];
      }, []);

      /**
       * Call `animateLines` that is made available by the Base parent class
       * and use the callback to use sequential animations and set state at
       * the end.
       */
      this.animateLines(animatedTriangle, 800, () => this.setState({ animate: false, showLocks: [] }));
    });
  };

  /**
   * Calls the passed prop onNodeClick when node is clicked
   */
  nodeClicked = (id: string | number) => () => {
    this.props.onNodeClick('moduleTest', id);
  };

  /**
   * It renders the nodes that can be clicked and also will animate
   */
  renderNodes() {
    const { modules, animationIndex, moduleCreditReached, animationColor, color, hasUnlockAnimation } = this.props;
    const { animate } = this.state;
    const { showLocks } = this.state;

    const sides = [
      {
        style: {
          position: 'absolute',
          top: 5,
          left: '50%',
          transform: 'translate3d(-50%, -50%, 0)',
        },
      },
      { style: { position: 'absolute', bottom: -20, right: -15 } },
      { style: { position: 'absolute', bottom: -20, left: -15 } },
    ];

    return sides.map((props, indx) => {
      const { id } = modules[indx];
      const { done } = modules[indx];
      const { passed } = modules[indx];
      const { unlocked } = modules[indx];
      const score = modules[indx].percentage;
      const { title } = modules[indx];
      const unlocking = showLocks.includes(indx);
      const limited = moduleCreditReached && !done;
      const popupType = !unlocked ? ModalTypes.LEMO_LOCKED : ModalTypes.LEMO_LIMIT_REACHED;
      const moduleStarColor = getColorFromScore(score);
      const brightColor = tinyColor(color).brighten();
      const darkColor = tinyColor(color).darken();
      const isTopRow = indx === 0;
      const shouldStartUnlockAnimate = hasUnlockAnimation && passed;

      const baseColor = !unlocked ? '#c3c3c3' : color;
      const brightBaseColor = unlocking ? brightColor : baseColor;
      const finalColor = passed ? darkColor : brightBaseColor;

      this.labelRefs[indx] = React.createRef();
      return (
        <div
          key={`tri-side-${indx}`}
          {...(props as any)}
          className={!unlocked ? 'locked-node' : 'unlocked-node'}
        >
          <SvgIcon
            color={String(finalColor)}
            size={40}
            name='circle'
            style={{ pointerEvents: 'none' }}
          />

          {unlocking || limited || !unlocked ? (
            <SvgIcon
              className='absolute top-0 left-0 translate-3d-[50%,50%,0]'
              color='#fff'
              size={20}
              name={unlocking ? 'lock-open' : 'lock'}
              onClick={this.openPopup(popupType)}
            />
          ) : (
            <SvgIcon
              className={classnames(
                'absolute top-0 left-0 text-aquaMarine fill-current cursor-pointer',
                'opacity-0 transition-opacity duration-200 ease-in-out translate-3d-[-10%,-10%,0]',
              )}
              size={50}
              name='circle'
              onClick={this.nodeClicked(id)}
            />
          )}

          {shouldStartUnlockAnimate && (
            <ModuleStar
              className='absolute top-0 left-0 translate-3d-[50%,50%,0] pointer-events-none'
              color={moduleStarColor}
            />
          )}

          <div
            className={classnames(
              'absolute text-center transform w-20 sm:w-36 -translate-x-1/4 sm:-translate-x-1/3 text-xs sm:text-sm font-medium',
              { '-top-1 sm:-top-2 -translate-y-full': isTopRow },
              {
                'text-turquoiseBlue': unlocked && !passed,
                'text-ocean': passed,
              },
            )}
            style={{ color }}
            ref={this.labelRefs[indx]}
          >
            {title}
          </div>

          {shouldStartUnlockAnimate && animationColor !== 'ruby' && (
            <div
              className={classnames(
                'absolute text-center transform w-20 sm:w-36 -translate-x-1/4 sm:-translate-x-1/3 text-xs sm:text-sm font-medium',
                'bg-white text-turquoiseBlue pointer-events-none',
                {
                  'pb-3 translate-3d-[-25%,-200%,0]': isTopRow,
                  'pb-2.5 -translate-x-1/4': !isTopRow,
                  'opacity-1 transition-opacity duration-200 ease-in-out': animate && animationIndex === indx,
                  'opacity-0 transition-opacity duration-700 ease-in-out delay-200':
                    !animate || animationIndex !== indx,
                },
              )}
            >
              {animationColor === 'diamond' && <FormattedMessage id='comp.moduleShape.finishedDiamond' />}
              {animationColor === 'gold' && <FormattedMessage id='comp.moduleShape.finishedGold' />}
              {animationColor === 'silver' && <FormattedMessage id='comp.moduleShape.finishedSilver' />}
            </div>
          )}

          {shouldStartUnlockAnimate && (
            <PopupStar
              color={animationColor}
              className={classnames('absolute top-4/10 left-1/2 w-20 h-16 pointer-events-none', {
                'opacity-1 translate-3d-scale-[-50%,-50%,0,1] transition-all duration-200 ease-in-out':
                  animate && animationIndex === indx,
                'opacity-0 translate-3d-scale-[-50%,-50%,0,0] transition-all duration-700 ease-in-out':
                  !animate || animationIndex !== indx,
              })}
            />
          )}
        </div>
      );
    });
  }
}

export default Triangle;
