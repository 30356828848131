import classNames from 'classnames';
import { FullScreenOverlay } from 'components/Overlay';
import React from 'react';
import { doNothing } from 'util/doNothing';
import { DrawerProps } from './Drawer.types';

export const Drawer: React.FC<DrawerProps> = ({ isOpen, onClose, children, className, renderElement = doNothing }) => (
  <FullScreenOverlay
    variant='faded'
    open={isOpen}
    onClick={onClose}
  >
    <div
      className={classNames(
        className,
        'fixed z-50 bottom-0 left-5/10 -translate-x-5/10 rounded-lg shadow-lg max-w-3xl w-9/10 h-fit overflow-auto rounded-b-none transform transition-transform slideFromBottom drop-shadow-md',
      )}
    >
      <div className='px-4 pt-4 pb-16'>{children}</div>
    </div>
    {renderElement()}
  </FullScreenOverlay>
);
