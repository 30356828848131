import { Format } from 'containers/FormatScreen/types';
import { Persona } from 'pages/personality-test/PersonalityTest.types';

/**
 * Personality test data references the translatable keys and image sources
 */
export const personalityTest: Format[] = [
  {
    id: '1',
    formatType: 'multiple_image_answer',
    batch: 1,
    generalFeedbackText: '',
    negativeText: '',
    negativeTitle: '',
    partialText: '',
    partialTitle: '',
    positiveText: '',
    positiveTitle: '',
    title: 'personality.test.collections.breadcrumbTitle',
    summary: '',
    content: {
      buttonText: 'Next',
      imageUrl: '',
      question: 'personality.test.collections.question',
      groups: [
        {
          id: '1',
          imgSrc: '/img/personality_test/1_control_freak/perfectionist.jpg',
          name: 'option-1',
        },
        {
          id: '2',
          imgSrc: '/img/personality_test/2_giver/love_sharing.jpg',
          name: 'option-2',
        },
        {
          id: '3',
          imgSrc: '/img/personality_test/3_action_oriented_career_hunter/competent.jpg',
          name: 'option-3',
        },
        {
          id: '4',
          imgSrc: '/img/personality_test/4_creator_or_actor/dramatic.jpg',
          name: 'option-4',
        },
        {
          id: '5',
          imgSrc: '/img/personality_test/5_scientist/isolated.jpg',
          name: 'option-5',
        },
        {
          id: '6',
          imgSrc: '/img/personality_test/6_social_type/devoted.jpg',
          name: 'option-6',
        },
        {
          id: '7',
          imgSrc: '/img/personality_test/7_life_lover/life_lover.jpg',
          name: 'option-7',
        },
        {
          id: '8',
          imgSrc: '/img/personality_test/8_boss/courage.jpg',
          name: 'option-8',
        },
        {
          id: '9',
          imgSrc: '/img/personality_test/9_negociator/careful_2.jpg',
          name: 'option-9',
        },
      ],
      rightButtonText: '',
      wrongButtonText: '',
      url: '',
      options: [
        {
          id: '1',
          imageUrl: '/img/personality_test/1_control_freak/critical.jpg',
          label: 'option-1',
          groupId: '1',
        },
        {
          id: '2',
          imageUrl: '/img/personality_test/1_control_freak/idealist.jpg',
          label: 'option-2',
          groupId: '1',
        },
        {
          id: '3',
          imageUrl: '/img/personality_test/1_control_freak/orderly.jpg',
          label: 'option-3',
          groupId: '1',
        },
        {
          id: '4',
          imageUrl: '/img/personality_test/1_control_freak/perfectionist.jpg',
          label: 'option-4',
          groupId: '1',
        },
        {
          id: '5',
          imageUrl: '/img/personality_test/1_control_freak/reliable.jpg',
          label: 'option-5',
          groupId: '1',
        },
        {
          id: '6',
          imageUrl: '/img/personality_test/1_control_freak/self_discipline.jpg',
          label: 'option-6',
          groupId: '1',
        },
        {
          id: '10',
          imageUrl: '/img/personality_test/2_giver/enthousiast.jpg',
          label: 'option-10',
          groupId: '2',
        },
        {
          id: '11',
          imageUrl: '/img/personality_test/2_giver/flattering.jpg',
          label: 'option-11',
          groupId: '2',
        },
        {
          id: '12',
          imageUrl: '/img/personality_test/2_giver/giver.jpg',
          label: 'option-12',
          groupId: '2',
        },
        {
          id: '13',
          imageUrl: '/img/personality_test/2_giver/helping.jpg',
          label: 'option-13',
          groupId: '2',
        },
        {
          id: '14',
          imageUrl: '/img/personality_test/2_giver/love_sharing.jpg',
          label: 'option-14',
          groupId: '2',
        },
        {
          id: '15',
          imageUrl: '/img/personality_test/2_giver/pleasing.jpg',
          label: 'option-15',
          groupId: '2',
        },
        {
          id: '19',
          imageUrl: '/img/personality_test/3_action_oriented_career_hunter/competent.jpg',
          label: 'option-19',
          groupId: '3',
        },
        {
          id: '20',
          imageUrl: '/img/personality_test/3_action_oriented_career_hunter/flexible.jpg',
          label: 'option-20',
          groupId: '3',
        },
        {
          id: '21',
          imageUrl: '/img/personality_test/3_action_oriented_career_hunter/image_aware.jpg',
          label: 'option-21',
          groupId: '3',
        },
        {
          id: '22',
          imageUrl: '/img/personality_test/3_action_oriented_career_hunter/optimistic.jpg',
          label: 'option-22',
          groupId: '3',
        },
        {
          id: '23',
          imageUrl: '/img/personality_test/3_action_oriented_career_hunter/organising.jpg',
          label: 'option-23',
          groupId: '3',
        },
        {
          id: '24',
          imageUrl: '/img/personality_test/3_action_oriented_career_hunter/target_driven.jpg',
          label: 'option-24',
          groupId: '3',
        },
        {
          id: '28',
          imageUrl: '/img/personality_test/4_creator_or_actor/consensing.jpg',
          label: 'option-28',
          groupId: '4',
        },
        {
          id: '29',
          imageUrl: '/img/personality_test/4_creator_or_actor/creator.jpg',
          label: 'option-29',
          groupId: '4',
        },
        {
          id: '30',
          imageUrl: '/img/personality_test/4_creator_or_actor/dramatic.jpg',
          label: 'option-30',
          groupId: '4',
        },
        {
          id: '31',
          imageUrl: '/img/personality_test/4_creator_or_actor/passionate.jpg',
          label: 'option-31',
          groupId: '4',
        },
        {
          id: '32',
          imageUrl: '/img/personality_test/4_creator_or_actor/romantic.jpg',
          label: 'option-32',
          groupId: '4',
        },
        {
          id: '33',
          imageUrl: '/img/personality_test/4_creator_or_actor/sensitive.jpg',
          label: 'option-33',
          groupId: '4',
        },
        {
          id: '37',
          imageUrl: '/img/personality_test/5_scientist/calm.jpg',
          label: 'option-37',
          groupId: '5',
        },
        {
          id: '38',
          imageUrl: '/img/personality_test/5_scientist/distant.jpg',
          label: 'option-38',
          groupId: '5',
        },
        {
          id: '39',
          imageUrl: '/img/personality_test/5_scientist/isolated.jpg',
          label: 'option-39',
          groupId: '5',
        },
        {
          id: '40',
          imageUrl: '/img/personality_test/5_scientist/observing.jpg',
          label: 'option-40',
          groupId: '5',
        },
        {
          id: '41',
          imageUrl: '/img/personality_test/5_scientist/sharp_minded.jpg',
          label: 'option-41',
          groupId: '5',
        },
        {
          id: '42',
          imageUrl: '/img/personality_test/5_scientist/sober.jpg',
          label: 'option-42',
          groupId: '5',
        },
        {
          id: '46',
          imageUrl: '/img/personality_test/6_social_type/braveness_for_group_goals.jpg',
          label: 'option-46',
          groupId: '6',
        },
        {
          id: '47',
          imageUrl: '/img/personality_test/6_social_type/devoted.jpg',
          label: 'option-47',
          groupId: '6',
        },
        {
          id: '48',
          imageUrl: '/img/personality_test/6_social_type/disbelief.jpg',
          label: 'option-48',
          groupId: '6',
        },
        {
          id: '49',
          imageUrl: '/img/personality_test/6_social_type/engagingly.jpg',
          label: 'option-49',
          groupId: '6',
        },
        {
          id: '50',
          imageUrl: '/img/personality_test/6_social_type/fearful.jpg',
          label: 'option-50',
          groupId: '6',
        },
        {
          id: '51',
          imageUrl: '/img/personality_test/6_social_type/security.jpg',
          label: 'option-51',
          groupId: '6',
        },
        {
          id: '55',
          imageUrl: '/img/personality_test/7_life_lover/charming.jpg',
          label: 'option-55',
          groupId: '7',
        },
        {
          id: '56',
          imageUrl: '/img/personality_test/7_life_lover/happy.jpg',
          label: 'option-56',
          groupId: '7',
        },
        {
          id: '57',
          imageUrl: '/img/personality_test/7_life_lover/life_lover.jpg',
          label: 'option-57',
          groupId: '7',
        },
        {
          id: '58',
          imageUrl: '/img/personality_test/7_life_lover/party_animal.jpg',
          label: 'option-58',
          groupId: '7',
        },
        {
          id: '59',
          imageUrl: '/img/personality_test/7_life_lover/spontaneous.jpg',
          label: 'option-59',
          groupId: '7',
        },
        {
          id: '63',
          imageUrl: '/img/personality_test/7_life_lover/structured.jpg',
          label: 'option-63',
          groupId: '7',
        },
        {
          id: '64',
          imageUrl: '/img/personality_test/8_boss/authority.jpg',
          label: 'option-64',
          groupId: '8',
        },
        {
          id: '65',
          imageUrl: '/img/personality_test/8_boss/combat.jpg',
          label: 'option-65',
          groupId: '8',
        },
        {
          id: '66',
          imageUrl: '/img/personality_test/8_boss/courage.jpg',
          label: 'option-66',
          groupId: '8',
        },
        {
          id: '67',
          imageUrl: '/img/personality_test/8_boss/extensive_physical_pleasure_1.jpg',
          label: 'option-67',
          groupId: '8',
        },
        {
          id: '68',
          imageUrl: '/img/personality_test/8_boss/extensive_physical_pleasure_2.jpg',
          label: 'option-68',
          groupId: '8',
        },
        {
          id: '69',
          imageUrl: '/img/personality_test/8_boss/justice.jpg',
          label: 'option-69',
          groupId: '8',
        },
        {
          id: '73',
          imageUrl: '/img/personality_test/9_negociator/careful_1.jpg',
          label: 'option-73',
          groupId: '9',
        },
        {
          id: '74',
          imageUrl: '/img/personality_test/9_negociator/careful_2.jpg',
          label: 'option-74',
          groupId: '9',
        },
        {
          id: '75',
          imageUrl: '/img/personality_test/9_negociator/easy_going_1.jpg',
          label: 'option-75',
          groupId: '9',
        },
        {
          id: '76',
          imageUrl: '/img/personality_test/9_negociator/easy_going_2.jpg',
          label: 'option-76',
          groupId: '9',
        },
        {
          id: '77',
          imageUrl: '/img/personality_test/9_negociator/relaxed_1.jpg',
          label: 'option-77',
          groupId: '9',
        },
        {
          id: '78',
          imageUrl: '/img/personality_test/9_negociator/relaxed_2.jpg',
          label: 'option-78',
          groupId: '9',
        },
      ],
    },
  },
  {
    id: '2',
    formatType: 'multiple_choice',
    batch: 1,
    generalFeedbackText: '',
    negativeText: '',
    negativeTitle: '',
    partialText: '',
    partialTitle: '',
    positiveText: '',
    positiveTitle: '',
    title: 'personality.test.tags.breadcrumbTitle',
    summary: '',
    content: {
      buttonText: 'Next',
      imageUrl: '',
      question: 'personality.test.tags.question',
      groups: [],
      rightButtonText: '',
      wrongButtonText: '',
      url: '',
      options: [
        {
          id: '1',
          imageUrl: '',
          label: Persona.CONTROL,
          groupId: '1',
        },
        {
          id: '2',
          imageUrl: '',
          label: Persona.HELPING_GIVING,
          groupId: '2',
        },
        {
          id: '3',
          imageUrl: '',
          label: Persona.SUCCESSFUL,
          groupId: '3',
        },
        {
          id: '4',
          imageUrl: '',
          label: Persona.UNIQUE_SPECIAL,
          groupId: '4',
        },
        {
          id: '5',
          imageUrl: '',
          label: Persona.SMART,
          groupId: '5',
        },
        {
          id: '6',
          imageUrl: '',
          label: Persona.LOYAL,
          groupId: '6',
        },
        {
          id: '7',
          imageUrl: '',
          label: Persona.HAPPY,
          groupId: '7',
        },
        {
          id: '8',
          imageUrl: '',
          label: Persona.STRONG,
          groupId: '8',
        },
        {
          id: '9',
          imageUrl: '',
          label: Persona.RELAX_SATISFIED,
          groupId: '9',
        },
      ],
    },
  },
];
