import { API_URL } from 'constants/endpoints';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ChannelMemberResponse, UserResponse } from 'stream-chat';
import { ChannelPreviewUIComponentProps, useChatContext } from 'stream-chat-react';
import { ListItemPreview } from './ListItemPreview';
import { useChannelRead } from './hooks/useChannelRead';

const emptyUserFallback: UserResponse = { id: 'deleted-user' };

export const ChatPreview: React.FC<ChannelPreviewUIComponentProps> = ({
  channel,
  displayTitle,
  displayImage,
  lastMessage,
  setActiveChannel,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const { client } = useChatContext();

  const channelMembers = Object.values(channel?.state?.members ?? {});
  const isGroupChat = Boolean(channel?.data?.isGroup);

  const getUserPresence = (members: ChannelMemberResponse[], loggedUserId?: string) => {
    if (isGroupChat) {
      return null;
    }

    const user = members.find((member) => member.user?.id !== loggedUserId)?.user;
    return user?.online ? 'online' : 'offline';
  };

  const getLoggedUser = (members: ChannelMemberResponse[], selfId?: string) => {
    if (isGroupChat) {
      return emptyUserFallback;
    }

    return members.find((member) => member.user?.id === selfId)?.user ?? emptyUserFallback;
  };

  const isLastMessageFromLoggedUser = lastMessage?.user?.id === client.userID;
  const member = getLoggedUser(channelMembers);
  const { unreadCount } = useChannelRead({ member, channel });

  const handleSetActiveChannel = async () => {
    if (!setActiveChannel || !channel || !channel?.id) {
      return;
    }

    setActiveChannel(channel);

    await channel.watch({ presence: true, state: true, watch: true });

    history.push(`/chat/${channel?.id}`);
  };

  const userAvatar = !isGroupChat && displayImage ? `${API_URL}/${displayImage}` : '';
  const image = isGroupChat ? displayImage : userAvatar;
  const userPresence = getUserPresence(channelMembers, client.userID);
  const lastMessageTranslated =
    lastMessage?.type === 'deleted'
      ? intl.formatMessage({ id: 'community.chat.delete-message.message', defaultMessage: 'Message deleted by user' })
      : lastMessage?.text;
  const lastMessageText = lastMessageTranslated ?? '';
  const lastMessagePrefix = intl.formatMessage({ id: 'community.chat.you', defaultMessage: 'You:' });
  const displayLastMessage = isLastMessageFromLoggedUser ? `${lastMessagePrefix} ${lastMessageText}` : lastMessageText;
  const lastMessageAt = lastMessage?.created_at ? moment(lastMessage.created_at).fromNow() : '';

  return (
    <div className='p-4 pt-0'>
      <ListItemPreview
        title={displayTitle ?? ''}
        message={displayLastMessage}
        image={image}
        userPresence={userPresence}
        unreadCount={unreadCount}
        lastMessageAt={lastMessageAt}
        onClick={handleSetActiveChannel}
      />
    </div>
  );
};
