/* eslint-disable no-case-declarations */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actions as authActions, GetUserInfoSuccessPayload } from 'containers/authentication';
import update from 'immutability-helper';
import { actions as personalityTestActions, PersonalityTestStatus } from 'pages/personality-test';
import { lepaApi } from './Lepa.api';
import { isLegacyLearningPath } from './Lepa.helpers';
import {
  ActiveLearningPathStore,
  CertificationStates,
  CreateCertificatePayload,
  CreateCertificateSuccessPayload,
  SendCertificatePayload,
  TrackTipPayload,
  TrackTipSuccessPayload,
} from './Lepa.types';

const initialState: ActiveLearningPathStore = {
  id: '',
  slug: '',
  currentLevelName: 'Player',
  currentLevelIndex: 0,
  moduleCreditReached: false,
  feedierURL: '',
  levels: [],
  error: null,
  type: null,
  showLogo: false,
  skippedRetentionTest: false,
  color: '',
  isCertification: false,
  certificateId: '',
  certificationState: CertificationStates.idle,
  splashScreen: null,
  pathBackground: null,
  locales: [],
  locale: null,
  openedTipsTricks: [],
};

const slice = createSlice({
  name: 'activeLepa',
  initialState,
  reducers: {
    trackTip: (state, _action: PayloadAction<TrackTipPayload>) => state,
    trackTipSuccess: (state, action: PayloadAction<TrackTipSuccessPayload>) => {
      const { id, tracked } = action.payload;
      const tipId = parseInt(id, 10);
      if (tracked && !state.openedTipsTricks.includes(tipId)) {
        state.openedTipsTricks = [...state.openedTipsTricks, tipId];
      }
    },
    trackTipError: (state, _action: PayloadAction<Error>) => state,
    skipRetentionTest: (state) => state,
    skipRetentionTestSuccess: (state) => state,
    skipRetentionTestError: (state, _action: PayloadAction<Error>) => state,
    createCertificate: (state, _action: PayloadAction<CreateCertificatePayload>) => {
      state.certificationState = CertificationStates.creating;
      state.error = null;
    },
    createCertificateSuccess: (state, action: PayloadAction<CreateCertificateSuccessPayload>) => {
      const { id } = action.payload;
      state.certificationState = CertificationStates.created;
      state.certificateId = id;
    },
    createCertificateError: (state, action: PayloadAction<Error>) => {
      state.certificationState = CertificationStates.createFailed;
      state.error = action.payload.message;
    },
    sendCertificate: (state, _action: PayloadAction<SendCertificatePayload>) => {
      state.certificationState = CertificationStates.sending;
      state.error = null;
    },
    sendCertificateSuccess: (state) => {
      state.certificationState = CertificationStates.sent;
    },
    sendCertificateError: (state, action: PayloadAction<Error>) => {
      state.certificationState = CertificationStates.sendFailed;
      state.error = action.payload.message;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authActions.getUserInfoSuccess.type, (state, action) => {
        const typedAction = action as PayloadAction<GetUserInfoSuccessPayload>;
        const { me } = typedAction.payload.users;
        state.moduleCreditReached = me.moduleCreditReached;
      })
      .addCase(personalityTestActions.setStatus, (state, action) => {
        if (action.payload !== PersonalityTestStatus.CLOSED) {
          return state;
        }
        return initialState;
      })
      .addMatcher(lepaApi.endpoints.startLepa.matchFulfilled, (state, action) => {
        const { payload: learningPath } = action;
        if (!isLegacyLearningPath(learningPath)) {
          return state;
        }
        const openedTipsTricks = learningPath.content.levels.flatMap((level) =>
          level.tipsTricks.filter((tipTrick) => tipTrick.opened).map((tipTrick) => tipTrick.id),
        );
        return update(state, {
          openedTipsTricks: { $set: openedTipsTricks },
        });
      })
      .addMatcher(lepaApi.endpoints.startLepa.matchFulfilled, (state, action) => {
        const { payload: learningPath } = action;
        return {
          ...state,
          splashScreen: learningPath.splashScreen,
          pathBackground: learningPath.pathBackground,
        };
      });
  },
});

export const { actions } = slice;
export default slice.reducer;
