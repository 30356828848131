import { BackButtonIframe } from 'components/BackButtonIframe';
import { Frame } from 'components/frame';
import { useLTI } from 'pages/lti/LTI.controller';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { Playground } from 'templates/playground';
import { LTICourseProps as Props } from './LTICourse.types';
import { LTIAuthentication } from './components';

export const LTICourse: React.FC<Props> = ({ provider }) => {
  const { providersState, url: courseUrl } = useLTI({ provider });
  const providerAuthCredentials = providersState[provider];

  if (!courseUrl) {
    return <Redirect to='/' />;
  }

  if (!providerAuthCredentials) {
    return (
      <FormattedMessage
        id='lti.loading-course'
        tagName='p'
      />
    );
  }

  return (
    <Playground
      className='max-w-none'
      heading={<BackButtonIframe to='/' />}
    >
      <LTIAuthentication
        targetName='lti-course'
        authRequestCredentials={providerAuthCredentials}
        url={courseUrl}
      />
      <Frame
        name='lti-course'
        title='lti-course'
        data-testid='lti-course-frame'
        src={courseUrl}
      />
    </Playground>
  );
};
