import { PageContainer } from 'components/page-container';
import { TopBar } from 'components/top-bar';
import { ProfileStore } from 'containers/Profile';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { UserResponse } from 'stream-chat';
import { LoadingIndicator, useChatContext } from 'stream-chat-react';
import { SelectedUsers } from '.';
import { AppChannelUser } from '../../Chat.types';
import { AlphabeticUserList } from '../../components/alphabetic-user-list';
import { SearchInput } from '../../components/search-input';
import { useSearchUsers } from '../../hooks/useSearchUsers';
import { NewChatViews } from '../NewChat.types';
import { NewGroupProps } from './NewGroup.types';

export const NewGroup: FC<NewGroupProps> = ({ selectedUsers, onUpdateSelectedUsers, onChangeView }) => {
  const intl = useIntl();
  const { client } = useChatContext();

  const filters = useMemo(() => ({ id: { $ne: String(client.userID) } }), [client.userID]);
  const { searchUsers, users, isLoading, searchText } = useSearchUsers(filters);

  const { primaryColor } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const handleBack = () => {
    onUpdateSelectedUsers([]);
    onChangeView(NewChatViews.NEW_CHAT);
  };

  const handleNext = () => {
    onChangeView(NewChatViews.NEW_GROUP_DETAILS);
  };

  const hasSelectedUsers = selectedUsers.length > 0;

  const isUserSelected = (user: UserResponse) => selectedUsers.some((currentUser) => currentUser.id === user.id);

  const handleDeselectUser = (user: UserResponse) => {
    const nextSelectedUsers = selectedUsers.filter((currentUser) => currentUser.id !== user.id);
    onUpdateSelectedUsers(nextSelectedUsers);
  };

  const handleToggleUser = (user: UserResponse) => {
    if (isUserSelected(user)) {
      handleDeselectUser(user);
      return;
    }

    onUpdateSelectedUsers([...selectedUsers, user]);
  };

  const groupUsers: AppChannelUser[] = users.map((user) => ({
    ...user,
    isSelected: isUserSelected(user),
  }));

  return (
    <>
      <TopBar
        backButton={<TopBar.IconButton onClick={handleBack} />}
        nextButton={
          <TopBar.Button
            onClick={handleNext}
            disabled={!hasSelectedUsers}
          />
        }
      >
        <h3 className='flex-1 p-4 text-lg font-medium text-center text-white uppercase'>
          {intl.formatMessage({ id: 'community.chat.new.group', defaultMessage: 'New Group' })}
        </h3>
      </TopBar>

      <PageContainer className='h-full overflow-y-hidden bg-zinc-100'>
        <SearchInput
          value={searchText}
          onChange={searchUsers}
        />

        {hasSelectedUsers && (
          <SelectedUsers
            users={selectedUsers}
            onClose={handleDeselectUser}
          />
        )}

        {isLoading ? (
          <div className='flex justify-center w-full p-5'>
            <LoadingIndicator color={primaryColor} />
          </div>
        ) : (
          <AlphabeticUserList
            users={groupUsers}
            hasSelectIndicator
            sectionClassName='pb-3 mx-4'
            listClassName='overflow-y-auto'
            onClick={handleToggleUser}
          />
        )}
      </PageContainer>
    </>
  );
};
