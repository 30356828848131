import Animated from 'animated/lib/targets/react-dom';
import svg from 'assets/images/draggable.svg';
import classnames from 'classnames';
import * as React from 'react';
import theme from 'theme';
import { doNothing } from 'util/doNothing';
import { DragableProps as Props, DragableState as State } from './types';

class Dragable extends React.Component<Props, State> {
  static defaultProps = {
    position: 0,
    longPressTimer: 100,
    onMove: doNothing,
    onStop: doNothing,
    springConfig: {
      tension: 300,
      friction: 30,
    },
  };

  startValue = 0;

  startPosition = 0;

  timer?: number;

  dragging = false;

  rootRef: React.RefObject<any> = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      anim: new Animated.Value(props.position),
      dragging: false,
    };
  }

  componentDidMount() {
    const root = this.rootRef.current;
    if (root !== null && this.props.onLayout) {
      this.props.onLayout(root.refs.node.getBoundingClientRect(), this.props.extraData.answer);
    }
  }

  componentWillUnmount() {
    this.cancelLongPress();

    window.removeEventListener('touchmove', this.onDragMove);
    window.removeEventListener('touchend', this.onDragStop);
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (!this.state.dragging && nextProps.position !== this.props.position) {
      this.animateMove(nextProps.position);
    }

    return nextState.dragging !== this.state.dragging || nextProps.disabled !== this.props.disabled;
  }

  asyncDragStart = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.persist();
    window.addEventListener('mouseup', this.cancelLongPress);
    window.addEventListener('mousemove', this.cancelLongPress);

    window.addEventListener('touchmove', this.cancelLongPress, { passive: false });
    window.addEventListener('touchend', this.cancelLongPress);

    this.timer = setTimeout(() => {
      this.cancelLongPress();
      this.onDragStart(event);
    }, this.props.longPressTimer);
  };

  cancelLongPress = () => {
    clearTimeout(this.timer);

    window.removeEventListener('mouseup', this.cancelLongPress);
    window.removeEventListener('mousemove', this.cancelLongPress);

    window.removeEventListener('touchmove', this.cancelLongPress);
    window.removeEventListener('touchend', this.cancelLongPress);
  };

  onDragStart = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.enableTouchEvents(event);
    this.setState({ dragging: true });

    this.state.anim.stopAnimation((startValue: number) => {
      this.startValue = startValue;
      this.startPosition = event.clientY;

      window.addEventListener('mousemove', this.onDragMove);
      window.addEventListener('mouseup', this.onDragStop);

      window.addEventListener('touchmove', this.onDragMove, { passive: false });
      window.addEventListener('touchend', this.onDragStop);
    });
  };

  onDragMove = (event: TouchEvent | MouseEvent) => {
    event.preventDefault();
    this.enableTouchEvents(event);

    let movement;
    if (event instanceof MouseEvent) {
      movement = event.clientY - this.startPosition;
    } else {
      movement = event.touches[0].pageY - this.startPosition;
    }

    const value = this.startValue + movement;
    const { _value } = this.state.anim;

    if (value === _value) {
      return;
    }

    this.state.anim.setValue(value);

    this.props.onMove(value, this.props.extraData);
  };

  onDragStop = () => {
    this.setState({ dragging: false });

    this.animateMove(this.props.position);

    window.removeEventListener('mousemove', this.onDragMove);
    window.removeEventListener('mouseup', this.onDragStop);

    window.removeEventListener('touchmove', this.onDragMove);
    window.removeEventListener('touchend', this.onDragStop);
  };

  animateMove = (toValue: number) => {
    Animated.spring(this.state.anim, {
      toValue,
      ...this.props.springConfig,
    }).start();
  };

  enableTouchEvents = (event: any) => {
    // Touch start and move
    if (event.touches && event.touches.length) {
      if (typeof event.persist === 'function') {
        event.persist();
      }

      event.clientX = event.touches[0].clientX;
      event.clientY = event.touches[0].clientY;
    }

    // Touch end
    if (event.changedTouches && event.changedTouches.length) {
      if (typeof event.persist === 'function') {
        event.persist();
      }

      event.clientX = event.changedTouches[0].clientX;
      event.clientY = event.changedTouches[0].clientY;
    }
  };

  render() {
    const {
      focusColor = theme.colors.turquoiseBlue,
      children,
      showAnswers,
      correct,
      longPressEnabled,
      disabled,
    } = this.props;
    const { anim, dragging } = this.state;

    const dragStart = longPressEnabled ? this.asyncDragStart : this.onDragStart;

    return (
      <Animated.div
        ref={this.rootRef}
        id='draggable-answer'
        className={classnames(
          'absolute flex left-5/10 justify-between rounded-lg  cursor-pointer border-4 border-solid',
          { 'text-greyishBrown bg-gray-200': !showAnswers },
          { 'bg-fullRed text-white border-fullRed': showAnswers && !correct },
          { 'bg-yellowGreen text-white border-yellowGreen': showAnswers && correct },
          'border-transparent select-none p-2 transition-colors',
          {
            'bg-white z-10 shadow-lg transition-color transition-shadow': dragging,
          },
        )}
        style={{
          transform: [{ translateY: anim }, { translateX: '-50%' }],
          borderColor: dragging ? focusColor : '',
          minWidth: '95%',
        }}
        onMouseDown={disabled ? undefined : dragStart}
        onTouchStart={disabled ? undefined : dragStart}
      >
        {children}
        {!disabled && (
          <div className='flex items-center px-3 leading-none'>
            <img
              draggable='false'
              className='w-8 opacity-75'
              src={svg}
              alt=''
            />
          </div>
        )}
      </Animated.div>
    );
  }
}

export default Dragable;
