import classnames from 'classnames';
import { RadialProgress } from 'components/RadialProgress';
import fitty from 'fitty';
import React from 'react';
import theme from 'theme';
import { LearningPathVariantProps } from '../types';
import Module from './Module';

const createOnClick =
  (fn: (value: any, event: React.MouseEvent<HTMLElement>) => void, value: any) =>
  (event: React.MouseEvent<HTMLElement>) =>
    fn(value, event);

// the svg viewbox width
const viewBoxW = 250;
// the svg viewbox height
const viewBoxH = 72;
// Initial path `d`
export const pathInitial =
  'M5,0 C5.27725324,21.5447695 25.6095154,38.9997301 50.5741054,38.9997301 L165.792402,38.9997301 L165.741993,39.0002699 L228.425895,39.0002699 C253.391021,39.0002699 273.723283,56.4552305 274,78';
// Dash array calculated from the initial path length
const dashArrayInitial = 260.776;
// Middle path `d`
export const pathMiddle =
  'M245,0 C244.750621,19.8870914 226.412234,35.9997352 203.895585,35.9997352 L99.977376,35.9997352 L100.022624,36.0002648 L46.1044149,36.0002648 C23.587766,36.0002648 5.24937943,52.1129086 5,72';
// Dash array calculated from the middle path length
const dashArrayMiddle = 296.216;

export const DefaultPath: React.FC<LearningPathVariantProps> = ({
  width = 250,
  initial,
  primaryColor,
  secondaryColor,
  lockedColor,
  pathThickness,
  reverse = false,
  bounce = false,
  unlocked,
  percentage,
  skill,
  onNodeClick,
  ...props
}) => {
  // Fits the title
  fitty('#path-title', {
    maxSize: 14,
    minSize: 8,
  });

  const initialOrReverse = initial || reverse;
  const neitherInitialNorReverse = !initial && !reverse;

  return (
    <>
      <svg
        x='0px'
        y='0px'
        width={width}
        height={width * (viewBoxH / viewBoxW)}
        viewBox={`0 0 ${viewBoxW} ${viewBoxH}`}
        {...props}
        style={reverse ? { transform: 'scaleX(-1)', display: 'block' } : { display: 'block' }}
        opacity={unlocked ? '1' : '0.3'}
      >
        {initial
          ? [
              <path
                key='initial-secondary'
                fill='none'
                stroke={percentage === 1 ? primaryColor : secondaryColor}
                strokeWidth={pathThickness}
                d={pathInitial}
              />,
              <path
                key='initial-primary'
                strokeDasharray={dashArrayInitial}
                strokeDashoffset={percentage * dashArrayInitial + dashArrayInitial}
                fill='none'
                stroke={primaryColor}
                strokeWidth={pathThickness}
                d={pathInitial}
              />,
            ]
          : [
              <path
                key='middle-secondary'
                fill='none'
                stroke={percentage === 1 ? primaryColor : secondaryColor}
                strokeWidth={pathThickness}
                d={pathMiddle}
              />,
              <path
                key='middle-primary'
                strokeDasharray={dashArrayMiddle}
                strokeDashoffset={percentage * dashArrayMiddle + dashArrayMiddle}
                fill='none'
                stroke={primaryColor}
                strokeWidth={pathThickness}
                d={pathMiddle}
              />,
            ]}
      </svg>
      {!!skill && (
        <div
          className={classnames(
            'absolute -top-5',
            { 'left-10': initialOrReverse },
            { 'justify-end right-10': neitherInitialNorReverse },
          )}
        >
          {/* by using createOnClick we prevent adding unnecessary event listeners */}
          <div
            data-testid='skill-container'
            className={classnames('flex items-center cursor-pointer flex-nowrap justify-inherit', {
              'justify-end': neitherInitialNorReverse,
            })}
            onClick={createOnClick(onNodeClick, skill.id)}
          >
            {initialOrReverse
              ? [
                  <RadialProgress
                    key={`radial-progress-${skill.id}`}
                    value={skill.testScore}
                    learningInformation={{
                      id: skill.id,
                      unlocked: skill.unlocked,
                      passed: skill.passed,
                    }}
                    primaryColor={primaryColor}
                    lockedColor={lockedColor}
                    bounce={bounce}
                  />,
                  skill.modules.map((module) => (
                    <Module
                      key={`${module.id}`}
                      className='ml-4'
                      nodeSize={15}
                      module={module}
                      primaryColor={primaryColor}
                      lockedColor={lockedColor}
                      pathThickness={pathThickness}
                    />
                  )),
                ]
              : [
                  skill.modules
                    .map((module) => (
                      <Module
                        key={`${module.id}`}
                        className='mr-4'
                        nodeSize={15}
                        module={module}
                        primaryColor={primaryColor}
                        lockedColor={lockedColor}
                        pathThickness={pathThickness}
                      />
                    ))
                    .reverse(),
                  <RadialProgress
                    key={`radial-progress-${skill.id}`}
                    value={skill.testScore}
                    learningInformation={{
                      id: skill.id,
                      unlocked: skill.unlocked,
                      passed: skill.passed,
                    }}
                    primaryColor={primaryColor}
                    lockedColor={lockedColor}
                    bounce={bounce}
                  />,
                ]}
          </div>
          <div
            className={classnames('uppercase text-sm w-64', {
              'pt-1': skill.unlocked,
              'text-left': initialOrReverse,
              'text-right': !initialOrReverse,
            })}
            id='path-title'
            style={{ color: skill && skill.unlocked ? secondaryColor : theme.colors.pinkishGrey }}
          >
            {skill.title}
          </div>
        </div>
      )}
    </>
  );
};

export default DefaultPath;
