import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EvalResult } from 'jsflagr';
import { useSelector } from 'react-redux';
import { call, put, select, StrictEffect, takeLatest } from 'redux-saga/effects';
import { batchEvaluation, defaultEntityContext, ENABLED_VALUE, EntityContext } from 'util/feature-flags';
import { Store } from './types';

export enum FlagKeys {
  SEARCH_PAGE = 'search-page',
  ALTERNATIVE_FORMATS = 'alternative-formats',
  PERSONALITY_TEST = 'personality-test',
  IMAGE_HANDLING = 'cms-image-handling',
  UNLOCK_LEPA_DIALOGS = 'unlock-lepa-dialogs',
  CUSTOMIZABLE_PROFILE = 'customizable-profile',
  GROWTH_PLAN = 'growth-plan',
  SKILLFLIX_FILTERS_CATEGORIES_TYPES = 'skillflix-filters-categories-types',
  AI_CHATBOT = 'ai-chatbot-app',
  LEARNING_PATH_NAVBAR_ICON = 'learning-path-navbar-icon',
  COMMUNITY_CHAT = 'community-chat',
  EMAIL_DIGEST = 'email-digest',
  INSTALL_INSTRUCTIONS = 'install-instructions',
}

function isKnownFeatureFlag(flagKey: string): flagKey is FlagKeys {
  return Object.values(FlagKeys).includes(flagKey as FlagKeys);
}

export interface FeatureFlagsStore {
  pending: boolean;
  flags: Partial<Record<FlagKeys, boolean>>;
}
const initialState: FeatureFlagsStore = {
  pending: false,
  flags: {},
};

const slice = createSlice({
  name: 'feature-flags',
  initialState,
  reducers: {
    getAllFlags: (state) => ({ ...state, pending: true }),
    getAllFlagsSuccess: (_, action: PayloadAction<EvalResult[]>) => {
      const evalResult = action.payload;

      const flags = evalResult.reduce<Partial<Record<FlagKeys, boolean>>>((acc, { flagKey, variantKey }) => {
        if (!flagKey || !isKnownFeatureFlag(flagKey)) {
          return acc;
        }
        return {
          ...acc,
          [flagKey]: variantKey === ENABLED_VALUE,
        };
      }, {});

      return {
        pending: false,
        flags,
      };
    },
    getAllFlagsError: (state, _action: PayloadAction<Error>) => ({ ...state, pending: false }),
  },
});

export const useFeatureFlag = (flagKey: FlagKeys) => {
  const { flags } = useSelector<Store, FeatureFlagsStore>(({ featureFlags }) => featureFlags);
  return Boolean(flags[flagKey]);
};

export const { actions } = slice;

function* getAllFlagsWorker(): Generator<StrictEffect, void> {
  try {
    const state = (yield select()) as Store;
    const accountId = state.profile.account.id;
    const flags = Object.values(FlagKeys);

    const entityContext: EntityContext = {
      ...defaultEntityContext,
      account: parseInt(accountId, 10),
    };

    const response = (yield call(batchEvaluation, flags, entityContext)) as EvalResult[];

    yield put(actions.getAllFlagsSuccess(response));
  } catch (unknownError) {
    const error = unknownError instanceof Error ? unknownError : new Error(String(unknownError));
    yield put(actions.getAllFlagsError(error));
  }
}

export function* rootSaga() {
  yield takeLatest(actions.getAllFlags.type, getAllFlagsWorker);
}

export default slice.reducer;
