import { StepProgressionBar } from 'components/ProgressionBar';
import Question from 'components/Question';
import { SvgIcon } from 'components/svg-icon';
import { SwipeArrow } from 'components/swipe-arrow';
import { ImageFormat, VideoFormat } from 'containers/FormatScreen/Formats';
import { HeaderFeedbackMessage } from 'containers/FormatScreen/Formats/SwipeFormat/HeaderFeedbackMessage';
import SwipeableCard from 'containers/FormatScreen/Formats/SwipeFormat/SwipeableCard';
import { SwipeColumns } from 'containers/FormatScreen/Formats/SwipeFormat/types';
import { useSwipe } from 'containers/FormatScreen/Formats/SwipeFormat/useSwipe';
import { AnswerListSelection } from 'containers/FormatScreen/Formats/components';
import { FormatContentOption } from 'containers/FormatScreen/types';
import { useMainScroll } from 'containers/app/root/MainScroll';
import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { shouldShowWalkthrough, useWalkthrough } from 'containers/walkthrough';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import theme from 'theme';
import { doNothing } from 'util/doNothing';
import { isHTML } from 'util/isHTML';
import { MultipleAnswerProps as Props } from './types';

const MovementToSwipe = {
  [SwipeColumns.LEFT]: -100,
  [SwipeColumns.RIGHT]: 100,
};

const MultipleAnswerSwipe: React.FC<Props> = ({
  initialOptions,
  question,
  imgSrc,
  video,
  focusColor = theme.colors.turquoiseBlue,
  showAnswers,
  selectedAnswers,
  correctAnswers,
  onOptionSelect,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const selectedOptions = selectedAnswers.map((id) => ({ id, label: '', imageUrl: '' }));

  const { enqueueModal } = useModalQueue();
  const { completedWalkthroughs, locale } = useWalkthrough();

  React.useEffect(() => {
    const showWalkthrough = shouldShowWalkthrough(completedWalkthroughs, 'multiple-answer-swipe');
    if (!showWalkthrough) {
      return;
    }

    enqueueModal({
      type: ModalTypes.WALKTHROUGH,
      modal: {
        variant: 'multiple-answer-swipe',
        targets: ['body', '#wt-multiple-answer-swipe'],
        lang: locale,
      },
    });
  }, [completedWalkthroughs, locale, enqueueModal]);

  const handleOptionSelect = (leftGroup: FormatContentOption[], rightGroup: FormatContentOption[]) => {
    const isAnswerDiscarded = leftGroup.length > 0;
    const swipedOption = rightGroup[rightGroup.length - 1];
    if (!swipedOption || isAnswerDiscarded) {
      return;
    }

    onOptionSelect(swipedOption.id);
  };

  const { animationStyles, options, handeSwipeStart, addToColumn, isSwiping } = useSwipe(
    [],
    selectedOptions,
    initialOptions,
    containerRef,
    handleOptionSelect,
  );

  const areOptionsAnswered = options.length === 0;
  const showResults = areOptionsAnswered || showAnswers;

  const mainScrollRef = useMainScroll();
  React.useEffect(() => {
    if (!showResults) {
      return;
    }
    mainScrollRef?.current?.scrollTo(0, 0);
  }, [mainScrollRef, showResults]);

  const handleClickToSwipe = (columnMovement: number) => () => {
    if (isSwiping) {
      return;
    }
    addToColumn(columnMovement);
  };

  const renderSelectionStage = () => {
    const answeredOptions = initialOptions.length - options.length;

    return (
      <>
        <div
          className='min-h-28v'
          id='wt-multiple-answer-swipe'
        >
          <div className='relative top-0 left-0 w-full h-full pt-5'>
            {initialOptions.map((option, i) => {
              const offset = 20 + i * 5;

              const hasBeenAnswered = !options.some((opt) => opt.id === option.id);
              if (hasBeenAnswered) {
                // By using the `initialOptions` and not rendering the already answered questions
                // the card stack remains in the same fixed position when one is removed.
                return null;
              }

              const isCurrentOption = i === answeredOptions;
              if (isCurrentOption) {
                return (
                  <SwipeableCard
                    key={`${option.id}-swipeableCard`}
                    className='w-8/10'
                    data={option}
                    style={{
                      zIndex: initialOptions.length + 1,
                      top: `${offset}px`,
                      ...animationStyles,
                    }}
                    onSwipeStart={handeSwipeStart}
                  />
                );
              }

              return (
                <SwipeableCard
                  key={`${option.id}-swipeableCard`}
                  className='w-8/10'
                  data={option}
                  style={{ zIndex: initialOptions.length - i, top: `${offset}px` }}
                  onSwipeStart={doNothing}
                />
              );
            })}
          </div>
        </div>

        <div className='flex flex-col items-center mt-2.5'>
          <div className='relative flex items-center justify-between w-full mb-4'>
            <div
              className='flex items-center justify-center w-5 cursor-pointer'
              onClick={handleClickToSwipe(MovementToSwipe[SwipeColumns.LEFT])}
            >
              <div className='transform -rotate-90 '>
                <span className='text-xs text-greyishBrown'>
                  <FormattedMessage id='formats.rightwrong.false' />
                </span>
              </div>
              <SwipeArrow
                direction={'left'}
                color={focusColor}
                className={'left-1/10 -translate-y-4/10'}
              />
            </div>

            <div className='flex w-5/10 justify-evenly'>
              <div
                className='flex items-center justify-center w-10 h-10 text-white rounded-full cursor-pointer bg-fullRed bg-opacity-90'
                onClick={handleClickToSwipe(MovementToSwipe[SwipeColumns.LEFT])}
              >
                <SvgIcon
                  name='close'
                  size={24}
                  color={theme.colors.white}
                />
              </div>
              <div
                className='flex items-center justify-center w-10 h-10 text-white rounded-full cursor-pointer bg-yellowGreen bg-opacity-90'
                onClick={handleClickToSwipe(MovementToSwipe[SwipeColumns.RIGHT])}
              >
                <SvgIcon
                  name='check'
                  size={24}
                  color={theme.colors.white}
                />
              </div>
            </div>

            <div
              className='flex items-center justify-center w-5 cursor-pointer'
              onClick={handleClickToSwipe(MovementToSwipe[SwipeColumns.RIGHT])}
            >
              <SwipeArrow
                direction={'right'}
                color={focusColor}
                className={'right-1/10 -translate-y-4/10'}
              />
              <div className='transform rotate-90 '>
                <span className='text-xs text-greyishBrown'>
                  <FormattedMessage id='formats.rightwrong.true' />
                </span>
              </div>
            </div>
          </div>

          <StepProgressionBar
            className='transform scale-125'
            current={answeredOptions}
            steps={initialOptions.length}
            variant='circular'
            color={focusColor}
          />
        </div>
      </>
    );
  };

  const renderResultsStage = () => (
    <AnswerListSelection
      selected={selectedAnswers}
      answers={initialOptions}
      correct={correctAnswers}
      focusColor={focusColor}
      showAnswers={showAnswers}
      onChange={onOptionSelect}
    />
  );

  if (!Array.isArray(options)) {
    return null;
  }

  return (
    <div className='relative'>
      {video && (
        <div className='my-4'>
          <VideoFormat url={video} />
        </div>
      )}
      {imgSrc && !video && (
        <div className='my-4'>
          <ImageFormat imgSrc={imgSrc} />
        </div>
      )}
      <div
        className='relative w-full h-full overflow-x-hidden overflow-y-hidden select-none'
        ref={containerRef}
      >
        {showResults && (
          <HeaderFeedbackMessage
            showAnswers={showAnswers}
            message={<FormattedMessage id='comp.SwipeFormat.finalCheck.body-options' />}
          />
        )}
        {!showResults && (
          <Question
            className='px-2 py-0 text-sm'
            text={question}
            rich={isHTML(question)}
            noStyles
          />
        )}

        <div
          className='flex flex-col h-full'
          dir='ltr'
        >
          {showResults ? renderResultsStage() : renderSelectionStage()}
        </div>
      </div>
    </div>
  );
};

export default MultipleAnswerSwipe;
