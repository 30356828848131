import { animated, config, useSpring } from 'react-spring';

export const PartyingFace = () => {
  const styles = useSpring({
    loop: true,
    from: { transform: 'translateY(-15px) scaleY(0)' },
    to: { transform: 'translateY(0) scaleY(1)' },
    config: config.molasses,
  });

  const partyBlowerStyles = useSpring({
    loop: true,
    from: { transform: 'translateX(55px) scalex(0.25)' },
    to: { transform: 'translatex(0) scalex(1)' },
    config: config.molasses,
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='110'
      height='109'
      fill='none'
      viewBox='0 0 110 109'
    >
      <animated.g style={styles}>
        <path
          fill='#815BCD'
          d='M12.01 11.869l31.138 10.706-20.801 20.557L12.01 11.869z'
        ></path>
        <path
          fill='#C66ED5'
          d='M24.03 16.01l-8.009 8.008 2.2 6.654 12.396-12.396-6.588-2.266z'
        ></path>
      </animated.g>
      <circle
        cx='62.904'
        cy='62.904'
        r='45.748'
        fill='#FFD23B'
      ></circle>
      <path
        fill='#FFB24A'
        d='M62.904 108.652c25.266 0 45.748-20.482 45.748-45.748S88.17 17.155 62.904 17.155v91.497z'
      ></path>
      <path
        fill='#2D3E53'
        d='M44.051 46.91a8.131 8.131 0 00-8.131 8.13c6.248-3.644 9.846-3.563 16.262 0 0-4.49-3.64-8.13-8.131-8.13z'
      ></path>
      <path
        fill='#000'
        d='M81.135 46.608a8.131 8.131 0 00-8.13 8.132c6.247-3.644 9.846-3.563 16.262 0 0-4.491-3.64-8.132-8.132-8.132z'
      ></path>
      <animated.g style={styles}>
        <path
          fill='#E70B69'
          d='M70.747 35.014H77.159V41.426H70.747z'
          transform='rotate(-45 70.747 35.014)'
        ></path>
        <path
          fill='#00B3FF'
          d='M82.919 13.57H89.331V19.982H82.919z'
          transform='rotate(-45 82.919 13.57)'
        ></path>
        <path
          fill='#56C125'
          d='M100.075 23.578H106.48700000000001V29.99H100.075z'
          transform='rotate(-45 100.075 23.578)'
        ></path>
        <path
          fill='#0174F9'
          d='M98.645 99.349H105.057V105.76100000000001H98.645z'
          transform='rotate(-45 98.645 99.349)'
        ></path>
        <path
          fill='#FD4151'
          d='M27.164 103.639H33.576V110.051H27.164z'
          transform='rotate(-45 27.164 103.639)'
        ></path>
        <path
          fill='#ABE233'
          d='M14.296 90.772H20.708V97.18400000000001H14.296z'
          transform='rotate(-45 14.296 90.772)'
        ></path>
      </animated.g>
      <animated.g style={{ ...partyBlowerStyles }}>
        <path
          fill='#815BCD'
          d='M88.638 61.933a9.293 9.293 0 010 18.586H71.482v-5.576h17.156a3.717 3.717 0 10-3.718-3.717h-5.575a9.293 9.293 0 019.293-9.293z'
        ></path>
      </animated.g>
      <path
        fill='#000'
        d='M63.606 69.082c.46-.237.91-.443 1.35-.615.439-.184.882-.335 1.328-.454a9.608 9.608 0 011.37-.259c.467-.064.957-.097 1.472-.097.577 0 1.14.043 1.69.13a7.398 7.398 0 011.575.42c.494.184.947.427 1.36.73.418.29.778.652 1.08 1.084.303.42.536.912.7 1.473.172.561.258 1.198.258 1.91 0 .573-.089 1.128-.267 1.668a4.688 4.688 0 01-.793 1.49 5.008 5.008 0 01-2.034 1.537c.251.102.486.22.705.355a5.01 5.01 0 011.329 1.182c.357.453.62.95.793 1.489.178.54.267 1.096.267 1.668 0 .712-.085 1.349-.257 1.91a4.843 4.843 0 01-.7 1.473 4.337 4.337 0 01-1.082 1.085 5.552 5.552 0 01-1.36.728 7.398 7.398 0 01-1.575.421c-.549.087-1.112.13-1.689.13-.515 0-1.005-.033-1.472-.097a9.608 9.608 0 01-1.37-.26c-.446-.118-.89-.27-1.329-.453-.439-.172-.889-.377-1.349-.615 6.028.508 9.424-5.438 3.852-8.5-.307-.162-.737-.597 0-1.033 5.572-3.062 2.176-9.007-3.852-8.5z'
      ></path>
    </svg>
  );
};
