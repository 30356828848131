import crystalBall from 'assets/images/crystal_ball.svg';
import { Popup } from 'components/popup';
import React from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { PersonalityTestType, PersonalityTestPopupProps as Props } from '../PersonalityTest.types';

const PersonalityTestStartPopupComponent: React.FC<Props> = ({ onModalClose, intl, open }) => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push(`/personality/test/${PersonalityTestType.COLLECTIONS}`);
    onModalClose();
  };

  return (
    <Popup
      variant='personalityTest'
      open={open}
      title={intl.formatMessage({ id: 'personality.start-popup.title' })}
      buttonText={intl.formatMessage({ id: 'personality.start-popup.confirmButton' })}
      onPrimaryClick={handleButtonClick}
      svgIcon={crystalBall}
      onRequestClose={handleButtonClick}
      onClick={handleButtonClick}
    >
      <div key={'text-container'}>
        <div
          className='flex flex-col items-center justify-center my-0 mx-1/10'
          data-testid={'personalitytest-welcome-popup'}
        >
          <p className='font-thin text-center whitespace-pre-line'>
            {intl.formatMessage({ id: 'personality.start-popup.content' })}
          </p>
        </div>
      </div>
    </Popup>
  );
};

export const PersonalityTestStartPopup = injectIntl(PersonalityTestStartPopupComponent);
