import classNames from 'classnames';
import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import React from 'react';
import { PopupVariantProps } from '../Popup.types';
import { CloseIcon } from '../close-icon';
import { PopupTitle } from '../popup-title';

export const CleanPopup: React.FC<PopupVariantProps> = ({
  variant,
  className,
  title,
  stylePopup,
  stylePopupContent,
  closeIcon,
  children,
  buttonText,
  secondaryButtonText,
  onClose,
}) => (
  <PageContainer
    center
    onClick={onClose('modal')}
  >
    <div
      className={classNames('relative flex flex-col p-4 pt-10 bg-white rounded-lg w-9/10 text-greyishBrown', className)}
      style={stylePopup}
      data-testid='e2e-popup'
    >
      {title && <PopupTitle variant={variant}>{title}</PopupTitle>}

      {closeIcon && <CloseIcon onClose={onClose('close')} />}

      <div
        className='flex flex-col items-center justify-center h-full overflow-x-hidden overflow-y-auto'
        style={stylePopupContent}
      >
        {children}
      </div>

      {buttonText && (
        <div className='flex justify-center pt-4'>
          <Button
            variant='outlined'
            text={buttonText}
            onClick={onClose('button')}
          />
          {secondaryButtonText && (
            <Button
              variant='outlined'
              text={secondaryButtonText}
              onClick={onClose('secondaryButton')}
            />
          )}
        </div>
      )}
    </div>
  </PageContainer>
);
