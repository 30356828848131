import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actions as authActions } from 'containers/authentication';
import {
  AnswerPollPayload,
  AnswersPollSuccessPayload,
  GetPostsPayload,
  GetPostsSuccessPayload,
  GetUnreadPostsPayload,
  GetUnreadPostsSuccessPayload,
  LikePostPayload,
  LikePostSuccessPayload,
  NewsfeedStore,
  SaveScrollPayload,
} from './Newsfeed.types';

export const POSTS_FETCH_LIMIT = 20;

const initialState: NewsfeedStore = {
  posts: [],
  error: '',
  isFetching: false,
  isRefreshing: false,
  scrollPos: 0,
  hasReachedEnd: false,
  currentPostLimit: POSTS_FETCH_LIMIT,
  unreadPosts: 0,
};

const slice = createSlice({
  initialState,
  name: 'newsfeed',
  reducers: {
    getPosts: (state, action: PayloadAction<GetPostsPayload>) => ({
      ...state,
      isFetching: true,
      isRefreshing: Boolean(action.payload.refresh),
      error: '',
    }),
    getPostsSuccess: (state, action: PayloadAction<GetPostsSuccessPayload>) => {
      const { data: newPosts } = action.payload;
      const filteredNewPosts = newPosts.filter((post) => !state.posts.find((p) => p.id === post.id));

      const hasReachedEnd = filteredNewPosts.length === 0;
      const shouldRefreshPosts = state.posts.length > 0 && !action.payload.offset;
      const isFetchingNextPosts = Boolean(action.payload.offset);

      const allPosts = isFetchingNextPosts ? state.posts.concat(filteredNewPosts) : state.posts.concat(newPosts);
      const nextPosts = shouldRefreshPosts ? newPosts : allPosts;
      const nextCurrentPostLimit = isFetchingNextPosts
        ? state.currentPostLimit + POSTS_FETCH_LIMIT
        : state.currentPostLimit;
      const nextHasReachedEnd = isFetchingNextPosts && hasReachedEnd;

      return {
        ...state,
        posts: nextPosts,
        isFetching: false,
        error: '',
        isRefreshing: false,
        currentPostLimit: nextCurrentPostLimit,
        hasReachedEnd: nextHasReachedEnd,
        unreadPosts: 0,
      };
    },
    getPostsError: (state, action: PayloadAction<Error>) => ({
      ...state,
      isFetching: false,
      error: action.payload.message,
      isRefreshing: false,
    }),
    getUnreadPosts: (state, _action: PayloadAction<GetUnreadPostsPayload>) => state,
    getUnreadPostsSuccess: (state, action: PayloadAction<GetUnreadPostsSuccessPayload>) => ({
      ...state,
      unreadPosts: action.payload.count,
    }),
    getUnreadPostsError: (state, _action: PayloadAction<Error>) => state,
    likePost: (state, _action: PayloadAction<LikePostPayload>) => ({
      ...state,
      isFetching: false,
      isRefreshing: false,
      error: '',
    }),
    likePostSuccess: (state, action: PayloadAction<LikePostSuccessPayload>) => {
      const hasLiked = action.payload.liked;

      const nextPosts = state.posts.map((post) => {
        if (post.id !== action.payload.postId) {
          return post;
        }

        const updatedPost = {
          ...post,
          like: hasLiked,
          likes: hasLiked ? post.likes + 1 : post.likes - 1,
        };

        return updatedPost;
      });

      return {
        ...state,
        posts: nextPosts,
        isFetching: false,
        isRefreshing: false,
        error: '',
      };
    },
    likePostError: (state, _action: PayloadAction<Error>) => state,
    answerPoll: (state, _action: PayloadAction<AnswerPollPayload>) => state,
    answerPollSuccess: (state, action: PayloadAction<AnswersPollSuccessPayload>) => {
      const post = state.posts.find((currentPost) => currentPost.id === action.payload.postID);
      if (!post?.answers) {
        return state;
      }

      const answer = post.answers.find((ans) => ans.id === action.payload.answerID);
      if (!answer) {
        return state;
      }

      const nextPosts = state.posts.map((currentPost) => {
        if (currentPost.id !== post.id) {
          return currentPost;
        }

        if (!currentPost.answers) {
          return { ...currentPost, answers: post.answers };
        }

        const nextAnswers = currentPost.answers.map((currentAnswer) => {
          if (currentAnswer.id !== answer.id) {
            return currentAnswer;
          }
          return {
            ...currentAnswer,
            answered: true,
          };
        });

        return { ...currentPost, answers: nextAnswers };
      });

      return {
        ...state,
        posts: nextPosts,
      };
    },
    answerPollError: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: action.payload.message,
    }),
    saveScroll: (state, action: PayloadAction<SaveScrollPayload>) => ({
      ...state,
      scrollPos: action.payload.position,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(authActions.setActiveAccountSuccess.type, (state) => ({
      ...state,
      unreadPosts: 0,
    }));
  },
});

export const { actions } = slice;
export default slice.reducer;
