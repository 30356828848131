import { MessageDialog } from 'components/message-dialog';
import { SvgIcon } from 'components/svg-icon';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { MessageTimestamp } from 'stream-chat-react';
import theme from 'theme';
import { MessageDeletedProps } from './Message.types';

export const MessageDeleted: FC<MessageDeletedProps> = ({ isMyMessage }) => (
  <MessageDialog
    isMyMessage={isMyMessage}
    isLastMessageInGroup={false}
    className='bg-neutral-300'
  >
    <div className='flex items-center'>
      <SvgIcon
        viewBox='0 0 24 24'
        name='cancel'
        size={20}
        color={theme.colors.neutral500}
      />

      <span className='px-2 italic text-neutral-500'>
        <FormattedMessage
          id='community.chat.delete-message.message'
          defaultMessage='Message deleted by user'
        />
      </span>
      <span className='self-end text-xs font-light leading-3 text-gray-400'>
        <MessageTimestamp format='HH:mm' />
      </span>
    </div>
  </MessageDialog>
);
