import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loading } from 'components/Loading';
import { defaultLocale } from 'containers/language-provider';
import { Loformats } from 'containers/loformats';
import { AnswerResponse } from 'containers/loformats/hooks/use-learning-format/types';
import { useModalQueue } from 'containers/modal-controller';
import { useStartLepaQuery } from 'pages/lepa/Lepa.api';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import { Store } from 'store/types';
import theme from 'theme';
import { useSubmitLearningFormatAnswerMutation } from './Tests.api';
import { AnswerPayload, LearningMomentUrlParams, LearningTestTypes } from './Tests.types';
import { enqueueLearningPathCompletionModals, mapLearningFormatToOldStructure, prepareFormats } from './helpers';
import { isLearningMoment, useTestConfiguration } from './use-test-configuration';

export const LearningMomentTest: React.FC = () => {
  const history = useHistory();
  const { enqueueModal } = useModalQueue();
  const { learningMomentId: lepaId } = useParams<LearningMomentUrlParams>();
  const { user, account } = useSelector(({ profile }: Store) => profile);
  const { id: userId, locale } = user;
  const { id: accountId } = account;
  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);
  const isEnabledLearningPathRating = dynamicConfiguration?.plugins.learningPathRating ?? false;

  const {
    data: lepaData,
    isError: lepaIsError,
    isFetching,
    originalArgs: lepaCache,
  } = useStartLepaQuery({
    userId: parseInt(userId, 10),
    lepaId: parseInt(lepaId, 10),
    locale: locale ?? defaultLocale,
  });

  const [submitLearningFormatAnswer, answerResponse] = useSubmitLearningFormatAnswerMutation();
  const { data: answerData, originalArgs } = answerResponse;
  const isFetchingWithoutAnswer = isFetching && !answerData;

  // MT-7127: mapping the formats to the old structure so that we don't have to change a lot of code for now
  const learningFormats = useMemo(
    () =>
      lepaData && isLearningMoment(lepaData)
        ? prepareFormats(lepaData.content.formats).map(mapLearningFormatToOldStructure)
        : [],
    [lepaData],
  );

  const testConfiguration = useTestConfiguration(LearningTestTypes.LEARNING_MOMENT, lepaData);
  const isErrorAndMatchingId = lepaIsError && lepaId === String(lepaCache?.lepaId);

  if (isErrorAndMatchingId) {
    history.push('/invalid-url');
  }

  if (!learningFormats.length || !lepaData || isFetchingWithoutAnswer) {
    return <Loading />;
  }

  const title = testConfiguration.getTitle();
  const navigateBackRoute = testConfiguration.getNavigateBackRoute();

  const { xp, percentage, next, passed, learningPathDone } = answerData?.momentCompletion ?? {};

  const handleNavigateAway = () => {
    const { path, state } = testConfiguration.getNavigateAwayPathAndState();
    history.push(path, state);
    if (passed) {
      enqueueLearningPathCompletionModals({
        isEnabledLearningPathRating: isEnabledLearningPathRating && user.role !== 'preview',
        enqueueModal,
        lepaId,
        isCertification: lepaData.isCertification,
        title: lepaData.title,
        color: lepaData.color ?? theme.colors.turquoiseBlue,
        isLearningMoment: true,
      });
    }
  };
  const handleOnAnswer = async (answer: AnswerPayload): Promise<AnswerResponse> => {
    const { learningFormatId, ...body } = answer;

    const response = await submitLearningFormatAnswer({
      learningPathId: parseInt(lepaId, 10),
      learningFormatId,
      body,
    }).unwrap();

    return {
      isCorrect: response.isCorrect,
      correctOptions: response.correctOptions,
      completion: response.momentCompletion,
    };
  };

  return (
    <Loformats
      learningFormats={learningFormats}
      title={title}
      id={lepaId}
      color={lepaData.color ?? undefined}
      navigateBackRoute={navigateBackRoute}
      onNavigateAway={handleNavigateAway}
      onAnswer={handleOnAnswer}
      isSubmittingAnswer={answerResponse.isLoading}
      isCorrect={answerData?.isCorrect}
      correctOptions={answerData?.correctOptions}
      xp={xp}
      percentage={percentage}
      passed={passed}
      next={next}
      learningPathDone={learningPathDone}
      withBackNavigation={testConfiguration.withBackNavigation}
      previousSubmittedFormatId={originalArgs?.learningFormatId}
    />
  );
};
