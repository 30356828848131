import gradientLock from 'assets/images/gradientLock.svg';
import star from 'assets/images/single_star.svg';
import { PathBanner } from 'components/PathBanner';
import { Popup } from 'components/popup';
import React from 'react';
import { injectIntl } from 'react-intl';
import { LevelPassedModalProps as Props } from '../Lepa.types';

const LevelCompletedModalComponent: React.FC<Props> = ({ intl, onModalClose, modal, open }) => (
  <Popup
    variant='gradientFullscreen'
    open={open}
    title={modal.title}
    className='flex items-center justify-center'
    pageContainerClassName='h-full'
    buttonText={modal.buttonText || intl.formatMessage({ id: 'formats.button.thanks' })}
    onRequestClose={onModalClose}
    closeIcon={false}
    hasThanksAndCloseButton={true}
  >
    <PathBanner
      className='h-48'
      minimal
      title={intl
        .formatMessage({ id: 'comp.level-passed-modal.new-level-unlocked', defaultMessage: 'new level unlocked' })
        .toUpperCase()}
      color='#e4f3f5'
      colorDark='#c2d6d9'
      textColor='#00bbd5'
    />
    <div className='relative flex flex-col content-center w-full my-0 text-center sm:w-5/10 sm:my-24'>
      <img
        src={gradientLock}
        className='h-40 m-auto'
        alt=''
      />
      <img
        src={star}
        className='absolute w-1/20 left-1/10 bottom-13/20'
        alt=''
      />
      <img
        src={star}
        className='absolute w-[7%] left-2/10 bottom-4/10'
        alt=''
      />
      <img
        src={star}
        className='absolute w-1/10 left-7/10 bottom-4/10'
        alt=''
      />

      {/* STARS RIGHT BOTTOM */}
      <img
        src={star}
        className='absolute w-[4%] right-1/10 bottom-7/10'
        alt=''
      />
      <img
        src={star}
        className='absolute w-[9%] right-2/10 bottom-17/20 -rotate-45'
        alt=''
      />

      {/* STARS LEFT TOP */}
      <img
        src={star}
        className='absolute w-[7%] left-5/20 top-3/20'
        alt=''
      />
      <h2 className='m-0 text-4xl font-extrabold text-center uppercase text-grey3'>{modal.levelTestTitle}</h2>
      <h3 className='m-0 text-2xl font-bold text-center uppercase text-grey3'>
        {intl.formatMessage({ id: 'comp.level-passed-modal.unlocked', defaultMessage: 'unlocked' }).toUpperCase()}
      </h3>
    </div>
  </Popup>
);

export const LevelCompletedModal = injectIntl(LevelCompletedModalComponent);
