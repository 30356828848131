import classNames from 'classnames';
import { Popup } from 'components/popup';
import { SvgIcon } from 'components/svg-icon';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import theme from 'theme';
import tinycolor from 'tinycolor2';
import { doNothing } from 'util/doNothing';
import { useMobieMessageMutation } from './MobieChatPopup.api';
import { ChatRoleTypes, MessageData, MobieChatPopupProps as Props } from './MobieChatPopup.types';
import { Chat } from './components/chat';
import { ChatForm } from './components/chat-form';

export const MobieChatPopup: React.FC<Props> = ({ open, welcomeMessage, onModalClose }) => {
  const INITIAL_MESSAGE: MessageData = {
    role: ChatRoleTypes.ASSISTANT,
    message: welcomeMessage,
  };

  const [messages, setMessages] = useState<MessageData[]>([INITIAL_MESSAGE]);

  const { id: accountId, pathColor } = useSelector(({ profile }: Store) => profile.account);
  const accountPathColor = pathColor || theme.colors.orangeRed;
  const isAccountPathColorDark = tinycolor(accountPathColor).isDark();

  const [mobieChatMessageMutation, { isLoading }] = useMobieMessageMutation();

  const handleQuestionSubmit = async (message: string) => {
    setMessages((prevMessages) => [...prevMessages, { role: ChatRoleTypes.USER, message }]);

    try {
      const body = {
        message,
      };

      const {
        message: repliedMessage,
        notFoundMessage,
        tags,
      } = await mobieChatMessageMutation({
        accountId,
        body,
      }).unwrap();

      setMessages((prevMessages) => [
        ...prevMessages,
        { role: ChatRoleTypes.ASSISTANT, message: repliedMessage, notFoundMessage, tags },
      ]);
    } catch (error) {
      doNothing();
    }
  };

  return (
    <Popup
      variant='fullscreen'
      open={open}
      closeIconSize={32}
      closeIconColor={theme.colors.darkGrey}
      pageContainerClassName='w-full max-w-none h-full py-4'
      disablePadding
      className='bg-neutral-200 lg:w-96 lg:h-13/20 lg:absolute lg:right-10 lg:mr-1 lg:rounded-2xl drop-shadow-lg lg:bottom-14 lg:-mb-1'
    >
      <div className='flex flex-col justify-end h-full mx-5'>
        <header
          className={classNames(
            'absolute top-0 left-0 z-10 flex flex-row items-center content-center justify-center w-full text-center h-14 lg:rounded-t-2xl drop-shadow-lg',
            {
              'text-white': isAccountPathColorDark,
              'text-neutral-800': !isAccountPathColorDark,
            },
          )}
          style={{
            background: accountPathColor,
          }}
        >
          <span className='text-lg font-bold leading-6'>MOBIE</span>
          <SvgIcon
            name='close'
            className='absolute right-0 mr-5 cursor-pointer lg:hidden'
            size={28}
            onClick={onModalClose}
          />
        </header>
        <Chat
          messages={messages}
          isLoading={isLoading}
          accountPathColor={accountPathColor}
        />
        <ChatForm
          onSubmit={handleQuestionSubmit}
          isLoading={isLoading}
        />
      </div>
    </Popup>
  );
};
