import classnames from 'classnames';
import { TopBarWithComponentsProps } from './TopBar.types';
import { TopBarButton } from './button';
import { TopBarIconButton } from './icon-button';

const TopBar: TopBarWithComponentsProps = ({ backButton, nextButton, className, children, ...rest }) => (
  <header
    className={classnames(
      'sticky top-0 z-10 w-full flex flex-row justify-between px-7 h-16 text-center bg-zinc-900',
      className,
    )}
    {...rest}
  >
    {backButton}
    <div className='flex-1 w-full m-auto text-lg font-medium leading-4 text-center text-white uppercase'>
      {children}
    </div>
    {nextButton}
  </header>
);

TopBar.IconButton = TopBarIconButton;
TopBar.Button = TopBarButton;

export { TopBar };
