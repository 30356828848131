import classnames from 'classnames';
import Textfield from 'components/Textfield';
import { Avatar } from 'components/avatar';
import { SvgIcon } from 'components/svg-icon';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { SeparatorLine } from '../components/separator-line';
import { GroupBasicInfoProps } from './Conversation.types';

export const GroupBasicInfo: FC<GroupBasicInfoProps> = ({
  channel,
  name,
  hasPermission,
  isEditing,
  handleEditName,
  handleChangeName,
}) => {
  const avatar = channel?.data?.image;

  return (
    <section className='mt-8 mb-4'>
      <div className='w-20 h-20 mx-auto mb-3'>
        <Avatar
          className='w-20 h-20 border-0'
          src={avatar}
        />
      </div>
      {isEditing && hasPermission ? (
        <Textfield
          className='flex mb-2 text-center'
          value={name ?? ''}
          inputClassName='bg-zinc-100 text-center text-black'
          typeStyle='login'
          maxLength={50}
          withBorder
          disableInputPadding
          onChange={handleChangeName}
        />
      ) : (
        <div className='w-full text-center px-14'>
          <button
            className={classnames('flex items-center justify-center w-full', { 'cursor-none': !hasPermission })}
            onClick={handleEditName}
          >
            <h2 className='pr-2'>{name}</h2>
            {hasPermission && (
              <SvgIcon
                name='pencil'
                color='gray600'
              />
            )}
          </button>
        </div>
      )}
      <p className='mb-8 text-sm text-center text-gray-600'>
        <FormattedMessage
          id='community.chat.group-info.members'
          defaultMessage='{memberCount} group members'
          values={{ memberCount: channel.data?.member_count }}
        />
      </p>
      <SeparatorLine />
    </section>
  );
};
