import { Walkthrough, useWalkthrough } from 'containers/walkthrough';
import React from 'react';
import { CallBackProps, EVENTS } from 'react-joyride';
import { WalkthroughModalProps as Props } from '../Walkthrough.types';

export const WalkthroughModal: React.FC<Props> = ({ modal, onModalClose, open }) => {
  const { onWalkthroughEnd } = useWalkthrough();

  const handleSkip = async () => {
    onModalClose();

    if (modal.onSkip) {
      await modal.onSkip();
      return;
    }

    await onWalkthroughEnd(modal.variant);
  };

  const handleEnd = async () => {
    onModalClose();

    if (modal.onEnd) {
      await modal.onEnd();
      return;
    }

    await onWalkthroughEnd(modal.variant);
  };

  const handleWalkthroughCallback = (data: CallBackProps) => {
    const hasFailedToGetTarget = data.type === EVENTS.TARGET_NOT_FOUND;
    if (hasFailedToGetTarget) {
      onModalClose();

      // eslint-disable-next-line no-console
      console.warn(`Failed to target ${modal.variant} walkthrough.`);
    }
  };

  return (
    <Walkthrough
      variant={modal.variant}
      open={open}
      targets={modal.targets}
      lang={modal.lang}
      callback={handleWalkthroughCallback}
      onSkip={handleSkip}
      onEnd={handleEnd}
    />
  );
};
