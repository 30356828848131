import classnames from 'classnames';
import { Avatar } from 'components/avatar';
import { ImageCropper } from 'components/image-cropper';
import { API_URL } from 'constants/endpoints';
import { useTextDirection } from 'hooks/useTextDirection';
import * as React from 'react';
import { useRef, useState } from 'react';
import { Crop } from 'react-image-crop';
import { FormattedMessage } from 'react-intl';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { getMessageImageSizeNotAcceptable, isImageSizeAcceptable } from 'util/image';
import { ProfileHeaderProps as Props } from './types';

const dataUrlToFile = async (dataUrl: string, fileName: string, fileType: string) => {
  const blob = await fetch(dataUrl).then((r) => r.blob());
  const file = new File([blob], fileName, { type: fileType });
  return file;
};

const ProfileHeader: React.FC<Props> = ({ src = '', user, primaryColor = '#00bbd5', onAvatarUpload }) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [img, setImg] = useState<string | ArrayBuffer | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [imageMessage, setImageMessage] = useState<string>('');
  const isImageHandlingEnabled = useFeatureFlag(FlagKeys.IMAGE_HANDLING);
  const { isLTR } = useTextDirection();

  const avatar = src || user?.avatar;
  const avatarUrl = avatar ? `${API_URL}/${avatar}` : '';

  const triggerUpload = () => {
    if (onAvatarUpload && fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  const closeEvent = () => {
    setImg(null);
  };

  const handleAvatarUpload = async (croppedDataUrlImage: string) => {
    if (!onAvatarUpload || !uploadedFile || !uploadedFile.name || !uploadedFile.type) {
      setImageMessage('');
      return;
    }

    const avatarFile = await dataUrlToFile(croppedDataUrlImage, uploadedFile.name, uploadedFile.type);

    onAvatarUpload(avatarFile);
    closeEvent();
  };

  const transformAndUploadImage = (file: File) => {
    const reader = new FileReader();
    const image = new Image();

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const { width, height } = image;

      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d');

      if (context) {
        context.drawImage(image, 0, 0, width, height);
      }

      // set the image for the Image Cropper the transformed one
      setImg(canvas.toDataURL('image/jpeg'));
    };

    reader.onloadend = () => {
      image.src = reader.result as string;

      // clean the selected image form FileList, when it's done
      if (fileUploadRef && fileUploadRef.current) {
        fileUploadRef.current.value = '';
      }
    };

    file && reader.readAsDataURL(file);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!(event.target.files instanceof FileList)) {
      return;
    }

    const imageFile = event.target.files.item(0);
    if (!imageFile) {
      return;
    }

    if (isImageHandlingEnabled && !isImageSizeAcceptable(imageFile)) {
      const message = getMessageImageSizeNotAcceptable(imageFile);
      // TODO: replace this for a notification component with translations.
      setImageMessage(message);
      return;
    }

    transformAndUploadImage(imageFile);
    setUploadedFile(imageFile);
  };

  const initialCrop: Crop = {
    unit: 'px',
    width: 100,
    height: 100,
    x: 0,
    y: 0,
  };

  return (
    <div>
      <div className='flex flex-row'>
        <div className='flex-initial h-full'>
          <Avatar
            src={avatarUrl}
            editable={!!onAvatarUpload}
            borderColor={primaryColor}
            onClick={triggerUpload}
            cameraIcon
          />
          <input
            className='hidden'
            type='file'
            accept='image/*'
            onChange={handleImageUpload}
            ref={fileUploadRef}
          />

          {img && (
            <ImageCropper
              aspect={1}
              initialCrop={initialCrop}
              imageSrc={img}
              onCloseClick={closeEvent}
              onUploadClick={handleAvatarUpload}
            />
          )}
        </div>

        {user && (
          <div>
            <div
              className={classnames('flex-1 border-gray-200', {
                'pl-2.5 ml-2.5 border-l-2': isLTR,
                'pr-2.5 mr-2.5 border-r-2': !isLTR,
              })}
            >
              <div className='text-lg text-warmGrey'>
                {user.firstName} {user.lastName}
              </div>
              <div className='text-xs font-light text-warmGrey'>{user.jobPosition}</div>
              <div
                className='mt-1 text-xs font-bold text-left'
                style={{ color: primaryColor }}
              >
                <FormattedMessage
                  id='profile.xpoints'
                  defaultMessage='{points} XP'
                  values={{
                    points: user.xp ?? 0,
                  }}
                />
              </div>
            </div>
            {imageMessage && (
              <div className='p-3 mt-3 text-sm text-gray-500 border-2 border-red-400 rounded-lg'>{imageMessage}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileHeader;
