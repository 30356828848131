import classNames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { animated, config, useTransition } from 'react-spring';
import theme from 'theme';
import { CollectionProps } from './Collection.types';

export const Collection: React.FC<CollectionProps> = ({
  collection,
  selected = false,
  className,
  style,
  ...restProps
}) => {
  const testId = `collection-${collection.id}-img${selected ? '-selected' : ''}`;
  const styles = {
    ...style,
    backgroundImage: `url(${collection.imgSrc})`,
    flexBasis: '30%',
  };

  const selectedTransitions = useTransition(selected, {
    unique: true,
    from: { width: '64px', height: '64px' },
    enter: { width: '32px', height: '32px' },
    leave: { width: '100%', height: '100%' },
    config: config.wobbly,
  });

  return (
    <animated.div
      data-testid={testId}
      className={classNames(
        className,
        'flex-grow flex-shrink-0 bg-cover bg-center bg-no-repeat border-white border-solid border-2 rounded-lg box-border',
        'hover:border-turquoiseBlue hover:cursor-pointer',
        { 'border-turquoiseBlue flex items-center justify-center': selected },
      )}
      style={styles}
      {...restProps}
    >
      {selectedTransitions(
        (transitionStyles, item) =>
          item && (
            <animated.div
              className='flex items-center justify-center rounded-full bg-turquoiseBlue'
              style={transitionStyles}
            >
              <SvgIcon
                name='check'
                size={14}
                color={theme.colors.white}
              />
            </animated.div>
          ),
      )}
    </animated.div>
  );
};
