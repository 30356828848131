import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import { LepaTypes } from 'pages/home/Home.types';
import { SearchResult } from 'pages/search/search-result';
import React from 'react';
import { SearchResultsProps as Props } from '../SearchResult.types';

export const LepaResult: React.FC<Props> = ({ result, context, onRedirect }) => {
  const handleRedirectClick = () => {
    if (result.locked || !result.learningPathType) {
      return;
    }
    const redirectPath = {
      [LepaTypes.CRASH_COURSE]: `/crash-course/${result.id}`,
      [LepaTypes.LEGACY]: `/learning-path/${result.id}`,
      [LepaTypes.VIDEO]: `/video/${result.id}`,
      [LepaTypes.TEXT]: `/learning-moment/${result.id}`,
      [LepaTypes.QUESTIONS_ONLY]: `/learning-moment/${result.id}`,
      [LepaTypes.SINGLE_LEARNING]: `/learning-moment/${result.id}`,
    }[result.learningPathType];
    onRedirect(redirectPath);
  };

  return (
    <>
      <div
        className={classnames('flex items-center cursor-pointer', {
          'opacity-50 cursor-not-allowed': result.locked,
        })}
        onClick={handleRedirectClick}
      >
        <p className='m-0 text-base select-none text-lightGrey'>
          <b>{result.title}</b>
        </p>
        {result.locked && (
          <SvgIcon
            name='lock'
            color='#979797'
            size={20}
            className='ml-2'
          />
        )}
      </div>

      {result.children.map((level) => (
        <SearchResult
          key={`level-${level.id}`}
          result={level}
          context={context}
          onRedirect={onRedirect}
        />
      ))}
    </>
  );
};
