import { BackButton } from 'components/back-button';
import Feedback from 'components/feedback';
import { actions as formatActions } from 'containers/FormatScreen';
import FormatPlayground from 'containers/FormatScreen/FormatPlayground';
import { defaultLocale } from 'containers/language-provider';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Playground } from 'templates/playground';
import theme from 'theme';
import { doNothing } from 'util/doNothing';
import { LoformatsProps as Props } from './Loformats.types';
import { useExperiencePoints } from './hooks/use-experience-points';
import { showHeading, useLearningFormat } from './hooks/use-learning-format';
import { ExperiencePointsPopup } from './popups/experience-points-popup';
import { TestCompletePopup } from './popups/test-complete-popup';

type View = 'feedback' | 'test-complete-popup' | 'format';

export const Loformats: React.FC<Props> = ({
  learningFormats,
  title,
  id,
  color = theme.colors.turquoiseBlue,
  navigateBackRoute,
  testContext,
  isSubmittingAnswer,
  isCorrect,
  correctOptions,
  percentage,
  xp,
  passed,
  previousSubmittedFormatId,
  withBackNavigation = false,
  next,
  learningPathDone,
  onNavigateAway,
  onAnswer,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const locale = useSelector(({ profile }: RootState) => profile.user.locale || defaultLocale);

  const [formatIndex, setFormatIndex] = useState<number>(0);

  const [showView, setShowView] = useState<View>('format');
  const currentLearningFormat = learningFormats[formatIndex];

  const isAnswerSubmitted =
    Boolean(correctOptions) &&
    !isSubmittingAnswer &&
    previousSubmittedFormatId === parseInt(currentLearningFormat.id, 10);

  const {
    handleChangeAnswer: onChangeAnswer,
    handleChangeAnswers: onChangeAnswers,
    handleChangeOrder: onChangeOrder,
    handleUpdateGroup: onUpdateGroup,
    learningFormatHasRequiredAnswers,
    submitAnswer,
    selectedAnswerId,
    selectedAnswersIds,
    leftGroup,
    rightGroup,
    lastAnswer,
    withFeedback,
  } = useLearningFormat({ format: currentLearningFormat, testContext, onAnswer, isAnswerSubmitted });

  const { awardInfo, showExperiencePointsPopup, getAwards } = useExperiencePoints({
    lastAnswer,
    isAnswerSubmitted,
    isCorrectAnswer: isCorrect ?? false,
    formatType: currentLearningFormat.formatType,
    skipAwards: !withFeedback,
  });

  const showFeedback = showView === 'feedback';
  const showTestCompletePopup = showView === 'test-complete-popup';
  const showFormat = showView === 'format';

  const canPlayNextFormat = (!withFeedback || showFeedback) && isAnswerSubmitted;
  const shouldShowFeedbackView = withFeedback && isAnswerSubmitted && !showFeedback;

  const playNextFormat = (nextView: View) => {
    setShowView(nextView);
    const nextFormatIndex = Math.min(formatIndex + 1, learningFormats.length - 1);
    setFormatIndex(nextFormatIndex);
  };

  const handleClickNextOnFeedback = () => {
    if (!canPlayNextFormat) {
      return;
    }

    const isLastFormat = Boolean(next) || learningPathDone || formatIndex === learningFormats.length - 1;
    playNextFormat(isLastFormat ? 'test-complete-popup' : 'format');
  };

  const handleClickNextOnFormat = async () => {
    if (shouldShowFeedbackView) {
      setShowView('feedback');
      return;
    }

    const answer = await submitAnswer();

    if (!answer) {
      return;
    }

    const { completion } = answer;

    if (withFeedback) {
      return;
    }

    const isLastFormat =
      Boolean(completion?.next) || completion?.learningPathDone || formatIndex === learningFormats.length - 1;

    playNextFormat(isLastFormat ? 'test-complete-popup' : 'format');
  };

  const handleClickNext = async () => {
    const handler = {
      'format': handleClickNextOnFormat,
      'feedback': handleClickNextOnFeedback,
      'test-complete-popup': doNothing,
    }[showView];
    await handler();
  };

  const handleTestCompletePopupClose = () => {
    dispatch(formatActions.closeFormatsPopup());
    onNavigateAway();
  };

  const breadcrumbs = {
    color,
    crumbs: [{ label: title.toUpperCase() }],
  };

  const hasRequiredAnswers = learningFormatHasRequiredAnswers();
  const isButtonDisabled = (!hasRequiredAnswers && !showFeedback) || isSubmittingAnswer;
  const isPreviousButtonDisabled = isButtonDisabled || formatIndex === 0;

  const shouldRenderExperiencePoints =
    showExperiencePointsPopup &&
    isAnswerSubmitted &&
    showFormat &&
    lastAnswer?.learningFormatId === parseInt(currentLearningFormat.id, 10);
  const awards = getAwards();
  const shouldRenderHeading = showHeading(currentLearningFormat);
  const { positiveTitle, positiveText, negativeTitle, negativeText, partialTitle, partialText, generalFeedbackText } =
    currentLearningFormat;

  const correctAnswers = correctOptions?.map((answer) => String(answer));

  return (
    <>
      <BackButton to={navigateBackRoute} />

      <Playground
        breadcrumbs={breadcrumbs}
        heading={shouldRenderHeading && <p className='px-3 pt-0 pb-3'>{currentLearningFormat.title}</p>}
      >
        <div className='w-full'>
          {showFormat ? (
            <FormatPlayground
              key={`${currentLearningFormat.id}-${locale}`}
              format={currentLearningFormat}
              correctAnswers={correctAnswers ?? []}
              intl={intl}
              leftGroup={leftGroup}
              rightGroup={rightGroup}
              selectedAnswerID={selectedAnswerId}
              selectedAnswersIDs={selectedAnswersIds}
              brandColor={color}
              onAnswerChange={onChangeAnswer}
              onAnswersChange={onChangeAnswers}
              onOrderChange={onChangeOrder}
              onUpdateGroups={onUpdateGroup}
              submitted={isAnswerSubmitted}
            />
          ) : (
            <Feedback
              type={isCorrect ? 'positive' : 'negative'}
              options={{
                positiveTitle,
                positiveText,
                negativeTitle,
                negativeText,
                partialTitle,
                partialText,
                generalFeedbackText,
              }}
              titleColor={color}
            />
          )}
        </div>

        <Playground.Actions
          progress={{
            current: formatIndex + 1,
            steps: learningFormats.length,
            variant: 'circular',
            color,
          }}
          previous={
            withBackNavigation && {
              onClick: () => {
                setFormatIndex(formatIndex - 1);
              },
              disabled: isPreviousButtonDisabled,
              color,
              style: { borderColor: isPreviousButtonDisabled ? 'initial' : color },
            }
          }
          next={{
            onClick: handleClickNext,
            disabled: isButtonDisabled,
            color,
            style: { borderColor: isButtonDisabled ? 'initial' : color },
          }}
        />

        <ExperiencePointsPopup
          showExperiencePoints={shouldRenderExperiencePoints}
          experiencePoints={awardInfo.points}
          text={intl.formatMessage({ id: awardInfo.translationId })}
          textColor={awardInfo.xpColor}
          fillColor={awardInfo.fill}
        />
      </Playground>

      {showTestCompletePopup && (
        <TestCompletePopup
          testName={title}
          isOpen
          brandColor={color}
          percentage={percentage ?? 0}
          xp={xp ?? 0}
          radialProgressInfo={{ id, unlocked: true, passed: passed ?? false }}
          awards={awards}
          onClose={handleTestCompletePopupClose}
        />
      )}
    </>
  );
};
