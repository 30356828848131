import { skipToken } from '@reduxjs/toolkit/dist/query';
import classnames from 'classnames';
import { SpinnerBalls } from 'components/Loading';
import { PageContainer } from 'components/page-container';
import { ProfileStore } from 'containers/Profile';
import { shouldShowWalkthrough, useWalkthrough, Walkthrough } from 'containers/walkthrough';
import debounce from 'lodash/debounce';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { Store } from 'store/types';
import { isOdd } from 'util/isOdd';
import { useNewsfeed } from './controller';
import { NewsItem } from './news-item';

export const Newsfeed: React.FC = () => {
  const {
    onPostClick,
    posts,
    onRefreshFeed,
    hasMore,
    isFetching,
    isRefreshing,
    feedContainerRef,
    trackScroll,
    fetchMoreData,
  } = useNewsfeed();
  const { onWalkthroughEnd, completedWalkthroughs, locale } = useWalkthrough();
  const { id: accountId } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);
  const isCommunityEnabled = useFeatureFlag(FlagKeys.COMMUNITY_CHAT) && dynamicConfiguration?.menu.community.show;

  const renderNoNewsMessage = () => (
    <p className='text-base text-center text-greyishBrown'>
      <FormattedMessage
        id='comp.newsfeed.noContent'
        defaultMessage='We don`t have any news yet.'
      />
    </p>
  );

  const noNews = posts.length <= 0 && !isFetching;
  const showWalkthrough = shouldShowWalkthrough(completedWalkthroughs, 'newsfeed');

  const handleCompleteWalkthrough = () => onWalkthroughEnd('newsfeed');

  return (
    <>
      {!isCommunityEnabled && (
        <>
          <PageContainer className='px-4 mt-4 md:mt-12'>
            <h2 className='p-0 m-0 text-lg text-turquoiseBlue'>
              <FormattedMessage id='comp.newsfeed.title' />
            </h2>
          </PageContainer>

          <div className='px-4 pt-1 border-b border-pinkishGrey' />
        </>
      )}
      {showWalkthrough && (
        <Walkthrough
          variant='newsfeed'
          open
          targets={['#mailOutline-bottomBarIcon', 'body']}
          lang={locale}
          onSkip={handleCompleteWalkthrough}
          onEnd={handleCompleteWalkthrough}
        />
      )}

      <ul
        className={classnames('relative w-full px-4 m-auto mt-0 overflow-y-auto list-none max-h-9/10', {
          'py-5': !isCommunityEnabled,
        })}
        id='scrollableTarget'
        ref={feedContainerRef}
        onScroll={debounce(trackScroll, 100)}
      >
        <InfiniteScroll
          className='flex flex-col justify-center align-center'
          dataLength={posts.length}
          loader={<SpinnerBalls className='self-center block mx-auto my-5' />}
          hasMore={hasMore}
          next={fetchMoreData}
          scrollableTarget='scrollableTarget'
          refreshFunction={onRefreshFeed}
          pullDownToRefresh
          pullDownToRefreshThreshold={200}
          pullDownToRefreshContent={
            <h3 className='text-base text-center text-greyishBrown'>
              &#8595; <FormattedMessage id='comp.newsfeed.pullDownToRefresh' /> &#8595;
            </h3>
          }
          releaseToRefreshContent={
            <h3 className='text-base text-center text-greyishBrown'>
              &#8593; <FormattedMessage id='comp.newsfeed.releaseToRefresh' /> &#8593;
            </h3>
          }
        >
          {isRefreshing && <SpinnerBalls className='block mx-auto my-5' />}

          <PageContainer className='h-full p-2 overflow-y-auto'>
            {posts?.map((post, idx) => (
              <NewsItem
                key={`newsPreview${post.id}`}
                post={post}
                onClick={onPostClick(post.id)}
                style={{
                  backgroundColor: isOdd(idx) ? '#deebff' : 'white',
                }}
              />
            ))}
          </PageContainer>

          {noNews && renderNoNewsMessage()}
        </InfiniteScroll>
      </ul>
    </>
  );
};
