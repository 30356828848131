import { BackButtonIframe } from 'components/BackButtonIframe';
import { Loading } from 'components/Loading';
import { Frame } from 'components/frame';
import { defaultLocale } from 'containers/language-provider/LanguageProvider.helpers';
import { Locale } from 'containers/language-provider/LanguageProvider.types';
import { useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { Store } from 'store/types';
import { Playground } from 'templates/playground';
import { useGetLaunchUrlQuery } from './Scorm.api';

interface URLParams {
  scormId: string;
}

export const Scorm: React.FC = () => {
  const { scormId } = useParams<URLParams>();
  const locale = useSelector<Store, Locale>(({ profile }) => profile.user.locale || defaultLocale);
  const getScormPayload = {
    scormId,
    locale,
  };
  const {
    data: scormUrlResponse,
    isFetching,
    error,
  } = useGetLaunchUrlQuery(getScormPayload, { refetchOnMountOrArgChange: true });

  const launchUrl = scormUrlResponse?.launchUrl;

  if (isFetching || !scormId) {
    return <Loading />;
  }

  if (error) {
    return <Redirect to={'/invalid-url'} />;
  }

  return (
    <Playground
      className='max-w-none'
      heading={<BackButtonIframe to='/' />}
    >
      <Frame
        name='scorm-course'
        title='scorm-course'
        data-testid='scorm-course-frame'
        src={launchUrl}
      />
    </Playground>
  );
};
