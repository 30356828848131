import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import { TextfieldProps as Props } from 'components/Textfield/types';
import { useTextDirection } from 'hooks/useTextDirection';
import React from 'react';
import theme from 'theme';

const createOnChange =
  (fn: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void) =>
  (event: React.ChangeEvent<HTMLInputElement>) =>
    fn(event.target.value, event);

const Textfield: React.FC<Props> = ({
  value,
  error = false,
  withBorder = false,
  icon,
  onChange,
  fullWidth = false,
  typeStyle,
  className,
  inputClassName,
  disabled,
  disableInputPadding = false,
  ...props
}) => {
  const { isLTR } = useTextDirection();

  return (
    <div
      className={classnames(className, 'relative', {
        'w-full': fullWidth,
      })}
      data-testid='e2e-input'
    >
      <input
        className={classnames(
          'text-base bg-white rounded-lg outline-none placeholder-pinkishGrey md:text-sm',
          { 'p-3.5': !disableInputPadding },
          { 'text-gray-300 border-gray-300': disabled },
          { 'border-2 border-fullRed': error },
          { 'w-full text-warmGrey shadow-none border-2 placeholder-warmGrey': withBorder },
          { 'border-pinkishGrey': withBorder && !error },
          { 'w-full': fullWidth },
          { 'w-full text-center': typeStyle === 'login' },
          inputClassName,
        )}
        value={value}
        onChange={createOnChange(onChange)}
        {...props}
      />
      {icon && (
        <div
          data-testid='textfield-icon'
          className={classnames('absolute bottom-2/10', {
            'right-3': isLTR,
            'left-3': !isLTR,
          })}
        >
          <SvgIcon
            name={icon}
            color={theme.colors.pinkishGrey}
            fill={theme.colors.pinkishGrey}
          />
        </div>
      )}
    </div>
  );
};

export default Textfield;
