import { ImageMap } from 'components/image-map';
import Question from 'components/Question';
import { shouldShowWalkthrough, useWalkthrough, Walkthrough } from 'containers/walkthrough';
import React from 'react';
import { isHTML } from 'util/isHTML';
import { HotspotProps as Props } from './Hotspot.types';

export const Hotspot: React.FC<Props> = ({
  question,
  answers,
  selected,
  showAnswers,
  correct,
  focusColor,
  imgSrc,
  onChange,
}) => {
  const { onWalkthroughEnd, completedWalkthroughs, locale } = useWalkthrough();
  const showWalkthrough = shouldShowWalkthrough(completedWalkthroughs, 'hotspot');
  const isRichText = isHTML(question);
  const handleCompleteWalkthrough = () => onWalkthroughEnd('hotspot');

  return (
    <div className='w-full'>
      {showWalkthrough && (
        <Walkthrough
          open
          variant='hotspot'
          targets={['body', '#hotspot-image-map']}
          lang={locale}
          onSkip={handleCompleteWalkthrough}
          onEnd={handleCompleteWalkthrough}
        />
      )}

      <Question
        text={question}
        rich={isRichText}
        scaleWithContent
      />

      <ImageMap
        id='hotspot-image-map'
        imgSrc={imgSrc}
        spots={answers}
        selected={selected}
        showAnswers={showAnswers}
        correct={correct}
        focusColor={focusColor}
        onSpotChange={onChange}
      />
    </div>
  );
};
