import calendarImg from 'assets/images/calendar.svg';
import { Popup } from 'components/popup';
import { EventStore, EventPopupsComponentProps as Props, actions } from 'pages/event';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'store/types';

export const CancelRegistrationPopup: React.FC<Props> = ({ onModalClose, open }) => {
  const intl = useIntl();
  const { event } = useSelector<Store, EventStore>((state) => state.activeEvent);
  const dispatch = useDispatch();

  if (!event) {
    return null;
  }

  const bookedSession = event.content.sessions.find((session) => Boolean(session.userRegistration?.id));

  if (!bookedSession?.userRegistration?.id) {
    return null;
  }

  const handleCancelRegistration = () => {
    dispatch(actions.cancelRegistration(bookedSession.userRegistration.id));
    onModalClose();
  };

  return (
    <Popup
      icon={calendarImg}
      variant='faded'
      open={open}
      title={intl.formatMessage({ id: 'event.cancel-registration-popup.title' })}
      buttonText={intl.formatMessage({ id: 'event.cancel-registration-popup.button' })}
      onPrimaryClick={handleCancelRegistration}
      onRequestClose={onModalClose}
      closeIcon
    >
      <div className='p-4 text-sm font-light text-center whitespace-pre-wrap'>
        <h2>
          <FormattedMessage id='event.cancel-registration-popup.subtitle' />
        </h2>
      </div>
    </Popup>
  );
};
