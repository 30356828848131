import defaultLogo from 'assets/images/default_client_logo.png';
import classnames from 'classnames';
import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import Textfield from 'components/Textfield';
import { OnTextfieldChange } from 'components/Textfield/types';
import { actions, AuthStore } from 'containers/authentication';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Store } from 'store/types';
import { ResetPasswordProps as Props } from './ResetPassword.types';

export const ResetPassword = injectIntl(({ intl }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const reset = useSelector<Store, AuthStore['reset']>(({ auth }) => auth.reset);
  const { token: resetPasswordToken } = useParams<{ token: string }>();

  const [inputs, setInputs] = React.useState({
    password: '',
    confirmPassword: '',
  });

  const { password, confirmPassword } = inputs;

  const redirectTo = (to: string) => () => history.push(to);

  const handleInputChange: OnTextfieldChange = (value, { target }) => {
    const { name } = target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleResetPasswordClick = () => {
    const passwordsMatch = password === confirmPassword;
    if (!resetPasswordToken || !passwordsMatch || password.length < 1) {
      return;
    }

    dispatch(actions.resetPassword({ token: resetPasswordToken, password }));
  };

  const renderTitle = () => {
    const hasErrorMessage = error && message;

    if (success) {
      return <FormattedMessage id='container.resetPassword.title.congrats' />;
    }
    if (hasErrorMessage) {
      return <p>{message}</p>;
    }

    return <FormattedMessage id='container.resetPassword.title.reset' />;
  };

  const renderMessageFeedback = () => {
    if (success) {
      return <FormattedMessage id='container.resetPassword.message.success' />;
    }

    const hasInvalidTokenError = error && invalidToken;
    if (hasInvalidTokenError) {
      return <FormattedMessage id='container.resetPassword.message.ask' />;
    }
    if (error) {
      return <FormattedMessage id='container.resetPassword.message.retry' />;
    }

    return <FormattedMessage id='container.resetPassword.message.normal' />;
  };

  const { error, success, message } = reset;

  const invalidToken = message === 'Token was not found';
  const shouldRenderBackToLogin = success || invalidToken;
  const showInputs = !success && !invalidToken;

  return (
    <div className='flex w-full h-full'>
      <PageContainer className='flex flex-col self-center w-full p-3 overflow-x-hidden'>
        <div className='flex flex-col items-center justify-around flex-grow w-full'>
          <img
            className='h-56 w-80'
            alt=''
            src={defaultLogo}
          />

          <div
            className={classnames(
              'flex m-2.5 mr-0 justify-center items-center text-turquoiseBlue text-2xl font-bold mt-12',
              { 'text-fullRed': error },
              { ' mb-12': showInputs },
            )}
          >
            {renderTitle()}
          </div>

          <div className={classnames('mt-5 text-center text-sm font-normal mb-4', { 'text-turquoiseBlue': success })}>
            {renderMessageFeedback()}
          </div>
        </div>

        {showInputs && (
          <div className='flex-grow w-full'>
            <Textfield
              className='mb-2.5'
              placeholder={intl.formatMessage({ id: 'container.resetPassword.passwordPlaceholder' })}
              value={password}
              type='password'
              name='password'
              onChange={handleInputChange}
              typeStyle='login'
              withBorder
              error={error}
            />
            <Textfield
              className='mb-2.5'
              placeholder={intl.formatMessage({ id: 'container.resetPassword.confirmPasswordPlaceholder' })}
              value={confirmPassword}
              type='password'
              name='confirmPassword'
              onChange={handleInputChange}
              typeStyle='login'
              withBorder
              error={error}
            />
            <Button
              text={<FormattedMessage id='container.resetPassword.button' />}
              variant='contained'
              fullWidth
              onClick={handleResetPasswordClick}
            />
          </div>
        )}

        {shouldRenderBackToLogin && (
          <div className='mt-6 text-center'>
            <span
              className='text-sm font-normal underline cursor-pointer text-warmGrey'
              onClick={redirectTo('/login')}
            >
              <FormattedMessage id='container.resetPassword.link' />
            </span>
          </div>
        )}
      </PageContainer>
    </div>
  );
});
