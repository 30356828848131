import classnames from 'classnames';
import { StepProgressionBar } from 'components/ProgressionBar';
import Question from 'components/Question';
import { SwipeArrow } from 'components/swipe-arrow';
import { ImageFormat, VideoFormat } from 'containers/FormatScreen/Formats';
import { useMainScroll } from 'containers/app/root/MainScroll';
import { Walkthrough, shouldShowWalkthrough, useWalkthrough } from 'containers/walkthrough';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import theme from 'theme';
import { doNothing } from 'util/doNothing';
import { isHTML } from 'util/isHTML';
import { HeaderFeedbackMessage } from './HeaderFeedbackMessage';
import SwipeColumn from './SwipeColumn';
import SwipeableCard from './SwipeableCard';
import { SwipeColumns, SwipeFormatProps } from './types';
import { useSwipe } from './useSwipe';

export const SwipeFormat: React.FC<SwipeFormatProps> = ({
  correctAnswers,
  leftColProps,
  leftGroupOptions,
  initialOptions,
  question,
  rightColProps,
  rightGroupOptions,
  showAnswers,
  image,
  video,
  brandColor,
  onUpdateGroups,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { onWalkthroughEnd, completedWalkthroughs, locale } = useWalkthrough();
  const { animationStyles, options, handeSwipeStart, handleSwitchColumn, swipeLeft, swipeRight } = useSwipe(
    leftGroupOptions,
    rightGroupOptions,
    initialOptions,
    containerRef,
    onUpdateGroups,
  );

  const handleValidateOption = (optionID: string) => correctAnswers.includes(optionID);
  const isComplete = options.length === 0;

  const mainScrollRef = useMainScroll();
  useEffect(() => {
    if (!isComplete) {
      return;
    }
    mainScrollRef?.current?.scrollTo(0, 0);
  }, [isComplete, mainScrollRef]);

  const renderSelectionStage = () => {
    const [firstOption, secondOption] = options;
    const answeredOptions = leftGroupOptions.length + rightGroupOptions.length;
    const hasPictureToDisplay = !!firstOption?.imageUrl;

    const arrowsColor = brandColor || theme.colors.turquoiseBlue;

    return (
      <>
        <div
          className={classnames('relative', {
            'mb-5 3xs:h-64 2xs:h-80 h-[30rem]': hasPictureToDisplay,
            'mb-5 h-56': !hasPictureToDisplay,
          })}
        >
          <div className={'flex h-full'}>
            <SwipeColumn
              className='relative flex-1 border-r border-dashed border-warmGrey'
              columnData={leftColProps}
              brandColor={brandColor}
            />
            <SwipeColumn
              className='relative flex-1'
              columnData={rightColProps}
              brandColor={brandColor}
            />
          </div>

          <div>
            <SwipeArrow
              direction={'left'}
              color={arrowsColor}
              withBorder
              onClick={swipeLeft}
              className={'left-1/10 -translate-y-4/10 cursor-pointer'}
            />

            <SwipeArrow
              direction={'right'}
              color={arrowsColor}
              withBorder
              onClick={swipeRight}
              className={'right-1/10 -translate-y-4/10 cursor-pointer'}
            />

            {firstOption && (
              <SwipeableCard
                key={`swipeable-card-${firstOption.id}`}
                className='z-10'
                data={firstOption}
                style={animationStyles}
                onSwipeStart={handeSwipeStart}
              />
            )}
            {secondOption && (
              <SwipeableCard
                key={`swipeable-card-${secondOption.id}`}
                className='z-0'
                data={secondOption}
                onSwipeStart={doNothing}
              />
            )}
          </div>
        </div>

        <div className='flex flex-col items-center justify-center my-8'>
          <span className='text-sm mt-2.5 font-bold text-gray-500'>
            <FormattedMessage id='comp.SwipeFormat.swipeHint' />
          </span>
          <StepProgressionBar
            className='transform scale-125'
            current={answeredOptions}
            steps={initialOptions.length}
            variant='circular'
            color={brandColor}
          />
        </div>
      </>
    );
  };

  const renderResultsStage = () => (
    <>
      <HeaderFeedbackMessage
        showAnswers={showAnswers}
        message={<FormattedMessage id='comp.SwipeFormat.finalCheck.body' />}
      />

      <div className='flex h-auto'>
        <SwipeColumn
          className='relative flex-1 border-r border-dashed border-warmGrey'
          columnData={leftColProps}
          options={leftGroupOptions}
          onValidateOption={handleValidateOption}
          onOptionSwitchColumn={handleSwitchColumn(SwipeColumns.LEFT)}
          showAnswers={showAnswers}
          brandColor={brandColor}
        />
        <SwipeColumn
          className='relative flex-1'
          columnData={rightColProps}
          options={rightGroupOptions}
          onValidateOption={handleValidateOption}
          onOptionSwitchColumn={handleSwitchColumn(SwipeColumns.RIGHT)}
          showAnswers={showAnswers}
          brandColor={brandColor}
        />
      </div>
    </>
  );

  if (!Array.isArray(options)) {
    return null;
  }

  const showWalkthrough = shouldShowWalkthrough(completedWalkthroughs, 'swipe');
  const showQuestion = question && !isComplete;

  const handleCompleteWalkthrough = () => onWalkthroughEnd('swipe');

  return (
    <div
      className='w-full h-full overflow-hidden select-none bg-gradient-to-t from-gray-100 to-white '
      ref={containerRef}
    >
      {showWalkthrough && (
        <Walkthrough
          variant='swipe'
          open
          targets={['body', '#swipeable-cards', 'body']}
          lang={locale}
          onSkip={handleCompleteWalkthrough}
          onEnd={handleCompleteWalkthrough}
        />
      )}

      <div>
        {video && (
          <div className='my-4'>
            <VideoFormat url={video} />
          </div>
        )}
        {!video && image && (
          <div className='my-4'>
            <ImageFormat imgSrc={image} />
          </div>
        )}

        {showQuestion && (
          <Question
            className='text-sm pt-2.5 pr-3.5 pb-5 pl-3.5'
            text={question}
            rich={isHTML(question)}
            noStyles
          />
        )}
      </div>

      <div
        className='flex flex-col h-auto'
        dir='ltr'
      >
        {isComplete ? renderResultsStage() : renderSelectionStage()}
      </div>
    </div>
  );
};
