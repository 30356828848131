import { Button } from 'components/button';
import React from 'react';
import { CalendarProps as Props } from '../Event.types';
import { useCalendar } from './useCalendar';

export const Calendar: React.FC<Props> = ({ event }) => {
  const { calendarProviders } = useCalendar();
  const calendars = Object.values(calendarProviders);

  return (
    <>
      {calendars.map((calendar) => (
        <Button
          variant='contained'
          className='flex flex-col justify-center w-16 h-12 p-0 mx-2 bg-neutral-200'
          withoutPadding
        >
          <a
            href={calendar.getURL(event)}
            download={event.title}
            target='_blank'
            rel='noopener noreferrer'
            className='flex justify-center w-16 h-12'
            title={calendar.displayName}
          >
            <img
              alt={calendar.displayName}
              {...calendar.icon}
            />
          </a>
        </Button>
      ))}
    </>
  );
};
