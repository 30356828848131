import { LoginCredentials } from 'containers/authentication';
import ReCAPTCHA from 'react-google-recaptcha';
import { URLSearchParams } from 'util/useURLSearchParams';

interface AuthenticationFlowLoginActionProps extends LoginCredentials {
  accountID?: string;
  params?: URLSearchParams;
}

interface GetRequestUrlParams {
  token?: string;
}

export interface AuthenticationFlow {
  type: Authentications;
  isInternal: boolean;
  isAutomaticLogin: boolean;
  loginAction: (props: AuthenticationFlowLoginActionProps) => void;
  getRequestBody: (credentials: LoginCredentials, params?: URLSearchParams) => Record<string, unknown>;
  getRequestUrl: (params: GetRequestUrlParams) => string;
  canLogin: (credentials: LoginCredentials, params?: URLSearchParams) => boolean;
}

export interface UseAuthenticationProps {
  reCaptchaRef: React.RefObject<ReCAPTCHA>;
}

export enum Authentications {
  TEST = 'test',
  OAUTH2 = 'oauth2',
  SSO = 'sso',
  MANIFEST = 'manifest',
}
