import CautionSvg from 'assets/images/caution.svg';
import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import { SvgIcon } from 'components/svg-icon';
import { useTrackEventMutation } from 'containers/Analytics/Analytics.api';
import { EventTypes } from 'containers/Analytics/internal/InternalAnalytics.types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'store/types';
import theme from 'theme';
import { removeFiltersFromStorage } from 'util/useFiltersQueryParams';

export const NoContent: React.FC = () => {
  const history = useHistory();
  const {
    account: { id: accountId },
    user: { role },
  } = useSelector(({ profile }: Store) => profile);

  const [trackEvent] = useTrackEventMutation();

  const handleClickInfoIcon = () => history.push('/help');

  const handleClearFields = async () => {
    history.replace({ search: '' });
    removeFiltersFromStorage();

    await trackEvent({
      eventType: EventTypes.CLEAR_LECO_FILTERS,
      accountId,
      properties: {
        role,
      },
    });
  };

  return (
    <PageContainer
      center
      className='w-full h-full max-w-full bg-black'
    >
      <SvgIcon
        name='help-circle'
        data-testid='home-informationIcon'
        className='absolute z-50 cursor-pointer left-4 top-4 lg:invisible'
        size={33}
        color={theme.colors.warmGrey}
        onClick={handleClickInfoIcon}
      />
      <img
        className='mb-10'
        src={CautionSvg}
        alt='caution sign'
      />
      <div className='max-w-lg w-72 lg:w-1/2'>
        <div className='mb-10 text-center text-white lg:px-10'>
          <h3 className='m-0 mb-1 font-bold'>
            <FormattedMessage id='home.no-content.title' />
          </h3>
          <p className='m-0 font-normal'>
            <FormattedMessage id='home.no-content.description' />
          </p>
        </div>
        <Button
          onClick={handleClearFields}
          className='w-full text-white bg-greyishBrown'
        >
          <FormattedMessage id='home.no-content.button' />
        </Button>
      </div>
    </PageContainer>
  );
};
