import { Location as HistoryLocation } from 'history';

const IGNORE_PARAMS_STARTING_WITH = ['mtm_', 'utm_'];

const getFilteredSearch = (search: string) =>
  IGNORE_PARAMS_STARTING_WITH.reduce(
    (acc, param) => acc.replace(new RegExp(`${param}([^&]*)&?`, 'g'), ''),
    search,
  ).replace(/\?$/, '');

export const preparePreviousLocation = (location: HistoryLocation | Location): string => {
  const search = getFilteredSearch(location.search);
  const url = `${location.pathname}${search}`;
  return url;
};
