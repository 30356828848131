import CautionSvg from 'assets/images/caution.svg';
import { FormattedMessage } from 'react-intl';

export const NoChannels: React.FC = () => (
  <div className='flex flex-col items-center justify-center h-full p-4 text-center'>
    <img
      className='mb-6'
      src={CautionSvg}
      alt='caution sign'
      height={80}
      width={80}
    />
    <h1 className='mb-2 text-lg font-bold text-neutral-800'>
      <FormattedMessage
        id='community.chat.no-chats.header'
        defaultMessage='You have no open chats!'
      />
    </h1>
    <p className='text-base font-light text-gray-500'>
      <FormattedMessage
        id='community.chat.no-chats.description'
        defaultMessage='Start chatting now'
      />
    </p>
  </div>
);
