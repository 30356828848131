import { Grimacing } from './Grimacing';
import { HeartEyes } from './HeartEyes';
import { PartyingFace } from './PartyingFace';
import { Smile } from './Smile';
import { Sob } from './Sob';
import { StarStruck } from './StarStruck';
import { Sweat } from './Sweat';
import { Triumph } from './Triumph';

export const positiveEmojis = [<HeartEyes />, <PartyingFace />, <Smile />, <StarStruck />];
export const negativeEmojis = [<Grimacing />, <Sob />, <Sweat />, <Triumph />];
