import Textfield from 'components/Textfield';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { injectIntl } from 'react-intl';
import theme from 'theme';
import './SearchBar.css';
import { SearchBarProps } from './SearchBar.types';

export const SearchBarComponent: React.FC<SearchBarProps> = ({
  intl,
  placeholder = intl.formatMessage({ id: 'search.placeholder' }),
  value,
  onChange,
  onSubmit,
}) => {
  const handleClickSearch = (event: React.MouseEvent) => onSubmit(value, event);

  const handlePressEnter = (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return null;
    }
    return onSubmit(value, event);
  };

  return (
    <div
      className='flex items-center w-full border-[1px] border-solid border-pinkishGrey rounded-full'
      onKeyPress={handlePressEnter}
      data-testid='e2e-search-bar'
    >
      <div className='flex-1 py-0 px-3.5'>
        <Textfield
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          data-testid='search-field'
          fullWidth
          disableInputPadding
          inputClassName='bg-transparent p-0 rounded-none search-bar-input-placeholder'
        />
      </div>
      <div
        className='flex items-center justify-center rounded-full cursor-pointer h-11 w-11 bg-pinkishGrey'
        onClick={handleClickSearch}
      >
        <SvgIcon
          name='search'
          size={24}
          color={theme.colors.white}
        />
      </div>
    </div>
  );
};

export const SearchBar = injectIntl(SearchBarComponent);
