import classnames from 'classnames';
import React from 'react';
import { filterXSS } from 'util/filterXSS';
import classes from './RichTypography.module.scss';
import { RichTypographyProps as Props } from './RichTypography.types';

export const RichTypography: React.FC<Props> = ({ children = '', className, ...rest }) => (
  <div
    className={classnames(classes.text, className, 'whitespace-normal')}
    dangerouslySetInnerHTML={{ __html: filterXSS(children) }}
    {...rest}
  />
);
