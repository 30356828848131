import MultipleAnswerSwipe from 'containers/FormatScreen/Formats/MultipleAnswer/alternatives/multiple-answer-swipe';
import { useWalkthrough } from 'containers/walkthrough';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { Store } from 'store/types';
import { useMainScroll } from '../app/root/MainScroll';
import { Locale, defaultLocale } from '../language-provider';
import {
  MultipleAnswer,
  MultipleChoice,
  MultipleImageAnswer,
  MultipleImageChoice,
  ReorderFormat,
  SwipeFormat,
  TextFormat,
  TrueOrFalse,
  VideoFormat,
  YesOrNo,
} from './Formats';
import { MultipleChoiceDragDrop } from './Formats/MultipleChoice/alternatives/MultipleChoiceDragDrop';
import { Hotspot } from './Formats/hotspot';
import { FormatRendererProps as Props } from './types';

const FormatPlayground: React.FC<Props> = ({
  format,
  correctAnswers,
  selectedAnswerID,
  selectedAnswersIDs,
  submitted,
  intl,
  rightGroup,
  leftGroup,
  brandColor,
  onAnswerChange,
  onAnswersChange,
  onOrderChange,
  onUpdateGroups,
}) => {
  const locale = useSelector<Store, Locale>(({ profile }) => profile.user.locale || defaultLocale);
  const { onWalkthroughEnd, completedWalkthroughs } = useWalkthrough();
  const showAlternativeFormats = useFeatureFlag(FlagKeys.ALTERNATIVE_FORMATS);

  const mainScrollRef = useMainScroll();
  useEffect(() => {
    if (!format.id) {
      return;
    }
    mainScrollRef?.current?.scrollTo(0, 0);
  }, [mainScrollRef, format.id]);

  switch (format.formatType) {
    case 'video':
      return (
        <VideoFormat
          url={format.content.url}
          summary={format.summary}
        />
      );
    case 'yes_no': {
      return (
        <YesOrNo
          question={format.content.question}
          imgSrc={format.content.imageUrl}
          video={format.content.url}
          answers={format.content.options}
          selected={String(selectedAnswerID)}
          showAnswers={submitted}
          correct={correctAnswers}
          onChange={onAnswerChange}
          focusColor={brandColor}
          IntlFormatFn={intl.formatMessage}
        />
      );
    }
    case 'true_false': {
      const question = intl.formatMessage({ id: 'formats.trueorfalse.title' });
      return (
        <TrueOrFalse
          question={question}
          imgSrc={format.content.imageUrl}
          video={format.content.url}
          answers={format.content.options}
          selected={String(selectedAnswerID)}
          showAnswers={submitted}
          correct={correctAnswers}
          onChange={onAnswerChange}
          focusColor={brandColor}
        />
      );
    }
    case 'multiple_choice_drag_drop':
      return showAlternativeFormats ? (
        <MultipleChoiceDragDrop
          question={format.content.question}
          imgSrc={format.content.imageUrl}
          video={format.content.url}
          answers={format.content.options}
          selected={String(selectedAnswerID)}
          showAnswers={submitted}
          correct={correctAnswers}
          onChange={onAnswerChange}
          focusColor={brandColor}
        />
      ) : (
        <MultipleChoice
          question={format.content.question}
          imgSrc={format.content.imageUrl}
          video={format.content.url}
          answers={format.content.options}
          selected={String(selectedAnswerID)}
          showAnswers={submitted}
          correct={correctAnswers}
          onChange={onAnswerChange}
          focusColor={brandColor}
        />
      );
    case 'multiple_choice':
      return (
        <MultipleChoice
          question={format.content.question}
          imgSrc={format.content.imageUrl}
          video={format.content.url}
          answers={format.content.options}
          selected={String(selectedAnswerID)}
          showAnswers={submitted}
          correct={correctAnswers}
          onChange={onAnswerChange}
          focusColor={brandColor}
        />
      );
    case 'multiple_image_choice':
      return (
        <MultipleImageChoice
          question={format.content.question}
          imgSrc={format.content.imageUrl}
          video={format.content.url}
          answers={format.content.options}
          selected={selectedAnswerID ? String(selectedAnswerID) : ''}
          showAnswers={submitted}
          correct={correctAnswers as any[]}
          onChange={onAnswerChange}
          focusColor={brandColor}
        />
      );
    case 'multiple_image_answer':
      return (
        <MultipleImageAnswer
          question={format.content.question}
          imgSrc={format.content.imageUrl}
          video={format.content.url}
          answers={format.content.options}
          selected={selectedAnswersIDs}
          showAnswers={submitted}
          correct={correctAnswers as any[]}
          onChange={onAnswersChange}
          focusColor={brandColor}
        />
      );
    case 'multiple_answer_swipe':
      return showAlternativeFormats ? (
        <MultipleAnswerSwipe
          question={format.content.question}
          imgSrc={format.content.imageUrl}
          video={format.content.url}
          initialOptions={format.content.options}
          selectedAnswers={selectedAnswersIDs}
          showAnswers={submitted}
          correctAnswers={correctAnswers as any[]}
          onOptionSelect={onAnswersChange}
          focusColor={brandColor}
        />
      ) : (
        <MultipleAnswer
          question={format.content.question}
          imgSrc={format.content.imageUrl}
          video={format.content.url}
          answers={format.content.options}
          selected={selectedAnswersIDs}
          showAnswers={submitted}
          correct={correctAnswers}
          onChange={onAnswersChange}
          focusColor={brandColor}
        />
      );
    case 'multiple_answer':
      return (
        <MultipleAnswer
          question={format.content.question}
          imgSrc={format.content.imageUrl}
          video={format.content.url}
          answers={format.content.options}
          selected={selectedAnswersIDs}
          showAnswers={submitted}
          correct={correctAnswers}
          onChange={onAnswersChange}
          focusColor={brandColor}
        />
      );
    case 'drag_drop': {
      const left = format.content.groups[0];
      const right = format.content.groups[1];
      const leftColProps = { title: left.name, imgSrc: left.imgSrc };
      const rightColProps = { title: right.name, imgSrc: right.imgSrc };

      return (
        <SwipeFormat
          question={format.content.question}
          initialOptions={format.content.options}
          showAnswers={submitted}
          leftGroupOptions={leftGroup}
          rightGroupOptions={rightGroup}
          onUpdateGroups={onUpdateGroups}
          leftColProps={leftColProps}
          rightColProps={rightColProps}
          correctAnswers={correctAnswers}
          video={format.content.url}
          image={format.content.imageUrl}
          brandColor={brandColor}
        />
      );
    }
    case 'ranking_quiz': {
      const handleCompleteWalkthrough = () => onWalkthroughEnd('reorder');
      return (
        <ReorderFormat
          question={format.content.question}
          answers={format.content.options}
          imgSrc={format.content.imageUrl}
          video={format.content.url}
          showAnswers={submitted}
          correct={correctAnswers}
          onChange={onOrderChange}
          focusColor={brandColor}
          completedWalkthroughs={completedWalkthroughs}
          locale={locale}
          onCompleteWalkthrough={handleCompleteWalkthrough}
        />
      );
    }
    case 'text':
      return (
        <TextFormat
          title={format.title}
          text={format.summary}
          src={format.content.imageUrl}
          video={format.content.url}
          titleColor={brandColor}
        />
      );
    case 'hotspot':
      return (
        <Hotspot
          question={format.content.question}
          answers={format.content.options}
          selected={selectedAnswersIDs}
          showAnswers={submitted}
          correct={correctAnswers}
          onChange={onAnswersChange}
          focusColor={brandColor}
          imgSrc={format.content.imageUrl}
        />
      );
    default:
      return null;
  }
};

export default FormatPlayground;
