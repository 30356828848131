import appleImg from 'assets/images/apple.svg';
import ellipseImg from 'assets/images/ellipse.svg';
import googleImg from 'assets/images/google.svg';
import outlookImg from 'assets/images/outlook.svg';
import { REST_API_URL } from 'constants/endpoints';
import { CalendarEvent, CalendarProvider, CalendarProviderHandler, CalendarProviderTypes } from '../Event.types';

const formatTime = (time: string) => time.replace(/[-:]|\.\d{3}/g, '');

const getICSCalendarURL = (event: CalendarEvent) => {
  const components = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:-//www.mobietrain.com//MobieTrain',
    'BEGIN:VEVENT',
    `DTSTAMP:${event.createdAt || new Date().toISOString()}`,
    `UID:${REST_API_URL}/learning-contents/${event.id}`,
    `URL:${document.URL}`,
    `DTSTART:${formatTime(event.startAt)}`,
    `DTEND:${formatTime(event.endAt)}`,
    `SUMMARY:${event.title}`,
    `DESCRIPTION:${event.description}`,
    `LOCATION:${event.location || ''}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ];
  return encodeURI(`data:text/calendar;charset=utf8,${components.join('\n')}`);
};

const getGoogleCalendarURL = (event: CalendarEvent) => {
  const params = new URLSearchParams({
    action: 'TEMPLATE',
    dates: `${formatTime(event.startAt)}/${formatTime(event.endAt)}`,
    location: event.location || '',
    text: event.title,
    details: event.description,
  });

  return `https://calendar.google.com/calendar/render?${params.toString()}`;
};

const getOutlookCalendarURL = (event: CalendarEvent) => {
  const params = new URLSearchParams({
    rru: 'addevent',
    startdt: event.startAt,
    enddt: event.endAt,
    subject: event.title,
    location: event.location || '',
    body: event.description,
    allday: 'false',
    uid: new Date().getTime().toString(),
    path: '/calendar/view/Month',
  });

  return `https://outlook.live.com/owa?${params.toString()}`;
};

const appleProvider: CalendarProvider = {
  displayName: 'Apple',
  icon: {
    src: appleImg,
    width: '21px',
    height: '25px',
  },
  getURL: (event: CalendarEvent) => getICSCalendarURL(event),
};

const icsFileProvider: CalendarProvider = {
  displayName: 'Download calendar',
  icon: {
    src: ellipseImg,
    width: '21px',
    height: '6px',
  },
  getURL: (event: CalendarEvent) => getICSCalendarURL(event),
};

const googleProvider: CalendarProvider = {
  displayName: 'Google',
  icon: {
    src: googleImg,
    width: '25px',
    height: '25px',
  },
  getURL: (event: CalendarEvent) => getGoogleCalendarURL(event),
};

const outlookProvider: CalendarProvider = {
  displayName: 'Outlook',
  icon: {
    src: outlookImg,
    width: '28px',
    height: '25px',
  },
  getURL: (event: CalendarEvent) => getOutlookCalendarURL(event),
};

export const useCalendar = () => {
  const calendarProviders: CalendarProviderHandler = {
    [CalendarProviderTypes.GOOGLE]: googleProvider,
    [CalendarProviderTypes.OUTLOOK]: outlookProvider,
    [CalendarProviderTypes.APPLE]: appleProvider,
    [CalendarProviderTypes.ICS_FILE]: icsFileProvider,
  };

  return { calendarProviders };
};
