import { PayloadAction } from '@reduxjs/toolkit';
import { REST_API_URL } from 'constants/endpoints';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import getDefaultHeaders from 'util/getDefaultHeaders';
import request, { RequestResult } from 'util/request';
import { actions } from './LTI.ducks';
import { LTIProviders, SignedGoodHabitzCourse } from './LTI.types';

function* getGoodHabitzSignedRequest({ payload }: PayloadAction<string>): Generator<StrictEffect, void> {
  const requestUrl = `${REST_API_URL}/lti/v1p1/goodhabitz/generate-request-settings`;
  const headers = (yield call(getDefaultHeaders)) as HeadersInit;
  const body = JSON.stringify({
    url: payload,
  });

  const response = (yield call(request, requestUrl, {
    method: 'POST',
    headers,
    body,
  })) as RequestResult<SignedGoodHabitzCourse>;

  if (!response.err) {
    yield put(
      actions.getCourseSignedRequestSuccess({
        ...response.data,
        provider: LTIProviders.GOODHABITZ,
      }),
    );
  } else {
    const { err } = response;
    const error = err instanceof Error ? err : new Error(String(err));
    yield put(actions.getCourseSignedRequestError(error));
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.getCourseSignedRequest.type, getGoodHabitzSignedRequest);
}
