import classNames from 'classnames';
import { Loading } from 'components/Loading';
import { InstallInstructionPopup } from 'components/install-instruction-popup';
import { InstallInstructionReminderPopup } from 'components/install-instruction-reminder-popup';
import { NotificationsBadge, SvgIcon } from 'components/svg-icon';
import { useTrackEventMutation } from 'containers/Analytics/Analytics.api';
import { EventTypes } from 'containers/Analytics/internal/InternalAnalytics.types';
import { actions as authActions } from 'containers/authentication';
import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { shouldShowWalkthrough, useWalkthrough } from 'containers/walkthrough';
import moment from 'moment';
import { actions as homeActions } from 'pages/home';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { usePendingRequests } from 'store/pending-requests';
import { Store } from 'store/types';
import theme from 'theme';
import tinycolor from 'tinycolor2';
import { useGetCategoriesQuery } from './Category.api';
import Content from './Content';
import { actions } from './Home.ducks';
import { HomeStore } from './Home.types';
import { MobieChatPopup, useMobieWelcomeMessageQuery } from './popups/mobie-chat';
import { useHomeFilters } from './useHomeFilters';

export const Home: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { enqueueModal } = useModalQueue();
  const currentModal = useSelector(({ modals }: RootState) => modals.currentModal);
  const showInstallInstructionReminder = useSelector(({ home }: RootState) => home.showInstallInstructionReminder);
  const { count: amountOfAppliedFilters, queryParams } = useHomeFilters();
  const [isMobieOpen, setIsMobieOpen] = React.useState(false);
  const { completedWalkthroughs, onWalkthroughEnd } = useWalkthrough();

  const [trackEvent] = useTrackEventMutation();

  const helpIconRef = useRef<HTMLDivElement | null>(null);

  const { unlockedPaths } = useSelector<Store, HomeStore>(({ home }) => home);
  const {
    user: { id: userId, locale: userLocale, role, eulaAcceptedAt },
    account: { id: accountId, pathColor },
  } = useSelector(({ profile }: Store) => profile);

  const accountPathColor = pathColor || theme.colors.orangeRed;

  const isAccountPathColorDark = tinycolor(accountPathColor).isDark();
  const mobieIconColor = isAccountPathColorDark ? theme.colors.white : accountPathColor;

  const hasPendingRequests = usePendingRequests([
    authActions.getUserInfo.type,
    actions.getUserLecos.type,
    actions.getFeaturedPath.type,
  ]);

  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery({ accountId });
  const isAiChatbotPluginEnabled = Boolean(dynamicConfiguration?.plugins.chatbot);

  const isCategoriesTypesFiltersEnabled = useFeatureFlag(FlagKeys.SKILLFLIX_FILTERS_CATEGORIES_TYPES);
  const showUnlockLepaDialogs = useFeatureFlag(FlagKeys.UNLOCK_LEPA_DIALOGS);
  const isAiChatbotFeatureFlagEnabled = useFeatureFlag(FlagKeys.AI_CHATBOT);
  const isInstallInstructionsFeatureFlagEnabled = useFeatureFlag(FlagKeys.INSTALL_INSTRUCTIONS);

  const isAiChatbotFeatureFlagAndPluginEnabled = isAiChatbotFeatureFlagEnabled && isAiChatbotPluginEnabled;
  const isDisplayingInstallInstructionsNextPopups =
    currentModal?.type === ModalTypes.INSTALL_INSTRUCTION_STEPS || showInstallInstructionReminder;
  const currentDate = new Date();
  const sixMonthsAgo = new Date(currentDate);
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  const sixMonthsAgoUnix = moment(sixMonthsAgo).unix();
  const eulaAcceptedAtUnix = moment(eulaAcceptedAt).unix();
  const isNewUser = eulaAcceptedAtUnix >= sixMonthsAgoUnix;
  const showInstallInstructionsPopup =
    shouldShowWalkthrough(completedWalkthroughs, 'install-app') &&
    isInstallInstructionsFeatureFlagEnabled &&
    isNewUser &&
    !isDisplayingInstallInstructionsNextPopups;

  const {
    data: mobieWelcomeMessageData,
    isLoading: isMobieWelcomeMessageLoading,
    isFetching: isMobieWelcomeMessageFetching,
  } = useMobieWelcomeMessageQuery(
    { accountId, locale: userLocale || 'en-GB' },
    { skip: !isAiChatbotFeatureFlagAndPluginEnabled },
  );
  const hasWelcomeMessage =
    Boolean(mobieWelcomeMessageData) && !isMobieWelcomeMessageLoading && !isMobieWelcomeMessageFetching;

  const shouldShowAiChatbot = isAiChatbotFeatureFlagAndPluginEnabled && hasWelcomeMessage;

  // ? We need to pass the locale to refetch the list if it changes
  useGetCategoriesQuery({ userId, accountId, locale: userLocale }, { skip: !isCategoriesTypesFiltersEnabled });

  const handleInfoIconClick = () => history.push('/help');

  const handleClickFilterIcon = () => {
    enqueueModal({ type: ModalTypes.CATEGORY_FILTER });
  };

  const handleInstallInstructionReminderPopupOnClose = () =>
    dispatch(homeActions.setShowInstallInstructionReminder({ showInstallInstructionReminder: false }));

  React.useEffect(() => {
    const hasUnlockedPaths = unlockedPaths.length > 0;
    if (!hasUnlockedPaths) {
      return;
    }

    if (showUnlockLepaDialogs) {
      enqueueModal({ type: ModalTypes.PATH_UNLOCKED });
    }
    dispatch(actions.cleanUnlockedPaths());
    dispatch(actions.setUnlockedPathsToAnimate(unlockedPaths));
  }, [dispatch, enqueueModal, showUnlockLepaDialogs, unlockedPaths]);

  React.useEffect(() => {
    if (!userId) {
      return;
    }

    dispatch(
      actions.getUserLecos({
        userId,
        queryParams,
      }),
    );
    dispatch(actions.getFeaturedPath());
    dispatch(actions.getCategories());
  }, [dispatch, userId, queryParams]);

  const themeStyles: React.CSSProperties = {
    backgroundColor: theme.home.bg,
  };

  const handleClickMobie = async () => {
    if (!mobieWelcomeMessageData) {
      return;
    }

    await trackEvent({
      accountId,
      eventType: EventTypes.OPEN_CHATBOT,
      properties: {
        role,
      },
    });

    setIsMobieOpen((previousValue) => !previousValue);
  };

  const handleMobieClose = () => {
    setIsMobieOpen(false);
  };

  if (hasPendingRequests) {
    return <Loading />;
  }

  const isFiltered = isCategoriesTypesFiltersEnabled && Boolean(amountOfAppliedFilters);

  return (
    <div
      className='relative flex flex-col w-full h-full overflow-y-auto'
      style={themeStyles}
    >
      <div
        className='absolute z-50 cursor-pointer left-4 top-4 lg:invisible'
        ref={helpIconRef}
      >
        <SvgIcon
          name='help-circle'
          data-testid='home-informationIcon'
          size={33}
          color={theme.colors.warmGrey}
          onClick={handleInfoIconClick}
        />
        <span className='sr-only'>
          <FormattedMessage id='comp.help-icon' />
        </span>
      </div>
      {isCategoriesTypesFiltersEnabled && (
        <div
          className={classNames('absolute cursor-pointer right-4 top-4 lg:invisible', {
            'z-40': showInstallInstructionReminder,
            'z-50': !showInstallInstructionReminder,
          })}
        >
          <SvgIcon
            name='filter'
            data-testid='home-informationIcon'
            size={26}
            viewBox='0 0 25 16'
            color={theme.colors.warmGrey}
            onClick={handleClickFilterIcon}
            aria-label={intl.formatMessage({ id: 'comp.help-filters-counter' }, { amount: amountOfAppliedFilters })}
            badge={
              <NotificationsBadge
                open
                amount={amountOfAppliedFilters}
                cy='3'
              />
            }
          />
          <span className='sr-only'>
            <FormattedMessage id='comp.help-filters' />
          </span>
        </div>
      )}
      <Content isFiltered={isFiltered} />

      {shouldShowAiChatbot && (
        <div
          className={classNames('fixed right-0 z-10 cursor-pointer bottom-24 lg:bottom-6 lg:right-12', {
            'lg:z-150': isMobieOpen,
          })}
          onClick={handleClickMobie}
        >
          <div
            className={classNames(
              'flex items-center content-center justify-center w-16 lg:hover:scale-110 border-t-2 border-l-2 border-b-2 h-14 lg:h-16 rounded-l-2xl lg:rounded-full lg:border-r-2 drop-shadow-lg lg:transition-transform lg:transform lg:ease-in-out',
              {
                'bg-gray-900': !isAccountPathColorDark,
                'lg:rotate-90 lg:h-14 lg:w-14': isMobieOpen,
                'lg:rotate-0': !isMobieOpen,
              },
            )}
            style={{
              ...(isAccountPathColorDark && { background: accountPathColor, borderColor: theme.colors.white }),
              ...(!isAccountPathColorDark && { borderColor: accountPathColor }),
            }}
          >
            <div className={classNames('flex', { 'lg:hidden': isMobieOpen })}>
              <SvgIcon
                name='mobie'
                color={mobieIconColor}
                size={32}
                viewBox='0 0 32 32'
              />
            </div>

            <div className={classNames('hidden', { 'lg:flex': isMobieOpen })}>
              <SvgIcon
                name='close'
                color={mobieIconColor}
                size={36}
              />
            </div>
          </div>
        </div>
      )}
      <InstallInstructionPopup
        open={showInstallInstructionsPopup}
        onUpdateUserInstallInstruction={onWalkthroughEnd}
        showHelpPageReminder={!completedWalkthroughs['install-app']}
      />
      <InstallInstructionReminderPopup
        open={showInstallInstructionReminder}
        onModalClose={handleInstallInstructionReminderPopupOnClose}
        target={helpIconRef}
        placement='bottom-right'
        className='lg:hidden'
      />
      {mobieWelcomeMessageData && (
        <MobieChatPopup
          open={isMobieOpen}
          welcomeMessage={mobieWelcomeMessageData.welcomeMessage}
          onModalClose={handleMobieClose}
        />
      )}
    </div>
  );
};
