import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'store/types';
import isNodeEnv from 'util/isNodeEnv';
import { actions } from './ModalController.ducks';
import ModalRenderer from './ModalRenderer';

/**
 * ModalsController is responsible for control the modals queue,
 * keeping track the current modal opened and to implement specific logic
 */
export const ModalsController: React.FC = () => {
  const dispatch = useDispatch();

  const state = useSelector(({ modals }: Store) => modals);
  const { queue, currentModal, previousModal } = state;
  const open = Boolean(currentModal);

  const showNextModal = useCallback(() => {
    dispatch(actions.showNextModal());
  }, [dispatch]);

  const handleCloseModal = () => {
    showNextModal();
  };

  React.useEffect(() => {
    if (!currentModal) {
      return;
    }

    dispatch(actions.setPreviousModal(currentModal));
  }, [currentModal, dispatch]);

  useEffect(() => {
    if (queue.length === 0) {
      return;
    }

    if (!currentModal) {
      dispatch(actions.showNextModal());
    }
  }, [currentModal, dispatch, queue]);

  if (currentModal && !currentModal.type) {
    if (isNodeEnv('development')) {
      console.warn('a modal was enqueued but had no TYPE');
    }

    // we need to remove this modal from the queue so it doesnt block the next ones
    handleCloseModal();
  }

  const modal = currentModal || previousModal;

  return (
    <ModalRenderer
      modal={modal}
      open={open}
      onModalClose={handleCloseModal}
    />
  );
};
