import { Button } from 'components/button';
import { actions as profileActions } from 'containers/Profile';
import { ProfileStore } from 'containers/Profile/types';
import { actions } from 'containers/authentication';
import { Locale } from 'containers/language-provider';
import LocaleSelectPopup from 'containers/language-provider/LocaleSelectPopup';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'store/types';
import theme from 'theme';
import { PreviewBottomNavigationProps as Props } from '../types';

export const PreviewBottomNavigation: React.FC<Props> = ({ locale }) => {
  const dispatch = useDispatch();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const userId = useSelector<Store, ProfileStore['user']['id']>(({ profile }) => profile.user.id);

  const handleLocaleChange = (newLocale: Locale) => {
    dispatch(profileActions.updateUser({ userId, locale: newLocale }));
  };

  const handleLanguageChange = (newLocale: Locale) => {
    handleLocaleChange(newLocale);
  };

  const handleLogout = () => dispatch(actions.logout());

  const handlePopupOpen = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  return (
    <div className='flex w-full justify-around items-center border-solid border-t-2 z-10 order-2 px-1 py-3.5 bg-white text-warmGrey border-whiteFour'>
      <Button
        focus
        variant='outlined'
        onClick={handleLogout}
        text='Exit Preview mode'
      />
      <Button
        variant='outlined'
        iconName='world'
        iconColor={theme.colors.warmGrey}
        onClick={handlePopupOpen}
        text={locale}
        className='w-24'
      />

      <LocaleSelectPopup
        isOpen={isPopupOpen}
        selectedLocale={locale}
        onChange={handleLanguageChange}
        onClose={handlePopupClose}
      />
    </div>
  );
};
