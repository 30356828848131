import { createInstance } from '@datapunt/matomo-tracker-react';
import { MatomoCredentials } from 'constants/global';

const { domain, siteId } = MatomoCredentials;

/**
 * Matomo React provider instance
 */
export const matomoInstance = createInstance({
  urlBase: domain,
  siteId,
  disabled: false,
  linkTracking: false,
  // Important to disable on SPA: https://www.npmjs.com/package/@datapunt/matomo-tracker-react#spa-link-tracking
});
