import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL, REST_API_URL } from 'constants/endpoints';

export const rootRestApi = createApi({
  reducerPath: 'root-rest-api',
  baseQuery: fetchBaseQuery({ baseUrl: REST_API_URL }),
  endpoints: () => ({}),
});

export const rootApi = createApi({
  reducerPath: 'root-api',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: () => ({}),
});
