import classNames from 'classnames';
import { Media, MediaProps } from 'components/media';
import { MEDIA_COMPONENTS } from 'components/media/DeprecatedMedia';
import React from 'react';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';

export const Logo: React.FC<MediaProps> = ({ className, alt, ...props }) => {
  const isImageHandlingEnabled = useFeatureFlag(FlagKeys.IMAGE_HANDLING);
  const mediaSizeClass =
    isImageHandlingEnabled || MEDIA_COMPONENTS.includes(String(props.component)) ? '!object-contain' : '!bg-contain';

  return (
    <Media
      className={classNames(className, mediaSizeClass)}
      alt={alt}
      {...props}
    />
  );
};
