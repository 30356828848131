import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetAllBadgesSuccessPayload, GetUnlockedBadgesSuccessPayload } from 'components/Badge/types';
import { UploadImagePayload } from 'constants/global';
import {
  actions as authActions,
  GetUserInfoSuccessPayload,
  GetUserSuccessPayload,
  LoginUserSuccessPayload,
  User,
  ValidatePreviewTokenSuccessPayload,
} from 'containers/authentication';
import { defaultLocale } from 'containers/language-provider';
import { actions as registerActions } from 'pages/register/Register.ducks';
import {
  DEPRECATED_UpdateUserPayload,
  GetAllBadgesPayload,
  GetUnlockedBadgesPayload,
  GetUserProgressSuccessPayload,
  ProfileStore,
  UpdateUserPayload,
  UpdateUserSuccessPayload,
} from './index';

const initialState: ProfileStore = {
  user: {
    id: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    description: '',
    avatar: '',
    level: 0,
    completed: 0,
    toNextLevel: 0,
    role: '',
    jobPosition: '',
    progress: {
      completedModules: 0,
      modulesToNextLevel: 0,
      meLastMonth: 0,
      myStoreLastMonth: 0,
      meOverall: 0,
      myStoreOverall: 0,
      totalLastMonth: 0,
      totalOverall: 0,
    },
    xp: 0,
    isLocaleSelected: false,
    isDigestOptedIn: false,
  },
  ui: {
    error: false,
  },
  badges: {
    list: [],
    unlocked: [],
  },
  account: {
    id: '',
    logoUrl: '',
    logoDarkUrl: '',
    applicationName: '',
    splashScreen: '',
    themeBg: '',
    pathColor: '',
    primaryColor: '',
  },
  accounts: [],
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    avatarUpload: (state, _action: PayloadAction<UploadImagePayload>) => state,
    avatarUploadSuccess: (state, action: PayloadAction<string>) => ({
      ...state,
      user: {
        ...state.user,
        avatar: action.payload,
      },
    }),
    avatarUploadError: (state) => ({
      ...state,
      ui: { ...state.ui, error: true },
    }),
    getAllBadges: (state, _action: PayloadAction<GetAllBadgesPayload>) => state,
    getAllBadgesSuccess: (state, action: PayloadAction<GetAllBadgesSuccessPayload>) => ({
      ...state,
      badges: {
        ...state.badges,
        list: action.payload.badges.get,
      },
    }),
    getAllBadgesError: (state, _action: PayloadAction<Error>) => state,
    getUnlockedBadges: (state, _action: PayloadAction<GetUnlockedBadgesPayload>) => state,
    getUnlockedBadgesSuccess: (state, action: PayloadAction<GetUnlockedBadgesSuccessPayload>) => ({
      ...state,
      badges: {
        ...state.badges,
        unlocked: action.payload.badges.getUnlocked,
      },
    }),
    getUnlockedBadgesError: (state, _action: PayloadAction<Error>) => state,
    DEPRECATED_updateUser: (state, _action: PayloadAction<DEPRECATED_UpdateUserPayload>) => state,
    DEPRECATED_updateUserSuccess: (state, action: PayloadAction<UpdateUserSuccessPayload>) => {
      const user = action.payload.users.updateMe;
      return {
        ...state,
        user: {
          ...state.user,
          name: `${user.firstName} ${user.lastName}`,
          firstName: user.firstName,
          lastName: user.lastName,
          avatar: user.avatarUrl,
        },
      };
    },
    DEPRECATED_updateUserError: (state, _action: PayloadAction<Error>) => ({
      ...state,
      user: initialState.user,
      account: initialState.account,
    }),
    getUserProgress: (state) => state,
    getUserProgressSuccess: (state, action: PayloadAction<GetUserProgressSuccessPayload>) => {
      const { me } = action.payload.users;
      const progress = action.payload.progress.overview;

      return {
        ...state,
        user: {
          ...state.user,
          xp: me.xp,
          progress,
        },
      };
    },
    getUserProgressError: (state, _action: PayloadAction<Error>) => state,
    updateUser: (state, _action: PayloadAction<UpdateUserPayload>) => state,
    updateUserSuccess: (state, action: PayloadAction<User>) => {
      const { isLocaleSelected, persona, locale, isDigestOptedIn } = action.payload;

      const setIsLocaleSelected = isLocaleSelected ? { isLocaleSelected } : {};
      const setPersonaSelected = persona ? { persona } : {};
      const setLocaleSelected = locale ? { locale } : {};
      const setIsDigestOptedIn = { isDigestOptedIn };

      return {
        ...state,
        user: {
          ...state.user,
          ...setIsDigestOptedIn,
          ...setIsLocaleSelected,
          ...setPersonaSelected,
          ...setLocaleSelected,
        },
      };
    },
    updateUserError: (state, _action: PayloadAction<Error>) => ({
      ...state,
      user: initialState.user,
      account: initialState.account,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(authActions.getUserInfoSuccess.type, (state, action) => {
        const typedAction = action as PayloadAction<GetUserInfoSuccessPayload>;
        const { me } = typedAction.payload.users;
        const account = me.lastAccount || {};
        const progress = typedAction.payload.progress.overview;

        return {
          ...state,
          user: {
            ...state.user,
            id: me.id,
            name: `${me.firstName} ${me.lastName}`,
            firstName: me.firstName,
            lastName: me.lastName,
            email: me.email,
            phoneNumber: me.phoneNumber,
            avatar: me.avatarUrl,
            jobPosition: me.jobPosition,
            role: me.role.slug,
            progress,
            xp: me.xp,
            walkthrough: me.walkthrough,
            persona: me.persona,
            locale: me.locale,
          },
          account: {
            ...state.account,
            id: account.id,
            logoUrl: account.logoUrl,
            logoDarkUrl: account.logoDarkUrl,
            applicationName: account.applicationName,
            splashScreen: account.splashScreen,
            themeBg: account.theme || '',
            pathColor: account.pathColor || '',
            primaryColor: account.primaryColor || '',
          },
          accounts: me.accounts,
        };
      })
      .addCase(authActions.getUserSuccess.type, (state, action) => {
        const typedAction = action as PayloadAction<GetUserSuccessPayload>;
        const user = typedAction.payload;

        return {
          ...state,
          user: {
            ...state.user,
            isDigestOptedIn: user.isDigestOptedIn,
            persona: user.persona,
            isLocaleSelected: user.isLocaleSelected,
            eulaAcceptedAt: user.eulaAcceptedAt,
          },
        };
      })
      .addCase(registerActions.validateInviteSuccess, (state, action) => {
        const { validateInvite: userInvite } = action.payload.accounts;
        const { account } = userInvite;

        const locale = userInvite.locale || account.locale || defaultLocale;
        return {
          ...state,
          user: { ...state.user, locale },
        };
      })
      .addCase(authActions.loginSuccess.type, (state, action) => {
        const typedAction = action as PayloadAction<LoginUserSuccessPayload>;
        const { accountLogo } = typedAction.payload;
        return {
          ...state,
          account: { ...state.account, logoUrl: accountLogo },
        };
      })
      .addCase(authActions.validatePreviewTokenSuccess.type, (state, action) => {
        const typedAction = action as PayloadAction<ValidatePreviewTokenSuccessPayload>;
        const { role } = typedAction.payload;
        return {
          ...state,
          user: { ...state.user, role },
        };
      });
  },
});

export const { actions } = slice;
export default slice.reducer;
