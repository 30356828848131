import { animated, config, useSpring } from 'react-spring';

export const Sweat = () => {
  const styles = useSpring({
    loop: true,
    from: { transform: 'translateY(-15px) scaleY(0)' },
    to: { transform: 'translateY(0) scaleY(1)' },
    config: config.molasses,
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={96}
      height={101}
      fill='none'
      viewBox='0 0 96 101'
    >
      <circle
        cx={50.037}
        cy={54.746}
        r={45.328}
        fill='#FFD23B'
      />
      <path
        d='M50.037 100.074c25.034 0 45.328-20.294 45.328-45.328 0-25.034-20.294-45.328-45.328-45.328v90.656z'
        fill='#FFB24A'
      />
      <path
        d='M76.596 81.305c0-14.669-11.93-26.56-26.648-26.56-14.717 0-26.648 11.891-26.648 26.56h53.296z'
        fill='#962B7C'
      />
      <path
        d='M76.596 81.305c0-14.669-11.93-26.56-26.648-26.56v26.56h26.648z'
        fill='#C22C7B'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28.79 38.9a2.567 2.567 0 005.134 0v-5.4h5.49v5.4a8.056 8.056 0 11-16.114 0v-5.4h5.49v5.4z'
        fill='#2D3E53'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M65.973 38.9a2.567 2.567 0 105.135 0v-5.4h5.489v5.4a8.056 8.056 0 11-16.113 0v-5.4h5.49v5.4z'
        fill='#000'
      />
      <path
        d='M49.948 73.515c10.553 0 17.913-6.257 20.274-9.385-2.331-3.128-9.65-9.384-20.274-9.384-10.623 0-18.06 6.256-20.45 9.384 2.42 3.128 9.897 9.385 20.45 9.385z'
        fill='#FF3187'
      />
      <path
        d='M49.948 73.515c10.553 0 17.913-6.257 20.274-9.385-2.331-3.128-9.65-9.384-20.274-9.384v18.769z'
        fill='#FF5178'
      />
      <animated.g style={styles}>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.527 12.472L11.993 0v33.46C5.37 33.46 0 28.09 0 21.468c0-3.422 1.21-5.341 3.527-8.995zm16.932 0L11.993 0v33.46c6.624 0 11.994-5.37 11.994-11.993 0-3.422-1.21-5.341-3.528-8.995z'
          fill='#49B9F2'
        />
        <path
          d='M11.994.001L3.528 12.472C1.21 16.127 0 18.046 0 21.468c0 6.624 5.37 11.994 11.994 11.994V0z'
          fill='#66E6FF'
        />
      </animated.g>
    </svg>
  );
};
