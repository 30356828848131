import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetUserInfoSuccessPayload, actions as authActions } from 'containers/authentication';
import update from 'immutability-helper';
import { HelpStore } from 'pages/help';

const initialState: HelpStore = {
  faqUrl: {
    url: null,
    enabled: false,
  },
  supportUrl: {
    url: null,
    enabled: false,
  },
};

const slice = createSlice({
  name: 'help',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authActions.getUserInfoSuccess.type, (state, action) => {
      const typedAction = action as PayloadAction<GetUserInfoSuccessPayload>;
      const { supportUrl, faqUrl } = typedAction.payload.settings;
      return update(state, {
        supportUrl: {
          url: { $set: supportUrl.value },
          enabled: { $set: supportUrl.enabled },
        },
        faqUrl: {
          url: { $set: faqUrl.value },
          enabled: { $set: faqUrl.enabled },
        },
      });
    });
  },
});

export const { actions } = slice;
export default slice.reducer;
