import request from '../request';
import { MediaProvider, ProvidersDomain, ProvidersID, ThumbnailParserOutput } from './types';

const youtubeProvider: MediaProvider = {
  getVideoID(url: string) {
    const regExp = /(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i;
    const parseUrl = new RegExp(regExp).exec(url);
    return parseUrl ? parseUrl[1] : '';
  },
  getVideoThumbnail(videoID: string) {
    return `https://img.youtube.com/vi/${videoID}/hqdefault.jpg`;
  },
};

const vimeoProvider: MediaProvider = {
  getVideoID(url: string) {
    const regExp =
      /(vimeo(?:cdn|pro)?)\.com\/(?:(?:channels\/[\w]+|(?:(?:album\/\d+|groups\/[\w]+|staff\/frame)\/)?videos?)\/)?(\d+)(?:_(\d+)(?:x(\d+))?)?(\.\w+)?/i;
    const parseUrl = new RegExp(regExp).exec(url);
    return parseUrl ? parseUrl[2] : '';
  },
  getVideoThumbnail(videoID: string) {
    const url = `https://vimeo.com/api/v2/video/${videoID}.json`;

    return request(url)
      .then(({ data }: any) => {
        if (!Array.isArray(data) || !data) {
          throw new Error('No data in vimeo thumbnail request.');
        }

        const thumbnail = data[0].thumbnail_medium;
        return thumbnail;
      })
      .catch((err) => console.error(err));
  },
};

const dailyMotionProvider: MediaProvider = {
  getVideoID(url: string) {
    const regExp = /(?:\/video|ly)\/([A-Za-z0-9]+)/i;
    const parseUrl = new RegExp(regExp).exec(url);
    return parseUrl ? parseUrl[1] : '';
  },
  getVideoThumbnail(videoID: string) {
    return `https://www.dailymotion.com/thumbnail/video/${videoID}`;
  },
};

function isProviderIDSupported(providerID: string): providerID is ProvidersID {
  return Object.values(ProvidersID).includes(providerID as ProvidersID);
}

export const MediaProviders = {
  [ProvidersDomain.YOUTUBE]: youtubeProvider,
  [ProvidersDomain.VIMEO]: vimeoProvider,
  [ProvidersDomain.DAILYMOTION]: dailyMotionProvider,
};

/** Returns the provider ID from it's domain */
export const getProviderID = (provider: string): ProvidersID | null => {
  const isYoutube = provider === ProvidersDomain.YOUTUBE || provider === ProvidersDomain.YOUTU;
  if (isYoutube) {
    return ProvidersID.YOUTUBE;
  }

  if (!isProviderIDSupported(provider)) {
    return null;
  }

  return provider;
};

/** Parses the video URL and returns the video information like `id` and `provider` */
export const videoURLParser = (videoURL: string): ThumbnailParserOutput => {
  const regExp = /(?:(?:https?:)?\/\/)?(?:[^.]+\.)?(\w+)\./i;
  const match = new RegExp(regExp).exec(videoURL);
  const providerName = match ? match[1] : '';

  const providerID = getProviderID(providerName);

  if (!providerID) {
    return {
      provider: null,
      id: '',
    };
  }

  return {
    provider: providerID,
    id: MediaProviders[providerID].getVideoID(videoURL),
  };
};
