import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import { LepaTypes } from 'pages/home/Home.types';
import { SearchResult } from 'pages/search/search-result';
import React from 'react';
import theme from 'theme';
import { SearchResultsProps as Props } from '../SearchResult.types';

export const LemoResult: React.FC<Props> = ({ result, context, pathContext, levelContext, onRedirect }) => {
  const handleRedirectClick = () => {
    if (result?.locked || !pathContext?.id || !levelContext?.id || !context?.id || !pathContext.learningPathType) {
      return;
    }
    const redirectPath = {
      [LepaTypes.CRASH_COURSE]: `/crash-course/${pathContext.id}/module/${result.id}`,
      [LepaTypes.LEGACY]: `/learning-path/${pathContext.id}/level/${levelContext.id}/skill/${context.id}/module/${result.id}`,
      [LepaTypes.VIDEO]: '/',
      [LepaTypes.TEXT]: '/',
      [LepaTypes.QUESTIONS_ONLY]: '/',
      [LepaTypes.SINGLE_LEARNING]: '/',
    }[pathContext.learningPathType];
    onRedirect(redirectPath);
  };

  return (
    <>
      <div
        className={classnames('flex cursor-pointer items-center my-2 mx-0 justify-between', {
          'opacity-50 cursor-not-allowed': result?.locked,
        })}
        onClick={handleRedirectClick}
      >
        <p className='m-0 text-sm font-light select-none text-lightGrey'>{result.title}</p>
        <i>
          {result?.locked ? (
            <SvgIcon
              name='lock'
              color='#979797'
              size={20}
              className='mr-1'
            />
          ) : (
            <SvgIcon
              name='arrowForward'
              color={theme.colors.lightGrey}
              size={15}
              className='mr-1'
            />
          )}
        </i>
      </div>

      {result.children.map((format) => (
        <SearchResult
          key={`format-${format.id}`}
          result={format}
          context={context}
          levelContext={levelContext}
          pathContext={pathContext}
          onRedirect={onRedirect}
        />
      ))}
    </>
  );
};
