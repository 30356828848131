import { PayloadAction } from '@reduxjs/toolkit';
import { REST_API_URL } from 'constants/endpoints';
import { StrictEffect, call, put, takeLatest } from 'redux-saga/effects';
import getDefaultHeaders from 'util/getDefaultHeaders';
import request from 'util/request';
import { actions as eventActions } from './Event.ducks';
import { EventContent, GetEventPayload } from './Event.types';

function* getEventWorker({ payload }: PayloadAction<GetEventPayload>): Generator<StrictEffect, void> {
  const { lecoId, locale } = payload;
  try {
    const requestUrl = `${REST_API_URL}/learning-contents/${lecoId}?locale=${locale}`;
    const headers = (yield call(getDefaultHeaders)) as HeadersInit;

    const { data } = (yield call(request, requestUrl, {
      method: 'GET',
      headers,
    })) as { data: EventContent };

    yield put(eventActions.getEventSuccess({ event: data }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(eventActions.getEventError(error));
    } else {
      throw new Error('unknown error`');
    }
  }
}

function* createRegistrationWorker({ payload }: PayloadAction<number>): Generator<StrictEffect, void> {
  const sessionId = payload;

  try {
    const requestUrl = `${REST_API_URL}/sessions/${sessionId}/registrations`;
    const headers = (yield call(getDefaultHeaders)) as HeadersInit;
    const options: RequestInit = {
      method: 'POST',
      headers,
    };

    const response = (yield call(request, requestUrl, options)) as {
      data: {
        id: number;
      };
      err?: {
        message: string;
        status: number;
        statusText: string;
      };
    };

    response.err?.status === 409
      ? yield put(eventActions.createRegistrationNoSpotsAvailable({ id: sessionId }))
      : yield put(eventActions.createRegistrationSuccess({ id: sessionId }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(eventActions.createRegistrationError(error));
    } else {
      throw new Error('unknown error');
    }
  }
}

function* cancelRegistrationWorker({ payload }: PayloadAction<number>): Generator<StrictEffect, void> {
  const registrationId = payload;

  try {
    const requestUrl = `${REST_API_URL}/registrations/${registrationId}`;
    const headers = (yield call(getDefaultHeaders)) as HeadersInit;

    const response = (yield call(request, requestUrl, {
      method: 'DELETE',
      headers,
    })) as {
      data: null;
      err?: {
        message: string;
        status: number;
        statusText: string;
      };
    };

    switch (true) {
      case response.err?.message.startsWith('ERR-40107'):
        yield put(eventActions.cancelRegistrationUserIsAlreadyConfirmed({}));
        break;
      case response.err?.message.startsWith('ERR-40097'):
        yield put(eventActions.cancelRegistrationError(new Error(response.err?.message)));
        break;
      default:
        yield put(eventActions.cancelRegistrationSuccess({}));
        break;
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(eventActions.cancelRegistrationError(error));
    } else {
      throw new Error('unknown error');
    }
  }
}

export function* rootSaga() {
  yield takeLatest(eventActions.getEvent.type, getEventWorker);
  yield takeLatest(eventActions.createRegistration.type, createRegistrationWorker);
  yield takeLatest(eventActions.cancelRegistration.type, cancelRegistrationWorker);
}

export default rootSaga;
