import classNames from 'classnames';
import { FC } from 'react';
import { CheckboxProps } from './Checkbox.types';

export const Checkbox: FC<CheckboxProps> = ({ checked, className, size = 20, color = '#C3C3C3', ...rest }) => (
  <div
    className={classNames('cursor-pointer', className)}
    {...rest}
  >
    {checked ? (
      <svg
        width={size + size / 20}
        height={size}
        viewBox='0 0 21 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.00176 20C2.20564 20 1.44213 19.6839 0.879194 19.1213C0.316256 18.5587 0 17.7956 0 17L0 3C0 2.20435 0.316256 1.44129 0.879194 0.87868C1.44213 0.31607 2.20564 0 3.00176 0L14.0082 0C14.2736 0 14.5281 0.105357 14.7157 0.292893C14.9034 0.48043 15.0088 0.734784 15.0088 1C15.0088 1.26522 14.9034 1.51957 14.7157 1.70711C14.5281 1.89464 14.2736 2 14.0082 2H3.00176C2.73638 2 2.48188 2.10536 2.29424 2.29289C2.10659 2.48043 2.00117 2.73478 2.00117 3V17C2.00117 17.2652 2.10659 17.5196 2.29424 17.7071C2.48188 17.8946 2.73638 18 3.00176 18H17.01C17.2753 18 17.5298 17.8946 17.7175 17.7071C17.9051 17.5196 18.0105 17.2652 18.0105 17V10C18.0105 9.73478 18.116 9.48043 18.3036 9.29289C18.4912 9.10536 18.7458 9 19.0111 9C19.2765 9 19.531 9.10536 19.7186 9.29289C19.9063 9.48043 20.0117 9.73478 20.0117 10V17C20.0117 17.7956 19.6955 18.5587 19.1325 19.1213C18.5696 19.6839 17.8061 20 17.01 20H3.00176ZM9.29844 12.707L6.29668 9.707C6.11442 9.5184 6.01357 9.2658 6.01585 9.0036C6.01813 8.7414 6.12336 8.49059 6.30887 8.30518C6.49439 8.11977 6.74535 8.0146 7.0077 8.01233C7.27005 8.01005 7.5228 8.11084 7.71151 8.293L10.0059 10.586L19.3043 1.293C19.493 1.11084 19.7458 1.01005 20.0081 1.01233C20.2705 1.0146 20.5214 1.11977 20.7069 1.30518C20.8925 1.49059 20.9977 1.7414 21 2.0036C21.0022 2.2658 20.9014 2.5184 20.7191 2.707L10.7133 12.707C10.6203 12.8 10.51 12.8737 10.3885 12.9241C10.267 12.9744 10.1368 13.0003 10.0054 13.0003C9.87386 13.0003 9.74366 12.9744 9.62219 12.9241C9.50072 12.8737 9.39037 12.8 9.29744 12.707H9.29844Z'
          fill={color}
        />
      </svg>
    ) : (
      <svg
        width={size + size / 20}
        height={size}
        viewBox='0 0 21 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H10H17C17.7957 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7957 20 17V14V10V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0H14H3C2.95027 0 2.90067 0.00123465 2.85124 0.00368698C2.40635 0.0257576 1.9751 0.146457 1.58735 0.353411C1.32884 0.491385 1.08966 0.667696 0.878679 0.87868C0.632538 1.12482 0.433586 1.40933 0.287645 1.71815C0.100007 2.11521 0 2.55245 0 3V17C0 17.7957 0.316071 18.5587 0.87868 19.1213ZM14.0006 2C14.0004 2 14.0002 2 14 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.11708 2.46871 2.01349 2.70325 2.00123 2.95041C2.00041 2.96689 2 2.98342 2 3V17C2 17.2321 2.08066 17.4558 2.22647 17.6338C2.24729 17.6592 2.26945 17.6837 2.29289 17.7071C2.46871 17.8829 2.70325 17.9865 2.95041 17.9988C2.96689 17.9996 2.98342 18 3 18H10H17C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V14V10V3C18 2.73478 17.8946 2.48043 17.7071 2.29289C17.5196 2.10536 17.2652 2 17 2L14.0006 2Z'
          fill={color}
        />
      </svg>
    )}
  </div>
);
