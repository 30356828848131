import Textfield from 'components/Textfield';
import { PageContainer } from 'components/page-container';
import { TopBar } from 'components/top-bar';
import { API_URL } from 'constants/endpoints';
import { ProfileStore } from 'containers/Profile';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'store/types';
import { UserResponse } from 'stream-chat';
import { SelectedUsers } from '.';
import { useCreateChannelMutation } from '../../Chat.api';
import { NewChatViews } from '../NewChat.types';
import { NewGroupProps } from './NewGroup.types';

export const NewGroupDetails: FC<NewGroupProps> = ({ selectedUsers, onChangeView, onUpdateSelectedUsers }) => {
  const intl = useIntl();
  const history = useHistory();
  const user = useSelector<Store, ProfileStore['user']>(({ profile }) => profile.user);
  const { id: accountId, logoUrl } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const [name, setName] = useState('');

  const [createChannel] = useCreateChannelMutation();

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleDeselectUser = (userToDeselect: UserResponse) => {
    const nextSelectedUsers = selectedUsers.filter((currentUser) => currentUser.id !== userToDeselect.id);
    onUpdateSelectedUsers(nextSelectedUsers);
  };

  const handleBack = () => {
    onChangeView(NewChatViews.NEW_GROUP);
  };

  const handleNext = async () => {
    const memberIds = selectedUsers.map((currentUser) => currentUser.id);

    const channel = await createChannel({
      accountId,
      name,
      members: [user.id, ...memberIds],
      image: logoUrl ? `${API_URL}/${logoUrl}` : '',
      isGroup: true,
    }).unwrap();

    if (!channel.id) {
      return;
    }
    history.push(`/chat/${channel.id}`);
  };

  const isNextButtonDisabled = !name || selectedUsers.length === 0;

  return (
    <>
      <TopBar
        backButton={<TopBar.IconButton onClick={handleBack} />}
        nextButton={
          <TopBar.Button
            onClick={handleNext}
            disabled={isNextButtonDisabled}
          >
            <FormattedMessage
              id='top-bar.create'
              defaultMessage='Create'
            />
          </TopBar.Button>
        }
      >
        <h3 className='flex-1 p-4 text-lg font-medium text-center text-white uppercase'>
          {intl.formatMessage({
            id: 'community.chat.new.group',
            defaultMessage: 'New Group',
          })}
        </h3>
      </TopBar>

      <PageContainer>
        <Textfield
          className='mx-4 my-6 '
          inputClassName='font-light border bg-zinc-100'
          name='name'
          placeholder={intl.formatMessage({
            id: 'community.chat.new.name-group',
            defaultMessage: 'Name your group',
          })}
          value={name}
          withBorder
          required
          onChange={handleChangeName}
        />
        <SelectedUsers
          users={selectedUsers}
          onClose={handleDeselectUser}
        />
      </PageContainer>
    </>
  );
};
