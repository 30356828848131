import classNames from 'classnames';
import { SpotProps as Props } from './Spot.types';

export const Spot: React.FC<Props> = ({
  id,
  index,
  x,
  y,
  isSelected,
  onChange,
  isCorrect,
  showAnswers,
  focusColor,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    if (showAnswers) {
      return;
    }
    // TODO: Change index to ID when integrating with the API in MT-5006
    onChange(id, event);
  };

  const hasUserAnswered = isSelected && !showAnswers;
  const brandColor = hasUserAnswered ? focusColor : '';
  const numberColor = hasUserAnswered ? 'white' : brandColor;

  return (
    <span
      onClick={handleClick}
      data-testid={`e2e-spot-${index}`}
      id='image-map'
      className={classNames(
        'absolute z-40 flex items-center justify-center origin-top-left',
        'bg-white border-2 border-gray-600 border-solid rounded-full cursor-pointer bg-opacity-70',
        {
          'bg-opacity-100': isSelected,
          'bg-gray-600': hasUserAnswered,
          'bg-yellowGreen border-none text-white': isSelected && showAnswers && isCorrect,
          'bg-red-600 border-none text-white': isSelected && showAnswers && !isCorrect,
          'bg-white bg-opacity-100 border-dashed border-yellowGreen text-yellowGreen':
            !isSelected && showAnswers && isCorrect,
        },
      )}
      style={{
        top: y,
        left: x,
        borderColor: brandColor,
        backgroundColor: brandColor,
        color: numberColor,
        margin: 'clamp(-0.028125rem, -0.0719rem + 0.2187vw, 0.015625rem)',
        width: '2.5em',
        height: '2.5em',
        fontSize: '14px',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {index}
    </span>
  );
};
