import React from 'react';
import { LTIAuthenticationProps as Props } from './LTIAuthentication.types';

export const LTIAuthentication: React.FC<Props> = ({ url, targetName, authRequestCredentials }) => {
  const [isFormRequestSent, setFormRequestSubmited] = React.useState(false);

  const submitOnMount = React.useCallback(
    (form: HTMLFormElement) => {
      if (!form) {
        return;
      }

      form.submit();
      setFormRequestSubmited(true);
    },
    [setFormRequestSubmited],
  );

  return (
    <>
      {/** A form POST request with the auth keys must be done since
       * it returns an HTML page to directly embed in the iframe */}

      {!isFormRequestSent && (
        <form
          target={targetName}
          action={url}
          method='POST'
          ref={submitOnMount}
        >
          {Object.entries(authRequestCredentials).map(([key, value]) => (
            <input
              key={key}
              type='hidden'
              name={key}
              value={value}
            />
          ))}
        </form>
      )}
    </>
  );
};
