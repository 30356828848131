import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStates } from 'store/types';
import { AppSearchResult, SearchStore } from './Search.types';

const initialState: SearchStore = {
  results: [],
  status: RequestStates.IDLE,
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    getSearchResults: (state, _action: PayloadAction<string>) => ({
      ...state,
      results: [],
      status: RequestStates.LOADING,
    }),
    getSearchResultsSuccess: (state, action: PayloadAction<AppSearchResult[]>) => ({
      ...state,
      results: action.payload,
      status: RequestStates.SUCCESS,
    }),
    getSearchResultsError: (state, _action: PayloadAction<Error>) => ({
      ...state,
      status: RequestStates.FAILED,
    }),
  },
});

export const { actions } = slice;
export default slice.reducer;
