import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import theme from 'theme';
import { QueryProps } from './Query.types';

export const Query: React.FC<QueryProps> = ({ query, onClick }) => {
  const handleQueryClick = (event: React.MouseEvent<HTMLParagraphElement>) => onClick(query, event);

  return (
    <div className='flex mx-0 my-3'>
      <div className='mr-5'>
        <SvgIcon
          name='search'
          size={18}
          color={theme.colors.pinkishGrey}
        />
      </div>
      <p
        className='m-0 text-sm font-light underline cursor-pointer'
        onClick={handleQueryClick}
      >
        {query}
      </p>
    </div>
  );
};
