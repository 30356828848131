import { Locales } from 'constants/global';

export type MobieChatPopupProps = {
  open: boolean;
  welcomeMessage: string;
  onModalClose: () => void;
};

export enum ChatRoleTypes {
  USER = 'user',
  ASSISTANT = 'assistant',
}

export interface MessageData {
  message: string;
  role: ChatRoleTypes;
  notFoundMessage?: string;
  tags?: string[];
}

export interface MobieMessageRequestPayload {
  accountId: string;
  body: {
    message: string;
  };
}

export type MobieMessageResponse = Omit<MessageData, 'role'>;

export interface MobieWelcomeMessageRequestPayload {
  accountId: string;
  locale: Locales;
}

export interface MobieWelcomeMessageResponse {
  welcomeMessage: string;
}
