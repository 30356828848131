import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import { LepaTypes } from 'pages/home/Home.types';
import { SearchResult } from 'pages/search/search-result';
import React from 'react';
import { SearchResultsProps as Props } from '../SearchResult.types';

export const TopicResult: React.FC<Props> = ({ result, context, pathContext, onRedirect }) => {
  const topicIndex = context?.children.findIndex((topic) => topic.id === result.id) ?? 0;
  const levelIndex = pathContext?.children.findIndex((level) => level.id === context?.id) ?? 0;

  const topicBellow = context?.children[topicIndex + 1];
  const levelBellow = pathContext?.children[levelIndex + 1];

  // Levels are not rendered on the results, so if they have no topic childs, they will not show up
  const levelBellowIsRendered = levelBellow && levelBellow.children.length > 0;

  const shouldRenderSeparator = topicBellow || levelBellowIsRendered;
  const isCrashCourse = pathContext?.learningPathType === LepaTypes.CRASH_COURSE;

  const handleRedirectClick = () => {
    if (result.locked || !pathContext?.id || !context?.id) {
      return;
    }
    onRedirect(`/learning-path/${pathContext.id}/level/${context.id}/skill/${result.id}`);
  };

  return (
    <>
      {!isCrashCourse && (
        <div
          className={classnames('flex justify-between items-center my-3 cursor-pointer', {
            'opacity-50 cursor-not-allowed': result.locked,
          })}
          onClick={handleRedirectClick}
        >
          <p className='m-0 text-base font-medium select-none text-lightGrey'>{result.title}</p>
          <i>
            {result.locked ? (
              <SvgIcon
                name='lock'
                color='white'
                size={24}
                className='p-1 rounded-full bg-lightGrey'
                viewBox='0 0 24 24'
              />
            ) : (
              <SvgIcon
                name='arrowForward'
                color='white'
                size={20}
                className='p-1 mr-1 rounded-full bg-lightGrey'
              />
            )}
          </i>
        </div>
      )}

      {result.children.map((learningMoment) => (
        <SearchResult
          key={`learningMoment-${learningMoment.id}`}
          result={learningMoment}
          context={result}
          pathContext={pathContext}
          levelContext={context}
          onRedirect={onRedirect}
        />
      ))}

      {shouldRenderSeparator && <hr className='border-t-2 opacity-75 bg-whiteFour' />}
    </>
  );
};
