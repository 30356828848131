import { AnyAction, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { CompleteWalkthroughRequestPayload, walkthroughApi } from 'containers/walkthrough';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { Store } from 'store/types';
import { withCompletedWalkthroughs } from 'util/withCompletedWalkthroughs';
import { defaultLocale } from '../language-provider';
import Search from './Search';

const mapStateToProps = ({ activePath, profile: { user } }: Store) => ({
  activePath,
  locale: user.locale || defaultLocale,
  user,
});

type PromiseVoidThunkAction = ThunkAction<Promise<void>, RootState, unknown, AnyAction>;

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => ({
  completeWalkthrough: (payload: CompleteWalkthroughRequestPayload) =>
    dispatch(walkthroughApi.endpoints.completeWalkthrough.initiate(payload) as unknown as PromiseVoidThunkAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(withCompletedWalkthroughs(Search));
