import React from 'react';

interface Storage<Value = string, Key extends string = string> {
  get(key: Key, otherwise?: Value): typeof otherwise;
  set(key: Key, value: Value): boolean;
  remove(key: Key): void;
  clear(): void;
}

const globalStorage = {
  local: localStorage,
  session: sessionStorage,
};

type StorageTypes = keyof typeof globalStorage;

export function getStorage<Value = string, Key extends string = string>(
  storage: StorageTypes = 'local',
): Storage<Value, Key> {
  return {
    get(key, otherwise) {
      const value = globalStorage[storage].getItem(key);
      if (value === null) {
        return otherwise;
      }
      return JSON.parse(value);
    },
    set(key, value) {
      try {
        globalStorage[storage].setItem(key, JSON.stringify(value));
        return true;
      } catch {
        return false;
      }
    },
    remove(key) {
      return globalStorage[storage].removeItem(key);
    },
    clear() {
      return globalStorage[storage].clear();
    },
  };
}

interface UseStorageStateOptions<Value = string, Key extends string = string> {
  key: Key;
  initialValue?: Value;
  storageType?: StorageTypes;
}

type UseStorageStateReturn<Value = string> = [Value | undefined, React.Dispatch<Value>];

/**
 * @param initialValue value to use if `key` doesn't exist in storage
 *
 * @returns Returns a stateful object, and a function to update it from `React.useState`.
 */
export function useStorageState<Value = string, Key extends string = string>({
  key,
  initialValue,
  storageType = 'local',
}: UseStorageStateOptions<Value, Key>): UseStorageStateReturn<Value> {
  const storage = getStorage<Value, Key>(storageType);
  const value = storage.get(key, initialValue);
  const [state, setReactState] = React.useState(value);

  const setState: React.Dispatch<Value> = (newValue: Value) => {
    storage.set(key, newValue);
    setReactState(newValue);
  };

  return [state, setState];
}
