import { AnyAction } from '@reduxjs/toolkit';
import { actions as authActions, SetActiveAccountPayload } from 'containers/authentication';
import { defaultLocale } from 'containers/language-provider';
import { actions as modalActions } from 'containers/modal-controller/ModalController.ducks';
import { EnqueueModalPayload } from 'containers/modal-controller/ModalController.types';
import { actions as profileActions } from 'containers/Profile';
import { DEPRECATED_UpdateUserPayload, UpdateUserPayload } from 'containers/Profile/types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from 'store';
import { dynamicConfigurationApi } from 'store/dynamic-configuration';
import { UploadImagePayload } from '../../constants/global';
import SettingsComponent from './Settings';
import { actions } from './Settings.ducks';
import { ChangeEmailPayload, ChangePasswordPayload, GetDynamicConfiguration } from './Settings.types';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAvatarUpload: (payload: UploadImagePayload) => dispatch(profileActions.avatarUpload(payload)),
  DEPRECATED_updateUser: (payload: DEPRECATED_UpdateUserPayload) =>
    dispatch(profileActions.DEPRECATED_updateUser(payload)),
  changePassword: (payload: ChangePasswordPayload) => dispatch(actions.changePassword(payload)),
  changeEmail: (payload: ChangeEmailPayload) => dispatch(actions.changeEmail(payload)),
  logout: () => dispatch(authActions.logout()),
  resetSettings: () => dispatch(actions.resetSettings()),
  setActiveAccount: (payload: SetActiveAccountPayload) => dispatch(authActions.setActiveAccount(payload)),
  updateUser: (payload: UpdateUserPayload) => dispatch(profileActions.updateUser(payload)),
  enqueueModal: (payload: EnqueueModalPayload) => dispatch(modalActions.enqueueModal(payload)),
  onModalClose: () => {
    dispatch(modalActions.showNextModal());
  },
  refetchDynamicConfiguration: (payload: GetDynamicConfiguration) =>
    dispatch(
      dynamicConfigurationApi.endpoints.getDynamicConfiguration.initiate(payload, {
        forceRefetch: true,
      }) as unknown as AnyAction,
    ),
});

const mapStateToProps = (state: RootState) => {
  const { profile, auth, settings, activePath, featureFlags } = state;
  const levelInfo = {
    currentLevelName: activePath.currentLevelName,
    currentLevelIndex: activePath.currentLevelIndex,
  };
  const locale = profile.user.locale || defaultLocale;

  const dynamicConfigurationSelector = dynamicConfigurationApi.endpoints.getDynamicConfiguration.select({
    accountId: profile.account.id,
  });

  // @ts-ignore - need to be change in the future
  const { data: dynamicConfiguration } = dynamicConfigurationSelector(state);

  const { flags } = featureFlags;

  return {
    profile,
    auth,
    settings,
    levelInfo,
    locale,
    dynamicConfiguration,
    isEnabledCustomizableProfile: flags['customizable-profile'],
    isEnabledDigest: flags['email-digest'],
  };
};

export const Settings = injectIntl(connect(mapStateToProps, mapDispatchToProps)(SettingsComponent));
