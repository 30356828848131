import classnames from 'classnames';
import React from 'react';
import { PopupTitleProps } from './PopupTitle.types';

export const PopupTitle: React.FC<PopupTitleProps> = ({ variant, className, children, ...rest }) => {
  const check = variant === 'profileUpdate' ? 'profileUpdate' : 'profileUpdateFeedback';
  return (
    <h1
      className={classnames(
        'm-0 mt-12 pb-2 text-center font-semibold',
        {
          'text-yellow-300 text-2xl': variant !== check,
          'text-turquoiseBlue text-center text-xl font-semibold m-0 mt-6': variant === 'profileUpdateFeedback',
          'text-blue-500': variant === 'retention',
          'text-greyishBrown font-bold':
            variant === 'locked' || variant === 'limitReach' || variant === 'skillFail' || variant === 'levelFail',
          'text-greyishBrown font-bold mt-14': variant === 'levelPassed' || variant === 'skillPassed',
          'text-turquoiseBlue text-lg font-bold m-0 mt-6 text-center': variant === 'badge',
          'text-turquoiseBlue text-xl font-semibold m-0 mt-6 text-center': variant === check,
          'my-2': variant === 'clean',
          'text-turquoiseBlue text-xl': variant === 'personalityTest',
        },
        className,
      )}
      {...rest}
    >
      {children}
    </h1>
  );
};
