import popupLogo from 'assets/images/popup_logo.svg';
import { Button } from 'components/button';
import { SpinnerBalls } from 'components/Loading';
import { Popup } from 'components/popup';
import Textfield from 'components/Textfield';
import { OnTextfieldChange } from 'components/Textfield/types';
import { ProfileUpdateModalProps as Props } from 'containers/settings/Settings.types';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const LoadingContent = () => (
  <div className='flex flex-row justify-center'>
    <SpinnerBalls />
  </div>
);

const EditingContent: React.FC<{
  password: string;
  isEulaChecked: boolean;
  error?: string;
  onChangePassword: OnTextfieldChange;
  onToggleEula: React.ChangeEventHandler;
}> = ({ password, isEulaChecked, error = '', onChangePassword, onToggleEula }) => {
  const intl = useIntl();
  const hasError = Boolean(error);

  return (
    <>
      <div>
        <Textfield
          className='mb-2'
          placeholder={intl.formatMessage({ id: 'container.login.passwordPlaceholder' })}
          value={password}
          onChange={onChangePassword}
          type='password'
          name='currentPassword'
          inputClassName='bg-neutral-200 text-neutral-600 placeholder-neutral-600 text-center'
          fullWidth
          error={hasError}
        />
        {hasError && <div className='pl-0.5 pb-1 text-fullRed mb-8'>{error}</div>}
      </div>
      <input
        type='checkbox'
        id='consent-message'
        onChange={onToggleEula}
        checked={isEulaChecked}
      />
      <label
        htmlFor='consent-message'
        className='mx-2'
      >
        <FormattedMessage
          id='settings.email.popup.eula.consent-message'
          defaultMessage='By registering to and using the MobieTrain software I accept the terms outlined in the {link} (EULA).'
          values={{
            link: (
              <a
                href={intl.formatMessage({ id: 'settings.email.popup.eula.eula-url' })}
                target='_blank'
                rel='noreferrer noopener'
                className='underline'
              >
                <FormattedMessage
                  id='settings.email.popup.eula.License-message'
                  defaultMessage='End User License Agreement'
                />
              </a>
            ),
          }}
        />
      </label>
    </>
  );
};

export const ProfileUpdateModal: React.FC<Props> = ({ open, modal: { onModalClose, onSubmitCallback }, sendLink }) => {
  const intl = useIntl();
  const [password, setPassword] = React.useState('');
  const [isEulaChecked, setIsEulaChecked] = React.useState(false);
  const isUpdating = useSelector((state: RootState) => state.settings.isUpdating);
  const { status } = useSelector((state: RootState) => state.settings.change.email);

  const isWrongPassword = status === 403;

  /**
   * This useEffect is used to close the modal when the request is submitted without a wrong password
   * We need the useEffect as long as we're using sagas to handle the submit.
   */
  useEffect(() => {
    const isSubmitted = status > 0;
    if (isSubmitted && !isWrongPassword) {
      onSubmitCallback();
    }
  }, [status, isWrongPassword, onSubmitCallback]);

  const handleClose = () => {
    onModalClose();
  };

  const handleChangePassword: OnTextfieldChange = (newPassword) => {
    setPassword(newPassword);
  };

  const handleEulaToggle = () => {
    setIsEulaChecked(!isEulaChecked);
  };

  const handleSubmit = () => {
    sendLink(password);
  };

  const canSubmit = isEulaChecked && !isUpdating && password.length > 0;

  return (
    <Popup
      icon={popupLogo}
      variant='profileUpdate'
      closeIcon={!isUpdating}
      open={open}
      onRequestClose={handleClose}
      title={intl.formatMessage({ id: 'settings.email.popup.eula.title' })}
    >
      <div className='flex flex-col content-center w-full'>
        <div
          data-testid='checkbox'
          className='mx-2 my-6 text-sm text-neutral-500'
        >
          {isUpdating && <LoadingContent />}

          {!isUpdating && (
            <EditingContent
              password={password}
              isEulaChecked={isEulaChecked}
              onChangePassword={handleChangePassword}
              onToggleEula={handleEulaToggle}
              error={isWrongPassword ? intl.formatMessage({ id: 'settings.pwd.message.wrong-pwd-error' }) : ''}
            />
          )}
        </div>

        <div className='self-center w-1/2 mb-4'>
          <Button
            className='w-full pt-4 pb-4 mt-4 ml-0 mr-0 text-sm'
            variant='outlined'
            type='submit'
            onClick={handleSubmit}
            disabled={!canSubmit}
          >
            <FormattedMessage id='settings.email.popup.eula.button' />
          </Button>
        </div>
      </div>
    </Popup>
  );
};
