import { FormatScreenStore, FormatType } from 'containers/FormatScreen';
import { AnswerPayload } from 'pages/tests/Tests.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { AwardInfo, Awards, ExperiencePointsType } from '../../Loformats.types';
import { formatTypeInformationMap } from '../../formatTypeInformation';
import {
  AwardsCounter,
  CheckAwardsProps,
  CheckAwardsResponse,
  ExperiencePointsData,
  UseExperiencePoints,
} from './types';

const initialExperiencePointsMap: ExperiencePointsData = {
  [ExperiencePointsType.STREAK]: {
    points: 0,
    fill: '#FFB500',
    xpColor: '#9013FE',
    translationId: 'formats.text.streak',
  },
  [ExperiencePointsType.NICE_COMEBACK]: {
    points: 0,
    fill: '#9013FE',
    xpColor: '#FFC000',
    translationId: 'formats.text.comeback',
  },
  [ExperiencePointsType.QUICK_ANSWER]: {
    points: 0,
    fill: '#FF8424',
    xpColor: '#9013FE',
    translationId: 'formats.text.quickAnswer',
  },
  [ExperiencePointsType.BACK_ON_TRACK]: {
    points: 0,
    fill: '#00A6F6',
    xpColor: '#F8E71C',
    translationId: 'formats.text.backTrack',
  },
};

const getExperiencePoints = (rules: FormatScreenStore['rules'], currentLevelIndex: number): ExperiencePointsData => {
  const { bonus, penalty } = rules;
  const maxIndex = Math.min(bonus.length, penalty.length) - 1;
  const shiftedIndex = currentLevelIndex <= maxIndex ? currentLevelIndex : maxIndex;
  return Object.keys(initialExperiencePointsMap).reduce((acc, key) => {
    const typedKey = key as ExperiencePointsType;
    return {
      ...acc,
      [typedKey]: {
        ...initialExperiencePointsMap[typedKey],
        points: Math.round(rules[typedKey] * bonus[shiftedIndex] * penalty[shiftedIndex]),
      },
    };
  }, {} as ExperiencePointsData);
};

const checkAwards = ({
  awards,
  awardsCounter,
  experiencePointsMap,
  isCorrectAnswer,
  answerTime,
  qaTimer,
}: CheckAwardsProps): CheckAwardsResponse => {
  const awardsUpdate = { ...awards };
  const awardsCounterUpdate = { ...awardsCounter };
  let showExperiencePointsPopupUpdate = false;
  let awardInfoUpdate = experiencePointsMap.quickAnswer;

  if (isCorrectAnswer) {
    if (answerTime <= qaTimer) {
      // quick answer
      showExperiencePointsPopupUpdate = true;
      const key = ExperiencePointsType.QUICK_ANSWER;
      awardsUpdate[key] += experiencePointsMap[key].points;
    }
    if (!awardsCounterUpdate.correctInRowGiven && awardsCounterUpdate.correctInRow >= 2) {
      // streak
      awardsCounterUpdate.correctInRowGiven = true;
      showExperiencePointsPopupUpdate = true;
      const key = ExperiencePointsType.STREAK;
      awardInfoUpdate = experiencePointsMap[key];
      awardsUpdate[key] += experiencePointsMap[key].points;
    }
    if (awardsCounterUpdate.incorrectInRow >= 2) {
      // back on track
      showExperiencePointsPopupUpdate = true;
      const key = ExperiencePointsType.BACK_ON_TRACK;
      awardInfoUpdate = experiencePointsMap[key];
      awardsUpdate[key] += experiencePointsMap[key].points;
    }
    if (awardsCounterUpdate.incorrectInRow === 1) {
      // nice comeback
      showExperiencePointsPopupUpdate = true;
      const key = ExperiencePointsType.NICE_COMEBACK;
      awardInfoUpdate = experiencePointsMap[key];
      awardsUpdate[key] += experiencePointsMap[key].points;
    }
    awardsCounterUpdate.correctInRow += 1;
    awardsCounterUpdate.incorrectInRow = 0;
  } else {
    awardsCounterUpdate.correctInRow = 0;
    awardsCounterUpdate.incorrectInRow += 1;
  }

  return {
    showExperiencePointsPopupUpdate,
    awardsUpdate,
    awardsCounterUpdate,
    awardInfoUpdate,
  };
};

export const useExperiencePoints = ({
  lastAnswer,
  isAnswerSubmitted,
  isCorrectAnswer,
  formatType,
  skipAwards = false,
}: {
  lastAnswer: AnswerPayload | null;
  isAnswerSubmitted: boolean;
  formatType: FormatType;
  isCorrectAnswer: boolean;
  skipAwards?: boolean;
}): UseExperiencePoints => {
  const { rules } = useSelector(({ formats }: Store) => formats);
  const { currentLevelIndex } = useSelector(({ activePath }: Store) => activePath);
  const experiencePointsMap = useMemo(
    () => getExperiencePoints(rules, currentLevelIndex ?? 0),
    [currentLevelIndex, rules],
  );
  const [awards, setAwards] = useState<Awards>({
    [ExperiencePointsType.STREAK]: 0,
    [ExperiencePointsType.NICE_COMEBACK]: 0,
    [ExperiencePointsType.QUICK_ANSWER]: 0,
    [ExperiencePointsType.BACK_ON_TRACK]: 0,
  });
  const [awardsCounter, setAwardsCounter] = useState<AwardsCounter>({
    correctInRow: 0,
    incorrectInRow: 0,
    correctInRowGiven: false,
  });
  const [showExperiencePointsPopup, setShowExperiencePointsPopup] = useState<boolean>(false);
  const [awardInfo, setAwardInfo] = useState<AwardInfo>(initialExperiencePointsMap.quickAnswer);
  const [lastAnswerIdEvaluated, setLastAnswerIdEvaluated] = useState<number>(-1);
  const [showExperiencePointsPopupTimer, setShowExperiencePointsPopupTimer] = useState<NodeJS.Timeout | null>(null);

  const updateShowExperiencePointsPopupTimer = useCallback(
    (showExperiencePointsPopupUpdate: boolean) => {
      if (showExperiencePointsPopupUpdate) {
        if (showExperiencePointsPopupTimer) {
          clearTimeout(showExperiencePointsPopupTimer);
        }
        setShowExperiencePointsPopupTimer(
          setTimeout(() => {
            setShowExperiencePointsPopup(false);
          }, 4000),
        );
      }
    },
    [showExperiencePointsPopupTimer],
  );

  useEffect(() => {
    const isNewAnswer = lastAnswer?.learningFormatId !== lastAnswerIdEvaluated;
    const lastAnswerHasStartAndEndTimes = lastAnswer?.startTime && lastAnswer?.endTime;
    const shouldCheckAwards = !skipAwards && isNewAnswer && lastAnswerHasStartAndEndTimes && isAnswerSubmitted;
    if (shouldCheckAwards) {
      const { startTime, endTime } = lastAnswer;
      const answerTime = endTime - startTime;
      const { qaTimer } = formatTypeInformationMap[formatType];
      const { showExperiencePointsPopupUpdate, awardsUpdate, awardsCounterUpdate, awardInfoUpdate } = checkAwards({
        awards,
        awardsCounter,
        experiencePointsMap,
        isCorrectAnswer,
        answerTime,
        qaTimer,
      });
      setAwards(awardsUpdate);
      setAwardsCounter(awardsCounterUpdate);
      setAwardInfo(awardInfoUpdate);
      setLastAnswerIdEvaluated(lastAnswer.learningFormatId);
      updateShowExperiencePointsPopupTimer(showExperiencePointsPopupUpdate);
      setShowExperiencePointsPopup(showExperiencePointsPopupUpdate);
    }
  }, [
    lastAnswer,
    isAnswerSubmitted,
    isCorrectAnswer,
    lastAnswerIdEvaluated,
    awards,
    awardsCounter,
    experiencePointsMap,
    formatType,
    showExperiencePointsPopupTimer,
    updateShowExperiencePointsPopupTimer,
    skipAwards,
  ]);

  const getAwards = (): AwardInfo[] =>
    Object.entries(awards)
      .filter(([, awardPoints]) => awardPoints > 0)
      .map(([key, awardPoints]) => ({
        ...initialExperiencePointsMap[key as ExperiencePointsType],
        points: awardPoints,
      }));

  return {
    getAwards,
    awardInfo,
    showExperiencePointsPopup,
  };
};
