import { defaultLocale, languageTextDirections, Locale } from 'containers/language-provider';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';

export const useTextDirection = (): { isLTR: boolean; isRTL: boolean } => {
  const locale = useSelector<Store, Locale>(({ profile }) => profile.user.locale || defaultLocale);
  const direction = languageTextDirections[locale];
  const isLTR = direction === 'ltr';

  return {
    isLTR,
    isRTL: !isLTR,
  };
};
