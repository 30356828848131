import Breadcrumbs from 'components/Breadcrumbs';
import { Loading } from 'components/Loading';
import { BackButton } from 'components/back-button';
import { PageContainer } from 'components/page-container';
import { USER_ROLES } from 'constants/global';
import { getRoleAccessLevel } from 'containers/authentication';
import { defaultLocale } from 'containers/language-provider';
import { useModalQueue } from 'containers/modal-controller';
import { LepaTypes } from 'pages/home/Home.types';
import { isLegacyLearningPath } from 'pages/lepa';
import { useStartLepaQuery } from 'pages/lepa/Lepa.api';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Store } from 'store/types';
import theme from 'theme';
import { actions } from './Skill.ducks';
import { SkillHistoryState, SkillUrlParams } from './Skill.types';
import { getColorFromScore, getProperShape, mapModulesToOldStructure } from './helpers';
import { useSkillWalkthroughs } from './useSkillWalkthroughs';

export const Skill: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory<SkillHistoryState>();
  const { location } = history;
  const { enqueueModal } = useModalQueue();
  const { pathID: learningPathId, levelID: levelId, skillID: skillId } = useParams<SkillUrlParams>();
  const profileStore = useSelector(({ profile }: Store) => profile);
  const { id: userId, role, locale } = profileStore.user;
  const {
    moduleCreditReached,
    error: informationError,
    loaded: isInformationLoaded,
  } = useSelector(({ skill }: Store) => skill);

  const {
    data: learningPath,
    isError: lepaIsError,
    isFetching,
    originalArgs: lepaCache,
  } = useStartLepaQuery({
    userId: parseInt(userId, 10),
    lepaId: parseInt(learningPathId, 10),
    locale: locale ?? defaultLocale,
  });

  useEffect(() => {
    if (learningPath) {
      dispatch(actions.getModuleCreditReached({ lepaId: parseInt(learningPathId, 10) }));
    }
  }, [dispatch, learningPath, learningPathId]);

  useSkillWalkthroughs({
    learningPath,
    levelId: parseInt(levelId, 10),
    skillId: parseInt(skillId, 10),
  });

  const isLearningPathDifferent = parseInt(learningPathId, 10) !== learningPath?.id;
  const isLoadingNewLepa = !learningPath || !isInformationLoaded || isFetching || isLearningPathDifferent;
  const isErrorAndMatchingId = lepaIsError && learningPathId === String(lepaCache?.lepaId);

  if (isErrorAndMatchingId) {
    history.push('/invalid-url');
    return null;
  }

  if (isLoadingNewLepa) {
    return <Loading />;
  }

  const shouldShowInvalidUrlPage =
    lepaIsError || informationError || (learningPath && !isLegacyLearningPath(learningPath));
  if (shouldShowInvalidUrlPage) {
    history.push('/invalid-url');
    return null;
  }

  const level = learningPath.content.levels.find((currentLevel) => currentLevel.id === parseInt(levelId, 10));
  const skill = level?.skills.find((currentSkill) => currentSkill.id === parseInt(skillId, 10));

  const toPathScreen = `/learning-path/${learningPathId}`;

  if (!skill || skill.modules.length < 2 || skill.modules.length > 5) {
    return <Redirect to={toPathScreen} />;
  }

  const animateUnlocks = location?.state?.nextUnlocked ?? false;

  const locationStateModuleId = location?.state?.moduleID ? parseInt(location.state.moduleID, 10) : -1;
  const animationIndex = skill.modules.findIndex((module) => module.id === locationStateModuleId) ?? -1;

  const hasLearningTest = Boolean(skill.progress.learningTestId);

  const { color: learningPathColor } = learningPath;
  const color = learningPathColor ?? theme.colors.orangeRed;

  const roleAccessLevel = getRoleAccessLevel(role);
  const isPreviewUserRole = roleAccessLevel === USER_ROLES.PREVIEW;

  const passedModules = skill.modules.filter((module) => module.progress.passed);
  const lastPassedModule = passedModules.slice(-1)[0];
  const lastPassedModulePercentage = lastPassedModule ? lastPassedModule.progress.percentage : '';
  const lastScore = lastPassedModulePercentage ? Number.parseFloat(lastPassedModulePercentage) : 0;
  const animationColor = getColorFromScore(lastScore);

  const modulesInOldStructure = mapModulesToOldStructure(skill.modules);

  const handleGoToFormats = (testType: string, id?: string | number) => {
    if (testType === 'moduleTest' && id) {
      history.push(`/learning-path/${learningPathId}/level/${levelId}/skill/${skillId}/module/${id}`);
    }
    if (testType === 'skillTest' && skill.progress.learningTestId) {
      const { learningTestId } = skill.progress;
      history.push(`/learning-path/${learningPathId}/level/${levelId}/skill/${skillId}/test/${learningTestId}`);
    }
  };

  const Shape = getProperShape(skill.modules.length);

  const { title, summary, tags } = skill;

  return (
    <>
      <BackButton to={toPathScreen} />
      <PageContainer className='flex flex-col h-full overflow-y-auto'>
        <Breadcrumbs
          levelId={levelId}
          to={toPathScreen}
        />
        <div className='flex-auto'></div>
        <div className='flex-auto w-full mb-10'>
          <h1
            className='px-4 my-4 text-lg font-medium leading-3 text-center'
            style={{ color }}
          >
            {title}
          </h1>
          <p className='px-6 my-4 text-sm font-normal leading-5 text-center whitespace-pre-wrap text-greyishBrown'>
            {summary}
          </p>
          <p className='px-4 my-4 text-sm leading-5 text-center text-greyishBrown'>
            {tags.map((tag) => (
              <span key={tag}>{`#${tag} `}</span>
            ))}
          </p>
        </div>
        <div
          id='shape-container'
          className='flex justify-center flex-auto w-full'
        >
          <Shape
            modules={modulesInOldStructure}
            color={color}
            animationIndex={animationIndex}
            animateUnlocks={animateUnlocks}
            onNodeClick={handleGoToFormats}
            moduleCreditReached={moduleCreditReached}
            animationColor={animationColor}
            skillTest={hasLearningTest}
            pathType={LepaTypes.LEGACY}
            hasUnlockAnimation={!isPreviewUserRole}
            enqueueModal={enqueueModal}
          />
        </div>
        <div className='flex-auto'></div>
      </PageContainer>
    </>
  );
};
