import classnames from 'classnames';
import { FC } from 'react';
import { AppChannelUser } from '../../Chat.types';
import { UserPreview } from '../../new/group';
import { AlphabeticUserListProps } from './AlphabeticUserList.types';

export const AlphabeticUserList: FC<AlphabeticUserListProps> = ({
  users,
  hasSelectIndicator,
  listClassName,
  sectionClassName,
  onClick,
}) => {
  const usersGroupByFirstLetter = users.reduce((acc: Record<string, AppChannelUser[]>, user) => {
    const firstLetter = user.name?.charAt(0).toUpperCase() ?? '';
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(user);
    return acc;
  }, {});

  return (
    <div className={classnames('pb-3', listClassName)}>
      {Object.keys(usersGroupByFirstLetter).map((letter) => (
        <section
          key={letter}
          className={sectionClassName}
        >
          <h4 className='mt-3 text-gray-600'>{letter}</h4>
          {usersGroupByFirstLetter[letter].map((user) => (
            <>
              <UserPreview
                key={user.id}
                user={user}
                hasSelectIndicator={hasSelectIndicator}
                onClick={onClick}
              />
            </>
          ))}
        </section>
      ))}
    </div>
  );
};
