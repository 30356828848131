import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PopupVariantProps } from '../Popup.types';
import { CloseIcon } from '../close-icon';

const ARROW_WIDTH = 20;
const SQUARE_WIDTH = ARROW_WIDTH / Math.sqrt(2);

export const TooltipPopup: React.FC<PopupVariantProps> = ({
  target,
  placement = 'bottom-right',
  children,
  distance = 20,
  isBackgroundVisible = true,
  closeIcon,
  onClose,
}) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const isTop = placement.includes('top');
  const isBottom = placement.includes('bottom');
  const isLeft = placement.includes('left');
  const isRight = placement.includes('right');

  const getTooltipPosition = useCallback(() => {
    if (typeof target === 'function' || !target?.current || !tooltipRef.current) {
      return { top: 0, left: 0 };
    }
    const targetRect = target.current.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    switch (placement) {
      case 'top-left':
        return {
          top: targetRect.top - tooltipRect.height - distance,
          left: targetRect.left + targetRect.width / 2 - tooltipRect.width + ARROW_WIDTH / 2,
        };
      case 'top-right':
        return {
          top: targetRect.top - tooltipRect.height - distance,
          left: targetRect.right - targetRect.width / 2 - ARROW_WIDTH / 2,
        };
      case 'bottom-left':
        return {
          top: targetRect.bottom + distance,
          left: targetRect.left + targetRect.width / 2 - tooltipRect.width + ARROW_WIDTH / 2,
        };
      case 'bottom-right':
        return {
          top: targetRect.bottom + distance,
          left: targetRect.right - targetRect.width / 2 - ARROW_WIDTH / 2,
        };
      default:
        return { top: 0, left: 0 };
    }
  }, [distance, placement, target]);

  const resizeObserverCallback = useCallback(() => {
    if (!tooltipRef.current) {
      return;
    }
    const { top, left } = getTooltipPosition();
    setPosition({ top, left });
  }, [getTooltipPosition]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(resizeObserverCallback);
    if (target?.current?.parentElement) {
      resizeObserver.observe(target.current.parentElement);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [resizeObserverCallback, target]);

  return (
    <>
      {isBackgroundVisible && <div className='fixed inset-0 z-10 bg-black bg-opacity-50' />}
      <div
        ref={tooltipRef}
        className={classnames(
          'absolute p-2 font-light text-base bg-white max-w-2xl 3xs:max-w-[18rem] 2xs:max-w-[24rem] lg:max-w-2xl z-20',
          {
            'rounded-tl': placement !== 'bottom-right',
            'rounded-tr': placement !== 'bottom-left',
            'rounded-bl': placement !== 'top-right',
            'rounded-br': placement !== 'top-left',
          },
        )}
        style={{
          top: `${position.top}px`,
          left: `${position.left}px`,
        }}
      >
        <div
          className='absolute rotate-45 bg-white'
          style={{
            width: `${SQUARE_WIDTH}px`,
            height: `${SQUARE_WIDTH}px`,
            ...(isBottom && { top: `-${SQUARE_WIDTH / 2}px` }),
            ...(isTop && { bottom: `-${SQUARE_WIDTH / 2}px` }),
            ...(isLeft && { right: `${-(SQUARE_WIDTH / 2) + ARROW_WIDTH / 2}px` }),
            ...(isRight && { left: `${-(SQUARE_WIDTH / 2) + ARROW_WIDTH / 2}px` }),
          }}
        />
        {closeIcon && <CloseIcon onClose={onClose('close')} />}
        <div className='m-5'>{children}</div>
      </div>
    </>
  );
};
