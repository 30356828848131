import installAppImage from 'assets/images/install_app.svg';
import qandaImage from 'assets/images/qanda.svg';
import technicalImage from 'assets/images/technical.svg';
import { BackButton } from 'components/back-button';
import { PageContainer } from 'components/page-container';
import { Typography } from 'components/typography';
import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { useWalkthrough } from 'containers/walkthrough';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { Store } from 'store/types';
import { HelpStore } from './Help.types';
import { ExplanationIconsItem, ExplanationIconsList } from './explanation-icons-list';
import { InfoCardButton } from './info-card-button';

const APP_VERSION = process.env.REACT_APP_VERSION;

/**
 * Page containing helpful information for the users
 */
export const Help = () => {
  const { faqUrl, supportUrl } = useSelector<Store, HelpStore>(({ help }) => help);
  const isInstallInstructionsFeatureFlagEnabled = useFeatureFlag(FlagKeys.INSTALL_INSTRUCTIONS);
  const { completedWalkthroughs, onWalkthroughEnd } = useWalkthrough();
  const { enqueueModal } = useModalQueue();

  const handleInstallAppClick = () => {
    const walkThroughSlug = 'install-app';
    const walkthrough = completedWalkthroughs[walkThroughSlug];
    const appIsAlreadyInstalled = walkthrough?.xpEarned > 0 && !walkthrough?.showAfter;

    enqueueModal({
      type: ModalTypes.INSTALL_INSTRUCTION_STEPS,
      modal: {
        onInstallComplete: async () => {
          await onWalkthroughEnd(walkThroughSlug);
        },
        appIsAlreadyInstalled,
      },
    });
  };

  const explanationIconsItems: ExplanationIconsItem[] = [
    {
      iconName: 'certified',
      translationKey: 'comp.icons-explanation.certification',
      viewBox: '-3 0 15 15',
    },
    {
      iconName: 'default-learning-path',
      translationKey: 'comp.icons-explanation.learning-path',
    },
    {
      iconName: 'crash-course',
      translationKey: 'comp.icons-explanation.crash-course',
      viewBox: '-6 0 15 15',
    },
    {
      iconName: 'single-learning',
      translationKey: 'comp.icons-explanation.single-learning',
      viewBox: '1 1 22 22',
    },
    {
      iconName: 'text-format',
      translationKey: 'comp.icons-explanation.informational-steps',
      viewBox: '3 3 18 18',
    },
    {
      iconName: 'play-outlined',
      translationKey: 'comp.icons-explanation.single-video',
      viewBox: '-10 0 140 140',
    },
    {
      iconName: 'questions-only',
      translationKey: 'comp.icons-explanation.questionnaire',
      viewBox: '3 3 18 18',
    },
    {
      iconName: 'calendar',
      translationKey: 'comp.icons-explanation.event',
    },
    {
      iconName: 'external',
      translationKey: 'comp.icons-explanation.external-content',
      explanationTranslationKey: 'comp.icons-explanation.external-content-explanation',
    },
  ];

  return (
    <>
      <BackButton />
      <div className='w-full px-2 overflow-y-auto'>
        <PageContainer>
          <div className='px-4 pb-4'>
            <FormattedMessage id='comp.info.title'>
              {(str) => <h1 className='p-0 m-0 mb-1 text-lg text-turquoiseBlue'>{str}</h1>}
            </FormattedMessage>

            <FormattedMessage id='comp.info.subTitle'>
              {(str) => <h3 className='p-0 m-0 text-sm text-greyishBrown'>{str}</h3>}
            </FormattedMessage>
          </div>

          <div className='px-1 pb-2'>
            {supportUrl.enabled && supportUrl.url && (
              <InfoCardButton
                src={technicalImage}
                url={supportUrl.url}
              >
                <FormattedMessage id='comp.info.report' />
              </InfoCardButton>
            )}

            {faqUrl.enabled && faqUrl.url && (
              <InfoCardButton
                src={qandaImage}
                url={faqUrl.url}
              >
                <FormattedMessage id='comp.info.aandq' />
              </InfoCardButton>
            )}

            {isInstallInstructionsFeatureFlagEnabled && (
              <InfoCardButton
                src={installAppImage}
                url={''}
                onClick={handleInstallAppClick}
              >
                <FormattedMessage
                  id='comp.info.install-app'
                  defaultMessage='Install APP on your device'
                />
              </InfoCardButton>
            )}
          </div>

          <div
            className='px-4 pb-4'
            data-testid='explanation-icons-title'
          >
            <FormattedMessage id='comp.icons-explanation.title'>
              {(str) => <h1 className='p-0 m-0 mb-1 text-lg text-turquoiseBlue'>{str}</h1>}
            </FormattedMessage>
          </div>

          <div
            className='grid px-6 pb-4 text-sm text-warmGrey gap-x-6 gap-y-6'
            style={{ gridTemplateColumns: 'min-content auto' }}
            data-testid='explanation-icons-items'
          >
            <ExplanationIconsList items={explanationIconsItems} />
          </div>

          {APP_VERSION && (
            <div className='w-full pb-4 text-xs text-center'>
              <Typography className='font-normal text-gray-200'>MobieTrain</Typography>
              <Typography className='font-medium text-gray-400'>Ver. {APP_VERSION}</Typography>
            </div>
          )}
        </PageContainer>
      </div>
    </>
  );
};
