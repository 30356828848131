import classnames from 'classnames';
import { languageTextDirections } from 'containers/language-provider';
import { LolecoRow } from 'containers/loleco-row';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { BuiltInPathCategories, HomeStore, LolecoProps as Props } from './Home.types';

export const BuiltinCategoryNames = {
  [BuiltInPathCategories.CONTINUE]: <FormattedMessage id='lecoBuiltinCategories.doing' />,
  [BuiltInPathCategories.NEW]: <FormattedMessage id='lecoBuiltinCategories.new' />,
  [BuiltInPathCategories.COACHING]: <FormattedMessage id='lecoBuiltinCategories.recommended-from-coaching' />,
  [BuiltInPathCategories.PLAY_AGAIN]: <FormattedMessage id='lecoBuiltinCategories.done' />,
  [BuiltInPathCategories.ALSO_INTERESTING]: <FormattedMessage id='lecoBuiltinCategories.also-interesting' />,
  [BuiltInPathCategories.LINK]: <FormattedMessage id='lecoBuiltinCategories.link' />,
};

export const Lololeco: React.FC<Props> = ({ categories, onLecoPlay, onLecoInfoOpen, locale }) => {
  const orderedCategories = useSelector<Store, HomeStore['categories']>(({ home }) => home.categories);

  const {
    'new': newCategories,
    'doing': doingCategories,
    'done': doneCategories,
    'also-interesting': alsoInterestingCategories,
    'link': linkCategories,
    'recommended-from-coaching': coachingCategories,
    ...customCategories
  } = categories;

  const direction = languageTextDirections[locale];
  const isLTR = direction === 'ltr';

  return (
    <div
      data-testid='home-categories'
      className={classnames('absolute w-full h-full text-white mt-96 lg:pt-10', {
        'lg:pl-16': isLTR,
        'lg:pr-16': !isLTR,
      })}
    >
      <div
        className='p-4 lg:px-0 lg:pt-4'
        id='home-categories'
      >
        <LolecoRow
          title={BuiltinCategoryNames.doing}
          loleco={doingCategories}
          onLecoPlay={onLecoPlay}
          onLecoInfoOpen={onLecoInfoOpen}
        />
        <LolecoRow
          title={BuiltinCategoryNames.new}
          loleco={newCategories}
          onLecoPlay={onLecoInfoOpen}
          onLecoInfoOpen={onLecoInfoOpen}
        />
        <LolecoRow
          title={BuiltinCategoryNames['recommended-from-coaching']}
          loleco={coachingCategories}
          onLecoPlay={onLecoPlay}
          onLecoInfoOpen={onLecoInfoOpen}
        />
        {orderedCategories.map((category) => {
          const paths = customCategories[category];
          if (!Array.isArray(paths)) {
            return null;
          }
          return (
            <LolecoRow
              title={category}
              loleco={paths}
              key={category}
              onLecoPlay={onLecoInfoOpen}
              onLecoInfoOpen={onLecoInfoOpen}
            />
          );
        })}
        <LolecoRow
          title={BuiltinCategoryNames.link}
          loleco={linkCategories}
          onLecoPlay={onLecoInfoOpen}
          onLecoInfoOpen={onLecoInfoOpen}
        />
        <LolecoRow
          title={BuiltinCategoryNames['also-interesting']}
          loleco={alsoInterestingCategories}
          onLecoPlay={onLecoPlay}
        />
        <LolecoRow
          title={BuiltinCategoryNames.done}
          loleco={doneCategories}
          onLecoPlay={onLecoPlay}
          onLecoInfoOpen={onLecoInfoOpen}
        />
      </div>
    </div>
  );
};
