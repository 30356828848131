import { PREVIOUS_LOCATION_COOKIE } from 'constants/global';
import { actions } from 'containers/authentication';
import cookie from 'js-cookie';
import qs from 'qs';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Command } from 'util/types';
import { useQueryParams } from 'util/useQueryParams';
import idPortalMap from './id-portal-map.json';

enum Flows {
  PASSWORD = 'password',
  EXTERNAL = 'external',
  JWT = 'jwt',
  SELF_REGISTRATION_LINK = 'self-registration-link',
}

const publicUrl = new URL(process.env.PUBLIC_URL || `${window.location.protocol}//${window.location.host}`);

const ID_PORTAL_URL = process.env.REACT_APP_ID_PORTAL_URL as string;
const DEFAULT_FLOW = Flows.PASSWORD;
const REDIRECT_URI = new URL('/login', publicUrl).href;

interface Params {
  flow?: string;
  tenant?: string;
  idp?: string;
  token?: string;
  selfRegistrationLinkToken?: string;
  state?: string;
}

interface QueryParams {
  code?: string;
  error?: string;
  // eslint-disable-next-line camelcase
  redirect_to?: string;
  // eslint-disable-next-line camelcase
  self_registration_link_token?: string;
  state?: string;
}

interface GetUrl {
  (base: string, params: Params): URL;
}

interface GetIdLaunchUrl {
  (clientId?: string, sourceClientId?: string, loginHint?: string): URL;
}

export const getIdLaunchUrl: GetIdLaunchUrl = (clientId, sourceClientId, loginHint) => {
  if (!clientId || !sourceClientId || !loginHint) {
    throw new Error('A `clientId`, `sourceClientId` and `loginHint` must be provided');
  }
  const idLaunchQueryString = qs.stringify({ sourceClientId, loginHint });
  const url = new URL(`/launch/${clientId}?${idLaunchQueryString}`, ID_PORTAL_URL);
  return url;
};

const isKnownFlow = (flow: string): flow is Flows => {
  const flows = Object.values<string>(Flows);
  return flows.includes(flow);
};

/* eslint-disable camelcase */
const queryParams = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  scope: '*',
  response_type: 'code',
  redirect_uri: REDIRECT_URI,
};
/* eslint-enable camelcase */

const getPasswordUrlCommand: GetUrl = (base) => {
  const url = new URL(`/authorize?${qs.stringify(queryParams)}`, base);
  return url;
};

const getExternalUrlCommand: GetUrl = (base, { tenant, idp, selfRegistrationLinkToken, state }) => {
  if (!tenant || !idp) {
    throw new Error('A `tenant` and a `idp` must be passed as url parameters');
  }

  const query = {
    ...queryParams,
    // eslint-disable-next-line camelcase
    ...(selfRegistrationLinkToken && { self_registration_link_token: selfRegistrationLinkToken }),
    ...(state && { state }),
  };

  const url = new URL(`/external/${tenant}/${idp}?${qs.stringify(query)}`, base);
  return url;
};

const getJwtUrlCommand: GetUrl = () => {
  throw new Error('not implemented');
};

const getSelfRegistrationLinkCommand: GetUrl = (base, { token }) => {
  if (!token) {
    throw new Error('A `token` must be passed as url parameters');
  }

  const url = new URL(`/self-registration-link/${token}?${qs.stringify(queryParams)}`, base);
  return url;
};

const getUrl = (method: Flows, base: string, options: Params) => {
  const commands: Command<Flows, GetUrl> = {
    [Flows.PASSWORD]: getPasswordUrlCommand,
    [Flows.EXTERNAL]: getExternalUrlCommand,
    [Flows.JWT]: getJwtUrlCommand,
    [Flows.SELF_REGISTRATION_LINK]: getSelfRegistrationLinkCommand,
  };

  return commands[method](base, options);
};

const getLoginMethod = (pathname: string, flow?: string): Flows => {
  const selectedFlow = flow ?? DEFAULT_FLOW;
  if (!isKnownFlow(selectedFlow)) {
    throw new Error(`flow ${selectedFlow} is unknown`);
  }

  const isSelfRegistrationFlow = pathname.startsWith('/self-registration-link');
  if (isSelfRegistrationFlow) {
    return Flows.SELF_REGISTRATION_LINK;
  }

  return selectedFlow;
};

type AddressMapKeys = keyof typeof idPortalMap;

const isAddressInIdPortalMap = (address: string): address is AddressMapKeys =>
  Object.keys(idPortalMap).includes(address);

export const useIdPortal = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { flow, tenant, idp, token } = useParams<Params>();
  const {
    code,
    error,
    redirect_to: redirectTo,
    self_registration_link_token: selfRegistrationLinkToken,
    state,
  } = useQueryParams<QueryParams>();

  const getIdPortalBase = useCallback(
    (origin: string) => (isAddressInIdPortalMap(origin) ? idPortalMap[origin] : ID_PORTAL_URL),
    [],
  );

  useEffect(() => {
    if (code || error) {
      return;
    }

    const method = getLoginMethod(pathname, flow);
    const base = getIdPortalBase(window.location.origin);
    const url = getUrl(method, base, { tenant, idp, token, selfRegistrationLinkToken, state });

    const { href } = url;

    window.location.replace(href);
  }, [code, error, flow, idp, pathname, tenant, token, selfRegistrationLinkToken, state]);

  useEffect(() => {
    if (!code) {
      return;
    }
    dispatch(actions.exchangeCodeForAuthToken({ code }));

    if (redirectTo) {
      cookie.set(PREVIOUS_LOCATION_COOKIE, redirectTo);
    }
  }, [code, dispatch, redirectTo]);

  useEffect(() => {
    if (!error) {
      return;
    }

    const url = new URL('/error', ID_PORTAL_URL);
    url.searchParams.append('type', 'authorization');
    url.searchParams.append('client_id', process.env.REACT_APP_CLIENT_ID ?? '');
    window.location.replace(url.toString());
  }, [error]);
};
