import { FormatContentOption } from 'containers/FormatScreen/types';
import { useCallback, useEffect, useState } from 'react';
import { useSpring } from 'react-spring';
import { SwipeColumn, SwipeColumns, SwipeFormatProps } from './types';

const THRESHOLD = 80;

let initialSwipeValue = 0;
let initialSwipePosition = 0;

export const useSwipe = (
  leftGroupOptions: SwipeFormatProps['leftGroupOptions'],
  rightGroupOptions: SwipeFormatProps['rightGroupOptions'],
  initialOptions: FormatContentOption[],
  containerRef: React.RefObject<HTMLDivElement | null>,
  onUpdateGroups: SwipeFormatProps['onUpdateGroups'],
) => {
  const [animationStyles, api] = useSpring(() => ({ left: 0 }));

  const [containerCenter, setContainerCenter] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
  const [options, setOptions] = useState<FormatContentOption[]>(initialOptions);

  const fixLeftPosition = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      const rect = container.getBoundingClientRect();
      const center = rect.width / 2;
      setContainerCenter(center);
      api.set({ left: center });
    }
  }, [api, containerRef]);

  useEffect(() => {
    fixLeftPosition();
    window.addEventListener('resize', () => {
      fixLeftPosition();
    });
    return () => window.removeEventListener('resize', () => fixLeftPosition());
  }, [fixLeftPosition]);

  const LeftColumn: SwipeColumn = {
    switch: (optionID: string) => {
      const option = leftGroupOptions.find((opt) => opt.id === optionID);
      if (!option) {
        return;
      }

      const updatedColumn = leftGroupOptions.filter((opt) => opt.id !== optionID);
      onUpdateGroups(updatedColumn, [...rightGroupOptions, option]);
    },
    push: () => {
      const [removedOption, ...newOptions] = [...options];

      setOptions(newOptions);
      onUpdateGroups([...leftGroupOptions, removedOption], rightGroupOptions);
      api.set({ left: containerCenter });
    },
  };

  const RightColumn: SwipeColumn = {
    switch: (optionID: string) => {
      const option = rightGroupOptions.find((opt) => opt.id === optionID);
      if (!option) {
        return;
      }

      const updatedColumn = rightGroupOptions.filter((opt) => opt.id !== optionID);
      onUpdateGroups([...leftGroupOptions, option], updatedColumn);
    },
    push: () => {
      const [removedOption, ...newOptions] = [...options];

      setOptions(newOptions);
      onUpdateGroups(leftGroupOptions, [...rightGroupOptions, removedOption]);
      api.set({ left: containerCenter });
    },
  };

  const handleSwitchColumn = (from: SwipeColumns) => (id: string) => {
    const handler = {
      [SwipeColumns.LEFT]: LeftColumn.switch,
      [SwipeColumns.RIGHT]: RightColumn.switch,
    };

    return handler[from](id);
  };

  const enableTouchEvents = (event: TouchEvent | MouseEvent | any) => {
    // Touch start and move
    if (event.touches && event.touches.length) {
      if (typeof event.persist === 'function') {
        event.persist();
      }

      event.clientX = event.touches[0].clientX;
      event.clientY = event.touches[0].clientY;
    }

    // Touch end
    if (event.changedTouches && event.changedTouches.length) {
      if (typeof event.persist === 'function') {
        event.persist();
      }

      event.clientX = event.changedTouches[0].clientX;
      event.clientY = event.changedTouches[0].clientY;
    }
  };

  const swipeRight = () => {
    setIsSwiping(true);

    return api.start({
      left: containerCenter * 3,
      onRest: () => {
        setIsSwiping(false);
        RightColumn.push();
      },
    });
  };

  const swipeLeft = () => {
    setIsSwiping(true);

    return api.start({
      left: -containerCenter,
      onRest: () => {
        setIsSwiping(false);
        LeftColumn.push();
      },
    });
  };

  const addToColumn = (movement: number) => {
    const canMoveToLeftColumn = movement < -THRESHOLD;
    if (canMoveToLeftColumn) {
      return swipeLeft();
    }

    const canMoveToRightColumn = movement > THRESHOLD;
    if (canMoveToRightColumn) {
      return swipeRight();
    }

    return api.start({ left: containerCenter });
  };

  const handleSwipeMove = (event: TouchEvent | MouseEvent) => {
    enableTouchEvents(event);

    if (event instanceof MouseEvent) {
      const movement = event.clientX - initialSwipePosition;
      return api.set({ left: initialSwipeValue + movement });
    }

    const movement = event.touches[0].pageX - initialSwipePosition;
    return api.set({ left: initialSwipeValue + movement });
  };

  const handleSwipeStop = (event: TouchEvent | MouseEvent) => {
    enableTouchEvents(event);

    const currentPosition = animationStyles.left.get();
    const movement = currentPosition - containerCenter;
    addToColumn(movement);

    window.removeEventListener('mousemove', handleSwipeMove);
    window.removeEventListener('mouseup', handleSwipeStop);

    window.removeEventListener('touchmove', handleSwipeMove);
    window.removeEventListener('touchend', handleSwipeStop);
  };

  const handeSwipeStart = (event: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element>) => {
    enableTouchEvents(event);

    initialSwipeValue = animationStyles.left.get();

    const eventAsMouseEvent = event as unknown as MouseEvent;
    initialSwipePosition = eventAsMouseEvent.clientX;

    window.addEventListener('mousemove', handleSwipeMove);
    window.addEventListener('mouseup', handleSwipeStop);

    window.addEventListener('touchmove', handleSwipeMove);
    window.addEventListener('touchend', handleSwipeStop);
  };

  return {
    options,
    animationStyles,
    isSwiping,
    handeSwipeStart,
    handleSwitchColumn,
    addToColumn,
    swipeRight,
    swipeLeft,
  };
};
