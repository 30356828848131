import classnames from 'classnames';
import React from 'react';
import classes from './BaseButton.module.scss';
import { BaseButtonProps } from './BaseButton.types';

export const BaseButton: React.FC<BaseButtonProps> = ({
  className = '',
  variant = 'text',
  fullWidth = false,
  round = false,
  focusColor = 'currentColor',
  focus = false,
  style = {},
  withoutPadding = false,
  ...rest
}) => {
  const computedStyles: React.CSSProperties = {
    ...style,
    color: focus ? focusColor : style.color,
  };

  return (
    <button
      data-testid='e2e-button'
      className={classnames(
        'focus:outline-none text-sm',
        { 'p-3.5': !withoutPadding },
        classes.root,
        { [classes[variant]]: Boolean(classes[variant]) },
        { [classes.disabled]: rest.disabled },
        { [classes.fullWidth]: fullWidth },
        { [classes.round]: round },
        { [classes.focused]: focus },
        className,
      )}
      style={computedStyles}
      {...rest}
    />
  );
};
