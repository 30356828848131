import BrokenLightBulb from 'assets/images/broken-light-bulb.svg';
import { IconButton } from 'components/button';
import { PageContainer } from 'components/page-container';
import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { GraphQLError } from 'graphql';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'store/types';

export const NotFound: React.FC = () => {
  const history = useHistory();
  const { enqueueModal } = useModalQueue();
  const homeError = useSelector<Store, GraphQLError | Error | null | undefined>(({ home }) => home.error);
  const hasAccessToContent = !(
    homeError?.message.includes('ERR-40098') &&
    'path' in homeError &&
    homeError.path?.includes('users')
  );

  const handleRedirectToHome = () => {
    history.push('/');
  };

  if (!hasAccessToContent) {
    handleRedirectToHome();
    enqueueModal({ type: ModalTypes.NO_ACCESS_TO_CONTENT });
  }

  return (
    <PageContainer
      center
      className='h-full'
    >
      <div className='text-center text-gray-600'>
        <h3 className='m-0 mb-1'>Oops!</h3>
        <p className='m-0 mb-1'>
          <FormattedMessage id='not-found.title' />
        </p>
      </div>

      <img
        className='mt-14'
        src={BrokenLightBulb}
        alt='broken light bulb'
      />

      <IconButton
        variant='outlined'
        round
        icon='arrowBack'
        size={30}
        className='border-gray-600 mt-14'
        color='#4c4c4c'
        onClick={handleRedirectToHome}
      />
    </PageContainer>
  );
};
