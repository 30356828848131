import { PayloadAction } from '@reduxjs/toolkit';
import { API_URL, REST_API_URL } from 'constants/endpoints';
import { loader } from 'graphql.macro';
import cookie from 'js-cookie';
import { authenticationFlows } from 'pages/login/deprecated-login/authentication-flows';
import { Authentications } from 'pages/login/deprecated-login/types';
import qs from 'qs';
import { AnyAction } from 'redux';
import { StrictEffect, call, delay, put, race, select, takeLatest } from 'redux-saga/effects';
import { Action, Store } from 'store/types';
import getDefaultHeaders from 'util/getDefaultHeaders';
import request, { RequestResult } from 'util/request';
import requestGraphql, { GraphQLRequestResult } from 'util/requestGraphql';
import type { URLSearchParams } from 'util/useURLSearchParams';
import { XT_PROV_URL, actions } from './Authentication.ducks';
import {
  ExchangeCodeForAuthTokenPayload,
  GetUserPayload,
  LoginPayload,
  LogoutSuccessPayload,
  SetActiveAccountPayload,
  TokenLoginPayload,
  ValidatePreviewTokenPayload,
  ValidatePreviewTokenSuccessPayload,
} from './Authentication.types';

// GraphQL imports
const resetPasswordMutation = loader('./graphql/mutation/reset-password.graphql');
const setActiveAccountMutation = loader('./graphql/mutation/set-active-account.graphql');
const getUserInfoQuery = loader('./graphql/queries/get-user-info.graphql');

function* loginWorker({
  credentials,
  flow,
  params,
}: LoginPayload & AnyAction): Generator<StrictEffect | Generator<StrictEffect, void>, void> {
  if (!flow) {
    return;
  }

  const requestUrl = `${API_URL}/oauth2/token`;

  const authFlow = authenticationFlows[flow];

  const headers = getDefaultHeaders({ type: 'basic' });
  const body = qs.stringify(authFlow.getRequestBody(credentials, params));

  const { res, timeout } = (yield race({
    res: call(request, requestUrl, {
      method: 'POST',
      headers,
      body,
    }),
    timeout: delay(8000),
  })) as { res: any; timeout: boolean };

  if (timeout) {
    yield put(actions.loginError({ message: 'Request Timed out.', name: 'Timed out' }));
    return;
  }
  if (res.err) {
    yield put(actions.loginError(res.err));
    return;
  }

  switch (flow) {
    case Authentications.OAUTH2:
      yield authorizeOAuth2({ data: res.data, params });
      break;
    default:
      yield put(actions.loginSuccess(res.data));
  }
}

function* authorizeOAuth2({ params }: { data: any; params?: URLSearchParams }): Generator<StrictEffect, void> {
  if (!params) {
    throw new Error('Params not found');
  }
  const requestUrl = `${API_URL}/oauth2/code`;

  const headers = getDefaultHeaders();
  const body = JSON.stringify(params);

  const { res, timeout } = (yield race({
    res: call(request, requestUrl, {
      method: 'POST',
      headers,
      body,
    }),
    timeout: delay(8000),
  })) as { res: any; timeout: boolean };

  if (timeout) {
    yield put(actions.loginError({ message: 'Request Timed out.', name: 'Timed out' }));
    return;
  }
  if (res.err) {
    yield put(actions.loginError(res.err));
    return;
  }

  window.location = res.data.redirect_uri;
}

function* logoutWorker(): Generator<StrictEffect, void> {
  const state = (yield select()) as Store;
  const { sso } = state.auth;
  const ssoRedirectUri = cookie.get(XT_PROV_URL);
  const requestUrl = `${API_URL}/authentication/revoke`;
  const headers = (yield call(getDefaultHeaders)) as HeadersInit;

  const options: RequestInit = {
    method: 'POST',
    headers,
  };
  const response = (yield call(request, requestUrl, options)) as RequestResult<{ redirectUri: string }>;

  if (!response.err) {
    const { redirectUri } = response.data;
    const payload: LogoutSuccessPayload = { redirectUri, sso, ssoRedirectUri };
    yield put(actions.logoutSuccess(payload));
  } else {
    const { err } = response;
    const error = err instanceof Error ? err : new Error(String(err));
    yield put(actions.logoutError(error.message));
  }
}

function* validatePreviewTokenWorker({
  payload,
}: PayloadAction<ValidatePreviewTokenPayload>): Generator<StrictEffect, void> {
  try {
    const requestUrl = `${API_URL}/preview/${payload.token}`;
    const headers = { 'Content-Type': 'application/json' };

    const response = (yield call(request, requestUrl, {
      method: 'GET',
      headers,
    })) as { data: any; err: any };

    if (!response.data || !response.data.access_token) {
      yield put(actions.validatePreviewTokenError('Token response failed'));
      return;
    }

    const { role, activePathId, activePathType } = response.data as ValidatePreviewTokenSuccessPayload;

    yield put(actions.validatePreviewTokenSuccess({ role, activePathId, activePathType }));
  } catch (err) {
    const message = err instanceof Error ? err.message : String(err);
    yield put(actions.validatePreviewTokenError(message));
  }
}

function* tokenLoginWorker({ payload }: Action<TokenLoginPayload>): Generator<StrictEffect, void> {
  const { flow, token } = payload;

  try {
    const authenticationFlow = authenticationFlows[flow];

    if (!token) {
      return;
    }

    const requestUrl = authenticationFlow.getRequestUrl({ token });
    const headers = { 'Content-Type': 'application/json' };

    const response = (yield call(request, requestUrl, {
      method: 'GET',
      headers,
    })) as { data: any; err: any };

    if (!response.data || !response.data.access_token) {
      yield put(actions.tokenLoginError('Token response failed'));
      return;
    }

    yield put(
      actions.tokenLoginSuccess({
        role: response.data.role,
      }),
    );
  } catch (err) {
    const message = err instanceof Error ? err.message : String(err);
    yield put(actions.tokenLoginError(message));
  }
}

/** Reset password worker */
function* resetPasswordWorker({ token, password }: AnyAction): Generator<StrictEffect, void> {
  const body = {
    query: resetPasswordMutation,
    variables: { token, password },
  };

  const { data, errors } = (yield call(requestGraphql, body)) as GraphQLRequestResult;

  if (errors) {
    const message = errors instanceof Error ? errors.message : errors.errors[0].message;
    yield put(actions.resetPasswordError(message));
  } else {
    yield put(actions.resetPasswordSuccess(data));
  }
}

function* validateCustomLoginWorker({ payload: loginSlug }: PayloadAction<string>): Generator<StrictEffect, void> {
  try {
    const requestUrl = `${API_URL}/accounts/${String(loginSlug)}`;
    const headers = { 'Content-Type': 'application/json' };

    /* eslint-disable camelcase */
    interface ResponseBody {
      id: number;
      logo: string;
      splash_screen: string;
      theme: null;
      primary_color: string;
      sidebar_color: string;
      text_color: number;
      path_color: null;
      path_background: null;
      show_logo: number;
      externalIdProviders: {
        client_id: string;
        resource: string;
        scope: string;
        href_auth_code: string;
      }[];
      has_external_id_provider: boolean;
    }
    /* eslint-enable camelcase */

    const { data, err } = (yield call(request, requestUrl, {
      method: 'GET',
      headers,
    })) as { data: ResponseBody; err: Error };

    if (err) {
      yield put(actions.validateCustomLoginError(err));
    } else {
      yield put(
        actions.validateCustomLoginSuccess({
          hasExternalIdProvider: data.has_external_id_provider,
          accountID: data.id ? String(data.id) : undefined,
          accountLogo: data.logo,
          primaryColor: data.primary_color,
          sidebarColor: data.sidebar_color,
          splashScreen: data.splash_screen,
        }),
      );
    }
  } catch (err) {
    const error = err instanceof Error ? err : new Error(String(err));
    yield put(actions.validateCustomLoginError(error));
  }
}

/** Get user info worker */
function* getUserInfoWorker(): Generator<StrictEffect, void> {
  yield call(request, `${API_URL}/authentication/exchange`, {
    method: 'POST',
    body: '',
  });

  const body = {
    query: getUserInfoQuery,
  };

  const { errors, data } = (yield call(requestGraphql, body)) as GraphQLRequestResult;

  if (errors) {
    const error = errors instanceof Error ? errors : errors.errors[0];
    yield put(actions.getUserInfoError(error));
  } else {
    yield put(actions.getUserInfoSuccess(data));
  }
}

/** set active account worker */
function* setActiveAccountWorker({ accountID }: AnyAction & SetActiveAccountPayload): Generator<StrictEffect, void> {
  const body = {
    query: setActiveAccountMutation,
    variables: { accountID },
  };

  const { errors } = (yield call(requestGraphql, body)) as GraphQLRequestResult;

  if (errors) {
    const error = errors instanceof Error ? errors : errors.errors[0];
    yield put(actions.setActiveAccountError(error));
  } else {
    yield put(actions.setActiveAccountSuccess());
  }
}

function* getUserWorker({ payload }: PayloadAction<GetUserPayload>): Generator<StrictEffect, void> {
  try {
    const requestUrl = `${REST_API_URL}/users/${payload.userId}`;
    const headers = (yield call(getDefaultHeaders)) as HeadersInit;

    const { data } = (yield call(request, requestUrl, {
      method: 'GET',
      headers,
    })) as { data: any };

    yield put(actions.getUserSuccess(data));
  } catch (unknownError) {
    const error = unknownError instanceof Error ? unknownError : new Error(String(unknownError));
    yield put(actions.getUserError(error));
  }
}

function* exchangeCodeForAuthTokenWorker({
  payload,
}: PayloadAction<ExchangeCodeForAuthTokenPayload>): Generator<StrictEffect, void> {
  const { code } = payload;
  try {
    const requestUrl = `${API_URL}/authentication/token`;
    const params = new URLSearchParams();
    params.set('code', code);
    const body = params.toString();

    const { data } = (yield call(request, requestUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    })) as { data: any };

    yield put(actions.exchangeCodeForAuthTokenSuccess(data));
  } catch (unknownError) {
    const error = unknownError instanceof Error ? unknownError : new Error(String(unknownError));
    yield put(actions.exchangeCodeForAuthTokenError(error));
  }
}

export function* rootSaga() {
  yield takeLatest(actions.login.type, loginWorker);
  yield takeLatest([actions.logout.type], logoutWorker);
  yield takeLatest(actions.validatePreviewToken.type, validatePreviewTokenWorker);
  yield takeLatest(actions.tokenLogin.type, tokenLoginWorker);
  yield takeLatest(actions.resetPassword.type, resetPasswordWorker);
  yield takeLatest(actions.validateCustomLogin.type, validateCustomLoginWorker);
  yield takeLatest([actions.getUserInfo.type, actions.exchangeCodeForAuthTokenSuccess.type], getUserInfoWorker);
  yield takeLatest(actions.getUser.type, getUserWorker);
  yield takeLatest(actions.setActiveAccount.type, setActiveAccountWorker);
  yield takeLatest(actions.setActiveAccountSuccess.type, getUserInfoWorker);
  yield takeLatest(actions.exchangeCodeForAuthToken.type, exchangeCodeForAuthTokenWorker);
}
