import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useDraggable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import classnames from 'classnames';
import { Draggable } from 'components/Draggable';
import { Droppable } from 'components/Droppable';
import Question from 'components/Question';
import { ImageFormat, VideoFormat } from 'containers/FormatScreen/Formats';
import { FormatContentOption } from 'containers/FormatScreen/types';
import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { shouldShowWalkthrough, useWalkthrough } from 'containers/walkthrough';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isHTML } from 'util/isHTML';
import { DraggableItemProps, MultipleChoiceDragDropProps as Props } from './types';

const DraggableItem: React.FC<DraggableItemProps> = ({ className, id, label, showAnswers }) => {
  const { isDragging, setNodeRef, listeners } = useDraggable({
    id,
    disabled: showAnswers,
  });

  return (
    <Draggable
      className={className}
      isDragging={isDragging}
      forwardRef={setNodeRef}
      listeners={listeners}
      style={{
        opacity: isDragging ? 0 : undefined,
      }}
      label={label}
    />
  );
};

export const MultipleChoiceDragDrop: React.FC<Props> = ({
  showAnswers,
  correct,
  question,
  answers,
  imgSrc,
  video,
  onChange,
  focusColor,
}) => {
  const [activeId, setActiveId] = React.useState<string | null>(null);
  const [selectedAnswerId, setSelectedAnswerId] = React.useState<FormatContentOption['id']>('');
  const isRichText = isHTML(question);

  const { enqueueModal } = useModalQueue();
  const { completedWalkthroughs, locale } = useWalkthrough();

  React.useEffect(() => {
    const showWalkthrough = shouldShowWalkthrough(completedWalkthroughs, 'multiple-choice-drag-drop');
    if (!showWalkthrough) {
      return;
    }

    enqueueModal({
      type: ModalTypes.WALKTHROUGH,
      modal: {
        variant: 'multiple-choice-drag-drop',
        targets: ['body'],
        lang: locale,
      },
    });
  }, [completedWalkthroughs, locale, enqueueModal]);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 140,
      tolerance: 5,
    },
  });

  const sensors = useSensors(touchSensor, mouseSensor);

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const {
      active, // dragged item
      over, // dropzone
    } = event;

    if (!active.id) {
      return null;
    }

    const hasDraggedSelectedAnswer = active.id === selectedAnswerId;
    const hasDroppedOnDropzone = over?.id === 'dropzone';
    const hasSelectedAnswerDroppedInsideDropzone = hasDraggedSelectedAnswer && hasDroppedOnDropzone;
    const hasUnselectedAnswerDroppedOutsideDropzone = !hasDraggedSelectedAnswer && !hasDroppedOnDropzone;
    const shouldIgnoreAction = hasSelectedAnswerDroppedInsideDropzone || hasUnselectedAnswerDroppedOutsideDropzone;

    if (shouldIgnoreAction) {
      return null;
    }

    // Dropped a new answer into the dropzone
    if (!hasDraggedSelectedAnswer && hasDroppedOnDropzone) {
      return updateSelectedAnswer(active.id);
    }

    // Dropped the selected answer outside the dropzone
    if (hasDraggedSelectedAnswer && !hasDroppedOnDropzone) {
      return updateSelectedAnswer('');
    }

    return null;
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const updateSelectedAnswer = (answerId: string) => {
    onChange(answerId);
    setSelectedAnswerId(answerId);
  };

  const hasSelectedAnswer = Boolean(selectedAnswerId);

  const activeAnswerLabel = answers.find((answer) => answer.id === activeId)?.label;
  const selectedAnswerLabel = answers.find((answer) => answer.id === selectedAnswerId)?.label;
  const availableOptions = answers.filter((option) => option.id !== selectedAnswerId);

  const isCorrect = showAnswers && correct.includes(selectedAnswerId);
  const isIncorrect = showAnswers && !correct.includes(selectedAnswerId);

  const selectedAnswerStyle =
    hasSelectedAnswer && !showAnswers
      ? {
          borderColor: focusColor ?? '',
        }
      : {};

  return (
    <div className='w-full'>
      {video && (
        <div className='my-4'>
          <VideoFormat url={video} />
        </div>
      )}
      {imgSrc && !video && (
        <div className='my-4'>
          <ImageFormat
            imgSrc={imgSrc}
            alt={question}
          />
        </div>
      )}
      <Question
        text={question}
        rich={isRichText}
        scaleWithContent
      />
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <Droppable
          id={'dropzone'}
          className={classnames('relative my-8 mx-4 rounded-lg text-light font-light border-4 min-h-[7vh]', {
            'border-solid border-transparent': showAnswers,
            'border-dashed border-light flex justify-center items-center py-7 px-20':
              !hasSelectedAnswer && !showAnswers,
            'border-solid border-turquoiseBlue': hasSelectedAnswer && !showAnswers,
          })}
          style={selectedAnswerStyle}
        >
          {!hasSelectedAnswer ? (
            <FormattedMessage id='formats.multiplechoice.alternative.dropzone' />
          ) : (
            <DraggableItem
              className={classnames(
                { 'bg-fullRed text-white border-4 border-solid border-fullRed': isIncorrect },
                { 'bg-yellowGreen text-white border-4 border-solid border-yellowGreen': isCorrect },
              )}
              id={selectedAnswerId}
              label={selectedAnswerLabel}
              showAnswers={showAnswers}
            />
          )}
        </Droppable>
        <div className='relative flex flex-col mx-4 my-0'>
          <FormattedMessage id='formats.multiplechoice.alternative.text' />
          <div>
            {availableOptions.map((answer) => {
              const isMissingAnswer = correct.includes(answer.id);
              return (
                <div
                  className='mx-auto my-3'
                  key={answer.id}
                >
                  <DraggableItem
                    className={classnames({ 'border-4 border-dashed border-yellowGreen': isMissingAnswer })}
                    id={answer.id}
                    label={answer.label}
                    showAnswers={showAnswers}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <DragOverlay>
          {activeId && (
            <Draggable
              className='bg-red'
              isDragging
              hasDragOverlay
              label={activeAnswerLabel}
            />
          )}
        </DragOverlay>
      </DndContext>
    </div>
  );
};
