import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CrashCourseStore,
  GetCrashCourseRelatedInformationPayload,
  GetCrashCourseRelatedInformationSuccess,
} from './CrashCourse.types';

const initialState: CrashCourseStore = {
  moduleCreditReached: true,
  showLogo: true,
  error: null,
  isLoading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'crashCourse',
  initialState,
  reducers: {
    getCrashCourseRelatedInformation: (state, _action: PayloadAction<GetCrashCourseRelatedInformationPayload>) => {
      state.error = null;
      state.isLoading = true;
      state.loaded = false;
    },
    getCrashCourseRelatedInformationSuccess: (
      state,
      action: PayloadAction<GetCrashCourseRelatedInformationSuccess>,
    ) => {
      state.moduleCreditReached = action.payload.moduleCreditReached;
      state.showLogo = action.payload.showLogo;
      state.isLoading = false;
      state.loaded = true;
    },
    getCrashCourseRelatedInformationError: (state, action: PayloadAction<Error>) => {
      state.error = action.payload.message || 'failed to load';
      state.isLoading = false;
      state.loaded = true;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;
