import classNames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ChatFormProps as Props } from './ChatForm.types';

export const ChatForm: React.FC<Props> = ({ isLoading, onSubmit }) => {
  const MAXIMUM_CHARACTERS = 750;

  const intl = useIntl();

  const [question, setQuestion] = useState('');
  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const isSubmitEnabled = !isLoading && question.trim() && question.length <= MAXIMUM_CHARACTERS;

  const adjustInputHeight = () => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.style.height = 'auto';
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
  };

  const handleQuestionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentQuestion = event.target.value.substring(0, MAXIMUM_CHARACTERS);

    setQuestion(currentQuestion);
    adjustInputHeight();
  };

  const resetInputHeightAndFocus = () => {
    if (!inputRef.current) {
      return;
    }

    const leadingRelaxed = 1.625;
    const fontSize = 16;
    const fontHeight = fontSize * leadingRelaxed;
    inputRef.current.style.height = `${fontHeight}px`;
    inputRef.current.focus();
  };

  const submitQuestion = () => {
    onSubmit(question);
    setQuestion('');
    resetInputHeightAndFocus();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isSubmitEnabled) {
      return;
    }

    submitQuestion();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const shouldSubmitQuestion = event.key === 'Enter' && !event.shiftKey && isSubmitEnabled;

    if (!shouldSubmitQuestion) {
      return;
    }

    event.preventDefault();
    submitQuestion();
  };

  return (
    <>
      <form
        className='flex items-center justify-center w-full py-2 my-1.5 bg-white rounded-lg filter drop-shadow-lg'
        onSubmit={handleSubmit}
      >
        <textarea
          ref={inputRef}
          value={question}
          onChange={handleQuestionChange}
          onKeyPress={handleKeyPress}
          className='w-full h-auto px-4 overflow-auto leading-relaxed rounded-lg outline-none resize-none placeholder-stone-500 text-neutral-800 max-h-32'
          placeholder={intl.formatMessage({ id: 'mobie.chat.placeholder' })}
          rows={1}
        />
        <span className='border-l-2 h-9/10'></span>
        <button className='flex items-end justify-center w-12 h-full py-2 border-gray-200'>
          <SvgIcon
            name='sendMessage'
            className={isSubmitEnabled ? 'text-gray-600' : 'text-gray-200'}
            size={19}
            viewBox='0 0 19 16'
          />
        </button>
      </form>
      <span
        className={classNames('text-xs text-right sm:mb-6 mb-1', {
          'text-red-600': question.length === MAXIMUM_CHARACTERS,
          'text-stone-500': question.length < MAXIMUM_CHARACTERS,
        })}
      >{`${question.length}/${MAXIMUM_CHARACTERS}`}</span>
    </>
  );
};
