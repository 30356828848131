import { animated, config, useSpring } from 'react-spring';

export const Smile = () => {
  const { tongue: tongueStyles } = useSpring({
    loop: true,
    from: { tongue: 0 },
    to: { tongue: 1 },
    config: config.gentle,
  });

  const eyeStyles = useSpring({
    loop: true,
    from: { transform: 'translateY(-8px) scale(1)' },
    to: { transform: 'translateY(0) scale(1)' },
    config: config.molasses,
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='92'
      height='92'
      fill='none'
      viewBox='0 0 92 92'
    >
      <circle
        cx='45.748'
        cy='45.748'
        r='45.748'
        fill='#FFD23B'
      ></circle>
      <path
        fill='#FFB24A'
        d='M45.749 91.497c25.266 0 45.748-20.482 45.748-45.748C91.497 20.482 71.015 0 45.75 0v91.497z'
      ></path>
      <path
        fill='#962B7C'
        d='M18.764 45.748c0 14.805 12.041 26.806 26.895 26.806s26.895-12.002 26.895-26.806h-53.79z'
      ></path>
      <path
        fill='#C22C7B'
        d='M18.764 45.748c0 14.805 12.042 26.806 26.896 26.806V45.748H18.764z'
      ></path>
      <animated.g style={eyeStyles}>
        <path
          fill='#2D3E53'
          fillRule='evenodd'
          d='M29.486 32.435a2.591 2.591 0 10-5.183 0v5.45h-5.54v-5.45a8.131 8.131 0 1116.263 0v5.45h-5.54v-5.45z'
          clipRule='evenodd'
        ></path>
        <path
          fill='#000'
          fillRule='evenodd'
          d='M67.014 32.435a2.591 2.591 0 00-5.182 0v5.45h-5.54v-5.45a8.131 8.131 0 0116.262 0v5.45h-5.54v-5.45z'
          clipRule='evenodd'
        ></path>
      </animated.g>
      <animated.path
        fill='#FF3187'
        d={tongueStyles.to({
          range: [0, 1],
          output: [
            'M45.6591 59.3311C35.0083 59.3311 27.5801 59.9267 25.1974 63.0839C27.5503 66.241 34.9368 72.5552 45.6591 72.5552C56.3814 72.5552 63.8871 66.241 66.2996 63.0839C63.8573 59.9267 56.31 59.3311 45.6591 59.3311Z',
            'M45.6591 53.6104C35.0083 53.6104 27.5801 59.9246 25.1974 63.0817C27.5503 66.2389 34.9368 72.5531 45.6591 72.5531C56.3814 72.5531 63.8871 66.2389 66.2996 63.0817C63.8573 59.9246 56.31 53.6104 45.6591 53.6104Z" fill="#FF3187',
          ],
        })}
      ></animated.path>
      <animated.path
        fill='#FF5178'
        d={tongueStyles.to({
          range: [0, 1],
          output: [
            'M45.6591 59.3311C35.0083 59.3311 27.5801 59.9267 25.1974 63.0839C27.5503 66.241 34.9368 72.5552 45.6591 72.5552V59.3311Z',
            'M45.6591 53.6104C35.0083 53.6104 27.5801 59.9246 25.1974 63.0817C27.5503 66.2389 34.9368 72.5531 45.6591 72.5531V53.6104Z',
          ],
        })}
      ></animated.path>
    </svg>
  );
};
