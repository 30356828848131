import { rootRestApi } from 'store/root.api';
import {
  MobieMessageRequestPayload,
  MobieMessageResponse,
  MobieWelcomeMessageRequestPayload,
  MobieWelcomeMessageResponse,
} from './MobieChatPopup.types';

export const mobieChatApi = rootRestApi.injectEndpoints({
  endpoints: (builder) => ({
    mobieMessage: builder.mutation<MobieMessageResponse, MobieMessageRequestPayload>({
      query: ({ accountId, body }) => ({
        url: `accounts/${accountId}/chatbot/send-message`,
        method: 'POST',
        body,
      }),
    }),
    mobieWelcomeMessage: builder.query<MobieWelcomeMessageResponse, MobieWelcomeMessageRequestPayload>({
      query: ({ accountId }) => `accounts/${accountId}/chatbot/welcome-message`,
    }),
  }),
});

export const { useMobieMessageMutation, useMobieWelcomeMessageQuery } = mobieChatApi;
