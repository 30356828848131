import { IconButton } from 'components/button';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ChannelList, useChatContext } from 'stream-chat-react';
import { PageContainer } from '../../../components/page-container';
import { RootState } from '../../../store';
import { ChatPreview } from './ChatPreview';
import { NoChannels } from './NoChannels';

export const ChatList = () => {
  const { user, account } = useSelector((state: RootState) => state.profile);
  const { client } = useChatContext();
  const history = useHistory();

  const { primaryColor } = account;

  if (!user?.id || !client) {
    return null;
  }

  const handleClickNewChat = () => {
    history.push('/chat/new');
  };

  if (!client) {
    return null;
  }

  return (
    <PageContainer>
      <div className='mt-8'>
        <ChannelList
          filters={{
            type: 'messaging',
            members: { $in: [user.id] },
          }}
          sort={{
            // eslint-disable-next-line camelcase
            last_message_at: -1,
          }}
          options={{ state: true, presence: true, watch: true }}
          EmptyStateIndicator={NoChannels}
          Preview={ChatPreview}
        />
      </div>
      <IconButton
        variant='outlined'
        icon='message-plus-outline'
        color={primaryColor}
        size='0.75rem'
        onClick={handleClickNewChat}
        style={{ borderColor: primaryColor }}
        viewBox='5 4 13 13'
        className='fixed bottom-0 right-0 mb-20 mr-5 bg-white rounded-full shadow-xl'
      />
    </PageContainer>
  );
};
