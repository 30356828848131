import padlockImg from 'assets/images/padlock.svg';
import { TextButton as Button } from 'components/button';
import { Popup } from 'components/popup';
import { LepaTypes, PathLockedPopupComponentProps as Props } from 'pages/home/Home.types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

export const PathLockedPopup: React.FC<Props> = ({ onModalClose, open, paths }) => {
  const history = useHistory();
  const intl = useIntl();

  const handleLockedPath = (id: number, type: LepaTypes) => () => {
    onModalClose();
    const path = {
      [LepaTypes.CRASH_COURSE]: `/crash-course/${id}`,
      [LepaTypes.LEGACY]: `/learning-path/${id}`,
      [LepaTypes.VIDEO]: `/video/${id}`,
      [LepaTypes.TEXT]: `/learning-moment/${id}`,
      [LepaTypes.QUESTIONS_ONLY]: `/learning-moment/${id}`,
      [LepaTypes.SINGLE_LEARNING]: `/learning-moment/${id}`,
    }[type];
    history.push(path);
  };

  if (paths.length === 0) {
    return null;
  }

  const lockedByPaths = paths.map(({ id, type, title }) => (
    <Button
      key={id}
      type='button'
      variant='outlined'
      onClick={handleLockedPath(id, type)}
    >
      {title}
    </Button>
  ));

  return (
    <Popup
      icon={padlockImg}
      variant='faded'
      open={open}
      title={intl.formatMessage({ id: 'home.path-locked-popup.title' })}
      onRequestClose={onModalClose}
      closeIcon
    >
      <>
        <div className='p-4 text-sm font-light text-center whitespace-pre-wrap'>
          <h2>
            <FormattedMessage id='home.path-locked-popup.subtitle' />
          </h2>
        </div>
        {lockedByPaths}
      </>
    </Popup>
  );
};
