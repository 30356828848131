import classnames from 'classnames';
import { Media } from 'components/media';
import { SvgIcon } from 'components/svg-icon';
import { API_URL } from 'constants/endpoints';
import React from 'react';
import { animated } from 'react-spring';
import theme from 'theme';
import { getSrcset } from 'util/image';
import { SwipeableCardProps as Props } from '../types';

const SwipeableCard: React.FC<Props> = ({
  data,
  className,
  children,
  showFeedback,
  isCorrect,
  onSwipeStart,
  ...rest
}) => {
  const havePictureToDisplay = data.imageUrl && data.imageUrl !== '';
  const showImageFeedback = showFeedback && havePictureToDisplay;
  const showTextFeedback = showFeedback && !havePictureToDisplay;

  if (!onSwipeStart) {
    return (
      <div
        className={classnames(
          'flex items-center justify-center w-full text-left cursor-pointer text-xs',
          'overflow-hidden select-none mt-2 break-words rounded-xl shadow-lg',
          className,
          { 'bg-gray-100 text-greyishBrown': !showTextFeedback },
          { 'p-4': !havePictureToDisplay },
          { 'bg-yellowGreen text-white': showTextFeedback && isCorrect },
          { 'bg-fullRed text-white': showTextFeedback && !isCorrect },
        )}
        {...rest}
      >
        {havePictureToDisplay ? (
          <Media
            alt='format'
            className={classnames(
              'bg-cover rounded-xl',
              { 'border-4 border-solid border-yellowGreen': showImageFeedback && isCorrect },
              { 'border-4 border-solid border-fullRed': showImageFeedback && !isCorrect },
            )}
            src={`${API_URL}/${data.imageUrl}`}
            style={{ minHeight: '3.5rem' }}
            sizes='(max-width: 768px) 50vw, 20vw'
            srcSet={getSrcset(`${API_URL}/${data.imageUrl}`, {
              sizes: [
                [1520, '1120w'],
                [1080, '720w'],
                [720, '420w'],
                [420, '320w'],
              ],
            })}
          />
        ) : (
          <span className='text-center'>{data.label}</span>
        )}
        {showFeedback && (
          <div
            className={classnames(
              {
                'absolute w-14 h-14 rounded-full flex justify-center items-center text-white bg-yellowGreen bg-opacity-90 m-auto':
                  havePictureToDisplay && isCorrect,
              },
              {
                'absolute w-14 h-14 rounded-full flex justify-center items-center text-white bg-fullRed bg-opacity-90 m-auto':
                  havePictureToDisplay && !isCorrect,
              },
            )}
          >
            <SvgIcon
              name={isCorrect ? 'check' : 'close'}
              size={havePictureToDisplay ? 48 : 24}
              color={theme.colors.white}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <animated.div
      id='swipeable-cards'
      onMouseDown={onSwipeStart}
      onTouchStart={onSwipeStart}
      className={classnames(
        'absolute flex items-center justify-center cursor-pointer text-base font-medium overflow-hidden',
        'left-5/10 rounded-xl bg-gray-100 text-greyishBrown shadow-lg -translate-x-1/2',
        className,
        {
          'top-1/7 3xs:h-48 2xs:h-64 h-80 3xs:w-48 2xs:w-64 w-80': havePictureToDisplay,
          'top-2/10 w-6/10 h-40': !havePictureToDisplay,
        },
      )}
      {...rest}
    >
      {havePictureToDisplay ? (
        <Media
          alt='format'
          className='w-full h-full bg-cover'
          src={`${API_URL}/${data.imageUrl}`}
          sizes='(max-width: 768px) 50vw, 20vw'
          srcSet={getSrcset(`${API_URL}/${data.imageUrl}`, {
            sizes: [
              [1520, '1120w'],
              [1080, '720w'],
              [720, '420w'],
              [420, '320w'],
            ],
          })}
        />
      ) : (
        <span className='text-center my-[6vh]'>{data.label}</span>
      )}
      {children}
    </animated.div>
  );
};

export default SwipeableCard;
