import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { PopupType } from 'components/popup';
import { Format as FormatData, Level as LevelData, TipData, TipsTricks } from 'containers/FormatScreen/types';
import { Locale } from 'containers/language-provider';
import {
  DefaultModalProps,
  EnqueueModalPayload,
  ModalsStore,
  ModalTypes,
} from 'containers/modal-controller/ModalController.types';
import { ProfileStore } from 'containers/Profile/types';
import { CompleteWalkthroughRequestPayload } from 'containers/walkthrough';
import { Leco, LepaTypes } from 'pages/home/Home.types';
import { RewardsModalData } from 'pages/skill/modals/rewards-modal/RewardsModal.types';
import { LearningFormat } from 'pages/tests';
import { GetRetentionTestPayload, LearningTestFormatsResponse } from 'pages/tests/Tests.types';
import { CSSProperties } from 'react';
import { WrappedComponentProps } from 'react-intl';
import { RouteChildrenProps } from 'react-router-dom';
import { WithCompletedWalkthroughs } from 'util/withCompletedWalkthroughs';

export enum TestTypes {
  SKILL_TEST = 'skillTest',
  LEVEL_TEST = 'levelTest',
  CERTIFICATION_TEST = 'certificationTest',
  RETENTION_TEST = 'retentionTest',
  MODULE_TEST = 'moduleTest',
  UNLOCKED_FINAL_TEST = 'unlockedFinalTest',
}

/** skills store */
export interface ActiveLearningPathStore {
  id: Leco['id'];
  slug: Leco['title'];
  currentLevelName: string;
  currentLevelIndex: number;
  moduleCreditReached: boolean;
  feedierURL: string;
  levels: LevelData[];
  error: string | null;
  type: LepaTypes | null;
  showLogo: boolean;
  skippedRetentionTest: boolean;
  color: string;
  isCertification: boolean;
  certificateId: string;
  certificationState: CertificationStates;
  splashScreen: string | null;
  pathBackground: string | null;
  locales: LocaleInformation[];
  locale: Locale | null;
  openedTipsTricks: number[];
}

interface LocaleInformation {
  slug: Locale;
  enabled: boolean;
  default: boolean;
}

interface Params {
  pathID?: string;
  levelId?: string;
  tip?: string;
}

interface HistoryState {
  levelId?: string;
  tip?: TipData | null;
}

interface LearningPathLevelTestInformation {
  levelId: string;
  learningTestId: string | null;
  done: boolean;
  passed: boolean;
}

interface LearningPathSkillTestInformation {
  skillId: string;
  learningTestId: string | null;
}

export type ActivePathLepaProps = Pick<
  ActiveLearningPathStore,
  'id' | 'slug' | 'type' | 'color' | 'levels' | 'locale' | 'locales' | 'error' | 'currentLevelIndex'
>;

export interface LepaProps
  extends RouteChildrenProps<Params, HistoryState>,
    WrappedComponentProps,
    WithCompletedWalkthroughs {
  /** @deprecated - use learningPath */
  activePath: ActivePathLepaProps | null;
  learningPath: Lepa | undefined;
  learningPathCache: StartLepaPayload | undefined;
  learningPathError: FetchBaseQueryError | SerializedError | undefined;
  profile: ProfileStore;
  accountLogo: string;
  themeBg: string;
  showLogo: boolean;
  skippedRetentionTest: boolean;
  locale: Locale;
  isLoadingRetentionTest: boolean;
  isPersonalityTestEnabled: boolean;
  modals: ModalsStore;
  retentionTest?: LearningTestFormatsResponse | null;
  isLoadingLearningPath: boolean;
  levelTests: LearningPathLevelTestInformation[];
  skillTests: LearningPathSkillTestInformation[];

  completeWalkthrough: (payload: CompleteWalkthroughRequestPayload) => Promise<void>;
  enqueueModal: (payload: EnqueueModalPayload) => void;
  getRetentionTest: (payload: GetRetentionTestPayload) => void;
  getLearningPath: (payload: StartLepaPayload) => void;
}

export interface LepaState {
  size: {
    width: number;
    height: number;
  };
  levels: LevelData[];
  levelTestAvailable: boolean;
  levelTestID: string;
  showWalkthrough: boolean;
}

export type TrackTipPayload = {
  id: TipsTricks['id'];
  levelIdx: number;
  tipIdx: number;
};

export type TrackTipSuccessPayload = {
  id: string;
  tracked: boolean;
};

export type GetLevelTestPayload = {
  levelID: LevelData['id'];
};

export enum CertificationStates {
  idle = 'idle',
  creating = 'creating',
  created = 'created',
  createFailed = 'createFailed',
  sending = 'sending',
  sent = 'sent',
  sendFailed = 'sendFailed',
}

export type CreateCertificatePayload = {
  pathId: string;
};

export type CreateCertificateSuccessPayload = {
  id: string;
};

export type SendCertificatePayload = {
  id: string;
  email: string;
};

export interface DefaultPopupProps extends WrappedComponentProps {
  onClose: (...args: any) => void;
}

export type PathEndModalData = {
  type: ModalTypes.PATH_END;
  modal: {
    brandColor: string;
    hidePlayMoreInfo?: boolean;
    onClose: () => void;
  };
};

export interface PathEndModalProps extends DefaultModalProps, PathEndModalData, WrappedComponentProps {
  brandColor?: string;
}

export type RetentionTestData = {
  type: ModalTypes.RETENTION_QUIZ;
  modal: {
    id: PopupType['id'];
    title: PopupType['title'];
    buttonText: PopupType['buttonText'];
    closeIcon: PopupType['closeIcon'];
    summary: PopupType['summary'];
  };
};

export type TipModalData = {
  type: ModalTypes.TIP;
  modal: PopupType;
};

export interface RetentionPopupProps extends DefaultModalProps, RetentionTestData, WrappedComponentProps {}

export interface TipModalProps extends DefaultModalProps, TipModalData, WrappedComponentProps {}

export type LevelPassedModalData = {
  type: ModalTypes.LEVEL_PASSED;
  modal: {
    title: PopupType['title'];
    buttonText: PopupType['buttonText'];
    closeIcon?: PopupType['closeIcon'];
    levelTestTitle: PopupType['levelTestTitle'];
    summary: PopupType['summary'];
  };
};

export interface LevelPassedModalProps extends DefaultModalProps, LevelPassedModalData, WrappedComponentProps {}

export interface UnlockedFinalTestModalProps extends DefaultModalProps, RewardsModalData {}

export type CertificationPassedModalData = {
  type: ModalTypes.CERTIFICATION_PASSED;
  modal: {
    pathName: PopupType['pathName'];
    pathId: string;
  };
};

export interface CertificationPassedModalProps
  extends DefaultModalProps,
    CertificationPassedModalData,
    WrappedComponentProps {}

export type CertificationShareModalData = {
  type: ModalTypes.CERTIFICATION_SHARE;
  modal: {
    pathName: PopupType['pathName'];
    closeIcon?: PopupType['closeIcon'];
    date: string;
    color?: string;
  };
};

export interface CertificationShareModalProps
  extends DefaultModalProps,
    CertificationShareModalData,
    WrappedComponentProps {}

export interface RatingModalData {
  modal: {
    pathId: string;
    pathName: PopupType['pathName'];
    onSubmitCallback: () => void;
    onClose: () => void;
  };
  type: ModalTypes.LEPA_RATING;
}

export interface RatingModalProps extends DefaultModalProps, RatingModalData {}

export type RatingFeedbackModalData = {
  type: ModalTypes.RATING_FEEDBACK;
};

export interface RatingFeedbackModalProps extends RatingFeedbackModalData, DefaultModalProps {}

export interface NoAccessToContentModalData {
  type: ModalTypes.NO_ACCESS_TO_CONTENT;
}

export interface NoAccessToContentModalProps extends DefaultModalProps, NoAccessToContentModalData {}

export type SocialNetworkShareDetails = {
  url: string;
  title: string;
  hashtags: string[];
  source: string;
  relations: string[];
};

export type SocialNetworkShareProps = {
  shareDetails: SocialNetworkShareDetails;
  iconColor: CSSProperties['color'];
  iconSize: number;
};

export interface LoadLevelsSuccessPayload {
  levels: LevelData[];
}

export interface CreateRatingRequestPayloadBody {
  rating: number;
  comment?: string;
}

export interface CreateRatingRequestPayload {
  lepaId: string;
  body: CreateRatingRequestPayloadBody;
}

export interface CreateRatingResponse {
  id: number;
  userId: number;
  learningPathId: number;
  createdAt: string;
  rating: number;
  comment: string;
}

export interface StartLepaPayload {
  userId: number;
  lepaId: number;
  locale: Locale;
}

export interface Progress {
  done: boolean;
  learningTestId: string | null;
  passed: boolean;
  testScore: number;
  unlocked: boolean;
  unlockedAt: string;
}

interface BaseLepa {
  id: number;
  title: string;
  description: string;
  color: string | null;
  isCertification: boolean;
  objectives: string | null;
  estimatedTimeToComplete: number | null;
  splashScreen: string | null;
  pathBackground: string | null;
}

export type Lepa = LegacyLepa | CrashCourseLepa | VideoLepa | TextLepa | SingleLearningLepa | QuestionsOnlyLepa;

export interface LegacyLepa extends BaseLepa {
  type: LepaTypes.LEGACY;
  content: LegacyContent;
}

export interface CrashCourseLepa extends BaseLepa {
  type: LepaTypes.CRASH_COURSE;
  content: CrashCourseContent;
}

export interface VideoLepa extends BaseLepa {
  type: LepaTypes.VIDEO;
  content: LemoContent;
}

export interface TextLepa extends BaseLepa {
  type: LepaTypes.TEXT;
  content: LemoContent;
}

export interface SingleLearningLepa extends BaseLepa {
  type: LepaTypes.SINGLE_LEARNING;
  content: LemoContent;
}

export interface QuestionsOnlyLepa extends BaseLepa {
  type: LepaTypes.QUESTIONS_ONLY;
  content: LemoContent;
}

export interface LegacyContent {
  levels: Level[];
}

export interface CrashCourseContent {
  progress: Progress;
  modules: Module[];
  formats: FormatData[];
}

export interface LemoContent {
  progress: Progress;
  formats: LearningFormat[];
}

interface TipTrick {
  id: number;
  tags: string[] | null;
  order: number;
  media: string | null;
  mediaType: string | null;
  title: string;
  summary: string;
  opened: boolean;
}

export interface Level {
  id: number;
  title: string;
  locale: Locale;
  progress: Progress;
  skills: Skill[];
  tipsTricks: TipTrick[];
  color: string;
}

export interface Skill {
  id: number;
  title: string;
  summary: string;
  tags: string[];
  progress: Progress;
  modules: Module[];
}

interface ModuleProgress extends Omit<Progress, 'learningTestId' | 'testScore'> {
  percentage: string;
}

export interface Module {
  id: number;
  title: string;
  progress: ModuleProgress;
}

export type FormatTypeOptions =
  | 'text'
  | 'video'
  | 'drag_drop'
  | 'multiple_choice'
  | 'multiple_choice_drag_drop'
  | 'multiple_answer'
  | 'multiple_answer_swipe'
  | 'yes_no'
  | 'ranking_quiz'
  | 'multiple_image_choice'
  | 'multiple_image_answer'
  | 'true_false'
  | 'hotspot'
  | null;

export interface FormatType {
  id: number;
  title: FormatTypeOptions;
  createdAt: string;
  updatedAt: string;
}

export interface FormatResponse {
  id: number;
  order: number;
  imageUrl: string;
  metadata: {
    x: string;
    y: string;
  };
  title: string;
  isCorrect: boolean;
}
