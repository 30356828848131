import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Store } from 'store/types';
import Profile from './Profile';

const mapStateToProps = ({ profile }: Store) => ({
  profile,
});

export * from './Profile.ducks';
export * from './types';
export default injectIntl(connect(mapStateToProps)(Profile));
