import { SKILLFLIX_FILTERS_SESSION_STORAGE_ITEM } from 'constants/global';
import qs from 'qs';
import { useURLSearchParams } from './useURLSearchParams';

export enum TypeFilters {
  LEARNING_PATHS = 'learning-paths',
  CRASH_COURSES = 'crash-courses',
  EXTERNAL_CONTENT = 'external-content',
  EVENTS = 'events',
  SINGLE_LEARNING = 'single-learning',
  INFORMATIONAL_STEPS = 'informational-steps',
  SINGLE_VIDEO = 'single-video',
  QUESTIONNAIRE = 'questionnaire',
}

export const getFiltersFromStorage = () => sessionStorage.getItem(SKILLFLIX_FILTERS_SESSION_STORAGE_ITEM);

export const saveFiltersOnStorage = (value: string) =>
  sessionStorage.setItem(SKILLFLIX_FILTERS_SESSION_STORAGE_ITEM, value);

export const removeFiltersFromStorage = () => sessionStorage.removeItem(SKILLFLIX_FILTERS_SESSION_STORAGE_ITEM);

export interface Filters {
  categories: number[];
  isCertified: boolean;
  types: TypeFilters[];
}

interface FiltersQueryParams {
  categories?: string;
  certified?: string;
  types?: string;
}

type WithCounter<T> = T & {
  count: number;
};

const filtersTypesAllowed: string[] = Object.values(TypeFilters);

const isKnownType = (type: string): type is TypeFilters => filtersTypesAllowed.includes(type);

const getFilters = ({ categories, certified, types }: FiltersQueryParams): Filters => {
  const filteredCategories = (categories?.split(',') ?? []).map((category) => parseInt(category, 10));
  const sanitizedCategories = filteredCategories.slice(0, 1); // only working with one category for now
  const sanitizedTypes = (types?.split(',') ?? []).filter(isKnownType);

  return {
    categories: sanitizedCategories,
    isCertified: certified === 'true',
    types: sanitizedTypes,
  };
};

export const getFiltersCount = ({ categories, isCertified, types }: Filters): number => {
  const categoriesCount = categories.length;
  const certifiedCount = +isCertified;
  const typesCount = types.length;

  return categoriesCount + certifiedCount + typesCount;
};

export const getFiltersQueryParams = ({ categories, isCertified, types }: Filters): string =>
  qs.stringify({
    ...(categories.length > 0 && { categories: categories.join(',') }),
    ...(isCertified && { certified: isCertified }),
    ...(types.length > 0 && { types: types.join(',') }),
  });

export const useFiltersQueryParams = (): WithCounter<Filters> => {
  const params = useURLSearchParams<keyof FiltersQueryParams>();
  const { categories, isCertified, types } = getFilters(params);
  const count = getFiltersCount({ categories, isCertified, types });

  return {
    categories,
    isCertified,
    types,
    count,
  };
};
