import { IconButton } from 'components/button';
import { Popup } from 'components/popup';
import React from 'react';
import { CollectionPopupProps } from './CollectionPopup.types';

export const CollectionPopup: React.FC<CollectionPopupProps> = ({
  collection,
  onPositiveSubmit,
  onNegativeSubmit,
  disablePositiveSubmit = false,
  ...restProps
}) => {
  if (!collection) {
    return <div />;
  }

  return (
    <Popup
      {...restProps}
      closeIcon={false}
      variant='clean'
      stylePopup={{ width: '22rem', height: '28rem', padding: '1rem 0.5rem' }}
      stylePopupContent={{ justifyContent: 'space-between', height: '100%' }}
    >
      <div className='flex flex-wrap justify-around h-96'>
        {collection.options.map((option) => (
          <img
            className='w-40 h-24 bg-center bg-cover rounded-lg background-repeat'
            key={option.id}
            src={option.imageUrl}
            alt={option.imageUrl}
            data-testid={`option-${option.id}-img`}
          />
        ))}
      </div>
      <div className='flex justify-center w-full px-0 py-3'>
        <IconButton
          icon='thumbs-down'
          variant='contained'
          color='white'
          size='20'
          round
          onClick={onNegativeSubmit}
          className='mx-4 my-0'
        />
        <IconButton
          icon='thumbs-up'
          variant='contained'
          color='white'
          size='20'
          round
          disabled={disablePositiveSubmit}
          onClick={onPositiveSubmit}
          className='mx-4 my-0'
        />
      </div>
    </Popup>
  );
};
