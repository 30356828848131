import { EvaluationApi, EvaluationBatchRequest } from 'jsflagr';

export type NODE_ENV = 'development' | 'staging' | 'production';

export const ENABLED_VALUE = 'on';

export interface EntityContext {
  env?: NODE_ENV;
  [key: string]: string | number | undefined;
}

export const defaultEntityContext: EntityContext = {
  env: process.env.REACT_APP_NODE_ENV as NODE_ENV,
};

export async function batchEvaluation(flagKeys: string[], entityContext: EntityContext) {
  const apiInstance = new EvaluationApi();

  const body: EvaluationBatchRequest = {
    flagKeys,
    entities: [{ entityContext }],
  };

  const { evaluationResults } = await apiInstance.postEvaluationBatch(body);
  return evaluationResults;
}
