import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import theme from 'theme';
import { LikeButtonProps as Props } from '../../Newsfeed.types';

export const LikeButton: React.FC<Props> = ({ onClickHandler, likes, liked, color = theme.colors.turquoiseBlue }) => {
  const iconName = liked ? 'thumbs-up' : 'thumbs-up-outline';

  return (
    <div className='flex items-center justify-end select-none'>
      <div
        className='flex justify-end items-center p-3.5 pr-0'
        onClick={onClickHandler(liked)}
      >
        <span className='mr-2.5 text-sm text-lightGrey'>{likes}</span>

        <SvgIcon
          name={iconName}
          size={26}
          color={color}
        />
      </div>
    </div>
  );
};
