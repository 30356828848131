import { Popup } from 'components/popup';
import React from 'react';
import { useIntl } from 'react-intl';
import { LockedLemoPopupProps as Props } from '../types';

export const LockedLemoPopup: React.FC<Props> = ({ onModalClose, open }) => {
  const intl = useIntl();
  return (
    <Popup
      variant='locked'
      open={open}
      title={intl.formatMessage({ id: 'comp.moduleShape.lock.title' })}
      buttonText={intl.formatMessage({ id: 'formats.button.thanks' })}
      onPrimaryClick={onModalClose}
      onRequestClose={onModalClose}
      closeIcon
      hasThanksAndCloseButton={true}
    >
      <div className='p-8 text-sm font-light text-center whitespace-pre-wrap'>
        <span>{intl.formatMessage({ id: 'comp.moduleShape.lock.message' })}</span>
      </div>
    </Popup>
  );
};
