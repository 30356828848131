import { PayloadAction } from '@reduxjs/toolkit';
import { GetUnlockedBadgesSuccessPayload } from 'components/Badge/types';
import { API_URL, REST_API_URL } from 'constants/endpoints';
import { defaultLocale } from 'containers/language-provider';
import { loader } from 'graphql.macro';
import { StrictEffect, call, put, takeLatest } from 'redux-saga/effects';
import getDefaultHeaders from 'util/getDefaultHeaders';
import request from 'util/request';
import requestGraphql, { GraphQLRequestResult } from 'util/requestGraphql';
import { UploadImagePayload } from '../../constants/global';
import { User } from '../authentication';
import {
  DEPRECATED_UpdateUserPayload,
  GetAllBadgesPayload,
  GetUnlockedBadgesPayload,
  GetUserProgressSuccessPayload,
  UpdateUserPayload,
  UpdateUserSuccessPayload,
  actions,
} from './index';

const getBadgesQuery = loader('./graphql/queries/get-badges.graphql');
const getUnlockedBadgesQuery = loader('./graphql/queries/get-unlocked-badges.graphql');
const updateUserMutation = loader('./graphql/mutation/update-user.graphql');
const getUserProgressQuery = loader('./graphql/queries/get-user-progress.graphql');

function* getAllBadgesWorker({ payload }: PayloadAction<GetAllBadgesPayload>): Generator<StrictEffect, void> {
  const { locale = defaultLocale } = payload;
  yield call(request, `${API_URL}/authentication/exchange`, {
    method: 'POST',
    body: '',
  });

  const body = {
    query: getBadgesQuery,
    variables: { locale },
  };

  const { data, errors } = (yield call(requestGraphql, body)) as GraphQLRequestResult;

  if (errors) {
    const error = errors instanceof Error ? errors : errors.errors[0];
    yield put(actions.getAllBadgesError(error));
    // Badges are translated in API, so if there is no translation we fallback to english
  } else if (data.badges.get.length === 0) {
    body.variables.locale = 'en-GB';
    const { data: finalData, errors: finalErrors } = (yield call(requestGraphql, body)) as GraphQLRequestResult;

    if (finalErrors) {
      const error = finalErrors instanceof Error ? finalErrors : finalErrors.errors[0];
      yield put(actions.getAllBadgesError(error));
    } else {
      yield put(actions.getAllBadgesSuccess(finalData));
    }
  } else {
    yield put(actions.getAllBadgesSuccess(data));
  }
}

function* getUnlockedBadgesWorker({ payload }: PayloadAction<GetUnlockedBadgesPayload>): Generator<StrictEffect, void> {
  const { userId, locale } = payload;
  const body = {
    query: getUnlockedBadgesQuery,
    variables: { userId, locale },
  };

  const { data, errors } = (yield call(requestGraphql, body)) as {
    data: GetUnlockedBadgesSuccessPayload;
    errors: Error;
  };

  if (errors) {
    yield put(actions.getUnlockedBadgesError(errors));
  } else {
    yield put(actions.getUnlockedBadgesSuccess(data));
  }
}

/** @deprecated: Use the `updateUserWorker` instead */
function* DEPRECATED_updateUserWorker({
  payload,
}: PayloadAction<DEPRECATED_UpdateUserPayload>): Generator<StrictEffect, void> {
  const body = {
    query: updateUserMutation,
    variables: { ...payload },
  };

  const { data, errors } = (yield call(requestGraphql, body)) as {
    data: UpdateUserSuccessPayload;
    errors: Error;
  };

  if (errors) {
    yield put(actions.DEPRECATED_updateUserError(errors));
  } else {
    yield put(actions.DEPRECATED_updateUserSuccess(data));
  }
}

function* uploadAvatarWorker({ payload }: PayloadAction<UploadImagePayload>): Generator<StrictEffect, void> {
  const { image, resourceEntity, resourceField, resourceId } = payload;

  const requestUrl = `${API_URL}/upload`;
  const headers = {
    Accept: 'application/json',
  };

  try {
    const body = new FormData();
    body.append('image', image, image.name);
    body.append('resource_id', resourceId);
    body.append('resource_entity', resourceEntity);
    body.append('resource_field', resourceField);

    const { data: imagePath } = (yield call(request, requestUrl, {
      method: 'POST',
      headers,
      body,
    })) as { data: string; error: Error };

    yield put(actions.avatarUploadSuccess(imagePath));
  } catch (error) {
    yield put(actions.avatarUploadError());
  }
}

function* getUserProgressWorker(): Generator<StrictEffect, void> {
  const body = {
    query: getUserProgressQuery,
  };

  const { data, errors } = (yield call(requestGraphql, body)) as {
    data: GetUserProgressSuccessPayload;
    errors: Error;
  };

  if (errors) {
    yield put(actions.getUserProgressError(errors));
  } else {
    yield put(actions.getUserProgressSuccess(data));
  }
}

function* updateUserWorker({ payload }: PayloadAction<UpdateUserPayload>): Generator<StrictEffect, void> {
  try {
    const { userId, ...props } = payload;
    const { locale } = payload;
    const requestUrl = `${REST_API_URL}/users/${String(userId)}`;
    const headers = (yield call(getDefaultHeaders)) as HeadersInit;
    const body = JSON.stringify(props);

    const { data } = (yield call(request, requestUrl, {
      method: 'PATCH',
      headers,
      body,
    })) as { data: User };

    yield put(actions.updateUserSuccess({ ...data, locale }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(actions.updateUserError(error));
    } else {
      throw new Error('unknown error thrown by `updateUser`');
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.getUnlockedBadges.type, getUnlockedBadgesWorker);
  yield takeLatest(actions.getAllBadges.type, getAllBadgesWorker);
  yield takeLatest(actions.avatarUpload.type, uploadAvatarWorker);
  yield takeLatest(actions.DEPRECATED_updateUser.type, DEPRECATED_updateUserWorker);
  yield takeLatest(actions.getUserProgress.type, getUserProgressWorker);
  yield takeLatest(actions.updateUser.type, updateUserWorker);
}
