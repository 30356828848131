import classnames from 'classnames';
import * as React from 'react';
import { SpinnerBallsProps as Props } from './types';

const spinnersTopAndLeftRemValues = [
  [3.125, 3.125],
  [3.375, 2.8125],
  [3.5625, 2.4375],
  [3.625, 2],
  [3.5625, 1.5625],
  [3.375, 1.1875],
  [3.125, 0.875],
  [2.8125, 0.625],
];

export const spinnerBalls = spinnersTopAndLeftRemValues.map((spinner, idx) => ({
  top: `${spinner[0]}rem`,
  left: `${spinner[1]}rem`,
  animationDelay: `-${0.036 * (idx + 1)}s`,
}));

export const SpinnerBalls: React.FC<Props> = ({ className, backgroundColor = 'bg-darkGrey' }) => (
  <div
    data-testid='spinner'
    className={classnames('inline-block w-16 h-16 relative', className)}
  >
    {spinnerBalls.map(({ top, left, animationDelay }, idx) => (
      <div
        key={`spinner-ball-${idx}`}
        className='animate-spinner origin-top-left-2-2'
        style={{ animationDelay }}
      >
        <div
          className={`block absolute w-1.5 h-1.5 rounded-[50%] ${backgroundColor} -mt-0.75 -ml-0.75`}
          style={{ top, left }}
        />
      </div>
    ))}
  </div>
);
