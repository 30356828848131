import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import fitty from 'fitty';
import React, { useLayoutEffect } from 'react';
import { BaseButton } from '../base-button';
import classes from './TextButton.module.scss';
import { TextButtonProps } from './TextButton.types';

export const TextButton: React.FC<TextButtonProps> = ({
  text,
  iconName,
  iconColor,
  iconSize,
  disabled,
  className = '',
  shrinkToFit,
  children,
  ...rest
}) => {
  useLayoutEffect(() => {
    if (shrinkToFit) {
      fitty('.buttonText', {
        maxSize: 14,
        minSize: 11,
      });
    }
  }, [shrinkToFit]);

  return (
    <BaseButton
      className={classnames(classes.root, { [classes.iconPreffixed]: iconName }, className)}
      type='button'
      disabled={disabled}
      {...rest}
    >
      {iconName && (
        <SvgIcon
          name={iconName}
          color={iconColor}
          size={iconSize}
          fill={iconColor}
        />
      )}

      <span className='buttonText'>{text || children}</span>
    </BaseButton>
  );
};
