import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { routesWithBackgroundImage } from 'containers/app/routes';
import { actions as authActions, GetUserInfoSuccessPayload } from 'containers/authentication';
import { actions as homeActions } from 'pages/home';
import { Leco } from 'pages/home/Home.types';
import { isLepa } from 'pages/home/useLecoCardConfiguration';
import { AppStore } from './App.types';

export const initialState: AppStore = {
  splashScreen: '',
  fallbackSplashScreen: '',
  background: '',
  fallbackBackground: '',
  routesWithBackgroundImage,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authActions.getUserInfoSuccess.type, (state, action) => {
        const typedAction = action as PayloadAction<GetUserInfoSuccessPayload>;
        const { me } = typedAction.payload.users;
        const account = me.lastAccount || {};
        const splashScreen = account.splashScreen || '';
        const background = account.pathBackground || '';

        const isNewSplashScreen = splashScreen !== state.fallbackSplashScreen;
        const isNewBackground = background !== state.fallbackBackground;
        const isNewState = isNewSplashScreen || isNewBackground;

        if (!isNewState) {
          return state;
        }

        const nextSplashScreen = isNewSplashScreen ? { splashScreen, fallbackSplashScreen: splashScreen } : {};

        const nextBackground = isNewBackground ? { background, fallbackBackground: background } : {};

        return {
          ...state,
          ...nextSplashScreen,
          ...nextBackground,
        };
      })
      .addCase(homeActions.setSplashScreen.type, (state, action) => {
        const typedAction = action as PayloadAction<Leco>;
        const lepa = typedAction.payload;
        if (!isLepa(lepa)) {
          return state;
        }

        const activeLepaSplashScreen = lepa.content.splashScreen;
        const splashScreen = activeLepaSplashScreen || state.fallbackSplashScreen;

        return {
          ...state,
          splashScreen,
        };
      });
  },
});

export const { actions } = slice;
export default slice.reducer;
