import classNames from 'classnames';
import { LazyImage } from 'components/lazy-image';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { MediaProviders, videoURLParser } from 'util/videoUtils';
import { ProvidersID } from 'util/videoUtils/types';
import { ThumbnailProps as Props } from './Thumbnail.types';

const Thumbnail = ({ videoURL, className }: Props) => {
  const { provider, id } = videoURLParser(videoURL);

  if (!provider) {
    return <FallbackThumbnail />;
  }

  const renderThumbnailImg = () => {
    switch (provider) {
      case ProvidersID.YOUTUBE:
        return (
          <img
            className={classNames('h-full w-full rounded-t-lg', className)}
            src={MediaProviders.youtube.getVideoThumbnail(id)}
            alt=''
          />
        );

      case ProvidersID.DAILYMOTION:
        return (
          <img
            className={classNames('h-full w-full rounded-t-lg', className)}
            src={MediaProviders.dailymotion.getVideoThumbnail(id)}
            alt=''
          />
        );

      case ProvidersID.VIMEO:
        return (
          <LazyImage
            alt={`thumbnail-vimeo-${id}`}
            onImageFetch={() => MediaProviders.vimeo.getVideoThumbnail(id)}
            className={classNames('h-full w-full rounded-t-lg bg-black', className)}
          />
        );

      default:
        return <FallbackThumbnail />;
    }
  };

  return (
    <>
      {renderThumbnailImg()}
      <SvgIcon
        name='yt-play'
        className='absolute transform top-5/10 left-5/10 -translate-x-5/10 -translate-y-5/10'
        color='#FFFFFF'
        size={44}
      />
    </>
  );
};

export const FallbackThumbnail = () => (
  <>
    <div
      className='w-full h-full bg-black rounded-t-lg'
      data-testid='thumbnail-fallback'
    />
    <SvgIcon
      name='yt-play'
      className='absolute transform top-5/10 left-5/10 -translate-x-5/10 -translate-y-5/10'
      color='#FFFFFF'
      size={44}
    />
  </>
);

export default React.memo(Thumbnail, (prevProps: Props, nextProps: Props) => prevProps.videoURL === nextProps.videoURL);
