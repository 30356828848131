import { FormattedMessage } from 'react-intl';
import { LoaderDotsProps as Props } from './LoaderDots.types';

export const LoaderDots: React.FC<Props> = ({ backgroundColor }) => (
  <div className='flex flex-col py-3'>
    <p className='pb-2 text-xs text-left text-neutral-500'>
      <FormattedMessage id='mobie.chat.loading' />
    </p>
    <div className='flex justify-start space-x-2'>
      <div
        className={'w-4 h-4 rounded-full animate-pulse ease-in-out'}
        style={{ backgroundColor, animationDelay: '200ms', animationDuration: '1000ms' }}
      ></div>
      <div
        className={'w-4 h-4 rounded-full animate-pulse ease-in-out'}
        style={{ backgroundColor, animationDelay: '400ms', animationDuration: '1000ms' }}
      ></div>
      <div
        className={'w-4 h-4 rounded-full animate-pulse ease-in-out'}
        style={{ backgroundColor, animationDelay: '600ms', animationDuration: '1000ms' }}
      ></div>
    </div>
  </div>
);
