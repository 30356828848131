import { useDroppable } from '@dnd-kit/core';
import classnames from 'classnames';
import React from 'react';
import { DroppableProps as Props } from './Droppable.types';

export const Droppable: React.FC<Props> = ({ id, className, style, children }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      className={classnames(className, 'relative text-center')}
      style={style}
    >
      {children}
      {isOver && <div className='absolute inset-0 border-4 border-solid border-light'></div>}
    </div>
  );
};
