import classnames from 'classnames';
import ProfileHeader from 'components/ProfileHeader';
import Textfield from 'components/Textfield';
import { Button, IconButton } from 'components/button';
import { Checkbox } from 'components/checkbox/Checkbox';
import { SvgIcon } from 'components/svg-icon';
import { EMAIL_PATTERN } from 'constants/global';
import { Account } from 'containers/Profile/types';
import { User } from 'containers/authentication';
import { Locale, LocaleSelect, languageTextDirections } from 'containers/language-provider';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PasswordSettings } from 'store/dynamic-configuration';
import theme from 'theme';
import { removeSpaces } from 'util/strings';
import { SettingsProps, SettingsState } from './Settings.types';

class Settings extends React.Component<SettingsProps, SettingsState> {
  public constructor(props: SettingsProps) {
    super(props);

    const { firstName = '', lastName = '', jobPosition = '', id = '', avatar = '' } = props.profile.user;
    const { locale } = props;
    const email = props.profile.user?.email ?? '';

    this.state = {
      id,
      firstName,
      lastName,
      jobPosition,
      email,
      image: avatar,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      isPasswordValid: true,
      isEmailValid: true,
      imageUploaded: false,
      locale,
      openEmailPopup: false,
      renderedUpdatePopup: false,
      openFeedbackPopup: false,
    };
  }

  public componentDidUpdate(prevProps: SettingsProps) {
    const {
      settings: { change: prevSettingsChanged },
    } = prevProps;
    const {
      profile,
      settings: { change: settingsChanged },
      refetchDynamicConfiguration,
    } = this.props;

    if (settingsChanged.password.success && !prevSettingsChanged.password.success) {
      refetchDynamicConfiguration({ accountId: profile.account.id });
      this.setState({ openFeedbackPopup: true });
    }

    if (prevProps.profile.user !== profile.user) {
      this.changeUserData();
    }

    if (prevProps.profile.user.avatar !== this.props.profile.user.avatar && this.props.profile.user.avatar) {
      this.setState({ imageUploaded: true });
    }

    if (this.state.openEmailPopup && !this.state.renderedUpdatePopup) {
      this.setState({ renderedUpdatePopup: true }, () => {
        this.renderUpdateProfilePopUp();
      });
    }

    if (this.state.openFeedbackPopup && !this.state.renderedUpdatePopup) {
      this.props.onModalClose();
      this.setState({ renderedUpdatePopup: true }, () => {
        this.renderUpdateProfileFeedbackPopUp(false);
      });
    }
  }

  public componentWillUnmount() {
    this.props.resetSettings();
  }

  public render() {
    const {
      profile,
      settings,
      levelInfo,
      auth,
      intl,
      dynamicConfiguration,
      isEnabledDigest,
      isEnabledCustomizableProfile,
    } = this.props;
    const {
      error: changePasswordError,
      message: changePasswordMessage,
      success: changePasswordSuccess,
    } = settings.change.password;

    const { error: changeEmailError } = settings.change.email;

    const { user } = profile;
    const {
      firstName,
      lastName,
      jobPosition,
      email,
      currentPassword,
      newPassword,
      newPasswordConfirm,
      image,
      isPasswordValid,
      isEmailValid,
      locale,
    } = this.state;

    const colors = [
      theme.colors.turquoiseBlue,
      theme.colors.redSecondary,
      theme.colors.blueSecondary,
      theme.colors.purpleSecondary,
      theme.colors.marigoldSecondary,
    ];

    const primaryColor = colors[levelInfo.currentLevelIndex]
      ? colors[levelInfo.currentLevelIndex]
      : colors[colors.length - 1];

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const fm = intl.formatMessage;

    const sortedAccounts = profile.accounts && this.sortAccountsByName(profile.accounts);
    const canChangeAccount = profile.user.role === 'test';

    const { locale: userLocale } = this.props;
    const direction = languageTextDirections[userLocale];
    const isLTR = direction === 'ltr';

    const shouldRenderChangeEmail = isEnabledCustomizableProfile && dynamicConfiguration?.user.allowChangingEmail;
    const shouldRenderDigestOptIn = isEnabledDigest && dynamicConfiguration?.user.allowDigestOptIn;
    const passwordSettings = dynamicConfiguration?.user.passwordSettings ?? PasswordSettings.NOT_ALLOWED;
    const shouldRenderChangePassword = [
      PasswordSettings.ALLOWED_CURRENT_PASSWORD,
      PasswordSettings.ALLOWED_NO_CURRENT_PASSWORD,
    ].includes(passwordSettings);
    const shouldRenderCurrentPasswordField = passwordSettings === PasswordSettings.ALLOWED_CURRENT_PASSWORD;

    const updatePasswordTranslationType = shouldRenderCurrentPasswordField ? 'change' : 'set';
    const newPasswordTranslation = shouldRenderCurrentPasswordField ? 'change-pwd-new' : 'set-pwd';
    const newPasswordConfirmTranslation = shouldRenderCurrentPasswordField
      ? 'change-pwd-new-confirm'
      : 'set-pwd-confirm';
    const showEmailError = !isEmailValid || changeEmailError;
    const showPasswordError = !changePasswordSuccess && (!isPasswordValid || changePasswordMessage);

    return (
      <div className='h-full p-3'>
        <ProfileHeader
          primaryColor={primaryColor}
          onAvatarUpload={this.handleAvatarUpload}
          src={image}
          user={user}
        />

        {canChangeAccount && (
          <div>
            <div className='px-0 pt-6 pb-3 text-xs font-bold tracking-wide text-center text-yellow-500 uppercase'>
              Change current Account
            </div>

            <div className='relative'>
              <select
                className='w-full p-3 text-sm bg-white border-2 rounded-lg shadow-none outline-none appearance-none cursor-pointer border-pinkishGrey text-warmGrey'
                onChange={this.changeActiveAccount}
                defaultValue={String(profile.account.id)}
              >
                {sortedAccounts.map((account) => {
                  const applicationName = account.applicationName ? ` - ${account.applicationName}` : '';
                  return (
                    <option
                      key={account.id}
                      value={account.id}
                    >
                      {account.slug}
                      {applicationName}
                    </option>
                  );
                })}
              </select>

              <SvgIcon
                className='absolute transform pointer-events-none top-5/10 right-3 text-warmGrey -translate-y-5/10'
                name='chevron-down'
                size={24}
              />
            </div>
          </div>
        )}

        <div className='px-0 pb-3 text-xs font-bold tracking-wide text-center text-yellow-500 uppercase pt-7'>
          <FormattedMessage id='settings.basicInformation' />
        </div>

        <div>
          <div className='flex justify-between mb-2'>
            <Textfield
              className={classnames('w-full', {
                'mr-2': isLTR,
                'ml-2': !isLTR,
              })}
              placeholder={fm({ id: 'settings.basicInfo.placeholder.fName' })}
              style={{ minWidth: 0 }}
              value={firstName}
              onChange={this.fieldChange}
              withBorder
              icon={auth.sso ? undefined : 'pencil'}
              readOnly={auth.sso}
              name='firstName'
            />
            <Textfield
              className='w-full'
              placeholder={fm({ id: 'settings.basicInfo.placeholder.lName' })}
              style={{ minWidth: 0 }}
              value={lastName}
              onChange={this.fieldChange}
              withBorder
              icon={auth.sso ? undefined : 'pencil'}
              readOnly={auth.sso}
              name='lastName'
            />
          </div>
          {user.jobPosition && (
            <Textfield
              readOnly
              style={{ minWidth: 0 }}
              value={jobPosition}
              onChange={this.fieldChange}
              withBorder
              name='jobPosition'
            />
          )}

          {!auth.sso && (
            <div className='flex justify-end mt-2 text-right'>
              <IconButton
                variant='contained'
                icon='check'
                color='white'
                size={'1.25rem'}
                onClick={this.saveProfile}
              />
            </div>
          )}
        </div>

        {shouldRenderDigestOptIn && (
          <>
            <div className='px-0 pb-3 text-xs font-bold tracking-wide text-center text-yellow-500 uppercase pt-7'>
              <FormattedMessage
                id='settings.digest.header'
                defaultMessage='Email Digest'
              />
            </div>
            <div className='flex items-center mb-2'>
              <Checkbox
                id='email-digest-toggle'
                className='mr-2'
                checked={user.isDigestOptedIn}
                onClick={this.handleUpdateUser({ isDigestOptedIn: !user.isDigestOptedIn })}
              />
              <label
                htmlFor='email-digest-toggle'
                className='relative text-warmGrey'
              >
                <span className='align-middle'>
                  <FormattedMessage
                    id='settings.digest.email-toggle'
                    defaultMessage='I want to receive the bi-weekly digest'
                  />
                </span>
              </label>
            </div>
          </>
        )}
        <div>
          {shouldRenderChangeEmail && (
            <div>
              <div className='px-0 pb-3 text-xs font-bold tracking-wide text-center text-yellow-500 uppercase pt-7'>
                <FormattedMessage id={'settings.change-email'} />
              </div>
              <div>
                <Textfield
                  className='mb-2'
                  placeholder={fm({ id: 'settings.email.placeholder.change-email-new' })}
                  type='email'
                  value={email}
                  onChange={this.handleInputChangeEmail}
                  withBorder
                  icon={'pencil'}
                  name='email'
                  error={!isEmailValid}
                />
                <div className={classnames({ 'flex justify-between': showEmailError })}>
                  {showEmailError && (
                    <div
                      className={classnames('pl-0.5 pb-1', {
                        'text-turquoiseBlue': isEmailValid,
                        'text-fullRed': !isEmailValid || changeEmailError,
                      })}
                    >
                      {!isEmailValid && <FormattedMessage id='settings.email.message.error' />}
                    </div>
                  )}
                  <div className='flex justify-end mt-2 text-right'>
                    <IconButton
                      variant='contained'
                      icon='check'
                      color='white'
                      size={'1.25rem'}
                      onClick={this.changeEmail}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {shouldRenderChangePassword && (
            <div>
              <div className='px-0 pb-3 text-xs font-bold tracking-wide text-center text-yellow-500 uppercase pt-7'>
                <FormattedMessage id={`settings.${updatePasswordTranslationType}-password`} />
              </div>
              <div>
                {shouldRenderCurrentPasswordField && (
                  <Textfield
                    className='mb-2'
                    placeholder={fm({ id: 'settings.pwd.placeholder.change-pwd-current' })}
                    value={currentPassword}
                    onChange={this.handleInputChangePassword}
                    withBorder
                    type='password'
                    name='currentPassword'
                    error={changePasswordError}
                  />
                )}
                <Textfield
                  className='mb-2'
                  placeholder={fm({
                    id: `settings.pwd.placeholder.${newPasswordTranslation}`,
                  })}
                  value={newPassword}
                  onChange={this.handleInputChangePassword}
                  withBorder
                  type='password'
                  name='newPassword'
                  error={!isPasswordValid}
                />
                <Textfield
                  placeholder={fm({
                    id: `settings.pwd.placeholder.${newPasswordConfirmTranslation}`,
                  })}
                  value={newPasswordConfirm}
                  onChange={this.handleInputChangePassword}
                  withBorder
                  type='password'
                  name='newPasswordConfirm'
                  error={!isPasswordValid}
                />
                <div className={classnames({ 'flex justify-between': showPasswordError })}>
                  {showPasswordError && (
                    <div
                      className={classnames('pl-0.5 pb-1', {
                        'text-turquoiseBlue': changePasswordSuccess,
                        'text-fullRed': !changePasswordSuccess,
                      })}
                    >
                      {changePasswordSuccess ? (
                        <FormattedMessage id='settings.pwd.message.success' />
                      ) : (
                        <FormattedMessage
                          id={
                            changePasswordError ? 'settings.pwd.message.wrong-pwd-error' : 'settings.pwd.message.error'
                          }
                        />
                      )}
                    </div>
                  )}
                  <div className='flex justify-end mt-2 text-right'>
                    <IconButton
                      variant='contained'
                      icon='check'
                      color='white'
                      size={'1.25rem'}
                      onClick={this.changePassword}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div>
            <div className='px-0 pb-3 text-xs font-bold tracking-wide text-center text-yellow-500 uppercase pt-7'>
              <FormattedMessage id='settings.locale.title' />
            </div>
            <div className='mb-2'>
              <LocaleSelect
                className='w-full p-3 text-sm bg-white border-2 rounded-lg shadow-none outline-none appearance-none cursor-pointer border-pinkishGrey text-warmGrey'
                locale={locale}
                onChange={this.handleChangeLocale}
              />
            </div>
            <div className='flex justify-end mt-2 text-right'>
              <IconButton
                variant='contained'
                icon='check'
                color='white'
                size={'1.25rem'}
                onClick={this.handleSubmitLocale}
              />
            </div>
          </div>

          {this.renderLogoutForm()}
        </div>
      </div>
    );
  }

  private renderLogoutForm = () => (
    <>
      <div className='px-0 pb-3 text-xs font-bold tracking-wide text-center text-yellow-500 uppercase pt-7'>
        <FormattedMessage id='settings.logout.title' />
      </div>
      <Button
        variant='outlined'
        onClick={this.logoutUser}
        fullWidth
      >
        <FormattedMessage id='settings.logout.button' />
      </Button>
    </>
  );

  private renderUpdateProfilePopUp = () => {
    this.props.enqueueModal({
      type: ModalTypes.PROFILE_UPDATE,
      modal: {
        onModalClose: this.handleCloseModal,
        onSubmitCallback: () => {
          this.handleCloseModal();
          this.renderUpdateProfileFeedbackPopUp(true);
        },
      },
      sendLink: this.sendChangeEmailLink,
    });
  };

  private renderUpdateProfileFeedbackPopUp = (isEmailRequest: boolean) => {
    const { email, password } = this.props.settings.change;

    this.props.enqueueModal({
      type: ModalTypes.PROFILE_UPDATE_FEEDBACK,
      modal: {
        onModalClose: this.handleCloseModal,
        onSubmitCallback: () => this.resetModalState(),
      },
      isEmailRequest,
      success: email.success ? email.success : password.success,
      error: email.error,
    });
  };

  private sendChangeEmailLink = (password: string) => {
    const {
      profile: { user },
    } = this.props;
    const { email } = this.state;
    const clientId = process.env.REACT_APP_CLIENT_ID;

    this.props.changeEmail({
      userId: user.id,
      newEmail: email,
      password,
      clientId,
    });
  };

  private handleCloseModal = () => {
    this.setState({ renderedUpdatePopup: false, openEmailPopup: false, openFeedbackPopup: false });
    this.props.onModalClose();
  };

  private resetModalState = () => {
    this.setState({ renderedUpdatePopup: false, openEmailPopup: false, openFeedbackPopup: false });
  };

  private changeUserData = () => {
    const { firstName, lastName, avatar } = this.props.profile.user;
    this.setState({ firstName, lastName, image: avatar });
  };

  private fieldChange = (value: string, { target }: React.ChangeEvent<HTMLInputElement>) => {
    const name = target.name || target.dataset.name;
    if (name) {
      this.setState({ ...this.state, [name]: value });
    }
  };

  private handleInputChangeEmail = (value: string, { target }: React.ChangeEvent<HTMLInputElement>) => {
    const name = target.name || target.dataset.name;
    const { email } = this.props.settings.change;

    if (email.error || email.success) {
      this.props.resetSettings();
    }

    if (name) {
      this.setState({ ...this.state, [name]: value });
    }
    this.setState({ isEmailValid: true });
  };

  private handleInputChangePassword = (value: string, { target }: React.ChangeEvent<HTMLInputElement>) => {
    const name = target.name || target.dataset.name;
    const { password } = this.props.settings.change;

    if (password.error || password.message) {
      this.props.resetSettings();
    }

    if (name) {
      const trimmedValue = removeSpaces(value);
      this.setState({ ...this.state, [name]: trimmedValue });
    }

    this.setState({ isPasswordValid: true });
  };

  private handleAvatarUpload = (image: File) => {
    const { user } = this.props.profile;

    this.setState({ imageUploaded: false });

    this.props.onAvatarUpload({
      image,
      resourceEntity: 'user',
      resourceField: 'avatar_url',
      resourceId: String(user.id),
    });
  };

  private saveProfile = () => {
    const { firstName, lastName, image, imageUploaded } = this.state;
    this.props.DEPRECATED_updateUser({
      firstName,
      lastName,
      ...(imageUploaded && { image }),
    });
    this.setState({ imageUploaded: false });
  };

  private changeEmail = () => {
    const { email } = this.state;
    const clientId = process.env.REACT_APP_CLIENT_ID;
    if (!email) {
      return;
    }
    const isEmailValid = EMAIL_PATTERN.test(email);

    if (!isEmailValid) {
      this.setState({ isEmailValid: false });
    }

    if (isEmailValid && email !== this.props.profile.user.email && clientId) {
      this.setState({ openEmailPopup: true });
    }
  };

  private changePassword = () => {
    this.props.resetSettings();
    const {
      profile: { user },
    } = this.props;
    const { currentPassword, newPassword, newPasswordConfirm } = this.state;

    if (newPassword !== newPasswordConfirm) {
      this.setState({ isPasswordValid: false });
    }

    if (newPassword.length > 0 && newPassword === newPasswordConfirm) {
      this.props.changePassword({
        userId: user.id,
        newPassword,
        ...(currentPassword && { currentPassword }),
      });

      this.setState({
        isPasswordValid: true,
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      });
    }
  };

  private handleChangeLocale = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ locale: event.target.value as Locale });
  };

  private handleUpdateUser = (partialUser: Partial<User>) => () => {
    const {
      profile: { user },
    } = this.props;

    this.props.updateUser({ userId: user.id, ...partialUser });
  };

  private handleSubmitLocale = () => {
    const {
      profile: { user },
    } = this.props;
    const { locale } = this.state;
    if (!locale) {
      return;
    }

    this.props.updateUser({ userId: user.id, locale });
    document.documentElement.lang = locale;
  };

  /** Sorting the account names by name (Ascending order) */
  private sortAccountsByName = (accounts: Account[]) =>
    [...accounts].sort((a, b) => {
      if (a.slug && b.slug) {
        const slugA = a.slug.toLocaleLowerCase();
        const slugB = b.slug.toLocaleLowerCase();

        if (slugA < slugB) {
          return -1;
        }
        if (slugA > slugB) {
          return 1;
        }
        return 0;
      }
      return 0;
    });

  private logoutUser = () => {
    this.props.logout();
  };

  private changeActiveAccount = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.setActiveAccount({ accountID: target.value });
  };
}

export default Settings;
