import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loading } from 'components/Loading';
import { defaultLocale } from 'containers/language-provider';
import { Loformats } from 'containers/loformats';
import { AnswerResponse } from 'containers/loformats/hooks/use-learning-format/types';
import { useModalQueue } from 'containers/modal-controller';
import { useStartLepaQuery } from 'pages/lepa/Lepa.api';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import { Store } from 'store/types';
import theme from 'theme';
import { useGetLearningTestFormatsQuery, useSubmitLearningTestAnswerMutation } from './Tests.api';
import { AnswerPayload, LearningTestTypes, LevelTestUrlParams } from './Tests.types';
import {
  enqueueLearningPathCompletionModals,
  isLegacy,
  mapLearningFormatToOldStructure,
  prepareTestFormats,
} from './helpers';
import { useTestConfiguration } from './use-test-configuration';

export const LevelTest: React.FC = () => {
  const history = useHistory();
  const { enqueueModal } = useModalQueue();
  const { user, account } = useSelector(({ profile }: Store) => profile);
  const { id: userId, locale } = user;
  const { id: accountId } = account;
  const { learningPathId: pathId, levelId, learningTestId } = useParams<LevelTestUrlParams>();
  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);
  const isEnabledLearningPathRating = dynamicConfiguration?.plugins.learningPathRating ?? false;

  const {
    data: lepaData,
    isError: lepaIsError,
    isFetching,
  } = useStartLepaQuery({
    userId: parseInt(userId, 10),
    lepaId: parseInt(pathId, 10),
    locale: locale ?? defaultLocale,
  });

  const { data: learningTestFormatsData, isError: learningTestFormatsIsError } = useGetLearningTestFormatsQuery({
    learningTestId,
  });
  const [submitLearningTestAnswer, answerResponse] = useSubmitLearningTestAnswerMutation();
  const { data: answerData, originalArgs } = answerResponse;
  const isFetchingWithoutAnswer = isFetching && !answerData;

  const isLearningTestTypeCorrect = learningTestFormatsData?.type === LearningTestTypes.LEVEL;

  // MT-7127: mapping the formats to the old structure so that we don't have to change a lot of code for now
  const learningFormats = useMemo(
    () =>
      learningTestFormatsData?.learningTestFormats
        ? prepareTestFormats(learningTestFormatsData.learningTestFormats).map(mapLearningFormatToOldStructure)
        : [],
    [learningTestFormatsData],
  );

  const testConfiguration = useTestConfiguration(LearningTestTypes.LEVEL, lepaData);

  if (lepaIsError || learningTestFormatsIsError || (learningTestFormatsData && !isLearningTestTypeCorrect)) {
    history.push('/invalid-url');
  }

  if (!learningFormats.length || !lepaData || isFetchingWithoutAnswer) {
    return <Loading />;
  }

  const testContext = { levelId: parseInt(levelId, 10), skillId: -1, moduleId: -1 };
  const title = testConfiguration.getTitle(testContext);
  const navigateBackRoute = testConfiguration.getNavigateBackRoute(testContext);

  const { xp, percentage, passed, next, learningPathDone, threshold } = answerData?.testCompletion ?? {};

  const handleNavigateAway = () => {
    const { path, state } = testConfiguration.getNavigateAwayPathAndState({ testContext, passed, threshold });
    history.push(path, state);
    if (isLegacy(lepaData) && answerData?.testCompletion?.learningPathDone) {
      const lastLevel = lepaData.content.levels[lepaData.content.levels.length - 1];
      const isLastLevel = lastLevel.id === parseInt(levelId, 10);
      if (isLastLevel) {
        enqueueLearningPathCompletionModals({
          isEnabledLearningPathRating: isEnabledLearningPathRating && user.role !== 'preview',
          enqueueModal,
          lepaId: pathId,
          isCertification: lepaData.isCertification,
          title: lepaData.title,
          color: lepaData.color ?? theme.colors.turquoiseBlue,
        });
      }
    }
  };

  const handleOnAnswer = async (baseAnswer: AnswerPayload): Promise<AnswerResponse> => {
    const { learningFormatId, ...body } = baseAnswer;
    const { startTime, endTime, answer } = body;
    const response = await submitLearningTestAnswer({
      learningPathId: parseInt(pathId, 10),
      learningFormatId,
      learningTestId,
      body: {
        startTime,
        endTime,
        answer,
      },
    }).unwrap();

    return {
      isCorrect: response.isCorrect,
      correctOptions: response.correctOptions,
      completion: response.testCompletion,
    };
  };

  return (
    <Loformats
      learningFormats={learningFormats}
      title={title}
      id={learningTestId}
      color={lepaData.color ?? undefined}
      navigateBackRoute={navigateBackRoute}
      onAnswer={handleOnAnswer}
      isSubmittingAnswer={answerResponse.isLoading}
      isCorrect={answerData?.isCorrect}
      correctOptions={answerData?.correctOptions}
      xp={xp}
      percentage={percentage}
      passed={passed}
      next={next}
      learningPathDone={learningPathDone}
      onNavigateAway={handleNavigateAway}
      testContext={testContext}
      previousSubmittedFormatId={originalArgs?.learningFormatId}
    />
  );
};
