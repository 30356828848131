import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { isUserWithWalkthroughAccess, shouldShowWalkthrough, useWalkthrough } from 'containers/walkthrough';
import { isLegacyLearningPath } from 'pages/lepa';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { UseSkillWalkthroughs } from './Skill.types';

export const useSkillWalkthroughs = ({ learningPath, levelId, skillId }: UseSkillWalkthroughs) => {
  const { enqueueModal, has } = useModalQueue();
  const { completedWalkthroughs, locale } = useWalkthrough();
  const {
    user: { role },
  } = useSelector(({ profile: profileStore }: Store) => profileStore);
  const shouldPlayWalkthrough = isUserWithWalkthroughAccess(role);
  const isLegacy = learningPath && isLegacyLearningPath(learningPath);
  const level = isLegacy ? learningPath.content.levels.find((currentLevel) => currentLevel.id === levelId) : null;
  const skill = level?.skills.find((currentSkill) => currentSkill.id === skillId);

  useEffect(() => {
    if (!skill) {
      return;
    }
    const walkthroughVariant = 'skill-shape';
    const hasSkillShapeWalkthrough = shouldShowWalkthrough(completedWalkthroughs, walkthroughVariant);
    const showIntroWalkthrough = Boolean(hasSkillShapeWalkthrough && shouldPlayWalkthrough);
    const isModalAlreadyEnqueued = has(ModalTypes.WALKTHROUGH, walkthroughVariant);
    if (!showIntroWalkthrough || isModalAlreadyEnqueued) {
      return;
    }
    const hasLockedModule = skill.modules.some((module) => !module.progress.unlocked);
    const hasUnlockedModule = skill.modules.some((module) => module.progress.unlocked);
    const firstLockedModuleSelector = hasLockedModule ? '.locked-node' : '#shape-container > *:first-child';
    const lastUnlockedModuleSelector = hasUnlockedModule
      ? '.unlocked-node'
      : '#shape-container > *:first-child > *:nth-child(2)';
    enqueueModal({
      type: ModalTypes.WALKTHROUGH,
      modal: {
        variant: walkthroughVariant,
        targets: ['body', '#shape-container > *:first-child', firstLockedModuleSelector, lastUnlockedModuleSelector],
        lang: locale,
      },
    });
  }, [completedWalkthroughs, enqueueModal, locale, shouldPlayWalkthrough, skill, has]);

  useEffect(() => {
    if (!skill) {
      return;
    }
    const walkthroughVariant = 'skill-test';
    const hasSkillTest = Boolean(skill?.progress.learningTestId);
    const hasSkillTestWalkthrough = shouldShowWalkthrough(completedWalkthroughs, walkthroughVariant) && hasSkillTest;
    const showTestWalkthrough = Boolean(hasSkillTestWalkthrough && shouldPlayWalkthrough);
    const isModalAlreadyEnqueued = has(ModalTypes.WALKTHROUGH, walkthroughVariant);
    if (!showTestWalkthrough || isModalAlreadyEnqueued) {
      return;
    }
    enqueueModal({
      type: ModalTypes.WALKTHROUGH,
      modal: {
        variant: walkthroughVariant,
        targets: ['.wt-skill-test'],
        lang: locale,
      },
    });
  }, [completedWalkthroughs, enqueueModal, has, locale, shouldPlayWalkthrough, skill]);
};
