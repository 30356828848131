import classNames from 'classnames';
import { Loading } from 'components/Loading';
import { BackButton } from 'components/back-button';
import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import { Typography } from 'components/typography';
import { ProfileStore } from 'containers/Profile/types';
import { defaultLocale } from 'containers/language-provider';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { useModalQueue } from 'containers/modal-controller/useModalQueue';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { usePendingRequests } from 'store/pending-requests';
import { Store } from 'store/types';
import { actions } from './Event.ducks';
import { EventStore, GetEventPayload } from './Event.types';
import { EventMedia } from './EventMedia';
import { Session } from './session/Session';

interface URLParams {
  eventId: string;
}

export const Event = () => {
  const { eventId } = useParams<URLParams>();
  const dispatch = useDispatch();
  const { event } = useSelector<Store, EventStore>((state) => state.activeEvent);
  const { account, user } = useSelector<Store, ProfileStore>(({ profile }) => profile);

  const [selectedSession, setSelectedSession] = useState<number | null>(null);
  const hasPendingRequests = usePendingRequests([actions.getEvent.type]);
  const { enqueueModal } = useModalQueue();

  const brandColor = event?.primaryColor || account.pathColor || '#00BBD5';
  const checkCircleColor = selectedSession ? brandColor : '#C4C4C4';
  const locale = user.locale || defaultLocale;

  useEffect(() => {
    const payload: GetEventPayload = {
      lecoId: eventId,
      locale,
    };

    dispatch(actions.getEvent(payload));
  }, [eventId, dispatch, locale]);

  if (hasPendingRequests || !event) {
    return <Loading />;
  }

  const areAllSpotsTaken = event.content.sessions.every((session) => session.registrationCount >= session.spots);
  const hasRegisteredSession = event.content.sessions.some((session) => Boolean(session.userRegistration?.id));
  const isUserConfirmedForSomeSession = event.content.sessions.some(
    (session) => session.userRegistration?.lastConfirmationLog?.type === 'confirmed',
  );

  const handleCreateRegistration = () => {
    if (!selectedSession) {
      return;
    }

    dispatch(actions.createRegistration(selectedSession));
    setSelectedSession(null);
  };

  const handleCancelRegistration = () => {
    if (isUserConfirmedForSomeSession) {
      enqueueModal({ type: ModalTypes.EVENT_SUBMIT_USER_IS_ALREADY_CONFIRMED });
      return;
    }

    enqueueModal({ type: ModalTypes.EVENT_SUBMIT_CANCEL_REGISTRATION });
    setSelectedSession(null);
  };

  const handleAddToCalendarClick = () =>
    enqueueModal({
      type: ModalTypes.EVENT_SUBMIT_CONFIRMED_REGISTRATION,
    });

  return (
    <div
      data-testid='e2e-event'
      className={classNames('w-full h-full overflow-y-auto', {
        'bg-black/50': areAllSpotsTaken && !hasRegisteredSession,
      })}
    >
      <BackButton />
      <PageContainer className='px-3 h-9/10'>
        <div
          className='text-xs font-bold uppercase'
          style={{ color: brandColor }}
        >
          {event.title}
        </div>
        <div className='relative w-full mt-7'>
          <EventMedia src={event.content.mediaSrcSigned} />
        </div>

        <div className='relative my-3 break-words border-2 rounded-lg border-light'>
          <Typography
            rich={true}
            className='p-5 text-sm font-normal'
          >
            {event.content.text}
          </Typography>
        </div>

        <div className='mb-3 text-sm font-normal'>
          {!isUserConfirmedForSomeSession ? (
            <FormattedMessage
              id='event.summary'
              tagName='p'
            />
          ) : (
            <FormattedMessage
              id='event.already-confirmed'
              tagName='p'
            />
          )}
        </div>

        {event.content.sessions.map((session) => {
          const isSessionConfirmed = session.userRegistration?.lastConfirmationLog?.type === 'confirmed';
          return (
            <Session
              key={session.id}
              {...session}
              className={classNames({
                hidden: isUserConfirmedForSomeSession && !isSessionConfirmed,
              })}
              onclick={setSelectedSession}
              isSelected={session.id === selectedSession && !hasRegisteredSession}
              isSelectable={!hasRegisteredSession}
              borderColor={brandColor}
            />
          );
        })}

        <div
          className={classNames('flex flex-col items-center justify-end flex-grow flex-shrink-0 px-0 py-7', {
            'cursor-pointer': selectedSession,
            'hidden': isUserConfirmedForSomeSession,
          })}
        >
          {hasRegisteredSession && (
            <Button
              className='mb-2'
              variant='outlined'
              onClick={handleAddToCalendarClick}
              fullWidth
            >
              <FormattedMessage id='event.add-to-calendar' />
            </Button>
          )}

          {hasRegisteredSession ? (
            <Button
              variant='outlined'
              onClick={handleCancelRegistration}
              fullWidth
              style={{ color: '#FF0000' }}
            >
              <FormattedMessage id='event.button' />
            </Button>
          ) : (
            <svg
              onClick={handleCreateRegistration}
              xmlns='http://www.w3.org/2000/svg'
              width='60'
              height='60'
              fill='none'
              viewBox='0 0 60 60'
            >
              <circle
                cx='30'
                cy='30'
                r='29'
                stroke={checkCircleColor}
                strokeWidth='2'
              ></circle>
              <path
                fill={checkCircleColor}
                d='M44.251 23.419L27.656 40.24a2.529 2.529 0 01-3.61 0L15.75 31.83a2.61 2.61 0 010-3.66 2.525 2.525 0 013.61 0l6.492 6.582 14.79-14.993a2.525 2.525 0 013.61 0 2.61 2.61 0 010 3.66z'
              ></path>
            </svg>
          )}
        </div>
      </PageContainer>
    </div>
  );
};
