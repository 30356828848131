import { Drawer } from 'components/drawer';
import { SvgIcon } from 'components/svg-icon';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MessageActionsProps } from './Message.types';
import { MessageDeleteAction } from './MessageDeleteAction';

export const MessageActions: FC<MessageActionsProps> = ({ renderHighlightedMessage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteActionOpen, setIsDeleteActionOpen] = useState(false);

  const handleOpenActions = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsDeleteActionOpen(false);
  };

  const handleOpenDeleteAction: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setIsDeleteActionOpen(true);
    setIsOpen(false);
  };

  return (
    <>
      <button
        className='text-lg font-bold'
        onClick={handleOpenActions}
      >
        ...
      </button>
      <Drawer
        className='font-light text-white text-opacity-75 bg-neutral-800'
        isOpen={isOpen}
        onClose={handleClose}
        renderElement={renderHighlightedMessage}
      >
        <button
          className='flex gap-2 p-2 m-auto text-lg font-light text-red-500'
          onClick={handleOpenDeleteAction}
        >
          <SvgIcon
            viewBox='0 -1 24 24'
            name='trash-can-outline'
            size={24}
            color='#ef4444'
          />
          <FormattedMessage
            id='community.chat.delete-message.title'
            defaultMessage='Delete message'
          />
        </button>
      </Drawer>
      <MessageDeleteAction
        isOpen={isDeleteActionOpen}
        onClose={handleClose}
        renderHighlightedMessage={renderHighlightedMessage}
      />
    </>
  );
};
