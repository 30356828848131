import { Loading } from 'components/Loading';
import React, { useState } from 'react';
import { FrameProps as Props } from './Frame.types';

export const Frame: React.FC<Props> = ({ height = '93.5%', width = '100%', title, ...rest }) => {
  const [loadSpinner, setLoadSpinner] = useState(true);
  return (
    <>
      {loadSpinner && <Loading />}
      <iframe
        title={title}
        height={height}
        width={width}
        onLoad={() => setLoadSpinner(false)}
        className={`border-none ${loadSpinner ? 'hidden' : 'inline'}`}
        {...rest}
      />
    </>
  );
};
