import classnames from 'classnames';
import { Button } from 'components/button';
import { Popup } from 'components/popup';
import { useTrackEventMutation } from 'containers/Analytics/Analytics.api';
import { EventTypes } from 'containers/Analytics/internal/InternalAnalytics.types';
import { xor } from 'lodash';
import { useGetCategoriesQuery } from 'pages/home/Category.api';
import { BuiltInPathCategories } from 'pages/home/Home.types';
import { BuiltinCategoryNames } from 'pages/home/Lololeco';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'store/types';
import theme from 'theme';
import {
  TypeFilters,
  getFiltersCount,
  getFiltersQueryParams,
  removeFiltersFromStorage,
  saveFiltersOnStorage,
  useFiltersQueryParams,
} from 'util/useFiltersQueryParams';
import { CategoryFilterPopupProps as Props } from './CategoryFilterPopup.types';
import { CheckIcon, RadioIcon } from './components';

export const CategoryFilterPopup: React.FC<Props> = ({ onModalClose, open }) => {
  const history = useHistory();
  const { categories: categoriesFilter, isCertified: isCertifiedFilter, types: typesFilter } = useFiltersQueryParams();
  const {
    user: { id: userId, locale: userLocale, role },
    account: { id: accountId, primaryColor: accountPrimaryColor },
  } = useSelector(({ profile }: Store) => profile);
  const { data: categories, isError: errorRetrievingCategories } = useGetCategoriesQuery({
    userId,
    accountId,
    locale: userLocale,
  });

  const [selectedCategories, setSelectedCategories] = useState<number[]>(categoriesFilter);
  const [certifiedContent, setCertifiedContent] = useState(isCertifiedFilter);
  const [selectedTypes, setSelectedTypes] = useState<TypeFilters[]>(typesFilter);

  const [trackEvent] = useTrackEventMutation();

  const handleToggleCategory = (categoryId: number) => () => {
    setSelectedCategories(
      (previousSelectedCategories) => (previousSelectedCategories.includes(categoryId) ? [] : [categoryId]), // only working with one category for now
    );
  };

  const handleToggleType = (type: TypeFilters) => () => {
    setSelectedTypes((previousSelectedTypes) =>
      previousSelectedTypes.includes(type)
        ? previousSelectedTypes.filter((previousSelectedType) => previousSelectedType !== type)
        : [...previousSelectedTypes, type],
    );
  };

  const handleToggleCertified = () => {
    setCertifiedContent((prevCertified) => !prevCertified);
  };

  const handleClearFilters = async () => {
    setSelectedCategories([]);
    setSelectedTypes([]);
    setCertifiedContent(false);

    await trackEvent({
      eventType: EventTypes.CLEAR_LECO_FILTERS,
      accountId,
      properties: {
        role,
      },
    });
  };

  const isSelectedType = (type: TypeFilters) => selectedTypes.includes(type);

  const handleApplyFilters = async () => {
    const search = getFiltersQueryParams({
      categories: selectedCategories,
      isCertified: certifiedContent,
      types: selectedTypes,
    });
    history.replace({ search });

    if (search) {
      saveFiltersOnStorage(`?${search}`);
    } else {
      removeFiltersFromStorage();
    }

    await trackEvent({
      eventType: EventTypes.SET_LECO_FILTERS,
      accountId,
      properties: {
        categories: selectedCategories,
        certifiedContent,
        types: selectedTypes,
        role,
      },
    });

    onModalClose();
  };

  const appliedFiltersCount = getFiltersCount({
    categories: selectedCategories,
    isCertified: certifiedContent,
    types: selectedTypes,
  });
  const categoriesAreDifferent = xor(selectedCategories, categoriesFilter).length !== 0;
  const certifiedContentIsDifferent = certifiedContent !== isCertifiedFilter;
  const typesAreDifferent = selectedTypes.length !== typesFilter.length || xor(selectedTypes, typesFilter).length !== 0;
  const applyButtonIsEnabled = categoriesAreDifferent || certifiedContentIsDifferent || typesAreDifferent;
  const buttonsColor = accountPrimaryColor || theme.colors.darkGrey;

  return (
    <Popup
      variant='fullscreen'
      open={open}
      closeIcon={true}
      closeIconSize={32}
      closeIconColor={theme.colors.darkGrey}
      onRequestClose={onModalClose}
      pageContainerClassName='w-full max-w-none h-full py-4 select-none'
      disablePadding
    >
      <div className='mt-10 overflow-y-auto h-9/10'>
        <div className='flex flex-col w-full max-w-3xl px-4 pb-8 mx-auto'>
          <div className='pb-5 text-lg font-semibold text-left text-darkGrey'>
            <FormattedMessage
              id='home.skillflix-filters-popup.titles.filter'
              defaultMessage='Filter'
            />
          </div>

          {categories && categories.length > 0 && !errorRetrievingCategories && (
            <>
              <div className='pb-4 text-sm font-semibold text-left text-darkGrey'>
                <FormattedMessage
                  id='home.skillflix-filters-popup.titles.category'
                  defaultMessage='Show category:'
                />
              </div>
              {categories.map(({ id, title }) => (
                <div
                  key={id}
                  className={'flex items-center pb-4 text-sm font-medium cursor-pointer gap-x-2 text-darkGrey'}
                  onClick={handleToggleCategory(id)}
                >
                  <RadioIcon selected={selectedCategories.includes(id)} />
                  {id < 0 && Object.keys(BuiltinCategoryNames).includes(title)
                    ? BuiltinCategoryNames[title as BuiltInPathCategories]
                    : title}
                </div>
              ))}

              <hr className='w-full h-px my-4 bg-whiteFour' />
            </>
          )}

          <div className='py-4 text-sm font-semibold text-left text-darkGrey'>
            <FormattedMessage
              id='home.skillflix-filters-popup.titles.type'
              defaultMessage='Show type:'
            />
          </div>
          <div
            className='flex items-center pb-5 text-sm font-medium cursor-pointer gap-x-2 text-darkGrey'
            onClick={handleToggleCertified}
          >
            <CheckIcon checked={certifiedContent} />
            <FormattedMessage
              id='home.skillflix-filters-popup.types-options.certified-content'
              defaultMessage='Certified Content'
            />
          </div>

          {Object.values(TypeFilters).map((type) => (
            <div
              key={type}
              className='flex items-center pb-5 text-sm font-medium cursor-pointer gap-x-2 text-darkGrey'
              onClick={handleToggleType(type)}
            >
              <CheckIcon checked={isSelectedType(type)} />
              <FormattedMessage id={`home.skillflix-filters-popup.types-options.${type}`} />
            </div>
          ))}

          <div className='fixed bottom-0 left-4 right-4 h-40 pointer-events-none bg-linear-gradient-[top,white,60%,transparent,90%]' />
        </div>
      </div>
      <div className='flex flex-row pb-2 gap-2.5 lg:gap-8 z-10 max-w-3xl mx-auto w-full px-4'>
        <Button
          variant='outlined'
          className={classnames({
            'cursor-pointer': appliedFiltersCount,
            'cursor-not-allowed opacity-40': !appliedFiltersCount,
          })}
          style={{ borderColor: buttonsColor, color: buttonsColor }}
          onClick={handleClearFilters}
          disabled={!appliedFiltersCount}
          fullWidth
        >
          {appliedFiltersCount ? (
            <FormattedMessage
              id='home.skillflix-filters-popup.buttons.clear-with-number'
              defaultMessage='Clear ({amount})'
              values={{
                amount: appliedFiltersCount,
              }}
            />
          ) : (
            <FormattedMessage
              id='home.skillflix-filters-popup.buttons.clear-without-number'
              defaultMessage='Clear'
            />
          )}
        </Button>
        <Button
          className={classnames('text-white', {
            'cursor-pointer': applyButtonIsEnabled,
            'cursor-not-allowed opacity-40': !applyButtonIsEnabled,
          })}
          style={{ backgroundColor: buttonsColor }}
          onClick={handleApplyFilters}
          disabled={!applyButtonIsEnabled}
          fullWidth
        >
          <FormattedMessage
            id='home.skillflix-filters-popup.buttons.apply'
            defaultMessage='Apply'
          />
        </Button>
      </div>
    </Popup>
  );
};
