import { ImageFormat, VideoFormat } from 'containers/FormatScreen/Formats';
import React from 'react';
import { MediaProps as Props } from './Event.types';

export const EventMedia: React.FC<Props> = ({ src }) => {
  if (!src) {
    return <></>;
  }

  const shouldRenderImage = src.startsWith('media/');

  return (
    <>
      {shouldRenderImage ? (
        <ImageFormat
          imgSrc={src}
          containerClassName='mx-auto my-0 overflow-hidden w-full'
        />
      ) : (
        <VideoFormat url={src} />
      )}
    </>
  );
};
