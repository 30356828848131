import { PayloadAction } from '@reduxjs/toolkit';
import { loader } from 'graphql.macro';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import requestGraphql from 'util/requestGraphql';
import { actions } from './Lepa.ducks';
import { CreateCertificatePayload, SendCertificatePayload, TrackTipPayload } from './Lepa.types';

const createCertificateMutation = loader('./GraphQL/mutations/create-certificate.graphql');
const sendCertificateMutation = loader('./GraphQL/mutations/send-certificate.graphql');
const trackTipsMutation = loader('./GraphQL/mutations/track-tips.graphql');
const skipRetentionTestMutation = loader('./GraphQL/mutations/skip-retention-test.graphql');

/** Track tip worker */
function* trackTipWorker({ payload }: PayloadAction<TrackTipPayload>): Generator<StrictEffect, void> {
  const { id } = payload;
  const body = {
    query: trackTipsMutation,
    variables: { id },
  };

  const { data, errors } = (yield call(requestGraphql, body)) as {
    errors: Error;
    data: {
      tracker: {
        tipsTricks: {
          id: string;
          tracked: boolean;
        };
      };
    };
  };

  if (errors) {
    yield put(actions.trackTipError(errors));
  } else {
    yield put(actions.trackTipSuccess(data.tracker.tipsTricks));
  }
}

function* skipRetentionTestWorker(): Generator<StrictEffect, void> {
  const body = {
    query: skipRetentionTestMutation,
  };

  const { errors } = (yield call(requestGraphql, body)) as {
    errors?: any; // TODO: NEEDS TO BE CHANGED AFTER GRAPHQL TO REST MIGRATION IS DONE
  };

  if (errors) {
    const error = Array.isArray(errors.errors) ? errors.errors[0] : errors;
    yield put(actions.skipRetentionTestError(error));
  } else {
    yield put(actions.skipRetentionTestSuccess());
  }
}

function* createCertificateWorker({ payload }: PayloadAction<CreateCertificatePayload>): Generator<StrictEffect, void> {
  const { pathId } = payload;

  const body = {
    query: createCertificateMutation,
    variables: { id: pathId },
  };

  const { errors, data } = (yield call(requestGraphql, body)) as {
    errors?: any; // ! NEEDS TO BE CHANGED AFTER GRAPHQL TO REST MIGRATION IS DONE
    data: {
      users: {
        certifyMe: {
          id: string;
        };
      };
    };
  };

  if (errors) {
    const error = Array.isArray(errors.errors) ? errors.errors[0] : errors;
    yield put(actions.createCertificateError(error));
  } else {
    const { id } = data.users.certifyMe;
    yield put(actions.createCertificateSuccess({ id }));
  }
}

function* sendCertificateWorker({ payload }: PayloadAction<SendCertificatePayload>) {
  const { id, email } = payload;

  const body = {
    query: sendCertificateMutation,
    variables: { id, emails: [email] },
  };

  const { errors } = (yield call(requestGraphql, body)) as {
    errors?: any; // ! NEEDS TO BE CHANGED AFTER GRAPHQL TO REST MIGRATION IS DONE
  };

  if (errors) {
    const error = Array.isArray(errors.errors) ? errors.errors[0] : errors;
    yield put(actions.sendCertificateError(error));
  } else {
    yield put(actions.sendCertificateSuccess());
  }
}

export function* rootSaga() {
  yield takeLatest(actions.trackTip.type, trackTipWorker);
  yield takeLatest(actions.skipRetentionTest.type, skipRetentionTestWorker);
  yield takeLatest(actions.createCertificate.type, createCertificateWorker);
  yield takeLatest(actions.sendCertificate.type, sendCertificateWorker);
}
