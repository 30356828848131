import { Loading } from 'components/Loading';
import { Line } from 'components/ModuleShape';
import { Logo } from 'components/logo';
import { API_URL } from 'constants/endpoints';
import { USER_ROLES } from 'constants/global';
import { getRoleAccessLevel } from 'containers/authentication';
import { defaultLocale } from 'containers/language-provider';
import { useModalQueue } from 'containers/modal-controller';
import { LepaTypes } from 'pages/home/Home.types';
import { useStartLepaQuery } from 'pages/lepa/Lepa.api';
import { SkillHistoryState, getColorFromScore, mapModulesToOldStructure } from 'pages/skill';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Store } from 'store/types';
import theme from 'theme';
import { actions } from './CrashCourse.ducks';
import { CrashCourseUrlParams } from './CrashCourse.types';
import { CrashCourseHeader } from './crash-course-header';
import { isCrashCourse } from './helpers';
import { useCrashCourseWalkthroughs } from './useCrashCourseWalkthroughs';

export const CrashCourse: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory<SkillHistoryState>();
  const { enqueueModal } = useModalQueue();
  const { location } = history;
  const { crashCourseId } = useParams<CrashCourseUrlParams>();
  const profileStore = useSelector(({ profile }: Store) => profile);
  const {
    showLogo,
    moduleCreditReached,
    error: informationError,
    loaded: isInformationLoaded,
  } = useSelector(({ crashCourse }: Store) => crashCourse);
  const { id: userId, role, avatar, locale } = profileStore.user;
  const { logoUrl } = profileStore.account;

  const {
    data: crashCourse,
    isError: lepaIsError,
    isFetching,
    originalArgs: lepaCache,
  } = useStartLepaQuery({
    userId: parseInt(userId, 10),
    lepaId: parseInt(crashCourseId, 10),
    locale: locale ?? defaultLocale,
  });

  useEffect(() => {
    if (crashCourse) {
      dispatch(actions.getCrashCourseRelatedInformation({ lepaId: parseInt(crashCourseId, 10) }));
    }
  }, [dispatch, crashCourse, crashCourseId]);

  useCrashCourseWalkthroughs(crashCourse);

  const isCrashCourseIdDifferent = crashCourse?.id !== parseInt(crashCourseId, 10);
  const isLoadingNewCrashCourse = !crashCourse || !isInformationLoaded || isFetching || isCrashCourseIdDifferent;
  const isErrorAndMatchingId = lepaIsError && crashCourseId === String(lepaCache?.lepaId);

  if (isErrorAndMatchingId) {
    history.push('/invalid-url');
    return null;
  }

  if (isLoadingNewCrashCourse) {
    return <Loading />;
  }

  if (lepaIsError || informationError || (crashCourse && !isCrashCourse(crashCourse))) {
    history.push('/invalid-url');
    return null;
  }

  const accountLogoUrl = logoUrl ? `${API_URL}/${logoUrl}` : '';
  const roleAccessLevel = getRoleAccessLevel(role);
  const isPreviewUserRole = roleAccessLevel === USER_ROLES.PREVIEW;

  const animateUnlocks = location?.state?.nextUnlocked ?? false;

  const locationStateModuleId = location?.state?.moduleID ? parseInt(location.state.moduleID, 10) : -1;
  const animationIndex = crashCourse.content.modules.findIndex((module) => module.id === locationStateModuleId) ?? -1;

  const hasLearningTest = Boolean(crashCourse.content.progress.learningTestId);
  const isCrashCourseCompleted = crashCourse.content.progress.done;
  const isLearningTestAvailable = hasLearningTest && !isCrashCourseCompleted;

  const passedModules = crashCourse.content.modules.filter((module) => module.progress.passed);
  const lastPassedModule = passedModules.slice(-1)[0];
  const lastPassedModulePercentage = lastPassedModule ? lastPassedModule.progress.percentage : '';
  const lastScore = lastPassedModulePercentage ? Number.parseFloat(lastPassedModulePercentage) : 0;
  const animationColor = getColorFromScore(lastScore);

  const { passed, learningTestId, testScore, unlocked } = crashCourse.content.progress;
  const isCrashCourseModulesCompleted = crashCourse.content.modules.every((module) => module.progress.passed);
  const isCrashCourseDone = isCrashCourseModulesCompleted && !passed;

  const avatarUrl = avatar ? `${API_URL}/${avatar}` : '';

  const { color: crashCourseColor } = crashCourse;
  const color = crashCourseColor ?? theme.colors.orangeRed;

  const firstModuleProgress = crashCourse.content.modules[0].progress;
  const shouldBounce =
    !firstModuleProgress.done && !Number.parseFloat(firstModuleProgress.percentage) && !isPreviewUserRole;

  const modulesInOldStructure = mapModulesToOldStructure(crashCourse.content.modules);

  const handleGoToFormats = (testType: string, id?: string | number) => {
    if (!id || !['skillTest', 'moduleTest'].includes(testType)) {
      return;
    }
    if (testType === 'skillTest') {
      history.push(`/crash-course/${crashCourseId}/test/${id}`);
    }
    if (testType === 'moduleTest') {
      history.push(`/crash-course/${crashCourseId}/module/${id}`);
    }
  };

  return (
    <div
      className='flex flex-col w-full h-full overflow-y-auto'
      data-testid='crashcourse'
    >
      <div className='flex flex-col flex-1 w-full'>
        {/* CRASH COURSE VERTICAL CENTERING: TOP CONTAINER  */}
        <div className='flex-1/2' />

        <div
          id='wt-line-container'
          className='w-full mt-1'
        >
          <CrashCourseHeader
            unlocked={isLearningTestAvailable && isCrashCourseDone}
            done={passed}
            crashCourseId={crashCourseId}
            learningTestId={learningTestId}
            crashCourseTestScore={testScore}
            crashCourseUnlocked={unlocked}
            crashCoursePassed={passed}
            color={color}
            startText={intl.formatMessage({ id: 'container.pathScreen.button.startTest' })}
            onClick={handleGoToFormats}
          />
          <div className='flex justify-center flex-1 h-auto'>
            <Line
              history={history}
              modules={modulesInOldStructure}
              modulesAmount={crashCourse.content.modules.length}
              color={color}
              animationIndex={animationIndex}
              animateUnlocks={animateUnlocks}
              onNodeClick={handleGoToFormats}
              moduleCreditReached={moduleCreditReached}
              animationColor={animationColor}
              skillTest={isLearningTestAvailable}
              pathType={LepaTypes.CRASH_COURSE}
              avatar={avatarUrl}
              hasUnlockAnimation={!isPreviewUserRole}
              bounce={shouldBounce}
              enqueueModal={enqueueModal}
            />
          </div>
        </div>

        {/* CRASH COURSE VERTICAL CENTERING: BOTTOM CONTAINER */}
        <div className='flex-1/2' />

        {showLogo && (
          <div className='flex items-end justify-start object-cover w-20 h-14 ml-7 mb-7'>
            <Logo
              alt='logo'
              component='img'
              key='account-logo-avatar'
              className='w-auto h-full'
              src={accountLogoUrl}
            />
          </div>
        )}
      </div>
    </div>
  );
};
