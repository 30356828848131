import { API_URL } from 'constants/endpoints';
import { FC, MouseEventHandler } from 'react';
import { ListItemPreview } from '../../ListItemPreview';
import { UserPreviewProps } from './NewGroup.types';

export const UserPreview: FC<UserPreviewProps> = ({ user, hasSelectIndicator = false, onClick }) => {
  const userAvatar = user.image ? `${API_URL}/${user.image}` : '';
  const userPresence = user.online ? 'online' : 'offline';

  const handleClickUser: MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(user, event);
  };

  return (
    <div className='mt-3'>
      <ListItemPreview
        role={user.channelRole}
        title={user.name ?? ''}
        image={userAvatar}
        userPresence={userPresence}
        hasSelectIndicator={hasSelectIndicator}
        isSelected={user.isSelected}
        onClick={handleClickUser}
      />
    </div>
  );
};
