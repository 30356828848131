import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { animated, useTransition } from 'react-spring';
import theme from 'theme';
import { TagProps } from './Tag.types';

export const Tag: React.FC<TagProps> = ({ tag, selected = false, className, style, ...restProps }) => {
  const getTestId = (isSelected: boolean) => `tag-${tag.id}${isSelected ? '-selected' : ''}`;
  const selectedTransitions = useTransition(selected, {
    unique: true,
    reset: true,
    from: { display: 'none', border: '4px solid white' },
    enter: selected
      ? { display: 'flex', border: `4px solid ${theme.colors.turquoiseBlue}` }
      : { display: 'flex', border: '4px solid white' },
    leave: { display: 'none', border: '4px solid white' },
  });

  return (
    <animated.div style={style}>
      {selectedTransitions((styles, item) => (
        <animated.div
          data-testid={getTestId(item)}
          className={classNames(
            'relative rounded-lg min-h-[4.5rem] my-3.5 bg-whiteTwo text-greyishBrown cursor-pointer p-2.5',
            'border-4 border-solid border-transparent',
            className,
          )}
          style={styles}
          {...restProps}
        >
          {<FormattedMessage id={`personality.test.tags.list.${tag.label}`} />}
        </animated.div>
      ))}
    </animated.div>
  );
};
