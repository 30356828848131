import { Media } from 'components/media';
import React, { useEffect, useState } from 'react';
import { LazyImageProps } from './LazyImage.types';

export const LazyImage: React.FC<LazyImageProps> = ({ className, onImageFetch, alt, ...props }) => {
  const [src, setSrc] = useState('');

  useEffect(() => {
    onImageFetch()
      .then((data: string) => {
        setSrc(data);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.error(error));
  }, [onImageFetch]);

  return (
    <Media
      alt={alt}
      src={src}
      className={className}
      external
      {...props}
    />
  );
};
