import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'store/types';
import { useAnalytics } from './useAnalytics';

/**
 * RoutingAnalyticsTracker will log to the analytics tracker when a logged in user
 * changes routes on the application
 */
export const MatomoRoutingAnalyticsTracker: React.FC = ({ children }) => {
  const userID = useSelector<Store, string>(({ profile }) => profile.user.id);
  const history = useHistory();
  const { location } = history;

  const { trackPageView, identifyUser } = useAnalytics();

  React.useEffect(() => {
    identifyUser(userID);
  }, [identifyUser, userID]);

  React.useEffect(() => {
    const pathName = location.pathname.split('/')[1] || 'home';
    const isUserOnView = Boolean(pathName && userID);

    if (isUserOnView) {
      trackPageView({ documentTitle: pathName });
    }
  }, [location.pathname, userID, trackPageView]);

  return <>{children}</>;
};
