import { Circle, Polygon, Square, Triangle } from 'components/ModuleShape';
import { Module as OldModule } from 'containers/FormatScreen';
import { Module } from 'pages/lepa';

export const getColorFromScore = (score: number) => {
  if (score >= 100) {
    return 'diamond';
  }
  if (score >= 80 && score <= 99) {
    return 'gold';
  }
  if (score >= 60 && score <= 79) {
    return 'silver';
  }
  return 'ruby';
};

export const getProperShape = (
  numberOfModules: number,
): typeof Circle | typeof Triangle | typeof Square | typeof Polygon => {
  switch (numberOfModules) {
    case 2:
      return Circle;
    case 3:
      return Triangle;
    case 4:
      return Square;
    case 5:
      return Polygon;
    default:
      return Polygon;
  }
};

export const mapModulesToOldStructure = (modules: Module[]): OldModule[] =>
  modules.map((module) => ({
    id: String(module.id),
    title: module.title,
    summary: '',
    unlocked: module.progress.unlocked,
    done: module.progress.done,
    passed: module.progress.passed,
    tags: [],
    percentage: Number.parseFloat(module.progress.percentage),
    formats: [],
  }));
