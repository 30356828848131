import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import { useTextDirection } from 'hooks/useTextDirection';
import React from 'react';
import { locales } from './LanguageProvider.helpers';
import { LocaleSelectProps as Props } from './LanguageProvider.types';
import { appLocales } from './index';

export const LocaleSelect: React.FC<Props> = ({ className, locale, onChange }) => {
  const localeValues = Object.values(locales);
  const sortedLocales = localeValues.sort((locale1, locale2) =>
    locale1.toLowerCase().localeCompare(locale2.toLowerCase()),
  );

  const { isLTR } = useTextDirection();

  return (
    <div className='relative'>
      <select
        defaultValue={locale}
        onChange={onChange}
        className={className}
      >
        {sortedLocales.map((appLocale) => {
          const localeCode = appLocales.find((code) => locales[code] === appLocale);

          return (
            <option
              key={localeCode}
              value={localeCode}
            >
              {appLocale}
            </option>
          );
        })}
      </select>
      <SvgIcon
        className={classnames('inline-block absolute top-1/2 -translate-y-1/2 text-warmGrey pointer-events-none', {
          'right-3': isLTR,
          'left-3': !isLTR,
        })}
        name='chevron-down'
        size={24}
      />
    </div>
  );
};
