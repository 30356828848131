import { SeparatorLine } from 'pages/community/chat/components/separator-line';
import { FC } from 'react';
import { Drawer } from './Drawer';
import { ConfirmationDrawerProps } from './Drawer.types';

export const ConfirmationDrawer: FC<ConfirmationDrawerProps> = ({
  isOpen,
  title,
  confirmationText,
  cancellationText,
  renderElement,
  onConfirm,
  onClose,
}) => (
  <Drawer
    className='font-light text-white text-opacity-75 bg-neutral-800'
    isOpen={isOpen}
    renderElement={renderElement}
    onClose={onClose}
  >
    <div className='m-auto mb-6 text-center text-white'>{title}</div>
    <SeparatorLine />

    <button
      className='w-full p-4 m-auto text-lg font-light text-red-600'
      onClick={onConfirm}
    >
      {confirmationText}
    </button>

    <SeparatorLine />

    <button
      className='w-full p-4 m-auto text-lg font-light '
      onClick={onClose}
    >
      {cancellationText}
    </button>
  </Drawer>
);
