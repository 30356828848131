import classnames from 'classnames';
import { Media } from 'components/media';
import { API_URL } from 'constants/endpoints';
import { FormatContentGroup } from 'containers/FormatScreen/types';
import React from 'react';
import { NodeGroup } from 'react-move';
import theme from 'theme';
import SwipeableCard from '../SwipeableCard';
import { SwipeColumnProps as Props } from '../types';

const SwipeColumn: React.FC<Props> = ({
  columnData,
  options = [],
  showAnswers,
  className,
  brandColor,
  onValidateOption,
  onOptionSwitchColumn,
}) => {
  const onStartAnimation = () => ({ scale: 0 });
  const onUpdateAnimation = () => ({ scale: [1], timing: { duration: 150 } });
  const onLeaveAnimation = () => ({ scale: [0], timing: { duration: 150 } });

  const handleSwitchColumn = (optionID: string) => () => {
    if (onOptionSwitchColumn) {
      onOptionSwitchColumn(optionID);
    }
  };

  const handleValidateOption = (optionID: string) => onValidateOption && onValidateOption(optionID);
  const imageSrc = columnData.imgSrc ? `${API_URL}/${columnData.imgSrc}` : '';

  return (
    <div className={classnames('h-auto w-1/2 relative', className)}>
      {imageSrc && (
        <Media
          alt={`column-${columnData.title}-background`}
          className='absolute'
          src={imageSrc}
        />
      )}
      <NodeGroup
        data={options}
        keyAccessor={(opt: FormatContentGroup) => `${columnData.title}Col-opt-${opt.id}`}
        start={onStartAnimation}
        enter={onUpdateAnimation}
        update={onUpdateAnimation}
        leave={onLeaveAnimation}
      >
        {(nodes) => (
          <div
            className='relative w-full p-4 text-xs font-bold select-none pt-14'
            style={columnData.style}
          >
            <div className='absolute top-0 left-0 w-full pt-2'>
              <div
                className='pb-1 text-sm text-center text-ocean'
                style={{
                  color: brandColor || theme.colors.ocean,
                }}
              >
                {columnData.title}
              </div>
            </div>

            {nodes.map(({ key, data: option, state }) => {
              const isOptionCorrect = handleValidateOption(option.id);

              return (
                <SwipeableCard
                  data={option}
                  key={key}
                  showFeedback={showAnswers}
                  isCorrect={isOptionCorrect}
                  style={{ transform: `scale(${String(state.scale)})`, transition: 'transform 0.15s' }}
                  onClick={handleSwitchColumn(option.id)}
                  data-testid={isOptionCorrect ? 'correct-option' : 'incorrect-option'}
                />
              );
            })}
          </div>
        )}
      </NodeGroup>
    </div>
  );
};

export default SwipeColumn;
