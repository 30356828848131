import { Frame } from 'components/frame';
import { COACHING_APP_GROWTH_PLAN_URL } from 'constants/global';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProfileStore } from '../../containers/Profile';
import { Store } from '../../store/types';
import { useCreateChannelMutation } from '../community/chat/Chat.api';
import { CreateChat } from './GrowthPlan.types';

export const GROWTH_PLAN_PATH = '/growth-plan';

export const GrowthPlan = () => {
  const history = useHistory();
  const user = useSelector<Store, ProfileStore['user']>(({ profile }) => profile.user);
  const { id: accountId } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const [createChannel] = useCreateChannelMutation();

  const createChatWithUser = useCallback(
    async (id: string, name: string) => {
      const channel = await createChannel({
        accountId,
        members: [user.id, id],
        isGroup: false,
        name: name ?? '',
      }).unwrap();

      if (!channel.id) {
        return;
      }

      history.push(`/chat/${channel.id}`);
    },
    [accountId, createChannel, history, user.id],
  );

  useEffect(() => {
    const handleIFrameMessage = (event: MessageEvent<CreateChat>) => {
      const message = event.data;
      const eventUser = message?.user;
      if (message.type === 'create-chat' && eventUser?.id) {
        void createChatWithUser(eventUser.id, eventUser.name);
      }
    };

    window.addEventListener('message', handleIFrameMessage);

    return () => {
      window.removeEventListener('message', handleIFrameMessage);
    };
  }, [createChatWithUser]);

  return (
    <Frame
      name='growth-plan'
      title='growth-plan'
      data-testid='growth-plan-frame'
      src={COACHING_APP_GROWTH_PLAN_URL}
      className='h-full'
    />
  );
};
