import { rootRestApi } from 'store/root.api';
import { GetScormUrlPayload, GetScormUrlResponse } from './Scorm.types';

export const scormApi = rootRestApi.injectEndpoints({
  endpoints: (builder) => ({
    getLaunchUrl: builder.query<GetScormUrlResponse, GetScormUrlPayload>({
      query: ({ scormId, locale }) => ({
        url: `/scorm/${scormId}/launch-link`,
        method: 'POST',
        body: {
          locale,
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetLaunchUrlQuery } = scormApi;
