import { API_URL } from 'constants/endpoints';
import { TEST_USER } from 'constants/global';
import { AuthStore, actions } from 'containers/authentication';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Store } from 'store/types';
import { removeSpaces } from 'util/strings';
import { useURLSearchParams } from 'util/useURLSearchParams';
import { authenticationFlows } from './authentication-flows';
import { Authentications, UseAuthenticationProps } from './types';

const redirectOAuthUserToAPI = (loginSlug: string) => {
  const redirectUrl = `${API_URL}/accounts/${loginSlug}/sso`;

  // The API expects a window URL request for oauth2
  window.location.assign(redirectUrl);
};

export const useAuthentication = ({ reCaptchaRef }: UseAuthenticationProps) => {
  const dispatch = useDispatch();
  const { accountID, hasExternalIdProvider } = useSelector<Store, AuthStore['customLogin']>(
    ({ auth }) => auth.customLogin,
  );

  const params = useURLSearchParams();
  const { loginSlug } = useParams<{ loginSlug: string }>();

  const [inputs, setInputs] = React.useState({
    email: '',
    password: '',
    token: '',
  });

  React.useEffect(() => {
    if (loginSlug) {
      dispatch(actions.validateCustomLogin(loginSlug));
    }
  }, [dispatch, loginSlug]);

  React.useEffect(() => {
    if (hasExternalIdProvider && loginSlug) {
      redirectOAuthUserToAPI(loginSlug);
    }
  }, [hasExternalIdProvider, loginSlug]);

  const { email, password, token } = inputs;

  const handleInputChange = (value: string, { target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = target;
    const parsedValue = removeSpaces(value);
    setInputs({ ...inputs, [name]: parsedValue });
  };

  const handleTokenChange = (value: string | null) => {
    const nextToken = value !== null ? value : '';
    setInputs({ ...inputs, token: nextToken });
  };

  const resetRecaptcha = () => {
    reCaptchaRef.current?.reset();
  };

  const getAuthenticationFlowType = () => {
    const hasAuthorizationCode = Boolean(params.code);
    const hasManifestCode = Boolean(params.manifest_token);

    if (hasManifestCode) {
      return Authentications.MANIFEST;
    }
    if (hasAuthorizationCode) {
      return Authentications.SSO;
    }
    if (TEST_USER) {
      return Authentications.TEST;
    }
    if (params.response_type === 'code') {
      return Authentications.OAUTH2;
    }
    return null;
  };

  const flowType = getAuthenticationFlowType();
  const flow = flowType && authenticationFlows[flowType];

  const handleLogin = () => {
    const credentials = {
      email,
      password,
      token,
      accountID,
    };
    if (!flow) {
      return;
    }
    const canLogin = flow.canLogin(credentials, params);

    if (!canLogin) {
      return;
    }
    dispatch(flow.loginAction({ ...credentials, params }));

    resetRecaptcha();
  };

  return {
    flow,
    email,
    password,
    token,
    params,
    handleInputChange,
    handleTokenChange,
    handleLogin,
  };
};
