import classNames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import * as React from 'react';
import { useState } from 'react';
import FullScreenContainer from './DeprecatedFullScreenContainer';
import classes from './Media.module.scss';
import { MediaProps } from './Media.types';

export const MEDIA_COMPONENTS = ['video', 'audio', 'picture', 'iframe', 'img'];

export const DeprecatedMedia: React.FC<MediaProps> = ({
  component = 'div',
  className,
  src,
  style,
  allowPreview,
  ...other
}) => {
  const [preview, setPreview] = useState(false);
  const mediaRef = React.createRef();
  const togglePreview = () => {
    setPreview(!preview);
  };

  const Component = component;

  const isMediaComponent = typeof Component === 'string' && MEDIA_COMPONENTS.includes(Component);
  const composedStyle = !isMediaComponent && src ? { backgroundImage: `url("${src}")`, ...style } : style;

  const showPreviewIndicator = allowPreview && !preview;

  const media = (
    // @ts-ignore: HTML elements
    <Component
      ref={mediaRef}
      className={classNames(
        classes.root,
        {
          [classes.backgroundImg]: !isMediaComponent,
        },
        className,
      )}
      style={composedStyle}
      src={src}
      onClick={allowPreview ? togglePreview : undefined}
      role='img'
      data-testid='e2e-media'
      {...other}
    />
  );

  return (
    <>
      {media}
      {showPreviewIndicator && (
        <span
          className={classes.previewIndicator}
          data-testid='media-preview-indicator'
        >
          <SvgIcon
            name='search'
            size={16}
            color='black'
          />
        </span>
      )}
      {preview && <FullScreenContainer onClose={togglePreview}>{media}</FullScreenContainer>}
    </>
  );
};
