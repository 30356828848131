import { SvgIcon, SvgIconName, SvgIconProps } from 'components/svg-icon';
import { LecoOverview } from 'containers/leco-overview';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTrackEventMutation } from '../../containers/Analytics/Analytics.api';
import { EventTypes } from '../../containers/Analytics/internal/InternalAnalytics.types';
import { RootState } from '../../store';
import { actions as homeActions } from './Home.ducks';
import {
  CardConfiguration,
  CrashCourseContentListItem,
  Domains,
  ExternalLecoListItem,
  Leco,
  LecoTypes,
  LegacyContentListItem,
  QuestionsOnlyContentListItem,
  SingleLearningContentListItem,
  TextContentListItem,
  VideoContentListItem,
} from './Home.types';

type LepaLeco =
  | LegacyContentListItem
  | CrashCourseContentListItem
  | TextContentListItem
  | VideoContentListItem
  | QuestionsOnlyContentListItem
  | SingleLearningContentListItem;

export function isLepa(leco: Leco): leco is LepaLeco {
  return [
    LecoTypes.CRASH_COURSE,
    LecoTypes.LEGACY,
    LecoTypes.TEXT,
    LecoTypes.VIDEO,
    LecoTypes.QUESTIONS_ONLY,
    LecoTypes.SINGLE_LEARNING,
  ].includes(leco.type);
}

export function isCrashCourse(leco: Leco): leco is CrashCourseContentListItem {
  return leco.type === LecoTypes.CRASH_COURSE;
}

export const useLecoCardConfiguration = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector(({ profile }: RootState) => profile);
  const { role } = user;

  const accountId = useSelector(({ profile }: RootState) => profile.account?.id);

  const [trackEvent] = useTrackEventMutation();

  const handleLepaPlay = (leco: Leco) => {
    if (!leco.unlocked || !isLepa(leco)) {
      return null;
    }
    dispatch(homeActions.setSplashScreen(leco));
    const baseUrl = isCrashCourse(leco) ? '/crash-course' : '/learning-path';
    return history.push(`${baseUrl}/${leco.id}`);
  };

  const isExternalLeco = (leco: Leco): leco is ExternalLecoListItem => {
    const externalLeco = leco as ExternalLecoListItem;
    const { content } = externalLeco;

    return Boolean(content.url);
  };

  const openInNewTab = (url: string) => window.open(url, '_blank', 'noopener,noreferrer');

  const handleLTIPlay = (leco: Leco, prefixRedirectTo: string) => {
    if (!isExternalLeco(leco)) {
      return null;
    }
    const queryString = qs.stringify({ url: leco.content.url });
    const redirectTo = `${prefixRedirectTo}?${queryString}`;

    return history.push(redirectTo);
  };

  const handleLinkPlay = (leco: Leco) => {
    if (!isExternalLeco(leco)) {
      return null;
    }
    dispatch(
      homeActions.createCheckpoint({
        learningContentId: Number(leco.id),
        domainId: Number(leco.content.id),
        domain: Domains.LINK,
      }),
    );

    return openInNewTab(leco.content.url);
  };

  const shouldDisplayLecoInfo = (leco: Leco) => {
    const { description, objectives, estimatedTimeToComplete } = leco;
    return Boolean(description || objectives || estimatedTimeToComplete);
  };

  const openEvent = (leco: Leco) => {
    if (leco.type !== LecoTypes.EVENT) {
      return;
    }
    dispatch(homeActions.setSplashScreen(leco));
    history.push(`/event/${leco.content.lecoId}`);
  };

  const handleEventPlay = (leco: Leco) => {
    if (!leco.unlocked) {
      return null;
    }
    return openEvent(leco);
  };

  const handleScormPlay = (leco: Leco) => {
    history.push(`/scorm/${leco.content.id}`);
  };

  const handleVideoPlay = (leco: Leco) => {
    history.push(`/video/${leco.content.id}`);
  };

  const handleLecoOneLemoPlay = (leco: Leco) => {
    history.push(`/learning-moment/${leco.content.id}`);
  };

  const getCurrentIcon = (
    { size = '16', viewBox: propsViewBox = '0 0 16 16', ...props }: SvgIconProps,
    isCertification = false,
  ): React.ReactNode => {
    const name: SvgIconName = isCertification ? 'certified' : props.name;
    const viewBox = isCertification ? '0 0 16 16' : propsViewBox;

    return (
      <SvgIcon
        {...props}
        name={name}
        size={size}
        viewBox={viewBox}
      />
    );
  };

  const lepaCardConfiguration: CardConfiguration = {
    Component: LecoOverview,
    getIcon: (leco) => {
      const iconName = isCrashCourse(leco) ? 'crash-course' : 'default-learning-path';

      const props: SvgIconProps = { name: iconName };

      const isCertification = isLepa(leco) && leco.content.isCertification;

      return getCurrentIcon(props, isCertification);
    },
    play: (leco) => handleLepaPlay(leco),
    openInfo: (leco) => {
      if (!shouldDisplayLecoInfo(leco)) {
        handleLepaPlay(leco);
        return;
      }
      history.push(`/path-info/${leco.id}`);
    },
    canOpen: (leco) => isLepa(leco) && leco.content.lockedBy.length === 0,
  };

  const ltiCardConfiguration: CardConfiguration = {
    Component: LecoOverview,
    getIcon: () => getCurrentIcon({ name: 'external' }),
    play: (leco) => handleLTIPlay(leco, '/lti/goodhabitz'),
    openInfo: (leco) => handleLTIPlay(leco, '/lti/goodhabitz'),
    canOpen: () => true,
  };

  const linkCardConfiguration: CardConfiguration = {
    Component: LecoOverview,
    getIcon: () => getCurrentIcon({ name: 'external' }),
    play: (leco) => {
      void (async () => {
        await trackEvent({
          accountId,
          eventType: EventTypes.NAVIGATE_EXTERNAL,
          properties: {
            ...leco.content,
            learningContentId: leco.id,
            domainId: leco.content.id,
            role,
          },
        });
      })();

      return handleLinkPlay(leco);
    },
    openInfo: (leco) => {
      if (!shouldDisplayLecoInfo(leco)) {
        handleLinkPlay(leco);
        return;
      }
      history.push(`/path-info/${leco.id}`);
    },
    canOpen: () => true,
  };

  const eventCardConfiguration: CardConfiguration = {
    Component: LecoOverview,
    getIcon: () => getCurrentIcon({ name: 'calendar' }),
    play: (leco) => handleEventPlay(leco),
    openInfo: (leco) => {
      if (leco.type !== LecoTypes.EVENT) {
        return;
      }

      if (!shouldDisplayLecoInfo(leco)) {
        handleEventPlay(leco);
        return;
      }

      history.push(`/path-info/${leco.content.lecoId}`);
    },
    canOpen: () => true,
  };

  const scormCardConfiguration: CardConfiguration = {
    Component: LecoOverview,
    getIcon: () => getCurrentIcon({ name: 'external' }),
    play: (leco) => handleScormPlay(leco),
    openInfo: (leco) => {
      if (leco.type !== LecoTypes.SCORM) {
        return;
      }

      if (!shouldDisplayLecoInfo(leco)) {
        handleScormPlay(leco);
        return;
      }

      history.push(`/path-info/${leco.content.lecoId}`);
    },
    canOpen: () => true,
  };

  const textCardConfiguration: CardConfiguration = {
    Component: LecoOverview,
    getIcon: (leco) => {
      const isCertification = isLepa(leco) && leco.content.isCertification;
      return getCurrentIcon({ name: 'text-format', size: '16', viewBox: '0 0 24 24' }, isCertification);
    },
    play: (leco) => handleLecoOneLemoPlay(leco),
    openInfo: (leco) => {
      if (leco.type !== LecoTypes.TEXT) {
        return;
      }

      if (!shouldDisplayLecoInfo(leco)) {
        handleLecoOneLemoPlay(leco);
        return;
      }

      history.push(`/path-info/${leco.content.id}`);
    },
    canOpen: (leco) => isLepa(leco) && leco.content.lockedBy.length === 0,
  };

  const videoCardConfiguration: CardConfiguration = {
    Component: LecoOverview,
    getIcon: (leco) => {
      const isCertification = isLepa(leco) && leco.content.isCertification;
      return getCurrentIcon({ name: 'play-outlined', viewBox: '0 0 160 160' }, isCertification);
    },
    play: (leco) => handleVideoPlay(leco),
    openInfo: (leco) => {
      if (leco.type !== LecoTypes.VIDEO) {
        return;
      }

      if (!shouldDisplayLecoInfo(leco)) {
        handleVideoPlay(leco);
        return;
      }

      history.push(`/path-info/${leco.content.id}`);
    },
    canOpen: (leco) => isLepa(leco) && leco.content.lockedBy.length === 0,
  };

  const questionsOnlyCardConfiguration: CardConfiguration = {
    Component: LecoOverview,
    getIcon: (leco) => {
      const isCertification = isLepa(leco) && leco.content.isCertification;
      return getCurrentIcon({ name: 'questions-only', viewBox: '0 0 24 24' }, isCertification);
    },
    play: (leco) => handleLecoOneLemoPlay(leco),
    openInfo: (leco) => {
      if (leco.type !== LecoTypes.QUESTIONS_ONLY) {
        return;
      }

      if (!shouldDisplayLecoInfo(leco)) {
        handleLecoOneLemoPlay(leco);
        return;
      }

      history.push(`/path-info/${leco.content.id}`);
    },
    canOpen: (leco) => isLepa(leco) && leco.content.lockedBy.length === 0,
  };

  const singleLearningCardConfiguration: CardConfiguration = {
    Component: LecoOverview,
    getIcon: (leco) => {
      const isCertification = isLepa(leco) && leco.content.isCertification;
      return getCurrentIcon({ name: 'single-learning', viewBox: '0 0 24 24' }, isCertification);
    },
    play: (leco) => handleLecoOneLemoPlay(leco),
    openInfo: (leco) => {
      if (leco.type !== LecoTypes.SINGLE_LEARNING) {
        return;
      }

      if (!shouldDisplayLecoInfo(leco)) {
        handleLecoOneLemoPlay(leco);
        return;
      }

      history.push(`/path-info/${leco.content.id}`);
    },
    canOpen: (leco) => isLepa(leco) && leco.content.lockedBy.length === 0,
  };

  const cardConfiguration: { [origin in LecoTypes]: CardConfiguration } = {
    [LecoTypes.LEGACY]: lepaCardConfiguration,
    [LecoTypes.CRASH_COURSE]: lepaCardConfiguration,
    [LecoTypes.LTI]: ltiCardConfiguration,
    [LecoTypes.LINK]: linkCardConfiguration,
    [LecoTypes.EVENT]: eventCardConfiguration,
    [LecoTypes.SCORM]: scormCardConfiguration,
    [LecoTypes.TEXT]: textCardConfiguration,
    [LecoTypes.VIDEO]: videoCardConfiguration,
    [LecoTypes.QUESTIONS_ONLY]: questionsOnlyCardConfiguration,
    [LecoTypes.SINGLE_LEARNING]: singleLearningCardConfiguration,
  };
  return cardConfiguration;
};
