import classnames from 'classnames';
import { Avatar } from 'components/avatar';
import { IconButton } from 'components/button';
import { Checkbox } from 'components/checkbox/Checkbox';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { doNothing } from '../../../util/doNothing';
import { ChannelGroupRoles, ListItemPreviewProps } from './Chat.types';
import { SeparatorLine } from './components/separator-line';

export const ListItemPreview: FC<ListItemPreviewProps> = ({
  title,
  message,
  image,
  icon,
  userPresence,
  unreadCount,
  lastMessageAt,
  hasSelectIndicator,
  isSelected = false,
  role,
  onClick = doNothing,
}) => {
  const { account } = useSelector(({ profile }: Store) => profile);

  return (
    <>
      <button
        tabIndex={0}
        onClick={onClick}
        className={classnames('flex w-full pb-3 cursor-pointer', {
          'items-center': !message,
        })}
      >
        <div className='w-12 h-12 mr-4'>
          {icon ? (
            <IconButton
              variant='outlined'
              icon={icon}
              color={account.primaryColor}
              size='0.75rem'
              style={{ borderColor: account.primaryColor }}
              viewBox='5 4 13 13'
              className='bg-white rounded-full mt-1 ml-0.5'
            />
          ) : (
            <Avatar
              src={image}
              disableBorder
              title={title}
              primaryColor={account.primaryColor}
              className='w-12 h-12 drop-shadow-md'
              presence={userPresence}
              notifications={unreadCount}
            />
          )}
        </div>
        <div className='w-full overflow-hidden text-left'>
          <div className='flex w-full'>
            <div className='overflow-hidden text-xl font-normal overflow-ellipsis whitespace-nowrap md:w-7/10 w-5/10'>
              {title}
            </div>
            <div className='ml-auto text-base font-light text-gray-500'>
              {role === ChannelGroupRoles.GROUP_ADMIN ? (
                <FormattedMessage
                  id='community.chat.group-info.group-admin'
                  defaultMessage='Group Admin'
                />
              ) : (
                lastMessageAt
              )}
            </div>
          </div>
          {message && (
            <div className='overflow-hidden font-light text-gray-500 overflow-ellipsis whitespace-nowrap'>
              {message}
            </div>
          )}
        </div>

        {hasSelectIndicator && (
          <Checkbox
            id='email-digest-toggle'
            className='mt-3 mr-2'
            color={account.primaryColor}
            checked={isSelected}
            onClick={onClick}
          />
        )}
      </button>
      <SeparatorLine />
    </>
  );
};
