import { LemoLimitReachedPopup, LockedLemoPopup } from 'components/ModuleShape/popups';
import {
  InstallInstructionStepsModalData,
  InstallInstructionStepsPopup,
} from 'components/install-instruction-steps-popup';
import { ProfileUpdateFeedbackModalData, ProfileUpdateModalData } from 'containers/settings/Settings.types';
import { WalkthroughModalData } from 'containers/walkthrough';
import { WalkthroughModal } from 'containers/walkthrough/walkthrough-modal';
import {
  CancelRegistrationPopup,
  ConfirmedRegistrationPopup,
  EventSubmitCancelRegistrationModalData,
  EventSubmitConfirmedRegistrationModalData,
  EventSubmitNoSpotsAvailableModalData,
  EventSubmitUserIsAlreadyConfirmedData,
  NoSpotsAvailablePopup,
  UserIsAlreadyConfirmedPopup,
} from 'pages/event';
import { PathLockedModalData, PathUnlockedModalData } from 'pages/home/Home.types';
import { ExternalContentDisclaimer } from 'pages/home/popups';
import { CategoryFilterPopup } from 'pages/home/popups/category-filter/CategoryFilterPopup';
import { CategoryFilterModalData } from 'pages/home/popups/category-filter/CategoryFilterPopup.types';
import { ExternalContentDisclaimerModal } from 'pages/home/popups/external-content-disclaimer/ExternalContentDisclaimer.types';
import { PathLockedPopup } from 'pages/home/popups/path-locked/PathLockedPopup';
import { PathUnlockedPopup } from 'pages/home/popups/path-unlocked/PathUnlockedPopup';
import {
  CertificationCompletedModal,
  CertificationPassedModalData,
  CertificationShareModal,
  CertificationShareModalData,
  LevelCompletedModal,
  LevelPassedModalData,
  NoAccessToContentModal,
  NoAccessToContentModalData,
  PathCompletedModal,
  PathEndModalData,
  RatingFeedbackModal,
  RatingFeedbackModalData,
  RatingModal,
  RatingModalData,
  RetentionTestData,
  RetentionTestModal,
  TipModal,
  TipModalData,
  UnlockedFinalTestModal,
} from 'pages/lepa';
import { ProfileUpdateFeedbackModal } from 'pages/lepa/modals/ProfileUpdateFeedbackModal';
import { ProfileUpdateModal } from 'pages/lepa/modals/ProfileUpdateModal';
import {
  PersonalityTestFeedbackModalData,
  PersonalityTestStartModalData,
} from 'pages/personality-test/PersonalityTest.types';
import { PersonalityTestFeedbackPopup, PersonalityTestStartPopup } from 'pages/personality-test/popups';
import { RewardsModal } from 'pages/skill/modals';
import { RewardsModalData } from 'pages/skill/modals/rewards-modal/RewardsModal.types';
import React, { ReactElement } from 'react';
import isNodeEnv from 'util/isNodeEnv';
import { LemoLimitReachedModalData, LockedLemoModalData } from '../../components/ModuleShape/types';
import { ModalTypes, ModalRendererProps as Props } from './ModalController.types';

function isModalTypeSupported(id: ModalTypes): id is ModalTypes {
  return Object.values(ModalTypes).includes(id);
}

/**
 * ModalRenderer is responsible to render the correct modal
 * based on an id.
 */
const ModalRenderer: React.FC<Props> = ({ modal, onModalClose, open }) => {
  const modalType = modal?.type;

  if (!isModalTypeSupported(modalType)) {
    // eslint-disable-next-line no-console
    if (isNodeEnv('development')) {
      console.warn('a modal was enqueued but had no valid type', modalType);
    }

    // we need to remove this modal from the queue so it doesn't block the next ones
    onModalClose();

    return null;
  }

  const defaultModalProps = { onModalClose, open };

  const handler: { [id in ModalTypes]: ReactElement | null } = {
    [ModalTypes.MODULE_STAR_REWARD]: (
      <RewardsModal
        {...(modal as RewardsModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.BADGE_UNLOCKED]: (
      <RewardsModal
        {...(modal as RewardsModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.LEPA_RATING]: (
      <RatingModal
        {...(modal as RatingModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.RATING_FEEDBACK]: (
      <RatingFeedbackModal
        {...(modal as RatingFeedbackModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.PROFILE_UPDATE]: (
      <ProfileUpdateModal
        {...(modal as ProfileUpdateModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.PROFILE_UPDATE_FEEDBACK]: (
      <ProfileUpdateFeedbackModal
        {...(modal as ProfileUpdateFeedbackModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.PATH_END]: (
      <PathCompletedModal
        {...(modal as PathEndModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.RETENTION_QUIZ]: (
      <RetentionTestModal
        {...(modal as RetentionTestData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.LEVEL_PASSED]: (
      <LevelCompletedModal
        {...(modal as LevelPassedModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.LEVEL_FAILED]: (
      <RewardsModal
        {...(modal as RewardsModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.SKILL_FAILED]: (
      <RewardsModal
        {...(modal as RewardsModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.SKILL_PASSED]: (
      <RewardsModal
        {...(modal as RewardsModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.UNLOCKED_FINAL_TEST]: (
      <UnlockedFinalTestModal
        {...(modal as RewardsModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.CERTIFICATION_PASSED]: (
      <CertificationCompletedModal
        {...(modal as CertificationPassedModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.CERTIFICATION_SHARE]: (
      <CertificationShareModal
        {...(modal as CertificationShareModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.WALKTHROUGH]: (
      <WalkthroughModal
        {...(modal as WalkthroughModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.TIP]: (
      <TipModal
        {...(modal as TipModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.PERSONALITY_TEST_START]: (
      <PersonalityTestStartPopup
        {...(modal as PersonalityTestStartModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.PERSONALITY_TEST_FEEDBACK]: (
      <PersonalityTestFeedbackPopup
        {...(modal as PersonalityTestFeedbackModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.EXTERNAL_CONTENT_DISCLAIMER]: (
      <ExternalContentDisclaimer
        {...(modal as ExternalContentDisclaimerModal)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.LEMO_LOCKED]: (
      <LockedLemoPopup
        {...(modal as LockedLemoModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.LEMO_LIMIT_REACHED]: (
      <LemoLimitReachedPopup
        {...(modal as LemoLimitReachedModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.EVENT_SUBMIT_CONFIRMED_REGISTRATION]: (
      <ConfirmedRegistrationPopup
        {...(modal as EventSubmitConfirmedRegistrationModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.EVENT_SUBMIT_NO_SPOTS_AVAILABLE]: (
      <NoSpotsAvailablePopup
        {...(modal as EventSubmitNoSpotsAvailableModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.EVENT_SUBMIT_CANCEL_REGISTRATION]: (
      <CancelRegistrationPopup
        {...(modal as EventSubmitCancelRegistrationModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.EVENT_SUBMIT_USER_IS_ALREADY_CONFIRMED]: (
      <UserIsAlreadyConfirmedPopup
        {...(modal as EventSubmitUserIsAlreadyConfirmedData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.PATH_UNLOCKED]: (
      <PathUnlockedPopup
        {...(modal as PathUnlockedModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.PATH_LOCKED]: (
      <PathLockedPopup
        {...(modal as PathLockedModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.NO_ACCESS_TO_CONTENT]: (
      <NoAccessToContentModal
        {...(modal as NoAccessToContentModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.CATEGORY_FILTER]: (
      <CategoryFilterPopup
        {...(modal as CategoryFilterModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.INSTALL_INSTRUCTION_STEPS]: (
      <InstallInstructionStepsPopup
        {...(modal as InstallInstructionStepsModalData)}
        {...defaultModalProps}
      />
    ),
    [ModalTypes.EMPTY]: null,
  };

  const modalToRender = handler[modalType];

  return modalToRender;
};

export default ModalRenderer;
