import { skipToken } from '@reduxjs/toolkit/dist/query';
import classnames from 'classnames';
import { Loading } from 'components/Loading';
import { Media } from 'components/media';
import BottomNavigation from 'containers/BottomNavigation';
import { PreviewBottomNavigation } from 'containers/BottomNavigation/PreviewBottomNavigation';
import { ProfileStore } from 'containers/Profile/types';
import { Locale, defaultLocale } from 'containers/language-provider';
import { SettingsStore } from 'containers/settings/Settings.types';
import { Location } from 'history';
import usePersonalityTest from 'pages/personality-test/PersonalityTest.controller';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import { actions } from 'store/feature-flags';
import { Store } from 'store/types';
import { doNothing } from 'util/doNothing';
import enhancedMatchPath from 'util/enhancedMatchPath';
import { untrustedRoutes } from '../App';
import { RootProps as Props } from '../App.types';
import Routes, { routesWithNav } from '../routes';
import { MainScroll } from './MainScroll';

export const SPLASHSCREEN_MINIMUM_TIME = 2500;

export const Root: React.FC<Props> = ({
  history,
  location,
  match,
  isAuthorizing,
  splashSrc,
  backgroundSrc,
  isPreviewMode,
  routesWithBackgroundImage,
  features,
}) => {
  const mainScrollRef = useRef<HTMLDivElement | null>(null);
  const [hasMinSplashTime, setHasMinSplashTime] = useState(false);
  const locale = useSelector<Store, Locale>(({ profile }) => profile.user.locale || defaultLocale);
  const { id: accountId } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);
  const { success: passwordChangedSuccessfully } = useSelector<Store, SettingsStore['change']['password']>(
    ({ settings }) => settings.change.password,
  );
  const dispatch = useDispatch();
  const isFetchingFeatureFlags = useSelector<Store, boolean>(({ featureFlags }) => featureFlags.pending);
  const isLoadingCrashCourse = useSelector<Store, boolean>(({ crashCourse }) => crashCourse.isLoading);

  const isLoadingLearningContent = isAuthorizing || isLoadingCrashCourse;

  const isUntrusted = untrustedRoutes.some((route) => location.pathname.includes(route));
  const isLoading = (!isUntrusted && isLoadingLearningContent) || hasMinSplashTime || isFetchingFeatureFlags;

  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { isFetching: isFetchingDynamicConfiguration } = useGetDynamicConfigurationQuery(
    getDynamicConfigurationQueryParams,
  );

  usePersonalityTest();

  React.useEffect(() => {
    if (!accountId) {
      return;
    }
    dispatch(actions.getAllFlags());
  }, [accountId, dispatch]);

  React.useEffect(() => {
    if (splashSrc && isLoadingLearningContent && !hasMinSplashTime) {
      setHasMinSplashTime(true);
      const timer = setTimeout(() => setHasMinSplashTime(false), SPLASHSCREEN_MINIMUM_TIME);
      return () => {
        if (hasMinSplashTime) {
          setHasMinSplashTime(false);
          clearTimeout(timer);
        }
      };
    }
    return doNothing;
  }, [hasMinSplashTime, isLoadingLearningContent, splashSrc]);

  const isRouteWithBackground = (route: Location['pathname']) => enhancedMatchPath(route, routesWithBackgroundImage);
  const showBackground = isRouteWithBackground(location.pathname);

  const shouldRenderNavbar = !isPreviewMode && enhancedMatchPath(location.pathname, routesWithNav);

  const shouldRenderRoutes =
    !isFetchingFeatureFlags && (!isFetchingDynamicConfiguration || passwordChangedSuccessfully);

  return (
    <div
      id='rootContainer'
      key='root'
      className={classnames('relative flex flex-col w-full h-full m-auto my-0', {
        'lg:flex-row': !isPreviewMode,
      })}
    >
      {showBackground && backgroundSrc && (
        <>
          <Media
            alt='background'
            className='absolute bg-center bg-no-repeat bg-cover -z-10'
            src={backgroundSrc}
          />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-white opacity-40 -z-10' />
        </>
      )}

      {shouldRenderNavbar && (
        <BottomNavigation
          className='flex-1 lg:justify-start lg:order-none lg:flex-col lg:py-1 lg:px-3.5'
          locale={locale}
          match={match}
          history={history}
          location={location}
          features={features}
        />
      )}

      {shouldRenderRoutes && (
        <div
          className='w-full h-full overflow-y-auto'
          ref={mainScrollRef}
        >
          <MainScroll scrollRef={mainScrollRef}>
            <Routes />
          </MainScroll>
        </div>
      )}

      {isLoading && <Loading splashSrc={splashSrc} />}

      {isPreviewMode && <PreviewBottomNavigation locale={locale} />}
    </div>
  );
};
