import { rootRestApi } from 'store/root.api';
import { GetCategoriesPayload, GetCategoriesResponse } from './Home.types';

export const categoryApi = rootRestApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query<GetCategoriesResponse, GetCategoriesPayload>({
      query: ({ userId, accountId }) => `/users/${userId}/categories?accountId=${accountId}`,
    }),
  }),
});

export const { useGetCategoriesQuery } = categoryApi;
