import { RouteProps, matchPath } from 'react-router-dom';

/**
 * Just like `matchPath`
 * https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/matchPath.md
 * but compares the path with an array of routes
 */
const enhancedMatchPath = (pathname: string, routes: RouteProps[]) => {
  for (let i = routes.length - 1; i >= 0; i -= 1) {
    if (matchPath(pathname, routes[i]) !== null) {
      return true;
    }
  }

  return false;
};

export default enhancedMatchPath;
