import trophy from 'assets/images/trophy.svg';
import classNames from 'classnames';
import Textfield from 'components/Textfield';
import { Button } from 'components/button';
import { Popup } from 'components/popup';
import { StarsRating } from 'components/stars-rating';
import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useCreateRatingMutation } from '../Lepa.api';
import { RatingModalProps as Props } from '../Lepa.types';

export const RatingModal: React.FC<Props> = ({
  open,
  modal: { pathId, pathName, onSubmitCallback, onClose },
  onModalClose,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const { enqueueModal } = useModalQueue();
  const [createRatingMutation, { error }] = useCreateRatingMutation();

  const trophyAnimation = useSpring({
    from: { transform: 'scale(0.5)' },
    to: { transform: 'scale(1)' },
    config: {
      duration: 750,
    },
    delay: 500,
  });

  const handleClose = () => {
    onClose();
    onModalClose();
    history.push('/');
  };

  const handleSubmit = async () => {
    try {
      await createRatingMutation({
        lepaId: pathId,
        body: {
          rating,
          ...(comment && { comment }),
        },
      }).unwrap();
      onSubmitCallback();
      onModalClose();
      enqueueModal({ type: ModalTypes.RATING_FEEDBACK });
    } catch {
      // do nothing
    }
  };

  const handleInputComment = (value: string) => {
    setComment(value);
  };

  const isAlreadySubmitted = error && 'status' in error && error.status === 409;

  return (
    <Popup
      variant='lepaRating'
      open={open}
      onRequestClose={handleClose}
    >
      <div className='relative flex flex-col content-center text-center'>
        <div
          key='text-container'
          className='pt-2 mt-8 mb-5 text-2xl font-extrabold text-center uppercase 2xs:text-lg 2xs:mt-4 2xs:mb-2'
        >
          <h1>
            <FormattedMessage id={'comp.rating-popup.congratulations'} />
          </h1>
        </div>
        <p className='text-lg font-normal 2xs:text-md mb-7 2xs:mb-4 text-neutral-700'>
          <FormattedMessage
            id={'comp.rating-popup.you-finished'}
            values={{ learningPathName: pathName }}
          />
        </p>
        <div className='self-center mb-4'>
          <animated.img
            src={trophy}
            className='w-28 h-28 2xs:w-20 2xs:h-20'
            alt=''
            style={trophyAnimation}
          />
        </div>
        <p className='self-center w-64 text-lg font-normal text-center 2xs:text-md mb-7 2xs:mb-4 text-neutral-700'>
          <FormattedMessage id={'comp.rating-popup.rate-experience'} />
        </p>
        <div className='flex justify-center'>
          <StarsRating onClick={setRating} />
        </div>
        <Textfield
          className='min-w-0 mt-8 mb-8 2xs:mt-6 2xs:mb-4'
          placeholder={intl.formatMessage({ id: 'comp.rating-popup.leave-comment' })}
          value={comment}
          withBorder
          name='comment'
          onChange={handleInputComment}
          maxLength={150}
        />
        <Button
          data-testid='rating-modal-submit-button'
          className='self-center w-32 mb-5 xs:mb-0'
          variant='outlined'
          disabled={!rating}
          onClick={handleSubmit}
        >
          <FormattedMessage id={'comp.rating-popup.submit'} />
        </Button>
        <p
          className={classNames('text-red-600', {
            invisible: !error,
          })}
          data-testid='rating-modal-submit-error'
        >
          {isAlreadySubmitted ? (
            <FormattedMessage
              id={'comp.rating-popup.already-rated'}
              defaultMessage='Feedback already submitted'
            />
          ) : (
            <FormattedMessage
              id={'comp.rating-popup.error-message'}
              defaultMessage='Feedback submission failed'
            />
          )}
        </p>
      </div>
    </Popup>
  );
};
