import { detect } from 'detect-browser';
import { loader } from 'graphql.macro';
import requestGraphql from '../requestGraphql';

const trackBrowserMutation = loader('./track-browser.graphql');

/**
 * Detects the user's browser information along with the App Version
 * and sends it to the API
 */
const browserDetect = () => {
  const appVersion = process.env.REACT_APP_VERSION;
  const browser = detect();

  if (browser && browser.name && browser.version && appVersion) {
    const body = {
      query: trackBrowserMutation,
      variables: { ...browser, appVersion },
    };

    // Don't care about response result
    requestGraphql(body);
  }
};

export default browserDetect;
