import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actions as authActions, GetUserSuccessPayload } from 'containers/authentication';
import { PersonalityTestState, PersonalityTestStatus } from './PersonalityTest.types';

const initialState: PersonalityTestState = {
  status: PersonalityTestStatus.CLOSED,
};

const slice = createSlice({
  name: 'personality-test',
  initialState,
  reducers: {
    addCollection: (state, action: PayloadAction<string>) => {
      const currentCollections = state.test?.collections ?? [];
      return {
        ...state,
        test: {
          ...state.test,
          collections: [...currentCollections, action.payload],
        },
      };
    },
    removeCollection: (state, action: PayloadAction<string>) => {
      const currentCollections = state.test?.collections ?? [];
      const nextCollections = currentCollections.filter((collection) => collection !== action.payload);
      return {
        ...state,
        test: {
          ...state.test,
          collections: nextCollections,
        },
      };
    },
    setTags: (state, action: PayloadAction<string[]>) => ({
      ...state,
      test: {
        ...state.test,
        tags: [...action.payload],
      },
    }),
    setStatus: (state, action: PayloadAction<PersonalityTestStatus>) => ({
      ...state,
      status: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(authActions.getUserSuccess.type, (state, action) => {
      const { payload } = action as PayloadAction<GetUserSuccessPayload>;

      return {
        ...state,
        status: payload.persona ? PersonalityTestStatus.CLOSED : PersonalityTestStatus._CANSTART,
      };
    });
  },
});

export const { actions } = slice;
export default slice.reducer;
