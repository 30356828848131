import Confetti from 'react-confetti';
import { CurveSvgProps, CurveBackgroundProps as Props } from './InstallInstructionStepsPopup.types';

const CurveSvg: React.FC<CurveSvgProps> = ({ color }) => (
  <svg
    viewBox='0 0 194 37'
    preserveAspectRatio='none'
  >
    <path
      d='M 98 0 C 44 0 0 17 0 37 H 0 L 194 37 C 194 17 150 0 98 0 Z'
      fill={color}
    />
  </svg>
);

const WhiteBlockSvg: React.FC = () => (
  <svg
    viewBox='0 0 194 37'
    preserveAspectRatio='none'
    className='absolute top-0 left-0 z-10'
  >
    <path
      d='M 0 0 L 194 0 L 194 37 L 0 37 Z M 98 0 C 44 0 0 17 0 37 H 194 C 194 17 150 0 98 0 Z'
      fill='white'
    />
  </svg>
);

export const CurveBackground: React.FC<Props> = ({ color, withConfetti }) => (
  <div className='absolute left-0 z-0 w-full -mt-px h-11/20 top-9/20'>
    {withConfetti && <Confetti className='absolute top-0' />}
    <div className='relative'>
      <CurveSvg color={color} />
      {withConfetti && <WhiteBlockSvg />}
    </div>
    <div
      className='h-full -mt-px'
      style={{ backgroundColor: color }}
    />
  </div>
);
