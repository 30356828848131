import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import theme from 'theme';
import tinycolor from 'tinycolor2';
import { PlayButtonProps as Props } from './PlayButton.types';

export const PlayButton: React.FC<Props> = ({ bgColor = theme.colors.orangeRed, className, ...rest }) => {
  const svgIconColor = tinycolor
    .mostReadable(bgColor, ['#FFFFFF', '#222222'], { includeFallbackColors: true })
    .toString();

  return (
    <div
      className={classnames('relative rounded-full text-center cursor-pointer my-0 mx-auto', className)}
      style={{ backgroundColor: bgColor }}
      data-testid='e2e-button'
      {...rest}
    >
      <SvgIcon
        className='absolute ml-1 transform left-5/10 top-5/10 translate -translate-x-5/10 -translate-y-5/10 w-4/10'
        name='play-outlined'
        size={38}
        viewBox='0 0 140 140'
        color={svgIconColor}
      />
    </div>
  );
};
