import { XPoval } from 'components/popup';
import { AwardBadgeProps as Props } from './AwardBadge.types';

export const AwardBadge: React.FC<Props> = ({ idx, fill, text, xpColor, points }) => (
  <svg
    className='my-0 mx-1.5 max-w-[5rem] opacity-0 animate-punchIn'
    style={{ animationDelay: `${String(1.2 + 0.22 * idx)}s` }}
    width='100%'
    height='100%'
    viewBox='0 0 250 251'
  >
    <XPoval
      style={{ transform: 'translate3d(-45%, -45%, 0) scale(1.9)' }}
      fill={fill}
      text={text}
      xpColor={xpColor}
      xp={`+ ${String(points)} XP!`}
    />
  </svg>
);
