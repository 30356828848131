import { TopBar } from 'components/top-bar';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { PageContainer } from '../../../../components/page-container';
import { NewChatViewProps } from './NewChat.types';
import { ChannelSearch } from './one-to-one/ChannelSearch';

export const NewChat: FC<NewChatViewProps> = ({ onChangeView }) => {
  const history = useHistory();
  const intl = useIntl();

  const handleBack = () => {
    history.push('/community/chat');
  };

  return (
    <>
      <TopBar backButton={<TopBar.IconButton onClick={handleBack} />}>
        <h3 className='flex-1 p-4 text-lg font-medium text-center text-white uppercase'>
          {intl.formatMessage({ id: 'community.chat.new.chat', defaultMessage: 'New chat' })}
        </h3>
      </TopBar>

      <PageContainer className='h-full overflow-y-hidden'>
        <ChannelSearch onChangeView={onChangeView} />
      </PageContainer>
    </>
  );
};
