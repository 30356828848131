import { PageContainer } from 'components/page-container';
import { TopBar } from 'components/top-bar';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ChannelMemberResponse, MemberSort } from 'stream-chat';
import { Channel, MessageInput, MessageList, Window, useChatContext } from 'stream-chat-react';
import { EmptyChannel } from '../EmptyChannel';
import { Message, MessageSystem } from '../components/message';
import { MessageInput as CustomMessageInput } from '../components/message-input';
import { ChatHeader } from './ChatHeader';
import { GroupInfo } from './GroupInfo';

export const Chat: React.FC = () => {
  const history = useHistory();
  const { channelId } = useParams<{ channelId: string }>();
  const { client, setActiveChannel, channel } = useChatContext();

  const [members, setMembers] = useState<ChannelMemberResponse[]>([]);
  const [isChatInfoOpen, setIsChatInfoOpen] = useState(false);

  const isGroupChat = Boolean(channel?.data?.isGroup);

  const setActiveChannelFromURL = useCallback(async () => {
    const activeChannel = client.getChannelById('messaging', channelId, {});
    await activeChannel.watch();
    setActiveChannel(activeChannel);
  }, [channelId, client, setActiveChannel]);

  const getChatMembers = useCallback(async () => {
    if (!channel) {
      return;
    }

    const sort: MemberSort = { name: 1 };
    const response = await channel.queryMembers({}, sort);
    setMembers(response.members);
  }, [channel]);

  useEffect(() => {
    if (!channelId || !client) {
      return;
    }

    void setActiveChannelFromURL();
  }, [channelId, client, setActiveChannel, setActiveChannelFromURL]);

  useEffect(() => {
    void getChatMembers();
  }, [getChatMembers]);

  const handleBack = () => {
    history.push('/community/chat');
  };

  const handleOpenChatInfo = () => {
    if (!isGroupChat) {
      return;
    }
    setIsChatInfoOpen(true);
  };

  const handleCloseChatInfo = () => setIsChatInfoOpen(false);

  if (!channel || !client) {
    return null;
  }

  return (
    <div className='relative h-full bg-zinc-100'>
      <TopBar backButton={<TopBar.IconButton onClick={handleBack} />}>
        <ChatHeader
          members={members}
          userId={client.userID}
          openChatInfo={handleOpenChatInfo}
        />
      </TopBar>
      <PageContainer className='h-[calc(100%-4rem)]'>
        <Channel
          Message={Message}
          MessageSystem={MessageSystem}
          Input={CustomMessageInput}
          EmptyStateIndicator={EmptyChannel}
        >
          <Window>
            <MessageList />
            <MessageInput
              grow
              focus
              additionalTextareaProps={{
                className:
                  'overflow-auto px-4 rounded-lg w-full h-auto leading-relaxed resize-none shadow-none outline-none',
              }}
            />
          </Window>
        </Channel>
      </PageContainer>

      {isChatInfoOpen && (
        <GroupInfo
          members={members}
          userId={client.userID}
          channel={channel}
          refreshMembers={getChatMembers}
          onClose={handleCloseChatInfo}
        />
      )}
    </div>
  );
};
