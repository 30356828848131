import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChannelFilters, MemberSort, UserResponse } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { doNothing } from 'util/doNothing';

export const useSearchUsers = (filters: ChannelFilters) => {
  const { client } = useChatContext();

  const [users, setUsers] = useState<UserResponse<DefaultStreamChatGenerics>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const getUsers = useCallback(
    async (value = '') => {
      setIsLoading(true);
      try {
        const sort: MemberSort = { name: 1 };
        const response = await client.queryUsers(
          {
            ...filters,
            ...(value !== '' && { name: { $autocomplete: String(value) } }),
          },
          sort,
        );
        setUsers(response.users);
      } catch (error: unknown) {
        doNothing();
      } finally {
        setIsLoading(false);
      }
    },
    [client, filters],
  );

  const debouncedSearch = useMemo(() => debounce((value) => getUsers(value), 500), [getUsers]);

  const submitSearch = useCallback((value: string) => debouncedSearch(value), [debouncedSearch]);

  useEffect(() => {
    void getUsers();
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch, getUsers]);

  const searchUsers = useCallback(
    async (newValue = '') => {
      if (searchText !== newValue) {
        setSearchText(newValue);
      }

      try {
        await submitSearch(newValue);
      } catch (error: unknown) {
        doNothing();
      }
    },
    [searchText, submitSearch],
  );

  return { searchUsers, users, isLoading, searchText };
};
