import classnames from 'classnames';
import { Button } from 'components/button';
import { Media } from 'components/media';
import { SvgIcon } from 'components/svg-icon';
import { Typography } from 'components/typography';
import { API_URL } from 'constants/endpoints';
import { Account } from 'containers/Profile';
import React, { useState } from 'react';
import { Config } from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { Store } from 'store/types';
import theme from 'theme';
import { LikeButton, PollAnswerOptions, SharedResults } from '.';
import { actions } from '../Newsfeed.ducks';
import {
  CoachIconProps,
  NewsItemPollVariant,
  NewsItemVariantsProps,
  PollResultsItemProps,
  NewsItemProps as Props,
} from '../Newsfeed.types';
import { hasPollBeenAnswered } from '../controller';
import { RelativeDate } from './relative-date/RelativeDate';
import Thumbnail from './thumbnail';

export const YT_VARS: Config = {
  youtube: {
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      rel: 0,
    },
  },
};

/**
 * Single news item component, each News item acts as a preview with some initial data for the news
 * and when clicked, it opens a window with the full content.
 */
export const NewsItem: React.FC<Props> = ({ post, onClick, ...rest }) => {
  const [selectedAnswerID, setSelectedAnswerID] = useState<string | null>(null);
  const { primaryColor = theme.colors.turquoiseBlue } = useSelector<Store, Account>(({ profile }) => profile.account);

  const isCommunityEnabled = useFeatureFlag(FlagKeys.COMMUNITY_CHAT);
  const color = isCommunityEnabled ? primaryColor : theme.colors.turquoiseBlue;

  const calculatedHeight = post.type === 'poll' ? '100%' : '220px';
  const hasSubmitted = hasPollBeenAnswered(post);

  const dispatch = useDispatch();

  const likePostHandler = (wasLiked: boolean) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    dispatch(actions.likePost({ post, like: !wasLiked }));
  };

  const onClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClick();
  };

  const answerPollQuestionHandler = (answerID: string) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setSelectedAnswerID(answerID);
  };

  const submitPollHandler = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (!post || !selectedAnswerID) {
      return;
    }
    dispatch(actions.answerPoll({ answerID: selectedAnswerID, postID: post.id }));
  };

  const renderMedia = () => {
    if (post.thumbnail) {
      return (
        <div className='relative w-full p-0 mb-1 pb-9/16'>
          <Media
            alt='post'
            className='absolute overflow-hidden text-center rounded-t-lg'
            src={post.thumbnail}
            external
          />
        </div>
      );
    }
    if (post.videoURL) {
      return (
        <div className='relative w-full p-0 mb-1 pb-9/16'>
          <Thumbnail
            videoURL={post.videoURL}
            className='absolute'
          />
        </div>
      );
    }
    if (post.imageURL) {
      return (
        <div className='relative w-full p-0 mb-1 pb-9/16'>
          <Media
            alt='post'
            className='absolute overflow-hidden text-center rounded-t-lg'
            src={`${API_URL}/${post.imageURL}`}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <li
      className='w-full mb-5 overflow-hidden border rounded-lg shadow cursor-pointer border-whiteFour'
      onClick={onClickHandler}
      {...rest}
    >
      {!post.sharedAt && renderMedia()}

      {
        {
          poll: (
            <PollItem
              post={post}
              height={calculatedHeight}
              onPostLike={likePostHandler}
              onSubmit={submitPollHandler}
              selectedAnswerID={selectedAnswerID}
              onAnswerClick={!hasSubmitted ? answerPollQuestionHandler : undefined}
              disabled={!!hasSubmitted || !selectedAnswerID}
              hasSubmited={hasSubmitted}
              color={color}
            />
          ),
          text: (
            <TextItem
              post={post}
              height={calculatedHeight}
              onPostLike={likePostHandler}
              color={color}
            />
          ),
        }[post.type]
      }
    </li>
  );
};

/** Poll Results variant component for News Item */
const PollResultsItem: React.FC<PollResultsItemProps> = ({ post, color }) => {
  if (!post.answers) {
    return null;
  }

  return (
    <article className='p-3.5 pb-0'>
      <div className='flex justify-between align-center'>
        <RelativeDate date={post.publishedAt} />
      </div>

      <h2
        className='mt-0 text-base font-medium break-words select-none'
        style={{ color }}
      >
        {post.title}
      </h2>

      <div className='flex flex-col items-start'>
        <h3 className='pt-0 mt-0 mb-1 text-sm font-medium break-words text-grey3'>{post.subtitle}</h3>
        <Typography
          className='h-16 text-sm font-medium truncate select-none text-warmGrey max-h-20 line-clamp-3'
          rich
        >
          {post.description}
        </Typography>
      </div>

      <SharedResults post={post} />
    </article>
  );
};

const CoachIcon: React.FC<CoachIconProps> = ({ color }) => (
  <div className='flex justify-end select-none align-center'>
    <div className='flex items-center justify-end p-4 pr-0'>
      <SvgIcon
        name='profile'
        size={26}
        color={color}
      />
    </div>
  </div>
);

/** Text variant component for News Item */
const TextItem: React.FC<NewsItemVariantsProps> = ({ height, post, color, onPostLike }) => {
  const isPersonalCoachItem = !post.likeable;

  return (
    <article
      className='p-3.5 pb-0'
      style={{ maxHeight: height }}
    >
      <div className='flex justify-between align-center'>
        <RelativeDate date={post.publishedAt} />
      </div>
      <div className='flex flex-col items-start'>
        <h2
          className='mt-0 text-base font-medium break-words select-none'
          style={{ color }}
        >
          {post.title}
        </h2>
        <h3 className='pt-0 mt-0 mb-1 text-sm font-medium break-words text-grey3'>{post.subtitle}</h3>
        <Typography
          className='h-16 text-sm font-medium truncate select-none text-warmGrey max-h-20 line-clamp-3'
          rich
        >
          {post.description}
        </Typography>
      </div>
      {post.likeable && (
        <LikeButton
          liked={Boolean(post.like)}
          likes={post.likes}
          onClickHandler={onPostLike}
          color={color}
        />
      )}
      {isPersonalCoachItem && <CoachIcon color={color} />}
    </article>
  );
};

/** Poll variant component for News Item */
const PollItem: React.FC<NewsItemPollVariant> = ({
  color,
  height,
  post,
  onPostLike,
  selectedAnswerID,
  onSubmit,
  onAnswerClick,
  disabled,
  hasSubmited,
}) => {
  const buttonColor = disabled ? theme.colors.grey2 : color;

  if (post.sharedAt) {
    return (
      <PollResultsItem
        post={post}
        color={color}
      />
    );
  }

  return (
    <article
      className='p-3.5 pb-0'
      style={{ maxHeight: height }}
    >
      <div className='flex justify-between align-center'>
        <RelativeDate date={post.publishedAt} />
      </div>

      <div className='flex justify-between align-center'>
        <h2
          className='mt-0 text-base font-medium break-words select-none'
          style={{ color }}
        >
          {post.title}
        </h2>
      </div>

      <div>
        <PollAnswerOptions
          onAnswerClick={onAnswerClick}
          post={post}
          color={color}
          selectedAnswerID={selectedAnswerID}
        />

        <div
          className={classnames('flex items-center pb-2.5', {
            'justify-end': hasSubmited,
            'justify-between': !hasSubmited,
          })}
        >
          {!hasSubmited && (
            <Button
              variant='outlined'
              disabled={disabled}
              onClick={onSubmit}
              style={{ color: buttonColor }}
              text='Submit'
            />
          )}
          {post.likeable && (
            <LikeButton
              liked={Boolean(post.like)}
              likes={post.likes}
              onClickHandler={onPostLike}
              color={color}
            />
          )}
        </div>
      </div>
    </article>
  );
};
