import classnames from 'classnames';
import { SvgIcon } from '../svg-icon';
import { MessageDialogProps } from './MessageDialog.types';

export const MessageDialog: React.FC<MessageDialogProps> = ({
  children,
  isMyMessage = true,
  isLastMessageInGroup = true,
  className,
  ...rest
}) => (
  <div
    className={classnames(className, 'flex mb-2 filter drop-shadow-lg rounded-lg', {
      'mr-9 ml-2.5 bg-gray-600': !isMyMessage,
      'ml-9 mr-2.5 bg-white': isMyMessage,
      'mt-2': isLastMessageInGroup,
    })}
    {...rest}
  >
    <SvgIcon
      name='triangle'
      size={16}
      viewBox='0 0 16 14'
      className={classnames('absolute bottom-0', {
        '-left-2 text-gray-600': !isMyMessage,
        '-right-1.5 text-white': isMyMessage,
        'invisible': !isLastMessageInGroup,
      })}
    />
    <div
      className={classnames('p-3 text-base font-normal text-left whitespace-pre-line w-full break-words', {
        'text-neutral-800': isMyMessage,
        'text-white': !isMyMessage,
      })}
    >
      {children}
    </div>
  </div>
);
