import { OverlayProps as Props } from './Overlay.types';

export const Overlay: React.FC<Props> = ({ children, icon, open }) => {
  if (!open) {
    return <>{children}</>;
  }

  return (
    <>
      {icon}
      <div className='opacity-50'>{children}</div>
    </>
  );
};
