import { SearchResult } from 'pages/search/search-result';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SearchResultsProps as Props } from '../Search.types';

export const SearchResults: React.FC<Props> = ({ results }) => {
  const history = useHistory();

  const handleRedirect = (to: string) => {
    history.push(to);
  };

  return (
    <div>
      <h2 className='font-medium text-lg text-lightGrey p-5'>{results.length} Results:</h2>

      {results.map((path) => (
        <div className='p-5 mb-6 rounded-lg border border-gray-300 shadow'>
          <SearchResult
            key={`path-${path.id}`}
            result={path}
            onRedirect={handleRedirect}
          />
        </div>
      ))}
    </div>
  );
};
