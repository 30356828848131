import { useEffect, useState } from 'react';
import { EventHandler } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import { doNothing } from '../../../../util/doNothing';
import { UseChannelReadProps } from '../Chat.types';

export const useChannelRead = ({ member, channel }: UseChannelReadProps) => {
  const { client } = useChatContext();

  const initialUnreadCount = channel.countUnread();
  const [unreadCount, setUnreadCount] = useState<number>(initialUnreadCount);

  useEffect(() => {
    if (!client) {
      return doNothing();
    }

    const handleMessageRead: EventHandler = (event) => {
      const isDifferentChannel = channel?.cid !== event.cid;
      if (isDifferentChannel) {
        return;
      }

      const { user } = event;
      if (user?.id !== member?.id) {
        return;
      }

      setUnreadCount(0);
    };

    client.on('message.read', handleMessageRead);

    return () => {
      client.off('message.read', handleMessageRead);
    };
  }, [channel?.cid, client, member?.id]);

  useEffect(() => {
    if (!client) {
      return doNothing();
    }

    const handleNewMessageSent: EventHandler = (event) => {
      const isDifferentChannel = channel?.cid !== event.cid;
      if (isDifferentChannel) {
        return;
      }

      setUnreadCount(event.unread_count ?? 0);
    };

    client.on('message.new', handleNewMessageSent);

    return () => {
      client.off('message.new', handleNewMessageSent);
    };
  }, [channel?.cid, client]);

  return { unreadCount };
};
