import { actions } from 'containers/modal-controller/ModalController.ducks';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import { AnimatedModalProps as Props } from './Popup.types';

const fadeAnimationConfig = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
};

const fadeScaleAnimationConfig = {
  from: {
    opacity: 0,
    transform: 'scale(0)',
  },
  enter: {
    opacity: 1,
    transform: 'scale(1)',
  },
  leave: {
    opacity: 0,
    transform: 'scale(0.2)',
  },
  config: { mass: 2, tension: 350, friction: 40 },
};

const slideAnimationConfig = {
  from: {
    opacity: 0,
    transform: 'translateY(100%)',
  },
  enter: {
    opacity: 1,
    transform: 'translateY(0%)',
  },
  leave: {
    opacity: 0,
    transform: 'translateY(100%)',
  },
};

export const AnimatedPopup: React.FC<Props> = ({ children, open, animation = 'fade-scale' }) => {
  const dispatch = useDispatch();

  const destroyPreviousModal = () => {
    dispatch(actions.setPreviousModal({ type: ModalTypes.EMPTY }));
  };

  const animationConfig = {
    'fade': fadeAnimationConfig,
    'fade-scale': fadeScaleAnimationConfig,
    'slide': slideAnimationConfig,
  }[animation];

  const transitions = useTransition(open, {
    ...animationConfig,
    onDestroyed: destroyPreviousModal,
  });

  return (
    <>
      {transitions(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className='flex items-center justify-center w-full h-full'
            >
              {children}
            </animated.div>
          ),
      )}
    </>
  );
};
