import { animated, config, useSpring } from 'react-spring';

export const Sob = () => {
  const styles = useSpring({
    loop: true,
    from: { transform: 'translateY(37px) scaleY(0)' },
    to: { transform: 'translateY(0) scaleY(1)' },
    config: config.molasses,
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='92'
      height='92'
      fill='none'
      viewBox='0 0 92 92'
    >
      <circle
        cx='45.748'
        cy='45.748'
        r='45.748'
        fill='#FFD23B'
      ></circle>
      <path
        fill='#FFB24A'
        d='M45.748 91.497c25.267 0 45.749-20.482 45.749-45.748C91.497 20.482 71.014 0 45.748 0v91.497z'
      ></path>
      <rect
        width='15.726'
        height='5.719'
        x='72.912'
        y='37.171'
        fill='#000'
        rx='2.859'
        transform='rotate(-180 72.912 37.17)'
      ></rect>
      <rect
        width='15.726'
        height='5.719'
        x='34.312'
        y='37.171'
        fill='#2D3E53'
        rx='2.859'
        transform='rotate(-180 34.312 37.17)'
      ></rect>
      <circle
        cx='45.033'
        cy='60.759'
        r='13.582'
        fill='#FF3187'
      ></circle>
      <path
        fill='#FF5178'
        d='M45.748 74.34c7.501 0 13.582-6.08 13.582-13.58 0-7.502-6.08-13.582-13.582-13.582V74.34z'
      ></path>
      <animated.g style={styles}>
        <path
          fill='#66E6FF'
          d='M27.878 86.064V37.17H22.16v48.894c-2.763 0-5.004 2.434-5.004 5.432H32.882c0-2.998-2.242-5.432-5.004-5.432z'
        ></path>
        <path
          fill='#49B9F2'
          d='M69.338 86.064V37.17h-5.719v48.894c-2.762 0-5.004 2.434-5.004 5.432H74.343c0-2.998-2.242-5.432-5.004-5.432z'
        ></path>
      </animated.g>
    </svg>
  );
};
