import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import theme from 'theme';
import { CloseIconProps } from './CloseIcon.types';

export const CloseIcon: React.FC<CloseIconProps> = ({ onClose, size = 16, color }) => {
  const iconColor = color || theme.colors.pinkishGrey;
  return (
    <SvgIcon
      className='absolute z-10 cursor-pointer top-4 right-4'
      size={size}
      name='close'
      color={iconColor}
      stroke={iconColor}
      onClick={onClose}
    />
  );
};
