/**
 * Will move a value from an index to other.
 * It will return a new array
 * @param {*[]} arr - The array to modify
 * @param {number} fromIndex - The index of the value to move
 * @param {number} toIndex - The new index of the value to move
 */
function arrayMove (arr, fromIndex, toIndex) {
  const clone = [...arr]
  Array.prototype.splice.call(clone, toIndex, 0, Array.prototype.splice.call(clone, fromIndex, 1)[0])
  return clone
}

export default arrayMove
