import { Breadcrumb, BreadcrumbsProps as Props } from 'components/Breadcrumbs/types';
import { Path } from 'history';
import React from 'react';
import { withRouter } from 'react-router-dom';
import theme from 'theme';

/**
 * Triggers the navigation event
 */
const navigate =
  (fn: (path: Path, state?: { levelId: string }) => void, path: Path, state?: { levelId: string }) => () =>
    state ? fn(path, state) : fn(path);

/**
 * Generates the breadcrumbs bar, if any are provided
 */
const generateCrumbs = (
  onHistoryPush: (path: Path, state?: { levelId: string }) => void,
  crumbs: Breadcrumb[],
  color = theme.colors.ocean,
) => {
  const crumbsStyles = { color: color || theme.colors.ocean };

  return (
    <div
      className='mt-0 mb-3 ml-3'
      style={crumbsStyles}
      data-testid='e2e-breadcrumbs'
    >
      {crumbs.map((crumb, i) => (
        <span
          key={i}
          className='text-xs font-medium'
          onClick={crumb.location ? navigate(onHistoryPush, crumb.location) : undefined}
        >
          {i === crumbs.length - 1 ? crumb.label : `${String(crumb.label)} | `}
        </span>
      ))}
    </div>
  );
};

const Breadcrumbs: React.FC<Props> = ({ history, crumbs, color }) => {
  const historyPush = (path: Path, state?: { levelId: string }) => history.push(path, state);

  return <div>{crumbs ? generateCrumbs(historyPush, crumbs, color) : null}</div>;
};

export default withRouter(Breadcrumbs);
