type ColorType =
  | 'light'
  | 'grey'
  | 'greyish'
  | 'greyishBrown'
  | 'pinkishGrey'
  | 'darkGrey'
  | 'green'
  | 'red'
  | 'redSecondary'
  | 'white'
  | 'whiteTwo'
  | 'turquoiseBlue'
  | 'ocean'
  | 'aquaMarine'
  | 'whiteFour'
  | 'warmGrey'
  | 'primary'
  | 'secondary'
  | 'yellow'
  | 'marigold'
  | 'marigoldSecondary'
  | 'blue'
  | 'blueSecondary'
  | 'blueTitle'
  | 'lightBlue'
  | 'purple'
  | 'purpleSecondary'
  | 'orange'
  | 'black'
  | 'grey2'
  | 'lightGrey'
  | 'roastedYellow'
  | 'orangeRed'
  | 'orangeRedTwo'
  | 'neutral100'
  | 'gray600'
  | 'stone50'
  | 'stone300'
  | 'zinc300'
  | 'neutral500'
  | 'neutral700';

type GradientType = 'red' | 'orange' | 'grey' | 'diamond';

export interface Theme {
  spacing: {
    unit: number;
  };
  colors: {
    [key in ColorType]: string;
  };
  gradients: {
    [key in GradientType]: {
      begin: string;
      end: string;
    };
  };
  home: {
    bg: string;
    fg: string;
  };
}

const theme: Theme = {
  spacing: {
    unit: 8,
  },
  colors: {
    light: '#dadada',
    grey: '#e2e2e2',
    grey2: '#A3A3A3',
    greyish: '#ababab',
    greyishBrown: '#4a4a4a',
    pinkishGrey: '#c3c3c3',
    darkGrey: '#474747',
    lightGrey: '#4c4c4c',
    green: '#7ed321',
    red: '#ff0000',
    redSecondary: '#EF9A9A',
    white: '#ffffff',
    black: '#000000',
    whiteTwo: '#e8e8e8',
    turquoiseBlue: '#00bbd5',
    ocean: '#01838f',
    aquaMarine: '#50e3c2',
    whiteFour: '#dadada',
    warmGrey: '#9b9b9b',
    primary: '#FF534F',
    secondary: '#E67372',
    yellow: '#ffb500',
    marigold: '#FFC000',
    marigoldSecondary: '#FFE082',
    blue: '#414cb7',
    blueSecondary: '#7986CB',
    blueTitle: '#414CB7',
    lightBlue: '#e9f5fe',
    purple: '#6931b9',
    purpleSecondary: '#9575CD',
    orange: '#ff8424',
    roastedYellow: '#FFC551',
    orangeRed: '#ff4500',
    orangeRedTwo: '#ff5800',
    neutral100: '#f5f5f5',
    gray600: '#4b5563',
    stone50: '#fafaf9',
    stone300: '#d6d3d1',
    zinc300: '#d4d4d8',
    neutral500: '#737373',
    neutral700: '#404040',
  },
  gradients: {
    red: {
      begin: '#f34280',
      end: '#f34280',
    },
    orange: {
      begin: '#FAD961',
      end: '#ff8424',
    },
    grey: {
      begin: '#ffffff',
      end: '#adadad',
    },
    diamond: {
      begin: '#fdfc47',
      end: '#24fe41',
    },
  },
  home: {
    bg: '#000000',
    fg: '#ffffff',
  },
};

export default theme;
