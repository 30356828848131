import classNames from 'classnames';
import { Overlay } from 'components/Overlay';
import { LinearProgressionBar } from 'components/ProgressionBar';
import { Card } from 'components/card';
import { Media } from 'components/media';
import { Typography } from 'components/typography';
import { HomeStore } from 'pages/home/Home.types';
import { getLecoAvatar } from 'pages/home/helpers';
import { UnlockPadlockLeco } from 'pages/home/popups/path-unlocked/UnlockPadlockLeco';
import { useLecoCardConfiguration } from 'pages/home/useLecoCardConfiguration';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import theme from 'theme';
import { getSrcset } from 'util/image';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import { LecoCardProps } from './LecoCard.types';

export const LecoCard: React.FC<LecoCardProps> = ({ leco, onPlay, onInfoOpen, ...rest }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { isVisible } = useIntersectionObserver(ref, {});

  const lecoCardConfiguration = useLecoCardConfiguration();
  const { shouldAnimateUnlocks, unlockedPathsToAnimate } = useSelector<Store, HomeStore>(({ home }) => home);
  const isPathUnlocked = unlockedPathsToAnimate.find((path) => String(path.id) === leco.id);

  const handlePlay = (event: React.MouseEvent<HTMLDivElement>) => onPlay(leco, event);

  const handleInfoOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!onInfoOpen) {
      return;
    }
    onInfoOpen(leco, event);
  };

  const { avatarUrl, external } = getLecoAvatar(leco);
  const { progress } = leco;

  const icon = lecoCardConfiguration[leco.type].getIcon(leco, { onClick: handleInfoOpen });
  const isLockedPath = !lecoCardConfiguration[leco.type].canOpen(leco);
  const shouldAnimate = Boolean(shouldAnimateUnlocks && isVisible && isPathUnlocked);
  const shouldShowLock = Boolean(isLockedPath || isPathUnlocked);

  return (
    <Card
      className={classNames('w-32 sm:w-40 lg:w-56 relative')}
      onClick={handlePlay}
      data-testid={`leco-card-${leco.id}`}
      ref={ref}
      {...rest}
    >
      <span className='sr-only'>
        <FormattedMessage
          id='comp.click-open'
          defaultMessage='Click to open {title}'
          values={{ title: leco.title }}
        />
      </span>
      <div className='relative w-full h-32 overflow-hidden'>
        <Overlay
          icon={
            <UnlockPadlockLeco
              open={shouldAnimate}
              lecoId={leco.id}
            />
          }
          open={shouldShowLock}
        >
          <Media
            loading='lazy'
            alt=''
            component='img'
            src={avatarUrl}
            className='absolute object-cover w-full h-full transform top-5/10 left-5/10 -translate-x-5/10 -translate-y-5/10'
            external={external}
            sizes='
              (max-width: 420px) 50vw,
              (max-width: 640px) 27.5vw,
              (max-width: 768px) 22.5vw,
              (max-width: 1024px) 25vw,
              (max-width: 1280px) 20vw,
              17.5vw
            '
            srcSet={getSrcset(avatarUrl ?? '', {
              sizes: [
                [1920, '1520w'],
                [1080, '720w'],
                [720, '420w'],
              ],
            })}
          />
        </Overlay>
      </div>
      <Overlay open={isLockedPath}>
        <LinearProgressionBar
          className='h-1 rounded-none'
          percentage={progress}
          color={theme.colors.turquoiseBlue}
        />
        <div className='flex items-center justify-between h-12 p-2 text-xs leading-none text-left bg-dark sm:text-sm sm:leading-none md:text-base md:leading-tight'>
          <Typography
            component='h3'
            variant='body2'
          >
            {leco.title}
          </Typography>

          <div className='w-4 h-4'>{icon}</div>
        </div>
      </Overlay>
    </Card>
  );
};
