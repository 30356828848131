import classnames from 'classnames';
import Question from 'components/Question';
import { Media } from 'components/media';
import { SvgIcon } from 'components/svg-icon';
import { API_URL } from 'constants/endpoints';
import { VideoFormat } from 'containers/FormatScreen/Formats';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import theme from 'theme';
import { getSrcset } from 'util/image';
import { isHTML } from 'util/isHTML';
import { MultipleImageAnswerProps as Props } from './types';

const createOnClick = (fn: any, value: any) => (event: any) => fn(value, event);

export const MultipleImageAnswer: React.FC<Props> = ({
  focusColor,
  selected,
  showAnswers,
  correct,
  question,
  answers,
  imgSrc,
  video,
  onChange,
}) => {
  const isRichText = isHTML(question);

  return (
    <div className='flex flex-col w-full'>
      {video && <VideoFormat url={video} />}

      {imgSrc && !video && (
        <div className='relative h-auto mx-auto my-0 overflow-hidden rounded-lg w-9/10 pb-9/16'>
          <Media
            alt='answer'
            component='img'
            allowPreview
            className='absolute w-full transform rounded-lg left-5/10 top-5/10 -translate-y-5/10 -translate-x-5/10'
            src={`${API_URL}/${imgSrc}`}
            sizes='90vw'
            srcSet={getSrcset(`${API_URL}/${imgSrc}`, {
              sizes: [
                [1520, '1520w'],
                [1120, '1120w'],
                [720, '720w'],
                [420, '420w'],
                [320, '320w'],
              ],
            })}
          />
        </div>
      )}
      {imgSrc && !video && (
        <Question
          text={question}
          rich={isRichText}
          scaleWithContent
        />
      )}

      {!imgSrc && (
        <Question
          text={question}
          scaleWithContent
        />
      )}

      <p className='pb-3 pl-3 text-xs leading-none text-greyishBrown'>
        <FormattedMessage id='comp.multipleAnswer.label' />
      </p>

      <div className='flex flex-wrap justify-between w-full px-2 py-5'>
        {answers.map((answer) => {
          const isSelected = selected.includes(answer.id);
          const showAnswerFeedback = isSelected && showAnswers;
          const isCorrect = correct.includes(answer.id);
          const isFocused = isSelected && !showAnswers;

          const isCorrectAndSelected = showAnswers && isSelected && isCorrect;
          const isCorrectAndUnselected = showAnswers && !isSelected && isCorrect;

          const isWrongAndSelected = showAnswers && isSelected && !isCorrect;
          const isWrongAndUnselected = !isSelected && !isCorrect;

          const answerStyles: React.CSSProperties = {
            borderColor: isFocused ? focusColor || theme.colors.turquoiseBlue : '',
            width: 'calc(50% - 16px)',
          };

          return (
            <div
              key={`multi-img-answers-${answer.id}`}
              className={classnames(
                'relative flex rounded-lg cursor-pointer border-4 m-2',
                { 'border-transparent': !showAnswers || isWrongAndUnselected },
                { 'border-fullRed': isWrongAndSelected },
                { 'border-yellowGreen': isCorrectAndSelected },
                { 'border-dashed border-yellowGreen': isCorrectAndUnselected },
              )}
              style={answerStyles}
              onClick={createOnClick(onChange, answer.id)}
            >
              <div className='relative w-full pb-9/16'>
                {answer.imageUrl && (
                  <Media
                    alt='answer'
                    className='absolute rounded-lg'
                    src={`${API_URL}/${answer.imageUrl}`}
                    sizes='(max-width: 768px) 45vw, 20vw'
                    srcSet={getSrcset(`${API_URL}/${answer.imageUrl}`, {
                      sizes: [
                        [1520, '1120w'],
                        [1080, '720w'],
                        [720, '420w'],
                        [420, '320w'],
                      ],
                    })}
                  />
                )}
                {showAnswerFeedback && (
                  <div className='absolute transform top-5/10 left-5/10 -translate-y-5/10 -translate-x-5/10 '>
                    <SvgIcon
                      className='transform scale-150 rounded-full'
                      name={correct.includes(answer.id) ? 'check' : 'close'}
                      size={24}
                      color={theme.colors.white}
                      style={{
                        backgroundColor: correct.includes(answer.id) ? theme.colors.green : theme.colors.red,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
