import { rootApi } from './root.api';

export enum PasswordSettings {
  NOT_ALLOWED = 'not-allowed',
  ALLOWED_NO_CURRENT_PASSWORD = 'allowed-no-current-password',
  ALLOWED_CURRENT_PASSWORD = 'allow-current-password',
}

export enum MenuDynamicConfigurationType {
  PLUGIN = 'plugin',
  ACCOUNT_FEATURE = 'account-feature',
}

export interface MenuDynamicConfiguration {
  id?: number;
  show: boolean;
  type: MenuDynamicConfigurationType;
}

export interface DynamicConfiguration {
  appSwitchingConfig: {
    cms: string;
    coachingAppFrontend: string;
  };
  menu: {
    coachingTool: MenuDynamicConfiguration;
    community: MenuDynamicConfiguration;
    newsfeed: MenuDynamicConfiguration;
  };
  plugins: {
    chat: boolean;
    chatbot: boolean;
    learningPathRating: boolean;
  };
  profile: {
    showMyLocation: boolean;
  };
  user: {
    passwordSettings: PasswordSettings;
    allowChangingEmail: boolean;
    allowDigestOptIn: boolean;
  };
  communityChat?: {
    hasChatAdminPermissions: boolean;
    isChatFileSharingEnabled: boolean;
    grantUsersFullGroupChatPermissions: boolean;
  };
}

export const dynamicConfigurationApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getDynamicConfiguration: builder.query<DynamicConfiguration, { accountId: string }>({
      query: ({ accountId }) => ({
        url: `/bff/app/configurations/${accountId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetDynamicConfigurationQuery } = dynamicConfigurationApi;
