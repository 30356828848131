import { createSelector } from 'reselect';
import { Store } from 'store/types';
import { Leco, PathCategories } from './Home.types';
import { getAllPathCategories, getBuiltInCategories } from './helpers';

export const categorizePathsSelector = createSelector<Store, Leco[], PathCategories>(
  ({ home }) => home.paths,
  (paths) => {
    const initialCategories = getBuiltInCategories();
    return getAllPathCategories(paths, initialCategories);
  },
);
