import { API_URL, REST_API_URL } from 'constants/endpoints';
import { OAuth2Credentials } from 'constants/global';
import { LoginCredentials, actions } from 'containers/authentication';
import { Command } from 'util/types';
import { AuthenticationFlow, Authentications } from './types';

const getDefaultRequestBody = ({ accountID, email, password, token }: LoginCredentials) => ({
  /* eslint-disable camelcase */
  grant_type: 'password',
  username: email,
  password,
  // server handles empty strings as valid ids, so we need to prevent it
  ...(accountID ? { account_id: accountID } : {}),
  recaptcha: token,
  /* eslint-enable camelcase */
});

const TestAuthenticationFlow: AuthenticationFlow = {
  type: Authentications.TEST,
  isInternal: true,
  isAutomaticLogin: false,
  loginAction: ({ email, password }) => {
    const token = 'bye recaptcha';
    const credentials = { email, password, token };
    return actions.login({ credentials, flow: Authentications.TEST });
  },
  getRequestUrl: () => `${API_URL}/oauth2`,
  getRequestBody: ({ accountID, email, password, token }) =>
    getDefaultRequestBody({
      accountID,
      email,
      password,
      token,
    }),
  canLogin: ({ email, password }) => Boolean(email && password),
};

const OAuth2AuthenticationFlow: AuthenticationFlow = {
  type: Authentications.OAUTH2,
  isInternal: false,
  isAutomaticLogin: false,
  loginAction: ({ email, password, token, accountID, params }) => {
    const credentials = {
      email,
      password,
      token,
      accountID,
    };
    return actions.login({ credentials, flow: Authentications.TEST, params });
  },
  getRequestUrl: () => `${API_URL}/oauth2/token`,
  getRequestBody: ({ accountID, email, password, token }) =>
    getDefaultRequestBody({
      accountID,
      email,
      password,
      token,
    }),
  canLogin: ({ email, password, token }) => Boolean(email && password && token),
};

const SSOAuthenticationFlow: AuthenticationFlow = {
  type: Authentications.SSO,
  isInternal: true,
  isAutomaticLogin: true,
  loginAction: ({ email, password, token, accountID, params }) => {
    const credentials = {
      email,
      password,
      token,
      accountID,
    };
    return actions.login({ credentials, flow: Authentications.TEST, params });
  },
  getRequestUrl: () => `${API_URL}/oauth2/token`,
  /* eslint-disable camelcase */
  getRequestBody: (_, params) => ({
    code: params?.code,
    grant_type: 'authorization_code',
    redirect_uri: OAuth2Credentials.redirect_uri,
    client_id: OAuth2Credentials.id,
  }),
  /* eslint-enable camelcase */
  canLogin: (_, params) => Boolean(params?.code),
};

const ManifestAuthenticationFlow: AuthenticationFlow = {
  type: Authentications.MANIFEST,
  isInternal: true,
  isAutomaticLogin: true,
  loginAction: ({ params }) => {
    const manifestToken = params?.manifest_token;
    if (!manifestToken) {
      throw new Error('No manifest token provided');
    }

    return actions.tokenLogin({ flow: Authentications.MANIFEST, token: manifestToken });
  },
  getRequestBody: () => ({}),
  getRequestUrl: ({ token }) => `${REST_API_URL}/manifest/${token ?? ''}`,
  canLogin: (_, params) => Boolean(params?.manifest_token),
};

export const authenticationFlows: Command<Authentications, AuthenticationFlow> = {
  [Authentications.TEST]: TestAuthenticationFlow,
  [Authentications.OAUTH2]: OAuth2AuthenticationFlow,
  [Authentications.SSO]: SSOAuthenticationFlow,
  [Authentications.MANIFEST]: ManifestAuthenticationFlow,
};
