import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RecentQueriesProps } from './RecentQueries.types';
import { Query } from './query';

export const RecentQueries: React.FC<RecentQueriesProps> = ({ queries, onQueryClick }) => {
  const reversedQueries = queries.slice().reverse();

  return (
    <div>
      <h4 className='font-medium text-lg text-lightGrey p-5'>
        <FormattedMessage id='search.recent' />
      </h4>
      {reversedQueries.map((query) => (
        <Query
          key={`query-${query}`}
          query={query}
          onClick={onQueryClick}
        />
      ))}
    </div>
  );
};
