import { Loading } from 'components/Loading';
import Textfield from 'components/Textfield';
import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { removeSpaces } from 'util/strings';
import { RegisterProps, RegisterState } from './Register.types';

class Register extends React.Component<RegisterProps, RegisterState> {
  public state = {
    id: '',
    email: '',
    image: '',
    password: '',
    confirmPassword: '',
    validationErrorFields: [],
  };

  public componentDidMount() {
    const { token } = this.props.match.params;
    if (token) {
      this.props.validateInvite(token);
    }
  }

  public componentDidUpdate(prevProps: RegisterProps) {
    const { register: prevRegister } = prevProps;
    const { register } = this.props;

    if (prevRegister.isValidating !== register.isValidating && !register.isValidating) {
      // If the validation was successfully, then set the initial values
      if (register.isValidToken) {
        const { email, id } = register.invite;
        this.setState({ email, id });
      }
    }
  }

  public render() {
    const { register, intl } = this.props;
    const { isCreating, isValidating, error } = register;
    const { password, confirmPassword, validationErrorFields } = this.state;

    const isWorking = isValidating || isCreating;
    const isValidInput = password && confirmPassword;

    if (isWorking) {
      return <Loading />;
    }

    return (
      <div className='p-6 pt-2.5 overflow-y-hidden h-full'>
        <div className='w-full p-1 border-b text-warmGrey border-warmGrey mt-7'>
          <FormattedMessage id='register.title' />
        </div>

        {error && (
          <div className='p-2 pt-6 text-center text-fullRed'>
            <p>{error}</p>
          </div>
        )}

        <PageContainer className='flex items-center justify-center w-full h-full'>
          <div className='w-full'>
            <div className='pb-4 pl-0 pr-0 text-sm font-bold tracking-wide text-center text-yellow-500 uppercase pt-7'>
              <FormattedMessage id='register.setPassword' />
            </div>

            <div data-testid='register-form'>
              <Textfield
                className='mb-2.5'
                placeholder={intl.formatMessage({ id: 'register.newPasswordPlaceholder' })}
                value={password}
                onChange={this.handlePasswordChange}
                withBorder
                typeStyle='login'
                type='password'
                name='password'
                error={!!error || this.hasError('password', validationErrorFields)}
              />
              <Textfield
                className='mb-2.5'
                placeholder={intl.formatMessage({ id: 'register.confirmPasswordPlaceholder' })}
                value={confirmPassword}
                onChange={this.handlePasswordChange}
                withBorder
                typeStyle='login'
                type='password'
                name='confirmPassword'
                error={!!error || this.hasError('confirmPassword', validationErrorFields)}
              />

              <div className='flex items-center justify-end text-right'>
                {error && (
                  <Button
                    className='w-full'
                    variant='contained'
                    text='Login'
                    onClick={this.redirectToLogin}
                  />
                )}
                <Button
                  variant='contained'
                  className='w-full'
                  onClick={this.saveProfile}
                  text={intl.formatMessage({ id: 'register.registerButton' })}
                  disabled={!isValidInput}
                />
              </div>
            </div>
          </div>
        </PageContainer>
      </div>
    );
  }

  private redirectToLogin = () => {
    this.props.history.push('/login');
  };

  private handlePasswordChange = (value: string, { target }: React.ChangeEvent<HTMLInputElement>) => {
    const password = removeSpaces(value);
    this.setState((state) => ({ ...state, [target.name]: password }));
  };

  /** Validates if the field has an error */
  private hasError = (field: 'password' | 'confirmPassword', errors: string[]) => {
    if (errors.length === 0) {
      return false;
    }
    return errors.includes(field);
  };

  /** client side validation for the input */
  private validate = () => {
    const { password, confirmPassword } = this.state;
    const errors = [];
    const fields = [];

    if (password.length <= 0) {
      errors.push('Password cannot be empty');
      fields.push('password');
    }

    if (password.length > 0 && password !== confirmPassword) {
      errors.push('Passwords do not match');
      fields.push('password', 'confirmPassword');
    }

    return [errors, fields];
  };

  /** Finish registration event handler */
  private saveProfile = () => {
    const [errors, fields] = this.validate();

    if (errors.length > 0) {
      const errorMessage = errors.join(' and ');

      alert(errorMessage);
    }
    if (fields.length > 0) {
      this.setState({ validationErrorFields: fields });
    } else {
      const { password, image } = this.state;
      const { firstName, lastName, job: jobPosition } = this.props.register.invite;

      this.props.createUser({
        firstName,
        lastName,
        password,
        jobPosition,
        image,
        invitationToken: this.props.match.params.token,
      });
    }
  };
}

export default Register;
