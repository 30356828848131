import { SvgIcon } from 'components/svg-icon';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { ChatAutoComplete, EmojiPicker, useMessageInputContext } from 'stream-chat-react';
import { TextInputProps } from './MessageInput.types';

export const TextInput: FC<TextInputProps> = ({ color, isFileSharingEnabled }) => {
  const intl = useIntl();
  const {
    cooldownRemaining,
    emojiPickerIsOpen,
    onPaste,
    handleSubmit,
    handleEmojiKeyDown,
    closeEmojiPicker,
    openEmojiPicker,
  } = useMessageInputContext();

  const handleSendMessage = (event: React.BaseSyntheticEvent) => {
    if (cooldownRemaining && cooldownRemaining > 0) {
      return;
    }
    handleSubmit(event);
  };

  const handleOnPaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    if (!isFileSharingEnabled) {
      return;
    }

    onPaste(event);
  };

  return (
    <section className='flex items-center w-full bg-white border rounded-lg border-zinc-300 drop-shadow-xl'>
      <ChatAutoComplete
        rows={1}
        placeholder={intl.formatMessage({ id: 'community.chat.placeholder' })}
        onPaste={handleOnPaste}
      />

      <div>
        <div className='str-chat__emojiselect-wrapper'>
          <button
            className='relative pt-1 fill-current str-chat__input-flat-emojiselect'
            onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
            onKeyDown={handleEmojiKeyDown}
            tabIndex={0}
          >
            <SvgIcon
              color={color}
              size={24}
              name='emoticon-happy-outline'
            />

            <div className='absolute bottom-0 right-0'>
              <EmojiPicker />
            </div>
          </button>
        </div>
      </div>

      <button className='flex items-end justify-center w-12 h-full py-2 border-gray-200'>
        <SvgIcon
          name='sendMessage'
          size={19}
          viewBox='0 0 19 16'
          color={color}
          onClick={handleSendMessage}
        />
      </button>
    </section>
  );
};
