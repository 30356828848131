import classnames from 'classnames';
import Breadcrumbs from 'components/Breadcrumbs';
import { BreadcrumbsProps } from 'components/Breadcrumbs/types';
import { PageContainer } from 'components/page-container';
import React from 'react';
import { PlaygroundActionsProps, PlaygroundProps } from './Playground.types';
import { PlaygroundActions } from './PlaygroundActions';

function showBreadcrumbs(breadcrumbs: PlaygroundProps['breadcrumbs']): breadcrumbs is BreadcrumbsProps {
  return typeof breadcrumbs !== 'boolean' || breadcrumbs;
}

function showHeading(heading: PlaygroundProps['heading']): heading is string | React.ReactNode {
  return typeof heading !== 'boolean' || Boolean(heading);
}

function showActions(actions: PlaygroundProps['actions']): actions is PlaygroundActionsProps {
  return typeof actions !== 'boolean' || Boolean(actions);
}

export const Playground: React.FC<PlaygroundProps> = ({
  className = '',
  children,
  heading = false,
  breadcrumbs = false,
  actions = false,
  ...restProps
}) => (
  <PageContainer
    className={classnames('flex flex-col h-9/10', className)}
    {...restProps}
  >
    {showBreadcrumbs(breadcrumbs) && <Breadcrumbs {...breadcrumbs} />}
    {showHeading(heading) && <div className='text-sm whitespace-pre-line'>{heading}</div>}
    {children}
    {showActions(actions) && <PlaygroundActions {...actions} />}
  </PageContainer>
);
