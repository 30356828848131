import { SvgIconProps } from 'components/svg-icon';
import { AuthStore } from 'containers/authentication';
import { Locale } from 'containers/language-provider';
import { HomeStore } from 'pages/home/Home.types';
import { HTMLAttributes, ReactNode, Ref } from 'react';
import { RouteChildrenProps } from 'react-router-dom';

export interface BottomNavigationProps extends RouteChildrenProps, HTMLAttributes<HTMLDivElement> {
  locale: Locale;
  paths: HomeStore['paths'];
  pathSet: HomeStore['pathSet'];
  features: AuthStore['features'];
  unreadPosts: number;
}

export enum Themes {
  DARK = 'dark',
  LIGHT = 'light',
}

export type NavigationType = {
  title?: string | ReactNode;
  icon: SvgIconProps['name'];
  viewBox?: string;
  path: string[];
  onClick?: () => void;
  required?: (keyof AuthStore['features'])[];
  notificationsBadge?: ReactNode;
  theme: Themes;
  className?: string;
  screenReaderText: string | ReactNode;
  ref?: Ref<HTMLLIElement>;
};

export type NavigationIcons = { [key: string]: NavigationType };

export interface PreviewBottomNavigationProps {
  locale: Locale;
}
