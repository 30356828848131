import { PresenceType } from 'components/avatar/types';
import { SvgIconName } from 'components/svg-icon';
import { MouseEventHandler } from 'react';
import { Channel, UserResponse } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

export enum ChannelGroupRoles {
  GROUP_ADMIN = 'Group admin',
  MEMBER = 'Member',
}

export interface AppChannelUser extends UserResponse<DefaultStreamChatGenerics> {
  channelRole?: string;
  isSelected?: boolean;
}

export interface UseChannelReadProps {
  member: UserResponse;
  channel: Channel;
}

export type UseClientOptions = {
  apiKey: string;
  isChatPluginEnabled?: boolean;
};

export interface GetChatTokenPayload {
  accountId: number | string;
  userId: number;
}

export interface GetChatTokenResponse {
  token: string;
}

export interface CreateChannelPayload {
  accountId: string;
  name: string;
  members: string[];
  isGroup: boolean;
  image?: string;
  description?: string;
}

export interface UpdateChannelPayload {
  accountId: string;
  channelId: string;
  name?: string;
  image?: string;
  description?: string;
}

export interface UpdateMembersPayload {
  accountId: string;
  channelId: string;
  members: number[];
}

export interface AddModeratorPayload {
  accountId: string;
  channelId: string;
  userIds: number[];
}

export interface ListItemPreviewProps {
  title: string;
  role?: string;
  message?: string;
  image?: string;
  icon?: SvgIconName;
  userPresence?: PresenceType | null;
  unreadCount?: number;
  lastMessageAt?: string;
  hasSelectIndicator?: boolean;
  isSelected?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
}
