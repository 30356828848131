import { useMatomo } from '@datapunt/matomo-tracker-react';

export const useAnalytics = () => {
  const { trackEvent, trackPageView, pushInstruction } = useMatomo();

  const identifyUser = (userID: string) => {
    // Sets userID to session logging
    pushInstruction('setUserId', userID);
  };

  return {
    trackEvent,
    trackPageView,
    identifyUser,
  };
};
