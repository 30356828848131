import { IconButton as BaseIconButton } from 'components/button';
import { FC } from 'react';
import theme from 'theme';
import { IconButtonProps } from './IconButton.types';

export const IconButton: FC<IconButtonProps> = ({ icon, onClick }) => (
  <BaseIconButton
    onClick={onClick}
    className='p-0 m-auto text-left bg-transparent text-neutral-300'
    color={theme.colors.stone300}
    icon={icon ?? 'left-arrow'}
    size={26}
    withoutPadding
  />
);
