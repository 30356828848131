import { Button } from 'components/button';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ReactCrop, { Crop } from 'react-image-crop';
import { FormattedMessage } from 'react-intl';
import { animated, useSpring } from 'react-spring';
import theme from 'theme';
import './ImageCropper.css';
import { ImageCropperProps as Props } from './types';

const INITIAL_CROP: Crop = {
  unit: '%',
  width: 40,
  height: 40,
  x: 0,
  y: 0,
};

export const ImageCropper: React.FC<Props> = ({
  imageSrc,
  aspect,
  initialCrop = INITIAL_CROP,
  circularCrop = true,
  disabled,
  locked,
  onCloseClick,
  onUploadClick,
}) => {
  const [crop, setCrop] = useState<Crop>(initialCrop);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string>();
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
  const cropperWidth = imageRef?.offsetWidth;

  const imageCropperContainerAnimation = useSpring({
    loop: false,
    from: {
      transform: 'translateX(-50%) scale(0.5)',
      opacity: 0,
      borderRadius: '100%',
    },
    to: {
      transform: 'translateX(-50%) scale(1)',
      opacity: 1,
      borderRadius: '0%',
    },
  });

  /** Get the cropped image section */
  const getCroppedImg = (image: HTMLImageElement, croppedSection: Crop): string => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const height = croppedSection.height || 0;
    const width = croppedSection.width || 0;
    const x = croppedSection.x || 0;
    const y = croppedSection.y || 0;

    // Set the image width/height to the cropped section
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');

    if (ctx) {
      // We draw cropped image onto the canvas, and store it, to then send it.
      ctx.drawImage(image, x * scaleX, y * scaleY, width * scaleX, height * scaleY, 0, 0, width, height);
    }

    const imageData = canvas.toDataURL('image/jpeg');

    // Return the cropped image
    return imageData;
  };

  // Set the cropped image, the one that is going to be uploaded
  const makeClientCrop = (croppedSection: Crop) => {
    if (imageRef && croppedSection.width && croppedSection.height) {
      const nextCroppedImageUrl = getCroppedImg(imageRef, croppedSection);

      setCroppedImageUrl(nextCroppedImageUrl);
    }
  };

  const onImageLoaded: React.ReactEventHandler<HTMLImageElement> = (event) => {
    const image = event.currentTarget;
    setImageRef(image);
    return true;
  };

  const onCropChange = (nextCrop: Crop) => {
    setCrop(nextCrop);
  };

  const onCropComplete = (nextCrop: Crop) => {
    makeClientCrop(nextCrop);
  };

  const onCancelCrop = () => {
    // Reseting memory
    setImageRef(null);
    setCrop(INITIAL_CROP);
    setCroppedImageUrl('');

    if (onCloseClick) {
      onCloseClick();
    }
  };

  const onUploadButtonClick = () => {
    if (!croppedImageUrl) {
      return;
    }
    onUploadClick(croppedImageUrl);
  };

  return (
    <>
      {imageSrc &&
        ReactDOM.createPortal(
          <animated.div
            className='absolute top-0 z-40 flex flex-col items-center justify-center w-full h-full -translate-x-1/2 bg-black left-1/2'
            style={imageCropperContainerAnimation}
            data-testid='e2e-image-cropper'
          >
            <div className='flex flex-col justify-center h-full'>
              <ReactCrop
                aspect={aspect}
                crop={crop}
                ruleOfThirds
                circularCrop={circularCrop}
                onComplete={onCropComplete}
                onChange={onCropChange}
                disabled={disabled}
                locked={locked}
                style={{ width: cropperWidth }}
              >
                <img
                  src={imageSrc as string}
                  alt=''
                  onLoad={onImageLoaded}
                  className='object-contain h-full'
                />
              </ReactCrop>
              <div
                className='flex justify-center w-full mt-6 gap-x-4'
                style={{ width: cropperWidth }}
              >
                <Button
                  variant='outlined'
                  className='w-full border-light color-warmGrey'
                  onClick={onCancelCrop}
                >
                  <FormattedMessage
                    id='comp.imageCropper.cancel'
                    defaultMessage='CANCEL'
                  />
                </Button>
                <Button
                  variant='outlined'
                  className='w-full'
                  focus
                  focusColor={theme.colors.turquoiseBlue}
                  onClick={onUploadButtonClick}
                  disabled={!croppedImageUrl}
                >
                  <FormattedMessage
                    id='comp.imageCropper.upload'
                    defaultMessage='UPLOAD'
                  />
                </Button>
              </div>
            </div>
          </animated.div>,
          document.body,
        )}
    </>
  );
};
