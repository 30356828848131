import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes, ModalsStore } from 'containers/modal-controller/ModalController.types';
import { shouldShowWalkthrough, useWalkthrough } from 'containers/walkthrough';
import { Lepa } from 'pages/lepa';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { isCrashCourse } from './helpers';

export const useCrashCourseWalkthroughs = (crashCourse?: Lepa) => {
  const { enqueueModal, has } = useModalQueue();
  const { completedWalkthroughs, locale } = useWalkthrough();
  const { currentModal } = useSelector<Store, ModalsStore>(({ modals }) => modals);

  useEffect(() => {
    if (!crashCourse || !isCrashCourse(crashCourse)) {
      return;
    }
    const hasUnlockedModule = Boolean(crashCourse.content.modules.some((module) => module.progress.unlocked));
    const lastUnlockedModuleSelector = hasUnlockedModule
      ? '.unlocked-node'
      : '#wt-line-container > *:first-child > *:nth-child(2)';
    const walkthroughVariant = 'crash-course';
    const showIntroWalkthrough = shouldShowWalkthrough(completedWalkthroughs, walkthroughVariant);
    const currentModalIsPersonalityTestStart = currentModal?.type === ModalTypes.PERSONALITY_TEST_START;
    const isModalAlreadyEnqueued = has(ModalTypes.WALKTHROUGH, walkthroughVariant);

    if (!showIntroWalkthrough || currentModalIsPersonalityTestStart || isModalAlreadyEnqueued) {
      return;
    }

    enqueueModal({
      type: ModalTypes.WALKTHROUGH,
      modal: {
        variant: walkthroughVariant,
        targets: ['body', lastUnlockedModuleSelector],
        lang: locale,
      },
    });
  }, [completedWalkthroughs, enqueueModal, locale, crashCourse, currentModal?.type, has]);

  useEffect(() => {
    if (!crashCourse || !isCrashCourse(crashCourse)) {
      return;
    }
    const walkthroughVariant = 'crash-course-test';
    const hasSkillTest = Boolean(crashCourse.content.progress.learningTestId);
    const showTestWalkthrough = Boolean(
      shouldShowWalkthrough(completedWalkthroughs, walkthroughVariant) &&
        hasSkillTest &&
        crashCourse &&
        !crashCourse.content.progress.passed,
    );
    const isModalAlreadyEnqueued = has(ModalTypes.WALKTHROUGH, walkthroughVariant);
    if (!showTestWalkthrough || isModalAlreadyEnqueued) {
      return;
    }

    enqueueModal({
      type: ModalTypes.WALKTHROUGH,
      modal: {
        variant: walkthroughVariant,
        targets: ['.level-test-unlocked-wt'],
        lang: locale,
      },
    });
  }, [completedWalkthroughs, enqueueModal, crashCourse, locale, has]);
};
