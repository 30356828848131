import { PayloadAction } from '@reduxjs/toolkit';
import { REST_API_URL } from 'constants/endpoints';
import { StrictEffect, call, put, takeLatest } from 'redux-saga/effects';
import getDefaultHeaders from 'util/getDefaultHeaders';
import request from 'util/request';
import { actions } from './Settings.ducks';
import {
  ChangeEmailPayload,
  ChangeEmailResponse,
  ChangePasswordPayload,
  ChangePasswordResponse,
} from './Settings.types';

function* changePasswordWorker({ payload }: PayloadAction<ChangePasswordPayload>): Generator<StrictEffect, void> {
  try {
    const { userId, ...props } = payload;
    const requestUrl = `${REST_API_URL}/users/${String(userId)}/change-password`;
    const headers = (yield call(getDefaultHeaders)) as HeadersInit;
    const body = JSON.stringify(props);

    const { errors } = (yield call(request, requestUrl, {
      method: 'POST',
      headers,
      body,
    })) as ChangePasswordResponse;

    if (errors) {
      yield put(actions.changePasswordError(errors));
    } else {
      yield put(actions.changePasswordSuccess());
    }
  } catch (error) {
    throw new Error('unknown error thrown by `changePassword`');
  }
}

function* changeEmailWorker({ payload }: PayloadAction<ChangeEmailPayload>): Generator<StrictEffect, void> {
  try {
    const { userId, ...props } = payload;
    const requestUrl = `${REST_API_URL}/users/${String(userId)}/change-email`;
    const headers = (yield call(getDefaultHeaders)) as HeadersInit;
    const body = JSON.stringify(props);

    const { errors } = (yield call(request, requestUrl, {
      method: 'POST',
      headers,
      body,
    })) as ChangeEmailResponse;

    if (errors) {
      yield put(actions.changeEmailError(errors));
    } else {
      yield put(actions.changeEmailSuccess());
    }
  } catch (error) {
    throw new Error('unknown error thrown by `changePassword`');
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.changePassword.type, changePasswordWorker);
  yield takeLatest(actions.changeEmail.type, changeEmailWorker);
}
