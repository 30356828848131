import { ConfirmationDrawer } from 'components/drawer';
import { ProfileStore } from 'containers/Profile';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { useChatContext } from 'stream-chat-react';
import { useRemoveMembersMutation } from '../Chat.api';
import { ConfirmationRemoveUserDrawerProps } from './Conversation.types';

export const ConfirmationRemoveUserDrawer: FC<ConfirmationRemoveUserDrawerProps> = ({
  isOpen,
  user,
  refreshMembers,
  onClose,
}) => {
  const { channel } = useChatContext();
  const { id: accountId } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const [removeMembers] = useRemoveMembersMutation();

  const handleRemoveUser = async () => {
    if (!user || !channel?.id) {
      return;
    }

    await removeMembers({
      accountId,
      channelId: channel.id,
      members: [parseInt(user.id, 10)],
    });

    await refreshMembers();
    onClose();
  };

  return (
    <ConfirmationDrawer
      isOpen={isOpen}
      title={
        <FormattedMessage
          id='community.chat.group-info.remove-confirmation'
          defaultMessage='Remove \"{name}\" from group?'
          values={{ name: user?.name }}
        />
      }
      confirmationText={
        <FormattedMessage
          id='community.chat.group-info.remove-user'
          defaultMessage='Remove user'
        />
      }
      cancellationText={
        <FormattedMessage
          id='community.chat.group-info.cancel'
          defaultMessage='Cancel'
        />
      }
      onConfirm={handleRemoveUser}
      onClose={onClose}
    />
  );
};
