import React from 'react';
import { PopupStarProps } from './types';

export const DiamondGradient = () => (
  <defs data-testid='popup-star-diamond'>
    <linearGradient
      x1='0%'
      y1='135.608681%'
      x2='50%'
      y2='69.1536462%'
      id='linearGradient-diamond-1'
    >
      <stop
        stopColor='#45E0A7'
        offset='0%'
      />
      <stop
        stopColor='#88E48A'
        offset='18.7170291%'
      />
      <stop
        stopColor='#D5E969'
        offset='100%'
      />
    </linearGradient>
    <linearGradient
      x1='70.5289388%'
      y1='-98.4126459%'
      x2='-101.302519%'
      y2='260.039875%'
      id='linearGradient-diamond-2'
    >
      <stop
        stopColor='#45E0A7'
        offset='0%'
      />
      <stop
        stopColor='#CDE86D'
        offset='68.2782503%'
      />
      <stop
        stopColor='#D5E969'
        offset='100%'
      />
    </linearGradient>
  </defs>
);

export const GoldGradient = () => (
  <defs data-testid='popup-star-gold'>
    <linearGradient
      x1='50%'
      y1='0%'
      x2='50%'
      y2='144.030525%'
      id='linearGradient-gold-1'
    >
      <stop
        stopColor='#FAD961'
        offset='0%'
      />
      <stop
        stopColor='#FAD760'
        offset='51.4110794%'
      />
      <stop
        stopColor='#F76B1C'
        offset='100%'
      />
    </linearGradient>
    <linearGradient
      x1='50%'
      y1='0%'
      x2='50%'
      y2='100%'
      id='linearGradient-gold-2'
    >
      <stop
        stopColor='#FAD961'
        offset='0%'
      />
      <stop
        stopColor='#F76B1C'
        offset='100%'
      />
    </linearGradient>
  </defs>
);

export const SilverGradient = () => (
  <defs data-testid='popup-star-silver'>
    <linearGradient
      x1='51.6451571%'
      y1='1.64515711%'
      x2='51.6451597%'
      y2='94.1964583%'
      id='linearGradient-silver-1'
    >
      <stop
        stopColor='#E9E9E7'
        offset='0%'
      />
      <stop
        stopColor='#EFEEEC'
        offset='13.8024767%'
      />
      <stop
        stopColor='#EEEEEC'
        offset='42.5951322%'
      />
      <stop
        stopColor='#D5D4D0'
        offset='100%'
      />
    </linearGradient>
    <linearGradient
      x1='15.3554498%'
      y1='20.9554771%'
      x2='-83.8360286%'
      y2='306.189515%'
      id='linearGradient-silver-2'
    >
      <stop
        stopColor='#FFFFFF'
        offset='0%'
      />
      <stop
        stopColor='#D4D4D4'
        offset='100%'
      />
    </linearGradient>
  </defs>
);

export const RubyGradient = () => (
  <defs data-testid='popup-star-ruby'>
    <linearGradient
      x1='52.6017487%'
      y1='-16.9714441%'
      x2='50.2224445%'
      y2='169.884561%'
      id='linearGradient-ruby-1'
    >
      <stop
        stopColor='#FFFFFF'
        offset='0%'
      />
      <stop
        stopColor='#D41872'
        offset='91.4501505%'
      />
      <stop
        stopColor='#FF0066'
        offset='100%'
      />
    </linearGradient>
    <linearGradient
      x1='15.3554498%'
      y1='20.9554771%'
      x2='-83.8360286%'
      y2='306.189515%'
      id='linearGradient-ruby-2'
    >
      <stop
        stopColor='#F34280'
        offset='0%'
      />
      <stop
        stopColor='#F34280'
        offset='100%'
      />
    </linearGradient>
  </defs>
);

/**
 * PopupStar component - animated star
 */
const PopupStars: React.FC<PopupStarProps> = ({ width = '8rem', height = '7rem', color = 'ruby', ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 128 112'
    data-testid='popup-star'
    {...rest}
  >
    {color === 'diamond' && <DiamondGradient />}
    {color === 'gold' && <GoldGradient />}
    {color === 'silver' && <SilverGradient />}
    {color === 'ruby' && <RubyGradient />}

    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g transform='translate(8.126984, 0.000000)'>
        <polygon
          id='Fill-13'
          fill={`url(#linearGradient-${color}-1)`}
          points='55.8908491 0.000142789731 39.8744145 35.1392677 1.34303855 39.7128228 30.0131747 65.711977 22.4818593 103.552684 56.2154871 84.483115 90.0941965 103.29709 82.2727179 65.5120714 110.741751 39.2973047 72.1744637 35.0164685'
        />
        <polygon
          id='Fill-14'
          fill={`url(#linearGradient-${color}-2)`}
          points='56.0419638 51.8365277 110.742038 39.3581331 82.2730052 65.5728998'
        />
        <polygon
          id='Fill-15'
          fill={`url(#linearGradient-${color}-2)`}
          points='56.0421074 51.8362421 72.1748946 35.0770114 55.89128 0.0635414303'
        />
        <polygon
          id='Fill-16'
          fill={`url(#linearGradient-${color}-2)`}
          points='90.095202 103.358061 56.1058859 51.7766988 56.2164926 84.5426583'
        />
        <polygon
          id='Fill-17'
          fill={`url(#linearGradient-${color}-2)`}
          points='22.4824339 103.613226 56.1054549 51.7762704 30.0137493 65.7710919'
        />
        <polygon
          id='Fill-18'
          fill={`url(#linearGradient-${color}-2)`}
          points='1.34318219 39.711966 56.1064604 51.7762704 39.8745581 35.1384109'
        />
      </g>
      <g transform='translate(110.730159, 61.968254)'>
        <polygon
          id='Fill-13'
          fill={`url(#linearGradient-${color}-1)`}
          points='8.55985977 2.17584352e-05 6.10689231 5.35455508 0.205690588 6.05147776 4.59661234 10.0132536 3.44316764 15.7794565 8.60957911 12.8736175 13.7982103 15.7405089 12.6003262 9.98279183 16.9604483 5.98816071 11.0537467 5.33584282'
        />
        <polygon
          id='Fill-14'
          fill={`url(#linearGradient-${color}-2)`}
          points='8.58300346 7.89889945 16.9604923 5.9974298 12.6003702 9.99206092'
        />
        <polygon
          id='Fill-15'
          fill={`url(#linearGradient-${color}-2)`}
          points='8.58302546 7.89885594 11.0538127 5.3450684 8.55992577 0.00968250366'
        />
        <polygon
          id='Fill-16'
          fill={`url(#linearGradient-${color}-2)`}
          points='13.7983643 15.7497998 8.59279333 7.88978267 8.60973311 12.8826908'
        />
        <polygon
          id='Fill-17'
          fill={`url(#linearGradient-${color}-2)`}
          points='3.44325564 15.7886821 8.59272733 7.88971739 4.59670034 10.0222616'
        />
        <polygon
          id='Fill-18'
          fill={`url(#linearGradient-${color}-2)`}
          points='0.205712588 6.0513472 8.59288133 7.88971739 6.10691431 5.35442453'
        />
      </g>
      <g transform='translate(0.000000, 61.968254)'>
        <polygon
          id='Fill-13'
          fill={`url(#linearGradient-${color}-1)`}
          points='9.06338093 2.17584352e-05 6.46612127 5.35455508 0.217790034 6.05147776 4.8670013 10.0132536 3.64570692 15.7794565 9.11602494 12.8736175 14.6098697 15.7405089 13.3415218 9.98279183 17.9581218 5.98816071 11.7039671 5.33584282'
        />
        <polygon
          id='Fill-14'
          fill={`url(#linearGradient-${color}-2)`}
          points='9.08788602 7.89889945 17.9581684 5.9974298 13.3415684 9.99206092'
        />
        <polygon
          id='Fill-15'
          fill={`url(#linearGradient-${color}-2)`}
          points='9.08790931 7.89885594 11.704037 5.3450684 9.06345081 0.00968250366'
        />
        <polygon
          id='Fill-16'
          fill={`url(#linearGradient-${color}-2)`}
          points='14.6100328 15.7497998 9.09825176 7.88978267 9.11618799 12.8826908'
        />
        <polygon
          id='Fill-17'
          fill={`url(#linearGradient-${color}-2)`}
          points='3.64580009 15.7886821 9.09818188 7.88971739 4.86709448 10.0222616'
        />
        <polygon
          id='Fill-18'
          fill={`url(#linearGradient-${color}-2)`}
          points='0.217813328 6.0513472 9.09834494 7.88971739 6.46614456 5.35442453'
        />
      </g>
      <g transform='translate(56.888889, 95.492063)'>
        <polygon
          id='Fill-13'
          fill={`url(#linearGradient-${color}-1)`}
          points='8.55985977 2.17584352e-05 6.10689231 5.35455508 0.205690588 6.05147776 4.59661234 10.0132536 3.44316764 15.7794565 8.60957911 12.8736175 13.7982103 15.7405089 12.6003262 9.98279183 16.9604483 5.98816071 11.0537467 5.33584282'
        />
        <polygon
          id='Fill-14'
          fill={`url(#linearGradient-${color}-2)`}
          points='8.58300346 7.89889945 16.9604923 5.9974298 12.6003702 9.99206092'
        />
        <polygon
          id='Fill-15'
          fill={`url(#linearGradient-${color}-2)`}
          points='8.58302546 7.89885594 11.0538127 5.3450684 8.55992577 0.00968250366'
        />
        <polygon
          id='Fill-16'
          fill={`url(#linearGradient-${color}-2)`}
          points='13.7983643 15.7497998 8.59279333 7.88978267 8.60973311 12.8826908'
        />
        <polygon
          id='Fill-17'
          fill={`url(#linearGradient-${color}-2)`}
          points='3.44325564 15.7886821 8.59272733 7.88971739 4.59670034 10.0222616'
        />
        <polygon
          id='Fill-18'
          fill={`url(#linearGradient-${color}-2)`}
          points='0.205712588 6.0513472 8.59288133 7.88971739 6.10691431 5.35442453'
        />
      </g>
      <g transform='translate(23.365079, 8.126984)'>
        <polygon
          id='Fill-13'
          fill={`url(#linearGradient-${color}-1)`}
          points='8.55985977 2.17584352e-05 6.10689231 5.35455508 0.205690588 6.05147776 4.59661234 10.0132536 3.44316764 15.7794565 8.60957911 12.8736175 13.7982103 15.7405089 12.6003262 9.98279183 16.9604483 5.98816071 11.0537467 5.33584282'
        />
        <polygon
          id='Fill-14'
          fill={`url(#linearGradient-${color}-2)`}
          points='8.58300346 7.89889945 16.9604923 5.9974298 12.6003702 9.99206092'
        />
        <polygon
          id='Fill-15'
          fill={`url(#linearGradient-${color}-2)`}
          points='8.58302546 7.89885594 11.0538127 5.3450684 8.55992577 0.00968250366'
        />
        <polygon
          id='Fill-16'
          fill={`url(#linearGradient-${color}-2)`}
          points='13.7983643 15.7497998 8.59279333 7.88978267 8.60973311 12.8826908'
        />
        <polygon
          id='Fill-17'
          fill={`url(#linearGradient-${color}-2)`}
          points='3.44325564 15.7886821 8.59272733 7.88971739 4.59670034 10.0222616'
        />
        <polygon
          id='Fill-18'
          fill={`url(#linearGradient-${color}-2)`}
          points='0.205712588 6.0513472 8.59288133 7.88971739 6.10691431 5.35442453'
        />
      </g>
      <g transform='translate(86.349206, 8.126984)'>
        <polygon
          id='Fill-13'
          fill={`url(#linearGradient-${color}-1)`}
          points='9.06338093 2.17584352e-05 6.46612127 5.35455508 0.217790034 6.05147776 4.8670013 10.0132536 3.64570692 15.7794565 9.11602494 12.8736175 14.6098697 15.7405089 13.3415218 9.98279183 17.9581218 5.98816071 11.7039671 5.33584282'
        />
        <polygon
          id='Fill-14'
          fill={`url(#linearGradient-${color}-2)`}
          points='9.08788602 7.89889945 17.9581684 5.9974298 13.3415684 9.99206092'
        />
        <polygon
          id='Fill-15'
          fill={`url(#linearGradient-${color}-2)`}
          points='9.08790931 7.89885594 11.704037 5.3450684 9.06345081 0.00968250366'
        />
        <polygon
          id='Fill-16'
          fill={`url(#linearGradient-${color}-2)`}
          points='14.6100328 15.7497998 9.09825176 7.88978267 9.11618799 12.8826908'
        />
        <polygon
          id='Fill-17'
          fill={`url(#linearGradient-${color}-2)`}
          points='3.64580009 15.7886821 9.09818188 7.88971739 4.86709448 10.0222616'
        />
        <polygon
          id='Fill-18'
          fill={`url(#linearGradient-${color}-2)`}
          points='0.217813328 6.0513472 9.09834494 7.88971739 6.46614456 5.35442453'
        />
      </g>
    </g>
  </svg>
);
export default PopupStars;
