import { useState } from 'react';
import { NewChat } from '.';
import { AppChannelUser } from '../Chat.types';
import { NewChatViews } from './NewChat.types';
import { NewGroup, NewGroupDetails } from './group';

export const NewChatOverview = () => {
  const [view, setView] = useState<NewChatViews>(NewChatViews.NEW_CHAT);
  const [selectedUsers, setSelectedUsers] = useState<AppChannelUser[]>([]);

  return (
    <div className='flex flex-col w-full h-full bg-zinc-100'>
      {view === NewChatViews.NEW_CHAT && <NewChat onChangeView={setView} />}
      {view === NewChatViews.NEW_GROUP && (
        <NewGroup
          onChangeView={setView}
          onUpdateSelectedUsers={setSelectedUsers}
          selectedUsers={selectedUsers}
        />
      )}
      {view === NewChatViews.NEW_GROUP_DETAILS && (
        <NewGroupDetails
          onChangeView={setView}
          onUpdateSelectedUsers={setSelectedUsers}
          selectedUsers={selectedUsers}
        />
      )}
    </div>
  );
};
