import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import getMessages, {
  defaultLocale,
  defaultMessages,
  formats,
  languageTextDirections,
} from './LanguageProvider.helpers';
import { Locale, Messages, LanguageProviderProps as Props } from './LanguageProvider.types';

export const LanguageProvider: React.FC<Props> = ({ children }) => {
  const userLocale = useSelector<Store, Locale>(({ profile }) => profile.user.locale || defaultLocale);
  const [locale, setLocale] = useState<Locale>(defaultLocale);

  const [messages, setMessages] = useState<Messages>(defaultMessages);

  const direction = languageTextDirections[locale];

  useEffect(() => {
    void (async () => {
      const { locale: nextLocale, messages: nextMessages } = await getMessages(userLocale);
      setLocale(nextLocale);
      setMessages(nextMessages);
    })();
  }, [userLocale]);

  return (
    <div
      id='text-direction-container'
      className='w-full h-full'
      dir={direction}
    >
      <IntlProvider
        locale={locale}
        messages={messages}
        formats={formats}
      >
        {React.Children.only(children)}
      </IntlProvider>
    </div>
  );
};
