import classnames from 'classnames';
import StatsBar from 'components/StatsBar';
import { useTextDirection } from 'hooks/useTextDirection';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StatsProps } from './types';

const Stats: React.FC<StatsProps> = ({ data, showMyStore }) => {
  const { isLTR } = useTextDirection();

  return (
    <div className='flex-1 px-4 pt-0 pb-2 border-b-2 border-gray-200 w-5/10 first:border-r-2 first:border-gray-200'>
      <div className='flex flex-col h-full'>
        <div className='h-16 text-center'>
          <div
            className='inline-block w-3 h-3 rounded-full bg-greyish'
            style={{ backgroundColor: data.defaultColor }}
          />
          <div
            className={classnames('inline-block relative text-xs bottom-0.5 text-greyishBrown font-normal', {
              'ml-2': isLTR,
              'mr-2': !isLTR,
            })}
          >
            <FormattedMessage id={`profile.${data.name}`} />
          </div>
        </div>
        <div className={classnames('flex justify-center flex-1 h-full', { 'md:mx-10': !showMyStore })}>
          <StatsBar
            value={data.me}
            label={<FormattedMessage id='profile.me' />}
            color={data.colors[1]}
            crown={data.colors[0]}
            valueColor={data.colors[0].end}
          />
          {showMyStore && (
            <StatsBar
              value={data.myStore}
              label={<FormattedMessage id='profile.myStore' />}
              color={data.defaultColor}
            />
          )}
          <StatsBar
            value={data.total}
            label={<FormattedMessage id='profile.total' />}
            color={data.defaultColor}
          />
        </div>
      </div>
    </div>
  );
};

export default Stats;
