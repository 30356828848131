import { Media } from 'components/media';
import { Popup } from 'components/popup';
import { API_URL } from 'constants/endpoints';
import { VideoFormat } from 'containers/FormatScreen/Formats';
import React from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { actions } from '../Lepa.ducks';
import { TipModalProps as Props } from '../Lepa.types';

const TipModalComponent: React.FC<Props> = ({ intl, modal, onModalClose, open }) => {
  const dispatch = useDispatch();

  const handleTrackTip = () => {
    dispatch(
      actions.trackTip({
        id: modal.id ?? '',
        levelIdx: modal.levelIndx ?? -1,
        tipIdx: modal.indx ?? -1,
      }),
    );
  };

  const handleCloseModal = () => {
    handleTrackTip();
    onModalClose();
  };

  const renderPopupMedia = () => {
    if (!modal.mediaData?.media) {
      return <div />;
    }

    const { mediaType } = modal.mediaData;

    if (mediaType === 'video') {
      return (
        <div className='w-full h-full'>
          <VideoFormat url={modal.mediaData.media} />
        </div>
      );
    }

    return (
      <div className='w-full h-full'>
        <div className='relative w-full pb-9/16'>
          <Media
            alt='tip'
            className='absolute'
            style={{ backgroundSize: 'contain' }}
            src={`${API_URL}/${modal.mediaData.media}`}
          />
        </div>
      </div>
    );
  };

  return (
    <Popup
      open={open}
      variant='tip'
      title={modal.title}
      buttonText={modal.buttonText || intl.formatMessage({ id: 'formats.button.thanks' })}
      onPrimaryClick={handleCloseModal}
      onRequestClose={handleCloseModal}
      closeIcon={false}
      hasThanksAndCloseButton={true}
    >
      {renderPopupMedia()}

      <div
        key='text-container'
        className='pt-2 text-sm font-light text-center whitespace-pre-wrap'
      >
        {modal.summary}
      </div>
    </Popup>
  );
};

export const TipModal = injectIntl(TipModalComponent);
