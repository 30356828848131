import { API_URL } from 'constants/endpoints';
import { PREVIOUS_LOCATION_COOKIE } from 'constants/global';
import { ModalsController } from 'containers/modal-controller';
import cookie from 'js-cookie';
import moment from 'moment';
import React from 'react';
import { preparePreviousLocation } from 'util/preparePreviousLocation';
import { getFiltersFromStorage } from 'util/useFiltersQueryParams';
import { AppProps as Props, AppState as State } from './App.types';
import { Root } from './root';

export const untrustedRoutes = [
  '/login',
  '/register',
  '/a/',
  '/error',
  '/ssomessage',
  '/preview',
  'self-registration-link',
];

class App extends React.Component<Props, State> {
  /** checks if the current route is /preview */
  static isPreviewRoute = (pathname: string) => pathname.includes('/preview');

  componentDidMount() {
    const { location, getUserInfo } = this.props;

    if (untrustedRoutes.some((route) => location.pathname.includes(route))) {
      return;
    }

    getUserInfo();
  }

  componentDidUpdate(prevProps: Props) {
    const { location, userId, auth, history, user } = this.props;
    const { user: prevUser, userId: prevUserId } = prevProps;
    const { isLocaleSelected } = user;

    if (untrustedRoutes.some((route) => location.pathname.includes(route)) || auth.isAuthorizing) {
      return;
    }

    const isPreviewing = App.isPreviewRoute(location.pathname) || user.role === 'preview';
    if (isPreviewing) {
      return;
    }

    const hasUser = Boolean(userId);
    const hasNewUser = hasUser && userId !== prevUserId;
    const hasLocale = Boolean(user.locale);
    const previousLocale = prevUser.locale;

    const hasNewLocale = user.locale !== previousLocale && !previousLocale && user.locale;
    const currentURL = preparePreviousLocation(location);
    const previousLocation = this.getPreviousLocation() ?? '';
    const skillflixFilters = getFiltersFromStorage() ?? '';

    // This extra locale checks are needed so a new user, without a lang
    // doesn't trigger them until the logging phase is over.
    // And the same for a previewer.
    if (hasNewUser && !hasUser && (hasLocale || hasNewLocale)) {
      this.props.getUserInfo();
    }

    if (hasNewLocale && !!user.locale) {
      document.documentElement.lang = user.locale;
    }

    const isPreviousLocationEqualsToCurrent = previousLocation.includes(location.pathname);
    if (user.persona && isPreviousLocationEqualsToCurrent) {
      this.removePreviousLocationCookie();
    }

    if (hasNewUser) {
      this.props.getAllBadges({ locale: user.locale });
      this.props.getWalkthroughs({ userId });
      this.props.getUser(userId);
      const currentLocationHasFiltersQueryParams = ['categories', 'types', 'certified'].some((word) =>
        new RegExp(`\\b${word}\\b`).test(location.search),
      );
      const homePageHasFilters = location.pathname === '/' && currentLocationHasFiltersQueryParams;
      if (!previousLocation && !homePageHasFilters) {
        cookie.set(PREVIOUS_LOCATION_COOKIE, currentURL);
      }
      this.redirectToPreviousLocation();
    }

    if (location.pathname === '/' && !location.search && skillflixFilters) {
      this.props.history.replace({ search: skillflixFilters });
    }

    if (isLocaleSelected && Boolean(previousLocation)) {
      this.redirectToPreviousLocation();
    }

    if (location.pathname === previousLocation) {
      // this would prevent mounting crash and unwanted redirects
      cookie.remove(PREVIOUS_LOCATION_COOKIE);
    }

    if (user.persona && previousLocation) {
      this.redirectToPreviousLocation();
    }

    if (hasLocale) {
      moment.locale(user.locale);
    }

    const isSelectingLanguage = location.pathname === '/language-selection';
    const shouldRedirectToLangSelection = hasUser && !isLocaleSelected && !isSelectingLanguage && !hasNewLocale;
    if (shouldRedirectToLangSelection) {
      history.push('/language-selection');
    }
  }

  render() {
    const { history, location, match, auth, splashSrc, routesWithBackgroundImage, user } = this.props;
    const isPreviewing = App.isPreviewRoute(location.pathname) || user.role === 'preview';
    const lecoBackground = this.getLecoBackground();

    return (
      <>
        <ModalsController />

        <Root
          history={history}
          location={location}
          match={match}
          isAuthorizing={auth.isAuthorizing}
          splashSrc={splashSrc || ''}
          backgroundSrc={lecoBackground}
          isPreviewMode={isPreviewing}
          routesWithBackgroundImage={routesWithBackgroundImage}
          features={auth.features}
        />
      </>
    );
  }

  getPreviousLocation = () => cookie.get(PREVIOUS_LOCATION_COOKIE);

  removePreviousLocationCookie = () => {
    if (!this.getPreviousLocation()) {
      return;
    }
    cookie.remove(PREVIOUS_LOCATION_COOKIE);
  };

  redirectToPreviousLocation = () => {
    const previousLocation = this.getPreviousLocation();
    if (!previousLocation) {
      return;
    }

    this.props.history.push(previousLocation);
  };

  getLecoBackground = () => {
    const { lecoBackground, accountBackground } = this.props;
    const background = lecoBackground || accountBackground;

    if (!background) {
      return null;
    }

    return `${API_URL}/${background}`;
  };
}

export default App;
