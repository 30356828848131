import { FC } from 'react';
import { SelectedUser } from '.';
import { SeparatorLine } from '../../components/separator-line';
import { SelectedUsersProps } from './NewGroup.types';

export const SelectedUsers: FC<SelectedUsersProps> = ({ users, onClose }) => (
  <section>
    <div className='flex pb-3 mx-4 overflow-x-auto cursor-pointer'>
      {users.map((user) => (
        <SelectedUser
          key={user.id}
          user={user}
          onClose={onClose}
        />
      ))}
    </div>
    <SeparatorLine className='mb-3' />
  </section>
);
