import { useHistory, useParams } from 'react-router-dom';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { PersonalityTestType, URLParams } from './PersonalityTest.types';
import { Collections } from './collections';
import { Tags } from './tags';

function isValidType(type: string): type is PersonalityTestType {
  return Object.values(PersonalityTestType).some((typeNum) => typeNum === type);
}

export const PersonalityTest = () => {
  const { type } = useParams<URLParams>();
  const history = useHistory();
  const isPersonalityTestEnabled = useFeatureFlag(FlagKeys.PERSONALITY_TEST);

  if (!isPersonalityTestEnabled) {
    history.push('/');
    return <div />;
  }

  if (!isValidType(type)) {
    throw new Error(`Personality Test type ${type} is invalid.`);
  }

  return (
    <>
      {
        {
          collections: <Collections />,
          tags: <Tags />,
        }[type]
      }
    </>
  );
};
