import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ExplanationIconsItem, ExplanationIconsListProps as Props } from './ExplanationIconsList.types';

export const ExplanationIconsList: React.FC<Props> = ({ items }) => (
  <>
    {items.map((item: ExplanationIconsItem, idx: number) => (
      <React.Fragment key={item.iconName}>
        <SvgIcon
          name={item.iconName}
          size={25}
          viewBox={item.viewBox ?? '0 0 15 15'}
          color='#555555'
          data-testid={`explanation-icons-item-${idx}-icon`}
        />
        <div
          className='flex flex-col'
          data-testid={`explanation-icons-item-${idx}-content`}
        >
          <FormattedMessage id={item.translationKey} />
          {item.explanationTranslationKey && (
            <div
              className='text-xs font-thin'
              data-testid={`explanation-icons-item-${idx}-extra-content`}
            >
              <FormattedMessage id={item.explanationTranslationKey} />
            </div>
          )}
        </div>
      </React.Fragment>
    ))}
  </>
);
