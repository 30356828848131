import React from 'react';
import ReactDOM from 'react-dom';
import { animated, useSpring } from 'react-spring';
import { UsePortalOptions } from './Overlay.types';

export const useAnimatedPortal = ({ display = true, disableAnimation = false, className, style }: UsePortalOptions) => {
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: display ? 1 : 0 },
    immediate: disableAnimation,
  });

  return (component: React.ReactNode) => {
    const root = document.getElementById('root');
    if (!root) {
      throw new Error('html root not found');
    }
    const animatedContent = (
      <animated.div
        style={{ ...fadeIn, ...style }}
        className={className}
      >
        {component}
      </animated.div>
    );
    return ReactDOM.createPortal(animatedContent, root);
  };
};
