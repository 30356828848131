import { PayloadAction } from '@reduxjs/toolkit';
import { loader } from 'graphql.macro';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import requestGraphql from 'util/requestGraphql';
import { actions } from './Register.ducks';
import { CreateUserPayload, ValidateInvitePayload, ValidateInviteSuccessPayload } from './Register.types';

const createUserMutation = loader('./graphql/mutation/create-user.graphql');
const validateInviteQuery = loader('./graphql/queries/validate-invite.graphql');

/** Validate invite token form `/register/{token}` worker */
function* validateInviteWorker({ payload }: PayloadAction<ValidateInvitePayload>): Generator<StrictEffect, void> {
  const { inviteToken } = payload;
  const body = {
    query: validateInviteQuery,
    variables: { inviteToken },
  };

  const { data, errors } = (yield call(requestGraphql, body)) as {
    data: ValidateInviteSuccessPayload;
    errors?: any; // ! NEEDS TO BE CHANGED AFTER GRAPHQL TO REST MIGRATION IS DONE
  };

  if (errors) {
    const message = Array.isArray(errors.errors) ? errors.errors[0].message : errors.message;
    yield put(actions.validateInviteError(new Error(message)));
  } else {
    yield put(actions.validateInviteSuccess(data));
  }
}

/** Create user worker */
function* createUserWorker({ payload }: PayloadAction<CreateUserPayload>): Generator<StrictEffect, void> {
  const { user } = payload;
  const body = {
    query: createUserMutation,
    variables: user,
  };

  const { errors } = (yield call(requestGraphql, body)) as {
    errors?: any; // ! NEEDS TO BE CHANGED AFTER GRAPHQL TO REST MIGRATION IS DONE
  };

  if (errors) {
    // since the API checks if the token exists or not, and if it doesn't exist it returns 'Not found'
    // which means the user is already created or the token expired (2 Weeks), this is very rare to happen.
    const message = 'This token is invalid or the account is already registered';

    yield put(actions.createUserError(new Error(message)));
  } else {
    yield put(actions.createUserSuccess());
  }
}

/**
 * Validate invite saga
 */
export default function* rootSaga() {
  yield takeLatest(actions.validateInvite.type, validateInviteWorker);
  yield takeLatest(actions.createUser.type, createUserWorker);
}
