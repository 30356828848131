import React from 'react';
import { XPovalProps as Props } from './Popup.types';

export const XPoval: React.FC<Props> = ({
  text = ['STREAK', 'OF 3!'],
  textColor = '#FFFFFF',
  fill = '#FFB500',
  xp = '+ 10 XP!',
  xpColor = '#9013FE',
  style,
}) => (
  <g style={style}>
    <ellipse
      fill={fill}
      cx='126'
      cy='125.5'
      rx='64'
      ry='63.5'
    />
    <text
      fill={xpColor}
      transform='translate(127, 153) rotate(-5.000000) translate(-127, -153)'
      fontFamily='inherit'
      fontSize='18'
    >
      <tspan
        x='93'
        y='159'
      >
        {xp}
      </tspan>
    </text>
    <g
      transform='translate(125, 96)'
      fill={textColor}
      fontFamily='inherit'
      fontSize='14'
    >
      <text textAnchor='middle'>
        {text?.map((t, i) => (
          <tspan
            key={`xp-text-${t}`}
            x='0'
            y={17 + 19 * i}
          >
            {t}
          </tspan>
        ))}
      </text>
    </g>
  </g>
);
