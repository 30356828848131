import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

export const Fallback = () => {
  const history = useHistory();

  const handleNavigateHome = () => history.push('/');

  return (
    <PageContainer
      center
      className='h-full'
    >
      <div className='pb-6 font-light text-center'>
        <FormattedMessage id='comp.fallback.message' />
      </div>
      <Button onClick={handleNavigateHome}>
        <FormattedMessage id='comp.fallback.button' />
      </Button>
    </PageContainer>
  );
};
