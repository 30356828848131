import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import React from 'react';
import { PopupVariantProps } from '../Popup.types';
import { PopupTitle } from '../popup-title';

export const PersonalityTestPopup: React.FC<PopupVariantProps> = ({
  svgIcon,
  title,
  variant,
  children,
  buttonText,
  onPrimaryClick,
  onClick,
}) => {
  const showPrimaryButton = Boolean(onPrimaryClick) && Boolean(buttonText);

  return (
    <PageContainer
      center
      onClick={onClick}
    >
      <div
        className='relative flex flex-col p-4 bg-white rounded-lg w-80 sm:w-96 text-greyishBrown'
        data-testid='e2e-popup'
      >
        {svgIcon && (
          <img
            src={svgIcon}
            className='absolute block transform -translate-x-1/2 pointer-events-none left-1/2 -translate-y-3/4'
            alt=''
          />
        )}

        {title && <PopupTitle variant={variant}>{title}</PopupTitle>}

        <div className='flex flex-col items-center justify-center overflow-visible'>{children}</div>
        {showPrimaryButton && (
          <div className='flex justify-center pt-4'>
            <Button
              variant='outlined'
              text={buttonText}
              data-testid={'personalitytest-popup-button'}
              onClick={onPrimaryClick}
            />
          </div>
        )}
      </div>
    </PageContainer>
  );
};
