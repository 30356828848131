import classnames from 'classnames';
import { FormatContentOption } from 'containers/FormatScreen/types';
import { personalityTest as personalityTestData } from 'data/personality-test';
import _shuffle from 'lodash/shuffle';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTransition } from 'react-spring';
import { Store } from 'store/types';
import { Playground } from 'templates/playground';
import { PlaygroundProps } from 'templates/playground/Playground.types';
import { actions } from '../PersonalityTest.ducks';
import { Collection as ICollection } from './Collections.types';
import { Collection } from './collection';
import { CollectionPopup } from './collection-popup';

const [collectionsTest] = personalityTestData;
const isOptionForCollection = (collectionId: string) => (option: FormatContentOption) =>
  option.groupId === collectionId;

const collections: ICollection[] = collectionsTest.content.groups.map((group) => ({
  ...group,
  options: collectionsTest.content.options.filter(isOptionForCollection(group.id)),
}));

const shuffledCollections = _shuffle(collections);

const TEST_SIZE = 3;

export const Collections = () => {
  const dispatch = useDispatch();
  const selectedCollections = useSelector<Store, string[]>(
    ({ personalityTest }) => personalityTest.test?.collections ?? [],
  );
  const [collectionOpen, setCollectionOpen] = useState<ICollection | null>(null);
  const history = useHistory();

  const breadcrumbs: PlaygroundProps['breadcrumbs'] = {
    crumbs: [
      {
        label: <FormattedMessage id={collectionsTest.title} />,
        location: '/',
      },
    ],
  };
  const canSubmitTest = selectedCollections.length === TEST_SIZE;

  const isSelected = (collectionId: string) => selectedCollections.includes(collectionId);

  const handleOpenCollection = (collection: ICollection) => () => {
    setCollectionOpen(collection);
  };

  const handleCloseCollection = () => {
    setCollectionOpen(null);
  };

  const handleAddCollection = () => {
    if (!collectionOpen || canSubmitTest) {
      return;
    }
    if (isSelected(collectionOpen.id)) {
      return;
    }
    dispatch(actions.addCollection(collectionOpen.id));
    handleCloseCollection();
  };
  const handleRemoveCollection = () => {
    if (!collectionOpen) {
      return;
    }
    dispatch(actions.removeCollection(collectionOpen.id));
    handleCloseCollection();
  };

  const handleSubmitTest = () => {
    history.push('/personality/test/tags');
  };

  const isOpenCollectionSelected = collectionOpen && isSelected(collectionOpen.id);
  const canLikeCollection = !canSubmitTest && !isOpenCollectionSelected;

  const transitions = useTransition(shuffledCollections, {
    unique: true,
    trail: 400 / collections.length,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const itemHasMarginLeft = (item: ICollection) => shuffledCollections.indexOf(item) % 3 !== 0;

  return (
    <Playground
      className='justify-center'
      breadcrumbs={breadcrumbs}
      heading={
        <p className='pt-0 px-3.5 pb-5'>
          <FormattedMessage id={collectionsTest.content.question} />
        </p>
      }
    >
      <div className='h-80 flex flex-wrap content-between py-0 px-3.5'>
        {transitions((styles, item) => (
          <Collection
            style={styles}
            collection={item}
            selected={isSelected(item.id)}
            onClick={handleOpenCollection(item)}
            className={classnames('h-24', { 'ml-[2%]': itemHasMarginLeft(item) })}
          />
        ))}
      </div>
      <CollectionPopup
        open={Boolean(collectionOpen)}
        collection={collectionOpen}
        onPositiveSubmit={handleAddCollection}
        onNegativeSubmit={handleRemoveCollection}
        disablePositiveSubmit={!canLikeCollection}
      />
      <Playground.Actions
        className='pt-20 !flex-grow-0'
        progress={{ current: 1, steps: 2, variant: 'circular' }}
        next={{ onClick: handleSubmitTest, disabled: !canSubmitTest }}
      />
    </Playground>
  );
};
