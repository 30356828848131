import { createSlice } from '@reduxjs/toolkit';
import { actions as authActions } from 'containers/authentication';
import { FormatScreenStore } from './types';

const initialState: FormatScreenStore = {
  rules: {
    streak: 0,
    quickAnswer: 0,
    backOnTrack: 0,
    niceComeback: 0,
    bonus: [],
    penalty: [],
  },
  error: null,
  unlockedBadges: [],
};

const slice = createSlice({
  name: 'formatscreen',
  initialState,
  reducers: {
    closeFormatsPopup: (state) => ({
      ...state,
      answerSubmitted: false,
      testSubmitted: false,
      answer: { correct: false, correctOptions: [] },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(authActions.getUserInfoSuccess, (state, action) => {
      const { rules } = action.payload;
      const bonus = rules.bonusModifiers
        .sort((a, b) => a.rankId - b.rankId)
        .map((currentBonus) => 1 + currentBonus.percentage / 100);

      const penalty = rules.penaltyModifiers
        .sort((a, b) => a.levelGap - b.levelGap)
        .map((currentPenalty) => 1 - currentPenalty.percentage / 100);

      return {
        ...state,
        rules: {
          streak: rules.streak.value,
          quickAnswer: rules.quickAnswer.value,
          backOnTrack: rules.backOnTrack.value,
          niceComeback: rules.niceComeback.value,
          bonus,
          penalty: [1].concat(penalty),
        },
      };
    });
  },
});

export const { actions } = slice;
export default slice.reducer;
