/**
 * Base JSS styles
 *
 * Every style code that is repited across the app, create a class here and spread there.
 */

export const ZIndex = {
  low: 10,
  medium: 30,
  high: 40,
  highest: 50,
};
