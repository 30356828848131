import { Popup, RequestCloseType } from 'components/popup';
import { PathUnlockedPopupComponentProps as Props } from 'pages/home/Home.types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { actions } from '../../Home.ducks';
import { UnlockPadlock } from './UnlockPadlock';

export const PathUnlockedPopup: React.FC<Props> = ({ onModalClose, open }) => {
  const dispatch = useDispatch();

  const intl = useIntl();

  const handleClose = (value: RequestCloseType) => {
    if (value !== 'modal') {
      onModalClose();
      dispatch(actions.toggleAnimateLocks(true));
    }
  };

  return (
    <Popup
      icon={<UnlockPadlock />}
      variant='faded'
      open={open}
      title={intl.formatMessage({ id: 'home.path-unlocked-popup.title' })}
      buttonText={intl.formatMessage({ id: 'home.path-unlocked-popup.button' })}
      onRequestClose={handleClose}
      closeIcon
      style={{ zIndex: 40 }}
      withConfettis
    >
      <div className='p-4 text-sm font-light text-center whitespace-pre-wrap'>
        <h2>
          <FormattedMessage id='home.path-unlocked-popup.subtitle' />
        </h2>
      </div>
    </Popup>
  );
};
