import certificationCompleteHeaderSvg from 'assets/images/certification_complete_header.svg';
import { Popup } from 'components/popup';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { actions } from '../../Lepa.ducks';
import { CertificationPassedModalProps as Props } from '../../Lepa.types';

const CertificationCompletedModalComponent: React.FC<Props> = ({ intl, modal, onModalClose, open }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.createCertificate({ pathId: modal.pathId }));
  }, [dispatch, modal.pathId]);

  return (
    <Popup
      variant='gradientFullscreen'
      open={open}
      className='flex items-center justify-center'
      buttonText={intl.formatMessage({ id: 'certification.completedPopup.completeButtonText' })}
      onRequestClose={onModalClose}
      closeIcon={false}
    >
      <div className='flex flex-col h-full content-evenly'>
        <div className='relative flex flex-col content-center w-full mb-8 text-center'>
          <img
            src={certificationCompleteHeaderSvg}
            className='m-auto w-72'
            alt=''
          />
          <h2>{intl.formatMessage({ id: 'certification.completedPopup.title' })}</h2>
        </div>

        <div className='text-grey3 text-center p-0.5'>
          <h3 className='m-2 text-2xl'>{intl.formatMessage({ id: 'certification.completedPopup.sub-title' })}</h3>
          <b className='m-0 text-3xl font-black'>{modal.pathName}</b>
          <h3 className='m-2 text-2xl'>{intl.formatMessage({ id: 'certification.sharePopup.subtitle' })}</h3>
          <br />
          <p className='max-w-lg mb-8'>{intl.formatMessage({ id: 'certification.completedPopup.description' })}</p>
        </div>
      </div>
    </Popup>
  );
};

export const CertificationCompletedModal = injectIntl(CertificationCompletedModalComponent);
