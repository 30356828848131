import AnimatedPath from 'components/AnimatedPath';
import { Loading } from 'components/Loading';
import { MinimalBanner } from 'components/PathBanner';
import { Avatar } from 'components/avatar';
import { Logo } from 'components/logo';
import { Media } from 'components/media';
import { PopupType } from 'components/popup';
import { API_URL } from 'constants/endpoints';
import { USER_ROLES } from 'constants/global';
import { Level, Module, Skill, TipsTricks } from 'containers/FormatScreen/types';
import { getRoleAccessLevel } from 'containers/authentication';
import { defaultLocale } from 'containers/language-provider';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { WalkthroughVariantTypes, shouldShowWalkthrough } from 'containers/walkthrough';
import _chunk from 'lodash/chunk';
import moment from 'moment';
import React from 'react';
import tinyColor from 'tinycolor2';
import { doNothing } from 'util/doNothing';
import { isOdd } from 'util/isOdd';
import { LepaProps as Props, LepaState as State } from './Lepa.types';

class Lepa extends React.Component<Props, State> {
  containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props) {
    super(props);

    this.state = {
      size: {
        width: 0,
        height: 0,
      },
      levels: [],
      levelTestAvailable: true,
      levelTestID: '',
      showWalkthrough: shouldShowWalkthrough(props.completedWalkthroughs, 'path'),
    };
  }

  // FIXME: this is to be removed after activePath state refactor
  static getDerivedStateFromProps(props: Props) {
    const { skippedRetentionTest, retentionTest: retentionTestFromRest } = props;

    function fillEmptyPaths(arr: Level[] | Skill[] | null[]) {
      const level = [...arr];
      const emptyPaths = level.length % 2 === 0 ? 2 : 3;

      for (let i = emptyPaths - 1; i >= 0; i -= 1) {
        level.push(null);
      }

      return level;
    }

    const {
      intl: { formatMessage },
    } = props;

    const levels = props.activePath?.levels.map((level: Level) => ({ ...level }));

    levels?.forEach((level, levelIndx) => {
      const levelAllModules = level.skills.reduce((acc: any, skill: Skill) => acc.concat(skill.modules), []);
      const levelAllModulesDone = levelAllModules.filter((skillModule: Skill) => skillModule.done);
      let tipsTricksIcons: PopupType[] = level.tipsTricks.map((tip: TipsTricks, indx: number) => ({
        ...tip,
        variant: 'tip',
        iconType: tip.opened ? 'tip' : 'light',
        levelIndx,
        indx,
      }));

      const retentionTestFormats = retentionTestFromRest?.learningTestFormats;
      const hasRetentionTest = (retentionTestFormats?.length ?? 0) > 0;
      const testId = retentionTestFromRest?.id;

      if (hasRetentionTest && !skippedRetentionTest) {
        const retentionTestPopup: PopupType = {
          id: testId,
          variant: 'retention',
          contentType: 'retentionTest',
          iconType: 'retention',
          title: formatMessage({ id: 'container.pathScreen.popup.title' }),
          summary: formatMessage({ id: 'container.pathScreen.popup.subTitle' }),
          buttonText: formatMessage({ id: 'container.pathScreen.button.startTest' }),
          pulse: true,
        };

        tipsTricksIcons = tipsTricksIcons.concat([retentionTestPopup]);
      }

      level.progress = levelAllModulesDone.length / levelAllModules.length;
      level.skills = fillEmptyPaths(level.skills) as Skill[];

      const chunks = _chunk(tipsTricksIcons, 2);
      level.popups = level.unlocked ? chunks : [];
    });

    return { levels };
  }

  componentDidMount() {
    const { match, profile, history } = this.props;
    const paramsPathID = match?.params.pathID;

    if (!paramsPathID) {
      history.replace('/invalid-url');
      return;
    }

    this.props.getLearningPath({
      lepaId: parseInt(paramsPathID, 10),
      userId: parseInt(profile.user.id, 10),
      locale: profile.user.locale ?? defaultLocale,
    });

    this.updateSize();
    this.renderPopupsFromHistory();
  }

  componentDidUpdate(prevProps: Props) {
    const { activePath: prevActivePath, levelTests: prevLevelTests } = prevProps;
    const {
      activePath,
      history,
      match,
      modals,
      profile,
      isLoadingRetentionTest,
      isLoadingLearningPath,
      learningPathError,
      levelTests,
    } = this.props;
    const paramsPathID = match?.params.pathID;

    if (!activePath) {
      return null;
    }

    const userLocale = profile.user.locale;
    const activePathLocale = activePath.locale;
    const activePathDefaultLocale = activePath.locales.find((locale) => locale.default)?.slug;
    const isUserLocaleInActivePathLocales = activePath.locales.some(
      (locale) => locale.slug === userLocale && locale.enabled,
    );
    const isActivePathLocaleDefault = activePathLocale === activePathDefaultLocale;
    const isLocaleAvailable = isUserLocaleInActivePathLocales || !isActivePathLocaleDefault;
    const isUserLocaleActive = userLocale === activePathLocale;
    const shouldActivePathLocaleChange = activePathLocale && !isUserLocaleActive && isLocaleAvailable;

    const paramsPathId = match?.params.pathID ?? '';
    const requestedNewPath =
      ((Boolean(paramsPathId) && paramsPathId !== activePath.id) || shouldActivePathLocaleChange) &&
      !isLoadingLearningPath;
    const isPathLoaded = activePath.levels && activePath.levels.length > 0;
    const isCrashCourse = activePath.type === 'crash_course';
    const isActivePathWithNoParamID = !paramsPathID && activePath.id;
    const hasNewPathLoaded =
      prevActivePath?.id !== activePath.id && isPathLoaded && !isLoadingRetentionTest && !isLoadingLearningPath;

    if (learningPathError) {
      return history.push('/');
    }

    if (isActivePathWithNoParamID) {
      return history.push(`/learning-path/${activePath.id}`);
    }

    if (requestedNewPath) {
      this.props.getRetentionTest({ lepaId: parseInt(paramsPathId, 10), userId: parseInt(profile.user.id, 10) });
      this.props.getLearningPath({
        lepaId: parseInt(paramsPathId, 10),
        userId: parseInt(profile.user.id, 10),
        locale: profile.user.locale ?? defaultLocale,
      });
    }

    if (hasNewPathLoaded) {
      this.updateSkills();
    }

    if (isCrashCourse) {
      return null;
    }

    const isPersonalityTestStarting = modals.currentModal?.type === ModalTypes.PERSONALITY_TEST_START;
    const showLepaWalkthrough = hasNewPathLoaded && !isPersonalityTestStarting;
    if (showLepaWalkthrough) {
      this.renderLepaWalkthrough();
    }
    const hasLevelTestBeenRequested =
      levelTests.filter(({ learningTestId }) => !!learningTestId).length >
      prevLevelTests.filter(({ learningTestId }) => !!learningTestId).length;
    if (hasLevelTestBeenRequested) {
      this.renderLevelTestWalktrough();
    }
    return null;
  }

  fillEmptyPaths = (arr: Level[] | Skill[] | null[]) => {
    const level = [...arr];
    const emptyPaths = level.length % 2 === 0 ? 2 : 3;

    for (let i = emptyPaths - 1; i >= 0; i -= 1) {
      level.push(null);
    }

    return level;
  };

  updateSize = () => {
    const container = this.containerRef.current;
    if (!container) {
      return;
    }
    const size = { width: container.clientWidth, height: container.clientHeight };
    this.setState({ size });
  };

  updateSkills = () => {
    const {
      intl: { formatMessage },
      skippedRetentionTest,
      enqueueModal,
      isPersonalityTestEnabled,
      profile,
      retentionTest: retentionTestFromRest,
    } = this.props;

    const levels = this.props.activePath?.levels.map((level: Level) => ({ ...level })) ?? [];
    const { persona, isLocaleSelected } = profile.user;

    const isPersonalityTestCompleted = isPersonalityTestEnabled && Boolean(persona);
    const hasPersona = isPersonalityTestCompleted || !isPersonalityTestEnabled;
    const shouldShowRetentionTest = isLocaleSelected && hasPersona;

    const testId = retentionTestFromRest?.id ?? '';
    const retentionTestFormats = retentionTestFromRest?.learningTestFormats ?? [];
    const hasRetentionTest = Boolean(testId) && retentionTestFormats.length > 0;

    if (shouldShowRetentionTest && hasRetentionTest && !skippedRetentionTest) {
      const retentionTestPopup: PopupType = {
        id: testId,
        variant: 'retention',
        iconType: 'retention',
        title: formatMessage({ id: 'container.pathScreen.popup.title' }),
        summary: formatMessage({ id: 'container.pathScreen.popup.subTitle' }),
        buttonText: formatMessage({ id: 'container.pathScreen.button.startTest' }),
        pulse: true,
      };

      enqueueModal({
        type: ModalTypes.RETENTION_QUIZ,
        modal: {
          buttonText: retentionTestPopup.buttonText,
          closeIcon: true,
          id: retentionTestPopup.id,
          summary: retentionTestPopup.summary,
          title: retentionTestPopup.title,
        },
      });
    }

    levels.forEach((level, levelIndx) => {
      const levelAllModules = level.skills.reduce((acc: Module[], skill: Skill) => acc.concat(...skill.modules), []);
      const levelAllModulesDone = levelAllModules.filter((skillModule) => skillModule.done);
      let tipsTricksIcons: PopupType[] = level.tipsTricks.map((tip: TipsTricks, indx: number) => ({
        ...tip,
        variant: 'tip',
        iconType: tip.opened ? 'tip' : 'light',
        levelIndx,
        indx,
      }));

      if (shouldShowRetentionTest && hasRetentionTest && !skippedRetentionTest) {
        const retentionTestPopup: PopupType = {
          id: testId ?? '0',
          variant: 'retention',
          iconType: 'retention',
          title: formatMessage({ id: 'container.pathScreen.popup.title' }),
          summary: formatMessage({ id: 'container.pathScreen.popup.subTitle' }),
          buttonText: formatMessage({ id: 'container.pathScreen.button.startTest' }),
          pulse: true,
        };

        tipsTricksIcons = tipsTricksIcons.concat([retentionTestPopup]);
      }

      level.progress = levelAllModulesDone.length / levelAllModules.length;
      level.skills = this.fillEmptyPaths(level.skills) as Skill[];
      const chunks = _chunk(tipsTricksIcons, 2);
      level.popups = level.unlocked ? chunks : [];
    }); //

    this.setState({ levels });
  };

  /** helper function to know if the animated path should be reversed */
  isReversed = (idx: number) => idx % 2 === 0;

  handleSkillNodeClick = (levelID: string) => (skillID: string) => {
    const pathID = this.props.match?.params.pathID;
    if (!pathID) {
      return;
    }

    this.props.history.push(`/learning-path/${pathID}/level/${levelID}/skill/${skillID}`);
  };

  handlePathIconClick = (iconData: PopupType) => {
    const { enqueueModal } = this.props;
    const { variant } = iconData;

    switch (variant) {
      case 'retention': {
        return enqueueModal({
          type: ModalTypes.RETENTION_QUIZ,
          modal: {
            buttonText: iconData.buttonText,
            closeIcon: true,
            id: iconData.id,
            summary: iconData.summary,
            title: iconData.title,
          },
        });
      }
      case 'tip': {
        return enqueueModal({
          type: ModalTypes.TIP,
          modal: iconData,
        });
      }

      default: {
        return console.warn(`An icon was clicked with a variant value of ${variant}, with no action handler.`);
      }
    }
  };

  handleLevelTestStart = (levelId: string, learningTestId?: string | null) => () => {
    const pathID = this.props.match?.params.pathID;
    if (!pathID) {
      return;
    }
    if (!learningTestId) {
      return;
    }
    this.props.history.push(`/learning-path/${pathID}/level/${levelId}/test/${learningTestId}`);
  };

  /** handler for rendering all of the levels */
  renderLevels() {
    const { profile, intl, activePath, levelTests } = this.props;
    const { levels, levelTestAvailable, levelTestID, showWalkthrough } = this.state;
    const { avatar } = profile.user;
    const avatarUrl = avatar ? `${API_URL}/${avatar}` : '';

    if (levels.length <= 0) {
      return (
        <div className='text-center whitespace-pre-wrap'>
          {intl.formatMessage({ id: 'container.pathScreen.pathless.message' })}
        </div>
      );
    }

    const strings = {
      title: intl.formatMessage({ id: 'container.pathScreen.banner.title' }),
      text: intl.formatMessage({ id: 'container.pathScreen.banner.text' }).split('\n'),
      done: intl.formatMessage({ id: 'container.pathScreen.banner.done' }).split('\n'),
      startText: intl.formatMessage({ id: 'container.pathScreen.banner.startText' }),
    };

    // When there is a Walktrough and having many levels the component scrolling behaviour is unpredictable
    // so the tutorial is focused on one level only
    const [firstLevel] = levels;
    const levelsToRender = showWalkthrough ? [firstLevel] : levels;

    return levelsToRender
      .map((level: Level, levelIndx: number) => {
        const totalPaths = level.skills?.length - 1;
        const totalSkills = activePath?.levels[levelIndx].skills.length ?? 0;
        const tips = level.popups;
        const tipsArraySize = tips.length - 1;
        const pathColor = level.color;
        const pathColorLight = tinyColor(pathColor).lighten(20).toString();

        // Checks if its user or manager role.
        const roleAccessLevel = getRoleAccessLevel(profile.user.role);
        const isUser = roleAccessLevel !== USER_ROLES.TEST && roleAccessLevel !== USER_ROLES.PREVIEW;
        const isPreviewUserRole = roleAccessLevel === USER_ROLES.PREVIEW;

        const isNextPath = (skill: Skill) => {
          if (!skill) {
            return false;
          }
          const isNextLevel = levelIndx === activePath?.currentLevelIndex;
          return isNextLevel && skill.unlocked && !skill.passed && !skill.done;
        };

        const isCurrentLevelTestAvailable = levelTests?.some(
          ({ learningTestId, levelId, passed }) => !!learningTestId && levelId === level.id && !passed,
        );
        const learningTestId = levelTests?.find(({ levelId }) => levelId === level.id)?.learningTestId;

        return [
          <MinimalBanner
            key={`level-banner-${level.id}`}
            startText={strings.startText}
            done={level.passed}
            title={level.title.toUpperCase() + strings.title}
            unlocked={isCurrentLevelTestAvailable}
            onStartClicked={this.handleLevelTestStart(level.id, learningTestId)}
            color={pathColor}
            lockedColor={pathColorLight}
            level={level}
          />,
          <div
            key={`level-${level.id}`}
            className='relative flex flex-col items-center w-full'
          >
            {level.skills
              .map((skill: Skill, idx: number) => {
                if (idx === 0) {
                  return null;
                }
                if (isOdd(totalSkills) && (idx === totalPaths - 1 || idx === totalPaths)) {
                  return null;
                }
                const percentage = level.progress < 1 ? (level.progress * (totalPaths * 100)) / 100 - idx : 1;
                const percentageGreaterThanZero = percentage > 1 ? 1 : percentage;

                return (
                  <AnimatedPath
                    key={`skill-${level.id}-${skill ? skill.id : idx}`} // there are nulls in level.skills
                    percentage={percentage <= 0 ? 0 : percentageGreaterThanZero}
                    primaryColor={pathColor}
                    secondaryColor={pathColor}
                    reverse={this.isReversed(idx)}
                    skill={skill}
                    tips={tipsArraySize >= idx ? tips[idx] : []}
                    onSkillNodeClick={skill ? this.handleSkillNodeClick(level.id) : doNothing}
                    onTipClick={this.handlePathIconClick}
                    isNext={isNextPath(skill)}
                    unlocked={this.hasPathSectionBeenReached(
                      skill,
                      level,
                      levelTestAvailable && levelTestID === level.id,
                      isUser,
                    )}
                    isPreviewUserRole={isPreviewUserRole}
                  />
                );
              })
              .reverse()}

            <AnimatedPath
              className={levelIndx === 0 ? 'walkthrough-animatedPath' : ''}
              key={`start-${level.id}-${level.skills[0].id}`}
              percentage={
                (level.progress * (totalPaths * 100)) / 100 > 1 ? 1 : (level.progress * (totalPaths * 100)) / 100
              }
              primaryColor={pathColor}
              secondaryColor={pathColor}
              reverse
              skill={level.skills[0]}
              tips={tipsArraySize >= 0 ? tips[0] : []}
              onSkillNodeClick={level.skills[0] ? this.handleSkillNodeClick(level.id) : doNothing}
              onTipClick={this.handlePathIconClick}
              isNext={isNextPath(level.skills[0])}
              unlocked={isCurrentLevelTestAvailable || level.done || level.unlocked}
              isPreviewUserRole={isPreviewUserRole}
            />

            {levelIndx === 0 ? (
              <div className='relative flex justify-end w-64 text-right transform -translate-y-1 ml-11'>
                <Avatar
                  borderColor={pathColor}
                  src={avatarUrl}
                  onClick={this.avatarClickHandler}
                  editable
                />
              </div>
            ) : null}
          </div>,
        ];
      })
      .reverse();
  }

  /** calculates if a path section `AnimatedPath` should has been unlocked or not */
  hasPathSectionBeenReached = (skill: Skill, level: Level, levelTestAvailable: boolean, isUser: boolean) => {
    if (skill) {
      return skill.unlocked;
    }
    return levelTestAvailable || level.done || !isUser || level.passed;
  };

  /** avatar onclick event handler */
  avatarClickHandler = () => {
    this.props.history.push('/profile', { requestedTabIndex: 2 });
  };

  /** checks and displays modals that came from outside of the container */
  renderPopupsFromHistory = () => {
    const { location, enqueueModal, activePath, history, levelTests, skillTests } = this.props;

    if (!activePath) {
      return null;
    }

    const isCrashCourse = activePath.type === 'crash_course';
    if (isCrashCourse) {
      return null;
    }

    const currentLevelId = location.state ? location.state.levelId : null;

    if (!currentLevelId) {
      return null;
    }

    const tipData = location.state.tip;

    if (!tipData) {
      return null;
    }

    const levelLearningTestId =
      levelTests?.find(({ levelId, passed }) => levelId === tipData.levelID && !passed)?.learningTestId ?? '';
    const skillLearningTestId = skillTests?.find(({ skillId }) => skillId === tipData.skillID)?.learningTestId ?? '';

    const isLevelLearningTestIdMissing =
      [ModalTypes.LEVEL_FAILED, ModalTypes.UNLOCKED_FINAL_TEST].includes(tipData.modalType) && !levelLearningTestId;
    const isSkillLearningTestIdMissing = [ModalTypes.SKILL_FAILED].includes(tipData.modalType) && !skillLearningTestId;
    if (isLevelLearningTestIdMissing || isSkillLearningTestIdMissing) {
      return null;
    }

    const resolveActionHandler: { [modal: string]: () => void } = {
      [ModalTypes.SKILL_FAILED]: () =>
        history.push(
          `/learning-path/${activePath.id}/level/${tipData.levelID ?? ''}/skill/${
            tipData.skillID ?? ''
          }/test/${skillLearningTestId}`,
        ),
      [ModalTypes.LEVEL_FAILED]: () =>
        history.push(`/learning-path/${activePath.id}/level/${tipData.levelID ?? ''}/test/${levelLearningTestId}`),
      [ModalTypes.UNLOCKED_FINAL_TEST]: () =>
        history.push(`/learning-path/${activePath.id}/level/${currentLevelId}/test/${levelLearningTestId}`),
    };

    switch (tipData.modalType) {
      case ModalTypes.CERTIFICATION_PASSED: {
        const { pathName } = tipData;
        const pathId = activePath.id;
        const { color } = activePath;

        enqueueModal({
          type: ModalTypes.CERTIFICATION_PASSED,
          modal: {
            pathName,
            pathId,
          },
        });
        return enqueueModal({
          type: ModalTypes.CERTIFICATION_SHARE,
          modal: {
            date: moment().format('DD-MM-YYYY'),
            pathName,
            color,
          },
        });
      }

      case ModalTypes.LEVEL_PASSED: {
        return enqueueModal({
          type: ModalTypes.LEVEL_PASSED,
          modal: {
            buttonText: tipData.buttonText,
            levelTestTitle: tipData.levelTestTitle,
            summary: tipData.summary,
            title: tipData.title,
          },
        });
      }

      default: {
        return enqueueModal({
          type: tipData.modalType,
          modal: {
            ...tipData,
            onPrimaryClick: resolveActionHandler[tipData.modalType],
          },
        });
      }
    }
  };

  /** completes the Walkthrough on the client and server side */
  onWalkthroughEnd = (slug: WalkthroughVariantTypes) => (): Promise<void> => {
    this.setState({ showWalkthrough: false });
    const { profile, completeWalkthrough } = this.props;
    return completeWalkthrough({
      userId: profile.user.id,
      body: { slug },
    }).catch(doNothing);
  };

  render() {
    const {
      themeBg,
      accountLogo,
      showLogo,
      activePath,
      history,
      match,
      learningPathError,
      learningPathCache,
      isLoadingLearningPath,
    } = this.props;
    const paramsPathId = match?.params.pathID;

    const isErrorAndMatchingId = learningPathError && paramsPathId === String(learningPathCache?.lepaId);

    if (isErrorAndMatchingId) {
      history.push('/invalid-url');
      return null;
    }

    if (isLoadingLearningPath) {
      return <Loading />;
    }

    if (!activePath) {
      return null;
    }

    const isCrashCourse = activePath.type === 'crash_course';
    const hasLecoBeenSet = !paramsPathId || activePath.id === paramsPathId;
    const isLecoLoaded = hasLecoBeenSet && paramsPathId === activePath.id;

    if (isLecoLoaded && isCrashCourse) {
      history.push(`/crash-course/${activePath.id}`);
      return <div />;
    }

    const showAccountLogo = showLogo && accountLogo;

    const ww = window.outerWidth || window.innerWidth;

    return (
      <div
        ref={this.containerRef}
        className='relative flex flex-col h-full pt-10 pb-5 overflow-x-hidden lg:w-full'
        data-testid='path-screen'
      >
        {themeBg && (
          <Media
            alt='background'
            key='theme-bg'
            className='fixed top-0 w-full -translate-x-1/2 bottom-12 left-1/2'
            src={themeBg}
            style={{
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              maxWidth: ww > 500 ? 500 : ww,
            }}
          />
        )}

        <div
          className='flex-1'
          dir='ltr'
        >
          {this.renderLevels()}
        </div>

        {showAccountLogo && (
          <div className='flex items-end content-start object-cover w-20 h-14 ml-7'>
            <Logo
              alt='logo'
              component='img'
              key='account-logo-avatar'
              className='w-auto h-full'
              src={accountLogo}
            />
          </div>
        )}
      </div>
    );
  }

  renderLepaWalkthrough = () => {
    const { completedWalkthroughs, locale, enqueueModal } = this.props;
    const { showWalkthrough } = this.state;

    if (showWalkthrough && shouldShowWalkthrough(completedWalkthroughs, 'path')) {
      enqueueModal({
        type: ModalTypes.WALKTHROUGH,
        modal: {
          variant: 'path',
          targets: [
            'body',
            '.walkthrough-animatedPath > :last-child',
            '.walkthrough-animatedPath > :last-child > :first-child',
            '.walkthrough-animatedPath > :last-child > :first-child > :first-child',
          ],
          lang: locale,
          onEnd: this.onWalkthroughEnd('path'),
          onSkip: this.onWalkthroughEnd('path'),
        },
      });
    }
  };

  renderLevelTestWalktrough = () => {
    const { completedWalkthroughs, locale, levelTests, enqueueModal } = this.props;

    const hasLevelTestUnlocked = levelTests.some(({ learningTestId, done }) => !!learningTestId && !done);
    if (shouldShowWalkthrough(completedWalkthroughs, 'level-quiz') && hasLevelTestUnlocked) {
      enqueueModal({
        type: ModalTypes.WALKTHROUGH,
        modal: {
          variant: 'level-quiz',
          targets: ['body'],
          lang: locale,
          onEnd: this.onWalkthroughEnd('level-quiz'),
          onSkip: this.onWalkthroughEnd('level-quiz'),
        },
      });
    }
  };
}

export default Lepa;
