import { Popup } from 'components/popup';
import React from 'react';
import { RewardsModalProps } from './RewardsModal.types';

/**
 * Generic modal structure to display the reward associated with
 * a given start reward for learning moments, levels, skills and badges.
 */
export const RewardsModal: React.FC<RewardsModalProps> = ({ intl, modal, onModalClose, open }) => {
  const { onPrimaryClick, onSecondaryClick, title, buttonText, closeIcon, summary, next, text, variant, ...rest } =
    modal;

  const handlePrimaryButtonClick = (event: React.SyntheticEvent) => {
    onModalClose();

    if (onPrimaryClick) {
      onPrimaryClick(event);
    }
  };

  const handleSecondaryButtonClick = (event: React.SyntheticEvent) => {
    onModalClose();

    if (onSecondaryClick) {
      onSecondaryClick(event);
    }
  };

  const modalTextClassNames = 'text-center text-sm font-light pt-2 whitespace-pre-wrap';

  return (
    <Popup
      variant={variant}
      open={open}
      title={title}
      buttonText={buttonText || intl.formatMessage({ id: 'formats.button.thanks' })}
      onRequestClose={onModalClose}
      closeIcon={closeIcon}
      onPrimaryClick={handlePrimaryButtonClick}
      onSecondaryClick={handleSecondaryButtonClick}
      hasThanksAndCloseButton={true}
      {...rest}
    >
      <div className={modalTextClassNames}>{text}</div>

      {summary && (
        <div
          key='text-container'
          className={modalTextClassNames}
        >
          {summary}
        </div>
      )}

      {next && <div className={modalTextClassNames}>{next}</div>}
    </Popup>
  );
};
