import classnames from 'classnames';
import { Media } from 'components/media';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import BadgeBanner from './BadgeBanner';
import { BadgeProps as Props } from './types';

export const Badge: React.FC<Props> = ({ locked, title = '', borderColor = '', rootClassName, className, ...rest }) => (
  <div
    data-testid='e2e-badge'
    className={classnames(
      rootClassName,
      'relative bg-gradient-to-b w-16 h-16',
      { 'p-1 rounded-full inline-block cursor-pointer': Boolean(borderColor) },
      { 'from-gray-300 to-gray-400': borderColor === 'grey' },
      { 'from-fullGreen to-yellow-200': borderColor === 'green' },
      { 'from-yellow-300 to-yellow-600': borderColor === 'orange' },
      { 'opacity-40 relative grayscale': locked },
    )}
  >
    <Media
      alt={`${title} Badge`}
      className={classnames(className, { 'border-2 rounded-full border-white': borderColor }, 'object-fill')}
      {...rest}
    />
    {locked && (
      <div className='absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2'>
        <SvgIcon
          size={24}
          name='lock'
          color='white'
        />
      </div>
    )}
    {borderColor && (
      <BadgeBanner
        text={title}
        className='absolute bottom-0 transform -translate-x-1/2 left-1/2'
      />
    )}
  </div>
);
