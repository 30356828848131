import { API_URL } from 'constants/endpoints';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useChatContext } from 'stream-chat-react';
import { Avatar } from '../../../../components/avatar';
import { Store } from '../../../../store/types';
import { ChatHeaderProps } from './Conversation.types';

export const ChatHeader: React.FC<ChatHeaderProps> = ({ members, userId, openChatInfo }) => {
  const { account } = useSelector(({ profile }: Store) => profile);
  const { channel } = useChatContext();

  const isGroupChat = Boolean(channel?.data?.isGroup);

  const getHeaderUser = () => {
    const user = members?.find((member) => member?.user?.id !== userId)?.user;
    const userAvatar = user?.image ? `${API_URL}/${String(user?.image)}` : '';

    return {
      name: user?.name,
      avatar: userAvatar,
    };
  };

  const getHeaderGroup = () => ({
    name: channel?.data?.name,
    avatar: channel?.data?.image,
  });

  const getHeaderInfo = () => {
    if (isGroupChat) {
      return getHeaderGroup();
    }
    return getHeaderUser();
  };

  const { name, avatar } = getHeaderInfo();

  return (
    <div className='flex w-full h-full px-6 text-white'>
      <button
        className='flex items-center w-full'
        onClick={openChatInfo}
      >
        <div className='w-12 h-12'>
          <Avatar
            title={name}
            disableBorder
            primaryColor={account.primaryColor}
            className='w-12 h-12 drop-shadow-md'
            src={avatar}
          />
        </div>
        <div className='w-3/4 pl-2 text-left'>
          <h1 className='overflow-hidden text-base font-normal overflow-ellipsis whitespace-nowrap'>{name}</h1>
          {isGroupChat && (
            <p className='text-xs font-light text-neutral-300'>
              <FormattedMessage
                id='community.chat.header-group-info'
                defaultMessage='Click here for group info'
              />
            </p>
          )}
        </div>
      </button>
    </div>
  );
};
