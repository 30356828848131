import React from 'react';
import { SvgCrownProps } from './types';

const SvgCrown: React.FC<SvgCrownProps> = ({ width = 26, height = 19, color = 'currentColor', gradient, ...rest }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    data-testid='svg-crown'
    {...rest}
    style={{
      display: 'inline',
      verticalAlign: 'baseline',
    }}
  >
    <defs>
      {gradient || color ? (
        <linearGradient
          x1='50%'
          y1='100%'
          x2='50%'
          y2='0%'
          id={`linearGradient-${gradient ? gradient.begin : color}`}
        >
          <stop
            stopColor={`${gradient ? gradient.begin : color}`}
            offset='0%'
          />
          <stop
            stopColor={`${gradient ? gradient.end : color}`}
            offset='100%'
          />
        </linearGradient>
      ) : null}
    </defs>
    <g
      id='0.0-First-Use-Onboarding'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='onboarding_9_progression'
        transform='translate(-44.000000, -373.000000)'
        fill={`url(#linearGradient-${gradient ? gradient.begin : color})`}
      >
        <g
          id='progression'
          transform='translate(17.000000, 314.000000)'
        >
          <g
            id='Last-Month'
            transform='translate(22.000000, 43.000000)'
          >
            <g
              id='Page-1'
              transform='translate(5.000000, 16.000000)'
            >
              <path
                d='M2.63095813,8.35524235 C4.99913543,9.9649027 10.2179171,12.1675371 12.6905533,2.67137397 C12.0570472,2.53671514 11.5834233,1.99705538 11.5834233,1.35184543 C11.5834233,0.605054864 12.2175696,0 12.9997842,0 C13.7822145,0 14.4163609,0.605054864 14.4163609,1.35184543 C14.4163609,1.99701036 13.9428031,2.53663983 13.3092242,2.67134578 C15.7818766,12.1675772 21.0008152,9.9648877 23.369039,8.35522417 C23.2408109,8.15189283 23.1670624,7.91372669 23.1670624,7.65912521 C23.1670624,6.91254066 23.8012087,6.30727978 24.5836391,6.30727978 C25.3658537,6.30727978 26,6.91254066 26,7.65912521 C26,8.40570976 25.3658537,9.01097064 24.5836391,9.01097064 C24.3158171,9.01097064 24.065369,8.94005474 23.8517803,8.81682927 L18.6118066,19 L12.9998921,19 L7.38797755,19 L2.14821338,8.81686835 C1.93467145,8.94006973 1.68429652,9.01097064 1.41657673,9.01097064 C0.633930499,9.01097064 0,8.40570976 0,7.65912521 C0,6.91254066 0.633930499,6.30727978 1.41657673,6.30727978 C2.19879128,6.30727978 2.83293762,6.91254066 2.83293762,7.65912521 C2.83293762,7.91373428 2.7591847,8.15190703 2.63095813,8.35524235 Z'
                id='Combined-Shape'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgCrown;
