import * as Sentry from '@sentry/react';
import * as React from 'react';
import store from 'store';

const ENV = process.env.REACT_APP_NODE_ENV;

const __PROD__ = ENV === 'production';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
  } as React.CSSProperties,
  errorInfo: {
    color: 'red',
  },
};

class ErrorMonitor extends React.Component {
  public state = {
    hasError: false,
    error: '',
    errorStack: '',
  };

  public componentDidCatch(error: Error, info: any) {
    if (__PROD__) {
      Sentry.withScope((scope) => {
        scope.setExtras({ extra: { state: store.getState() } });
        Sentry.captureException(error);
      });
    }

    this.setState({ hasError: true, error, errorStack: info.componentStack });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={styles.container}>
          <h1>Oops!</h1>
          <div style={styles.errorInfo}>{this.renderError()}</div>
        </div>
      );
    }

    return this.props.children;
  }

  private refreshWindow = () => {
    window.location.reload();
  };

  private renderError() {
    const { error, errorStack } = this.state;
    const stack = errorStack.split('\n').map((line, indx) => <div key={indx}>{line}</div>);

    if (__PROD__) {
      return (
        <div style={{ textAlign: 'center', cursor: 'pointer' }}>
          <p>We are sorry — something has gone wrong.</p>
          <p>Our team has been notified.</p>
        </div>
      );
    }

    return [
      <div key='error'>{error.toString()}</div>,
      ...stack,
      <button
        key='refresh'
        onMouseUp={this.refreshWindow}
      >
        RELOAD
      </button>,
    ];
  }
}

export default ErrorMonitor;
