import { LecoOverview } from 'containers/leco-overview';
import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { shouldShowWalkthrough, useWalkthrough, Walkthrough } from 'containers/walkthrough';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import theme from 'theme';
import { ContentProps as Props } from './Content.types';
import { BuiltInPathCategories, Leco, PathCategories } from './Home.types';
import { Lololeco } from './Lololeco';
import { NoContent } from './NoContent';
import { categorizePathsSelector } from './selector';
import { isLepa, useLecoCardConfiguration } from './useLecoCardConfiguration';

const Content: React.FC<Props> = ({ isFiltered }) => {
  const { onWalkthroughEnd, completedWalkthroughs, locale } = useWalkthrough();
  const currentModal = useSelector(({ modals }: RootState) => modals.currentModal);
  const handleCompleteWalkthrough = () => onWalkthroughEnd('home');
  const isAuthorizing = useSelector(({ auth }: RootState) => auth.isAuthorizing);
  const { paths, featuredPath, showInstallInstructionReminder } = useSelector(({ home }: RootState) => ({
    ...home,
    /*
     * Because super user does not have featured paths
     * we use the first element of the paths array.
     * This way skillflix UI maintains its integrity
     * when logged in with a super user.
     */
    featuredPath: home.featuredPath ?? home.paths[0],
  }));
  const pathsByCategory = useSelector<RootState, PathCategories>(categorizePathsSelector);
  const { logoDarkUrl, logoUrl, pathColor } = useSelector(({ profile }: RootState) => profile.account);

  const lecoCardConfiguration = useLecoCardConfiguration();
  const { enqueueModal } = useModalQueue();

  const darkAccountLogo = logoDarkUrl || logoUrl;
  const isEmpty = paths.length <= 0;
  const isDisplayingInstallInstructions =
    currentModal?.type === ModalTypes.INSTALL_INSTRUCTION_STEPS || showInstallInstructionReminder;
  const isInstallInstructionsFeatureFlagEnabled = useFeatureFlag(FlagKeys.INSTALL_INSTRUCTIONS);
  const isInstallInstructionsPresentedOrNotNeeded =
    !isInstallInstructionsFeatureFlagEnabled || !shouldShowWalkthrough(completedWalkthroughs, 'install-app');
  const showWalkthrough =
    !isAuthorizing &&
    shouldShowWalkthrough(completedWalkthroughs, 'home') &&
    isInstallInstructionsPresentedOrNotNeeded &&
    !isDisplayingInstallInstructions;
  const showCategories = isFiltered || paths.length > 1;

  const playButtonColor = featuredPath?.playButtonColor || pathColor || theme.colors.orange;

  const handleLecoPlay = (leco: Leco) => lecoCardConfiguration[leco.type].play(leco);

  const handleLecoInfoOpen = (leco: Leco, e: React.MouseEvent) => {
    e.stopPropagation();

    const { canOpen, openInfo } = lecoCardConfiguration[leco.type];

    if (!canOpen(leco)) {
      const lockedByPaths = isLepa(leco) ? leco.content.lockedBy : [];

      return enqueueModal({
        type: ModalTypes.PATH_LOCKED,
        paths: lockedByPaths,
      });
    }

    return openInfo(leco);
  };

  const handleFeaturedPathClick = (event: React.MouseEvent) => {
    if (!featuredPath) {
      return null;
    }

    const hasNewCategory = featuredPath.categories.find((category) => category.title === BuiltInPathCategories.NEW);
    if (hasNewCategory) {
      return handleLecoInfoOpen(featuredPath, event);
    }

    return handleLecoPlay(featuredPath);
  };

  if (isFiltered && isEmpty) {
    return <NoContent />;
  }

  if (isEmpty) {
    return (
      <div className='relative flex flex-col w-full h-full overflow-y-auto'>
        <div className='m-auto text-center text-white'>
          <p className='whitespace-pre-line'>
            <FormattedMessage id='home.content.empty' />
          </p>
        </div>
      </div>
    );
  }

  if (!pathsByCategory) {
    return null;
  }

  return (
    <div
      className='relative flex flex-col h-full'
      data-testid='content'
    >
      {featuredPath && (
        <LecoOverview
          leco={featuredPath}
          accountLogo={darkAccountLogo}
          onClick={handleFeaturedPathClick}
          onLecoInfoOpen={handleLecoInfoOpen}
          contentClassName={showCategories ? '' : 'h-1/2 mb-20'}
          playButtonColor={playButtonColor}
          locale={locale}
        />
      )}

      {showCategories && (
        <Lololeco
          categories={pathsByCategory}
          onLecoPlay={handleLecoPlay}
          onLecoInfoOpen={handleLecoInfoOpen}
          locale={locale}
        />
      )}

      {showWalkthrough && (
        <Walkthrough
          variant='home'
          open
          disableScrolling
          targets={['body', '#featured-path', '#home-categories > *:first-child']}
          externalConditions={{
            showCategories,
          }}
          lang={locale}
          onSkip={handleCompleteWalkthrough}
          onEnd={handleCompleteWalkthrough}
        />
      )}
    </div>
  );
};

export default Content;
