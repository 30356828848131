import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import theme from 'theme';
import { BaseButton } from '../base-button';
import { IconButtonProps } from './IconButton.types';

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  size,
  color = theme.colors.turquoiseBlue,
  className = '',
  viewBox,
  ...baseButtonProps
}) => {
  const iconColor = !baseButtonProps.disabled ? color : 'currentColor';

  return (
    <BaseButton
      className={className}
      {...baseButtonProps}
    >
      <SvgIcon
        name={icon}
        color={iconColor}
        size={size}
        viewBox={viewBox}
      />
    </BaseButton>
  );
};
