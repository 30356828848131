import Animated from 'animated/lib/targets/react-dom';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ZIndex } from 'styles/base';
import { FullScreenContainerProps } from './Media.types';

class FullScreenContainer extends React.Component<FullScreenContainerProps> {
  private animTop = new Animated.Value(0);

  private root: React.RefObject<HTMLDivElement> = React.createRef();

  private rootRect = {
    x: 0,
    y: 0,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  };

  private childRect = {
    x: 0,
    y: 0,
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  };

  private middle = 0;

  private timer?: number | NodeJS.Timeout;

  public componentDidMount() {
    // @ts-ignore: HTML elements
    const rootRef = this.root.current && this.root.current.refs.node;
    // @ts-ignore: HTML elements
    const ref = this.props.children.ref && this.props.children.ref.current;

    if (rootRef && ref) {
      this.rootRect = rootRef.getBoundingClientRect();
      this.childRect = ref.getBoundingClientRect();

      if (this.rootRect.height > this.rootRect.width) {
        this.middle = this.rootRect.height / 2 - this.childRect.height / 2;
      } else {
        this.middle = this.rootRect.height * 0.05;
      }

      this.animTop.setValue(this.childRect.top);

      this.animateOpen();

      window.addEventListener('orientationchange', this.onOrientationchange);
    }
  }

  public componentWillUnmount() {
    clearTimeout(this.timer as number);
    window.removeEventListener('orientationchange', this.onOrientationchange);
  }

  public render() {
    const { children } = this.props;

    const rootStyles = {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
      zIndex: ZIndex.highest,
      backgroundColor: '#000000',
      overflow: 'scroll',
    };

    const wrapperStyles = {
      position: 'relative',
      width: '90%',
      maxWidth: 700,
      margin: '0 auto',
      transform: [{ translateY: this.animTop }],
    };

    const child = React.cloneElement(children, {
      ref: null,
      onClick: undefined,
      style: {
        ...children.props.style,
        transform: 'none',
        position: 'relative',
        top: 0,
        left: 0,
        width: '100%',
        pointerEvents: 'none',
      },
    });

    const container = (
      <Animated.div
        ref={this.root}
        style={rootStyles}
        onClick={this.animateClose}
      >
        <Animated.div style={wrapperStyles}>{child}</Animated.div>
      </Animated.div>
    );

    return ReactDOM.createPortal(container, document.getElementById('root') as HTMLElement);
  }

  private animateOpen = () => {
    Animated.sequence([
      Animated.delay(150),
      Animated.timing(this.animTop, { toValue: this.middle, duration: 300 }),
    ]).start();
  };

  private animateClose = (event: React.MouseEvent) => {
    Animated.timing(this.animTop, { toValue: this.childRect.top, duration: 300 }).start(() => {
      if (this.props.onClose) {
        this.props.onClose(event);
      }
    });
  };

  private onOrientationchange = () => {
    clearTimeout(this.timer as number);

    this.timer = setTimeout(() => {
      // @ts-ignore: HTML elements
      const rootRef = this.root && this.root.current && this.root.current.refs && this.root.current.refs.node;
      // @ts-ignore: HTML elements
      const ref = this.props.children.ref && this.props.children.ref.current;

      if (rootRef && ref) {
        this.rootRect = rootRef.getBoundingClientRect();
        this.childRect = ref.getBoundingClientRect();

        if (this.rootRect.height > this.rootRect.width) {
          this.middle = this.rootRect.height / 2 - this.childRect.height / 2;
        } else {
          this.middle = this.rootRect.height * 0.05;
        }

        this.animTop.setValue(this.middle);
      }
    }, 100);
  };
}

export default FullScreenContainer;
