import classNames from 'classnames';
import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import React from 'react';
import { PopupVariantProps } from '../Popup.types';
import { CloseIcon } from '../close-icon';
import { PopupTitle } from '../popup-title';

export const RoundedTopPopup: React.FC<PopupVariantProps> = ({
  variant,
  title,
  icon,
  children,
  buttonText,
  secondaryButtonText,
  smallRoundedTop = false,
  onClose,
}) => {
  const renderIcon =
    typeof icon === 'string' ? (
      <img
        src={icon}
        alt={title}
      />
    ) : (
      icon
    );

  return (
    <PageContainer center>
      <div
        className='relative flex flex-col p-4 bg-white rounded-lg w-9/10 text-greyishBrown'
        data-testid='e2e-popup'
      >
        <div
          className={classNames('absolute z-0 self-center justify-center bg-white rounded-full -top-8', {
            'w-60 h-60': smallRoundedTop,
            'w-80 h-80': !smallRoundedTop,
          })}
        />

        {icon && (
          <div className='absolute block transform -translate-x-1/2 pointer-events-none left-1/2 -translate-y-3/4 top-6'>
            {renderIcon}
          </div>
        )}

        <CloseIcon onClose={onClose('close')} />

        {title && <PopupTitle variant={variant}>{title}</PopupTitle>}

        <div className='z-10 flex flex-col items-center justify-center overflow-x-hidden overflow-y-auto h-9/10'>
          {children}
        </div>

        {buttonText && (
          <div className='flex justify-center pt-4'>
            <Button
              variant='outlined'
              text={buttonText}
              onClick={onClose('button')}
            />
            {secondaryButtonText && (
              <Button
                variant='outlined'
                text={secondaryButtonText}
                onClick={onClose('secondaryButton')}
              />
            )}
          </div>
        )}
      </div>
    </PageContainer>
  );
};
