import classNames from 'classnames';
import React from 'react';
import { SliderItemProps } from './Slider.types';

export const SliderItem: React.FC<SliderItemProps> = ({ className, ...rest }) => (
  <button
    className={classNames('flex flex-shrink-0 flex-grow-0 mr-2', className)}
    {...rest}
  />
);
