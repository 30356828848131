import { RefObject, useEffect, useState } from 'react';
import { doNothing } from 'util/doNothing';

interface Props extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

interface UseIntersectionObserver {
  entry: IntersectionObserverEntry | undefined;
  isVisible: boolean;
}

export const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  { threshold = 1.0, root = null, rootMargin = '0%', freezeOnceVisible = false }: Props,
): UseIntersectionObserver => {
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([interceptedEntry]: IntersectionObserverEntry[]) => setEntry(interceptedEntry);

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) {
      return doNothing;
    }

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, root, rootMargin, frozen, threshold]);

  const isVisible = Boolean(entry?.isIntersecting);

  return {
    entry,
    isVisible,
  };
};
