export enum TabsStyle {
  DEFAULT = 'default',
  UNDERLINE = 'underline',
}

export interface TabsProps {
  indexSelected: number;
  children: React.ReactElement<TabProps>[];
  style?: TabsStyle;
  className?: string;
  onChange: (index: number) => void;
}

export interface TabProps {
  title: string;
  notificationCount?: number;
  children: React.ReactNode;
}

export interface TabHeader {
  title: string;
  isActive: boolean;
  notificationCount?: number;
}

export interface TabsHeaderProps {
  tabs: TabHeader[];
  style?: TabsStyle;
  onClick: (index: number) => () => void;
}
