import CautionSvg from 'assets/images/caution.svg';
import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import theme from 'theme';

export const NoPath: React.FC = () => {
  const history = useHistory();

  const handleRedirectToHome = () => {
    history.push('/');
  };

  const handleInfoIconClick = () => history.push('/help');

  return (
    <PageContainer
      center
      className='h-full w-full bg-black max-w-full'
    >
      <SvgIcon
        name='help-circle'
        data-testid='home-informationIcon'
        className='absolute z-50 cursor-pointer left-4 top-4 lg:invisible'
        size={33}
        color={theme.colors.warmGrey}
        onClick={handleInfoIconClick}
      />
      <span className='sr-only'>
        <FormattedMessage id='comp.help-icon' />
      </span>
      <img
        className='mb-14'
        src={CautionSvg}
        alt='caution sign'
      />

      <div className='text-center mb-10 text-white text-sm/[15px] min-w-[275px] w-1/5'>
        <h3 className='m-0 mb-1 font-bold'>Oops!</h3>
        <p className='m-0'>
          <FormattedMessage id='no-path.title' />
        </p>
      </div>
      <Button
        onClick={handleRedirectToHome}
        className='w-1/4 bg-greyishBrown min-w-[275px] text-white'
      >
        <FormattedMessage id='no-path.button-text' />
      </Button>
    </PageContainer>
  );
};
