import { LemoLimitReachedModalData, LockedLemoModalData } from 'components/ModuleShape/types';
import { InstallInstructionStepsModalData } from 'components/install-instruction-steps-popup';
import { ProfileUpdateFeedbackModalData, ProfileUpdateModalData } from 'containers/settings/Settings.types';
import { WalkthroughModalData, WalkthroughVariantTypes } from 'containers/walkthrough';
import {
  EventSubmitCancelRegistrationModalData,
  EventSubmitConfirmedRegistrationModalData,
  EventSubmitNoSpotsAvailableModalData,
  EventSubmitUserIsAlreadyConfirmedData,
} from 'pages/event';
import { PathLockedModalData, PathUnlockedModalData } from 'pages/home/Home.types';
import { CategoryFilterModalData } from 'pages/home/popups/category-filter/CategoryFilterPopup.types';
import { ExternalContentDisclaimerModal } from 'pages/home/popups/external-content-disclaimer/ExternalContentDisclaimer.types';
import {
  CertificationPassedModalData,
  CertificationShareModalData,
  LevelPassedModalData,
  NoAccessToContentModalData,
  PathEndModalData,
  RatingFeedbackModalData,
  RatingModalData,
  RetentionTestData,
  TipModalData,
} from 'pages/lepa';
import {
  PersonalityTestFeedbackModalData,
  PersonalityTestStartModalData,
} from 'pages/personality-test/PersonalityTest.types';
import { RewardsModalData } from 'pages/skill/modals/rewards-modal/RewardsModal.types';

export enum ModalTypes {
  MODULE_STAR_REWARD = 'module-star-reward',
  BADGE_UNLOCKED = 'badge-unlocked',
  PATH_END = 'path-end',
  RETENTION_QUIZ = 'retention-quiz',
  LEVEL_PASSED = 'level-passed',
  LEVEL_FAILED = 'level-failed',
  SKILL_PASSED = 'skill-passed',
  SKILL_FAILED = 'skill-failed',
  CERTIFICATION_PASSED = 'certification-passed',
  CERTIFICATION_SHARE = 'certification-share',
  WALKTHROUGH = 'walkthrough',
  UNLOCKED_FINAL_TEST = 'unlocked-final-test',
  TIP = 'tip',
  PERSONALITY_TEST_START = 'personality-test-start',
  PERSONALITY_TEST_FEEDBACK = 'personality-test-feedback',
  EXTERNAL_CONTENT_DISCLAIMER = 'external-content-disclaimer',
  LEMO_LOCKED = 'locked',
  LEMO_LIMIT_REACHED = 'limitReach',
  EMPTY = 'empty',
  EVENT_SUBMIT_CONFIRMED_REGISTRATION = 'event-submit-confirmed-registration',
  EVENT_SUBMIT_NO_SPOTS_AVAILABLE = 'event-submit-no-spots-available',
  EVENT_SUBMIT_CANCEL_REGISTRATION = 'event-submit-cancel-registration',
  EVENT_SUBMIT_USER_IS_ALREADY_CONFIRMED = 'event-submit-user-is-already-confirmed',
  PATH_UNLOCKED = 'path-unlocked',
  PATH_LOCKED = 'path-locked',
  LEPA_RATING = 'lepa-rating',
  RATING_FEEDBACK = 'rating-feedback',
  NO_ACCESS_TO_CONTENT = 'no-access-to-content',
  PROFILE_UPDATE = 'profile-update',
  PROFILE_UPDATE_FEEDBACK = 'profile-update-feedback',
  CATEGORY_FILTER = 'category-filter',
  INSTALL_INSTRUCTION_STEPS = 'install-instruction-steps',
}

export interface EmptyModalData {
  type: ModalTypes.EMPTY;
}

export type ModalData =
  | PathEndModalData
  | LevelPassedModalData
  | CertificationPassedModalData
  | CertificationShareModalData
  | RewardsModalData
  | RetentionTestData
  | WalkthroughModalData
  | TipModalData
  | PersonalityTestStartModalData
  | PersonalityTestFeedbackModalData
  | ExternalContentDisclaimerModal
  | LockedLemoModalData
  | LemoLimitReachedModalData
  | EmptyModalData
  | EventSubmitConfirmedRegistrationModalData
  | EventSubmitNoSpotsAvailableModalData
  | EventSubmitCancelRegistrationModalData
  | EventSubmitUserIsAlreadyConfirmedData
  | PathUnlockedModalData
  | PathLockedModalData
  | RatingModalData
  | RatingFeedbackModalData
  | ProfileUpdateModalData
  | ProfileUpdateFeedbackModalData
  | NoAccessToContentModalData
  | CategoryFilterModalData
  | InstallInstructionStepsModalData;

export interface EnqueueModalOptions {
  onClose: () => void;
  onContinueClick: () => void;
}

export interface ModalsStore {
  previousModal: ModalData;
  queue: ModalData[];
  currentModal: ModalData | null;
}

export interface DefaultModalProps {
  onModalClose: () => void;
  open: boolean;
}

export interface InjectedModalProps {
  isEmpty: () => boolean;
  enqueueModal: (modal: ModalData) => void;
  resetQueue: () => void;
  has: (type: ModalData['type'], walkThroughVariant?: WalkthroughVariantTypes) => boolean;
}

export interface ModalRendererProps {
  modal: ModalData;
  open: boolean;
  onModalClose: () => void;
}

export type EnqueueModalPayload = ModalData;
