import { GetWalkthroughsResponse, useWalkthrough } from 'containers/walkthrough';
import { ComponentType, FC } from 'react';

export interface WithCompletedWalkthroughs {
  completedWalkthroughs: GetWalkthroughsResponse;
}

export function withCompletedWalkthroughs<P = Record<string, unknown>>(Component: ComponentType<P>) {
  const Wrapper: FC<Omit<P, keyof WithCompletedWalkthroughs>> = (props) => {
    const { completedWalkthroughs } = useWalkthrough();

    return (
      // @ts-ignore We are sure about the P type
      <Component
        {...props}
        completedWalkthroughs={completedWalkthroughs}
      />
    );
  };

  return Wrapper;
}
