import * as React from 'react';
import { isDesktop } from 'util/platforms';
import { ScreenLockProps } from './types';

class ScreenLock extends React.Component<ScreenLockProps> {
  state = {
    locked: false,
  };

  desktop = isDesktop();

  componentDidMount() {
    if (!this.desktop) {
      this.lockScreen();
    }
  }

  componentDidUpdate() {
    if (!this.state.locked && !this.desktop) {
      this.lockScreen();
    }
  }

  render = () => this.props.children;

  private lockScreen = async () => {
    const elem = document.documentElement;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const fullscreen =
      elem.requestFullscreen ||
      // @ts-ignore: HTML elements
      elem.mozRequestFullScreen ||
      // @ts-ignore: HTML elements
      elem.webkitRequestFullScreen ||
      // @ts-ignore: HTML elements
      elem.msRequestFullscreen;
    const { orientation = 'portrait-primary' } = this.props;
    try {
      await fullscreen.call(elem);
      await window.screen.orientation.lock(orientation);
      this.setState({ locked: true });
    } catch (err) {
      // do nothing
    }
  };
}

export default ScreenLock;
