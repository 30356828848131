import { FormatContentOption } from 'containers/FormatScreen/types';
import React from 'react';
import { SpringStyles } from 'util/types';

export enum SwipeColumns {
  LEFT = 'left',
  RIGHT = 'right',
}

export type ColumnPropData = {
  title: string;
  imgSrc: string;
  style?: React.CSSProperties;
};

export interface SwipeFormatProps {
  leftGroupOptions: FormatContentOption[];
  rightGroupOptions: FormatContentOption[];
  leftColProps: ColumnPropData;
  rightColProps: ColumnPropData;
  initialOptions: FormatContentOption[];
  question: string;
  showAnswers: boolean;
  correctAnswers: string[];
  image?: string;
  video?: string;
  brandColor?: string;
  onUpdateGroups: (leftGroup: FormatContentOption[], rightGroup: FormatContentOption[]) => void;
}

export interface SwipeableCardProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'style'> {
  showFeedback?: boolean;
  isCorrect?: boolean;
  data: FormatContentOption;
  style?: SpringStyles;
  onSwipeStart?: React.EventHandler<React.MouseEvent | React.TouchEvent>;
}

export interface SwipeColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  columnData: ColumnPropData;
  options?: FormatContentOption[];
  showAnswers?: boolean;
  brandColor?: SwipeFormatProps['brandColor'];
  onValidateOption?: (optionID: string) => boolean;
  onOptionSwitchColumn?: (optionID: string) => void;
}

export interface HeaderFeedbackMessageProps {
  showAnswers: boolean;
  message: JSX.Element;
}

export interface SwipeColumn {
  switch: (optionID: string) => void;
  push: () => void;
}
