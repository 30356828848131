import classNames from 'classnames';
import { PageContainer } from 'components/page-container';
import React from 'react';
import { PopupVariantProps } from '../Popup.types';
import { CloseIcon } from '../close-icon';

export const FullscreenPopup: React.FC<PopupVariantProps> = ({
  className,
  pageContainerClassName = '',
  children,
  closeIcon,
  closeIconSize,
  closeIconColor,
  disablePadding = false,
  maxWidth = true,
  onClose,
}) => {
  const handleClickPageContainer = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (!closeIcon) {
      return;
    }

    onClose('modal')(event);
  };

  return (
    <div
      className={classNames(
        className,
        'relative flex flex-col w-screen h-full bg-white text-greyishBrown text-center',
        {
          'p-4': !disablePadding,
        },
      )}
      data-testid='e2e-popup'
    >
      <PageContainer
        className={pageContainerClassName}
        onClick={handleClickPageContainer}
        maxWidth={maxWidth}
      >
        {closeIcon && (
          <CloseIcon
            onClose={onClose('close')}
            size={closeIconSize}
            color={closeIconColor}
          />
        )}
        {children}
      </PageContainer>
    </div>
  );
};
