import { rootRestApi } from 'store/root.api';
import { Channel } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import {
  AddModeratorPayload,
  CreateChannelPayload,
  GetChatTokenPayload,
  GetChatTokenResponse,
  UpdateChannelPayload,
  UpdateMembersPayload,
} from './Chat.types';

export const chatApi = rootRestApi.injectEndpoints({
  endpoints: (builder) => ({
    getChatToken: builder.mutation<GetChatTokenResponse, GetChatTokenPayload>({
      query: ({ accountId, ...body }) => ({
        url: `/accounts/${accountId}/chat/token`,
        method: 'POST',
        body,
      }),
    }),
    createChannel: builder.mutation<Channel<DefaultStreamChatGenerics>, CreateChannelPayload>({
      query: ({ accountId, ...body }) => ({
        url: `/accounts/${accountId}/channels`,
        method: 'POST',
        body,
      }),
    }),
    updateChannel: builder.mutation<Channel<DefaultStreamChatGenerics>, UpdateChannelPayload>({
      query: ({ accountId, channelId, ...body }) => ({
        url: `/accounts/${accountId}/channels/${channelId}`,
        method: 'PATCH',
        body,
      }),
    }),
    addMembers: builder.mutation<void, UpdateMembersPayload>({
      query: ({ accountId, channelId, ...body }) => ({
        url: `/accounts/${accountId}/channels/${channelId}/add-members`,
        method: 'POST',
        body,
      }),
    }),
    removeMembers: builder.mutation<void, UpdateMembersPayload>({
      query: ({ accountId, channelId, ...body }) => ({
        url: `/accounts/${accountId}/channels/${channelId}/remove-members`,
        method: 'POST',
        body,
      }),
    }),
    addModerator: builder.mutation<void, AddModeratorPayload>({
      query: ({ accountId, channelId, ...body }) => ({
        url: `/accounts/${accountId}/channels/${channelId}/add-moderators`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetChatTokenMutation,
  useCreateChannelMutation,
  useUpdateChannelMutation,
  useAddMembersMutation,
  useRemoveMembersMutation,
  useAddModeratorMutation,
} = chatApi;
