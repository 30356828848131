import warningImg from 'assets/images/warning.svg';
import { Popup } from 'components/popup';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EventPopupsComponentProps as Props } from '../Event.types';

export const NoSpotsAvailablePopup: React.FC<Props> = ({ onModalClose, open }) => {
  const intl = useIntl();

  return (
    <Popup
      icon={warningImg}
      variant='faded'
      open={open}
      title={intl.formatMessage({ id: 'event.no-spots-available-popup.title' })}
      onRequestClose={onModalClose}
      closeIcon
    >
      <div className='p-4 text-sm font-light text-center whitespace-pre-wrap'>
        <h2>
          <FormattedMessage id='event.no-spots-available-popup.subtitle' />
        </h2>
      </div>
    </Popup>
  );
};
