import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Store } from 'store/types';
import Register from './Register';
import { actions } from './Register.ducks';
import { RegisterUser } from './Register.types';

const mapStateToProps = ({ register }: Store) => ({ register });

const mapDispatchToProps = (dispatch: Dispatch) => ({
  validateInvite: (inviteToken: string | null) => dispatch(actions.validateInvite({ inviteToken })),
  createUser: (user: RegisterUser) => dispatch(actions.createUser({ user })),
});

export * from './Register.ducks';
export * from './Register.types';
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Register));
