import { Locale } from 'containers/language-provider';
import { DefaultModalProps, ModalTypes } from 'containers/modal-controller/ModalController.types';
import { Props as JoyrideProps, TooltipRenderProps } from 'react-joyride';

// Any variant must follow the nomenclature: [view]-[?subView]-[walktroughNumber]
export const walkthroughVariants = [
  'home',
  'path',
  'swipe',
  'hotspot',
  'level-quiz',
  'reorder',
  'skill-shape',
  'skill-test',
  'search',
  'profile',
  'newsfeed',
  'module-complete',
  'crash-course',
  'crash-course-test',
  'multiple-answer-swipe',
  'multiple-choice-drag-drop',
  'install-app',
] as const;

export type WalkthroughVariantTypes = typeof walkthroughVariants[number];

export type HomeExternalVars = {
  showCategories?: boolean;
};

export interface WalkthroughProps extends Omit<JoyrideProps, 'steps'> {
  variant: WalkthroughVariantTypes;
  lang: Locale;
  targets: JoyrideProps['steps'][0]['target'][];
  open: boolean;
  externalConditions?: HomeExternalVars;

  onSkip?: () => Promise<void>;
  onEnd?: () => Promise<void>;
}

export interface JoyrideWrapperProps extends JoyrideProps {
  open: boolean;
  /** show skip button */
  withSkip?: boolean;

  onSkip?: WalkthroughProps['onSkip'];
  onEnd?: WalkthroughProps['onEnd'];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface WalkthroughVariantProps extends Omit<WalkthroughProps, 'variant'> {}

export interface TooltipProps extends TooltipRenderProps {
  onSkip: WalkthroughProps['onSkip'];
  onEnd: WalkthroughProps['onEnd'];
  withSkip?: boolean;
}

export type WalkthroughModalData = {
  type: ModalTypes.WALKTHROUGH;
  modal: Omit<WalkthroughProps, 'open'>;
};

export interface WalkthroughModalProps extends DefaultModalProps, WalkthroughModalData {}

export interface GetWalkthroughsRequestPayload {
  userId: string;
}

export interface GetWalkthroughsResponse {
  [key: string]: {
    showAfter: string | null;
    xpEarned: number;
  };
}

export interface CompleteWalkthroughRequestPayload {
  userId: string;
  body: {
    slug: WalkthroughVariantTypes;
    showAfter?: string;
  };
}

export interface UseWalkthrough {
  onWalkthroughEnd: (slug: WalkthroughVariantTypes, showAfter?: Date) => Promise<void>;
  completedWalkthroughs: GetWalkthroughsResponse;
  locale: Locale;
}
