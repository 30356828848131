import { Action, CaseReducer, createSlice } from '@reduxjs/toolkit';
import { actions as authActions } from 'containers/authentication/Authentication.ducks';
import { actions as eventActions } from 'pages/event';
import { actions as homeActions } from 'pages/home';
import { Pending, PendingRequestsStore } from './pending-requests.types';

const pendings: Pending[] = [
  {
    trigger: homeActions.getFeaturedPath.type,
    success: homeActions.getFeaturedPathSuccess.type,
    failure: homeActions.getFeaturedPathError.type,
  },
  {
    trigger: authActions.getUserInfo.type,
    success: authActions.getUserInfoSuccess.type,
    failure: authActions.getUserInfoError.type,
  },
  {
    trigger: homeActions.getUserLecos.type,
    success: homeActions.getUserLecosSuccess.type,
    failure: homeActions.getUserLecosError.type,
  },
  {
    trigger: eventActions.getEvent.type,
    success: eventActions.getEventSuccess.type,
    failure: eventActions.getEventError.type,
  },
];

const initialState: PendingRequestsStore = {};

const setPending =
  <T extends string>(actionType: T, value: boolean): CaseReducer<PendingRequestsStore, Action<T>> =>
  (state) => ({
    ...state,
    [actionType]: value,
  });

const slice = createSlice({
  name: 'pending-requests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    pendings.forEach((pending) => {
      const { failure, success, trigger } = pending;
      builder
        .addCase(trigger, setPending(trigger, true))
        .addCase(success, setPending(trigger, false))
        .addCase(failure, setPending(trigger, false));
    });
  },
});

export default slice.reducer;
