import { USER_ROLES } from 'constants/global';
import { ProfileStore } from 'containers/Profile/types';
import { AuthStore, User, getRoleAccessLevel } from 'containers/authentication';
import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { PersonalityTestStatus, actions as personalityTestActions } from 'pages/personality-test';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { Store } from 'store/types';

const usePersonalityTest = () => {
  const { enqueueModal, has: hasModal } = useModalQueue();
  const personalityTestStatus = useSelector<Store, PersonalityTestStatus>(
    ({ personalityTest }) => personalityTest.status,
  );
  const { id: accountID } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);
  const isPreviewUser = useSelector<Store, boolean>(
    ({ profile }) => getRoleAccessLevel(profile.user.role) === USER_ROLES.PREVIEW,
  );
  const { persona, isLocaleSelected } = useSelector<Store, User>(({ profile }) => profile.user);
  const isAuthorizing = useSelector<Store, AuthStore['isAuthorizing']>(({ auth }) => auth.isAuthorizing);
  const dispatch = useDispatch();
  const location = useLocation();
  const isPersonalityTestEnabled = useFeatureFlag(FlagKeys.PERSONALITY_TEST);

  useEffect(() => {
    const isValidPersonalityTest = !isPreviewUser;
    if (!isValidPersonalityTest) {
      return;
    }
    if (!isPersonalityTestEnabled) {
      return;
    }
    if (isAuthorizing || persona || !isLocaleSelected) {
      return;
    }

    const isRoutePersonalityTest = location.pathname.includes('personality/test');
    if (isRoutePersonalityTest) {
      return;
    }

    const hasUserNavigatedOut =
      personalityTestStatus === PersonalityTestStatus.DOING &&
      !hasModal(ModalTypes.PERSONALITY_TEST_START) &&
      !isRoutePersonalityTest;

    const isOutOfPersonalityTest = hasUserNavigatedOut || personalityTestStatus === PersonalityTestStatus._CANSTART;

    const isDoingPersonalityTest = isRoutePersonalityTest || !isOutOfPersonalityTest;
    if (isDoingPersonalityTest) {
      return;
    }

    dispatch(personalityTestActions.setStatus(PersonalityTestStatus.TODO));
  }, [
    personalityTestStatus,
    isPreviewUser,
    dispatch,
    location.pathname,
    isAuthorizing,
    persona,
    hasModal,
    accountID,
    isLocaleSelected,
    isPersonalityTestEnabled,
  ]);

  useEffect(() => {
    const shouldStartPersonalityTest =
      !hasModal(ModalTypes.PERSONALITY_TEST_START) && personalityTestStatus === PersonalityTestStatus.TODO;

    if (shouldStartPersonalityTest) {
      enqueueModal({ type: ModalTypes.PERSONALITY_TEST_START });
      dispatch(personalityTestActions.setStatus(PersonalityTestStatus.DOING));
    }
  }, [dispatch, enqueueModal, hasModal, personalityTestStatus]);

  useEffect(() => {
    const shouldEndPersonalityTest =
      !hasModal(ModalTypes.PERSONALITY_TEST_FEEDBACK) && personalityTestStatus === PersonalityTestStatus.DONE;

    if (shouldEndPersonalityTest) {
      enqueueModal({ type: ModalTypes.PERSONALITY_TEST_FEEDBACK });
      dispatch(personalityTestActions.setStatus(PersonalityTestStatus.CLOSED));
    }
  }, [dispatch, enqueueModal, hasModal, personalityTestStatus]);
};

export default usePersonalityTest;
