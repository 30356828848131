import calendarImg from 'assets/images/calendar.svg';
import { Popup } from 'components/popup';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { CalendarEvent, EventStore, EventPopupsComponentProps as Props } from '../Event.types';
import { Calendar } from '../calendar/Calendar';

export const ConfirmedRegistrationPopup: React.FC<Props> = ({ onModalClose, open }) => {
  const intl = useIntl();
  const { event } = useSelector<Store, EventStore>((state) => state.activeEvent);

  if (!event) {
    return null;
  }

  const bookedSession = event.content.sessions.find((session) => Boolean(session.userRegistration?.id));

  if (!bookedSession?.userRegistration?.id) {
    return null;
  }

  const bookedDate = intl.formatDate(bookedSession.startAt, { month: 'long', day: 'numeric' });

  const calendarEvent: CalendarEvent = {
    ...bookedSession,
    description: event.description,
    title: bookedSession.title,
  };

  return (
    <Popup
      icon={calendarImg}
      variant='faded'
      open={open}
      title={intl.formatMessage({ id: 'event.confirmed-registration-popup.title' })}
      onRequestClose={onModalClose}
      closeIcon
    >
      <div className='text-sm font-light text-center whitespace-pre-wrap'>
        <h2>
          <FormattedMessage
            id='event.confirmed-registration-popup.subtitle'
            values={{ bookedDate }}
          />
        </h2>
        <p className='pt-4'>
          <FormattedMessage id='event.confirmed-registration-popup.message' />
        </p>
      </div>
      <div className='flex flex-row items-center justify-center w-full pt-4 pb-3'>
        <Calendar event={calendarEvent} />
      </div>
    </Popup>
  );
};
