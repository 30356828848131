import { useCallback } from 'react';

import { WalkthroughModalData, WalkthroughVariantTypes } from 'containers/walkthrough';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'store/types';
import { actions } from './ModalController.ducks';
import { InjectedModalProps, ModalData, ModalTypes } from './ModalController.types';

const isWalkthroughModalData = (modalData: ModalData): modalData is WalkthroughModalData =>
  modalData.type === ModalTypes.WALKTHROUGH && Boolean(modalData.modal?.variant);

export const useModalQueue = (): InjectedModalProps => {
  const dispatch = useDispatch();

  const state = useSelector(({ modals }: Store) => modals);
  const { queue, currentModal, previousModal } = state;

  const enqueueModal = useCallback(
    (modalData: ModalData) => {
      dispatch(actions.enqueueModal(modalData));
    },
    [dispatch],
  );

  const resetQueue = useCallback(() => {
    dispatch(actions.resetQueue());
  }, [dispatch]);

  const isEmpty = useCallback(() => queue.length === 0, [queue.length]);

  const has = useCallback(
    (type: ModalData['type'], walkThroughVariant?: WalkthroughVariantTypes) => {
      const isSameType = (modalData: ModalData) => modalData.type === type;
      const isSameVariant = (modalData: ModalData) =>
        walkThroughVariant && isWalkthroughModalData(modalData)
          ? walkThroughVariant === modalData.modal?.variant
          : true;
      const isSameTypeAndVariant = (modalData: ModalData) => isSameType(modalData) && isSameVariant(modalData);

      if (currentModal && isSameTypeAndVariant(currentModal)) {
        return true;
      }

      if (queue.some((modalData) => isSameTypeAndVariant(modalData))) {
        return true;
      }

      return isSameTypeAndVariant(previousModal);
    },
    [currentModal, queue, previousModal],
  );

  return {
    enqueueModal,
    resetQueue,
    isEmpty,
    has,
  };
};
