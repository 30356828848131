import { PREVIOUS_LOCATION_COOKIE, RoleSlug, USER_ROLES } from 'constants/global';
import { NavigationType } from 'containers/BottomNavigation/types';
import cookie from 'js-cookie';
import { LepaTypes } from 'pages/home/Home.types';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'store/types';
import { preparePreviousLocation } from 'util/preparePreviousLocation';
import { AuthFeatures, AuthRedirectMessageTypes, AuthStore } from './Authentication.types';

export const unallowedPreviousLocations = ['/', '/login', '/self-registration-link'];

const isKnownRole = (roleSlug: RoleSlug) => {
  const existingRoles = Object.keys(USER_ROLES) as Array<RoleSlug>;

  return existingRoles.includes(roleSlug);
};

export function getRoleAccessLevel(role: string): number {
  const roleSlug = role.toLocaleUpperCase() as RoleSlug;
  const hasKnownRole = isKnownRole(roleSlug);

  if (!hasKnownRole) {
    return -1;
  }

  return USER_ROLES[roleSlug];
}

export function hasRequiredFeatures(requires: NavigationType['required'], features: AuthStore['features']) {
  if (!requires || requires.length === 0 || !features) {
    return true;
  }

  for (const requirement of requires) {
    // If has the same requirement but it's value is false
    if (requirement in features && features[requirement] === false) {
      return false;
    }
  }
  return true;
}

export function withAuthentication<T>(
  Component: React.ComponentType<T>,
  roleRequired: number = USER_ROLES.USER,
  requiredFeatures?: AuthFeatures,
) {
  return (props: T) => {
    const history = useHistory();
    const sso = useSelector<Store, boolean>(({ auth }) => auth.sso);
    const features = useSelector<Store, AuthStore['features']>(({ auth }) => auth.features);
    const isAuthorizing = useSelector<Store, boolean>(({ auth }) => auth.isAuthorizing);
    const userLevel = useSelector<Store, number>(({ profile }) => getRoleAccessLevel(profile.user.role));
    const [isAuthorized, setIsAuthorized] = useState(false);
    const { activePathId, activePathType } = useSelector(({ auth }: Store) => auth.preview);

    const checkAuth = useCallback(
      (currentUserLevel: number, currentIsAuthorizing: boolean): void => {
        let hasFeatureAccess = true;

        // Feature access validation
        if (requiredFeatures?.length) {
          const hasAccess = hasRequiredFeatures(requiredFeatures, features);
          if (!hasAccess && !currentIsAuthorizing) {
            hasFeatureAccess = false;
          }
        }

        // Role validation
        if (currentUserLevel >= roleRequired) {
          setIsAuthorized(hasFeatureAccess);
          return;
        }
        if (!currentIsAuthorizing) {
          if (cookie.get('a_t')) {
            return;
          }

          if (sso) {
            history.replace(`/ssomessage?messageType=${AuthRedirectMessageTypes.UNAUTHORIZED}`);
            return;
          }
          if (currentUserLevel === USER_ROLES.PREVIEW && activePathId && activePathType) {
            const redirectPath = {
              [LepaTypes.CRASH_COURSE]: `/crash-course/${activePathId}`,
              [LepaTypes.LEGACY]: `/learning-path/${activePathId}`,
              [LepaTypes.VIDEO]: `/video/${activePathId}`,
              [LepaTypes.TEXT]: `/learning-moment/${activePathId}`,
              [LepaTypes.QUESTIONS_ONLY]: `/learning-moment/${activePathId}`,
              [LepaTypes.SINGLE_LEARNING]: `/learning-moment/${activePathId}`,
            }[activePathType];

            history.replace(redirectPath ?? '/invalid-url');
            return;
          }

          if (currentUserLevel < 0) {
            const currentURL = preparePreviousLocation(history.location);
            const isAllowedPreviousLocation = !unallowedPreviousLocations.includes(currentURL);

            if (isAllowedPreviousLocation) {
              cookie.set(PREVIOUS_LOCATION_COOKIE, currentURL);
            }
          }

          history.replace('/login');
        }
      },
      [activePathId, activePathType, features, history, sso],
    );

    useEffect(() => {
      checkAuth(userLevel, isAuthorizing);
    }, [checkAuth, isAuthorizing, userLevel]);

    if (!isAuthorized) {
      return <div />;
    }
    return <Component {...props} />;
  };
}
