import { useEffect } from 'react';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import {
  Filters,
  TypeFilters,
  getFiltersFromStorage,
  getFiltersQueryParams,
  saveFiltersOnStorage,
  useFiltersQueryParams,
} from 'util/useFiltersQueryParams';

interface HomeFilters {
  count: number;
  queryParams: string;
}

const filterTypesRequestMap: Record<TypeFilters, string[]> = {
  [TypeFilters.LEARNING_PATHS]: ['learning-path'],
  [TypeFilters.CRASH_COURSES]: ['crash-course'],
  [TypeFilters.EXTERNAL_CONTENT]: ['link', 'scorm', 'lti'],
  [TypeFilters.EVENTS]: ['event'],
  [TypeFilters.SINGLE_LEARNING]: ['single-learning'],
  [TypeFilters.INFORMATIONAL_STEPS]: ['text'],
  [TypeFilters.SINGLE_VIDEO]: ['video'],
  [TypeFilters.QUESTIONNAIRE]: ['questions-only'],
};

const getRequestQueryParams = ({ categories, isCertified, types }: Filters): string => {
  const params = new URLSearchParams();

  categories.forEach((category) => params.append('categories', String(category)));
  if (isCertified) {
    params.append('certified', 'true');
  }
  types.forEach((type) => filterTypesRequestMap[type].forEach((requestType) => params.append('types', requestType)));

  const paramsString = params.toString();
  return paramsString ? `?${paramsString}` : '';
};

export const useHomeFilters = (): HomeFilters => {
  const { count, categories, isCertified, types } = useFiltersQueryParams();
  const areHomeFiltersEnabled = useFeatureFlag(FlagKeys.SKILLFLIX_FILTERS_CATEGORIES_TYPES);
  const queryParams = areHomeFiltersEnabled ? getRequestQueryParams({ categories, isCertified, types }) : '';

  useEffect(() => {
    if (areHomeFiltersEnabled && count && !getFiltersFromStorage()) {
      saveFiltersOnStorage(
        `?${getFiltersQueryParams({
          categories,
          isCertified,
          types,
        })}`,
      );
    }
  }, [areHomeFiltersEnabled, categories, count, isCertified, types]);

  return {
    count,
    queryParams,
  };
};
