import { AnswerTypes, FormatTypeInformationMap } from './Loformats.types';

/* eslint-disable camelcase */
export const formatTypeInformationMap: FormatTypeInformationMap = {
  text: {
    answerType: AnswerTypes.NO_ANSWER,
    answerPropertyName: '',
    qaTimer: 3000,
  },
  video: {
    answerType: AnswerTypes.NO_ANSWER,
    answerPropertyName: '',
    qaTimer: 3000,
  },
  drag_drop: {
    answerType: AnswerTypes.GROUPS,
    answerPropertyName: 'dragDrop',
    qaTimer: 5000,
  },
  yes_no: {
    answerType: AnswerTypes.SINGLE,
    answerPropertyName: 'yesNo',
    qaTimer: 3000,
  },
  ranking_quiz: {
    answerType: AnswerTypes.MULTIPLE,
    answerPropertyName: 'rankingQuiz',
    qaTimer: 3000,
  },
  multiple_choice: {
    answerType: AnswerTypes.SINGLE,
    answerPropertyName: 'multipleChoice',
    qaTimer: 3000,
  },
  multiple_choice_drag_drop: {
    answerType: AnswerTypes.SINGLE,
    answerPropertyName: 'multipleChoice',
    qaTimer: 3000,
  },
  multiple_answer: {
    answerType: AnswerTypes.MULTIPLE,
    answerPropertyName: 'multipleAnswer',
    qaTimer: 3000,
  },
  multiple_answer_swipe: {
    answerType: AnswerTypes.MULTIPLE,
    answerPropertyName: 'multipleAnswer',
    qaTimer: 3000,
  },
  multiple_image_choice: {
    answerType: AnswerTypes.SINGLE,
    answerPropertyName: 'multipleImageChoice',
    qaTimer: 3000,
  },
  multiple_image_answer: {
    answerType: AnswerTypes.MULTIPLE,
    answerPropertyName: 'multipleImageAnswer',
    qaTimer: 3000,
  },
  true_false: {
    answerType: AnswerTypes.SINGLE,
    answerPropertyName: 'trueFalse',
    qaTimer: 3000,
  },
  hotspot: {
    answerType: AnswerTypes.MULTIPLE,
    answerPropertyName: 'hotspot',
    qaTimer: 3000,
  },
};
/* eslint-enable camelcase */
