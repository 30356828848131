import React, { useEffect, useRef } from 'react';
import { MessageData } from '../../MobieChatPopup.types';
import { ChatMessage } from '../chat-message';
import { ChatMessageProps } from '../chat-message/ChatMessage.types';
import { LoaderDots } from '../loader-dots';
import { ChatProps as Props } from './Chat.types';

export const Chat: React.FC<Props> = ({ messages, isLoading, accountPathColor }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }

    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [messages, scrollRef]);

  const shouldDisplayTags = (chatMessages: MessageData[], currentIndex: number) => {
    const CONSECUTIVE_NUMBER_OF_MESSAGES_WITH_TAGS = 4;

    const currentMessages = chatMessages.slice(0, currentIndex);
    const numberOfMessagesWithTags = currentMessages.filter(({ tags }) => tags && tags.length > 0).length;

    const shouldDisplayTag = numberOfMessagesWithTags % CONSECUTIVE_NUMBER_OF_MESSAGES_WITH_TAGS === 0;

    return shouldDisplayTag;
  };

  const reducedMessages = messages.reduce<ChatMessageProps[]>(
    (accumulator, currentMessage, currentIndex, chatMessages) => {
      const { message, role, notFoundMessage, tags } = currentMessage;

      const shouldDisplayTag = shouldDisplayTags(chatMessages, currentIndex);

      const shouldAddMessageWithTag = notFoundMessage && shouldDisplayTag;

      accumulator.push({
        message,
        role,
      });

      if (shouldAddMessageWithTag) {
        accumulator.push({
          message: notFoundMessage,
          role,
          tags,
        });
      }

      return accumulator;
    },
    [],
  );

  return (
    <div
      className='flex flex-col mt-10 mb-2 overflow-y-auto'
      ref={scrollRef}
    >
      {reducedMessages.map(({ message, role, tags }, index) => (
        <ChatMessage
          key={index}
          message={message}
          role={role}
          tags={tags}
        />
      ))}
      {isLoading && <LoaderDots backgroundColor={accountPathColor} />}
    </div>
  );
};
