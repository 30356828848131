import React from 'react';
import { useSelector } from 'react-redux';
import { useTrackEventMutation } from '../../../containers/Analytics/Analytics.api';
import { EventTypes } from '../../../containers/Analytics/internal/InternalAnalytics.types';
import { RootState } from '../../../store';
import { InfoCardButtonProps } from './InfoCardButton.types';

const redirectToExternalDomain = (url: string) => () => {
  const URL = url.search('http') < 0 ? `https://${url}` : url;
  window.location.href = URL;
};

export const InfoCardButton: React.FC<InfoCardButtonProps> = ({ src, url, children, onClick }) => {
  const {
    account: { id: accountId },
    user: { role },
  } = useSelector(({ profile }: RootState) => profile);
  const [trackEvent] = useTrackEventMutation();

  const handleClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      return onClick(event);
    }

    await trackEvent({
      eventType: EventTypes.NAVIGATE_EXTERNAL,
      accountId,
      properties: {
        url,
        role,
      },
    });

    const redirectTo = redirectToExternalDomain(url);
    return redirectTo();
  };

  return (
    <div
      className='relative flex items-center py-4 pl-2 pr-1 mb-4 text-sm border border-solid rounded-lg cursor-pointer border-whiteFour text-warmGrey'
      onClick={handleClick}
    >
      <div className='relative flex items-center content-center mr-3 w-14 h-14'>
        <img
          src={src}
          className='block h-auto w-8/10'
          alt=''
        />
      </div>
      {children}
    </div>
  );
};
