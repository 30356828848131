/**
 * Global constants
 */

/**
 * Available User roles for APP users and their access levels
 */
export const USER_ROLES = Object.freeze({
  PREVIEW: 0,
  USER: 1,
  MANAGER: 1,
  TEST: 2,
  ADMIN: 3,
  MULTIADMIN: 3,
  COACH: 1,
});

export type RoleSlug = keyof typeof USER_ROLES;

export const TEST_USER = process.env.NODE_ENV === 'test';

/** Basic auth credentials for OAuth2 login */
export const OAuth2Credentials = {
  id: process.env.REACT_APP_LOGIN_BASIC_AUTH_ID,
  secret: process.env.REACT_APP_LOGIN_BASIC_AUTH_PASSWORD,
  // eslint-disable-next-line camelcase
  redirect_uri: 'http://localhost:3000/handle-code',
};

function getMatomoCredentials() {
  const { REACT_APP_MATOMO_DOMAIN, REACT_APP_MATOMO_SITE_ID } = process.env;

  if (!REACT_APP_MATOMO_DOMAIN) {
    throw new Error('enviroment variable `REACT_APP_MATOMO_DOMAIN` was not properly set.');
  }
  if (!REACT_APP_MATOMO_SITE_ID) {
    throw new Error('enviroment variable `REACT_APP_MATOMO_SITE_ID` was not properly set.');
  }

  return {
    domain: REACT_APP_MATOMO_DOMAIN,
    siteId: Number(REACT_APP_MATOMO_SITE_ID),
  };
}

export const MatomoCredentials = getMatomoCredentials();

/** Recaptcha API KEY */
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA;
/**
 * cookie domain string
 */
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;

export const PREVIOUS_LOCATION_COOKIE = 'previous_location';

export const SKILLFLIX_FILTERS_SESSION_STORAGE_ITEM = 'skillflix_filters';

export const COACHING_APP_GROWTH_PLAN_URL = process.env.REACT_APP_COACHING_APP_GROWTH_PLAN_URL;

/** Allowed languages */
export const localeStrings = {
  'de-DE': 'German',
  'en-GB': 'English',
  'es-ES': 'Spanish',
  'fr-FR': 'French',
  'it-IT': 'Italian',
  'nl-NL': 'Dutch',
  'pl-PL': 'Polish',
  'pt-PT': 'Portuguese',
  'ar-AE': 'Arabic',
  'ur-PK': 'Urdu',
  'sv-SE': 'Swedish',
  'fi-FI': 'Finnish',
  'bg-BG': 'Bulgarian',
  'da-DK': 'Danish',
  'ru-RU': 'Russian',
  'el-GR': 'Greek',
  'zh-Hans-CN': 'Chinese - simplified',
  'sq-AL': 'Albanian',
  'hr-HR': 'Croatian',
  'hu-HU': 'Hungarian',
  'ro-RO': 'Romanian',
  'sl-SI': 'Slovenian',
  'sk-SK': 'Slovak',
  'cs-CZ': 'Czech',
  'tr-TR': 'Turkish',
  'no-NO': 'Norwegian',
  'hi-IN': 'Hindi',
  'ja-JP': 'Japanese',
  'zh-Hant-CN': 'Chinese - traditional',
  'ko-KR': 'Korean',
  'vi-VN': 'Vietnamese',
};

export type Locales = keyof typeof localeStrings;

type ResourceEntity =
  | 'account'
  | 'user'
  | 'learning_path'
  | 'learning_format'
  | 'tips_tricks'
  | 'question_group'
  | 'group_item_image'
  | 'format_response'
  | 'format_response_group'
  | 'post'
  | 'event'
  | 'learning_content';

type ResourceField =
  | 'image_url'
  | 'media'
  | 'splash_screen'
  | 'logo'
  | 'logo_dark'
  | 'header_image'
  | 'footer_image'
  | 'theme'
  | 'avatar'
  | 'avatar_url'
  | 'path_background'
  | 'background_image'
  | 'title'
  | 'media_src'
  | 'image';

export interface UploadImagePayload {
  resourceId: string;
  resourceEntity: ResourceEntity;
  resourceField: ResourceField;
  image: File;
}

export const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const STREAM_API_KEY = process.env.REACT_APP_STREAM_API_KEY ?? '';
