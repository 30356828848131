import { Loading } from 'components/Loading';
import { PageContainer } from 'components/page-container';
import { PREVIOUS_LOCATION_COOKIE } from 'constants/global';
import { LoginProps } from 'containers/authentication';
import cookie from 'js-cookie';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormattedMessage } from 'react-intl';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { checkCookieSupport } from 'util/cookieHelper';
import { useAuthentication } from './useAuthentication';

/** maps i18n error messages to HTTP response codes */
const mapStatusCodeToError: { [key: string]: string } = {
  401: 'container.login.errorMessage',
  409: 'container.login.errorRedirectMessage', // when /register/{token} fails
};

export const Login: React.FC<LoginProps> = ({ customLogin, error, isAuthorizing }) => {
  const history = useHistory();
  const reCaptchaRef: React.RefObject<ReCAPTCHA> = React.createRef();
  const { flow, handleLogin } = useAuthentication({ reCaptchaRef });
  const [hasCookieSupport, setHasCookieSupport] = React.useState(false);

  React.useEffect(() => {
    const hasCookingSupport = checkCookieSupport();
    setHasCookieSupport(hasCookingSupport);
  }, []);

  const { redirectURL } = useParams<{ redirectURL?: string }>();
  React.useEffect(() => {
    if (!redirectURL) {
      return;
    }

    cookie.set(PREVIOUS_LOCATION_COOKIE, redirectURL);
  }, [history, redirectURL]);

  React.useEffect(() => {
    if (!flow) {
      return;
    }
    if (isAuthorizing || error) {
      return;
    }

    if (flow.isAutomaticLogin) {
      handleLogin();
    }
  }, [error, flow, handleLogin, isAuthorizing]);

  const renderErrorMessage = () => {
    const errorMessage = mapStatusCodeToError[error];
    if (!errorMessage) {
      return null;
    }

    return (
      <div className='p-2 pt-6 text-center text-fullRed'>
        <FormattedMessage id={errorMessage} />
      </div>
    );
  };

  if (!flow) {
    return <Loading />;
  }

  const shouldRedirectToApplication = flow.isInternal;
  const isExternalId = Boolean(customLogin?.hasExternalIdProvider);

  if (isAuthorizing || isExternalId) {
    return <Loading />;
  }

  if (shouldRedirectToApplication) {
    return <Redirect to='/' />;
  }

  return (
    <div className='flex w-full h-full'>
      <PageContainer className='flex flex-col self-center w-full p-3 overflow-x-hidden'>
        <div className='flex flex-col items-center justify-around flex-grow w-full'>{renderErrorMessage()}</div>

        {!hasCookieSupport && (
          <div className='p-5 text-center'>
            It seems either your device doesn't have Cookies support enabled. Please enable this feature to use the
            application!
          </div>
        )}
      </PageContainer>
    </div>
  );
};
