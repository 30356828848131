import starGroup from 'assets/images/gold_star_group.svg';
import gradCap from 'assets/images/grad_cap.svg';
import star from 'assets/images/single_star.svg';
import { RadialProgress } from 'components/RadialProgress';
import { Popup } from 'components/popup';
import { Walkthrough, shouldShowWalkthrough, useWalkthrough } from 'containers/walkthrough';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { animated, config, useSpring, useTrail } from 'react-spring';
import { ZIndex } from 'styles/base';
import theme from 'theme';
import { AwardBadge } from './AwardBadge';
import { TestCompletePopupProps as Props } from './TestCompletePopup.types';

export const TestCompletePopup: React.FC<Props> = ({
  isOpen = false,
  testName = '',
  brandColor,
  percentage,
  xp,
  radialProgressInfo,
  awards = [],
  onClose,
}) => {
  const intl = useIntl();

  const { onWalkthroughEnd, completedWalkthroughs, locale } = useWalkthrough();
  const showWalkthrough = shouldShowWalkthrough(completedWalkthroughs, 'module-complete');

  const fadingStars = useTrail(11, {
    from: { opacity: 0 },
    to: { opacity: isOpen ? 1 : 0 },
    config: config.gentle,
  });

  const fadingCaps = useTrail(5, {
    from: { opacity: 0 },
    to: { opacity: isOpen ? 1 : 0 },
    config: config.gentle,
    delay: 1000,
  });

  const fadingStarsGroup = useSpring({
    from: { opacity: 0 },
    to: { opacity: isOpen ? 1 : 0 },
    config: config.gentle,
    delay: 1000,
  });

  const fadingResults = useSpring({
    from: { opacity: 0 },
    to: { opacity: isOpen ? 1 : 0 },
    config: config.gentle,
    delay: 1600,
  });

  const handleCompleteWalkthrough = () => onWalkthroughEnd('module-complete');

  return (
    <>
      {showWalkthrough && (
        <Walkthrough
          open
          variant='module-complete'
          targets={['#test-complete-popup-score', '#test-complete-popup-total-xp']}
          lang={locale}
          onSkip={handleCompleteWalkthrough}
          onEnd={handleCompleteWalkthrough}
        />
      )}

      <Popup
        open={isOpen}
        variant='minimal'
        buttonText={intl.formatMessage({ id: 'formats.button.thanks' })}
        onRequestClose={onClose}
        style={{ zIndex: ZIndex.high }}
        hasThanksAndCloseButton={true}
      >
        <div className='flex items-center justify-center w-full h-80 pt-3/10'>
          {/* STARS LEFT BOTTOM */}
          <animated.img
            src={star}
            className='absolute w-1/12 left-1/10 bottom-4/10'
            style={fadingStars[0]}
            alt=''
          />
          <animated.img
            src={star}
            className='absolute w-1/20 left-2/10 bottom-1/4'
            style={fadingStars[1]}
            alt=''
          />
          <animated.img
            src={star}
            className='absolute w-1/10 left-1/14 bottom-1/10'
            style={fadingStars[2]}
            alt=''
          />

          {/* STARS RIGHT BOTTOM */}
          <animated.img
            src={star}
            className='absolute w-1/20 right-2/10 bottom-1/4'
            style={fadingStars[3]}
            alt=''
          />
          <animated.img
            src={star}
            className='absolute rotate-45 w-1/10 right-1/10 bottom-1/6'
            style={fadingStars[4]}
            alt=''
          />

          {/* STARS LEFT TOP */}
          <animated.img
            src={star}
            className='absolute w-1/14 left-1/3 top-1/7'
            style={fadingStars[5]}
            alt=''
          />
          <animated.img
            src={star}
            className='absolute w-1/10 left-2/10 top-1/4 rotate-12'
            style={fadingStars[6]}
            alt=''
          />

          {/* STARS RIGHT TOP */}
          <animated.img
            src={star}
            className='absolute w-1/7 right-1/4 top-1/6'
            style={fadingStars[7]}
            alt=''
          />
          <animated.img
            src={star}
            className='absolute w-1/20 right-2/10 top-2/10'
            style={fadingStars[8]}
            alt=''
          />
          <animated.img
            src={star}
            className='absolute w-1/10 right-1/7 top-1/4'
            style={fadingStars[9]}
            alt=''
          />
          <animated.img
            src={star}
            className='absolute w-1/10 right-1/20 top-1/3'
            style={fadingStars[10]}
            alt=''
          />

          {/* STARS GROUP CENTER */}
          <animated.img
            src={starGroup}
            className='absolute top-1/7 w-6/10'
            style={fadingStarsGroup}
            alt=''
          />

          {/* HATS */}
          <animated.img
            src={gradCap}
            className='absolute scale-x-[-1] w-[10%] left-1/10 top-4/10'
            style={fadingCaps[0]}
            alt=''
          />
          <animated.img
            src={gradCap}
            className='absolute w-1/7 left-1/14 top-6/10'
            style={fadingCaps[1]}
            alt=''
          />
          <animated.img
            src={gradCap}
            className='absolute w-1/6 left-1/10 top-1/4'
            style={fadingCaps[2]}
            alt=''
          />
          <animated.img
            src={gradCap}
            className='absolute scale-x-[-1] w-2/10 right-1/20 top-1/2'
            style={fadingCaps[3]}
            alt=''
          />
          <animated.img
            src={gradCap}
            className='absolute w-1/3 left-3/10 top-1/6'
            style={fadingCaps[4]}
            alt=''
          />

          <div id='test-complete-popup-score'>
            <RadialProgress
              value={percentage}
              learningInformation={radialProgressInfo}
              primaryColor={brandColor || theme.colors.ocean}
              extended
              hideProgress={false}
              withSign
              counter
            />
          </div>
        </div>

        {awards.length > 0 && (
          <div className='flex justify-center w-full h-24 pt-4'>
            {awards.map(({ fill, translationId, xpColor, points }, idx) => (
              <AwardBadge
                key={translationId}
                idx={idx}
                fill={fill}
                text={intl.formatMessage({ id: translationId }).split('\n')}
                xpColor={xpColor}
                points={points}
              />
            ))}
          </div>
        )}

        <animated.div
          className='z-30 w-full h-24 pt-6 text-sm font-semibold text-center text-turquoiseBlue'
          style={{ color: brandColor, ...fadingResults }}
        >
          {!testName ? (
            <FormattedMessage
              id='formats.rewards.message.retention-test'
              defaultMessage='In this test, you earned'
            />
          ) : (
            <FormattedMessage
              id='formats.rewards.message.test'
              defaultMessage='In this {name} test, you earned'
              values={{
                name: <b>"{testName}"</b>,
              }}
            />
          )}
          <div
            id='test-complete-popup-total-xp'
            className='text-3xl font-black text-center'
          >{`${String(xp)} XP!`}</div>
        </animated.div>
      </Popup>
    </>
  );
};
