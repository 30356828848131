import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ProfileStore } from 'containers/Profile';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { Store } from 'store/types';
import { POSTS_FETCH_LIMIT, actions } from './Newsfeed.ducks';
import { Post } from './Newsfeed.types';

export const hasPollBeenAnswered = (post: Post) =>
  Array.isArray(post.answers) && post.answers.some((ans) => ans.answered);

export const useNewsfeed = () => {
  const [hasMore, setHasMore] = useState(true);
  const feedContainerRef = useRef<HTMLUListElement | null>(null);
  const [scrollPos, setScrollPos] = useState(0);

  // Selectors
  const posts = useSelector(({ newsfeed }: Store) => newsfeed.posts);
  const error = useSelector(({ newsfeed }: Store) => newsfeed.error);
  const isFetching = useSelector(({ newsfeed }: Store) => newsfeed.isFetching);
  const isRefreshing = useSelector(({ newsfeed }: Store) => newsfeed.isRefreshing);
  const savedScroll = useSelector(({ newsfeed }: Store) => newsfeed.scrollPos);
  const hasReachedEnd = useSelector(({ newsfeed }: Store) => newsfeed.hasReachedEnd);
  const currentPostLimit = useSelector(({ newsfeed }: Store) => newsfeed.currentPostLimit);
  const { id: accountId } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);
  const isCommunityEnabled = useFeatureFlag(FlagKeys.COMMUNITY_CHAT) && dynamicConfiguration?.menu.community.show;

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch the posts on entering the route
    dispatch(actions.getPosts({}));
  }, [dispatch]);

  useEffect(() => {
    // Will set the `hasMore` to false when there are no more posts, basicaly when limit is bigger than the actual posts
    if (hasReachedEnd && posts.length !== 0) {
      setHasMore(false);
    }
  }, [currentPostLimit, posts.length, hasReachedEnd]);

  const onPostClick = (postID: string) => () => {
    const path = isCommunityEnabled ? '/community/newsfeed' : '/newsfeed';
    history.push(`${path}/${postID}`);
    // Save scroll position
    dispatch(actions.saveScroll({ position: scrollPos }));
  };

  const onRefreshFeed = () => {
    // This is the reason the limit needs to be dynamic, so we can refresh and get the same ammount we had before
    dispatch(actions.getPosts({ limit: currentPostLimit, refresh: true }));
  };

  const fetchMorePosts = () => {
    dispatch(actions.getPosts({ limit: POSTS_FETCH_LIMIT, offset: currentPostLimit }));
  };

  const shouldFetchMore = () => {
    if (!hasReachedEnd) {
      setHasMore(true);
      return true;
    }
    setHasMore(false);
    return false;
  };

  const fetchMoreData = () => {
    if (shouldFetchMore()) {
      fetchMorePosts();
    }
  };

  const trackScroll = () => {
    const container = feedContainerRef.current;
    if (container) {
      setScrollPos(container.scrollTop);
    }
  };

  useEffect(() => {
    // Set scroll to saved scroll when mounting
    const container = feedContainerRef.current;
    if (container) {
      container.scrollTop = savedScroll;
    }
  }, [savedScroll]);

  return {
    posts,
    onPostClick,
    onRefreshFeed,
    hasMore,
    error,
    isRefreshing,
    isFetching,
    feedContainerRef,
    trackScroll,
    fetchMoreData,
  };
};
