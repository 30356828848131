import classnames from 'classnames';
import React from 'react';
import { FullScreenOverlayProps as Props } from './Overlay.types';
import { useAnimatedPortal } from './usePortal';

export const FullScreenOverlay: React.FC<Props> = ({ open, disableAnimation, variant, className, style, ...rest }) => {
  const createPortal = useAnimatedPortal({
    display: open,
    disableAnimation,
    style,
    className: classnames(
      'fixed top-0 w-full h-full',
      className,
      'z-50',
      { 'pointer-events-none w-full': disableAnimation },
      { 'bg-black bg-opacity-50': variant === 'faded' },
      { 'bg-white': variant === 'fullWhite' },
      { 'z-0 pointer-events-none': !open },
    ),
  });

  return createPortal(
    <div
      className='flex items-center justify-center w-full h-full'
      {...rest}
    />,
  );
};
