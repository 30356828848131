import { AnyAction } from '@reduxjs/toolkit';
import { API_URL } from 'constants/endpoints';
import { GetAllBadgesPayload, UpdateUserPayload, actions as profileActions } from 'containers/Profile';
import { actions as authActions } from 'containers/authentication';
import { GetWalkthroughsRequestPayload, walkthroughApi } from 'containers/walkthrough';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Store } from 'store/types';
import AppComponent from './App';

const mapStateToProps = ({ auth, app, profile, activePath }: Store) => {
  const fallbackSplash = app.splashScreen ? `${API_URL}/${app.splashScreen}` : null;
  const splashSrc = activePath.splashScreen ? `${API_URL}/${activePath.splashScreen}` : fallbackSplash;
  return {
    auth,
    splashSrc,
    lecoBackground: activePath.pathBackground,
    accountBackground: app.background,
    routesWithBackgroundImage: app.routesWithBackgroundImage,
    role: profile.user.role,
    userId: profile.user.id,
    user: profile.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getUser: (userId: string) => dispatch(authActions.getUser({ userId })),
  getUserInfo: () => dispatch(authActions.getUserInfo()),
  getAllBadges: (payload: GetAllBadgesPayload) => dispatch(profileActions.getAllBadges(payload)),
  getWalkthroughs: (payload: GetWalkthroughsRequestPayload) =>
    dispatch(walkthroughApi.endpoints.getWalkthroughs.initiate(payload) as unknown as AnyAction),
  updateUser: (payload: UpdateUserPayload) => dispatch(profileActions.updateUser(payload)),
});

export const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent);
