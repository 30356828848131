import iconStars from 'assets/images/iconStars.png';
import popupPencil from 'assets/images/popup_pencil.png';
import { Popup } from 'components/popup';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'store/types';
import theme from 'theme';
import { ActiveLearningPathStore, PathEndModalProps as Props } from '../Lepa.types';

/**
 * Modal to show after completion of a path
 */
const PathEndModalComponent: React.FC<Props> = ({ intl, onModalClose, modal, brandColor, open }) => {
  const isPathWithCertification = useSelector<Store, ActiveLearningPathStore['isCertification']>(
    ({ activePath }) => activePath.isCertification,
  );
  const history = useHistory();
  const { hidePlayMoreInfo = false } = modal;

  const handleSearchClick = () => {
    history.push('/search');
  };

  const handleClose = () => {
    modal.onClose(); // boolean on redux store

    if (onModalClose) {
      onModalClose();
    }

    if (!isPathWithCertification) {
      history.push('/');
    }
  };

  const congratsStyles: React.CSSProperties = {
    color: brandColor || theme.colors.turquoiseBlue,
  };

  return (
    <Popup
      noAnimation
      open={open}
      variant='endPath'
      buttonText={intl.formatMessage({ id: 'formats.button.thanks' })}
      onRequestClose={handleClose}
      hasThanksAndCloseButton={true}
      smallRoundedTop={hidePlayMoreInfo}
    >
      <div className='relative py-12'>
        <div
          className='mt-6 text-3xl font-black text-center text-turquoiseBlue'
          style={congratsStyles}
        >
          {intl.formatMessage({ id: 'container.pathScreen.popup.congratulations' })}
        </div>
        <div className='mt-5 text-base font-black text-center text-lightGrey'>
          {intl.formatMessage({ id: 'container.pathScreen.popup.message' })}
        </div>
        {!hidePlayMoreInfo && (
          <div className='mt-3 font-light text-center'>
            {intl.formatMessage({ id: 'container.pathScreen.popup.question' })}
          </div>
        )}
      </div>

      {!hidePlayMoreInfo && (
        <div className='flex flex-col -mt-5 -ml-2 text-sm font-light'>
          <div
            onClick={handleClose}
            className='flex flex-row items-center mb-5 ml-2'
          >
            <img
              src={iconStars}
              className='w-7 h-7'
              alt=''
            />
            <div className='pt-1 pl-5'>{intl.formatMessage({ id: 'container.pathScreen.popup.redo' })}</div>
          </div>

          <div
            onClick={handleClose}
            className='flex flex-row items-center mb-5 ml-2'
          >
            <img
              src={popupPencil}
              className='w-6 h-6'
              alt=''
            />
            <div className='pt-1 pl-5'>{intl.formatMessage({ id: 'container.pathScreen.popup.test' })}</div>
          </div>

          <div
            onClick={handleSearchClick}
            className='flex flex-row items-center mb-5 ml-2'
          >
            <SvgIcon
              key={'icons-search'}
              className='w-7 h-7 text-pinkishGrey'
              color='currentColor'
              name={'search'}
              style={{ overflow: 'visible' }}
            />

            <div className='pt-1 pl-5'>{intl.formatMessage({ id: 'container.pathScreen.popup.search' })}</div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export const PathCompletedModal = injectIntl(PathEndModalComponent);
