import Question from 'components/Question';
import { SvgIcon } from 'components/svg-icon';
import { ImageFormat, VideoFormat } from 'containers/FormatScreen/Formats';
import { Walkthrough, shouldShowWalkthrough } from 'containers/walkthrough';
import _shuffle from 'lodash/shuffle';
import * as React from 'react';
import theme from 'theme';
import arrayMove from 'util/arrayMove';
import { isHTML } from 'util/isHTML';
import Dragable from './Dragable';
import {
  ExtraAnswerData,
  ReorderFormatProps as Props,
  ReorderFormatAnswer,
  ReorderFormatState as State,
} from './types';

// spacing between the answers
const margin = 8;

export class ReorderFormat extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // TODO: Because this component is based on mutating the `answers` this is a temporary fix
    // until it's refactored, since the `createSlice` freezes everything.
    const unfrozenAnswers = JSON.parse(JSON.stringify(props.answers)) as ReorderFormatAnswer[];

    const answers = _shuffle(unfrozenAnswers);
    const answersIDs = answers.map((ans) => ans.id);
    const originalAnswersIDs = props.answers.map((ans) => ans.id);

    // Ensure at least one move in the array
    if (answersIDs.join() === originalAnswersIDs.join()) {
      const temp = answers[0];
      const pick = Math.floor(Math.random() * (answers.length - 1)) + 1;
      answers[0] = answers[pick];
      answers[pick] = temp;
    }

    const positions = [0, 0, 0, 0, 0];

    this.state = {
      answers,
      positions,
      minHeight: 0,
    };
  }

  componentDidMount() {
    const { answers } = this.state;

    // Updates the positions
    const updatedPositions = this.formatPositionMargins(answers);
    // Sets minHeight container size
    const updatedMihHight = answers.reduce((acc, ans) => acc + (ans.height ?? 0) + margin, 0);

    this.setState({
      positions: updatedPositions,
      minHeight: updatedMihHight,
    });
  }

  /** Formats the fixed position of the answers acording
   *  to the space occupied by the label */
  formatPositionMargins = (answers: Props['answers']) => {
    const newPositions = [];
    let height = 0;
    let prevPos = 0;

    let i = 0;
    const len = answers.length;
    for (i; i < len; i += 1) {
      if (i === 0) {
        newPositions[i] = 0;
        height = answers[i].height ?? 0;
        prevPos = height;
      } else {
        height = answers[i].height ?? 0;
        newPositions[i] = prevPos + margin;
        prevPos = height + prevPos + margin;
      }
    }
    return newPositions;
  };

  reorderDragables = (newPosition: number, answerData: ExtraAnswerData) => {
    const { indx } = answerData;
    const { positions, answers } = this.state;

    const up = indx > 0 && newPosition <= positions[indx - 1];
    const down = indx < positions.length - 1 && newPosition >= positions[indx + 1];

    if (up || down) {
      const newIndex = up ? indx - 1 : indx + 1;

      const newAnswers = arrayMove(answers, indx, newIndex);

      // Rearenge positions based on answers
      const newPositions = this.formatPositionMargins(newAnswers);

      this.setState({ answers: newAnswers, positions: newPositions }, () => {
        this.props.onChange(newAnswers.map((answer) => answer.id));
      });
    }
  };

  //* gets and sets the height needed for all the answers label */
  setHeightToAnswers = (dimentions: DOMRect, answer: ReorderFormatAnswer) => {
    const { id } = answer;
    const { height } = dimentions;
    const { answers } = this.state;

    // Get the corresponding answer and add a height property
    const updatedAnswers = [...answers];
    const desiredAnswers = updatedAnswers.filter((ans) => ans.id === id);
    const desiredAnswer = desiredAnswers[0];

    // sets the height for the desired answer
    desiredAnswer.height = height;

    this.setState({ answers: updatedAnswers });
  };

  render() {
    const {
      showAnswers,
      correct,
      imgSrc,
      video,
      question,
      focusColor,
      completedWalkthroughs,
      onCompleteWalkthrough,
      locale,
    } = this.props;
    const { answers, positions, minHeight } = this.state;

    const isRichText = isHTML(question);
    const showWalkthrough = shouldShowWalkthrough(completedWalkthroughs, 'reorder');

    return (
      <div className='w-full'>
        {showWalkthrough && (
          <Walkthrough
            variant='reorder'
            open
            targets={['body', '#draggable-answer']}
            lang={locale}
            onSkip={onCompleteWalkthrough}
            onEnd={onCompleteWalkthrough}
          />
        )}

        {video && (
          <div className='my-4'>
            <VideoFormat url={video} />
          </div>
        )}
        {imgSrc && !video && (
          <div className='my-4'>
            <ImageFormat imgSrc={imgSrc} />
          </div>
        )}

        {question && (
          <Question
            className='p-3 text-base break-words text-greyishBrown'
            text={question}
            rich={isRichText}
            noStyles
          />
        )}

        <div
          className='relative'
          style={{ minHeight }}
        >
          {answers.map((answer, indx) => (
            <Dragable
              key={answer.id}
              position={positions[indx]}
              onMove={this.reorderDragables}
              extraData={{ answer, indx }}
              longPressEnabled
              disabled={showAnswers}
              showAnswers={showAnswers}
              correct={correct[indx] === answer.id}
              onLayout={this.setHeightToAnswers}
              focusColor={focusColor}
            >
              <div
                className='flex items-center w-full px-3 mx-4 leading-none'
                style={{ minHeight: '48px' }}
              >
                {answer.label}
              </div>
              {showAnswers && (
                <div className='absolute transform top-5/10 right-4 -translate-y-5/10'>
                  <SvgIcon
                    viewBox='0 0 22 22'
                    name={correct[indx] === answer.id ? 'check' : 'close'}
                    size={24}
                    color={theme.colors.white}
                  />
                </div>
              )}
            </Dragable>
          ))}
        </div>
      </div>
    );
  }
}
