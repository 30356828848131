import classnames from 'classnames';
import Breadcrumbs from 'components/Breadcrumbs';
import Textfield from 'components/Textfield';
import { Popup } from 'components/popup';
import { SvgIcon } from 'components/svg-icon';
import { VideoFormat } from 'containers/FormatScreen/Formats';
import { Format, Module, Skill } from 'containers/FormatScreen/types';
import { Walkthrough, WalkthroughVariantTypes, shouldShowWalkthrough } from 'containers/walkthrough';
import { LepaTypes } from 'pages/home/Home.types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import theme from 'theme';
import { doNothing } from 'util/doNothing';
import { NewResults, Rank, Result, SearchProps, SearchState } from './types';

class Search extends React.Component<SearchProps, SearchState> {
  public state = {
    searchText: '',
    filteredResults: {
      skills: [],
      tipsTricks: [],
    },
    searchResults: {},
    popupOpen: false,
    popupMedia: null,
    popupTitle: null,
  };

  public componentDidMount() {
    this.syncState(this.props);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: SearchProps) {
    this.syncState(nextProps);
  }

  public render() {
    const { intl, completedWalkthroughs, locale } = this.props;
    const { searchText, filteredResults, popupMedia, popupOpen, popupTitle } = this.state;
    const haveResults = filteredResults.skills.length > 0 || filteredResults.tipsTricks.length > 0;
    const showWalkthrough = shouldShowWalkthrough(completedWalkthroughs, 'search');

    const resultsToShow = haveResults ? (
      Object.keys(filteredResults).map((groupName, idx) => {
        if ((filteredResults as any)[groupName].length > 0) {
          return (
            <div
              className='flex flex-col flex-1 mb-3.5'
              key={`search-group-${idx}`}
            >
              <div className='text-lg mb-3.5'>
                {groupName === 'skills' ? (
                  <FormattedMessage id='container.search.group.content' />
                ) : (
                  <FormattedMessage id='container.search.group.tips' />
                )}
              </div>
              {(filteredResults as any)[groupName].map((result: Result, index: number) =>
                this.renderResults(result, index),
              )}
            </div>
          );
        }
        return null;
      })
    ) : (
      <FormattedMessage id='container.search.noResults' />
    );

    return (
      <div className='flex flex-col w-full h-full overflow-y-auto'>
        {showWalkthrough && (
          <Walkthrough
            variant='search'
            open
            targets={['#search-bottomBarIcon', '#search-top-wrapper']}
            lang={locale}
            onSkip={this.onWalkthroughEnd('search')}
            onEnd={this.onWalkthroughEnd('search')}
          />
        )}

        <Breadcrumbs to='/' />

        <div className='pt-3.5 pr-0 pb-1 pl-3.5 border-b-[1px] border-solid border-light'>
          <div
            id='search-top-wrapper'
            className='text-sm text-light'
          >
            <FormattedMessage id='container.search.title' />
          </div>
          <div className='flex items-center'>
            <SvgIcon
              name='search'
              size={24}
              color={theme.colors.warmGrey}
            />
            <Textfield
              placeholder={intl.formatMessage({ id: 'container.search.placeholder' })}
              value={searchText}
              onChange={this.onInputChange}
              data-testid='search-field'
            />
          </div>
        </div>

        <div className='p-3.5 text-greyishBrown'>{searchText && resultsToShow}</div>

        {popupOpen && (
          <Popup
            variant='clean'
            open={popupOpen}
            title={popupTitle ?? ''}
            buttonText={intl.formatMessage({ id: 'formats.button.thanks' })}
            hasThanksAndCloseButton={true}
            onClick={doNothing}
            onRequestClose={this.togglePopup}
          >
            <VideoFormat
              url={popupMedia}
              className='relative'
            />
          </Popup>
        )}
      </div>
    );
  }

  private renderResults = (result: Result, idx: number) =>
    result.rank ? (
      <div
        key={`${result.title}-${idx}`}
        className='flex min-h-14 text-sm border-b-[1px] border-solid border-black justify-between cursor-pointer'
        onClick={this.navigate(result)}
      >
        <div className={result.videos && result.videos.length > 0 ? 'flex items-center w-1/2' : 'w-full flex flex-col'}>
          <div className='w-full flex flex-col pt-2.5 break-words'>
            <span
              className={classnames({
                'text-turquoiseBlue': result.rank === Rank.PLAYER,
                'text-fullRed': result.rank === Rank.EXPLORER,
                'text-yellow-400': result.rank === Rank.GURU,
                'text-violet-800': result.rank === Rank.SPECIALIST,
                'text-indigo-700': result.rank === Rank.NAVIGATOR,
              })}
            >
              {result.title}
              {result.locked ? <span className='text-sm text-greyish'> - locked</span> : ''}
            </span>
            <span className='text-sm text-greyish'>{result.levelName}</span>
          </div>
        </div>
        {result.videos && result.videos.length > 0 && (
          <div className='flex flex-col w-1/2 text-2xs border-l-[1px] border-solid border-neutral-200'>
            {result.videos.map((video, indx) => (
              <div
                className='flex items-center w-full p-1'
                onClick={this.triggerVideo(video)}
                key={`videoItem-${indx}`}
              >
                <SvgIcon
                  className='relative rounded-full m-1 h-6 w-6 border-[1px] border-solid border-turquoiseBlue'
                  name='play'
                  color={theme.colors.turquoiseBlue}
                />
                <div className='flex flex-col flex-1 w-full pl-5'>
                  <span className='text-turquoiseBlue'>{video.title}</span>
                  <span className='text-greyish'>Watch video</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    ) : (
      <div
        key={`result-${idx}`}
        className='flex min-h-14 text-sm border-b-[1px] border-solid border-black justify-between cursor-pointer text-greyish w-full flex flex-col pt-2.5 break-words'
        onClick={this.navigate(result)}
      >
        {result.title}
      </div>
    );

  /**
   *
   * @param skillModule
   *
   * We need to search videos inside text & video formats
   * Return only text & video formats where content.url is not empty
   *
   */
  private getModuleVideos = (skillModule: Module) =>
    skillModule.formats.filter((format: Format) => {
      const formatsToSearch = ['text', 'video'];
      const { formatType, content } = format;
      const hasVideoURL = Boolean(content.url);

      return formatsToSearch.includes(formatType) && hasVideoURL;
    });

  private syncState(props: SearchProps) {
    const { levels } = props.activePath;

    const filtered = levels.reduce(
      (acc: any, level) => {
        const tipsTricks = acc.tipsTricks.concat(
          level.tipsTricks.map((tip) => ({
            ...tip,
            type: 'tip',
            levelID: level.id,
          })),
        );

        let skills = acc.skills.concat(
          level.skills.map((skill) => ({
            id: skill.id,
            levelID: level.id,
            levelName: level.title,
            rank: level.rank,
            title: skill.title,
            summary: skill.summary,
            tags: skill.tags,
            locked: !skill.unlocked,
            type: 'skill',
          })),
        );

        skills = ([] as any[]).concat.apply(
          skills,
          level.skills.map((skill) =>
            skill.modules.map((skillModule) => ({
              id: skillModule.id,
              skillID: skill.id,
              levelID: level.id,
              levelName: level.title,
              rank: level.rank,
              title: skillModule.title,
              summary: skillModule.summary,
              tags: skillModule.tags,
              locked: !skillModule.unlocked,
              videos: this.getModuleVideos(skillModule),
              type: 'module',
            })),
          ),
        );

        return { skills, tipsTricks };
      },
      { skills: [], tipsTricks: [] },
    );

    const results = { skills: filtered.skills, tipsTricks: filtered.tipsTricks };

    this.setState({ searchResults: results }, () => this.onInputChange(this.state.searchText));
  }

  private onInputChange = (value: string) => {
    const { searchResults } = this.state;
    let newResults: NewResults = { ...searchResults };

    newResults = Object.keys(newResults)
      .map((groupName) => ({
        [groupName]: newResults[groupName].filter(
          (result: Skill) =>
            result.title.toLowerCase().search(value.toLowerCase()) !== -1 ||
            result.summary.toLowerCase().search(value.toLowerCase()) !== -1 ||
            (result.tags && result.tags.find((tag) => tag.toLowerCase().search(value.toLowerCase()) !== -1)),
        ),
      }))
      .reduce((obj, key) => ({ ...obj, ...key }), {});

    this.setState({ filteredResults: newResults, searchText: value });
  };

  private navigate = (result: Result) => () => {
    const { id: pathID, type } = this.props.activePath;

    if ((result.type === 'module' && result.locked) || !type) {
      return null;
    }

    const { history } = this.props;

    switch (result.type) {
      case 'skill': {
        const skillResultPath = {
          [LepaTypes.CRASH_COURSE]: `/crash-course/${pathID}`,
          [LepaTypes.LEGACY]: `/learning-path/${pathID}/level/${result.levelID}/skill/${result.id}`,
          [LepaTypes.VIDEO]: '/',
          [LepaTypes.TEXT]: '/',
          [LepaTypes.QUESTIONS_ONLY]: '/',
          [LepaTypes.SINGLE_LEARNING]: '/',
        }[type];
        return history.push(skillResultPath);
      }
      case 'module': {
        const moduleResultPath = {
          [LepaTypes.CRASH_COURSE]: `/crash-course/${pathID}/module/${result.id}`,
          [LepaTypes.LEGACY]: `/learning-path/${pathID}/level/${result.levelID}/skill/${result.skillID}/module/${result.id}`,
          [LepaTypes.VIDEO]: '/',
          [LepaTypes.TEXT]: '/',
          [LepaTypes.QUESTIONS_ONLY]: '/',
          [LepaTypes.SINGLE_LEARNING]: '/',
        }[type];
        return history.push(moduleResultPath);
      }
      case 'tip': {
        const tipResultPath = {
          [LepaTypes.CRASH_COURSE]: `/crash-course/${pathID}`,
          [LepaTypes.LEGACY]: `/learning-path/${pathID}`,
          [LepaTypes.VIDEO]: '/',
          [LepaTypes.TEXT]: '/',
          [LepaTypes.QUESTIONS_ONLY]: '/',
          [LepaTypes.SINGLE_LEARNING]: '/',
        }[type];
        return history.push(tipResultPath, { levelId: result.levelID, tip: { ...result } });
      }
      default:
        return null;
    }
  };

  private triggerVideo = (video: Format) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const popupMedia = video && video.content ? video.content.url : undefined;
    event.stopPropagation();

    if (popupMedia) {
      this.setState({
        popupOpen: true,
        popupMedia,
        popupTitle: video.title,
      });
    }
  };

  private togglePopup = () => {
    this.setState({
      popupOpen: false,
      popupMedia: null,
      popupTitle: null,
    });
  };

  private onWalkthroughEnd = (slug: WalkthroughVariantTypes) => () => {
    const { user } = this.props;
    return this.props.completeWalkthrough({
      userId: user.id,
      body: { slug },
    });
  };
}

export default injectIntl(Search);
