import { Bonus, Penalty } from 'containers/FormatScreen/types';
import { Account, ProfileStore } from 'containers/Profile';
import { Locale } from 'containers/language-provider';
import { Authentications } from 'pages/login/deprecated-login/types';
import { Persona } from 'pages/personality-test';
import { WrappedComponentProps } from 'react-intl';
import { URLSearchParams } from 'util/useURLSearchParams';
import { Locales } from '../../constants/global';

export enum AuthRedirectMessageTypes {
  LOGOUT = 'logout',
  UNAUTHORIZED = 'unauthorized',
}

export type AuthHistoryStateParams = { messageType?: string };

export type AuthStore = {
  sso: boolean;
  isAuthorizing: boolean;
  error: string;
  forgot: {
    sent: boolean;
  };
  reset: {
    success: boolean;
    error: boolean;
    message: string;
  };
  preview: {
    isValidating: boolean;
    isValidToken: boolean;
    activePathId?: number;
    activePathType?: string;
  };
  customLogin: ValidateCustomLoginSuccess;
  features: {
    newsfeed: boolean;
  };
};

export interface User {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  description: string;
  avatar: string;
  level: number;
  completed: number;
  toNextLevel: number;
  role: string;
  jobPosition: string;
  progress: {
    completedModules: number;
    modulesToNextLevel: number;
    meLastMonth: number;
    myStoreLastMonth: number;
    meOverall: number;
    myStoreOverall: number;
    totalLastMonth: number;
    totalOverall: number;
  };
  xp: number;
  persona?: Persona;
  isLocaleSelected: boolean;
  locale?: Locales;
  isDigestOptedIn: boolean;
  eulaAcceptedAt?: string;
}

export interface LogoutSuccessPayload {
  redirectUri: string;
  sso: boolean;
  ssoRedirectUri?: string;
}

export interface TokenLoginPayload {
  flow: Authentications;
  token: string;
}

export type LoginCredentials = {
  email?: string;
  password?: string;
  /** recaptcha token */
  token?: string;
  /** accountID for custom login */
  accountID?: AuthStore['customLogin']['accountID'];
};

export interface LoginProps extends WrappedComponentProps {
  error: string;
  locale: string;
  accountLogo: ProfileStore['account']['logoUrl'];
  customLogin?: AuthStore['customLogin'];
  isAuthorizing: AuthStore['isAuthorizing'];
  onLocaleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export type LoginPayload = {
  credentials: LoginCredentials;
  flow?: Authentications;
  params?: URLSearchParams;
};

export type ValidatePreviewTokenPayload = {
  token: string;
};

export type ValidatePreviewTokenSuccessPayload = {
  role: string;
  activePathId?: number;
  activePathType?: string;
};

export interface SetActiveAccountPayload {
  accountID: string;
}

export type AuthFeatures = (keyof AuthStore['features'])[];

export interface ValidateCustomLoginSuccess {
  accountID?: string;
  accountLogo?: string;
  primaryColor?: string;
  sidebarColor?: string;
  splashScreen?: string;
  hasExternalIdProvider: boolean;
}

export interface LoginUserSuccessPayload {
  accountLogo: string;
  locale: Locale;
}
export interface GetUserPayload {
  userId: string;
}

export type GetUserSuccessPayload = User;

export interface GetUserInfoSuccessPayload {
  users: {
    me: {
      id: string;
      email: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      avatarUrl: string;
      jobPosition: string;
      walkthrough: boolean;
      xp: number;
      moduleCreditReached: boolean;
      sso: string;
      locale: Locale;
      persona: Persona;

      role: {
        slug: string;
      };

      accounts: Account[];

      lastAccount: {
        id: string;
        locale: Locale;
        logoUrl: string;
        applicationName: string;
        splashScreen: string;
        pathColor: string;
        pathBackground: string;
        newsfeedEnabled: boolean;
        logoDarkUrl: string;
        layout: string;
        theme: string;
        primaryColor: string;
      };
    };
  };

  progress: {
    overview: {
      completedModules: number;
      modulesToNextLevel: number;
      meLastMonth: number;
      myStoreLastMonth: number;
      meOverall: number;
      myStoreOverall: number;
      totalLastMonth: number;
      totalOverall: number;
    };
  };

  rules: {
    streak: {
      value: number;
    };
    quickAnswer: {
      value: number;
    };
    niceComeback: {
      value: number;
    };
    backOnTrack: {
      value: number;
    };
    penaltyModifiers: Penalty[];
    bonusModifiers: Bonus[];
  };

  settings: {
    faqUrl: {
      value: string;
      enabled: boolean;
    };

    supportUrl: {
      value: string;
      enabled: boolean;
    };
  };
}

export interface ExchangeCodeForAuthTokenPayload {
  code: string;
}

export const RESPONSE_TOKEN_KEY = 'access_token';
export const RESPONSE_XT_PROV_URL_KEY = 'xt_prov_url';
export interface ExchangeCodeForAuthTokenSuccessPayload {
  [RESPONSE_TOKEN_KEY]: string;
  [RESPONSE_XT_PROV_URL_KEY]: string;
}
export interface ResetPasswordPayload {
  token: string;
  password: string;
}
