import { actions } from 'pages/lti/LTI.ducks';
import { LTIProvider, LTIProviders, LTIState, UseLTIProps } from 'pages/lti/LTI.types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store from 'store';
import { Store } from 'store/types';
import { useURLSearchParams } from 'util/useURLSearchParams';

const GoodHabitzProvider: LTIProvider = {
  getSignedRequestUrl: (courseUrl) => {
    store.dispatch(actions.getCourseSignedRequest(courseUrl));
  },
};

const LTIProvidersHandler: { [provider in LTIProviders]: LTIProvider } = {
  [LTIProviders.GOODHABITZ]: GoodHabitzProvider,
};

export const useLTI = ({ provider }: UseLTIProps) => {
  const dispatch = useDispatch();
  const { url } = useURLSearchParams<string>();
  const providersState = useSelector<Store, LTIState>(({ lti }) => lti);

  React.useEffect(() => {
    const lti = LTIProvidersHandler[provider];

    if (!lti.getSignedRequestUrl) {
      return;
    }
    lti.getSignedRequestUrl(url);
  }, [url, provider, dispatch]);

  return {
    url,
    providersState,
  };
};
