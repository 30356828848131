import classNames from 'classnames';
import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import React from 'react';
import { PopupVariantProps } from '../Popup.types';

export const GradientFullscreenPopup: React.FC<PopupVariantProps> = ({
  className,
  pageContainerClassName = '',
  children,
  buttonText,
  accentColor,
  closeIcon,
  onClose,
}) => (
  <div
    className={classNames(
      className,
      'relative flex flex-col w-full h-full p-4 bg-white rounded-lg text-greyishBrown transform-none bg-gradient-to-tl from-blue-50 to-white',
    )}
    data-testid='e2e-popup'
  >
    <PageContainer className={pageContainerClassName}>
      <div className='flex flex-col items-center justify-center overflow-x-hidden overflow-y-auto h-9/10'>
        {children}
      </div>

      <div className={classNames('flex flex-col items-center', { 'pt-4 mb-14': buttonText })}>
        {buttonText && (
          <Button
            variant='outlined'
            text={buttonText}
            className='text-sm font-bold border-turquoiseBlue text-turquoiseBlue'
            onClick={onClose('button')}
          />
        )}
        {closeIcon && (
          <Button
            variant='text'
            text='X'
            round
            className='text-sm font-bold border-turquoiseBlue text-turquoiseBlue'
            style={{ color: accentColor, borderColor: accentColor }}
            onClick={onClose('close')}
          />
        )}
      </div>
    </PageContainer>
  </div>
);
