import { Popup } from 'components/popup';
import React from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'store/types';
import { actions } from '../Lepa.ducks';
import { RetentionPopupProps as Props } from '../Lepa.types';

/**
 * Modal to show when a path has a retention test to play
 */
const RetentionTestModalComponent: React.FC<Props> = ({ intl, modal, onModalClose, open }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const activePathID = useSelector<Store, string>(({ activePath }) => activePath.id);

  const handleSkipTest = () => {
    dispatch(actions.skipRetentionTest());
  };

  const handlePrimaryButtonClick = () => {
    onModalClose();
    history.push(`/learning-path/${activePathID}/retention-test/${modal.id ?? ''}`);
  };

  const handleCloseModal = () => {
    onModalClose();

    handleSkipTest();
  };

  return (
    <Popup
      variant='retention'
      open={open}
      title={modal.title}
      buttonText={modal.buttonText || intl.formatMessage({ id: 'formats.button.thanks' })}
      onRequestClose={handleCloseModal}
      onPrimaryClick={handlePrimaryButtonClick}
      closeIcon={modal.closeIcon}
      hasThanksAndCloseButton={true}
    >
      <div
        key='text-container'
        className='pt-2 text-sm font-light text-center whitespace-pre-wrap'
      >
        {modal.summary}
      </div>
    </Popup>
  );
};

export const RetentionTestModal = injectIntl(RetentionTestModalComponent);
