import classnames from 'classnames';
import { Account } from 'containers/Profile';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { TabHeader, TabsHeaderProps, TabsStyle } from './Tabs.types';

export const TabsHeader: FC<TabsHeaderProps> = ({ tabs, style, onClick }) => {
  const { primaryColor = 'turquoiseBlue' } = useSelector<Store, Account>(({ profile }) => profile.account);
  const hasDefaultStyle = style === TabsStyle.DEFAULT;
  const hasUnderlineStyle = style === TabsStyle.UNDERLINE;

  const handleClickTab = (tabIndex: number) => () => {
    onClick(tabIndex)();
  };

  const isUnderline = (tab: TabHeader) => tab.isActive && hasUnderlineStyle;

  return (
    <div
      id='tabs'
      className={classnames('flex mb-2', {
        'border-b-2 border-solid border-warmGrey text-pinkishGrey': hasDefaultStyle,
        'text-zinc-600 font-normal mt-3': hasUnderlineStyle,
      })}
    >
      {tabs.map((tab, currentIndex) => {
        const { notificationCount = 0 } = tab;
        const hasNotifications = notificationCount > 0;

        return (
          <button
            key={`tab-${tab.title}`}
            className={classnames(
              'flex justify-center flex-1 text-xs font-light leading-normal text-center p-2 cursor-pointer',
              {
                'uppercase': hasUnderlineStyle,
                'bg-warmGrey text-white': tab.isActive && hasDefaultStyle,
                'text-zinc-900 font-bold': isUnderline(tab),
              },
            )}
            onClick={handleClickTab(currentIndex)}
          >
            <span
              className='pb-1.5'
              style={{
                borderBottom: isUnderline(tab) ? `2px solid ${primaryColor}` : 'none',
              }}
            >
              {tab.title}
            </span>

            {hasNotifications && (
              <div className='ml-2 mb-2 z-10 w-5 h-5 rounded-full font-normal bg-red-500 text-white text-2xs text-center p-0.5'>
                {notificationCount}
              </div>
            )}
          </button>
        );
      })}
    </div>
  );
};
