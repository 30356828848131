import { Badge } from 'components/Badge';
import { BadgeData, Group } from 'components/Badge/types';
import ProfileHeader from 'components/ProfileHeader';
import { Tooltip } from 'components/Tooltip';
import { API_URL } from 'constants/endpoints';
import { actions as profileActions } from 'containers/Profile';
import { ProfileStore } from 'containers/Profile/types';
import { defaultLocale } from 'containers/language-provider';
import { ActiveLearningPathStore } from 'pages/lepa';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'store/types';

/** badges border colors */
const colors = ['grey', 'orange', 'green'];

export const Badges: React.FC = () => {
  const dispatch = useDispatch();

  const [selectedCategoryIndex, setSelectedCategoryIndex] = React.useState<number | null>(null);
  const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState<number | null>(null);

  const { user, badges } = useSelector<Store, ProfileStore>(({ profile }) => profile);
  const { currentLevelIndex } = useSelector<Store, ActiveLearningPathStore>(({ activePath }) => activePath);

  const locale = user.locale || defaultLocale;

  React.useEffect(() => {
    dispatch(profileActions.getAllBadges({ locale }));
    dispatch(profileActions.getUnlockedBadges({ locale, userId: user.id }));
  }, [dispatch, locale, user.id]);

  const handleBadgeTooltipToggle =
    (badgeIndex: number, categoryIndex: number) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();

      const isAlreadyOpen = badgeIndex === selectedBadgeIndex && selectedCategoryIndex === categoryIndex;
      if (isAlreadyOpen) {
        onCloseTooltip();
        return;
      }

      setSelectedCategoryIndex(categoryIndex);
      setSelectedBadgeIndex(badgeIndex);
    };

  const onCloseTooltip = () => {
    setSelectedCategoryIndex(null);
    setSelectedBadgeIndex(null);
  };

  const isLocked = (id: string) => !badges.unlocked.some((badge) => id === badge.id);

  const data = badges.list;
  const primaryColor = colors[currentLevelIndex] ? colors[currentLevelIndex] : colors[colors.length - 1];

  const getNextName = (badge: BadgeData, group: Group) => (badge.tier === 1 ? group.next : '');

  return (
    <div className='h-full p-3'>
      <ProfileHeader
        primaryColor={primaryColor}
        user={user}
      />

      <div className='px-0 py-6'>
        {data.map((group, groupIdx: number) => (
          <div
            className='grid items-center grid-cols-4 pb-9'
            key={group.id}
          >
            <Badge src={`${API_URL}/${group.picture}`} />
            {group.badges.map((badge, index) => (
              <div
                key={badge.id}
                className='flex px-2'
              >
                <Tooltip
                  className='z-20 p-0 border-r rounded border-blue-50'
                  arrowClassName='after:border-t-color-important-[#eff6ff] after:border-b-color-important-[#eff6ff] before:border-t-color-important-[#eff6ff] before:border-b-color-important-[#eff6ff]'
                  placement='bottom'
                  content={
                    <div className='z-20 w-full px-5 py-3 mx-auto my-0 text-left transition-opacity bg-blue-50'>
                      <div className='text-base font-bold text-turquoiseBlue'>
                        {isLocked(badge.id) ? group.title : badge.title}
                      </div>
                      <div className='text-xs font-medium text-pinkishGrey'>
                        {isLocked(badge.id) ? '' : badge.subtitle}
                      </div>
                      <div className='px-0 py-1 text-xs'>
                        {isLocked(badge.id) ? group.description : badge.description}
                      </div>
                      <div className='text-xs opacity-80 text-pinkishGrey'>
                        {isLocked(badge.id) ? getNextName(badge, group) : badge.next}
                      </div>
                    </div>
                  }
                >
                  <Badge
                    src={`${API_URL}/${group.picture}`}
                    title={badge.title}
                    borderColor={colors[index]}
                    locked={isLocked(badge.id)}
                    onClick={handleBadgeTooltipToggle(index, groupIdx)}
                  />
                </Tooltip>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
