import classnames from 'classnames';
import { RadialProgress } from 'components/RadialProgress';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import tinyColor from 'tinycolor2';
import { CrashCourseHeaderProps as Props } from './CrashCourseHeader.types';

export const CrashCourseHeader: React.FC<Props> = ({
  unlocked = false,
  done = false,
  crashCourseId,
  learningTestId,
  crashCourseTestScore,
  crashCourseUnlocked,
  crashCoursePassed,
  color = 'red',
  startText,
  onClick,
}) => {
  const colorOpacity = unlocked || done ? 1 : 0.3;
  const lightColor = tinyColor(color).brighten(20).toString();
  const handleButtonClick = () => (unlocked && learningTestId ? onClick('skillTest', learningTestId) : null);

  const wrapperStyles: React.CSSProperties = { color };

  const radialProgressLearningInformation = {
    id: crashCourseId,
    unlocked: crashCourseUnlocked,
    passed: crashCoursePassed,
  };

  const icon = done ? (
    <RadialProgress
      key={`radial-progress-${crashCourseId}`}
      value={crashCourseTestScore}
      learningInformation={radialProgressLearningInformation}
      primaryColor={color}
      lockedColor={color}
      medium
      className='text-xs'
    />
  ) : (
    <SvgIcon
      className={classnames('mx-auto', {
        'm-0 mb-1 ml-2': unlocked,
      })}
      name={unlocked ? 'lock-unlocked' : 'lock-locked'}
      color='#ffffff'
      viewBox='0 0 18 27'
      size={25}
    />
  );

  return (
    <div className='flex-col w-full'>
      <div className='relative flex flex-row items-end'>
        <div
          className='flex justify-center flex-1 h-5'
          style={wrapperStyles}
        >
          <div
            className={classnames(
              'absolute flex items-center justify-center rounded-full w-10 h-10 cursor-pointer z-10 top-0',
              {
                'bg-transparent': done,
                'cursor-auto': !unlocked,
                'w-auto p-2.5 rounded-3xl': unlocked && !done,
                'level-test-unlocked-wt': unlocked && !done,
              },
            )}
            onClick={handleButtonClick}
            style={{ backgroundColor: unlocked || done ? color : lightColor }}
          >
            {unlocked && !done && <p className='p-0 m-0 text-sm text-white'>{startText}</p>}
            {icon}
          </div>
        </div>
      </div>
      <div
        className='w-full h-0.5'
        style={{ backgroundColor: lightColor }}
      />
      <div
        className='flex justify-center'
        style={wrapperStyles}
      >
        <span
          className='w-1 h-20 ml-1 transform translate-y-0 -translate-x-5/10'
          style={{ backgroundColor: color, opacity: colorOpacity }}
        />
      </div>
    </div>
  );
};
