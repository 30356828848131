import classNames from 'classnames';
import React from 'react';
import classes from './Typography.module.scss';
import { TypographyProps } from './Typography.types';
import { RichTypography } from './rich-typography';

export const Typography: React.FC<TypographyProps> = ({
  variant = 'body1',
  component,
  className,
  children,
  rich,
  ...props
}) => {
  const variantMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subtitle1: 'h6',
    subtitle2: 'h6',
    body1: 'p',
    body2: 'p',
    caption: 'p',
  }[variant];

  const componentTag = component ?? variantMapping;

  const computedProps = {
    className: classNames(classes.root, classes[variant], className),
    ...props,
  };

  if (rich) {
    return <RichTypography {...computedProps}>{children?.toString()}</RichTypography>;
  }

  return React.createElement(componentTag, computedProps, children);
};
