/*
 *
 * Combine all sagas in the this file
 * and export them.
 *
 */

import ProfileSagas from 'containers/Profile/sagas';
import { authenticationPageSaga } from 'containers/authentication';
import SettingsSaga from 'containers/settings/Settings.sagas';
import NewsfeedSaga from 'pages/community/newsfeed/Newsfeed.sagas';
import { crashCoursePageSaga } from 'pages/crash-course';
import event from 'pages/event/Event.sagas';
import HomeSagas from 'pages/home/Home.sagas';
import { lepaPageSaga } from 'pages/lepa';
import LTISaga from 'pages/lti/LTI.sagas';
import RegisterSaga from 'pages/register/Register.sagas';
import SearchSaga from 'pages/search/Search.sagas';
import { skillPageSaga } from 'pages/skill';
import { rootSaga as featureFlagsSaga } from './feature-flags';

const sagas = [
  authenticationPageSaga,
  HomeSagas,
  lepaPageSaga,
  crashCoursePageSaga,
  skillPageSaga,
  SettingsSaga,
  ProfileSagas,
  RegisterSaga,
  NewsfeedSaga,
  LTISaga,
  SearchSaga,
  featureFlagsSaga,
  event,
];

export default sagas;
