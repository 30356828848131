import { PREVIOUS_LOCATION_COOKIE } from 'constants/global';
import { FormatContentOption } from 'containers/FormatScreen/types';
import { actions as profileActions } from 'containers/Profile';
import { User } from 'containers/authentication';
import { personalityTest as personalityTestData } from 'data/personality-test';
import cookie from 'js-cookie';
import _map from 'lodash/map';
import _shuffle from 'lodash/shuffle';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useTransition } from 'react-spring';
import { Store } from 'store/types';
import { Playground } from 'templates/playground';
import { PlaygroundProps } from 'templates/playground/Playground.types';
import { actions } from '../PersonalityTest.ducks';
import { Persona, PersonalityTestStatus } from '../PersonalityTest.types';
import { Tag } from './tag';

function isPersona(label: string): label is Persona {
  return Object.values(Persona).includes(label as Persona);
}

const [, tagsTest] = personalityTestData;

export const Tags: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const persona = useSelector<Store, User['persona']>(({ profile }) => profile.user.persona);
  const userId = useSelector<Store, User['id']>(({ profile }) => profile.user.id);
  const selectedCollectionsIDs = useSelector<Store, string[]>(
    ({ personalityTest }) => personalityTest.test?.collections ?? [],
  );
  const selectedTagsIds = useSelector<Store, string[]>(({ personalityTest }) => personalityTest.test?.tags ?? []);
  const [shuffledTags, setShuffledTags] = useState<FormatContentOption[]>([]);

  useEffect(() => {
    const collectionTags: FormatContentOption[] = selectedCollectionsIDs.map((collectionID) => {
      const tag = tagsTest.content.options.find((option) => option.groupId === collectionID);
      if (!tag) {
        throw new Error(`Tag for collection ${collectionID} was not found.`);
      }
      return tag;
    });
    setShuffledTags(_shuffle(collectionTags));
  }, [selectedCollectionsIDs]);

  useEffect(() => {
    if (!persona) {
      return;
    }

    dispatch(actions.setStatus(PersonalityTestStatus.DONE));
    const previousLocation = cookie.get(PREVIOUS_LOCATION_COOKIE);
    const redirectTo = previousLocation ?? '/';
    history.push(redirectTo);
  }, [dispatch, history, persona]);

  const tagsTransitions = useTransition(shuffledTags, {
    unique: true,
    trail: 400 / shuffledTags.length,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    keys: _map(shuffledTags, 'id'),
  });

  if (selectedCollectionsIDs.length <= 0) {
    return <Redirect to='/personality/test/collections' />;
  }

  const breadcrumbs: PlaygroundProps['breadcrumbs'] = {
    crumbs: [
      {
        label: <FormattedMessage id={tagsTest.title} />,
        location: '/personality/test/tags',
      },
    ],
  };

  const handleSubmitTest = () => {
    const selectedTags = shuffledTags.filter((tag) => selectedTagsIds.includes(tag.id));
    const [selectedTag] = selectedTags;

    if (!isPersona(selectedTag.label)) {
      return;
    }

    dispatch(
      profileActions.updateUser({
        userId,
        persona: selectedTag.label,
      }),
    );
  };

  const handleSelectTag = (tag: FormatContentOption) => () => {
    dispatch(actions.setTags([tag.id]));
  };

  const isSelected = (tagId: string) => selectedTagsIds.includes(tagId);

  const canSubmitTest = Boolean(selectedTagsIds.length >= 1);

  return (
    <Playground
      className='justify-center'
      breadcrumbs={breadcrumbs}
      heading={
        <p className='pt-0 px-3.5 pb-5'>
          <FormattedMessage id={tagsTest.content.question} />
        </p>
      }
    >
      <section className='px-3 pt-0 pb-3 overflow-y-auto h-80'>
        {tagsTransitions(
          (styles, item) =>
            item && (
              <Tag
                key={item.id}
                style={styles}
                tag={item}
                selected={isSelected(item.id)}
                onClick={handleSelectTag(item)}
              />
            ),
        )}
      </section>
      <Playground.Actions
        className='pt-20 !flex-grow-0'
        progress={{ current: 2, steps: 2, variant: 'circular' }}
        next={{ onClick: handleSubmitTest, disabled: !canSubmitTest }}
      />
    </Playground>
  );
};
