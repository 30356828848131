import { USER_ROLES } from 'constants/global';
import { getRoleAccessLevel } from 'containers/authentication';
import { LepaTypes } from 'pages/home/Home.types';
import { Lepa, QuestionsOnlyLepa, SingleLearningLepa, TextLepa } from 'pages/lepa';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { LearningTestTypes } from '../Tests.types';
import { isCrashCourse, isLegacy } from '../helpers';
import { getLevelTestTipData, getSkillTestTipData } from './tipDataHelpers';
import { NavigateAwayParams, TestConfiguration } from './types';

const emptyTestConfiguration: TestConfiguration = {
  withBackNavigation: false,
  getNavigateBackRoute: () => '',
  getTitle: () => '',
  getNavigateAwayPathAndState: () => ({ path: '' }),
};

const defaultTestContext = { levelId: -1, skillId: -1, moduleId: -1 };

export const isLearningMoment = (lepa: Lepa): lepa is TextLepa | SingleLearningLepa | QuestionsOnlyLepa =>
  [LepaTypes.TEXT, LepaTypes.SINGLE_LEARNING, LepaTypes.QUESTIONS_ONLY].includes(lepa.type);

export const useTestConfiguration = (testType: LearningTestTypes, lepa?: Lepa) => {
  const intl = useIntl();
  const { role } = useSelector(({ profile }: Store) => profile.user);
  const roleAccessLevel = getRoleAccessLevel(role);
  const isPreviewUser = roleAccessLevel === USER_ROLES.PREVIEW;

  if (!lepa) {
    return emptyTestConfiguration;
  }

  const getLegacyModuleTestTitle = ({
    levelId,
    skillId,
    moduleId,
  }: {
    levelId: number;
    skillId: number;
    moduleId: number;
  }): string => {
    if (!isLegacy(lepa)) {
      return '';
    }
    return (
      lepa.content.levels
        .find((level) => level.id === levelId)
        ?.skills.find((skill) => skill.id === skillId)
        ?.modules.find((module) => module.id === moduleId)?.title ?? ''
    );
  };

  const getLegacySkillTestCompletionPopupTitle = ({
    levelId,
    skillId,
  }: {
    levelId: number;
    skillId: number;
  }): string => {
    if (!isLegacy(lepa)) {
      return '';
    }
    return (
      lepa.content.levels.find((level) => level.id === levelId)?.skills.find((skill) => skill.id === skillId)?.title ??
      ''
    );
  };

  const getLegacyLevelTestCompletionPopupTitle = ({ levelId }: { levelId: number }): string => {
    if (!isLegacy(lepa)) {
      return '';
    }
    return lepa.content.levels.find((level) => level.id === levelId)?.title ?? '';
  };

  const getCrashCourseModuleTestTitle = ({ moduleId }: { moduleId: number }): string => {
    if (!isCrashCourse(lepa)) {
      return '';
    }
    return lepa.content.modules.find((module) => module.id === moduleId)?.title ?? '';
  };

  const legacyTestConfiguration: TestConfiguration = {
    withBackNavigation: false,
    getNavigateBackRoute: ({ levelId, skillId } = defaultTestContext) => {
      if (testType === LearningTestTypes.MODULE) {
        return `/learning-path/${lepa.id}/level/${String(levelId)}/skill/${String(skillId)}`;
      }
      return `/learning-path/${lepa.id}`;
    },

    getTitle: ({ levelId, skillId, moduleId } = defaultTestContext) =>
      ({
        [LearningTestTypes.MODULE]: getLegacyModuleTestTitle({ levelId, skillId, moduleId }),
        [LearningTestTypes.SKILL]: getLegacySkillTestCompletionPopupTitle({ levelId, skillId }),
        [LearningTestTypes.LEVEL]: getLegacyLevelTestCompletionPopupTitle({ levelId }),
        [LearningTestTypes.RETENTION]: intl.formatMessage({ id: 'formats.titles.retentionTest' }),
        [LearningTestTypes.LEARNING_MOMENT]: '',
      }[testType]),

    getNavigateAwayPathAndState: {
      [LearningTestTypes.MODULE]: ({ testContext = defaultTestContext }: NavigateAwayParams = {}) => {
        const { levelId, skillId, moduleId } = testContext;
        const path = `/learning-path/${lepa.id}/level/${String(levelId)}/skill/${String(skillId)}`;
        const state = {
          moduleID: String(moduleId),
        };
        return { path, state };
      },
      [LearningTestTypes.SKILL]: ({
        testContext = defaultTestContext,
        passed = true,
        threshold = 100,
      }: NavigateAwayParams = {}) => {
        const { levelId, skillId } = testContext;
        const path = `/learning-path/${lepa.id}`;
        const tip = getSkillTestTipData({
          intl,
          lepa,
          levelId,
          skillId,
          passed,
          threshold,
        });
        const state = { levelId: String(levelId), tip };
        return { path, state };
      },
      [LearningTestTypes.LEVEL]: ({
        testContext = defaultTestContext,
        passed = true,
        threshold = 100,
      }: NavigateAwayParams = {}) => {
        const { levelId } = testContext;
        const path = `/learning-path/${lepa.id}`;
        const tip = getLevelTestTipData({
          intl,
          lepa,
          levelId,
          passed,
          threshold,
        });
        const state = tip ? { levelId: String(levelId), tip } : undefined;
        return { path, state };
      },
      [LearningTestTypes.RETENTION]: () => {
        const path = `/learning-path/${lepa.id}`;
        return { path };
      },
      [LearningTestTypes.LEARNING_MOMENT]: () => ({ path: '/' }),
    }[testType],
  };

  const crashCourseTestConfiguration: TestConfiguration = {
    withBackNavigation: false,
    getNavigateBackRoute: () => `/crash-course/${lepa.id}`,

    getTitle: ({ moduleId } = defaultTestContext) =>
      ({
        [LearningTestTypes.MODULE]: getCrashCourseModuleTestTitle({ moduleId }),
        [LearningTestTypes.SKILL]: intl.formatMessage({ id: 'formats.titles.skillTest' }),
        [LearningTestTypes.LEVEL]: '',
        [LearningTestTypes.RETENTION]: intl.formatMessage({ id: 'formats.titles.retentionTest' }),
        [LearningTestTypes.LEARNING_MOMENT]: '',
      }[testType]),

    getNavigateAwayPathAndState: {
      [LearningTestTypes.MODULE]: ({ testContext = defaultTestContext }: NavigateAwayParams = {}) => {
        const { moduleId } = testContext;
        const path = `/crash-course/${lepa.id}`;
        const state = { moduleID: String(moduleId) };
        return { path, state };
      },
      [LearningTestTypes.SKILL]: ({
        testContext = defaultTestContext,
        passed = true,
        threshold = 100,
      }: NavigateAwayParams = {}) => {
        const { levelId, skillId } = testContext;
        const path = `/crash-course/${lepa.id}`;
        const tip = getSkillTestTipData({
          intl,
          lepa,
          levelId,
          skillId,
          passed,
          threshold,
        });
        const state = { levelId: String(levelId), tip };
        return { path, state };
      },
      [LearningTestTypes.LEVEL]: () => ({ path: '' }),
      [LearningTestTypes.RETENTION]: () => {
        const path = `/crash-course/${lepa.id}`;
        return { path };
      },
      [LearningTestTypes.LEARNING_MOMENT]: () => ({ path: '' }),
    }[testType],
  };

  const learningMomentTestConfiguration: TestConfiguration = {
    withBackNavigation: lepa.type === LepaTypes.TEXT,
    getNavigateBackRoute: () => '/',

    getTitle: () => lepa.title,

    getNavigateAwayPathAndState: () => {
      const path = isPreviewUser ? '/' : `/learning-moment/${lepa.id}`;
      return { path };
    },
  };

  const videoTestConfiguration: TestConfiguration = {
    withBackNavigation: false,
    getNavigateBackRoute: () => {
      throw new Error('Not implemented');
    },
    getTitle: () => {
      throw new Error('Not implemented');
    },
    getNavigateAwayPathAndState: () => {
      const path = `/video/${lepa.id}`;
      return { path };
    },
  };

  const cardConfiguration: { [origin in LepaTypes]: TestConfiguration } = {
    [LepaTypes.LEGACY]: legacyTestConfiguration,
    [LepaTypes.CRASH_COURSE]: crashCourseTestConfiguration,
    [LepaTypes.VIDEO]: videoTestConfiguration,
    [LepaTypes.TEXT]: learningMomentTestConfiguration,
    [LepaTypes.QUESTIONS_ONLY]: learningMomentTestConfiguration,
    [LepaTypes.SINGLE_LEARNING]: learningMomentTestConfiguration,
  };
  return cardConfiguration[lepa.type];
};
