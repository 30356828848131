import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetLTICourseSettingsSuccessPayload, LTIState } from './LTI.types';

const initialState: LTIState = {
  goodhabitz: null,
  error: null,
};

const slice = createSlice({
  name: 'lti',
  initialState,
  reducers: {
    getCourseSignedRequest: (state, _action: PayloadAction<string>) => ({
      ...state,
      goodhabitz: null,
      error: null,
    }),
    getCourseSignedRequestSuccess: (state, action: PayloadAction<GetLTICourseSettingsSuccessPayload>) => {
      const { provider, ...signedAuthCredentials } = action.payload;
      if (!provider) {
        return state;
      }

      return {
        ...state,
        [provider]: signedAuthCredentials,
      };
    },
    getCourseSignedRequestError: (state, action: PayloadAction<Error>) => ({
      ...state,
      error: String(action.payload),
    }),
  },
});

export const { actions } = slice;
export default slice.reducer;
