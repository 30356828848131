import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createAuthMiddleware, cretePreviewMiddleware } from 'containers/authentication';
import { walkthroughApi } from 'containers/walkthrough';
import { createBrowserHistory } from 'history';
import { createActiveEventMiddleware } from 'pages/event';
import { mobieChatApi } from 'pages/home/popups/mobie-chat';
import { lepaApi } from 'pages/lepa/Lepa.api';
import { createRegisterMiddleware } from 'pages/register/Register.ducks';
import { scormApi } from 'pages/scorm/Scorm.api';
import { testsApi } from 'pages/tests/Tests.api';
import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { analyticsApi } from '../containers/Analytics/Analytics.api';
import combinedReducers from './combinedReducers';
import combinedSagas from './combinedSagas';
import { dynamicConfigurationApi } from './dynamic-configuration';
import { rootApi, rootRestApi } from './root.api';

export const history = createBrowserHistory();

// Build the middleware for intercepting and dispatching navigation actions
const sagaMiddleware = createSagaMiddleware();
const authMiddleware = createAuthMiddleware(history);
const registerMiddleware = createRegisterMiddleware(history);
const previewMiddleware = cretePreviewMiddleware(history);
const activeEventMiddleware = createActiveEventMiddleware(history);

const middleware = [
  routerMiddleware(history),
  sagaMiddleware,
  rootRestApi.middleware,
  rootApi.middleware,
  dynamicConfigurationApi.middleware,
  analyticsApi.middleware,
  scormApi.middleware,
  mobieChatApi.middleware,
  lepaApi.middleware,
  testsApi.middleware,
  walkthroughApi.middleware,
  previewMiddleware,
  authMiddleware,
  registerMiddleware,
  activeEventMiddleware,
];

const isProductionEnvironment = process.env.NODE_ENV === 'production';
const isTestingEnvironment = process.env.NODE_ENV === 'test';

if (!isProductionEnvironment && !isTestingEnvironment) {
  middleware.push(createLogger());
}
// @ts-ignore: Middleware
export const enhancer = composeWithDevTools(applyMiddleware(...middleware));

const store = configureStore({
  reducer: combinedReducers(history),
  devTools: !isProductionEnvironment && !isTestingEnvironment,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['profile/avatarUpload'],
      },
    }).concat(...middleware),
});

export type RootState = ReturnType<typeof store.getState>;

combinedSagas.forEach((saga) => {
  sagaMiddleware.run(saga);
});

export default store;
