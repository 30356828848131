import { PayloadAction } from '@reduxjs/toolkit';
import { loader } from 'graphql.macro';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import requestGraphql from 'util/requestGraphql';
import { actions } from './Skill.ducks';
import { GetModuleCreditReachedPayload } from './Skill.types';

const getCrashCourseRelatedInformationQuery = loader('./GraphQL/get-module-credit-reached.graphql');

function* getModuleCreditReached({
  payload,
}: PayloadAction<GetModuleCreditReachedPayload>): Generator<StrictEffect, void> {
  const body = {
    query: getCrashCourseRelatedInformationQuery,
    variables: { lepaId: payload.lepaId },
  };

  const { data, errors } = (yield call(requestGraphql, body)) as {
    data: {
      users: {
        me: {
          moduleCreditReached: boolean;
        };
      };
    };
    errors: Error;
  };

  if (errors) {
    yield put(actions.getModuleCreditReachedError(errors));
  } else {
    yield put(
      actions.getModuleCreditReachedSuccess({
        moduleCreditReached: data.users.me.moduleCreditReached,
      }),
    );
  }
}

export function* rootSaga() {
  yield takeLatest(actions.getModuleCreditReached.type, getModuleCreditReached);
}
