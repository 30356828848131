import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Fab } from 'components/FloatingActionButton';
import { Button } from 'components/button';
import { Media } from 'components/media';
import { PageContainer } from 'components/page-container';
import { SvgIcon } from 'components/svg-icon';
import { Typography } from 'components/typography';
import { API_URL } from 'constants/endpoints';
import { useTrackEventMutation } from 'containers/Analytics/Analytics.api';
import { EventTypes } from 'containers/Analytics/internal/InternalAnalytics.types';
import { Locale, defaultLocale } from 'containers/language-provider';
import React, { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { Store } from 'store/types';
import theme from 'theme';
import { prettifyDate } from 'util/dateFormater';
import { actions } from '../Newsfeed.ducks';
import { NewsItemFullViewContent, Post, SharedPollContentProps, TextContentProps } from '../Newsfeed.types';
import { PollAnswerOptions, SharedResults } from '../news-item';
import { YT_VARS } from '../news-item/NewsItem';

export const NewsPostFullView: React.FC = () => {
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [selectedAnswerID, setSelectedAnswerID] = useState<string | null>(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const [trackEvent] = useTrackEventMutation();

  const {
    account: { id: accountId, primaryColor = theme.colors.turquoiseBlue },
    user: { role },
  } = useSelector(({ profile }: Store) => profile);

  const posts = useSelector(({ newsfeed }: Store) => newsfeed.posts);
  const locale = useSelector<Store, Locale>(({ profile }) => profile.user.locale || defaultLocale);
  const hasMedia = selectedPost && (selectedPost.videoURL || selectedPost.imageURL);
  const params = history.location.pathname.split('/');
  const postID = params[params.length - 1];

  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);
  const isCommunityEnabled = useFeatureFlag(FlagKeys.COMMUNITY_CHAT) && dynamicConfiguration?.menu.community.show;

  const color = isCommunityEnabled ? primaryColor : theme.colors.turquoiseBlue;

  const goToNewsfeed = useCallback(() => {
    const path = isCommunityEnabled ? '/community/newsfeed' : '/newsfeed';
    history.push(path);
  }, [history, isCommunityEnabled]);

  useEffect(() => {
    const post = posts.find((p) => p.id === postID);

    if (!post) {
      goToNewsfeed();
      return;
    }

    void (async () => {
      await trackEvent({
        eventType: EventTypes.OPEN_NEWSFEED_POST,
        accountId,
        properties: {
          postId: post.id,
          type: post.type,
          role,
        },
      });
    })();

    setSelectedPost(post);
  }, [accountId, goToNewsfeed, history, postID, posts, role, trackEvent]);

  const submitPoll = () => {
    if (!selectedPost || !selectedAnswerID) {
      return;
    }
    dispatch(actions.answerPoll({ answerID: selectedAnswerID, postID: selectedPost.id }));
  };

  const answerPollQuestionHandler = (answerID: string) => () => {
    setSelectedAnswerID(answerID);
  };

  const handleLikeClick = () => {
    if (!selectedPost) {
      return null;
    }

    const wasLiked = !!selectedPost.like;
    return dispatch(actions.likePost({ post: selectedPost, like: !wasLiked }));
  };

  const onCloseHandler = () => {
    goToNewsfeed();
  };

  const renderMedia = () => {
    if (!selectedPost) {
      return null;
    }

    if (selectedPost.videoURL) {
      return (
        <div className='w-full mb-10 h-72'>
          <ReactPlayer
            url={selectedPost.videoURL}
            width='100%'
            height='100%'
            config={YT_VARS}
            controls
          />
        </div>
      );
    }

    if (selectedPost.imageURL) {
      return (
        <div className='relative w-full mb-10 pb-9/16'>
          <Media
            className='absolute'
            src={`${API_URL}/${selectedPost.imageURL}`}
            alt='post'
          />
        </div>
      );
    }

    return null;
  };

  if (!selectedPost) {
    return null;
  }
  const hasSubmited = Array.isArray(selectedPost.answers) && selectedPost.answers.find((ans) => ans.answered);
  const isShared = Boolean(selectedPost.sharedAt);

  const showLikeButton = !isShared && selectedPost.likeable;
  const isPersonalCoachItem = !isShared && !selectedPost.likeable;
  const prettifiedDate = prettifyDate(selectedPost.publishedAt, locale);

  return (
    <PageContainer className='w-full h-full pb-5 overflow-y-auto bg-white'>
      <article>
        <nav className='flex flex-row justify-between mb-1.5 p-5 sticky top-0 z-10 bg-white'>
          <span
            onClick={onCloseHandler}
            className='cursor-pointer'
          >
            <SvgIcon
              name='arrowBack'
              color='#555555'
              size={20}
            />
          </span>
          <span className='text-sm select-none text-pinkishGrey'>{prettifiedDate}</span>
        </nav>

        {hasMedia && !isShared && renderMedia()}

        <div className='p-5 pt-0 overflow-auto'>
          <h2
            className='mt-0 text-xl font-medium break-words select-none mb-7'
            style={{ color }}
          >
            {selectedPost.title}
          </h2>

          {
            {
              poll: (
                <PollContent
                  selectedPost={selectedPost}
                  onAnswerClick={!hasSubmited ? answerPollQuestionHandler : undefined}
                  onSubmit={submitPoll}
                  selectedAnswerID={selectedAnswerID}
                  disabled={!!hasSubmited || !selectedAnswerID}
                  color={color}
                />
              ),

              text: (
                <TextContent
                  selectedPost={selectedPost}
                  isPersonalCoachItem={isPersonalCoachItem}
                />
              ),
            }[selectedPost.type]
          }
        </div>
      </article>

      {showLikeButton && (
        <Fab
          position={{ x: 'right', y: 'bottom' }}
          onClick={handleLikeClick}
          backgroundColor={selectedPost.like ? theme.colors.green : color}
        >
          <SvgIcon
            name='thumbs-up'
            color='#FFFFFF'
            size={22}
            className='absolute transform top-5/10 left-5/10 -translate-x-5/10 -translate-y-5/10'
          />
        </Fab>
      )}
    </PageContainer>
  );
};

const TextContent: React.FC<TextContentProps> = ({ selectedPost, isPersonalCoachItem }) => (
  <>
    <div className='flex flex-row'>
      {isPersonalCoachItem && (
        <div className='flex items-end'>
          <SvgIcon
            name={'clipboard'}
            color={theme.colors.grey2}
            size={26}
          />
        </div>
      )}
      <h4 className='pt-0 mt-5 mb-1 text-base font-light break-words text-grey3'>{selectedPost.subtitle}</h4>
    </div>
    <Typography
      className='overflow-hidden text-sm font-medium select-none text-greyishBrown'
      rich
    >
      {selectedPost.description}
    </Typography>
  </>
);

const SharedPollContent: React.FC<SharedPollContentProps> = ({ selectedPost }) => {
  if (!selectedPost.answers && !Array.isArray(selectedPost.answers)) {
    return null;
  }

  return (
    <>
      <TextContent selectedPost={selectedPost} />

      <h4 className='mb-0 font-light text-grey3'>Poll results:</h4>
      <SharedResults post={selectedPost} />
    </>
  );
};

const PollContent: React.FC<NewsItemFullViewContent> = ({
  color,
  selectedPost,
  onAnswerClick,
  onSubmit,
  disabled,
  selectedAnswerID,
}) => {
  if (selectedPost.sharedAt) {
    return (
      <SharedPollContent
        selectedPost={selectedPost}
        disabled
      />
    );
  }

  const buttonColor = disabled ? theme.colors.grey2 : color;

  return (
    <>
      <PollAnswerOptions
        onAnswerClick={onAnswerClick}
        post={selectedPost}
        selectedAnswerID={selectedAnswerID}
        color={color}
      />

      <div className='flex content-start mx-0 my-7'>
        <Button
          variant='outlined'
          disabled={disabled}
          onClick={onSubmit}
          style={{ color: buttonColor }}
          text='Submit'
        />
      </div>
    </>
  );
};
