import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Loading } from 'components/Loading';
import { VideoFormat } from 'containers/FormatScreen/Formats';
import { defaultLocale } from 'containers/language-provider';
import { useModalQueue } from 'containers/modal-controller';
import { LepaTypes } from 'pages/home/Home.types';
import { useStartLepaQuery } from 'pages/lepa/Lepa.api';
import { enqueueLearningPathCompletionModals } from 'pages/tests';
import { useSubmitLearningFormatAnswerMutation } from 'pages/tests/Tests.api';
import { LearningTestTypes } from 'pages/tests/Tests.types';
import { useTestConfiguration } from 'pages/tests/use-test-configuration';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import { Store } from 'store/types';
import theme from 'theme';
import { URLParams } from './Video.types';

export const Video: React.FC = () => {
  const { videoId } = useParams<URLParams>();
  const history = useHistory();
  const { enqueueModal } = useModalQueue();
  const { user, account } = useSelector(({ profile }: Store) => profile);
  const { id: userId, locale } = user;
  const { id: accountId } = account;
  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);
  const isEnabledLearningPathRating = dynamicConfiguration?.plugins.learningPathRating ?? false;
  const { data: videoLepa, isFetching } = useStartLepaQuery({
    userId: parseInt(userId, 10),
    lepaId: parseInt(videoId, 10),
    locale: locale ?? defaultLocale,
  });
  const testConfiguration = useTestConfiguration(LearningTestTypes.LEARNING_MOMENT, videoLepa);

  const [submitLearningFormatAnswer, { data: answerData }] = useSubmitLearningFormatAnswerMutation();
  const isFetchingWithoutAnswer = isFetching && !answerData;

  const startTime = useMemo(() => Date.now(), []);

  if (isFetchingWithoutAnswer) {
    return <Loading />;
  }

  if (videoLepa?.type !== LepaTypes.VIDEO) {
    return <p>Wrong lepa type</p>;
  }

  const format = videoLepa.content.formats[0];

  if (!videoId || !format) {
    return <p>Something went wrong</p>;
  }

  const handleSubmitAnswer = async () => {
    await submitLearningFormatAnswer({
      learningPathId: parseInt(videoId, 10),
      learningFormatId: format.id,
      body: {
        formatType: 'video',
        answer: format.id,
        startTime,
        endTime: Date.now(),
      },
    });
    const { path, state } = testConfiguration.getNavigateAwayPathAndState();
    history.push(path, state);
    enqueueLearningPathCompletionModals({
      isEnabledLearningPathRating: isEnabledLearningPathRating && user.role !== 'preview',
      enqueueModal,
      lepaId: videoId,
      isCertification: videoLepa.isCertification,
      title: videoLepa.title,
      color: videoLepa.color ?? theme.colors.turquoiseBlue,
      isLearningMoment: true,
    });
  };

  return (
    <>
      {format?.url && (
        <VideoFormat
          url={format.url}
          isFullscreen
          onNavigateAway={handleSubmitAnswer}
        />
      )}
    </>
  );
};
