import * as React from 'react';
import { TestBannerProps } from './types';

const TestBanner: React.FC<TestBannerProps> = ({ text, color, ...rest }) => (
  <svg
    width='157px'
    height='28px'
    viewBox='0 0 157 28'
    {...rest}
  >
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <polygon
        fill={color}
        points='0 0 9.40022963 13.999 0 28 157 28 147.600933 13.999 157 0'
      />
      <text
        fontFamily='.NotoSans'
        fontSize='11'
        fontWeight='normal'
        fill='#FFFFFF'
        textAnchor='middle'
      >
        <tspan
          x='50%'
          y='65%'
        >
          {text}
        </tspan>
      </text>
    </g>
  </svg>
);

export default TestBanner;
