import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import classes from './Draggable.module.scss';
import { DraggableProps as Props } from './Draggable.types';

export const Draggable: React.FC<Props> = ({
  isDragging,
  className,
  hasDragOverlay,
  label,
  listeners,
  forwardRef,
  style,
}) => (
  <div
    className={classnames(
      classes.container,
      { [classes.dragging]: isDragging },
      { [classes.dragOverlay]: hasDragOverlay },
    )}
    style={style}
  >
    <div
      ref={forwardRef}
      className={classnames(className, classes.draggable)}
      aria-label='Draggable'
      {...listeners}
    >
      {label && <div className={classes.text}>{label}</div>}
      <div className={classes.handleWrapper}>
        <SvgIcon
          name='dragHandle'
          size={25}
        />
      </div>
    </div>
  </div>
);
