import { actions as profileActions } from 'containers/Profile';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Progression } from './Progression';
import selector from './selector';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getUserProgress: () => dispatch(profileActions.getUserProgress()),
});

export default connect(selector, mapDispatchToProps)(Progression);
