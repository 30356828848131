import { OverlayVariants } from 'components/Overlay/Overlay.types';
import { AnimationVariants, PopupVariantProps, PopupVariants } from './Popup.types';
import {
  BadgePopup,
  CleanPopup,
  ExperiencePointsPopup,
  FadedPopup,
  FullscreenPopup,
  GradientFullscreenPopup,
  MinimalPopup,
  PersonalityTestPopup,
  RoundedTopPopup,
  TooltipPopup,
} from './variants';

const getOverlayVariant = (variant: PopupVariants): OverlayVariants => {
  switch (variant) {
    case 'retention':
    case 'tip':
    case 'locked':
    case 'limitReach':
    case 'skillFail':
    case 'skillPassed':
    case 'levelPassed':
    case 'levelFail':
    case 'firstUnlock':
    case 'rubyUnlock':
    case 'silverUnlock':
    case 'goldUnlock':
    case 'diamondUnlock':
    case 'badge':
    case 'clean':
    case 'endPath':
    case 'unlockedFinalTest':
    case 'personalityTest':
    case 'lepaRating':
    case 'profileUpdate':
    case 'profileUpdateFeedback':
    case 'ratingFeedback':
    case 'faded':
      return 'faded';
    case 'minimal':
      return 'fullWhite';
    default:
      return 'none';
  }
};

const getPopupAnimation = (variant: PopupVariants): AnimationVariants => {
  switch (variant) {
    case 'fullscreen':
      return 'slide';
    case 'tooltip':
      return 'fade';
    default:
      return 'fade-scale';
  }
};

export const usePopupVariant = (variant: PopupVariants) => {
  const PopupVariant: React.FC<PopupVariantProps> = {
    retention: FadedPopup,
    tip: FadedPopup,
    locked: FadedPopup,
    limitReach: FadedPopup,
    skillFail: FadedPopup,
    skillPassed: FadedPopup,
    levelPassed: FadedPopup,
    levelFail: FadedPopup,
    firstUnlock: FadedPopup,
    rubyUnlock: FadedPopup,
    silverUnlock: FadedPopup,
    goldUnlock: FadedPopup,
    diamondUnlock: FadedPopup,
    unlockedFinalTest: FadedPopup,
    xp: ExperiencePointsPopup,
    minimal: MinimalPopup,
    badge: BadgePopup,
    clean: CleanPopup,
    endPath: RoundedTopPopup,
    gradientFullscreen: GradientFullscreenPopup,
    personalityTest: PersonalityTestPopup,
    fullscreen: FullscreenPopup,
    faded: FadedPopup,
    lepaRating: FadedPopup,
    ratingFeedback: FadedPopup,
    error: FadedPopup,
    profileUpdate: FadedPopup,
    profileUpdateFeedback: FadedPopup,
    tooltip: TooltipPopup,
  }[variant];

  const overlayVariant = getOverlayVariant(variant);
  const animationVariant = getPopupAnimation(variant);

  return { PopupVariant, overlayVariant, animationVariant };
};
