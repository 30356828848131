import { Locales } from 'constants/global';
import { DefaultModalProps, ModalTypes } from 'containers/modal-controller/ModalController.types';
import { BaseLeco, LecoTypes } from 'pages/home/Home.types';

export interface Event {
  id: number;
  lecoId: number;
  mediaSrc: string;
  mediaSrcSigned: string;
  text: string;
  isOpen: boolean;
  sessions: Session[];
  progress: number;
}

export interface EventContent extends BaseLeco {
  type: LecoTypes.EVENT;
  content: Event;
}

export interface GetEventPayload {
  lecoId: string;
  locale: Locales;
}

export interface GetEventSuccessPayload {
  event: EventContent;
}

export interface CreateRegistrationPayload {
  id: number;
}

export interface EventStore {
  event: EventContent | null;
  error: Error | null;
}

export interface ConfirmationLog {
  id: number;
  registrationId: number;
  performedById: number;
  type: 'confirmed' | 'unconfirmed';
}

export interface UserRegistration {
  id: number;
  sessionId: number;
  userId: number;
  cancelledAt: Date | null;
  createdAt: string;
  updatedAt: string;
  lastConfirmationLogId: number | null;
  lastConfirmationLog?: ConfirmationLog | null;
}

export interface Session {
  id: number;
  title: string;
  startAt: string;
  endAt: string;
  spots: number;
  registrationCount: number;
  cancellationCount: number;
  userRegistration: UserRegistration | Record<string, never>;
}

export interface SessionProps extends Omit<Session, 'cancellationCount'> {
  borderColor: string;
  className?: string;
  isSelected: boolean;
  isSelectable: boolean;
  onclick: (id: number) => void;
}

export type EventPopupsComponentProps = DefaultModalProps;

export interface EventSubmitConfirmedRegistrationModalData {
  type: ModalTypes.EVENT_SUBMIT_CONFIRMED_REGISTRATION;
}

export interface EventSubmitNoSpotsAvailableModalData {
  type: ModalTypes.EVENT_SUBMIT_NO_SPOTS_AVAILABLE;
}

export interface EventSubmitCancelRegistrationModalData {
  type: ModalTypes.EVENT_SUBMIT_CANCEL_REGISTRATION;
}

export interface EventSubmitUserIsAlreadyConfirmedData {
  type: ModalTypes.EVENT_SUBMIT_USER_IS_ALREADY_CONFIRMED;
}

export interface CalendarEvent
  extends Omit<Session, 'spots' | 'registrationCount' | 'cancellationCount' | 'userRegistration'> {
  description: string;
  location?: string;
  createdAt?: string;
}
export interface CalendarProps extends React.HTMLAttributes<HTMLDivElement> {
  event: CalendarEvent;
}

export enum CalendarProviderTypes {
  ICS_FILE = 'ics_file',
  GOOGLE = 'google',
  OUTLOOK = 'outlook',
  APPLE = 'apple',
}

interface CalendarIcon {
  src: React.ImgHTMLAttributes<HTMLImageElement>['src'];
  width: React.ImgHTMLAttributes<HTMLImageElement>['width'];
  height: React.ImgHTMLAttributes<HTMLImageElement>['height'];
}
export interface CalendarProvider {
  displayName: string;
  icon: CalendarIcon;
  getURL: (event: CalendarEvent) => string;
}

export type CalendarProviderHandler = {
  [type in CalendarProviderTypes]: CalendarProvider;
};

export interface MediaProps {
  src: string;
}
