import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store';
import { useTrackEventMutation } from '../Analytics.api';
import { EventTypes } from './InternalAnalytics.types';

export const UserNavigationTracker: React.FC = ({ children }) => {
  const history = useHistory();
  const { location } = history;

  const [trackEvent] = useTrackEventMutation();
  const { account, user } = useSelector(({ profile }: RootState) => profile);
  const { role } = user;
  const accountId = account?.id;
  const { pathname, search } = location;

  useEffect(() => {
    if (!pathname || !accountId) {
      return;
    }

    const trackPageView = async () =>
      trackEvent({
        eventType: EventTypes.NAVIGATE,
        accountId,
        properties: {
          pathname,
          search,
          role,
        },
      });

    void (async () => {
      await trackPageView();
    })();
  }, [accountId, pathname, role, search, trackEvent]);

  return <>{children}</>;
};
