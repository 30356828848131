import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetModuleCreditReachedPayload, GetModuleCreditReachedSuccess, SkillStore } from './Skill.types';

const initialState: SkillStore = {
  moduleCreditReached: true,
  error: null,
  isLoading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'skill',
  initialState,
  reducers: {
    getModuleCreditReached: (state, _action: PayloadAction<GetModuleCreditReachedPayload>) => {
      state.error = null;
      state.isLoading = true;
      state.loaded = false;
    },
    getModuleCreditReachedSuccess: (state, action: PayloadAction<GetModuleCreditReachedSuccess>) => {
      state.moduleCreditReached = action.payload.moduleCreditReached;
      state.isLoading = false;
      state.loaded = true;
    },
    getModuleCreditReachedError: (state, action: PayloadAction<Error>) => {
      state.error = action.payload.message || 'failed to load';
      state.isLoading = false;
      state.loaded = true;
    },
  },
});

export const { actions } = slice;
export default slice.reducer;
