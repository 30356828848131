import classnames from 'classnames';
import React from 'react';
import { CardProps as Props } from './Card.types';

/**
 * Cards group specific content and actions about a subject.
 */
export const Card = React.forwardRef<HTMLDivElement, Props>(({ className, ...rest }, ref) => {
  const clickable = Boolean(rest.onClick);
  return (
    <div
      data-testid='e2e-card'
      className={classnames('overflow-hidden rounded', { 'cursor-pointer': clickable }, className)}
      ref={ref}
      {...rest}
    />
  );
});
