import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import React from 'react';
import { PopupVariantProps } from '../Popup.types';

export const MinimalPopup: React.FC<PopupVariantProps> = ({ buttonText, children, onClose }) => (
  <PageContainer center>
    <div
      className='relative flex flex-col h-full p-4 bg-white rounded-lg w-9/10 text-greyishBrown transform-none'
      data-testid='e2e-popup'
    >
      <div className='flex flex-col items-center justify-center overflow-hidden'>{children}</div>

      {buttonText && (
        <div className='flex justify-center pt-4'>
          <Button
            variant='outlined'
            text={buttonText}
            onClick={onClose('button')}
          />
        </div>
      )}
    </div>
  </PageContainer>
);
