import { AnyAction, createSlice, Dispatch, MiddlewareAPI, PayloadAction } from '@reduxjs/toolkit';
import { actions as authActions } from 'containers/authentication/Authentication.ducks';
import { History } from 'history';
import { Store } from 'store/types';
import { CreateUserPayload, RegisterStore, ValidateInvitePayload, ValidateInviteSuccessPayload } from './index';

const initialState: RegisterStore = {
  isValidating: false,
  isValidToken: false,
  isCreating: false,
  error: '',
  invite: {
    id: '',
    firstName: '',
    lastName: '',
    job: '',
    email: '',
    password: '',
    image: '',
  },
};

const slice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    validateInvite: (state, _action: PayloadAction<ValidateInvitePayload>) => {
      state.isValidating = true;
    },
    validateInviteSuccess: (state, action: PayloadAction<ValidateInviteSuccessPayload>) => {
      const invite = action.payload.accounts.validateInvite;
      state.isValidating = false;
      state.isValidToken = true;
      state.invite = { ...invite, password: '', image: '' };
    },
    validateInviteError: (state, action: PayloadAction<Error>) => {
      state.isValidating = false;
      state.isValidToken = false;
      state.error = action.payload.message;
    },
    createUser: (state, _action: PayloadAction<CreateUserPayload>) => {
      state.isCreating = true;
    },
    createUserSuccess: (state) => {
      state.isCreating = false;
    },
    createUserError: (state, action: PayloadAction<Error>) => {
      state.isCreating = false;
      state.error = action.payload.message;
    },
  },
});

export const createRegisterMiddleware =
  (history: History) => (store: MiddlewareAPI<Dispatch, Store>) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === actions.createUserSuccess.type || action.type === actions.validateInviteError.type) {
      if (action.type === actions.validateInviteError.type) {
        // If the validation fails the token has been used or expired
        const error = { status: '409', name: 'Conflict', message: 'Already exists' };
        store.dispatch(authActions.loginError(error));
      }

      history.replace('/login');
    }
  };

export const { actions } = slice;
export default slice.reducer;
