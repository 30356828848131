import { COOKIE_DOMAIN } from 'constants/global';
import { SSO_KEY, TOKEN_MAX_AGE } from 'containers/authentication';
import cookie from 'js-cookie';

export function setCookieWithDefaults(
  key: string,
  value: string,
  defaults = {
    path: '/',
    expires: TOKEN_MAX_AGE * 2,
  },
) {
  cookie.set(key, value, defaults);
}

export function checkCookieSupport() {
  const key = 'test-cookie';
  const value = 'test-cookie-value';
  cookie.set(key, value);
  if (cookie.get(key) === value) {
    return true;
  }
  return false;
}

export function clearSSOCookies() {
  cookie.remove(SSO_KEY, { path: '/', domain: COOKIE_DOMAIN });
}

export function clearLegacyAccessTokenCookie() {
  const env = process.env.REACT_APP_NODE_ENV;
  if (!env) {
    return;
  }

  // eslint-disable-next-line no-underscore-dangle
  const __PROD__ = env === 'production';
  const hostname = __PROD__ ? '.mobietrain.com' : `.${env}.mobietrain.com`;

  cookie.remove('a_t', { path: '/', domain: '.localhost' });
  cookie.remove('a_t', { path: '/', domain: '.app.localhost' });
  cookie.remove('a_t', { path: '/', domain: '.cms.localhost' });
  cookie.remove('r_t', { path: '/', domain: '.localhost' });
  cookie.remove('r_t', { path: '/', domain: '.app.localhost' });
  cookie.remove('r_t', { path: '/', domain: '.cms.localhost' });

  const cmsHostname = `.cms${hostname}`;
  const appHostname = `.app${hostname}`;

  cookie.remove('a_t', { path: '/', domain: hostname });
  cookie.remove('a_t', { path: '/', domain: appHostname });
  cookie.remove('a_t', { path: '/', domain: cmsHostname });
  cookie.remove('r_t', { path: '/', domain: hostname });
  cookie.remove('r_t', { path: '/', domain: appHostname });
  cookie.remove('r_t', { path: '/', domain: cmsHostname });
}
