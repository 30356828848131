import { SpinnerBalls } from 'components/Loading';
import { BackButton } from 'components/back-button';
import { PageContainer } from 'components/page-container';
import { SearchBar } from 'components/search-bar';
import { Locale } from 'containers/language-provider/LanguageProvider.types';
import { Walkthrough, shouldShowWalkthrough, useWalkthrough } from 'containers/walkthrough';
import debounce from 'lodash/debounce';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStates, Store } from 'store/types';
import { useStorageState } from 'util/storage';
import { defaultLocale } from '../../containers/language-provider/LanguageProvider.helpers';
import { actions } from './Search.ducks';
import { SearchStore } from './Search.types';
import { RecentQueries } from './recent-queries';
import { SearchResults } from './search-results/SearchResults';

const MAX_RECENT_QUERIES = 10;

export const Search = () => {
  const dispatch = useDispatch();
  const search = useSelector<Store, SearchStore>(({ search: searchStore }) => searchStore);
  const locale = useSelector<Store, Locale>(({ profile }) => profile.user.locale || defaultLocale);

  const [searchText, setSearchText] = React.useState('');
  const [recentQueries = [], setRecentQueries] = useStorageState<string[]>({ key: 'search-queries', initialValue: [] });

  const { completedWalkthroughs, onWalkthroughEnd } = useWalkthrough();

  const addQueryToRecents = (value: string) => {
    const nextRecentQueries = [...recentQueries, value].slice(-MAX_RECENT_QUERIES);
    setRecentQueries(nextRecentQueries);
  };

  const handleSearchSubmit = (value: string) => {
    if (!value) {
      return;
    }

    if (searchText !== value) {
      setSearchText(value);
    }
    if (!recentQueries.includes(value)) {
      addQueryToRecents(value);
    }

    dispatch(actions.getSearchResults(value));
  };

  const submitSearch = React.useCallback(
    debounce((value: string) => handleSearchSubmit(value), 500),
    [recentQueries],
  );

  const handleSearchInputChange = (value: string) => {
    setSearchText(value);
    submitSearch(value);
  };

  const isSearching = search.status === RequestStates.LOADING;

  const showWalkthrough = shouldShowWalkthrough(completedWalkthroughs, 'search');
  const showRecentQueries = !searchText && Array.isArray(recentQueries);
  const showResults = search.results && searchText && !isSearching;

  const handleCompleteWalkthrough = () => onWalkthroughEnd('search');

  return (
    <div className='flex flex-col'>
      {showWalkthrough && (
        <Walkthrough
          variant='search'
          open
          targets={['#search-bottomBarIcon', '#search-bar']}
          lang={locale}
          onSkip={handleCompleteWalkthrough}
          onEnd={handleCompleteWalkthrough}
        />
      )}
      <BackButton />

      <PageContainer className='flex flex-col h-28'>
        <div
          className='flex items-center px-4 flex1'
          id='search-bar'
        >
          <SearchBar
            value={searchText}
            onChange={handleSearchInputChange}
            onSubmit={handleSearchSubmit}
          />
        </div>
      </PageContainer>

      <div className='my-2 border-b border-whiteFour' />

      <PageContainer className='px-4 overflow-y-auto'>
        {showRecentQueries && (
          <RecentQueries
            queries={recentQueries}
            onQueryClick={handleSearchSubmit}
          />
        )}

        {isSearching && (
          <div className='mt-8 overflow-y-hidden text-center'>
            <SpinnerBalls />
          </div>
        )}

        {showResults && <SearchResults results={search.results} />}
      </PageContainer>
    </div>
  );
};
