import { skipToken } from '@reduxjs/toolkit/dist/query';
import { PageContainer } from 'components/page-container';
import { TopBar } from 'components/top-bar';
import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ChannelMemberResponse, UserResponse } from 'stream-chat';
import tinycolor from 'tinycolor2';
import { doNothing } from 'util/doNothing';
import { ProfileStore } from '../../../../containers/Profile';
import { useGetDynamicConfigurationQuery } from '../../../../store/dynamic-configuration';
import { Store } from '../../../../store/types';
import { useUpdateChannelMutation } from '../Chat.api';
import { AppChannelUser, ChannelGroupRoles } from '../Chat.types';
import { ListItemPreview } from '../ListItemPreview';
import { AlphabeticUserList } from '../components/alphabetic-user-list';
import { UserPreview } from '../new/group';
import { ConfirmationRemoveUserDrawer } from './ConfirmationRemoveUserDrawer';
import { GroupInfoProps } from './Conversation.types';
import { GroupAddMembers } from './GroupAddMembers';
import { GroupBasicInfo } from './GroupBasicInfo';
import { UserChatDrawer } from './UserChatDrawer';

const getChannelRole = (role?: string) => {
  switch (role) {
    case 'owner':
    case 'moderator':
      return ChannelGroupRoles.GROUP_ADMIN;
    default:
      return ChannelGroupRoles.MEMBER;
  }
};

export const GroupInfo: FC<GroupInfoProps> = ({ channel, members, userId, refreshMembers, onClose }) => {
  const intl = useIntl();
  const { id: accountId, primaryColor } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const [name, setName] = useState(channel?.data?.name);
  const [isEditing, setIsEditing] = useState(false);

  const [isAddMembersOpen, setIsAddMembersOpen] = useState(false);
  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState(false);
  const [isConfirmationRemoveUserDrawerOpen, setIsConfirmationRemoveUserDrawerOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<AppChannelUser | null>(null);

  const [updateChannel] = useUpdateChannelMutation();

  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);

  const hasChatAdminPermissions = Boolean(dynamicConfiguration?.communityChat?.hasChatAdminPermissions);

  const isPrimaryColorDark = tinycolor(primaryColor).isDark();
  const textColor = isPrimaryColorDark ? tinycolor(primaryColor).brighten(40).toString() : primaryColor;

  const myChannelMember = members.find((member: ChannelMemberResponse) => member.user?.id === userId);
  const myUser = {
    id: userId ?? '',
    ...myChannelMember?.user,
    channelRole: getChannelRole(myChannelMember?.role),
  };
  const otherUsers = members.reduce((acc: UserResponse[], member: ChannelMemberResponse) => {
    const { user } = member;
    if (user && user.id !== userId) {
      acc.push({ ...user, channelRole: getChannelRole(member.role) });
    }
    return acc;
  }, []);

  const isGroupAdmin = getChannelRole(myChannelMember?.role) === ChannelGroupRoles.GROUP_ADMIN;
  const hasPermission = hasChatAdminPermissions || isGroupAdmin;

  const handleClickUser = (user: AppChannelUser) => {
    if (!hasPermission) {
      return;
    }

    setSelectedUser(user);
    handleOpenUserChatDrawer();
  };

  const handleEditName = () => {
    if (!hasPermission) {
      return;
    }
    setIsEditing(true);
  };

  const handleChangeName = (value: string) => setName(value);

  const handleSaveInfo = async () => {
    if (!channel.id) {
      return;
    }
    await updateChannel({ accountId, channelId: channel.id, name });
    setIsEditing(false);
  };

  const handleBackButton = () => {
    if (isEditing) {
      setIsEditing(false);
      setName(channel?.data?.name);
      return;
    }
    onClose();
  };

  const handleOpenAddMembers = () => setIsAddMembersOpen(true);
  const handleCloseAddMembers = () => setIsAddMembersOpen(false);
  const handleOpenUserChatDrawer = () => setIsUserDrawerOpen(true);
  const handleCloseUserChatDrawer = () => setIsUserDrawerOpen(false);

  const handleCloseConfirmationRemoveUserMenu = () => {
    setIsConfirmationRemoveUserDrawerOpen(false);
    setSelectedUser(null);
  };

  const handleOpenConfirmationRemoveUserMenu = () => {
    setIsUserDrawerOpen(false);
    setIsConfirmationRemoveUserDrawerOpen(true);
  };

  const nextButtonText = isEditing ? (
    <FormattedMessage
      id='community.chat.group-info.save'
      defaultMessage='Save'
    />
  ) : (
    ''
  );

  const backButtonText = isEditing ? (
    <FormattedMessage
      id='community.chat.group-info.cancel'
      defaultMessage='Cancel'
    />
  ) : (
    ''
  );

  if (!myChannelMember) {
    return null;
  }

  const backButton = backButtonText ? (
    <TopBar.Button onClick={handleBackButton}>{backButtonText}</TopBar.Button>
  ) : (
    <TopBar.IconButton onClick={handleBackButton} />
  );

  return (
    <>
      <div className='absolute top-0 z-10 flex flex-col w-full h-full bg-zinc-100'>
        <TopBar
          backButton={backButton}
          nextButton={
            <TopBar.Button
              onClick={handleSaveInfo}
              disabled={!name || name === channel?.data?.name}
              textColor={textColor}
            >
              {nextButtonText}
            </TopBar.Button>
          }
        >
          <FormattedMessage
            id='community.chat.group-info.title'
            defaultMessage='Group info'
          />
        </TopBar>

        <PageContainer className='h-full px-4 pb-3 overflow-y-auto'>
          <GroupBasicInfo
            channel={channel}
            name={name ?? ''}
            hasPermission={hasPermission}
            isEditing={isEditing}
            handleEditName={handleEditName}
            handleChangeName={handleChangeName}
          />
          {hasPermission && (
            <ListItemPreview
              title={intl.formatMessage({ id: 'community.chat.group-info.add-members', defaultMessage: 'Add members' })}
              icon='account-group-outline'
              onClick={handleOpenAddMembers}
            />
          )}
          <UserPreview
            user={myUser}
            onClick={doNothing}
          />
          <AlphabeticUserList
            users={otherUsers}
            onClick={handleClickUser}
          />
        </PageContainer>
      </div>

      {isAddMembersOpen && (
        <GroupAddMembers
          refreshMembers={refreshMembers}
          onClose={handleCloseAddMembers}
        />
      )}

      <UserChatDrawer
        isOpen={isUserDrawerOpen}
        user={selectedUser}
        refreshMembers={refreshMembers}
        onRemoveUser={handleOpenConfirmationRemoveUserMenu}
        onClose={handleCloseUserChatDrawer}
      />

      <ConfirmationRemoveUserDrawer
        isOpen={isConfirmationRemoveUserDrawerOpen}
        user={selectedUser}
        refreshMembers={refreshMembers}
        onClose={handleCloseConfirmationRemoveUserMenu}
      />
    </>
  );
};
