import { BackButton } from 'components/back-button';
import { PageContainer } from 'components/page-container';
import { Tab, Tabs } from 'components/tabs';
import Progression from 'containers/Progression';
import { Settings } from 'containers/settings';
import { Walkthrough, shouldShowWalkthrough, useWalkthrough } from 'containers/walkthrough';
import { Badges } from 'pages/badges';
import React, { useState } from 'react';
import { ProfileProps as Props } from './types';

/** used to persist the last opened across the application */
let initialtab = 0;

const Profile: React.FC<Props> = ({ intl, location }) => {
  /** request tab from an outside routing event */
  const requestedTabIndex = location.state && location.state.requestedTabIndex;

  const [tabIndex, setTabIndex] = useState<number>(requestedTabIndex ?? initialtab);

  const { onWalkthroughEnd, completedWalkthroughs, locale } = useWalkthrough();
  const showWalkthrough = shouldShowWalkthrough(completedWalkthroughs, 'profile');
  const handleCompleteWalkthrough = () => onWalkthroughEnd('profile');

  /** tab change handler */
  const changeTab = (index: number) => {
    setTabIndex(index);
    initialtab = index;
  };

  const tabs = [
    {
      title: intl.formatMessage({ id: 'profile.tab.progression' }),
      component: <Progression />,
    },
    {
      title: intl.formatMessage({ id: 'profile.tab.badges' }),
      component: <Badges />,
    },
    {
      title: intl.formatMessage({ id: 'profile.tab.settings' }),
      component: <Settings />,
    },
  ];

  return (
    <>
      <BackButton />
      <PageContainer>
        {showWalkthrough && (
          <Walkthrough
            variant='profile'
            open
            targets={['#profile-bottomBarIcon', '#profile-stats', '#tabs']}
            lang={locale}
            onSkip={handleCompleteWalkthrough}
            onEnd={handleCompleteWalkthrough}
          />
        )}

        <Tabs
          indexSelected={tabIndex}
          onChange={(index) => changeTab(index)}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.title}
              title={tab.title}
            >
              {tab.component}
            </Tab>
          ))}
        </Tabs>
      </PageContainer>
    </>
  );
};

export default Profile;
