import { Answer } from 'containers/FormatScreen/Formats/components';
import React from 'react';
import { AnswersListSelectionProps as Props } from './types';

const AnswerListSelection: React.SFC<Props> = ({ answers, correct, selected, focusColor, showAnswers, onChange }) => (
  <div className='mx-3.5'>
    {answers.map((answer) => {
      const isCorrect = correct.includes(answer.id);
      const selectedAnswer = selected.includes(answer.id);

      return (
        <Answer
          key={answer.id}
          answer={answer}
          correct={isCorrect}
          selected={selectedAnswer}
          showFeedback={showAnswers}
          focusColor={focusColor}
          onClick={onChange}
        />
      );
    })}
  </div>
);

export default AnswerListSelection;
