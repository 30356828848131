import popupLogo from 'assets/images/popup_logo.svg';
import { Popup } from 'components/popup';
import { ProfileUpdateFeedbackModalProps as Props } from 'containers/settings/Settings.types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const ProfileUpdateFeedbackModal: React.FC<Props> = ({
  open,
  modal: { onModalClose },
  isEmailRequest,
  success,
}) => {
  const intl = useIntl();
  const requestResponseData = { success, isEmailRequest };

  const popupContent = getFeedbackModalContents(requestResponseData);

  const handleClose = () => {
    onModalClose();
  };

  return (
    <Popup
      icon={popupLogo}
      variant='profileUpdateFeedback'
      open={open}
      onRequestClose={handleClose}
      title={intl.formatMessage({ id: `${popupContent.title}` })}
    >
      <div className='flex flex-col content-center'>
        <div className='mx-2 my-6 text-sm text-neutral-500'>
          {popupContent.message}
          <div />
        </div>
      </div>
    </Popup>
  );
};

const getFeedbackModalContents = (requestResponseData: { success: boolean; isEmailRequest: boolean }) => {
  const { success, isEmailRequest } = requestResponseData;
  if (success && isEmailRequest) {
    return {
      title: 'settings.email.popup.success.title',
      message: <FormattedMessage id='settings.email.popup.success.message' />,
    };
  }
  if (success && !isEmailRequest) {
    return {
      title: 'settings.pwd.message.success',
      message: <FormattedMessage id='settings.pwd.message.message' />,
    };
  }

  return {
    title: 'settings.email.popup.error.title',
    message: <FormattedMessage id='settings.email.popup.error.message' />,
  };
};
