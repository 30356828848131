import classNames from 'classnames';
import { Popup } from 'components/popup';
import { FormattedMessage } from 'react-intl';
import { doNothing } from 'util/doNothing';
import { InstallInstructionReminderPopupProps } from './InstallInstructionReminderPopup.types';

export const InstallInstructionReminderPopup: React.FC<InstallInstructionReminderPopupProps> = ({
  onModalClose,
  open,
  target,
  placement,
  className = '',
}) => (
  <Popup
    variant='tooltip'
    open={open}
    closeIcon
    target={target}
    placement={placement}
    onRequestClose={onModalClose}
    onClick={doNothing}
    fullScreenOverlayExtraClassnames={classNames('z-40', className)}
  >
    <div className='text-sm md:text-base text-greyishBrown 3xs:text-xs 2xs:text-xs'>
      <FormattedMessage
        id='home.install-app.install-instruction-reminder-popup.text'
        defaultMessage='You can still find this challenge in the help page at all times.'
      />
    </div>
  </Popup>
);
