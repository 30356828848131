import React from 'react';
import { NotificationsBadgeProps } from './SvgIcon.types';

export const NotificationsBadge: React.FC<NotificationsBadgeProps> = ({ open, amount = false, ...rest }) => {
  if (!open || amount === 0) {
    return null;
  }

  if (amount === false) {
    return (
      <circle
        cx='20'
        cy='5'
        r='3'
        stroke='#f34280'
        strokeWidth='3'
        fill='#f34280'
        {...rest}
      />
    );
  }

  return (
    <>
      <circle
        cx='20'
        cy='5'
        r='7.5'
        stroke='#f34280'
        strokeWidth='3'
        fill='#f34280'
        {...rest}
      />
      <text
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize='11px'
        fontWeight='100'
        x={rest.cx ?? 20}
        y={rest.cy ?? 5}
        stroke='white'
      >
        {amount}
      </text>
    </>
  );
};
