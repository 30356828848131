import { FormattedMessage } from 'react-intl';
import { EmptyStateIndicatorProps } from 'stream-chat-react';

export const EmptyChannel: React.FC<EmptyStateIndicatorProps> = () => (
  <div className='flex items-end justify-center h-full'>
    <p className='mb-6 text-sm text-center text-gray-500 uppercase'>
      <FormattedMessage
        id='community.chat.empty-channel'
        defaultMessage='This is the start of your chat'
      />
    </p>
  </div>
);
