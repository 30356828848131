import { ProfileStore } from 'containers/Profile';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Store } from 'store/types';
import { UserResponse } from 'stream-chat';
import { LoadingIndicator, useChatContext } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { useCreateChannelMutation } from '../../Chat.api';
import { AlphabeticUserList } from '../../components/alphabetic-user-list';
import { SearchInput } from '../../components/search-input';
import { useSearchUsers } from '../../hooks/useSearchUsers';
import { NewChatViewProps } from '../NewChat.types';
import { NewGroupPreview } from '../group';

export const ChannelSearch: React.FC<NewChatViewProps> = ({ onChangeView }) => {
  const history = useHistory();

  const { id: accountId, primaryColor } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);
  const { client } = useChatContext();

  const filters = useMemo(() => ({ id: { $ne: String(client.userID) } }), [client.userID]);
  const { searchUsers, users, isLoading, searchText } = useSearchUsers(filters);

  const [createChannel] = useCreateChannelMutation();

  const handleStartConversation = async (selectedUser: UserResponse<DefaultStreamChatGenerics>) => {
    if (!selectedUser || !client.userID) {
      return;
    }

    const channel = await createChannel({
      accountId,
      name: selectedUser.name ?? '',
      members: [client.userID, selectedUser.id],
      isGroup: false,
    }).unwrap();

    if (!channel.id) {
      return;
    }
    history.push(`/chat/${channel.id}`);
  };

  return (
    <div className='flex flex-col w-full h-full'>
      <SearchInput
        value={searchText}
        onChange={searchUsers}
      />
      <div className='p-4 overflow-y-auto'>
        <NewGroupPreview onChangeView={onChangeView} />

        {isLoading ? (
          <div className='flex justify-center w-full p-5'>
            <LoadingIndicator color={primaryColor} />
          </div>
        ) : (
          <AlphabeticUserList
            users={users}
            onClick={handleStartConversation}
          />
        )}
      </div>
    </div>
  );
};
