import { Typography } from 'components/typography';
import { ImageFormat, VideoFormat } from 'containers/FormatScreen/Formats';
import * as React from 'react';
import { TextFormatProps } from './types';

export const TextFormat: React.FC<TextFormatProps> = ({ src, video, title, text, titleColor }) => (
  <div className='flex flex-col w-full whitespace-pre-wrap'>
    {title && (
      <span
        className='px-4 mt-3 text-lg font-bold text-turquoiseBlue'
        style={{ color: titleColor }}
      >
        {title}
      </span>
    )}

    <Typography
      className='px-4 pb-4 mt-5 text-sm'
      rich
    >
      {text}
    </Typography>

    {video && (
      <div className='my-4'>
        <VideoFormat url={video} />
      </div>
    )}
    {src && !video && (
      <div className='my-4'>
        <ImageFormat imgSrc={src} />
      </div>
    )}
  </div>
);
