import { Loading } from 'components/Loading';
import { PopupType } from 'components/popup';
import { USER_ROLES } from 'constants/global';
import { getRoleAccessLevel } from 'containers/authentication';
import { defaultLocale } from 'containers/language-provider';
import { Loformats } from 'containers/loformats';
import { AnswerResponse } from 'containers/loformats/hooks/use-learning-format/types';
import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { LepaTypes } from 'pages/home/Home.types';
import { useStartLepaQuery } from 'pages/lepa/Lepa.api';
import { getColorFromScore } from 'pages/skill';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Store } from 'store/types';
import { useGetModuleFormatsQuery, useSubmitLearningFormatAnswerMutation } from './Tests.api';
import {
  AnswerPayload,
  Completion,
  LearningFormatTestTypes,
  LearningTestTypes,
  ModuleTestUrlParams,
} from './Tests.types';
import { isFirstModuleDone, mapLearningFormatToOldStructure, prepareFormats, saveFirstModuleDone } from './helpers';
import { useTestConfiguration } from './use-test-configuration';

export const ModuleTest: React.FC = () => {
  const history = useHistory();
  const intl = useIntl();
  const { enqueueModal, resetQueue } = useModalQueue();
  const { learningPathId, crashCourseId, levelId, skillId, moduleId } = useParams<ModuleTestUrlParams>();
  const pathId = learningPathId ?? crashCourseId ?? '';
  const {
    user: { id: userId, role, locale },
    badges: { list: badgesList },
  } = useSelector(({ profile }: Store) => profile);
  const isPreviewUser = getRoleAccessLevel(role) === USER_ROLES.PREVIEW;

  const [submitLearningFormatAnswer, answerResponse] = useSubmitLearningFormatAnswerMutation();
  const { data: answerData, originalArgs } = answerResponse;

  const {
    data: lepaData,
    isError: lepaIsError,
    isFetching: isStartingLepa,
  } = useStartLepaQuery({
    userId: parseInt(userId, 10),
    lepaId: parseInt(pathId, 10),
    locale: locale ?? defaultLocale,
  });

  const {
    data: learningFormatsData,
    isError: learningFormatsIsError,
    isFetching: isFetchingLefos,
  } = useGetModuleFormatsQuery({
    userId: parseInt(userId, 10),
    moduleId: parseInt(moduleId, 10),
    locale: locale ?? defaultLocale,
  });

  // MT-7127: mapping the formats to the old structure so that we don't have to change a lot of code for now
  const learningFormats = useMemo(
    () => (learningFormatsData ? prepareFormats(learningFormatsData).map(mapLearningFormatToOldStructure) : []),
    [learningFormatsData],
  );

  const isFetchingWithoutAnswer = (isStartingLepa || isFetchingLefos) && !answerData;

  const testConfiguration = useTestConfiguration(LearningTestTypes.MODULE, lepaData);

  const handleTryAgainModule = useCallback(
    () => () => {
      if (!lepaData || (!learningPathId && !crashCourseId)) {
        return null;
      }
      resetQueue();
      const lepaId = learningPathId ?? crashCourseId ?? '';
      const redirectPath = {
        [LepaTypes.CRASH_COURSE]: `/crash-course/${lepaId}/module/${moduleId}`,
        [LepaTypes.LEGACY]: `/learning-path/${lepaId}/level/${levelId}/skill/${skillId}/module/${moduleId}`,
        [LepaTypes.VIDEO]: '/',
        [LepaTypes.TEXT]: '/',
        [LepaTypes.QUESTIONS_ONLY]: '/',
        [LepaTypes.SINGLE_LEARNING]: '/',
      }[lepaData.type];
      return history.push(redirectPath);
    },
    [crashCourseId, learningPathId, history, lepaData, levelId, moduleId, resetQueue, skillId],
  );

  if (lepaIsError || learningFormatsIsError) {
    history.push('/invalid-url');
  }

  if (!learningFormats.length || !lepaData || isFetchingWithoutAnswer) {
    return <Loading />;
  }

  const testContext = {
    levelId: parseInt(levelId, 10),
    skillId: parseInt(skillId, 10),
    moduleId: parseInt(moduleId, 10),
  };
  const title = testConfiguration.getTitle(testContext);
  const navigateBackRoute = testConfiguration.getNavigateBackRoute(testContext);

  const enqueueUnlockedBadgesModals = (unlockedBadges?: Completion['unlockedBadges']) => {
    const hasUnlockedBadges = Array.isArray(unlockedBadges) && unlockedBadges.length > 0;
    if (!hasUnlockedBadges || isPreviewUser) {
      return;
    }
    unlockedBadges.forEach((unlockedBadge) => {
      const badgeType = badgesList.find((currentBadge) => Number(currentBadge.id) === unlockedBadge.typeId);
      const badge = badgeType?.badges.find((currentBadge) => currentBadge.id === String(unlockedBadge.id));
      if (badgeType && badge) {
        enqueueModal({
          type: ModalTypes.BADGE_UNLOCKED,
          modal: {
            variant: 'badge',
            title: badge.title,
            subtitle: badge.subtitle,
            text: badge.description,
            next: badge.next,
            badgeSrc: badgeType.picture,
            badgeTier: unlockedBadge.tier,
          },
        });
      }
    });
  };

  const enqueueModuleCompletionModal = (isFirstPopupType: boolean, score: number) => {
    const popupType = isFirstPopupType ? 'first' : getColorFromScore(score);
    enqueueModal({
      type: ModalTypes.MODULE_STAR_REWARD,
      modal: {
        variant: `${popupType}Unlock` as PopupType['variant'],
        title: intl.formatMessage({ id: `container.skill.${popupType}Unlock.title` }),
        secondaryButtonText: score < 100 ? intl.formatMessage({ id: 'comp.popup.button.tryAgain' }) : undefined,
        onSecondaryClick: handleTryAgainModule(),
        text: intl.formatMessage({ id: `container.skill.${popupType}Unlock.summary` }),
      },
    });
  };

  const handleNavigateAway = () => {
    const { path, state } = testConfiguration.getNavigateAwayPathAndState({ testContext });
    const next = answerData?.momentCompletion?.next;
    const nextUnlocked = Boolean(next?.type === LearningFormatTestTypes.MOMENT && next?.id);
    history.push(path, { ...state, nextUnlocked });
    const unlockedBadges = answerData?.momentCompletion?.unlockedBadges;
    const unlockedBadgesAmount = unlockedBadges?.length ?? 0;
    if (unlockedBadgesAmount > 0) {
      enqueueUnlockedBadgesModals(unlockedBadges);
    }
    const firstModuleDone = isFirstModuleDone();
    const isModulePassed = answerData?.momentCompletion?.passed;
    if (!isPreviewUser && (!firstModuleDone || isModulePassed) && state?.moduleID === moduleId) {
      const isFirstPopupType = !firstModuleDone;
      const score = answerData?.momentCompletion?.percentage ?? 0;
      enqueueModuleCompletionModal(isFirstPopupType, score);
    }
    if (!firstModuleDone && isModulePassed) {
      saveFirstModuleDone();
    }
  };

  const handleOnAnswer = async (answer: AnswerPayload): Promise<AnswerResponse> => {
    const { learningFormatId, ...body } = answer;
    const response = await submitLearningFormatAnswer({
      learningPathId: parseInt(pathId, 10),
      learningFormatId,
      body,
    }).unwrap();

    return {
      isCorrect: response.isCorrect,
      correctOptions: response.correctOptions,
      completion: response.momentCompletion,
    };
  };

  const { xp, percentage, passed, next, learningPathDone } = answerData?.momentCompletion ?? {};

  return (
    <Loformats
      learningFormats={learningFormats}
      title={title}
      id={moduleId}
      color={lepaData.color ?? undefined}
      navigateBackRoute={navigateBackRoute}
      onAnswer={handleOnAnswer}
      isSubmittingAnswer={answerResponse.isLoading}
      isCorrect={answerData?.isCorrect}
      correctOptions={answerData?.correctOptions}
      xp={xp}
      percentage={percentage}
      passed={passed}
      next={next}
      learningPathDone={learningPathDone}
      onNavigateAway={handleNavigateAway}
      testContext={testContext}
      previousSubmittedFormatId={originalArgs?.learningFormatId}
    />
  );
};
