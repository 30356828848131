import classNames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import * as React from 'react';
import { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { getSrcset } from 'util/image';
import { DeprecatedMedia } from './DeprecatedMedia';
import { FullScreenContainer } from './FullScreenContainer';
import { MediaProps, SourceConfiguration } from './Media.types';

export const Media: React.FC<MediaProps> = ({
  className,
  component,
  src,
  style,
  allowPreview,
  alt,
  children,
  loading = 'eager',
  sizes = '(max-width: 360px) 420px, 720px',
  external = false,
  disableCover = false,
  onClick,
  ...other
}) => {
  const isImageHandlingEnabled = useFeatureFlag(FlagKeys.IMAGE_HANDLING);

  const [preview, setPreview] = useState(false);
  const showPreviewIndicator = allowPreview && !preview;

  const [styles, api] = useSpring(() => ({
    from: { transform: 'translateY(-40px)' },
  }));

  const handleOpenPreview = () => {
    setPreview(true);
    api({ transform: 'translateY(0)', immediate: false });
  };

  const handleClosePreview: React.MouseEventHandler<HTMLDivElement> = () => {
    setPreview(false);
    api({ transform: 'translateY(-40px)', immediate: true });
  };

  if (!isImageHandlingEnabled) {
    return (
      <DeprecatedMedia
        className={className}
        style={style}
        allowPreview={allowPreview}
        component={component}
        alt=''
        src={src}
        children={children}
        {...other}
      />
    );
  }

  if (!src) {
    return (
      <div
        data-testid='e2e-image-no-src'
        className={className}
        style={style}
        {...other}
      />
    );
  }

  const getSrcConfiguration = (): SourceConfiguration => {
    if (external) {
      return { src };
    }

    return {
      src: `${src}?width=920`,
      srcSet: getSrcset(src),
      loading,
      sizes,
    };
  };

  const handleMediaClick: React.MouseEventHandler<HTMLImageElement> = (event) => {
    if (onClick) {
      return onClick(event);
    }
    if (allowPreview) {
      return handleOpenPreview();
    }
    return null;
  };

  const srcConfiguration = getSrcConfiguration();

  const media = (
    <img
      onDragStart={(event) => event.preventDefault()} // fixes image dragging
      data-testid={`e2e-image-${src}`}
      className={classNames(
        'w-full h-full text-center',
        'text-transparent', // hides alt text
        {
          'cursor-pointer': allowPreview && !preview,
          'object-cover': !disableCover,
          'object-contain': disableCover,
        },
        className,
      )}
      alt={alt}
      onClick={handleMediaClick}
      style={style}
      {...srcConfiguration}
      {...other}
    />
  );

  return (
    <>
      {media}
      {showPreviewIndicator && (
        <span
          className='absolute flex items-center justify-center w-6 h-6 bg-white rounded-full pointer-events-none top-2 right-2'
          data-testid='media-preview-indicator'
        >
          <SvgIcon
            name='search'
            size={16}
            color='black'
          />
        </span>
      )}
      {preview && (
        <FullScreenContainer
          open={preview}
          onClose={handleClosePreview}
        >
          <animated.div
            style={styles}
            className='flex items-center justify-center w-full h-full'
          >
            {React.cloneElement<MediaProps>(media, {
              className: '!object-contain !rounded-none h-screen w-screen',
              srcSet: getSrcset(src, { sizes: 2560 }),
            })}
          </animated.div>
        </FullScreenContainer>
      )}
    </>
  );
};
