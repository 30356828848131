import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import { useTextDirection } from 'hooks/useTextDirection';
import React, { useState } from 'react';
import { animated, useSpring } from 'react-spring';
import theme from 'theme';
import { easeInCirc } from 'util/easings';
import { isAndroid, isIOS } from 'util/platforms';
import {
  AnimatedStarProps,
  BorderStarProps,
  FullStarProps,
  StarsRatingProps as Props,
  StarProps,
} from './StarsRating.types';

const Star: React.FC<StarProps> = ({ index, iconName, color, strokeWidth, onMouseOver, onClick }) => (
  <SvgIcon
    name={iconName}
    data-testid={`rating-star-${index}-${iconName}`}
    stroke={theme.colors.roastedYellow}
    onMouseOver={onMouseOver}
    onClick={onClick}
    color={color}
    strokeWidth={strokeWidth}
    size={44}
    viewBox='0 0 43 40'
    className='outline-none cursor-pointer 2xs:w-10 2xs:h-10'
  />
);

const AnimatedStar: React.FC<AnimatedStarProps> = ({ starComponent, styles, className = '' }) => (
  <animated.div
    style={styles}
    className={className}
  >
    {starComponent}
  </animated.div>
);

const BorderStar: React.FC<BorderStarProps> = ({ index, onMouseOver, onClick }) => (
  <div className='flex items-center'>
    <Star
      index={index}
      iconName='star-outlined'
      color='none'
      strokeWidth={3}
      onMouseOver={onMouseOver}
      onClick={onClick}
    />
  </div>
);

const FullStar: React.FC<FullStarProps> = ({ index, onMouseOver, onClick, styles }) => (
  <AnimatedStar
    className='flex items-center'
    starComponent={
      <Star
        index={index}
        iconName='star-filled'
        color={theme.colors.roastedYellow}
        strokeWidth={1}
        onMouseOver={onMouseOver}
        onClick={onClick}
      />
    }
    styles={styles}
  />
);

const isMobile = isAndroid() || isIOS();

export const StarsRating: React.FC<Props> = ({ ratingStars = 5, animationDuration = 200, onClick }) => {
  const [selectedRating, setSelectedRating] = useState(0);
  const [onHoverRating, setOnHoverRating] = useState<number | null>(null);
  const [useAnimation, setUseAnimation] = useState(false);
  const ratingValues = Array.from(Array(ratingStars).keys()).map((rating) => rating + 1);
  const { isRTL } = useTextDirection();

  const styles = useSpring({
    loop: false,
    from: {
      transform: 'scale(1, 1)',
      rotate: '0deg',
    },
    to: [
      {
        transform: 'scale(0.1, 0.1)',
        rotate: '-45deg',
      },
      {
        transform: 'scale(1, 1)',
        rotate: '0deg',
      },
    ],
    config: {
      duration: animationDuration,
      easing: easeInCirc,
    },
    reset: true,
  });

  const handleHoverRating = (value: number | null) => {
    if (!useAnimation) {
      setOnHoverRating(value);
      setUseAnimation(false);
    }
  };

  const handleStarClick = (rating: number) => {
    onClick(rating);
    setSelectedRating(rating);
    setUseAnimation(true);
    const timer = setTimeout(() => {
      setUseAnimation(false);
    }, animationDuration * 2);
    return () => {
      clearTimeout(timer);
    };
  };

  const ratingValue = onHoverRating ?? selectedRating;
  const animatedStarStyles = useAnimation ? { ...styles } : {};

  return (
    <div
      className={classnames('flex flex-row gap-x-2', {
        'flex-row-reverse': isRTL,
      })}
      data-testid='stars-rating'
      onMouseLeave={() => handleHoverRating(null)}
    >
      {ratingValues.map((starIndex) =>
        starIndex > ratingValue ? (
          <BorderStar
            index={starIndex}
            key={starIndex}
            onClick={() => handleStarClick(starIndex)}
            onMouseOver={!isMobile ? () => handleHoverRating(starIndex) : undefined}
          />
        ) : (
          <FullStar
            index={starIndex}
            key={starIndex}
            styles={animatedStarStyles}
            onMouseOver={!isMobile ? () => handleHoverRating(starIndex) : undefined}
            onClick={() => handleStarClick(starIndex)}
          />
        ),
      )}
    </div>
  );
};
