import qs from 'qs';
import React from 'react';
import { useHistory } from 'react-router-dom';

export function useQueryParams<T = Record<string, string>>() {
  const history = useHistory();
  const {
    location: { search },
  } = history;
  const params = React.useMemo(() => qs.parse(search, { ignoreQueryPrefix: true, parameterLimit: 5 }) as T, [search]);
  return params;
}
