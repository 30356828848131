/**
 * Formats the dates to a human readable form.
 */
import { Locale } from 'containers/language-provider';
import moment from 'moment';
import 'moment/min/locales';

/**
 * Returns the difference from a date to now in a pretty way:
 *
 * 6 hours ago, 3 days ago...
 */
export const getRelativeDate = (date: string, locale: Locale) => moment(parseInt(date, 10)).locale(locale).fromNow();

/**
 * Formats the date to something like: Jan 15, 2020
 */
export const prettifyDate = (date: string, locale: Locale) => moment(parseInt(date, 10)).locale(locale).format('ll');
