import React from 'react';
import Joyride, { TooltipRenderProps } from 'react-joyride';
import { useSelector } from 'react-redux';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { Store } from 'store/types';
import { ZIndex } from 'styles/base';
import isNodeEnv from 'util/isNodeEnv';
import { JoyrideWrapperProps, WalkthroughProps } from './Walkthrough.types';
import { CrashCourseTestWalkthrough } from './variants/CrashCourseTestWalkthrough';
import { CrashCourseWalkthrough } from './variants/CrashCourseWalkthrough';
import { HomeWalkthrough } from './variants/HomeWalkthrough';
import { HotspotWalkthrough } from './variants/HotspotWalkthrough';
import { LevelQuizWalkthrough } from './variants/LevelQuizWalkthrough';
import { ModuleCompleteWalkthrough } from './variants/ModuleCompleteWalkthrough';
import { MultipleAnswerSwipeWalkthrough } from './variants/MultipleAnswerSwipeWalkthrough';
import { MultipleChoiceDragDropWalkthrough } from './variants/MultipleChoiceDragDropWalkthrough';
import { NewsfeedWalkthrough } from './variants/NewsfeedWalkthrough';
import { PathscreenWalkthrough } from './variants/PathscreenWalkthrough';
import { ProfileWalkthrough } from './variants/ProfileWalkthrough';
import { ReorderWalkthrough } from './variants/ReorderWalkthrough';
import { SearchWalkthrough } from './variants/SearchWalkthrough';
import { SkillShapeWalkthrough } from './variants/SkillShapeWalkthrough';
import { SkillTestWalkthrough } from './variants/SkillTestWalkthrough';
import { SwipeWalkthrough } from './variants/SwipeWalkthrough';
import { WalkthroughTooltip } from './walkthrough-tooltip';

const isDev = isNodeEnv('development');
export const Walkthrough: React.FC<WalkthroughProps> = ({ variant, open, onSkip, onEnd, ...props }) => {
  const user = useSelector(({ profile: profileStore }: Store) => profileStore.user);
  const isPersonalityTestEnabled = useFeatureFlag(FlagKeys.PERSONALITY_TEST);

  const { isLocaleSelected, persona } = user;
  const isPersonalityTestCompleted = isPersonalityTestEnabled && Boolean(persona);
  const hasPersona = isPersonalityTestCompleted || !isPersonalityTestEnabled;
  const shouldShowWalkthrough = isLocaleSelected && hasPersona;

  if (!shouldShowWalkthrough) {
    return null;
  }

  return (
    <div data-testid='e2e-walkthrough'>
      {
        {
          'home': (
            <HomeWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'path': (
            <PathscreenWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'swipe': (
            <SwipeWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'hotspot': (
            <HotspotWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'level-quiz': (
            <LevelQuizWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'skill-shape': (
            <SkillShapeWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'reorder': (
            <ReorderWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'skill-test': (
            <SkillTestWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'profile': (
            <ProfileWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'search': (
            <SearchWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'newsfeed': (
            <NewsfeedWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'module-complete': (
            <ModuleCompleteWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'crash-course': (
            <CrashCourseWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'crash-course-test': (
            <CrashCourseTestWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'multiple-answer-swipe': (
            <MultipleAnswerSwipeWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          'multiple-choice-drag-drop': (
            <MultipleChoiceDragDropWalkthrough
              {...props}
              onSkip={onSkip}
              onEnd={onEnd}
              open={open}
            />
          ),
          // This is not a Joyride Walkthrough. Therefore, we won't be using this component for that matter.
          'install-app': null,
        }[variant]
      }
    </div>
  );
};

export const JoyrideWrapper: React.FC<JoyrideWrapperProps> = ({
  steps,
  onSkip,
  onEnd,
  open,
  withSkip = true,
  ...props
}) => (
  <Joyride
    debug={isDev}
    showProgress
    run={open}
    steps={steps}
    disableCloseOnEsc
    disableOverlayClose
    disableScrollParentFix // fixes the overlay: initial that the component adds for scrolling containers
    showSkipButton
    scrollToFirstStep
    tooltipComponent={(tooltipProps: TooltipRenderProps) => (
      <WalkthroughTooltip
        onSkip={onSkip}
        onEnd={onEnd}
        withSkip={withSkip}
        {...tooltipProps}
      />
    )}
    styles={{
      options: {
        overlayColor: 'rgba(0, 0, 0, 0.9)',
        arrowColor: '#FF534F',
        zIndex: ZIndex.high,
      },
      overlay: {
        mixBlendMode: 'multiply',
      },
      spotlight: {
        backgroundColor: 'rgba(240, 240, 240, 0.8)',
        boxShadow: 'inset 0px 0px 10px 5px rgba(0, 0, 0, 0.7)',
        borderRadius: 0,
      },
    }}
    {...props}
  />
);
