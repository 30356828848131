import { SvgIcon } from 'components/svg-icon';
import { ReplayFrameContentProps } from './types';

export const ReplayFrameContent: React.FC<ReplayFrameContentProps> = ({ onReplay, onClose }) => (
  <div className='relative w-full h-0 bg-black pb-9/16'>
    <div className='absolute inset-0 flex items-center justify-center'>
      <div
        className='mx-2 cursor-pointer'
        onClick={onReplay}
      >
        <SvgIcon
          name='replay'
          size={55}
          color='white'
        />
      </div>
      {onClose && (
        <div
          className='mx-2 cursor-pointer'
          onClick={onClose}
        >
          <SvgIcon
            name='check'
            size={55}
            color='white'
          />
        </div>
      )}
    </div>
  </div>
);
