import classnames from 'classnames';
import ModuleStar from 'components/ModuleStar';
import PopupStar from 'components/PopupStar';
import { SvgIcon } from 'components/svg-icon';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { getColorFromScore } from 'pages/skill';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import tinyColor from 'tinycolor2';
import Base from '../Base';
import { ModuleShapeProps, PolygonType } from '../types';

/**
 * Polygon shape with unlock animations (5 modules)
 *
 * @module ModuleShape/Polygon
 */
class Polygon extends Base<ModuleShapeProps> {
  /** array of all module label refs */
  private labelRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {};

  public render() {
    const { modules } = this.props;
    const { width, height } = this.state;

    // Offset to make sure the container has enought space
    // this because the labels are set absolute outside of the canvas
    let heightestRef = 15;
    if (this.labelRefs[0] && this.labelRefs[0].current) {
      const numOfModules = modules.length;
      heightestRef = this.findBiggestRef(this.labelRefs as any, numOfModules);
    }

    return (
      <div
        className='relative w-64 h-64 text-greyish'
        style={{ marginTop: heightestRef }}
      >
        <canvas
          ref={this.canvasRef}
          width={width}
          height={height}
        />
        {this.renderNodes()}
        {this.renderCap('absolute top-1/3 left-1/3 w-24 h-20 bg-no-repeat bg-center bg-100/100')}
      </div>
    );
  }

  /**
   * This method needs to be implemented so the Base parent
   * can call it on `componentDidMount`
   */
  public drawShape = () => {
    const { animationIndex, animateUnlocks } = this.props;

    if (animationIndex >= 0 && animationIndex <= 4) {
      if (animateUnlocks) {
        this.unlockNextModule(animationIndex);
      } else {
        this.setState({ animate: true }, () =>
          this.animateLines(this.getPolygon(), 800, () => this.setState({ animate: false })),
        );
      }
    } else {
      this.drawLines(this.getPolygon());
    }
  };

  /**
   * It returns a polygon shape with no animations
   */
  private getPolygon() {
    const { width, height } = this.state;
    const { color, modules } = this.props;

    const lineWidth = 5;
    const halfW = width / 2;
    const quarterW = width / 5;
    const quarterH = height / 3;

    // Stroked polygon
    if (this.ctx && color) {
      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = lineWidth;
    }

    return [
      // Top to middle right
      {
        from: { x: halfW, y: 0 },
        to: { x: width, y: quarterH },
        props: { globalAlpha: modules[1].unlocked ? 1 : 0.3 },
      },
      // Middle right to bottom right
      {
        from: { x: width, y: quarterH },
        to: { x: width - quarterW, y: height - lineWidth },
        props: { globalAlpha: modules[2].unlocked ? 1 : 0.3 },
      },
      // Bottom right to bottom left
      {
        from: { x: width - quarterW, y: height - lineWidth },
        to: { x: quarterW, y: height - lineWidth },
        props: { globalAlpha: modules[3].unlocked ? 1 : 0.3 },
      },
      // Bottom left to middle left
      {
        from: { x: quarterW, y: height - lineWidth },
        to: { x: 0, y: quarterH },
        props: { globalAlpha: modules[4].unlocked ? 1 : 0.3 },
      },
      // Middle left to top
      {
        from: { x: 0, y: quarterH },
        to: { x: halfW, y: 0 },
        props: { globalAlpha: modules[4].passed ? 1 : 0.3 },
      },
    ];
  }

  /**
   * It will animate the next unlocked module
   */
  private unlockNextModule = (moduleIndex: number) => {
    /**
     * We set state to change the look of the locks as the lines are
     * being animated. And animate to true to show the stars.
     */
    const lock = moduleIndex + 1;
    this.setState({ animate: true, showLocks: [lock] }, () => {
      const { width, height } = this.state;
      const { color } = this.props;
      const lineWidth = 5;
      const halfW = width / 2;
      const quarterW = width / 5;
      const quarterH = height / 3;

      // Stroked polygon
      if (this.ctx && color) {
        this.ctx.strokeStyle = color;
        this.ctx.lineWidth = lineWidth;
      }

      const props = { globalAlpha: 0.3 };

      /**
       * The polygon sides to be drawn
       */
      const polygon: PolygonType[] = [
        { from: { x: halfW, y: 0 }, to: { x: width, y: quarterH }, props },
        { from: { x: width, y: quarterH }, to: { x: width - quarterW, y: height - lineWidth }, props },
        { from: { x: width - quarterW, y: height - lineWidth }, to: { x: quarterW, y: height - lineWidth }, props },
        { from: { x: quarterW, y: height - lineWidth }, to: { x: 0, y: quarterH }, props },
        { from: { x: 0, y: quarterH }, to: { x: halfW, y: 0 }, props },
      ];

      /**
       * We loop the sides and add props that will show better looking
       * animation.
       */
      const animatedOptions = { props: { globalAlpha: 1 } };
      const animatedPolygon = polygon.reduce((acc: PolygonType[], line, indx) => {
        if (indx < moduleIndex) {
          return [...acc, { ...line, ...animatedOptions }];
        }
        if (indx === moduleIndex) {
          return [...acc, line, { ...line, ...animatedOptions, animated: true }];
        }
        return [...acc, line];
      }, []);

      /**
       * Call `animateLines` that is made available by the Base parent class
       * and use the callback to set state at the end.
       */
      this.animateLines(animatedPolygon, 800, () => this.setState({ animate: false, showLocks: [] }));
    });
  };

  /**
   * It renders the nodes that can be clicked and also will animate
   */
  private renderNodes() {
    const { modules, animationIndex, moduleCreditReached, animationColor, color, hasUnlockAnimation } = this.props;
    const { width, height, animate } = this.state;
    const { showLocks } = this.state;
    const quarterW = width / 5;
    const quarterH = height / 3;

    const sides = [
      {
        style: {
          position: 'absolute',
          top: 5,
          left: '50%',
          transform: 'translate3d(-50%, -50%, 0)',
        },
      },
      {
        style: {
          position: 'absolute',
          top: quarterH,
          right: 0,
          transform: 'translate3d(40%, -45%, 0)',
        },
      },
      {
        style: {
          position: 'absolute',
          bottom: 0,
          right: quarterW,
          transform: 'translate3d(50%, 45%, 0)',
        },
      },
      {
        style: {
          position: 'absolute',
          bottom: 0,
          left: quarterW,
          transform: 'translate3d(-50%, 45%, 0)',
        },
      },
      {
        style: {
          position: 'absolute',
          top: quarterH,
          left: 0,
          transform: 'translate3d(-40%, -45%, 0)',
        },
      },
    ];

    return sides.map((props, indx) => {
      const { id } = modules[indx];
      const { done } = modules[indx];
      const { passed } = modules[indx];
      const { unlocked } = modules[indx];
      const score = modules[indx].percentage;
      const { title } = modules[indx];
      const unlocking = showLocks.includes(indx);
      const limited = moduleCreditReached && !done;
      const popupType = !unlocked ? ModalTypes.LEMO_LOCKED : ModalTypes.LEMO_LIMIT_REACHED;
      const moduleStarColor = getColorFromScore(score);
      const brightColor = tinyColor(color).brighten();
      const darkColor = tinyColor(color).darken();
      const isTopRow = indx === 0;
      const isMiddleRightRow = indx === 1;
      const isBottomRightRow = indx === 2;
      const isBottomLeftRow = indx === 3;
      const isMiddleLeftRow = indx === 4;
      this.labelRefs[indx] = React.createRef();

      const shouldStartUnlockAnimate = hasUnlockAnimation && passed;

      const baseColor = !unlocked ? '#c3c3c3' : color;
      const brightBaseColor = unlocking ? brightColor : baseColor;
      const finalColor = passed ? darkColor : brightBaseColor;

      return (
        <div
          key={`poly-side-${indx}`}
          {...(props as any)}
          className={!unlocked ? 'locked-node' : 'unlocked-node'}
        >
          <SvgIcon
            color={String(finalColor)}
            size={40}
            name='circle'
            style={{ pointerEvents: 'none' }}
          />

          {unlocking || limited || !unlocked ? (
            <SvgIcon
              className='absolute top-0 left-0 translate-3d-[50%,50%,0]'
              color='#fff'
              size={20}
              name={unlocking ? 'lock-open' : 'lock'}
              onClick={this.openPopup(popupType)}
            />
          ) : (
            <SvgIcon
              className='absolute top-0 left-0 text-aquaMarine fill-current opacity-0 transition-opacity duration-200 ease-in-out translate-3d-[-10%,-10%,0] cursor-pointer'
              size={50}
              name='circle'
              onClick={this.nodeClicked(id)}
            />
          )}

          {shouldStartUnlockAnimate && (
            <ModuleStar
              className='absolute top-0 left-0 translate-3d-[50%,50%,0] pointer-events-none'
              color={moduleStarColor}
            />
          )}

          <div
            className={classnames(
              'absolute transform w-20 sm:w-36 -translate-x-1/4 sm:-translate-x-1/3 text-xs sm:text-sm font-medium',
              { '-top-2 -translate-y-full text-center': isTopRow },
              { 'text-center sm:text-left top-4 right-4 sm:top-2 sm:-right-52': isMiddleRightRow },
              { 'text-center sm:text-left sm:left-12': isBottomRightRow },
              { 'text-center sm:text-right sm:-right-12': isBottomLeftRow },
              { 'text-center sm:text-right top-4 sm:top-2 left-14 sm:-left-28': isMiddleLeftRow },
              {
                'text-turquoiseBlue': unlocked && !passed,
                'text-ocean': passed,
              },
            )}
            style={{ color }}
            ref={this.labelRefs[indx]}
          >
            {title}
          </div>

          {shouldStartUnlockAnimate && animationColor !== 'ruby' && (
            <div
              className={classnames(
                'absolute transform w-20 sm:w-36 -translate-x-1/4 sm:-translate-x-1/3 text-xs sm:text-sm font-medium',
                'bg-white text-turquoiseBlue pointer-events-none',
                {
                  'pb-3 translate-3d-[-25%,-200%,0]': isTopRow,
                  'pb-2.5 -translate-x-1/4': !isTopRow,
                  'opacity-1 transition-opacity duration-200 ease-in-out': animate && animationIndex === indx,
                  'opacity-0 transition-opacity duration-700 ease-in-out delay-200':
                    !animate || animationIndex !== indx,
                },
              )}
            >
              {animationColor === 'diamond' && <FormattedMessage id='comp.moduleShape.finishedDiamond' />}
              {animationColor === 'gold' && <FormattedMessage id='comp.moduleShape.finishedGold' />}
              {animationColor === 'silver' && <FormattedMessage id='comp.moduleShape.finishedSilver' />}
            </div>
          )}

          {shouldStartUnlockAnimate && (
            <PopupStar
              color={animationColor}
              className={classnames('absolute top-4/10 left-1/2 w-20 h-16 pointer-events-none', {
                'opacity-1 translate-3d-scale-[-50%,-50%,0,1] transition-all duration-200 ease-in-out':
                  animate && animationIndex === indx,
                'opacity-0 translate-3d-scale-[-50%,-50%,0,0] transition-all duration-700 ease-in-out':
                  !animate || animationIndex !== indx,
              })}
            />
          )}
        </div>
      );
    });
  }
}

export default Polygon;
