/** checks if desktop */
export const isDesktop = () => {
  const regex = RegExp(/(Win32|WinCE|Win16|MacIntel|MacPPC|Linux i686|Linux x86_64|Linux armv7l)/);
  return Boolean(regex.exec(navigator.platform));
};

/** checks if iOS */
export const isIOS = () => navigator.platform.includes('iPhone');

/** checks if Android */
export const isAndroid = () => navigator.userAgent.includes('Android');

export const isSafariBrowser = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isFirefoxBrowser = () => navigator.userAgent.toLowerCase().includes('firefox');
