import classNames from 'classnames';
import { FC } from 'react';
import { Attachment, MessageText, MessageTimestamp } from 'stream-chat-react';
import { MessageDialog as MessageDialogGlobal } from '../../../../../components/message-dialog';
import { MessageDialogProps } from './Message.types';

export const MessageDialog: FC<MessageDialogProps> = ({
  message,
  ownMessage,
  isLastMessageInGroup,
  showUserName,
  nameColor,
}) => (
  <MessageDialogGlobal
    isMyMessage={ownMessage}
    isLastMessageInGroup={isLastMessageInGroup}
  >
    {showUserName && <span className={classNames('mr-4 text-sm mb-4', nameColor)}>{message.user?.name}</span>}

    <Attachment attachments={message.attachments ?? []} />
    <MessageText />

    <div className='flex mt-1 text-xs font-light leading-3 text-gray-400'>
      <span className='ml-auto'>
        <MessageTimestamp format='HH:mm' />
      </span>
    </div>
  </MessageDialogGlobal>
);
