import { Badge } from 'components/Badge';
import { Button } from 'components/button';
import { PageContainer } from 'components/page-container';
import { API_URL } from 'constants/endpoints';
import React from 'react';
import { PopupVariantProps as Props } from '../Popup.types';
import { CloseIcon } from '../close-icon';

export const BadgePopup: React.FC<Props> = ({
  title,
  subtitle,
  buttonText,
  children,
  badgeSrc,
  badgeTier,
  onClose,
}) => {
  const colors = ['', 'grey', 'orange', 'green'];
  const badgeImageUrl = badgeSrc ? `${API_URL}/${badgeSrc}` : '';

  return (
    <PageContainer center>
      <div
        className='relative flex flex-col p-4 bg-white rounded-lg w-9/10 text-greyishBrown'
        data-testid='e2e-popup'
      >
        <Badge
          src={badgeImageUrl}
          title={title}
          borderColor={colors[badgeTier as number]}
          rootClassName='absolute block transform -translate-x-1/2 pointer-events-none left-1/2 -translate-y-3/4'
        />

        <CloseIcon onClose={onClose('close')} />

        {title && <h1 className='m-0 mt-6 text-lg font-bold text-center text-turquoiseBlue'>{title}</h1>}
        {subtitle && <h2 className='pb-2 m-0 text-sm text-center text-turquoiseBlue font-extralight'>{subtitle}</h2>}

        {children}

        {buttonText && (
          <div className='flex justify-center pt-4'>
            <Button
              variant='outlined'
              text={buttonText}
              onClick={onClose('button')}
            />
          </div>
        )}
      </div>
    </PageContainer>
  );
};
