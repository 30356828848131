import { PopupProps } from 'components/popup';
import { DefaultModalProps, ModalTypes } from 'containers/modal-controller/ModalController.types';
import { WrappedComponentProps } from 'react-intl';

export interface PersonalityTestState {
  status: PersonalityTestStatus;
  test?: {
    collections?: string[];
    tags?: string[];
  };
}

export enum Persona {
  CONTROL = 'control',
  HELPING_GIVING = 'helpingGiving',
  SUCCESSFUL = 'successful',
  UNIQUE_SPECIAL = 'uniqueSpecial',
  SMART = 'smart',
  LOYAL = 'loyal',
  HAPPY = 'happy',
  STRONG = 'strong',
  RELAX_SATISFIED = 'relaxSatisfied',
}

export enum PersonalityTestStatus {
  CLOSED = 'closed',
  // TODO:  _CANSTART Should be removed when 'me' gets deprecated
  _CANSTART = 'can-start',
  TODO = 'to-do',
  DOING = 'doing',
  DONE = 'done',
}

export interface URLParams {
  type: string;
}

export enum PersonalityTestType {
  COLLECTIONS = 'collections',
  TAGS = 'tags',
}

export interface PersonalityTestPopupProps extends DefaultModalProps, WrappedComponentProps {
  onModalClose: () => void;
}
export interface PersonalityTestStartModalData {
  type: ModalTypes.PERSONALITY_TEST_START;
}

export interface FeedbackPopupProps extends Omit<PopupProps, 'variant' | 'title'> {
  onModalClose: () => void;
}
export interface PersonalityTestFeedbackModalData {
  type: ModalTypes.PERSONALITY_TEST_FEEDBACK;
}
