import { Button } from 'components/button';
import { Popup } from 'components/popup';
import { useModalQueue } from 'containers/modal-controller';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { actions as homeActions } from 'pages/home';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'store/types';
import theme from 'theme';
import { InstallInstructionPopupProps as Props } from './InstallInstructionPopup.types';

export const InstallInstructionPopup: React.FC<Props> = ({
  open,
  onUpdateUserInstallInstruction,
  showHelpPageReminder,
}) => {
  const [showPopup, setShowPopup] = useState(true);
  const accountPrimaryColor = useSelector(({ profile }: Store) => profile.account.primaryColor);
  const { enqueueModal } = useModalQueue();
  const dispatch = useDispatch();
  const customizableAccountColor = accountPrimaryColor || theme.colors.orangeRedTwo;
  const walkThroughSlug = 'install-app';

  const enqueueInstallInstructionReminder = () => {
    if (!showHelpPageReminder) {
      return;
    }
    dispatch(homeActions.setShowInstallInstructionReminder({ showInstallInstructionReminder: true }));
  };

  const handleContinueClick = () => {
    setShowPopup(false);
    enqueueModal({
      type: ModalTypes.INSTALL_INSTRUCTION_STEPS,
      modal: {
        onFirstStepBack: () => setShowPopup(true),
        onInstallComplete: async () => {
          await onUpdateUserInstallInstruction(walkThroughSlug);
        },
        onEnd: enqueueInstallInstructionReminder,
      },
    });
  };

  const handleRemindMeLaterClick = async () => {
    setShowPopup(false);
    const dateWithTwoWeeksLater = new Date();
    dateWithTwoWeeksLater.setDate(dateWithTwoWeeksLater.getDate() + 14);
    await onUpdateUserInstallInstruction(walkThroughSlug, dateWithTwoWeeksLater);
    enqueueInstallInstructionReminder();
  };

  return (
    <Popup
      variant='faded'
      open={showPopup && open}
      closeIcon={false}
    >
      <div className='flex flex-col items-center gap-5 px-8 py-10 sm:px-16'>
        <h1
          className='text-2xl font-bold text-center'
          style={{ color: customizableAccountColor }}
        >
          <FormattedMessage
            id='home.install-app.install-instruction-popup.texts.title'
            defaultMessage='Your first challenge'
          />
        </h1>
        <p className='text-lg font-bold text-center text-greyishBrown'>
          <FormattedMessage
            id='home.install-app.install-instruction-popup.texts.subtitle'
            defaultMessage='Are you ready to win your first {xpAmount} XP (Experience Points)?'
            values={{ xpAmount: 50 }}
          />
        </p>
        <p className='text-lg font-light text-center text-greyishBrown'>
          <FormattedMessage
            id='home.install-app.install-instruction-popup.texts.description'
            defaultMessage='Install the app on your device now and improve your learning experience!'
          />
        </p>
        <Button
          variant='contained'
          className='w-full text-base text-white'
          style={{ backgroundColor: customizableAccountColor }}
          onClick={handleContinueClick}
        >
          <FormattedMessage
            id='home.install-app.install-instruction-popup.buttons.continue'
            defaultMessage='Continue'
          />
        </Button>
        <button
          className='text-sm underline cursor-pointer'
          onClick={handleRemindMeLaterClick}
        >
          <FormattedMessage
            id='home.install-app.install-instruction-popup.buttons.remind-me-later'
            defaultMessage='Remind me later'
          />
        </button>
      </div>
    </Popup>
  );
};
