import { skipToken } from '@reduxjs/toolkit/dist/query';
import { ProfileStore } from 'containers/Profile';
import { useSelector } from 'react-redux';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import { Store } from 'store/types';
import { AttachmentPreviewList } from 'stream-chat-react';
import theme from 'theme';
import { FileInput } from './FileInput';
import { TextInput } from './TextInput';

export const MessageInput: React.FC = () => {
  const { id: accountId, primaryColor = theme.colors.orange } = useSelector<Store, ProfileStore['account']>(
    ({ profile }) => profile.account,
  );

  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);

  const isFileSharingEnabled = Boolean(dynamicConfiguration?.communityChat?.isChatFileSharingEnabled);

  return (
    <>
      <AttachmentPreviewList />
      <div className='flex gap-2 mx-4 my-1.5 mb-4 rounded-lg items-center'>
        {isFileSharingEnabled && <FileInput color={primaryColor} />}
        <TextInput
          color={primaryColor}
          isFileSharingEnabled={isFileSharingEnabled}
        />
      </div>
    </>
  );
};
