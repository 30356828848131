import brokenLightBulb from 'assets/images/broken-light-bulb.svg';
import diamondStar from 'assets/images/diamond_star.svg';
import endPathHeader from 'assets/images/endPathHeader.png';
import firstUnlockStars from 'assets/images/first_unlock_stars.svg';
import goldenCrown from 'assets/images/gold_crown.svg';
import goldStar from 'assets/images/gold_star.svg';
import hoursImg from 'assets/images/icon_hours.svg';
import levelTestFail from 'assets/images/level_test_fail.png';
import levelTestPassed from 'assets/images/level_test_passed.png';
import lockersImg from 'assets/images/lockers.svg';
import pencilImg from 'assets/images/popup_pencil.png';
import tptImg from 'assets/images/popup_tip_trick.png';
import rubyStar from 'assets/images/ruby_star.svg';
import silverStar from 'assets/images/silver_star.svg';
import skillFailCap from 'assets/images/skill_fail_cap.png';
import skillpassedCap from 'assets/images/skill_passed_cap.png';
import classNames from 'classnames';
import { FullScreenOverlay } from 'components/Overlay/FullScreenOverlay';
import React from 'react';
import Confetti from 'react-confetti';
import { doNothing } from 'util/doNothing';
import { AnimatedPopup } from './AnimatedPopup';
import { usePopupVariant } from './Popup.controller';
import { PopupProps as Props, RequestCloseType } from './Popup.types';

const imgSrcs: Record<string, string> = {
  tip: tptImg,
  retention: pencilImg,
  locked: lockersImg,
  limitReach: hoursImg,
  skillFail: skillFailCap,
  skillPassed: skillpassedCap,
  levelPassed: levelTestPassed,
  levelFail: levelTestFail,
  firstUnlock: firstUnlockStars,
  rubyUnlock: rubyStar,
  silverUnlock: silverStar,
  goldUnlock: goldStar,
  diamondUnlock: diamondStar,
  endPath: endPathHeader,
  unlockedFinalTest: goldenCrown,
  error: brokenLightBulb,
};

export const Popup: React.FC<Props> = ({
  onRequestClose = doNothing,
  onPrimaryClick,
  onSecondaryClick,
  closeIcon = true,
  icon = '',
  withConfettis = false,
  fullScreenOverlayExtraClassnames = '',
  ...variantProps
}) => {
  const { open = false, variant, onClick, skipRetentionTest, style, hasThanksAndCloseButton } = variantProps;

  const { PopupVariant, overlayVariant, animationVariant } = usePopupVariant(variant);

  const handleClose = (value: RequestCloseType) => (event?: React.SyntheticEvent) => {
    event?.stopPropagation();

    if (value === 'modal' && !hasThanksAndCloseButton) {
      return;
    }

    onRequestClose(value, event);

    if (skipRetentionTest) {
      skipRetentionTest();
    }
  };

  const handlePrimaryClick: React.ReactEventHandler<HTMLButtonElement> = (event) => {
    event?.stopPropagation();

    const primaryButtonButtonClose = handleClose('button');

    if (!onPrimaryClick) {
      return primaryButtonButtonClose(event);
    }

    return onPrimaryClick(event);
  };

  const handleSecondaryClick: React.ReactEventHandler<HTMLButtonElement> = (event) => {
    event?.stopPropagation();

    const secondaryButtonClose = handleClose('secondaryButton');

    if (!onSecondaryClick) {
      return secondaryButtonClose(event);
    }

    return onSecondaryClick(event);
  };

  const handleCloseOverlay = () => {
    switch (variant) {
      case 'gradientFullscreen':
        return () => doNothing();
      case 'lepaRating':
        return () => doNothing();
      case 'profileUpdate':
        return () => doNothing();
      case 'profileUpdateFeedback':
        return () => doNothing();
      default:
        return onClick || handleClose('overlay');
    }
  };

  return (
    <FullScreenOverlay
      className={classNames('flex items-center justify-center w-full', fullScreenOverlayExtraClassnames)}
      open={open}
      disableAnimation={variant === 'xp'}
      variant={overlayVariant}
      onClick={handleCloseOverlay()}
      style={style}
    >
      {withConfettis && (
        <Confetti
          style={{ zIndex: 0 }}
          className='w-screen h-screen'
        />
      )}
      <AnimatedPopup
        open={open}
        animation={animationVariant}
      >
        <PopupVariant
          closeIcon={closeIcon}
          onClose={handleClose}
          onPrimaryClick={handlePrimaryClick}
          onSecondaryClick={handleSecondaryClick}
          icon={icon || imgSrcs[variant]}
          {...variantProps}
        />
      </AnimatedPopup>
    </FullScreenOverlay>
  );
};
