import { animated, config, useSpring } from 'react-spring';

export const HeartEyes = () => {
  const styles = useSpring({
    loop: true,
    from: { transform: 'translateY(-8px) scale(0.5)' },
    to: { transform: 'translateY(0) scale(1)' },
    config: config.molasses,
  });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='92'
      height='92'
      fill='none'
      viewBox='0 0 92 92'
    >
      <circle
        cx='45.748'
        cy='45.748'
        r='45.748'
        fill='#FFD23B'
      ></circle>
      <path
        fill='#FFB24A'
        d='M45.748 91.497c25.266 0 45.749-20.482 45.749-45.748C91.497 20.482 71.014 0 45.748 0v91.497z'
      ></path>
      <path
        fill='#962B7C'
        d='M18.764 45.748c0 14.805 12.041 26.806 26.895 26.806s26.895-12.002 26.895-26.806h-53.79z'
      ></path>

      <path
        fill='#C22C7B'
        d='M18.764 45.748c0 14.805 12.04 26.806 26.895 26.806V45.748H18.764z'
      ></path>
      <animated.g style={{ ...styles, transformOrigin: '50%' }}>
        <path
          fill='#F44336'
          d='M61.295 39.778a.922.922 0 01-.764.085.921.921 0 01-.552-.535l-7.212-17.509c-3.008-7.3 3.814-15.798 10.824-13.399 4.21 1.441 4.952 5.58 5.482 6.488.98-.394 4.09-3.211 8.308-1.768 6.988 2.393 7.223 13.279.341 17.221l-16.427 9.417zM29.696 39.778c.242.14.516.17.764.085a.921.921 0 00.552-.535l7.212-17.509c3.008-7.3-3.814-15.798-10.825-13.399-4.21 1.441-4.951 5.58-5.482 6.488-.98-.394-4.088-3.211-8.307-1.768-6.988 2.393-7.223 13.279-.342 17.221l16.428 9.417z'
        ></path>
      </animated.g>
    </svg>
  );
};
