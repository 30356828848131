export enum ProvidersDomain {
  YOUTU = 'youtu',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  DAILYMOTION = 'dailymotion',
}

export enum ProvidersID {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  DAILYMOTION = 'dailymotion',
}

export interface ThumbnailParserOutput {
  id: string;
  provider: ProvidersID | null;
}

export interface DefaultMediaProvider {
  getVideoID: (url: string) => string;
  getVideoThumbnail: (videoID: string) => string;
}

export interface VimeoProvider extends Omit<DefaultMediaProvider, 'getVideoThumbnail'> {
  getVideoThumbnail: (videoID: string) => Promise<string>;
}

export type MediaProvider = DefaultMediaProvider | VimeoProvider;
