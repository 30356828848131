import { Selector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { Store } from '../types';

export const selectPendingRequests =
  <T extends string>(actions: Array<T>): Selector<Store, boolean> =>
  ({ pendingRequests }) =>
    actions.some((action) => pendingRequests[action]);

export const usePendingRequests = <T extends string>(actions: Array<T>) => {
  const selector = selectPendingRequests(actions);
  const hasPendingRequests = useSelector<Store, boolean>(selector);
  return hasPendingRequests;
};
