import Keys from 'assets/images/keys.svg';
import { IconButton } from 'components/button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

export const Unauthorized: React.FC = () => {
  const history = useHistory();

  const handleRedirectToLogin = () => {
    history.push('/login');
  };

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className='mx-8 text-lg'>
        <h1 className='font-bold'>
          <FormattedMessage id='unauthorized.title' />
        </h1>
        <p className='font-medium'>
          <FormattedMessage id='unauthorized.description' />
        </p>
      </div>

      <img
        className='my-16 w-52 h-52'
        src={Keys}
        alt='unauthorized keys'
      />

      <IconButton
        variant='outlined'
        round
        icon='arrowBack'
        size={30}
        className='border-lightGrey'
        color='#4c4c4c'
        onClick={handleRedirectToLogin}
      />
    </div>
  );
};
