import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { ModuleProps } from './types';

/**
 * Returns the correct aligment for the node
 * @param nodeSize node size in pixels
 * @param isLeft boolean indicating if it is left aligned or not
 */
export function getModuleTitleAlignment(nodeSize: number, isLeft = false) {
  const threshold = nodeSize / 1.5;
  const alignment = `calc(50% + ${threshold}px)`;
  return isLeft ? { right: alignment } : { left: alignment };
}

const Module: React.FC<ModuleProps> = ({
  variant = 'default',
  className = '',
  primaryColor,
  lockedColor,
  nodeSize,
  module,
  pathThickness,
  isOdd = false,
}) => {
  const color = module.unlocked ? primaryColor : lockedColor;

  const customStyles = {
    ...getModuleTitleAlignment(nodeSize, isOdd),
    color,
  };

  const innerNodeSize = nodeSize - pathThickness * 2;

  return (
    <>
      {
        {
          default: (
            <SvgIcon
              key={`${module.id}`}
              className={className}
              color={color}
              size={15}
              name={module.done ? 'circle' : 'circle-outline'}
            />
          ),
          straight: (
            <div
              key={`module-${module.id}`}
              className={classnames('relative w-full h-full', className)}
            >
              <SvgIcon
                color={color}
                size={nodeSize}
                name={'circle'}
                className='absolute top-1/2 left-1/2 translate-3d-[-50%,-50%,0]'
              />
              {!module.done && (
                <SvgIcon
                  color='#FFF'
                  size={innerNodeSize}
                  name={'circle'}
                  className='absolute top-1/2 left-1/2 translate-3d-[-50%,-50%,0]'
                />
              )}
              <p
                className={classnames('absolute m-0 top-1/2 -translate-y-1/2 text-xs', {
                  'text-right': isOdd,
                  'text-left': !isOdd,
                })}
                style={customStyles}
              >
                {module.title}
              </p>
            </div>
          ),
        }[variant]
      }
    </>
  );
};

export default Module;
