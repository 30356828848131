import { Media } from 'components/media';
import { API_URL } from 'constants/endpoints';
import React from 'react';
import { getSrcset } from 'util/image';
import { ImageProps as Props } from './types';

export const ImageFormat: React.FC<Props> = ({
  imgSrc,
  containerClassName = 'mx-auto my-0 overflow-hidden w-9/10',
  ...rest
}) => (
  <div
    className={containerClassName}
    data-testid='image-format'
  >
    <div className='relative w-full'>
      <Media
        alt='image-format'
        component='img'
        allowPreview
        className='rounded-lg'
        src={`${API_URL}/${imgSrc}`}
        sizes='90vw'
        srcSet={getSrcset(`${API_URL}/${imgSrc}`, {
          sizes: [
            [1520, '1520w'],
            [1120, '1120w'],
            [720, '720w'],
            [420, '420w'],
            [320, '320w'],
          ],
        })}
        {...rest}
      />
    </div>
  </div>
);
