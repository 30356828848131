import Question from 'components/Question';
import { ImageFormat, VideoFormat } from 'containers/FormatScreen/Formats';
import { AnswerListSelection } from 'containers/FormatScreen/Formats/components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isHTML } from 'util/isHTML';
import { MultipleAnswerProps } from './types';

export const MultipleAnswer: React.FC<MultipleAnswerProps> = ({
  selected,
  showAnswers,
  correct,
  question,
  answers,
  imgSrc,
  video,
  focusColor,
  onChange,
}) => {
  const isRichText = isHTML(question);

  return (
    <div className='w-full'>
      {video && (
        <div className='my-4'>
          <VideoFormat url={video} />
        </div>
      )}
      {imgSrc && !video && (
        <div className='my-4'>
          <ImageFormat imgSrc={imgSrc} />
        </div>
      )}

      <Question
        text={question}
        rich={isRichText}
        scaleWithContent
      />

      <p className='pb-3 pl-3 text-xs leading-none text-greyishBrown'>
        <FormattedMessage id='comp.multipleAnswer.label' />
      </p>

      <AnswerListSelection
        selected={selected}
        answers={answers}
        correct={correct}
        focusColor={focusColor}
        showAnswers={showAnswers}
        onChange={onChange}
      />
    </div>
  );
};
