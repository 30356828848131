import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { BackButtonIframeProps as Props } from './BackButtonIframe.types';

export const BackButtonIframe: React.FC<Props> = ({ to = '/' }) => (
  <div className='p-3 bg-gray-600'>
    <Link
      to={to}
      className='flex items-center text-sm text-white no-underline'
    >
      <SvgIcon
        name='arrowBack'
        color='white'
        size={15}
        className='mr-1'
      />
      <FormattedMessage id='lti.backText' />
    </Link>
  </div>
);
