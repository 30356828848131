import { IconButton } from 'components/button';
import { ChangeEvent, FC, useRef } from 'react';
import { useMessageInputContext } from 'stream-chat-react';
import { InputProps } from './MessageInput.types';

const ACCEPTED_TYPES = ['image/*', 'application/*', 'text/*', 'video/*'];

export const FileInput: FC<InputProps> = ({ color }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { isUploadEnabled, maxFilesLeft, uploadNewFiles } = useMessageInputContext();

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;
    if (!files) {
      return;
    }

    uploadNewFiles(files);
  };

  const handleSelectFile = () => fileInputRef.current?.click();

  if (!isUploadEnabled || maxFilesLeft === 0) {
    return null;
  }

  return (
    <>
      <IconButton
        variant='outlined'
        icon='plus'
        size={30}
        viewBox='0 0 24 24'
        color={color}
        className='w-8 h-8 p-0 border rounded-lg border-zinc-300 drop-shadow-xl'
        withoutPadding
        onClick={handleSelectFile}
      />
      <input
        className='hidden'
        type='file'
        accept={ACCEPTED_TYPES.join(',')}
        onChange={handleUploadFile}
        ref={fileInputRef}
      />
    </>
  );
};
