import { PopupStarProps } from 'components/PopupStar/types';
import React from 'react';

export const DiamondGradient = () => (
  <linearGradient
    data-testid='module-star-diamond'
    x1='50%'
    y1='132.732301%'
    x2='66.3867188%'
    y2='0%'
    id='linearGradient-diamond'
  >
    <stop
      stopColor='#4AC29A'
      offset='0%'
    />
    <stop
      stopColor='#FDFC47'
      offset='100%'
    />
  </linearGradient>
);

export const GoldGradient = () => (
  <linearGradient
    data-testid='module-star-gold'
    x1='50%'
    y1='50%'
    x2='50%'
    y2='114.366308%'
    id='linearGradient-gold'
  >
    <stop
      stopColor='#FAD961'
      offset='0%'
    />
    <stop
      stopColor='#F76B1C'
      offset='100%'
    />
  </linearGradient>
);

export const SilverGradient = () => (
  <linearGradient
    data-testid='module-star-silver'
    x1='11.099%'
    x2='11.099%'
    y1='100%'
    y2='-29.706%'
    id='linearGradient-silver'
  >
    <stop
      offset='0%'
      stopColor='#BABABA'
    />
    <stop
      offset='29.307%'
      stopColor='#FAFAFA'
    />
    <stop
      offset='51.874%'
      stopColor='#BEBEBE'
    />
    <stop
      offset='100%'
      stopColor='#C4C4C4'
    />
    <stop
      offset='100%'
      stopColor='#FFF'
    />
  </linearGradient>
);

export const RubyGradient = () => (
  <linearGradient
    data-testid='module-star-ruby'
    x1='50%'
    x2='50%'
    y1='0%'
    y2='100%'
    id='linearGradient-ruby'
  >
    <stop
      offset='0%'
      stopColor='#EC87C0'
    />
    <stop
      offset='100%'
      stopColor='#BF4C90'
    />
  </linearGradient>
);

const ModuleStar: React.FC<PopupStarProps> = ({ width = '20px', height = '19px', color = 'ruby', ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 19'
    data-testid='module-star'
    {...rest}
  >
    <defs>
      {color === 'diamond' && <DiamondGradient />}
      {color === 'gold' && <GoldGradient />}
      {color === 'silver' && <SilverGradient />}
      {color === 'ruby' && <RubyGradient />}
    </defs>
    <g
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        transform='translate(-178.000000, -235.000000)'
        fill={`url(#linearGradient-${color}`}
      >
        <polygon points='188 250.264423 181.798077 254.014423 183.456731 246.971154 178 242.235577 185.1875 241.610577 188 235 190.8125 241.610577 198 242.235577 192.567308 246.971154 194.201923 254.014423' />
      </g>
    </g>
  </svg>
);

export default ModuleStar;
