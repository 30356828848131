import classnames from 'classnames';
import { Button as BaseButton } from 'components/button';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonProps } from './button.types';

export const Button: FC<ButtonProps> = ({
  textColor,
  disabled,
  children = (
    <FormattedMessage
      id='top-bar.next'
      defaultMessage='Next'
    />
  ),
  onClick,
}) => (
  <BaseButton
    onClick={onClick}
    className={classnames('font-light text-right text-base bg-transparent m-0 p-0', {
      'text-white': !disabled,
      'text-neutral-400': disabled,
    })}
    style={{ color: textColor }}
    disabled={disabled}
  >
    {children}
  </BaseButton>
);
