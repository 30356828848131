import { Locales } from 'constants/global';
import { CustomFormats } from 'react-intl';
import { Locale, Messages, UnflattenMessages } from './LanguageProvider.types';
import enTranslationMessages from './translations/en-GB.json';

const __PROD__ = process.env.NODE_ENV === 'production';

export const formats: CustomFormats = {
  number: {
    GBP: {
      style: 'currency',
      currency: 'GBP',
    },
    EUR: {
      style: 'currency',
      currency: 'EUR',
    },
  },
};

/**
 * Source: https://formatjs.io/docs/react-intl/upgrade-guide-2x
 */
function flattenMessages(nestedMessages: UnflattenMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce<Messages>((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      // eslint-disable-next-line no-param-reassign
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

const formatTranslationMessages = (messages: UnflattenMessages): Messages => {
  function* mapMessages(obj: any, prevKey = ''): any {
    for (const key of Object.keys(obj)) {
      const id = prevKey ? `${prevKey}.${key}` : key;

      if (typeof obj[key] !== 'string') {
        yield* mapMessages(obj[key], id);
      } else {
        yield [id, obj[key]];
      }
    }
  }

  const formattedMessages: any = {};

  for (const map of mapMessages(messages)) {
    const [id, message] = map;
    formattedMessages[id] = message;
  }

  return flattenMessages(formattedMessages);
};

export const defaultLocale = 'en-GB' as Locale;

export const locales = {
  'de-DE': 'Deutsch',
  'en-GB': 'English',
  'el-GR': 'Ελληνικά',
  'es-ES': 'Español',
  'fr-FR': 'Français',
  'it-IT': 'Italiano',
  'nl-NL': 'Nederlands',
  'pl-PL': 'Polski',
  'pt-PT': 'Português',
  'ar-AE': 'اَلْعَرَبِيَّةُ',
  'ur-PK': 'اردو',
  'sv-SE': 'Svenska',
  'fi-FI': 'Suomi',
  'bg-BG': 'Български',
  'da-DK': 'Dansk',
  'ru-RU': 'Русский',
  'zh-Hans-CN': '中文 - 简体',
  'sq-AL': 'Shqip',
  'hr-HR': 'Hrvatski',
  'hu-HU': 'Magyar',
  'ro-RO': 'Română',
  'sk-SK': 'Slovenčina',
  'sl-SI': 'Slovenščina',
  'cs-CZ': 'Čeština',
  'tr-TR': 'Türkçe',
  'no-NO': 'Norsk',
  'hi-IN': 'हिन्दी',
  'ja-JP': '日本語',
  'zh-Hant-CN': '中文 - 繁體',
  'ko-KR': '한국인',
  'vi-VN': 'Tiếng Việt',
};

export const languageTextDirections: { [key in Locales]: 'rtl' | 'ltr' } = {
  'de-DE': 'ltr',
  'en-GB': 'ltr',
  'el-GR': 'ltr',
  'es-ES': 'ltr',
  'fr-FR': 'ltr',
  'it-IT': 'ltr',
  'nl-NL': 'ltr',
  'pl-PL': 'ltr',
  'pt-PT': 'ltr',
  'ar-AE': 'rtl',
  'ur-PK': 'rtl',
  'sv-SE': 'ltr',
  'fi-FI': 'ltr',
  'bg-BG': 'ltr',
  'da-DK': 'ltr',
  'ru-RU': 'ltr',
  'zh-Hans-CN': 'ltr',
  'sq-AL': 'ltr',
  'hr-HR': 'ltr',
  'hu-HU': 'ltr',
  'ro-RO': 'ltr',
  'sl-SI': 'ltr',
  'sk-SK': 'ltr',
  'cs-CZ': 'ltr',
  'tr-TR': 'ltr',
  'no-NO': 'ltr',
  'hi-IN': 'ltr',
  'ja-JP': 'ltr',
  'zh-Hant-CN': 'ltr',
  'ko-KR': 'ltr',
  'vi-VN': 'ltr',
};

export const appLocales = Object.keys(locales) as Locale[];

export const defaultMessages = formatTranslationMessages(enTranslationMessages);

export default async function getMessages(locale: Locale): Promise<{ locale: Locale; messages: Messages }> {
  if (!locale || locale === defaultLocale) {
    return { messages: defaultMessages, locale: defaultLocale };
  }

  try {
    const rawMessages = await require(/* webpackChunkName: "translation-[request]" */ `./translations/${locale}.json`);

    const formattedMessages = formatTranslationMessages(rawMessages);

    if (!__PROD__) {
      for (const key in defaultMessages) {
        if (!(key in formattedMessages)) {
          console.error(`[i18n] ${key} is missing in ${locale}.json`);
        }
      }
    }
    const messages = { ...defaultMessages, ...formattedMessages };

    return {
      messages,
      locale,
    };
  } catch (error) {
    if (!__PROD__) {
      console.error(error);
    }

    return { messages: defaultMessages, locale: defaultLocale };
  }
}

export function isValidLocale(locale: string): locale is Locale {
  return locale in locales;
}
