import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { VideoControlsProps as Props } from './types';

const VideoControls: React.FC<Props> = ({ status, onPlay, onPause }) => {
  const isPlayBtnDisabled = status === 'play';
  const isPauseBtnDisabled = status === 'pause';

  return (
    <div
      className='flex justify-center h-14'
      data-testid='video-format-controls'
    >
      <div
        className={classnames('text-greyish h-6 w-6 my-5 mx-2.5', {
          'opacity-40': isPlayBtnDisabled,
        })}
        aria-disabled={isPlayBtnDisabled}
        data-testid='video-format-controls-play'
        onClick={onPlay}
      >
        <SvgIcon
          name='play'
          size={24}
        />
      </div>
      <div
        className={classnames('text-greyish h-6 w-6 my-5 mx-2.5', {
          'opacity-40': isPauseBtnDisabled,
        })}
        aria-disabled={isPauseBtnDisabled}
        data-testid='video-format-controls-pause'
        onClick={onPause}
      >
        <SvgIcon
          name='pause'
          size={24}
        />
      </div>
    </div>
  );
};

export default VideoControls;
