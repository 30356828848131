import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Props as JoyrideProps } from 'react-joyride';
import { JoyrideWrapper } from '../Walkthrough';
import { WalkthroughVariantProps as Props } from '../Walkthrough.types';

const getSteps = (target: Props['targets'], externalConditions: Props['externalConditions']): JoyrideProps['steps'] => {
  const showCategories = externalConditions && externalConditions.showCategories;

  const steps: JoyrideProps['steps'] = [
    {
      content: <FormattedMessage id='walkthrough.home.step-1.summary' />,
      title: <FormattedMessage id='walkthrough.home.step-1.title' />,
      target: target[0],
      disableBeacon: true,
      placement: 'center',
    },
    {
      content: <FormattedMessage id='walkthrough.home.step-2.summary' />,
      title: <FormattedMessage id='walkthrough.home.step-2.title' />,
      target: target[1],
      disableBeacon: true,
      placement: 'auto',
    },
    {
      content: <FormattedMessage id='walkthrough.home.step-3.summary' />,
      title: <FormattedMessage id='walkthrough.home.step-3.title' />,
      target: target[2],
      disableBeacon: true,
      placement: 'top',
    },
  ];

  if (!showCategories) {
    return steps.slice(0, 2);
  }

  return steps;
};

/**
 * Home Walktrough component
 */
export const HomeWalkthrough: React.FC<Props> = ({ targets, externalConditions, ...props }) => {
  const steps = getSteps(targets, externalConditions);

  return (
    <JoyrideWrapper
      steps={steps}
      continuous
      {...props}
    />
  );
};
