import { Format, FormatContentOption } from 'containers/FormatScreen';
import { AnswerTypes, TestContext } from 'containers/loformats/Loformats.types';
import { Answer, AnswerPayload } from 'pages/tests/Tests.types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatTypeInformationMap } from '../../formatTypeInformation';
import { AnswerResponse, UseLearningFormat } from './types';

export const showHeading = (format: Format) => Boolean(format?.title && format?.formatType !== 'text');

export const useLearningFormat = ({
  format,
  onAnswer,
  isAnswerSubmitted,
}: {
  format: Format;
  testContext?: TestContext;
  onAnswer?: (baseAnswer: AnswerPayload) => Promise<AnswerResponse | null>;
  isAnswerSubmitted: boolean;
}): UseLearningFormat => {
  const [formatId, setFormatId] = useState<string | undefined>(undefined);

  const [leftGroup, setLeftGroup] = useState<FormatContentOption[]>([]);
  const [rightGroup, setRightGroup] = useState<FormatContentOption[]>([]);
  const [selectedAnswerId, setSelectedAnswerId] = useState<string | undefined>(undefined);
  const [selectedAnswersIds, setSelectedAnswersIds] = useState<string[]>([]);

  const [allAnswers, setAllAnswers] = useState<AnswerPayload[]>([]);
  const [lastAnswer, setLastAnswer] = useState<AnswerPayload | null>(null);

  const initialTime = useMemo(() => (formatId ? Date.now() : 0), [formatId]);

  const cleanState = useCallback((newFormatId: string) => {
    setFormatId(newFormatId);
    setLeftGroup([]);
    setRightGroup([]);
    setSelectedAnswerId(undefined);
    setSelectedAnswersIds([]);
    setLastAnswer(null);
  }, []);

  useEffect(() => {
    if (format?.formatType && format?.id !== formatId) {
      cleanState(format.id);
    }
  }, [cleanState, format, formatId]);

  const handleChangeAnswer = (answerId?: string) => {
    if (!isAnswerSubmitted) {
      setSelectedAnswerId(answerId);
    }
  };

  const handleChangeAnswers = (answerId: string) => {
    if (!isAnswerSubmitted) {
      if (selectedAnswersIds.includes(answerId)) {
        setSelectedAnswersIds(selectedAnswersIds.filter((currentAnswerId) => currentAnswerId !== answerId));
      } else {
        setSelectedAnswersIds(selectedAnswersIds.concat([answerId]));
      }
    }
  };

  const handleChangeOrder = (answersIds: string[]) => {
    if (!isAnswerSubmitted) {
      setSelectedAnswersIds(answersIds);
    }
  };

  const handleUpdateGroup = (left: FormatContentOption[], right: FormatContentOption[]) => {
    if (!isAnswerSubmitted) {
      setLeftGroup(left.filter((item) => item));
      setRightGroup(right.filter((item) => item));
    }
  };

  const getAnswerValue = (): number | number[] | Answer[] => {
    const { answerType } = formatTypeInformationMap[format.formatType];
    switch (answerType) {
      case AnswerTypes.SINGLE:
        return Number(selectedAnswerId);
      case AnswerTypes.MULTIPLE:
        return selectedAnswersIds.map((answerId) => Number(answerId));
      case AnswerTypes.GROUPS:
        return [
          {
            id: Number(format.content.groups[0].id),
            options: leftGroup.map((option) => Number(option.id)),
          },
          {
            id: Number(format.content.groups[1].id),
            options: rightGroup.map((option) => Number(option.id)),
          },
        ];
      default:
        return [];
    }
  };

  const learningFormatHasRequiredAnswers = () => {
    if (!format?.formatType) {
      return false;
    }
    switch (formatTypeInformationMap[format.formatType].answerType) {
      case AnswerTypes.NO_ANSWER:
        return true;
      case AnswerTypes.SINGLE:
        return Boolean(selectedAnswerId);
      case AnswerTypes.MULTIPLE:
        return selectedAnswersIds.length > 0;
      case AnswerTypes.GROUPS:
        return leftGroup.length + rightGroup.length === format.content.options.length;
      default:
        return true;
    }
  };

  const submitAnswer = async () => {
    if (!onAnswer) {
      return null;
    }
    const answer: AnswerPayload = {
      learningFormatId: Number(format.id),
      formatType: format.formatType,
      startTime: initialTime,
      endTime: Date.now(),
      answer: getAnswerValue(),
    };
    setAllAnswers(allAnswers.concat([answer]));
    setLastAnswer(answer);
    return onAnswer(answer);
  };

  /* eslint-disable camelcase */
  const withFeedback = {
    drag_drop: true,
    ranking_quiz: true,
    multiple_choice: true,
    multiple_choice_drag_drop: true,
    multiple_answer: true,
    multiple_answer_swipe: true,
    multiple_image_choice: true,
    multiple_image_answer: true,
    true_false: true,
    yes_no: true,
    hotspot: true,
    text: false,
    video: false,
  }[format.formatType];
  /* eslint-enable camelcase */

  return {
    handleChangeAnswer,
    handleChangeAnswers,
    handleChangeOrder,
    handleUpdateGroup,
    learningFormatHasRequiredAnswers,
    submitAnswer,
    leftGroup,
    rightGroup,
    isAnswerSubmitted,
    selectedAnswerId,
    selectedAnswersIds,
    lastAnswer,
    withFeedback,
  };
};
