import { ImageSrcsetOption, SrcsetPair } from 'components/media';
import qs from 'qs';

const SIZES: SrcsetPair[] = [
  [3840, '3840w'],
  [2560, '2560w'],
  [1920, '1920w'],
  [1520, '1520w'],
  [1120, '1120w'],
  [720, '720w'],
  [420, '420w'],
  [320, '320w'],
];

const FULLSCREEN_SIZES: SrcsetPair[] = [
  [420, '320w'],
  [1120, '720w'],
  [1920, '1120w'],
  [2560, '1920w'],
  [3840, '2560w'],
];

const defaultOptions: ImageSrcsetOption = {
  sizes: SIZES,
  fit: 'cover',
  withoutEnlargement: true,
};

const getImageUrlForSrcset =
  (src: string, extraQueryString: string) =>
  ([size, label]: SrcsetPair) =>
    `${src}?width=${size}&${extraQueryString} ${label}`;

export const getSrcset = (src: string, options?: ImageSrcsetOption) => {
  const optionsWithDefaults = { ...defaultOptions, ...options };
  const { sizes, ...restOptions } = optionsWithDefaults;

  if (!sizes) {
    return '';
  }

  const optionsQueryString = qs.stringify(restOptions);

  if (sizes === 'fullscreen') {
    const srcset = FULLSCREEN_SIZES.map(getImageUrlForSrcset(src, optionsQueryString)).join(', ');
    return srcset;
  }

  if (typeof sizes === 'number') {
    const srcset = getImageUrlForSrcset(src, optionsQueryString)([sizes, `${sizes}w`]);
    return srcset;
  }

  const srcset = sizes.map(getImageUrlForSrcset(src, optionsQueryString)).join(', ');
  return srcset;
};

interface ImageUploadConfiguration {
  unitMensurament: string;
  size: number;
  readableSize: string;
}

const getImageUploadConfiguration = (): ImageUploadConfiguration => {
  const size = 50 * 1024 * 1024; // 52428800 bytes
  const readableSize = `${size / (1024 * 1024)}`;

  return {
    unitMensurament: 'mb',
    size,
    readableSize,
  };
};

export const isImageSizeAcceptable = (image: File): boolean => {
  const imageUploadConfiguration = getImageUploadConfiguration();

  return image.size <= imageUploadConfiguration.size;
};

export const getMessageImageSizeNotAcceptable = (image: File): string => {
  const imageUploadConfiguration = getImageUploadConfiguration();
  const { name } = image;
  const { readableSize, unitMensurament } = imageUploadConfiguration;

  return `Image ${name} exceeds the allowed ${readableSize}${unitMensurament} of size.`;
};
