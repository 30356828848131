import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ChangeEmailPayload,
  ChangeEmailResponse,
  ChangePasswordPayload,
  ChangePasswordResponse,
  SettingsStore,
} from './Settings.types';

const initialState: SettingsStore = {
  isUpdating: false,
  change: {
    email: {
      success: false,
      error: false,
      message: null,
      status: -1,
    },
    password: {
      success: false,
      error: false,
      message: null,
    },
  },
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changePassword: (state, _action: PayloadAction<ChangePasswordPayload>) => ({
      ...state,
      isUpdating: true,
    }),
    changePasswordSuccess: (state, _action: PayloadAction<void>) => ({
      ...state,
      isUpdating: false,
      change: {
        ...state.change,
        password: {
          success: true,
          error: false,
          message: null,
        },
      },
    }),
    changePasswordError: (state, { payload }: PayloadAction<ChangePasswordResponse['errors']>) => ({
      ...state,
      isUpdating: false,
      change: {
        ...state.change,
        password: {
          success: false,
          error: true,
          message: payload.message,
        },
      },
    }),
    changeEmail: (state, _action: PayloadAction<ChangeEmailPayload>) => ({
      ...state,
      isUpdating: true,
    }),
    changeEmailSuccess: (state, _action: PayloadAction<void>) => ({
      ...state,
      isUpdating: false,
      change: {
        ...state.change,
        email: {
          success: true,
          error: false,
          message: null,
          status: 200,
        },
      },
    }),
    changeEmailError: (state, { payload }: PayloadAction<ChangeEmailResponse['errors']>) => ({
      ...state,
      isUpdating: false,
      change: {
        ...state.change,
        email: {
          success: false,
          error: true,
          message: payload.message,
          status: payload.status,
        },
      },
    }),
    resetSettings: (state) => ({
      ...state,
      isUpdating: false,
      change: {
        email: {
          success: false,
          error: false,
          message: null,
          status: -1,
        },
        password: {
          success: false,
          error: false,
          message: null,
        },
      },
    }),
  },
});

export const { actions } = slice;
export default slice.reducer;
