import { Popup } from 'components/popup';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NoAccessToContentModalProps as Props } from '../Lepa.types';

export const NoAccessToContentModal: React.FC<Props> = ({ open, onModalClose }) => (
  <Popup
    variant='error'
    open={open}
    onRequestClose={onModalClose}
    iconClassnames='w-20'
  >
    <div className='relative flex flex-col content-center text-center'>
      <h1 className='mt-12 text-lg font-bold'>
        <FormattedMessage
          id={'comp.no-access-to-content.title'}
          defaultMessage='Oops'
        />
      </h1>
      <p className='self-center w-64 mt-4 mb-10 text-sm font-normal'>
        <FormattedMessage
          id={'comp.no-access-to-content.text'}
          defaultMessage="You don't have access to this content."
        />
      </p>
    </div>
  </Popup>
);
