import { USER_ROLES } from 'constants/global';
import Profile from 'containers/Profile';
import OldSearch from 'containers/Search';
import { withAuthentication } from 'containers/authentication';
import { Community } from 'pages/community';
import { NewChatOverview } from 'pages/community/chat/new';
import { NewsPostFullView, Newsfeed } from 'pages/community/newsfeed';
import { CrashCourse } from 'pages/crash-course';
import { Event } from 'pages/event';
import { Fallback } from 'pages/fallback';
import { GROWTH_PLAN_PATH, GrowthPlan } from 'pages/growth-plan/GrowthPlan';
import { Help } from 'pages/help';
import { Home } from 'pages/home';
import { LanguageSelection } from 'pages/language-selection';
import PathScreen from 'pages/lepa';
import { LepaInfo } from 'pages/lepa-info';
import { Login } from 'pages/login';
import { DeprecatedLogin } from 'pages/login/deprecated-login';
import { LTI } from 'pages/lti';
import { NoPath } from 'pages/no-path';
import { NotFound } from 'pages/not-found';
import { PersonalityTest } from 'pages/personality-test';
import { Preview } from 'pages/preview';
import Register from 'pages/register';
import { ResetPassword } from 'pages/reset-password';
import { Scorm } from 'pages/scorm';
import { Search } from 'pages/search';
import { Skill } from 'pages/skill';
import { SSOMessage } from 'pages/sso-message';
import { LearningMomentTest, LevelTest, ModuleTest, RetentionTest, SkillTest } from 'pages/tests';
import { Video } from 'pages/video';
import React from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { Chat } from '../../pages/community/chat/conversation';
import { withChatProvider } from '../../pages/community/chat/hooks/withChatProvider';
import { RouteConfig } from './App.types';

export const RedirectToLearningPath: React.FC = () => {
  const { pathID: pathId } = useParams<{ pathID: string }>();
  const history = useHistory();
  history.push(`/learning-path/${pathId}`);
  return null;
};

/**
 * Add routes here
 * - should the route be protected by auth? - `protected: true`
 * - should the route have bottom navigation? - `navigation: true`
 */
const routesConfig: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: withAuthentication(Home),
    navigation: true,
  },
  {
    path: '/personality/test/:type',
    exact: true,
    component: withAuthentication(PersonalityTest),
    navigation: false,
  },
  {
    path: '/lti/:provider',
    exact: true,
    component: withAuthentication(LTI),
  },
  {
    path: '/no-path',
    exact: true,
    component: withAuthentication(NoPath),
    navigation: true,
    backgroundImage: false,
  },
  {
    path: '/path',
    exact: true,
    component: withAuthentication(PathScreen, USER_ROLES.PREVIEW),
    navigation: true,
    backgroundImage: true,
  },
  {
    path: '/path/:pathID',
    exact: true,
    component: RedirectToLearningPath,
    navigation: true,
  },
  {
    // MT-7127 routes: using pathID because PathScreen component needs to be changed to use different params
    path: '/learning-path/:pathID',
    exact: true,
    component: withAuthentication(PathScreen, USER_ROLES.PREVIEW),
    navigation: true,
    backgroundImage: true,
  },
  {
    path: '/crash-course/:crashCourseId',
    exact: true,
    component: withAuthentication(CrashCourse, USER_ROLES.PREVIEW),
    navigation: true,
    backgroundImage: true,
  },
  {
    path: '/scorm/:scormId',
    exact: true,
    component: withAuthentication(Scorm),
  },
  {
    path: '/event/:eventId',
    exact: true,
    component: withAuthentication(Event),
    navigation: true,
  },
  {
    path: '/video/:videoId',
    exact: true,
    component: withAuthentication(Video, USER_ROLES.PREVIEW),
    navigation: true,
  },
  {
    // MT-7127 routes: using pathID, levelID and skillID because Skill component needs to be changed to use different params
    path: '/learning-path/:pathID/level/:levelID/skill/:skillID',
    exact: true,
    component: withAuthentication(Skill, USER_ROLES.PREVIEW),
    navigation: true,
  },
  // New learning moment as content routes for format screen
  {
    path: '/learning-path/:learningPathId/level/:levelId/skill/:skillId/module/:moduleId',
    component: withAuthentication(ModuleTest, USER_ROLES.PREVIEW),
    navigation: true,
  },
  {
    path: '/crash-course/:crashCourseId/module/:moduleId',
    component: withAuthentication(ModuleTest, USER_ROLES.PREVIEW),
    navigation: true,
  },
  {
    path: '/learning-path/:learningPathId/level/:levelId/skill/:skillId/test/:learningTestId',
    component: withAuthentication(SkillTest, USER_ROLES.PREVIEW),
    navigation: true,
  },
  {
    path: '/crash-course/:crashCourseId/test/:learningTestId',
    component: withAuthentication(SkillTest, USER_ROLES.PREVIEW),
    navigation: true,
  },
  {
    path: '/learning-path/:learningPathId/level/:levelId/test/:learningTestId',
    component: withAuthentication(LevelTest, USER_ROLES.PREVIEW),
    navigation: true,
  },
  {
    path: '/learning-path/:learningPathId/retention-test/:learningTestId',
    component: withAuthentication(RetentionTest, USER_ROLES.PREVIEW),
    navigation: true,
  },
  {
    path: '/crash-course/:crashCourseId/retention-test/:learningTestId',
    component: withAuthentication(RetentionTest, USER_ROLES.PREVIEW),
    navigation: true,
  },
  {
    path: '/learning-moment/:learningMomentId',
    component: withAuthentication(LearningMomentTest, USER_ROLES.PREVIEW),
    navigation: true,
  },
  {
    path: '/path-info/:lepaId',
    exact: true,
    component: withAuthentication(LepaInfo),
    navigation: true,
  },
  {
    path: '/profile',
    component: withAuthentication(Profile),
    navigation: true,
  },
  {
    path: '/search',
    component: withAuthentication(OldSearch),
    navigation: true,
  },
  {
    path: '/language-selection',
    component: withAuthentication(LanguageSelection),
  },
  {
    path: '/help',
    component: withAuthentication(Help),
    navigation: true,
  },
  {
    path: '/register/:token',
    component: Register,
  },
  {
    path: '/reset/:token',
    component: ResetPassword,
  },
  {
    path: '/error',
    component: Fallback,
  },
  {
    path: '/invalid-url',
    component: NotFound,
  },
  {
    path: '/ssomessage',
    component: SSOMessage,
  },
  {
    path: '/preview/:token',
    component: Preview,
  },
  {
    path: '/community',
    component: withAuthentication(Community),
    navigation: true,
  },
  {
    path: '/newsfeed',
    component: withAuthentication(Newsfeed, USER_ROLES.USER, ['newsfeed']),
    navigation: true,
    exact: true,
  },
  {
    path: '/newsfeed/:id',
    component: withAuthentication(NewsPostFullView, USER_ROLES.USER, ['newsfeed']),
    navigation: true,
    exact: true,
  },
  {
    path: '/chat/new',
    component: withAuthentication(withChatProvider(NewChatOverview), USER_ROLES.USER),
    navigation: true,
    exact: true,
  },
  {
    path: '/chat/:channelId',
    component: withAuthentication(withChatProvider(Chat), USER_ROLES.USER),
    navigation: true,
    exact: true,
  },
  {
    path: GROWTH_PLAN_PATH,
    component: withAuthentication(GrowthPlan, USER_ROLES.USER),
    navigation: true,
    exact: true,
  },
  {
    path: '/login/:flow?/:tenant?/:idp?',
    component: Login,
  },
  {
    path: '/self-registration-link/:token',
    component: Login,
  },
  // The `redirectURL*` is an optional paramenter that matches any location path
  // in front, to then redirect to as the actual path without the account prefix
  {
    path: '/a/:loginSlug/:redirectURL*',
    component: DeprecatedLogin,
  },
  {
    component: NotFound,
  },
];

/** filters all the routes that should have bottom nav */
export const routesWithNav: RouteConfig[] = routesConfig
  .map((route) => ({ path: route.path, exact: !!route.exact, navigation: !!route.navigation }))
  .filter((route) => route.navigation);

/** filters all the routes that should have background image */
export const routesWithBackgroundImage: RouteConfig[] = routesConfig
  .map((route) => ({ path: route.path, exact: !!route.exact, backgroundImage: !!route.backgroundImage }))
  .filter((route) => route.backgroundImage);

const Routes = () => {
  const isNewSearch = useFeatureFlag(FlagKeys.SEARCH_PAGE);

  const featureFlagComponents: Pick<RouteConfig, 'path' | 'component'>[] = React.useMemo(
    () => [{ path: '/search', component: isNewSearch ? withAuthentication(Search) : withAuthentication(OldSearch) }],
    [isNewSearch],
  );

  const getFeatureFlagComponent = (path?: string | readonly string[]) => {
    const isPathArrayEquals = (route: RouteConfig) =>
      Array.isArray(path) &&
      path.every((currentPath, index) => Array.isArray(route.path) && currentPath === route.path[index]);
    return featureFlagComponents.find((route) =>
      !route.path || Array.isArray(path) ? isPathArrayEquals(route) : path === route.path,
    );
  };

  const routes = routesConfig.map((route) => {
    const featureFlagComponent = getFeatureFlagComponent(route.path);
    if (!featureFlagComponent) {
      return route;
    }
    return { ...route, component: featureFlagComponent.component };
  });

  return (
    <Switch key='routes'>
      {routes.map((route, indx) => (
        <Route
          key={`routes-${indx}`}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
};

export default Routes;
