import { AnyAction, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { API_URL } from 'constants/endpoints';
import { defaultLocale } from 'containers/language-provider';
import { actions as modalActions } from 'containers/modal-controller/ModalController.ducks';
import { EnqueueModalPayload } from 'containers/modal-controller/ModalController.types';
import { CompleteWalkthroughRequestPayload, walkthroughApi } from 'containers/walkthrough';
import { testsApi } from 'pages/tests/Tests.api';
import { GetRetentionTestPayload } from 'pages/tests/Tests.types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RootState } from 'store';
import { FlagKeys } from 'store/feature-flags';
import { withCompletedWalkthroughs } from 'util/withCompletedWalkthroughs';
import Lepa from './Lepa';
import { lepaApi } from './Lepa.api';
import { isLegacyLearningPath, mapLepaToActivePathStructure } from './Lepa.helpers';
import { StartLepaPayload } from './Lepa.types';

type PromiseVoidThunkAction = ThunkAction<Promise<void>, RootState, unknown, AnyAction>;

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => ({
  completeWalkthrough: (payload: CompleteWalkthroughRequestPayload) =>
    dispatch(walkthroughApi.endpoints.completeWalkthrough.initiate(payload) as unknown as PromiseVoidThunkAction),
  enqueueModal: (payload: EnqueueModalPayload) => dispatch(modalActions.enqueueModal(payload)),
  getRetentionTest: (payload: GetRetentionTestPayload) =>
    dispatch(testsApi.endpoints.getRetentionTest.initiate(payload) as unknown as AnyAction),
  getLearningPath: (payload: StartLepaPayload) =>
    dispatch(lepaApi.endpoints.startLepa.initiate(payload) as unknown as AnyAction),
});

const mapStateToProps = (state: RootState) => {
  const { activePath, profile, featureFlags, modals } = state;
  const isPersonalityTestEnabled = Boolean(featureFlags.flags[FlagKeys.PERSONALITY_TEST]);
  const retentionTestSelector = testsApi.endpoints.getRetentionTest.select({
    lepaId: parseInt(activePath.id, 10),
    userId: parseInt(profile.user.id, 10),
  });
  const { data: retentionTest, isLoading: isLoadingRetentionTest } = retentionTestSelector(state);
  const learningPathSelector = lepaApi.endpoints.startLepa.select({
    lepaId: -1, // This is an arbitrary value because we are using the same cache key for all calls. This means that the key will be hit with any id.
    userId: parseInt(profile.user.id, 10),
    locale: profile.user.locale || defaultLocale,
  });

  const {
    data: learningPath,
    isLoading: isLoadingLearningPath,
    error: learningPathError,
    originalArgs: learningPathCache,
    // @ts-ignore - need to be change in the future
  } = learningPathSelector(state);

  const isLegacy = learningPath && isLegacyLearningPath(learningPath);
  const levelTests = isLegacy
    ? learningPath?.content?.levels?.map((level) => ({
        levelId: String(level.id),
        learningTestId: level.progress.learningTestId,
        done: level.progress.done,
        passed: level.progress.passed,
      })) ?? []
    : [];
  const skillTests = isLegacy
    ? learningPath?.content?.levels?.flatMap((level) =>
        level.skills.map((skill) => ({
          skillId: String(skill.id),
          learningTestId: skill.progress.learningTestId,
        })),
      ) ?? []
    : [];

  const learningPathWithActivePathStructure = isLegacy
    ? mapLepaToActivePathStructure(learningPath, learningPathError, activePath.openedTipsTricks)
    : null;

  return {
    activePath: learningPathWithActivePathStructure,
    learningPath,
    learningPathCache,
    learningPathError,
    profile,
    accountLogo: profile.account.logoUrl ? `${API_URL}/${profile.account.logoUrl}` : '',
    themeBg: profile.account.themeBg ? `${API_URL}/${profile.account.themeBg}` : '',
    showLogo: activePath.showLogo,
    skippedRetentionTest: activePath.skippedRetentionTest,
    locale: state.profile.user.locale || defaultLocale,
    isLoadingRetentionTest,
    isPersonalityTestEnabled,
    modals,
    retentionTest,
    isLoadingLearningPath,
    levelTests,
    skillTests,
  };
};

const PathScreenWithIntl = withCompletedWalkthroughs(injectIntl(Lepa));

export default connect(mapStateToProps, mapDispatchToProps)(PathScreenWithIntl);
export * from './Lepa.ducks';
export { default as lepaPageReducer } from './Lepa.ducks';
export { isLegacyLearningPath } from './Lepa.helpers';
export { rootSaga as lepaPageSaga } from './Lepa.sagas';
export * from './Lepa.types';
export * from './modals';
