import classNames from 'classnames';
import { FC } from 'react';
import { TabsProps as Props, TabsStyle } from './Tabs.types';
import { TabsHeader } from './TabsHeader';

export const Tabs: FC<Props> = ({ indexSelected, style = TabsStyle.DEFAULT, children, className = '', onChange }) => {
  const handleClickTab = (tabIndex: number) => () => {
    onChange(tabIndex);
  };

  const tabs =
    children?.map((tab, index) => ({
      title: tab.props.title,
      isActive: index === indexSelected,
      notificationCount: tab.props.notificationCount,
    })) ?? [];

  return (
    <div
      className={classNames(className, 'w-[calc(100%-1.5rem)] my-0 mx-auto')}
      data-testid='e2e-tabs'
    >
      <TabsHeader
        tabs={tabs}
        style={style}
        onClick={handleClickTab}
      />
      <div className='px-0'>{children?.[indexSelected]}</div>
    </div>
  );
};
