import classNames from 'classnames';
import { useRef } from 'react';
import { ChannelMemberResponse } from 'stream-chat';
import { MessageUIComponentProps, useChannelStateContext, useMessageContext } from 'stream-chat-react';
import { Avatar } from '../../../../../components/avatar';
import { API_URL } from '../../../../../constants/endpoints';
import { MessageActions as CustomMessageActions } from './MessageActions';
import { MessageDeleted as CustomMessageDeleted } from './MessageDeleted';
import { MessageDialog as CustomMessageDialog } from './MessageDialog';

const USERNAME_COLORS = [
  {
    border: '#F97316',
    text: 'text-orange-300',
  },
  {
    border: '#FCD34D',
    text: 'text-amber-300',
  },
  {
    border: '#F87171',
    text: 'text-pink-200',
  },
  {
    border: '#A3E635',
    text: 'text-lime-400',
  },
  {
    border: '#C4B5FD',
    text: 'text-violet-300',
  },
  {
    border: '#72F1F9',
    text: 'text-cyan-500',
  },
];

export const Message: React.FC<MessageUIComponentProps> = () => {
  const { message, isMyMessage, groupStyles } = useMessageContext();
  const { user } = message;
  const { channel, members } = useChannelStateContext();

  const messageRef = useRef<HTMLDivElement | null>(null);

  const userAvatar = user?.image ? `${API_URL}/${String(user?.image)}` : '';

  const ownMessage = isMyMessage();
  const isGroupChat = Boolean(channel?.data?.isGroup);
  const isFirstMessageInGroup = groupStyles?.includes('single') || groupStyles?.includes('top');
  const isLastMessageInGroup = groupStyles?.includes('single') || groupStyles?.includes('bottom');
  const showAvatarSpace = isGroupChat && !ownMessage;
  const showAvatarImage = isLastMessageInGroup && userAvatar;

  const getBorderAndTextColor = (
    membersObj?: Record<string, ChannelMemberResponse>,
    memberId?: string,
  ): {
    border: string;
    text: string;
  } => {
    const fallbackColors = { border: '', text: 'text-orangeRed' };
    if (!membersObj) {
      return fallbackColors;
    }

    const membersArr = Object.values(membersObj);
    const membersIndex = membersArr.findIndex((member) => member.user?.id === memberId);

    if (membersIndex < 0) {
      return fallbackColors;
    }

    const colorIndex = membersIndex % membersArr.length;
    return USERNAME_COLORS[colorIndex];
  };

  const { border, text } = getBorderAndTextColor(members, user?.id);
  const showUserName = isFirstMessageInGroup && isGroupChat && !ownMessage;

  const renderPositionedMessageDialog = () => {
    const width = messageRef?.current?.clientWidth ?? 0;
    const { top, left } = messageRef?.current?.getBoundingClientRect() ?? { top: 0, left: 0 };

    return (
      <div
        className='absolute z-10'
        style={{
          top: `${top}px`,
          left: `${left}px`,
          width: `${width}px`,
        }}
      >
        <CustomMessageDialog
          message={message}
          ownMessage={ownMessage}
          isLastMessageInGroup={!!isLastMessageInGroup}
          showUserName={!!showUserName}
          nameColor={text}
        />
      </div>
    );
  };

  return (
    <div
      className={classNames('flex w-full my-2', {
        'flex-row-reverse': ownMessage,
      })}
    >
      <div className='self-end mb-2'>
        {showAvatarSpace && (
          <Avatar
            src={showAvatarImage ? userAvatar : ''}
            className='w-10 h-10'
            borderColor={border}
            borderWidth={2}
            disableBorder={!showAvatarImage}
          />
        )}
      </div>

      {message.deleted_at ? (
        <CustomMessageDeleted isMyMessage={ownMessage} />
      ) : (
        <>
          <div
            className='max-w-[calc(100%-18px)]'
            ref={messageRef}
          >
            <CustomMessageDialog
              message={message}
              ownMessage={ownMessage}
              isLastMessageInGroup={!!isLastMessageInGroup}
              showUserName={!!showUserName}
              nameColor={text}
            />
          </div>
          {ownMessage && <CustomMessageActions renderHighlightedMessage={renderPositionedMessageDialog} />}
        </>
      )}
    </div>
  );
};
