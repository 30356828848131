import Question from 'components/Question';
import { ImageFormat, VideoFormat } from 'containers/FormatScreen/Formats';
import React from 'react';
import { isHTML } from 'util/isHTML';
import { AnswerListSelection } from '../components';
import { MultipleChoiceProps as Props } from './types';

export const MultipleChoice: React.FC<Props> = ({
  selected = '',
  showAnswers,
  correct,
  question,
  answers,
  imgSrc,
  video,
  focusColor,
  onChange,
}) => {
  const isRichText = isHTML(question);

  return (
    <div className='w-full'>
      {video && (
        <div className='my-4'>
          <VideoFormat url={video} />
        </div>
      )}
      {imgSrc && !video && (
        <div className='my-4'>
          <ImageFormat
            imgSrc={imgSrc}
            alt={question}
          />
        </div>
      )}

      <Question
        text={question}
        rich={isRichText}
        scaleWithContent
      />

      <AnswerListSelection
        answers={answers}
        correct={correct}
        selected={selected}
        focusColor={focusColor}
        showAnswers={showAnswers}
        onChange={onChange}
      />
    </div>
  );
};
