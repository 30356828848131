import { actions } from 'containers/authentication';
import { LepaTypes } from 'pages/home/Home.types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Store } from 'store/types';

export const Preview: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token: previewToken } = useParams<{ token?: string }>();

  const { isValidToken, isValidating, activePathId, activePathType } = useSelector(({ auth }: Store) => auth.preview);
  const { user } = useSelector(({ profile }: Store) => profile);

  useEffect(() => {
    if (!previewToken) {
      return;
    }

    dispatch(actions.validatePreviewToken({ token: previewToken }));
  }, [dispatch, previewToken]);

  useEffect(() => {
    if (!isValidToken) {
      return;
    }
    dispatch(actions.getUserInfo());
  }, [dispatch, isValidToken]);

  useEffect(() => {
    const hasUser = Boolean(user.id);
    const shouldRedirectToLecoPreview = isValidToken && hasUser && activePathId && activePathType;
    if (!shouldRedirectToLecoPreview) {
      return;
    }

    const redirectPath = {
      [LepaTypes.CRASH_COURSE]: `/crash-course/${activePathId}`,
      [LepaTypes.LEGACY]: `/learning-path/${activePathId}`,
      [LepaTypes.VIDEO]: `/video/${activePathId}`,
      [LepaTypes.TEXT]: `/learning-moment/${activePathId}`,
      [LepaTypes.QUESTIONS_ONLY]: `/learning-moment/${activePathId}`,
      [LepaTypes.SINGLE_LEARNING]: `/learning-moment/${activePathId}`,
    }[activePathType];

    history.push(redirectPath ?? '/invalid-url');
  }, [activePathId, activePathType, history, isValidToken, user.id]);

  if (!isValidToken && !isValidating) {
    return (
      <div className='flex items-center justify-center h-full text-xl'>
        Link is not valid, please generate another one.
      </div>
    );
  }

  return <div />;
};
