import { Row } from 'components/row';
import { Slider, SliderItem } from 'components/slider';
import { Typography } from 'components/typography';
import { LecoCard } from 'containers/leco-card';
import React from 'react';
import { LolecoRowProps } from './LolecoRow.types';

export const LolecoRow: React.FC<LolecoRowProps> = ({ title, loleco, onLecoPlay, onLecoInfoOpen }) => {
  if (loleco.length === 0) {
    return null;
  }

  return (
    <Row>
      <Typography
        variant='h2'
        className='mx-0 my-3 text-base text-white'
      >
        {title}
      </Typography>

      <Slider fade>
        {loleco.map((leco) => (
          <SliderItem key={leco.id}>
            <LecoCard
              leco={leco}
              onPlay={onLecoPlay}
              onInfoOpen={onLecoInfoOpen}
            />
          </SliderItem>
        ))}
      </Slider>
    </Row>
  );
};
