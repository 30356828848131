import React from 'react';
import { AppSearchResult } from '../Search.types';
import { SearchResultsProps as Props } from './SearchResult.types';
import { LemoResult, LepaResult, LevelResult, TopicResult } from './results';

export const SearchResult: React.FC<Props> = ({ result, context, pathContext, levelContext, key, ...rest }) => {
  const resultHierarchyLevel: { [type in AppSearchResult['type']]: React.ReactElement } = {
    'learning-path': (
      <LepaResult
        result={result}
        context={result}
        {...rest}
      />
    ),
    'level': (
      <LevelResult
        result={result}
        context={context}
        {...rest}
      />
    ),
    'skill': (
      <TopicResult
        result={result}
        context={context}
        pathContext={pathContext}
        levelContext={levelContext}
        {...rest}
      />
    ),
    'module': (
      <LemoResult
        result={result}
        context={context}
        pathContext={pathContext}
        levelContext={levelContext}
        {...rest}
      />
    ),
  };

  return <div key={key}>{resultHierarchyLevel[result.type]}</div>;
};
