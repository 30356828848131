import moment from 'moment';
import { EventComponentProps } from 'stream-chat-react';

export const MessageSystem = (props: EventComponentProps) => {
  const { message } = props;

  const { created_at: createdAt = '' } = message;

  const isoDate = moment(createdAt);
  const weekDay = isoDate.format('dddd');
  const time = isoDate.format('HH:mm');

  return (
    <p className='text-xs text-center text-zinc-500'>
      <span className='font-bold'>{weekDay}</span>, {time}
    </p>
  );
};
