import handWithMobile from 'assets/images/install_instruction_hand_with_mobile.svg';
import magnifyingGlass from 'assets/images/install_instruction_magnifying_glass.svg';
import handHangingPlusSign from 'assets/images/install_intruction_hand_hanging_plus_sign.svg';
import classnames from 'classnames';
import { Button } from 'components/button';
import { Popup } from 'components/popup';
import { SvgIcon } from 'components/svg-icon';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import theme from 'theme';
import { doNothing } from 'util/doNothing';
import { CurveBackground } from './CurveBackground';
import { InstallInstructionStepsPopupProps as Props } from './InstallInstructionStepsPopup.types';
import { StepIndicator } from './StepIndicator';

export const InstallInstructionStepsPopup: React.FC<Props> = ({
  onModalClose,
  open,
  modal: { onFirstStepBack, onInstallComplete, onEnd, appIsAlreadyInstalled = false },
}) => {
  const [step, setStep] = useState(0);
  const [imageIsHidden, setImageIsHidden] = useState(false);
  const accountPrimaryColor = useSelector(({ profile }: Store) => profile.account.primaryColor);
  const customizableAccountColor = accountPrimaryColor || theme.colors.orangeRedTwo;

  const nextStep = () => setStep((previousStep) => Math.min(previousStep + 1, 2));
  const prevStep = () => setStep((previousStep) => Math.max(previousStep - 1, 0));
  const isLastStep = step === 2;

  const steps = [
    {
      content: (
        <>
          <h1 className='text-2xl font-bold 3xs:text-xl'>
            <FormattedMessage
              id='home.install-app.install-instruction-steps-popup.step-1.texts.title'
              defaultMessage='Step 1'
            />
          </h1>
          <p className='text-lg 3xs:text-base'>
            <FormattedMessage
              id='home.install-app.install-instruction-steps-popup.step-1.texts.description'
              defaultMessage='Search your screen from top to bottom until you <b>find one of these icons</b>:'
              values={{
                b: (chunks: string) => <b>{chunks}</b>,
              }}
            />
          </p>
          <div className='flex flex-row justify-center gap-8'>
            <div className='w-10 h-10 p-1 bg-white rounded-lg box-shadow-[0,0,5px,1px,0.25]'>
              <SvgIcon
                name={'ios-share'}
                color={theme.colors.neutral700}
                size={28}
                viewBox='-2 0 20 20'
              />
            </div>
            <div className='w-10 h-10 p-1 bg-white rounded-lg box-shadow-[0,0,5px,1px,0.25]'>
              <SvgIcon
                name={'three-dots-menu'}
                color={theme.colors.neutral700}
                size={26}
                viewBox='0.5 -1 20 20'
              />
            </div>
            <div className='w-10 h-10 p-1 bg-white rounded-lg box-shadow-[0,0,5px,1px,0.25]'>
              <SvgIcon
                name={'mobile-with-right-arrow'}
                color={theme.colors.neutral700}
                size={26}
                viewBox='-2 -1.5 20 20'
              />
            </div>
            <div className='w-10 h-10 p-1 bg-white rounded-lg box-shadow-[0,0,5px,1px,0.25]'>
              <SvgIcon
                name={'hamburguer'}
                color={theme.colors.neutral700}
                size={28}
                viewBox='-2 -6 20 20'
              />
            </div>
          </div>
        </>
      ),
      image: {
        src: magnifyingGlass,
        altName: 'magnifying glass',
        extraClassnames: 'bottom-14 h-[40vh] 3xs:h-[36vh]',
      },
      buttons: {
        backText: (
          <FormattedMessage
            id='home.install-app.install-instruction-steps-popup.step-1.buttons.back'
            defaultMessage='Back'
          />
        ),
        forwardText: (
          <span>
            <FormattedMessage
              id='home.install-app.install-instruction-steps-popup.step-1.buttons.found-it'
              defaultMessage='Found it'
            />
            {' >'}
          </span>
        ),
        onBack: () => {
          onModalClose();
          onFirstStepBack?.();
        },
        onForward: doNothing,
      },
    },
    {
      content: (
        <>
          <h1 className='text-2xl font-bold 3xs:text-xl'>
            <FormattedMessage
              id='home.install-app.install-instruction-steps-popup.step-2.texts.title'
              defaultMessage='Step 2'
            />
          </h1>
          <p className='text-lg 3xs:text-base'>
            <FormattedMessage
              id='home.install-app.install-instruction-steps-popup.step-2.texts.description'
              defaultMessage='Tap it, scroll the menu that pops up and select the option "<b>add to home screen</b>"'
              values={{
                b: (chunks: string) => <b>{chunks}</b>,
              }}
            />
          </p>
          {!appIsAlreadyInstalled && (
            <p className='text-sm'>
              <FormattedMessage
                id='home.install-app.install-instruction-steps-popup.step-2.texts.note'
                defaultMessage='Once you complete it make sure to continue this process to collect your XP'
              />
            </p>
          )}
        </>
      ),
      image: {
        src: handHangingPlusSign,
        altName: 'hand hanging plus sign',
        extraClassnames:
          'left-1/2 translate-x-0 scale-110 3xs:scale-125 2xs:scale-125 sm:scale-90 sm:left-1/3 lg:left-1/2 bottom-[15vh] sm:bottom-[5vh]',
      },
      buttons: {
        backText: (
          <FormattedMessage
            id='home.install-app.install-instruction-steps-popup.step-2.buttons.back'
            defaultMessage='Back'
          />
        ),
        forwardText: (
          <span>
            <FormattedMessage
              id='home.install-app.install-instruction-steps-popup.step-2.buttons.done'
              defaultMessage='Done'
            />
            {' >'}
          </span>
        ),
        onBack: doNothing,
        onForward: async () => {
          if (appIsAlreadyInstalled) {
            return;
          }
          await onInstallComplete();
        },
      },
    },
    {
      content: (
        <>
          <h1 className='text-2xl font-bold 3xs:text-xl'>
            <FormattedMessage
              id='home.install-app.install-instruction-steps-popup.step-3.texts.title'
              defaultMessage='Congratulations!'
            />
          </h1>
          <p className='text-lg 3xs:text-base'>
            <FormattedMessage
              id={`home.install-app.install-instruction-steps-popup.step-3.texts.${
                appIsAlreadyInstalled ? 'alternative-' : ''
              }description`}
              values={{
                xpAmount: 50,
                b: (chunks: string) => <b>{chunks}</b>,
              }}
            />
          </p>
        </>
      ),
      image: {
        src: handWithMobile,
        altName: 'hand with mobile',
        extraClassnames: 'bottom-14 h-[40vh] 3xs:h-[36vh]',
      },
      buttons: {
        backText: null,
        forwardText: (
          <FormattedMessage
            id='home.install-app.install-instruction-steps-popup.step-3.buttons.keep-learning'
            defaultMessage='Keep learning!'
          />
        ),
        onBack: doNothing,
        onForward: () => {
          onModalClose();
          onEnd?.();
        },
      },
    },
  ];

  const stepData = steps[step];

  const handleImageChange = () => {
    setImageIsHidden(true);
    setTimeout(() => {
      setImageIsHidden(false);
    }, 100);
  };

  const handleBackClick = () => {
    stepData.buttons.onBack();
    handleImageChange();
    prevStep();
  };

  const handleForwardClick = () => {
    stepData.buttons.onForward();
    handleImageChange();
    nextStep();
  };

  return (
    <Popup
      variant='fullscreen'
      open={open}
      closeIcon={false}
      pageContainerClassName='relative mx-auto bg-white w-full h-full flex flex-col justify-between p-0'
      disablePadding
      maxWidth={false}
    >
      <CurveBackground
        color={theme.colors.whiteTwo}
        withConfetti={isLastStep}
      />
      <div className='relative z-20 flex flex-col p-2'>
        <div className='flex justify-between gap-2'>
          <StepIndicator
            stepNumber={0}
            currentStep={step}
          />
          <StepIndicator
            stepNumber={1}
            currentStep={step}
          />
          <StepIndicator
            stepNumber={2}
            currentStep={step}
          />
        </div>
        <div className='flex flex-col gap-12 mx-5 mt-24 text-neutral-700 3xs:gap-6 3xs:mt-14 2xs:gap-10 2xs:mt-20 xl:mt-20'>
          {stepData.content}
        </div>
      </div>
      <div
        className={classnames('relative bottom-0 w-full p-5 left-0 flex gap-5', {
          'justify-center': isLastStep,
          'justify-between': !isLastStep,
        })}
      >
        <img
          src={stepData.image.src}
          alt={stepData.image.altName}
          className={classnames(stepData.image.extraClassnames, 'absolute z-10 transform -translate-x-1/2 left-1/2', {
            hidden: imageIsHidden,
          })}
        />
        {!isLastStep && (
          <Button
            variant='outlined'
            onClick={handleBackClick}
            className='z-20 w-1/3 text-sm sm:text-lg'
            style={{ borderColor: customizableAccountColor, color: customizableAccountColor }}
          >
            {stepData.buttons.backText}
          </Button>
        )}
        <Button
          onClick={handleForwardClick}
          className={classnames('text-white text-sm sm:text-lg z-20', {
            'w-2/3': !isLastStep,
            'w-full': isLastStep,
          })}
          style={{ backgroundColor: customizableAccountColor }}
        >
          {stepData.buttons.forwardText}
        </Button>
      </div>
    </Popup>
  );
};
