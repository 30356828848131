import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import * as React from 'react';
import { RadialProgress } from '../RadialProgress';
import { MinimalPathProps as Props } from './types';

/**
 * Minimal level banner.
 */
const MinimalBanner: React.FC<Props> = ({
  variant = 'default',
  unlocked,
  title,
  startText,
  done,
  color,
  lockedColor,
  level,
  onStartClicked,
}) => {
  // Calculations of the ajustments on the banner based on the level progress
  const dynamicColor = unlocked ? color : lockedColor;

  const innerContainerWidth = unlocked ? '247px' : '270px';
  const dynamicStyles = { backgroundColor: dynamicColor, color: '#ffffff' };

  const radialProgressLearningInformation = {
    id: level.id,
    unlocked: level.unlocked,
    passed: level.passed,
  };

  const icon = done ? (
    <RadialProgress
      key={`radial-progress-${level.id}`}
      value={level.testScore}
      learningInformation={radialProgressLearningInformation}
      primaryColor={color}
      lockedColor={lockedColor}
      medium
      className='ml-1 -mb-0.5 text-xs'
    />
  ) : (
    <SvgIcon
      className={classnames('m-auto', { 'm-0 mb-1 ml-2.5': unlocked })}
      name={unlocked ? 'lock-unlocked' : 'lock-locked'}
      color='#ffffff'
      viewBox='0 0 18 27'
      size={25}
    />
  );

  /**
   * This flag represents a Path that has only one Level.
   * The banner displays the horizontal line without title nor lock icon
   */
  const showMultipleLevelsBanner = variant !== 'straight';

  return (
    <div className='relative w-full h-0.5 my-0 mx-auto'>
      <div
        className='h-0.5 w-full'
        style={dynamicStyles}
      />
      <div
        className='relative w-64 m-auto'
        style={{ ...dynamicStyles, width: innerContainerWidth }}
      >
        {showMultipleLevelsBanner && (
          <span
            className={classnames(
              'absolute flex items-center justify-center top-1/2 right-0 transform -translate-y-1/2 bg-inherit rounded-full w-10 h-10 cursor-pointer z-10',
              {
                'bg-transparent': done,
                'cursor-auto': !unlocked,
                'rounded-2xl w-auto p-2.5': unlocked,
              },
            )}
            data-testid='banner-pill'
            onClick={unlocked ? onStartClicked : undefined}
          >
            {unlocked && <p className='p-0 m-0 text-sm text-white'>{startText}</p>}
            {icon}
          </span>
        )}
      </div>
      {showMultipleLevelsBanner && (
        <h3
          className='absolute inline m-0 p-0 text-sm pl-3.5 -top-5 left-0 lg:left-4/10 w-3/4'
          style={{ color }}
        >
          {title}
        </h3>
      )}
    </div>
  );
};

export default MinimalBanner;
