import classNames from 'classnames';
import { Button } from 'components/button';
import { Logo } from 'components/logo';
import { PageContainer } from 'components/page-container';
import { API_URL } from 'constants/endpoints';
import { User } from 'containers/authentication';
import { defaultLocale, isValidLocale, Locale, LocaleSelect } from 'containers/language-provider';
import { actions as profileActions } from 'containers/Profile';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlagKeys, useFeatureFlag } from 'store/feature-flags';
import { Store } from 'store/types';

export const LanguageSelection: React.FC = () => {
  const isImageHandlingEnabled = useFeatureFlag(FlagKeys.IMAGE_HANDLING);

  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const userLocale = useSelector<Store, Locale>(({ profile }) => profile.user.locale || defaultLocale);
  const [locale, setLocale] = React.useState<Locale>(userLocale);

  const { isAuthorizing } = useSelector(({ auth }: Store) => auth);
  const { logoUrl: accountLogo } = useSelector(({ profile }: Store) => profile.account);
  const { id: userId, isLocaleSelected } = useSelector<Store, User>(({ profile }) => profile.user);

  const [isEulaChecked, setIsEulaChecked] = React.useState(false);

  React.useEffect(() => {
    if (!isLocaleSelected || isAuthorizing) {
      return;
    }
    history.push('/');
  }, [history, isAuthorizing, isLocaleSelected]);

  const handleLocaleChange = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = target;
    if (!isValidLocale(value)) {
      return;
    }

    setLocale(value);

    dispatch(
      profileActions.updateUser({
        userId,
        locale: value,
      }),
    );
  };

  const handleEulaToggle = () => {
    setIsEulaChecked(!isEulaChecked);
  };

  const handleSubmit = () => {
    dispatch(
      profileActions.updateUser({
        userId,
        isLocaleSelected: true,
        locale,
        eulaAcceptedAt: 'now',
      }),
    );
  };

  return (
    <PageContainer>
      <div className='flex flex-col items-center p-4 h-f'>
        <div className='w-full h-5/10'>
          {accountLogo && (
            <div className='relative flex justify-center w-full pb-48 mx-auto mt-10 mb-4'>
              <Logo
                alt='logo'
                className={classNames('absolute', {
                  'w-auto': isImageHandlingEnabled,
                })}
                src={`${API_URL}/${accountLogo}`}
              />
            </div>
          )}
        </div>

        <div className='flex flex-col items-center w-full h-full'>
          <h3 className='font-light'>
            <FormattedMessage id='register.select-language' />
          </h3>
          <div className='w-full mt-4'>
            <LocaleSelect
              className='flex justify-center w-full p-4 text-lg font-light text-gray-400 bg-white border-2 border-gray-400 border-solid appearance-none'
              onChange={handleLocaleChange}
              locale={locale}
            />
          </div>
        </div>

        <div
          data-testid='checkbox'
          className='mx-2 my-6 text-sm text-warmGrey'
        >
          <input
            type='checkbox'
            id='consent-message'
            onChange={handleEulaToggle}
            checked={isEulaChecked}
          />
          <label
            htmlFor='consent-message'
            className='mx-2'
          >
            <FormattedMessage
              id='register.consent-message'
              defaultMessage='By registering to and using the MobieTrain software, I accept the terms outlined in the End User License Agreement ({link}).'
              values={{
                link: (
                  <a
                    href={intl.formatMessage({ id: 'register.eula-url' })}
                    target='_blank'
                    rel='noreferrer noopener'
                    className='text-yellow-500 underline'
                  >
                    <FormattedMessage
                      id='register.eula'
                      defaultMessage='EULA'
                    />
                  </a>
                ),
              }}
            />
          </label>
        </div>

        <div className='w-full mb-4'>
          <Button
            className='w-full pt-4 pb-4 mt-4 ml-0 mr-0 text-lg'
            variant='contained'
            onClick={handleSubmit}
            disabled={!isEulaChecked}
          >
            <FormattedMessage id='formats.button.next' />
          </Button>
        </div>
      </div>
    </PageContainer>
  );
};
