import classnames from 'classnames';
import { SvgIcon } from 'components/svg-icon';
import React from 'react';
import { RadialProgressProps as Props, RadialProgressState as State } from './types';

type CallbackFunc = (value: any, event: React.MouseEvent<HTMLElement>) => void;

const createOnClick = (fn: CallbackFunc, value: any) => (event: React.MouseEvent<HTMLElement>) => fn(value, event);

export class RadialProgress extends React.Component<Props, State> {
  public static defaultProps = {
    radius: 54,
    size: 28,
    value: 0,
    bounce: false,
  };

  private progressValueRef: React.RefObject<SVGCircleElement>;

  private linearGradientRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);

    this.progressValueRef = React.createRef();
    this.linearGradientRef = React.createRef();

    this.state = {
      count: 0,
      countInterval: null,
      topColor: 'grey',
      bottomColor: 'grey',
      config: {
        innerSize: 28,
        outerSize: 34,
        offset: null,
      },
    };
  }

  public componentDidMount() {
    const { value, learningInformation, radius, extended, counter, medium } = this.props;
    const { config } = this.state;

    let circumference = 2 * Math.PI;
    if (radius) {
      circumference *= radius;
    }
    const progress = value > 0 ? value / 100 : 0;
    const dashoffset = circumference * (1 - progress);

    if (counter) {
      this.renderCounter();
    }

    this.setState({ config: { ...config, offset: (config.innerSize - config.outerSize) / 2 } });
    if (extended) {
      this.setState({ config: { innerSize: 110, outerSize: 130, offset: (110 - 130) / 2 - 2.5 } });
    }
    if (medium) {
      this.setState({ config: { innerSize: 45, outerSize: 52, offset: (45 - 53) / 2 } });
    }

    switch (true) {
      case value < 80:
        this.setState({ topColor: '#f34280', bottomColor: '#f34280' });
        break;
      case value >= 80 && value < 90:
        this.setState({ topColor: '#ccd1d9', bottomColor: '#8f9aa8' });
        break;
      case value >= 90 && value <= 99:
        this.setState({ topColor: '#fad961', bottomColor: '#f76b1c' });
        break;
      case value === 100:
        this.setState({ topColor: '#24fe41', bottomColor: '#fdfc47' });
        break;
    }

    if (this.progressValueRef && this.progressValueRef.current) {
      this.progressValueRef.current.style.strokeDashoffset = dashoffset.toString();
      this.progressValueRef.current.style.strokeDasharray = circumference.toString();
      this.progressValueRef.current.style.stroke = `url(#linearGradient-${
        learningInformation ? learningInformation.id : value
      })`;
    }
  }

  public componentWillUnmount() {
    const { countInterval } = this.state;

    countInterval && clearInterval(countInterval);
  }

  public render() {
    const { learningInformation, primaryColor, lockedColor, onNodeClick, extended, hideProgress, bounce, className } =
      this.props;
    const { config } = this.state;

    const baseColor = learningInformation.unlocked ? primaryColor : lockedColor;

    return (
      <div
        className={classnames(
          'relative flex justify-center items-center cursor-pointer',
          {
            'cursor-[initial] top-2.5': extended,
            'animate-bounce': bounce,
          },
          className,
        )}
        onClick={(typeof onNodeClick === 'function' ? createOnClick(onNodeClick, learningInformation.id) : null) as any}
      >
        {this.renderText()}
        <SvgIcon
          color={learningInformation ? baseColor : primaryColor}
          size={hideProgress ? 130 : config.innerSize}
          name='circle'
        />
      </div>
    );
  }

  private setLinearGradient = () => {
    const { learningInformation, value } = this.props;
    const { topColor, bottomColor, config } = this.state;
    return (
      <svg
        key={`progress-${learningInformation ? learningInformation.id : value}`}
        className='absolute -rotate-90'
        width={config.outerSize}
        height={config.outerSize}
        viewBox='0 0 120 120'
      >
        <defs>
          <linearGradient
            id={`linearGradient-${learningInformation ? learningInformation.id : value}`}
            ref={this.linearGradientRef}
          >
            <stop
              offset='5%'
              stopColor={topColor}
            />
            <stop
              offset='95%'
              stopColor={bottomColor}
            />
          </linearGradient>
        </defs>
        <circle
          className='fill-none stroke-none'
          cx='60'
          cy='60'
          r='54'
          strokeWidth='12'
        />
        <circle
          ref={this.progressValueRef}
          className='fill-none'
          cx='60'
          cy='60'
          r='54'
          strokeWidth='12'
        />
      </svg>
    );
  };

  private renderText = () => {
    const { value, learningInformation, extended, hideProgress, withSign, counter, medium } = this.props;
    const { topColor, bottomColor, count } = this.state;
    const counterValue = counter ? count : value;
    const valueToShow = withSign ? `${counterValue}%` : counterValue;
    const percentageDisplay = learningInformation.passed ? valueToShow : '';

    if (!learningInformation || !learningInformation.unlocked) {
      return [];
    }

    return [
      !hideProgress && topColor && bottomColor && this.setLinearGradient(),
      <div
        key={`progress-label-${learningInformation ? learningInformation.id : value}`}
        className={classnames('absolute top-1/2 left-1/2 translate-3d-[-50%,-50%,0] text-white', {
          'text-2xs': !medium && !extended,
          'text-sm': medium,
          'text-2xl': extended,
        })}
      >
        {extended ? valueToShow : percentageDisplay}
      </div>,
    ];
  };

  private renderCounter = () => {
    const countInterval = setInterval(() => this.counter(), 20);
    this.setState({ countInterval });
  };

  private counter = () => {
    if (this.state.count < this.props.value) {
      this.setState(({ count }) => ({
        count: count + 1,
      }));
    } else {
      this.state.countInterval && clearInterval(this.state.countInterval);
    }
  };
}
