import { rootRestApi } from 'store/root.api';
import { CreateRatingRequestPayload, CreateRatingResponse, Lepa, StartLepaPayload } from './Lepa.types';

export const START_LEPA_TAG = 'startLepaTag';

export const lepaApi = rootRestApi
  .enhanceEndpoints({
    addTagTypes: [START_LEPA_TAG],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      startLepa: builder.query<Lepa, StartLepaPayload>({
        query: ({ userId, lepaId }) => ({
          url: `/users/${userId}/learning-paths/${lepaId}/start`,
          method: 'POST',
        }),
        providesTags: (_result, _error, { lepaId }) => [{ type: START_LEPA_TAG, id: lepaId }],
        serializeQueryArgs: ({ endpointName }) => endpointName,
        forceRefetch: ({ currentArg, previousArg }) =>
          currentArg?.lepaId !== previousArg?.lepaId || currentArg?.locale !== previousArg?.locale,
      }),
      createRating: builder.mutation<CreateRatingResponse, CreateRatingRequestPayload>({
        query: ({ lepaId, body }) => ({
          url: `/learning-paths/${lepaId}/ratings`,
          method: 'POST',
          body,
        }),
      }),
    }),
    overrideExisting: false,
  });

export const { useStartLepaQuery, useCreateRatingMutation } = lepaApi;
