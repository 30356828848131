import SvgCrown from 'components/SvgCrown';
import * as React from 'react';
import { StatsBarProps } from './types';

const StatsBar: React.FC<StatsBarProps> = ({ label, value, crown, valueColor, color, ...rest }) => (
  <div
    className='flex flex-col items-center flex-1 h-full p-1.5 mx-1 my-0 text-center w-9 text-2xs'
    {...rest}
  >
    <div className='relative h-full w-9'>
      <div
        className='bg-black w-full h-full absolute bottom-0 min-h-[1%]'
        style={{ height: `${value}%`, background: color }}
      />
      <div
        className='absolute w-full pb-1.5 text-center'
        style={{ bottom: `${value}%` }}
      >
        <span
          className='font-light text-warmGrey'
          style={{ color: valueColor || '' }}
        >
          {value}%
        </span>
        {crown ? (
          <SvgCrown
            gradient={crown}
            style={{ marginTop: 5 }}
          />
        ) : null}
      </div>
    </div>
    <div className='flex flex-grow-0 flex-shrink-0 flex-basis-[3rem] items-center text-greyishBrown'>{label}</div>
  </div>
);

export default StatsBar;
