import { PageContainer } from 'components/page-container';
import { AuthHistoryStateParams, AuthRedirectMessageTypes } from 'containers/authentication';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useQueryParams } from 'util/useQueryParams';
import { Logout } from './logout';
import { Unauthorized } from './unauthorized';

function isAuthRedirectMessageType(messageType?: string): messageType is AuthRedirectMessageTypes {
  if (!messageType) {
    return false;
  }
  const asAuthRedirectMessageType = messageType as AuthRedirectMessageTypes;
  return Object.values(AuthRedirectMessageTypes).includes(asAuthRedirectMessageType);
}

export const SSOMessage: React.FC = () => {
  const { messageType } = useQueryParams<AuthHistoryStateParams>();

  if (!isAuthRedirectMessageType(messageType)) {
    return <Redirect to='/' />;
  }

  return (
    <PageContainer
      center
      className='h-full'
    >
      <div className='font-light text-center'>
        {
          {
            [AuthRedirectMessageTypes.LOGOUT]: <Logout />,
            [AuthRedirectMessageTypes.UNAUTHORIZED]: <Unauthorized />,
          }[messageType]
        }
      </div>
    </PageContainer>
  );
};
