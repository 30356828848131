import classnames from 'classnames';
import ModuleStar from 'components/ModuleStar';
import PopupStar from 'components/PopupStar';
import { SvgIcon } from 'components/svg-icon';
import { ModalTypes } from 'containers/modal-controller/ModalController.types';
import { getColorFromScore } from 'pages/skill';
import { FormattedMessage } from 'react-intl';
import tinyColor from 'tinycolor2';
import Base from '../Base';
import { ModuleShapeProps } from '../types';

class Circle extends Base<ModuleShapeProps> {
  public render() {
    const { width, height } = this.state;
    return (
      <div
        className='relative w-72 h-72 text-greyish'
        data-testid='skill-module-circle'
      >
        <canvas
          ref={this.canvasRef}
          width={width}
          height={height}
        />
        {this.renderNodes()}
        {this.renderCap('absolute top-3/10 left-7/20 w-24 h-20 bg-no-repeat bg-center bg-100/100')}
      </div>
    );
  }

  /**
   * This method needs to be implemented so the Base parent
   * can call it on `componentDidMount`
   */
  public drawShape = () => {
    const { animationIndex, animateUnlocks } = this.props;

    if (animationIndex >= 0 && animationIndex <= 1) {
      if (animationIndex === 0 && animateUnlocks) {
        this.setState({ animate: true, showLocks: [1] }, () => {
          this.animateArch(800, () => {
            this.setState({ animate: false, showLocks: [] });
          });
        });
      } else {
        this.drawCircle();
        this.setState({ animate: true }, () => {
          clearTimeout(this.timer as number);
          this.timer = setTimeout(() => {
            this.setState({ animate: false });
          }, 800);
        });
      }
    } else {
      this.drawCircle();
    }
  };

  public drawCircle() {
    const { color, modules, animateUnlocks } = this.props;
    const lineWidth = 5;

    // Stroked triangle
    if (this.ctx && color) {
      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = lineWidth;
      this.ctx.globalAlpha = !modules[1].unlocked || animateUnlocks ? 0.3 : 1;

      this.drawArch();
      this.drawArch({ reverse: true });
      this.ctx.restore();
    }
  }

  public animateArch = (duration = 800, callback: (...args: any) => void) => {
    const { color } = this.props;
    const lineWidth = 5;

    // Stroked triangle
    if (this.ctx && color) {
      this.ctx.strokeStyle = color;
      this.ctx.lineWidth = lineWidth;
    }
    const steps = 100;
    const angleIncrement = 1 / steps;

    const { width, height } = this.state;
    let step = 0;
    let leftAngle = 1.5;
    let rightAngle = 1.5;
    this.timer = setInterval(() => {
      step += 1;

      this.ctx && this.ctx.clearRect(0, 0, width, height);

      this.drawCircle();

      this.ctx && this.ctx.save();

      if (this.ctx && color) {
        this.ctx.strokeStyle = color;
        this.ctx.lineWidth = 5;
        this.ctx.globalAlpha = 1;
      }

      leftAngle -= angleIncrement;
      rightAngle += angleIncrement;

      this.drawArch({ startAngle: leftAngle });
      this.drawArch({ reverse: true, startAngle: rightAngle });

      this.ctx && this.ctx.restore();

      if (step === steps) {
        clearInterval(this.timer as number);
        if (typeof callback === 'function') {
          return callback();
        }
      }
      return true;
    }, duration / steps);
  };

  /**
   * Calls the passed prop onNodeClick when node is clicked
   */
  public nodeClicked = (id: number | string) => () => {
    this.props.onNodeClick('moduleTest', id);
  };

  /**
   * It renders the nodes that can be clicked and also will animate
   */
  public renderNodes() {
    const { modules, animationIndex, moduleCreditReached, animationColor, color, hasUnlockAnimation } = this.props;
    const { animate, width } = this.state;
    const { showLocks } = this.state;

    const sides = [
      {
        style: {
          position: 'absolute',
          top: 22,
          left: '50%',
          transform: 'translate3d(-50%, -50%, 0)',
        },
      },
      { style: { position: 'absolute', bottom: -3, left: width / 2 - 20 } },
    ];

    return sides.map((props, indx) => {
      const { id } = modules[indx];
      const { done } = modules[indx];
      const { passed } = modules[indx];
      const { unlocked } = modules[indx];
      const score = modules[indx].percentage;
      const { title } = modules[indx];
      const unlocking = showLocks.includes(indx);
      const limited = moduleCreditReached && !done;
      const popupType = !unlocked ? ModalTypes.LEMO_LOCKED : ModalTypes.LEMO_LIMIT_REACHED;
      const moduleStarColor = getColorFromScore(score);
      const brightColor = tinyColor(color).brighten();
      const darkColor = tinyColor(color).darken();

      const shouldStartUnlockAnimate = hasUnlockAnimation && passed;

      const baseColor = !unlocked ? '#c3c3c3' : color;
      const brightBaseColor = unlocking ? brightColor : baseColor;
      const finalColor = passed ? darkColor : brightBaseColor;

      return (
        <div
          key={`bi-side-${indx}`}
          {...(props as any)}
          className={!unlocked ? 'locked-node' : 'unlocked-node'}
        >
          <SvgIcon
            color={String(finalColor)}
            size={40}
            name='circle'
            style={{ pointerEvents: 'none' }}
          />

          {unlocking || limited || !unlocked ? (
            <SvgIcon
              className='absolute top-0 left-0 translate-3d-[50%,50%,0]'
              color='#fff'
              size={20}
              name={unlocking ? 'lock-open' : 'lock'}
              onClick={this.openPopup(popupType)}
            />
          ) : (
            <SvgIcon
              className='absolute top-0 left-0 text-aquaMarine fill-current opacity-0 transition-opacity duration-200 ease-in-out translate-3d-[-10%,-10%,0] cursor-pointer'
              size={50}
              name='circle'
              onClick={this.nodeClicked(id)}
            />
          )}

          {shouldStartUnlockAnimate && (
            <ModuleStar
              className='absolute top-0 left-0 translate-3d-[50%,50%,0] pointer-events-none'
              color={moduleStarColor}
            />
          )}

          <div
            className={classnames(
              'absolute w-36 transform -translate-x-1/3 text-center text-sm font-medium',
              { '-top-2 transform -translate-x-1/3 -translate-y-full': indx === 0 },
              {
                'text-turquoiseBlue': unlocked && !passed,
                'text-ocean': passed,
              },
            )}
            style={{ color }}
          >
            {title}
          </div>

          {shouldStartUnlockAnimate && animationColor !== 'ruby' && (
            <div
              className={classnames(
                'absolute w-36 transform text-center text-sm font-medium',
                'bg-white text-turquoiseBlue pointer-events-none',
                {
                  'pb-3 -translate-x-1/3': indx >= 1,
                  'pb-2.5 translate-3d-[-33%,-200%,0]': indx < 1,
                  'opacity-1 transition-opacity duration-200 ease-in-out': animate && animationIndex === indx,
                  'opacity-0 transition-opacity duration-700 ease-in-out delay-200':
                    !animate || animationIndex !== indx,
                },
              )}
            >
              {animationColor === 'diamond' && <FormattedMessage id='comp.moduleShape.finishedDiamond' />}
              {animationColor === 'gold' && <FormattedMessage id='comp.moduleShape.finishedGold' />}
              {animationColor === 'silver' && <FormattedMessage id='comp.moduleShape.finishedSilver' />}
            </div>
          )}

          {shouldStartUnlockAnimate && (
            <PopupStar
              color={animationColor}
              className={classnames('absolute top-4/10 left-1/2 w-20 h-16 pointer-events-none', {
                'opacity-1 translate-3d-scale-[-50%,-50%,0,1] transition-all duration-200 ease-in-out':
                  animate && animationIndex === indx,
                'opacity-0 translate-3d-scale-[-50%,-50%,0,0] transition-all duration-700 ease-in-out':
                  !animate || animationIndex !== indx,
              })}
            />
          )}
        </div>
      );
    });
  }
}

export default Circle;
