import { useSelector } from 'react-redux';
import { Store } from 'store/types';
import { doNothing } from 'util/doNothing';
import { Locale, defaultLocale } from '../language-provider';
import { useCompleteWalkthroughMutation, useGetWalkthroughsQuery } from './Walkthrough.api';
import { completedWalkthroughsFallback, isUserWithWalkthroughAccess } from './Walkthrough.helpers';
import { UseWalkthrough, WalkthroughVariantTypes } from './Walkthrough.types';

export const useWalkthrough = (): UseWalkthrough => {
  const user = useSelector(({ profile: profileStore }: Store) => profileStore.user);
  const { data: completedWalkthroughs = completedWalkthroughsFallback } = useGetWalkthroughsQuery({
    userId: user.id,
  });
  const [completeWalkthrough] = useCompleteWalkthroughMutation();
  const locale = useSelector<Store, Locale>(({ profile }) => profile.user.locale || defaultLocale);

  const onWalkthroughEnd = async (slug: WalkthroughVariantTypes, showAfter?: Date) => {
    try {
      const body = {
        slug,
        ...(showAfter && { showAfter: showAfter.toISOString() }),
      };

      await completeWalkthrough({
        userId: user.id,
        body,
      }).unwrap();
    } catch (unexpectedError) {
      doNothing();
    }
  };

  if (!isUserWithWalkthroughAccess(user.role)) {
    return {
      completedWalkthroughs: completedWalkthroughsFallback,
      onWalkthroughEnd,
      locale,
    };
  }

  return { onWalkthroughEnd, completedWalkthroughs, locale };
};
