import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Tab, Tabs } from 'components/tabs';
import { TabsStyle } from 'components/tabs/Tabs.types';
import { TopBar } from 'components/top-bar';
import { USER_ROLES } from 'constants/global';
import { ProfileStore } from 'containers/Profile';
import { withAuthentication } from 'containers/authentication';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useGetDynamicConfigurationQuery } from 'store/dynamic-configuration';
import { Store } from 'store/types';
import { ChatList } from './chat';
import { useChatUnreadCount } from './chat/ChatContext';
import { withChatProvider } from './chat/hooks/withChatProvider';
import { NewsPostFullView, Newsfeed } from './newsfeed';

export const Community = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const { id: accountId } = useSelector<Store, ProfileStore['account']>(({ profile }) => profile.account);

  const getDynamicConfigurationQueryParams = accountId ? { accountId } : skipToken;
  const { data: dynamicConfiguration } = useGetDynamicConfigurationQuery(getDynamicConfigurationQueryParams);

  const isNewsfeedEnabled = Boolean(dynamicConfiguration?.menu.newsfeed.show);
  const isChatEnabled = Boolean(dynamicConfiguration?.plugins.chat);
  const currentLocation = location.pathname;

  const { unreadCount } = useChatUnreadCount();

  const TABS = [
    ...(isNewsfeedEnabled
      ? [
          {
            title: intl.formatMessage({ id: 'community.news', defaultMessage: 'News & Messages' }),
            component: withAuthentication(Newsfeed, USER_ROLES.USER, ['newsfeed']),
            path: '/community/newsfeed',
            exact: true,
          },
        ]
      : []),
    ...(isChatEnabled
      ? [
          {
            title: intl.formatMessage({ id: 'community.my-chat', defaultMessage: 'My chat' }),
            component: withAuthentication(withChatProvider(ChatList)),
            path: '/community/chat',
            notificationsCount: unreadCount,
            exact: true,
          },
        ]
      : []),
  ];

  const pathIndex = TABS.findIndex((tab) => tab.path === currentLocation);
  const currentIndex = pathIndex === -1 ? 0 : pathIndex;

  const handleTabChange = (selectedIndex: number) => {
    const { path } = TABS[selectedIndex];
    if (!path) {
      return;
    }
    history.push(path);
  };

  const handleBack = () => history.push('/');

  return (
    <div className='h-full overflow-y-auto bg-zinc-100'>
      <TopBar backButton={<TopBar.IconButton onClick={handleBack} />}>
        <h3 className='flex-1 p-4 text-lg font-medium text-center text-white uppercase'>
          {intl.formatMessage({ id: 'community.title', defaultMessage: 'Community' })}
        </h3>
      </TopBar>
      <Tabs
        className='bg-zinc-100'
        indexSelected={currentIndex}
        onChange={handleTabChange}
        style={TabsStyle.UNDERLINE}
      >
        {TABS.map((tab) => (
          <Tab
            key={tab.path}
            title={tab.title}
            notificationCount={tab.notificationsCount}
          >
            <Route
              key={`routes-${tab.path}`}
              path={tab.path}
              component={tab.component}
              exact={tab.exact}
            />
          </Tab>
        ))}
      </Tabs>

      <Route
        path={'/community/newsfeed/:postId'}
        component={withAuthentication(NewsPostFullView, USER_ROLES.USER, ['newsfeed'])}
        exact={true}
      />
    </div>
  );
};
